import React, { SyntheticEvent, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import NavBarMobile from './NavBarMobile';
import { get_merchant_profile } from '../services/Settings/merchantprofileinfo';


const HotLinksCheckoutMobile = () => {

    const [titleSelected, setTitleSelected] = useState(1)
    const handleNavLinkClick = (index: React.SetStateAction<number>) => {
        setTitleSelected(index);
    }
    const [merchant, setMerchant] = useState([] as any)

    const location = useLocation();
    const currentRoutePath = location.pathname;
    useEffect(() => {
        if (currentRoutePath === '/successfulpayments') {
            setTitleSelected(1);

        } else if (currentRoutePath === '/payment-subscription') {
            setTitleSelected(2);

        } else if (currentRoutePath === '/payment-links') {
            setTitleSelected(3);

        } else if (currentRoutePath === '/invoices') {
            setTitleSelected(4);

        }
    })

    useEffect(() => {
        // Get Merchant Information
        async function fetchMerchantInfo() {
            var merchant = await get_merchant_profile();
            setMerchant(merchant);
        }
        fetchMerchantInfo();
    }, [])

    return (
        <>
            {/* NavBar */}
            <NavBarMobile></NavBarMobile>

            {/* HotLinksMenu + Title */}
            <div className="hotlinksmenu1_mob">
                <div className="pagetitle_mob">
                    <div className="marketing1_mob">Payments</div>
                </div>
                <div className="hotlinkscard-parent_mob">
                    <NavLink to="/payment-link-create" className="hotlinkscard3_mob" style={{ textDecoration: "none" }}>
                        <div className="frame-parent2_mob">
                            <div className="new-customer-x-parent1_mob">
                                <div className="new-customer-x3_mob">Payment Link</div>
                                <div className="frame-child3_mob" />
                                <div className="frame-child4_mob" />
                            </div>
                            <div className="path-9386-parent1_mob">
                                <svg className="path-9386-icon3_mob" viewBox="0 0 11 11">
                                    <path d="M 4.714285850524902 4.714285850524902 L 4.714285850524902 0 L 6.285714149475098 0 L 6.285714149475098 4.714285850524902 L 11 4.714285850524902 L 11 6.285714149475098 L 6.285714149475098 6.285714149475098 L 6.285714149475098 11 L 4.714285850524902 11 L 4.714285850524902 6.285714149475098 L 0 6.285714149475098 L 0 4.714285850524902 L 4.714285850524902 4.714285850524902 Z">
                                    </path>
                                </svg>
                                <div className="add-new-customer3_mob">
                                    Create Payment link
                                </div>
                            </div>
                        </div>
                    </NavLink>
                    <NavLink to="/createinvoice" className="hotlinkscard3_mob" style={{ textDecoration: "none" }}>
                        <div className="frame-parent2_mob">
                            <div className="new-customer-x-parent1_mob">
                                <div className="new-customer-x3_mob">
                                    New Invoice
                                </div>
                                <div className="frame-child3_mob" />
                                <div className="frame-child4" />
                            </div>
                            <div className="path-9386-parent2_mob">
                                <svg className="path-9386-icon3_mob" viewBox="0 0 11 11">
                                    <path d="M 4.714285850524902 4.714285850524902 L 4.714285850524902 0 L 6.285714149475098 0 L 6.285714149475098 4.714285850524902 L 11 4.714285850524902 L 11 6.285714149475098 L 6.285714149475098 6.285714149475098 L 6.285714149475098 11 L 4.714285850524902 11 L 4.714285850524902 6.285714149475098 L 0 6.285714149475098 L 0 4.714285850524902 L 4.714285850524902 4.714285850524902 Z">
                                    </path>
                                </svg>
                                <div className="add-new-customer3_mob">
                                    Create New Invoice
                                </div>
                            </div>
                        </div>
                    </NavLink>
                    <NavLink to="/subscription-create" className="hotlinkscard3_mob" style={{ textDecoration: "none" }}>
                        <div className="frame-parent2_mob">
                            <div className="new-customer-x-parent1_mob">
                                <div className="new-customer-x3_mob">
                                    Monthly subscription
                                </div>
                                <div className="frame-child3_mob" />
                                <div className="frame-child4_mob" />
                            </div>
                            <div className="path-9386-parent2_mob">
                                <svg className="path-9386-icon3_mob" viewBox="0 0 11 11">
                                    <path d="M 4.714285850524902 4.714285850524902 L 4.714285850524902 0 L 6.285714149475098 0 L 6.285714149475098 4.714285850524902 L 11 4.714285850524902 L 11 6.285714149475098 L 6.285714149475098 6.285714149475098 L 6.285714149475098 11 L 4.714285850524902 11 L 4.714285850524902 6.285714149475098 L 0 6.285714149475098 L 0 4.714285850524902 L 4.714285850524902 4.714285850524902 Z">
                                    </path>
                                </svg>
                                <div className="add-new-customer3_mob">
                                    Create Subscription
                                </div>
                            </div>
                        </div>
                    </NavLink>
                </div>
            </div>

            {/* ListViewMenu + Line*/}
            <div className="listviewmenu1_mob">
                <div className="lmenu1_mob">
                    <div className="lmenu-inner_mob">
                        <div className="all-group_mob">
                            <NavLink to={'/successfulpayments'} key={1} onClick={() => handleNavLinkClick(1)} className={`new-customer-x11_mob ${1 === titleSelected ? 'bold-title' : ''}`} style={{ textDecoration: 'none' }} >
                                <span>Payments</span>
                            </NavLink>
                            <NavLink to={'/payment-subscription'} key={2} onClick={() => handleNavLinkClick(2)} className={`new-customer-x12_mob ${2 === titleSelected ? 'bold-title' : ''}`} style={{ textDecoration: 'none' }} >
                                <span>Subscriptions</span>
                            </NavLink>
                            <NavLink to={'/payment-links'} key={3} onClick={() => handleNavLinkClick(3)} className={`new-customer-x13_mob ${3 === titleSelected ? 'bold-title' : ''}`} style={{ textDecoration: 'none' }}>
                                <span>Payment links</span>
                            </NavLink>
                            <NavLink to={'/invoices'} key={4} onClick={() => handleNavLinkClick(4)} className={`new-customer-x14_mob ${4 === titleSelected ? 'bold-title' : ''}`} style={{ textDecoration: 'none' }}>
                                <span>Invoices</span>
                            </NavLink>
                        </div>
                    </div>
                    <div className="line-container_mob">
                        <svg className="line-icon1_mob" viewBox="0 1 413.583 0.5">
                            <path id="line-icon1_mob" d="M 0 1 L 413.5830078125 1">
                            </path>
                        </svg>
                    </div>
                </div >
            </div >
        </>
    );
};

export default HotLinksCheckoutMobile;

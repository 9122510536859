
// Get Available Products
export async function get_available_products(){

  const response = await fetch(process.env.REACT_APP_CHPTER_API_URL+"/products/Available", {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer "+localStorage.getItem("JWT"),
        'Accept': 'application/json',
      },
    });
  const data = await response.json();
  console.log(data.status);

  if(data.status === 401){
    //Redirect to login Token expired
    window.location.href = '/login';

  }    
  if(data.status === 200){
      return data.products;
  }

  return [];
}

// Get Archived Products
export async function get_archived_products(){

const response = await fetch(process.env.REACT_APP_CHPTER_API_URL+"/products/Archived", {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': "Bearer "+localStorage.getItem("JWT"),
      'Accept': 'application/json',
    },
  });
const data = await response.json();
console.log(data.status);

if(data.status === 401){
  //Redirect to login Token expired
  window.location.href = '/login';

}

if(data.status === 200){
    return data.products;
}

return [];
}

// Get Product Detail
export async function get_product_detail(product_slug: string | undefined){

    const response = await fetch(process.env.REACT_APP_CHPTER_API_URL+"/product/" + product_slug, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer "+localStorage.getItem("JWT"),
          'Accept': 'application/json',
        },
      });
    const data = await response.json();
    console.log(data.status);

    if(data.status === 401){
      //Redirect to login Token expired
      window.location.href = '/login';

    }

    if(data.status === 200){
        return data.product;
    }

    return [];
}

// Get Products's Payment History
export async function get_product_payment_history(productId: string | undefined){

    const response = await fetch(process.env.REACT_APP_CHPTER_API_URL+"/payments/product/" + productId + "/status/Successful", {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer "+localStorage.getItem("JWT"),
          'Accept': 'application/json',
        },
      });
    const data = await response.json();
    console.log(data.status);

    if(data.status === 401){
      //Redirect to login Token expired
      window.location.href = '/login';

    }

    if(data.status === 200){
        return data.payment;
    }

    return [];
}

// Get linkbasket 
export async function get_checkout_link(brand_alias : string | undefined, slug : string | undefined){

    const response = await fetch(process.env.REACT_APP_CHPTER_API_URL+"/checkout/" + brand_alias + "/" + slug, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer "+localStorage.getItem("JWT"),
          'Accept': 'application/json',
        },
      });
    const data = await response.json();
  
    if(data.status === 401){
      //Redirect to login Token expired
    }    
    if(data.status === 200){
        return data.product;
    }
    
    return [];
}


// Get Link Navigation Info
export async function get_linkbasket_navigation(brand_alias : string | undefined){

  const response = await fetch(process.env.REACT_APP_CHPTER_API_URL+"/merchants/nocodenavigation/" + brand_alias, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    });
  const data = await response.json();
  console.log(data.status);
  console.log("data :::: get_linkbasket_navigation ::::", data);

  if(data.status === 401){
    //Redirect to login Token expired
    
  }

  if(data.status === 200){
      return data.link_basket_navigation;
  }

  return [];
}
export async function GetMerchantConversations(){

    const response = await fetch(process.env.REACT_APP_CHPTER_API_URL+"/whatsapp/conversations", {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer "+localStorage.getItem("JWT"),
          'Accept': 'application/json',
        },
      });
    const data = await response.json();
    
    if(data.status === 401){
      //Redirect to login Token expired
      window.location.href = '/login';
  
    }    
    return data;
  }

  export async function GetConversationMessagesWhatsapp(conversationId: any){

    const response = await fetch(process.env.REACT_APP_CHPTER_API_URL+"/whatsapp/conversation/messages/"+conversationId, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer "+localStorage.getItem("JWT"),
          'Accept': 'application/json',
        },
      });
    const data = await response.json();
    
    if(data.status === 401){
      //Redirect to login Token expired
      window.location.href = '/login';
  
    }  
    return data;
  }

  export async function SendTextMessageWhatsapp(message: any){

    const response = await fetch(process.env.REACT_APP_CHPTER_API_URL+"/whatsapp/message/send/text", {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer "+localStorage.getItem("JWT"),
          'Accept': 'application/json',
        },
        method: "POST",
        // Adding body or contents to send
        body: JSON.stringify(message),
         
      });
    const data = await response.json();
    
    if(data.status === 401){
      //Redirect to login Token expired
      window.location.href = '/login';
  
    }  
    return data;
  }

  export async function SendMediaMessageWhatsapp(message: any){

    const response = await fetch(process.env.REACT_APP_CHPTER_API_URL+"/whatsapp/message/send/media", {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer "+localStorage.getItem("JWT"),
          'Accept': 'application/json',
        },
        method: "POST",
        // Adding body or contents to send
        body: JSON.stringify(message),
         
      });
    const data = await response.json();
    
    if(data.status === 401){
      //Redirect to login Token expired
      window.location.href = '/login';
  
    }  
    return data;
  }
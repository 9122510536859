import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import '../../styles/Developers/apilogs.css'
import '../../styles/Components/NavBar.css'
import '../../styles/Components/UserRibbon.css'
import '../../styles/Components/HotLinks_Developers.css'
import Wrapper from "../../components/Wrapper";
import HotlinksDevelopers from '../../components/HotLinks_Developers';

import { SuccessfulPayments } from "../../models/payments";


const APIlogs = () => {
    return (
        <>
            <main className="form-signin">
                <div id="DevelopersLogs_devlog">
                    <svg className="Line_337_devlog" viewBox="0 0 1383.34 1">
                        <path id="Line_337_devlog" d="M 0 0 L 1383.339599609375 0">
                        </path>
                    </svg>
                    <div id="Group_6817_devlog">
                        <div id="n_2628_devlog">
                            <span>POST</span>
                        </div>
                        <div id="n_4_July_164500_PM_devlog">
                            <span>24 July, 16:45:00 PM</span>
                        </div>
                        <div id="v1chptercoapipaymentpayment-in_devlog">
                            <span>v1/chpter.co/api/payment/payment-initiate-callback/</span>
                        </div>
                        <div id="Group_4599_devlog">
                            <div id="Group_3969_devlog">
                                <svg className="Rectangle_2057_devlog">
                                    <rect id="Rectangle_2057_devlog" rx="3" ry="3" x="0" y="0" width="90" height="21.176">
                                    </rect>
                                </svg>
                            </div>
                            <div id="Group_6815_devlog">
                                <div id="n_00___OK_devlog">
                                    <span>200   OK</span>
                                </div>
                                <div id="Layer_2_devlog">
                                    <div id="checkmark_devlog">
                                        <svg className="Rectangle_2122_devlog">
                                            <rect id="Rectangle_2122_devlog" rx="0" ry="0" x="0" y="0" width="18" height="18">
                                            </rect>
                                        </svg>
                                        <svg className="Path_9092_devlog" viewBox="3.999 5.956 12.298 9.233">
                                            <path id="Path_9092_devlog" d="M 8.492274284362793 15.18946647644043 C 8.279788017272949 15.18878364562988 8.077116012573242 15.09994220733643 7.932625770568848 14.94414138793945 L 4.206743717193604 10.98060131072998 C 3.916711807250977 10.67151546478271 3.932157278060913 10.18583393096924 4.2412428855896 9.895801544189453 C 4.550328254699707 9.605769157409668 5.036009311676025 9.621214866638184 5.326041698455811 9.930300712585449 L 8.484606742858887 13.29586124420166 L 14.93206882476807 6.242752075195312 C 15.11035251617432 6.020743370056152 15.39699077606201 5.915829658508301 15.6764669418335 5.970292091369629 C 15.95594310760498 6.024755477905273 16.18221473693848 6.229621887207031 16.26408958435059 6.502328872680664 C 16.34596633911133 6.775036811828613 16.26995849609375 7.070656776428223 16.06669998168945 7.270052909851074 L 9.059587478637695 14.9364767074585 C 8.916444778442383 15.09511184692383 8.713593482971191 15.18681240081787 8.49993896484375 15.18946933746338 Z">
                                            </path>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <svg className="Path_9130_devlog" viewBox="0 -1 1383.34 1">
                            <path id="Path_9130_devlog" d="M 0 -1 L 1383.339599609375 0">
                            </path>
                        </svg>
                    </div>
                    <div id="Group_6816_devlog">
                        <svg className="Path_9130_e_devlog" viewBox="0 -1 1383.34 1">
                            <path id="Path_9130_e_devlog" d="M 0 -1 L 1383.339599609375 0">
                            </path>
                        </svg>
                        <div id="TIME_devlog">
                            <span>TIME</span>
                        </div>
                        <div id="API_ENDPOINT_devlog">
                            <span>API ENDPOINT</span>
                        </div>
                        <div id="n_2628_fc_devlog">
                            <span>REQUEST</span>
                        </div>
                    </div>
                </div>
            </main>
            <HotlinksDevelopers></HotlinksDevelopers>
            <Wrapper></Wrapper>
        </>
    )
};

export default APIlogs;

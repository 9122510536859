import React from 'react';
import WrapperConnect from '../../components/Wrapper_Connect';
import '../../styles/Components/Toastify.css'

const ChatInfo = () => {

    return (
        <>
      
    <WrapperConnect></WrapperConnect>

        <main className="form-signin">
            <div id="Chat_Info_Links_inflnks">
                <div id="Group_7400_inflnks">
                    <div id="Group_7013_inflnks">
                        <div id="Group_6994_inflnks">
                            <div id="Group_4740_inflnks">
                                <svg className="Rectangle_1922_inflnks">
                                    <rect id="Rectangle_1922_inflnks" rx="0" ry="0" x="0" y="0" width="375" height="999">
                                    </rect>
                                </svg>
                                <div id="Sign_in_inflnks">
                                    <span>Sign in</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="Group_7391_inflnks">
                        <div id="Customer_Profile_inflnks">
                            <span>Customer Profile</span>
                        </div>
                        <div id="Fernando_inflnks">
                            <span>Fernando</span>
                        </div>
                        <div id="n_54_733_735_829_inflnks">
                            <span>+254 733 735 829</span>
                        </div>
                        <div id="Group_7038_inflnks">
                            <svg className="Line_inflnks" viewBox="0 1 331.037 1">
                                <path id="Line_inflnks" d="M 0 1 L 331.0370788574219 1">
                                </path>
                            </svg>
                            <div id="LINKS_inflnks">
                                <span>LINKS</span>
                            </div>
                        </div>
                    </div>
                    <div id="Group_7386_inflnks">
                        <svg className="Rectangle_2560_inflnks">
                            <rect id="Rectangle_2560_inflnks" rx="5" ry="5" x="0" y="0" width="331" height="111">
                            </rect>
                        </svg>
                        <div id="Group_7385_inflnks">
                            <div id="Redbull_Speedboat_Pro_inflnks">
                                <span>Redbull Speedboat Pro</span>
                            </div>
                            <div id="KES_2000000_inflnks">
                                <span>KES. 20,000.00</span>
                            </div>
                        </div>
                        <div id="Group_7384_inflnks">
                            <div id="Add_to_chat_inflnks">
                                <span>Add to chat</span>
                            </div>
                            <img id="Group_7383_inflnks" src="media/Group_7383.png" />
                        </div>
                        <div id="Group_5220_inflnks">
                            <svg className="Rectangle_2210_inflnks">
                                <rect id="Rectangle_2210_inflnks" rx="7" ry="7" x="0" y="0" width="68.495" height="68.002">
                                </rect>
                            </svg>
                            <img id="adidas-ultraboost_inflnks" src="media/adidas-ultraboost.png" />
                                
                        </div>
                    </div>
                </div>
            </div>
            
        </main>
        </>
    )
};

export default ChatInfo;
ChatInfo.displayName = 'ChatInfo';
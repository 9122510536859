import '../../styles/Welcome/welcomepage.css';
import React, { Component } from 'react'
import { NavLink } from "react-router-dom"


class WelcomePage extends Component {
    render() {
        return (
            <div>

                <div id="WelcomePage">
                    <div id="Group_3972_wp">
                        <svg className="Rectangle_1922_wp">
                            <rect id="Rectangle_1922_wp" rx="15" ry="15" x="0" y="0" width="600" height="375">
                            </rect>
                        </svg>
                        <div id="All_wp">
                            <span>Sign in or Register to continue. </span>
                        </div>
                        <div id="Group_4533_wp">
                            <div id="Group_3969_wp">
                                <svg className="Rectangle_2057_es_wp">
                                    <linearGradient id="Rectangle_2057_es" spreadMethod="pad" x1="0.066" x2="0.79" y1="0" y2="1.3">
                                        <stop offset="0" stop-color="#000" stop-opacity="1"></stop>
                                        <stop offset="1" stop-color="#000" stop-opacity="1"></stop>
                                    </linearGradient>
                                    <rect id="Rectangle_2057_es_wp" rx="7" ry="7" x="0" y="0" width="469" height="48">
                                    </rect>
                                </svg>
                            </div>
                            <NavLink to={'/login'}>
                                <div id="Sign_in_wp">
                                    <span>Sign in</span>
                                </div>
                            </NavLink>
                        </div>
                        <div id="Group_4535_wp">
                            <div id="Group_4534_wp">
                                <div id="Group_3969_ew_wp">
                                    <svg className="Rectangle_2057_ey_wp">
                                        <linearGradient id="Rectangle_2057_ey" spreadMethod="pad" x1="0.066" x2="0.79" y1="0" y2="1.3">
                                            <stop offset="0" stop-color="#000" stop-opacity="1"></stop>
                                            <stop offset="1" stop-color="#000" stop-opacity="1"></stop>
                                        </linearGradient>
                                        <rect id="Rectangle_2057_ey_wp" rx="7" ry="7" x="0" y="0" width="469" height="48">
                                        </rect>
                                    </svg>
                                </div>
                            </div>

                            <a href="https://app.chpter.co/auth/register">
                                <div id="Register_wp">
                                    <span>Register</span>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div id="Group_4528_wp">
                        <div id="Group_3168_wp">
                            <NavLink to={'/homepage'}>
                                <div id="chpter_wp">
                                    <span>chpter.</span>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                    <div id="Group_4541_wp">
                        <div id="Group_3168_e_wp">
                            <div id="Fast_checkout_Built_for_small__wp">
                                <span>Fast checkout.<br />Built for small businesses.</span>
                            </div>
                        </div>
                    </div>
                    <div id="Get_started__for_free_wp">
                        <span>Get started  for free. </span>
                    </div>
                    <div id="Group_4531_wp">
                        <div id="chpter_e_wp">
                            <span>chpter.</span>
                        </div>
                        <div id="Contact_wp">
                            <span>Contact</span>
                        </div>
                        <div id="Privacy_Policy_wp">
                            <span>Privacy Policy</span>
                        </div>
                        <div id="Group_4532_wp">
                            <div id="Layer_2_wp">
                                <div id="radio-button-off_wp">
                                    <svg className="Rectangle_2094_wp">
                                        <rect id="Rectangle_2094_wp" rx="0" ry="0" x="0" y="0" width="15" height="15">
                                        </rect>
                                    </svg>
                                </div>
                            </div>
                            <div id="c_wp">
                                <span>©</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default WelcomePage
import '../../styles/Welcome/accountverification.css';

import React, { Component } from 'react'

class AccountVerification extends Component {
  render() {
    return (
      <div>
        <div id="AccountVerification" style={{left: "0px", transformOrigin: "0px 0px", transform: "scale(0.755)"}}>
            <div id="Group_3972_av">
                <svg className="Rectangle_1922_av">
                    <rect id="Rectangle_1922_av" rx="15" ry="15" x="0" y="0" width="600" height="575">
                    </rect>
                </svg>
                <div id="All_av">
                    <span>Two Factor Authentication </span>
                </div>
                <div id="Group_3971_av">
                    <div id="Group_3858_av">
                        <div id="Group_4536_av">
                            <input id="Phonenumber_Input_av" type="number" name="MSISDN" placeholder="0723 887 536"  required 
                            />
                        </div>
                        <div id="Phone_Number_av">
                            <span>Phone Number</span>
                        </div>
                    </div>
                </div>
                <div id="Group_4529_av">
                    <div id="Group_3858_ev_av">
                        <div >
                            <input id="Input_Email_Address_av" type="text" name="verification" placeholder="Input Verification Code" required 
                            />
                        </div>
                        <div id="Input_Verification_Code_av">
                            <span>Verification Code</span>
                        </div>
                    </div>
                    <div id="Didnt_get_the_code_av">
                        <span>Didn’t get the code?</span>
                    </div>
                </div>
                <div id="Group_5317_av">
                    <div id="Group_3969_av">
                        <svg className="Rectangle_2057_e_av">
                            <linearGradient id="Rectangle_2057_e" spreadMethod="pad" x1="0.066" x2="0.79" y1="0" y2="1.3">
                                <stop offset="0" stop-color="#000" stop-opacity="1"></stop>
                                <stop offset="1" stop-color="#000" stop-opacity="1"></stop>
                            </linearGradient>
                            <rect id="Rectangle_2057_e_av" rx="7" ry="7" x="0" y="0" width="469" height="48">
                            </rect>
                        </svg>
                    </div>
                    <div id="Submit_av">
                        <span>Submit</span>
                    </div>
                </div>
            </div>
            <div id="Group_4528_av">
                <div id="Group_3168_av">
                    <div id="chpter_av">
                        <span>chpter.</span>
                    </div>
                </div>
            </div>
            <div id="Group_4541_av">
                <div id="Group_3168_e_av">
                    <div id="Registration_successful_One_mo_av">
                        <span>Registration successful!<br/>One more step . . .</span>
                    </div>
                </div>
            </div>
            <div id="Secure_your_account_with_Two_F_av">
                <span>Secure your account with Two Factor Authentication </span>
            </div>
            <div id="Group_4531_av">
                <div id="chpter_fd_av">
                    <span>chpter.</span>
                </div>
                <div id="Contact_av">
                    <span>Contact</span>
                </div>
                <div id="Privacy_Policy_av">
                    <span>Privacy Policy</span>
                </div>
                <div id="Group_4532_av">
                    <div id="Layer_2_av">
                        <div id="radio-button-off_av">
                            <svg className="Rectangle_2094_av">
                                <rect id="Rectangle_2094_av" rx="0" ry="0" x="0" y="0" width="15" height="15">
                                </rect>
                            </svg>
                        </div>
                    </div>
                    <div id="c_av">
                        <span>©</span>
                    </div>
                </div>
            </div>
            <div id="Group_4530_av">
                <div id="Request_for_new_code_av">
                    <span>Request for new code.</span>
                </div>
                <div id="Click_here_av">
                    <span>Click here.</span>
                </div>
            </div>
        </div>
      </div>
    )
  }
}

export default AccountVerification 

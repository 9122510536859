import '../../styles/Welcome/emailverification.css';
import React, { Component, useEffect, useState } from 'react'
import { NavLink, useParams } from "react-router-dom"
import { VerifyEmail } from '../../services/Welcome/PasswordService';
import { FullPageSpinner } from '../../components/spinner';
import { BouncingDotsLoader } from '../../components/loading-dots';
import MediaQuery from 'react-responsive';
import '../../styles/Components/loading-dots.css'


const EmailVerification = () => {

    let { verificationToken } = useParams();
    let token = { verificationToken }.verificationToken
    const [email_verified, setEmailVerified] = useState(false)
    const [isLoading, setIsLoading] = useState(true)


    useEffect(() => {
        verifyMerchantsEmail(token)
    }, [])

    async function verifyMerchantsEmail(verificationToken: any) {
        var verification = await VerifyEmail(verificationToken);
        setIsLoading(false)

        if (verification.success) {
            setEmailVerified(true)
        }

    }



    return (
        <>
            <MediaQuery minWidth={1001}>
                {isLoading ? (<BouncingDotsLoader></BouncingDotsLoader>
                ) : (
                    <div>
                        {email_verified ? (
                            <div id="EmailVerified_ev">
                                <div id="EmailVerified_Body_ev">
                                    <div id="continue_ev">
                                        <span>continue</span>
                                    </div>
                                    <div id="Group_4528_ev">
                                        <div id="Group_3168_ev">
                                            <div id="chpter_ev">
                                                <span>chpter.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="Group_4965_ev">
                                        <div id="Group_4541_ev">
                                            <div id="Group_3168_es_ev">
                                                <div id="Account_successfully__activate_ev">
                                                    <span>Email successfully <br />verified!</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="Thank_you_for_registering_and__ev">
                                            <span>Kindly login to access your dashboard</span>
                                        </div>
                                    </div>
                                    <div id="Group_4531_ev">
                                        <div id="chpter_ew_ev">
                                            <span>chpter.</span>
                                        </div>
                                        <div id="Contact_ev">
                                            <span>Contact</span>
                                        </div>
                                        <div id="Privacy_Policy_ev">
                                            <span>Privacy Policy</span>
                                        </div>
                                        <div id="Group_4532_ev">
                                            <div id="Layer_2_ev">
                                                <div id="radio-button-off_ev">
                                                    <svg className="Rectangle_2094_ev">
                                                        <rect id="Rectangle_2094_ev" rx="0" ry="0" x="0" y="0" width="15" height="15">
                                                        </rect>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div id="c_ev">
                                                <span>©</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="Group_3969_ev">
                                        <svg className="Rectangle_2057_e_ev">
                                            <linearGradient id="Rectangle_2057_e" spreadMethod="pad" x1="0.066" x2="0.79" y1="0" y2="1.3">
                                                <stop offset="0" stopColor="#000" stopOpacity="1"></stop>
                                                <stop offset="1" stopColor="#000" stopOpacity="1"></stop>
                                            </linearGradient>
                                            <rect id="Rectangle_2057_e_ev" rx="7" ry="7" x="0" y="0" width="356" height="62">
                                            </rect>
                                        </svg>
                                        <NavLink to={'/login'}>
                                            <div id="Take_me_home_ev">
                                                <span>Login</span>
                                            </div>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>

                        ) : (

                            <div id="EmailNotVerified_env">
                                <div id="EmailVerified_Body_ev">
                                    <div id="continue_env">
                                        <span>continue</span>
                                    </div>
                                    <div id="Group_4528_env">
                                        <div id="Group_3168_env">
                                            <div id="chpter_env">
                                                <span>chpter.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="Group_4965_env">
                                        <div id="Group_4541_env">
                                            <div id="Group_3168_es_env">
                                                <div id="Account_successfully__activate_env">
                                                    <span>Email verification code<br />expired</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="Thank_you_for_registering_and__env">
                                            <span>Click the button below<br /> to request a new verificaton code</span>
                                        </div>
                                    </div>
                                    <div id="Group_4531_env">
                                        <div id="chpter_ew_env">
                                            <span>chpter.</span>
                                        </div>
                                        <div id="Contact_env">
                                            <span>Contact</span>
                                        </div>
                                        <div id="Privacy_Policy_env">
                                            <span>Privacy Policy</span>
                                        </div>
                                        <div id="Group_4532_env">
                                            <div id="Layer_2_env">
                                                <div id="radio-button-off_env">
                                                    <svg className="Rectangle_2094_env">
                                                        <rect id="Rectangle_2094_env" rx="0" ry="0" x="0" y="0" width="15" height="15">
                                                        </rect>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div id="c_env">
                                                <span>©</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="Group_3969_env">
                                        <svg className="Rectangle_2057_e_env">
                                            <linearGradient id="Rectangle_2057_e" spreadMethod="pad" x1="0.066" x2="0.79" y1="0" y2="1.3">
                                                <stop offset="0" stopColor="#000" stopOpacity="1"></stop>
                                                <stop offset="1" stopColor="#000" stopOpacity="1"></stop>
                                            </linearGradient>
                                            <rect id="Rectangle_2057_e_env" rx="7" ry="7" x="0" y="0" width="356" height="62">
                                            </rect>
                                        </svg>
                                        <div id="Take_me_home_env">
                                            <span>Verify Email Address</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                        }
                    </div>
                )
                }
            </MediaQuery>

            <MediaQuery maxWidth={1000}>
                {isLoading ? (<BouncingDotsLoader></BouncingDotsLoader>
                ) : (
                    <div>
                        {email_verified ? (
                            <div className="mobilesuccessfulyverified_eev">
                                <div className="mobilesuccessfulyverified-inner_eev">
                                    <div className="group-frame_eev">
                                        <div className="group-frame_eev">
                                            <div className="success_eev">
                                                <div className="header_eev">
                                                    <div className="account-successfully-activate-container_eev">
                                                        chpter.
                                                    </div>
                                                </div>
                                                <div className="body_eev">
                                                    <div className="account-successfully-activate-container_eev">
                                                        <p className="email-successfully_eev">{`Email successfully `}</p>
                                                        <p className="email-successfully_eev">verified!</p>
                                                    </div>
                                                    <div className="thank-you-for_eev">
                                                        Kindly login to access your dashboard
                                                    </div>
                                                </div>
                                                <div className="submitbutton_eev">
                                                    <NavLink to="/login" className="take-me-home-wrapper_eev" style={{ textDecoration: "none" }}>
                                                        <div className="mobilesuccessfulyverified-inner1_eev">Login</div>
                                                    </NavLink>
                                                </div>
                                                <div className="footer_eev">
                                                    <div className="contact_eev">© chpter.</div>
                                                    <div className="contact_eev">Contact</div>
                                                    <div className="contact_eev">Privacy Policy</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="mobilesuccessfulyverified_eev">
                                <div className="mobilesuccessfulyverified-inner_eev">
                                    <div className="group-frame_eev">
                                        <div className="group-frame_eev">
                                            <div className="success_eev">
                                                <div className="header_eev">
                                                    <div className="account-successfully-activate-container_eev">
                                                        chpter.
                                                    </div>
                                                </div>
                                                <div className="body_eev">
                                                    <div className="account-successfully-activate-container_eev">
                                                        <p className="email-successfully_eev">{`Email verification`}</p>
                                                        <p className="email-successfully_eev">code expired</p>
                                                    </div>
                                                    <div className="thank-you-for_eev">
                                                        Click the button below to request<br /> a new verificaton code
                                                    </div>
                                                </div>
                                                <div className="submitbutton_eev">
                                                    <div className="take-me-home-wrapper_eev">
                                                        <div className="mobilesuccessfulyverified-inner_eev">Verify Email Address</div>
                                                    </div>
                                                </div>
                                                <div className="footer_eev">
                                                    <div className="contact_eev">© chpter.</div>
                                                    <div className="contact_eev">Contact</div>
                                                    <div className="contact_eev">Privacy Policy</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                        }
                    </div>
                )
                }
            </MediaQuery>

        </>

    )
}

export default EmailVerification;
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { NavLink, Navigate } from 'react-router-dom';
import '../../styles/Products/categories.css'
import '../../styles/Products/products.css'
import '../../styles/Components/NavBar.css'
import '../../styles/Components/UserRibbon.css'
import '../../styles/Components/HotLinks_Products.css'
import Wrapper from "../../components/Wrapper";
import HotlinksProducts from '../../components/HotLinks_Products';

import { Categories } from "../../models/products";
import { get_categories } from "../../services/Products/categoriesinfo";
import { Spinner } from '../../components/spinner';
import { dateFormatter, datetimeFormatter_human } from '../../utils/date_formatter';
import { get_branding_info } from '../../services/Settings/brandinginfo';
import { BouncingDotsLoaderDataTable } from '../../components/loading-dots';

import FilterableDataTable from '../../utils/data-table-search';
import { TableColumn } from 'react-data-table-component';
import MediaQuery from 'react-responsive';
import HotLinksProductsMobile from '../../components/HotLinksProductsMobile';

const Categorys = () => {
    const [Categories, setCategories] = useState([] as any);
    const [loading, setLoading] = useState(false)
    const [Branding, setBrandingInfo] = useState([] as any);
    const [filterText, setFilterText] = React.useState('');

    useEffect(() => {
        fetchCategories();
        fetchBrandingInfo();

    }, []);

    // Categories
    async function fetchCategories() {
        setLoading(true)
        //show spinner
        await get_categories().then(categories => {
            setCategories(categories);


        }).catch(error => {

        }).finally(() => {
            setLoading(false)

        })

    }

    // Branding Info
    async function fetchBrandingInfo() {
        var branding = await get_branding_info();
        setBrandingInfo(branding);
    }


    const columns: TableColumn<{ category_name: string, image: any, updated_at: string; id: string; products: any }>[] = [
        {
            name: 'NAME',
            cell: (row) => (
                <>
                    <a href={`${process.env.REACT_APP_BASE_URL}/categorydetail/${row.id}`}>

                        <div id="n_2628_e_cts">
                            {row.category_name}
                        </div>
                        <div id="Group_4712_cts">
                            <svg className="Rectangle_2151_cts">
                                <rect id="Rectangle_2151_cts" rx="7" ry="7" x="0" y="0" width="35" height="35">
                                </rect>
                            </svg>
                            {row.image === "" ?
                                (
                                    <img id="adidas-ultraboost_cts" src="media/no-image-placeholder.png" />
                                ) : (
                                    <img id="adidas-ultraboost_cts" src={"https://chpter-3-jarvis-bucket.s3.amazonaws.com/categories/" + row.image} />
                                )
                            }

                        </div>
                    </a>
                </>
            )
        },

        {
            name: '',
        },
        {
            name: 'IN STOCK',
            selector: (row) => `Products: ${row.products.length}`,
            sortable: true,
        },
        {
            name: 'UPDATED',
            selector: (row) => datetimeFormatter_human(row.updated_at),
            sortable: true,
        },
    ];

    // Filter
    const filteredItems = Categories.filter(
        (item: { category_name: string; products: { toString: () => string; }; updated_at: string; }) =>
            item.category_name?.toLowerCase().includes(filterText.toLowerCase()) ||
            item.products.toString().toLowerCase().includes(filterText.toLowerCase()) ||
            dateFormatter(item.updated_at)
                .toLowerCase()
                .includes(filterText.toLowerCase())
    );


    // Export to CSV
    const handleExport = () => {
        const columnNames = ['Category Name', 'Products', 'Date Updated'];
        const dataRows = Categories.map((category: { category_name: string, image: any, products: any, updated_at: string }) => (
            [category.category_name, category.products.length, dateFormatter(category.updated_at)].join(',')
        ));
        const csv = [columnNames.join(','), ...dataRows].join('\n');
        const csvData = new Blob([csv], { type: 'text/csv' });
        const csvUrl = URL.createObjectURL(csvData);
        const hiddenElement = document.createElement('a');
        hiddenElement.href = csvUrl;
        hiddenElement.target = '_blank';
        hiddenElement.download = 'Categories.csv';
        hiddenElement.click();
    };


    return (
        <>
            <MediaQuery minWidth={1001}>

                <main className="form-signin">
                    <div id="CategoryLanding_cts">

                        {loading ? (<BouncingDotsLoaderDataTable></BouncingDotsLoaderDataTable>
                        ) : (
                            <div id="Group_4666_prds">

                                <FilterableDataTable data={filteredItems} columns={columns} redirect_url={'categorydetail'} type={'id'} rowHeight={'wide'} scrollHeight={'660px'} />

                            </div>
                        )
                        }
                    </div>
                </main>

                <HotlinksProducts handleExport={handleExport}></HotlinksProducts>
                <Wrapper navigation="Products"></Wrapper>
            </MediaQuery>




            <MediaQuery maxWidth={1000}>
                <div className="mobilepages">
                    <div className="mobilepages-inner">
                        <div className="group-frame">
                            <div className="group-frame">
                                <div className="mobilepages1">
                                    <HotLinksProductsMobile></HotLinksProductsMobile>

                                    {/* ListView */}
                                    <div className="listview_prds_mob">
                                        <div className="frame-parent41_prds_mob">
                                            {Categories.map((category: Categories) => {
                                                return (
                                                    <NavLink to={`/categorydetail/${category.id}`} className="frame-parent42_prds_mob" style={{ textDecoration: 'none' }}>
                                                        <div className="frame-parent43_prds_mob">
                                                            <div className="group-wrapper16_prds_mob">
                                                                <div className="rectangle-parent9_prds_mob">
                                                                    <div className="group-child1_prds_mob" />
                                                                    {category.image === "" ? (
                                                                        <img
                                                                            className="adidas-ultraboost-icon_prds_mob"
                                                                            alt=""
                                                                            src="media/no-image-placeholder.png"
                                                                        />
                                                                    ) : (
                                                                        <img
                                                                            className="adidas-ultraboost-icon_prds_mob"
                                                                            alt=""
                                                                            src={"https://chpter-3-jarvis-bucket.s3.amazonaws.com/categories/" + category.image}
                                                                        />
                                                                    )}

                                                                </div>
                                                            </div>
                                                            <div className="kes-1490000-parent_prds_mob">
                                                                <div className="kes-1490000_prds_mob">{category.category_name}</div>
                                                                <div className="barry-oloo1_prds_mob">Products: {category.products.length}</div>
                                                            </div>
                                                        </div>
                                                        <div className="july-2023-wrapper_prds_mob">
                                                            <div className="july-2023_prds_mob">Available</div>
                                                        </div>
                                                    </NavLink>

                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>

        </>
    )
}

export default Categorys;


//Function for getting a file extension
export const GetFileExtension = (file:any) => {
    return file.name.split('.').pop();
}

// This function returns either audio, video, image or document depending on the passed file extension
export const GetFileType = (fileExtension:string) => {

    const audioExtensions = ['aac', 'aiff', 'amr', 'flac', 'm4a', 'mp3', 'oga', 'opus', 'wav', 'wma', 'acc', 'mp4'];
    const videoExtensions = ['3gp', 'avi', 'flv', 'm4v', 'mkv', 'mov', 'mp4', 'mpeg', 'mpg', 'ogv', 'webm', 'wmv', 'ogg'];
    const imageExtensions = ['bmp', 'gif', 'jpeg', 'jpg', 'png', 'svg', 'webp'];
    const documentExtensions = ['doc', 'docx', 'odt', 'pdf', 'rtf', 'txt', 'wpd'];
  
    if (audioExtensions.includes(fileExtension)) {
      return 'audio';
    } else if (videoExtensions.includes(fileExtension)) {
      return 'video';
    } else if (imageExtensions.includes(fileExtension)) {
      return 'image';
    } else if (documentExtensions.includes(fileExtension)) {
      return 'document';
    } else {
      return 'unknown';
    }
  };

//File Type specific to Instagram and Facebook
  export const GetFileType_Meta = (fileExtension:string) => {

    const audioExtensions = ['m4a', 'wav', 'acc', 'mp4'];
    const videoExtensions = ['mp4', 'ogg', 'avi', 'mov', 'webm'];
    const imageExtensions = ['png', 'gif', 'jpeg'];
  
    if (audioExtensions.includes(fileExtension)) {
      return 'audio';
    } else if (videoExtensions.includes(fileExtension)) {
      return 'video';
    } else if (imageExtensions.includes(fileExtension)) {
      return 'image';
    }
     else {
      return 'unknown';
    }
  };


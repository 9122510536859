import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

const HotlinksProducts = ({ handleExport }: { handleExport: () => void }) => {

    const [titleSelected, setTitleSelected] = useState(1)
    const handleNavLinkClick = (index: React.SetStateAction<number>) => {
        setTitleSelected(index);
    }


    const location = useLocation();
    const currentRoutePath = location.pathname;
    useEffect(() => {
        if (currentRoutePath === '/products') {
            setTitleSelected(1);

        } else if (currentRoutePath === '/archivedproducts') {
            setTitleSelected(2);

        } else if (currentRoutePath === '/categories') {
            setTitleSelected(3);

        } else if (currentRoutePath === '/subcategories') {
            setTitleSelected(4);
        }
    }, [currentRoutePath])


    return (
        <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse" >
            <div id="Group_7015_prds">

                <div id="Group_6978_prds">

                    <div id="Group_4972_prds">
                        <div id="Group_4117_prds" onClick={() => handleExport()}>
                            <svg className="Rectangle_19779_prds">
                                <rect id="Rectangle_19779_prds" rx="5" ry="5" x="0" y="0" width="75" height="34">
                                </rect>
                            </svg>
                            <div id="Export_prds">
                                <span>Export</span>
                            </div>
                        </div>
                        {/* <div id="Group_4973_prds">
                        {/* <div id="Group_4973_prds">
                            <div id="Group_4117_fz_prds">
                                <svg className="Rectangle_1977_f_prds">
                                    <rect id="Rectangle_1977_f_prds" rx="5" ry="5" x="0" y="0" width="75" height="34">
                                    </rect>
                                </svg>
                                <div id="Filter_prds">
                                    <span>Filter</span>
                                </div>
                            </div>
                        </div> */}
                    </div>


                    <NavLink to={'/products'} className="nav-link" style={{ textDecoration: 'none' }}>
                        <div id="All_prds" key={1} onClick={() => handleNavLinkClick(1)} className={`div ${1 === titleSelected ? 'bold-title' : ''}`} >
                            <span>Available products</span>
                        </div>
                    </NavLink>
                    <NavLink to={'/archivedproducts'} className="nav-link" style={{ textDecoration: 'none' }}>
                        <div id="All_gi_prds" key={2} onClick={() => handleNavLinkClick(2)} className={`div ${2 === titleSelected ? 'bold-title' : ''}`} >
                            <span>Archived products</span>
                        </div>
                    </NavLink>
                    <NavLink to={'/categories'} className="nav-link" style={{ textDecoration: 'none' }}>
                        <div id="All_gh_prds" key={3} onClick={() => handleNavLinkClick(3)} className={`div ${3 === titleSelected ? 'bold-title' : ''}`} >
                            <span>Categories</span>
                        </div>
                    </NavLink>
                    <NavLink to={'/subcategories'} className="nav-link" style={{ textDecoration: 'none' }}>
                        <div id="All_gj_prds" key={4} onClick={() => handleNavLinkClick(4)} className={`div ${4 === titleSelected ? 'bold-title' : ''}`} >
                            <span>Subcategories</span>
                        </div>
                    </NavLink>
            

                    <svg className="Rectangle_9_prds" viewBox="0 0 149.157 2.175">
                        <path id="Rectangle_9_prds" d="M 0.665880560874939 0 L 148.4913635253906 0 C 148.8591156005859 0 149.1572265625 0.04295705258846283 149.1572265625 0.095947265625 L 149.1572265625 2.078857421875 C 149.1572265625 2.131847620010376 148.8591156005859 2.1748046875 148.4913635253906 2.1748046875 L 0.665880560874939 2.1748046875 C 0.2981248199939728 2.1748046875 0 2.131847620010376 0 2.078857421875 L 0 0.095947265625 C 0 0.04295705258846283 0.2981248199939728 0 0.665880560874939 0 Z">
                        </path>
                    </svg>
                    <svg className="Line_gl_prds" viewBox="0 1 1400 0.5">
                        <path id="Line_gl_prds" d="M 0 1 L 1400 1">
                        </path>
                    </svg>
                </div>
                <div id="Component_77__13_prds" className="Component_77___13">
                    <svg className="Rectangle_2198_prds">
                        <rect id="Rectangle_2198_prds" rx="10" ry="10" x="0" y="0" width="1402" height="345">
                        </rect>
                    </svg>
                    <div id="Group_3168_prds">
                        <div id="configure_on_the_go_prds">
                            <span>configure on the go</span>
                        </div>
                    </div>
                    <div id="your_customers_are_waiting____prds">
                        <span>your customers are waiting . . .</span>
                    </div>
                    {/* <div id="Group_5103_prds">
                        <svg className="Rectangle_2243_prds">
                            <rect id="Rectangle_2243_prds" rx="7" ry="7" x="0" y="0" width="265" height="171">
                            </rect>
                        </svg>
                        <div id="Create_and_manage_products_and_prds">
                            <span>Create and manage products and <br />services your business has to offer.</span>
                        </div>
                        <div id="Bundle_up_products_or_services_prds">
                            <span>Bundle up products or services that<br />work well together to create product<br />baskets.</span>
                        </div>
                        <div id="Group_5354_prds">
                            <svg className="Rectangle_2057_prds">
                                <rect id="Rectangle_2057_prds" rx="3" ry="3" x="0" y="0" width="99" height="18">
                                </rect>
                            </svg>
                            <div id="Tips_on_products_prds">
                                <span>Tips on products</span>
                            </div>
                        </div>
                    </div> */}
                    <NavLink to={'/createproduct'} className="nav-link">
                        <div id="Group_5343_prds">
                            <div id="Group_5343_if_prds">
                                <div id="Start_prds">
                                    <span>Start</span>
                                </div>
                                <div id="Group_5329_prds">
                                    <div id="Group_5321_prds">
                                        <div id="Layer_2_ij_prds">
                                            <div id="arrow-forward_prds">
                                                <svg className="Rectangle_2234_prds">
                                                    <rect id="Rectangle_2234_prds" rx="0" ry="0" x="0" y="0" width="20" height="20">
                                                    </rect>
                                                </svg>
                                                <svg className="Path_9157_prds" viewBox="4 5 13.187 11.539">
                                                    <path id="Path_9157_prds" d="M 4.82415771484375 11.59326171875 L 14.59866905212402 11.59326171875 L 11.60697650909424 15.18659019470215 C 11.3156681060791 15.53706836700439 11.3636360168457 16.05734062194824 11.71411609649658 16.34865188598633 C 12.06459712982178 16.63995933532715 12.58486938476562 16.59199142456055 12.87617874145508 16.24151229858398 L 16.99696731567383 11.29656600952148 C 17.02469253540039 11.25723457336426 17.04948425292969 11.21591472625732 17.07114219665527 11.17294311523438 C 17.07114219665527 11.13173484802246 17.11234855651855 11.10700988769531 17.12883186340332 11.0658016204834 C 17.16618919372559 10.97130489349365 17.18574714660645 10.87071323394775 17.1865234375 10.76910400390625 C 17.18574714660645 10.66749477386475 17.16618919372559 10.56690406799316 17.12883186340332 10.47240734100342 C 17.12883186340332 10.4311990737915 17.08762550354004 10.40647506713867 17.07114219665527 10.36526679992676 C 17.04948425292969 10.32229518890381 17.02469253540039 10.28097534179688 16.99696731567383 10.24164390563965 L 12.87617874145508 5.296696662902832 C 12.71929550170898 5.108343601226807 12.48670864105225 4.999601364135742 12.2415771484375 4.999999523162842 C 12.04901123046875 4.999622821807861 11.86239147186279 5.066689014434814 11.71411609649658 5.189555644989014 C 11.54557037353516 5.32928991317749 11.43954944610596 5.530345439910889 11.41944885253906 5.748357772827148 C 11.39934921264648 5.966370582580566 11.46682071685791 6.183421611785889 11.60697555541992 6.35161828994751 L 14.59866905212402 9.9449462890625 L 4.82415771484375 9.9449462890625 C 4.368988037109375 9.9449462890625 4 10.31393432617188 4 10.76910400390625 C 4 11.22427368164062 4.368988037109375 11.59326171875 4.82415771484375 11.59326171875 Z">
                                                    </path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="Group_4545_prds">
                                <div id="Create_new_product_prds">
                                    <span>Create new product</span>
                                </div>
                            </div>
                            <div id="Group_5345_prds">
                                <div id="Group_7178_prds">
                                    <svg className="Rectangle_2103_prds">
                                        <rect id="Rectangle_2103_prds" rx="7" ry="7" x="0" y="0" width="171" height="175">
                                        </rect>
                                    </svg>
                                    <div id="Group_4554_prds">
                                        <div id="Adidas_Ultraboost_prds">
                                            <span>Adidas Ultraboost</span>
                                        </div>
                                    </div>
                                    <div id="USD_prds">
                                        <span>USD.</span>
                                    </div>
                                    <div id="Group_4633_prds">
                                        <img id="Ultraboost_standard_prds" src="media/Ultraboost_standard.png" />

                                        <div id="Layer_2_hx_prds">
                                            <div id="camera_prds">
                                                <svg className="Rectangle_2124_prds">
                                                    <rect id="Rectangle_2124_prds" rx="0" ry="0" x="0" y="0" width="20" height="20">
                                                    </rect>
                                                </svg>
                                                <svg className="Path_9094_prds" viewBox="2 3 16.667 15">
                                                    <path id="Path_9094_prds" d="M 16.16666603088379 6.333333015441895 L 13.66666603088379 6.333333015441895 L 13.66666603088379 5.083333015441895 C 13.66666603088379 3.932739973068237 12.73392581939697 3 11.58333301544189 3 L 9.083333015441895 3 C 7.932741165161133 3 7.000000953674316 3.932740211486816 7.000000953674316 5.083333492279053 L 7 6.333333015441895 L 4.5 6.333333015441895 C 3.119287967681885 6.333333015441895 1.999999642372131 7.452621936798096 2 8.833333969116211 L 2 15.5 C 2 16.88071250915527 3.119287967681885 18 4.5 18 L 16.16666603088379 18 C 17.54737854003906 18 18.66666603088379 16.88071250915527 18.66666603088379 15.5 L 18.66666603088379 8.833333015441895 C 18.66666603088379 7.452620983123779 17.54737854003906 6.333333015441895 16.16666603088379 6.333333015441895 Z M 8.666666030883789 5.083333015441895 C 8.666666030883789 4.853214263916016 8.853214263916016 4.666666507720947 9.083333015441895 4.666666507720947 L 11.58333301544189 4.666666507720947 C 11.81345081329346 4.666666507720947 11.99999904632568 4.853214740753174 11.99999904632568 5.083333015441895 L 11.99999904632568 6.333333015441895 L 8.666666030883789 6.333333015441895 L 8.666666030883789 5.083333015441895 Z M 17 15.5 C 17 15.96023654937744 16.62690353393555 16.33333206176758 16.16666603088379 16.33333206176758 L 4.5 16.33333206176758 C 4.039762496948242 16.33333206176758 3.666666507720947 15.96023654937744 3.666666507720947 15.5 L 3.666666507720947 8.833333015441895 C 3.666666507720947 8.373095512390137 4.039762496948242 8 4.5 8 L 16.16666603088379 8 C 16.62690353393555 8 17 8.373096466064453 17 8.833333015441895 L 17 15.5 Z">
                                                    </path>
                                                </svg>
                                                <svg className="Path_9095_prds" viewBox="8.5 10.5 5.833 5.833">
                                                    <path id="Path_9095_prds" d="M 11.41666698455811 10.5 C 9.80583667755127 10.5 8.500000953674316 11.80583572387695 8.500000953674316 13.41666603088379 C 8.500000953674316 15.02749729156494 9.80583667755127 16.33333206176758 11.41666793823242 16.33333206176758 C 13.02749824523926 16.33333206176758 14.33333396911621 15.02749729156494 14.33333396911621 13.41666603088379 C 14.33333396911621 11.80583572387695 13.02749824523926 10.5 11.41666793823242 10.5 Z M 11.41666698455811 14.66666603088379 C 10.72631072998047 14.66666698455811 10.16666698455811 14.10702323913574 10.16666698455811 13.41666698455811 C 10.16666698455811 12.72631072998047 10.72631072998047 12.16666698455811 11.41666698455811 12.16666698455811 C 12.10702323913574 12.16666698455811 12.66666698455811 12.72631072998047 12.66666698455811 13.41666698455811 C 12.66666698455811 14.10702323913574 12.10702323913574 14.66666698455811 11.41666698455811 14.66666698455811 Z">
                                                    </path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <svg className="Rectangle_2409_h_prds">
                                        <linearGradient id="Rectangle_2409_h_prds" spreadMethod="pad" x1="0" x2="1.449" y1="-0.72" y2="3.053">
                                            <stop offset="0" stop-color="#747474" stop-opacity="1"></stop>
                                            <stop offset="1" stop-color="#5f5f5f" stop-opacity="1"></stop>
                                        </linearGradient>
                                        <rect id="Rectangle_2409_h_prds" rx="3.700000047683716" ry="3.700000047683716" x="0" y="0" width="54.704" height="7.4">
                                        </rect>
                                    </svg>
                                    <svg className="Rectangle_2411_h_prds">
                                        <linearGradient id="Rectangle_2411_h_prds" spreadMethod="pad" x1="0" x2="1.449" y1="-0.72" y2="3.053">
                                            <stop offset="0" stop-color="#cbc9c9" stop-opacity="1"></stop>
                                            <stop offset="1" stop-color="#e2e2e2" stop-opacity="1"></stop>
                                        </linearGradient>
                                        <rect id="Rectangle_2411_h_prds" rx="3.700000047683716" ry="3.700000047683716" x="0" y="0" width="40.592" height="7.4">
                                        </rect>
                                    </svg>
                                    <svg className="Rectangle_2465_h_prds">
                                        <linearGradient id="Rectangle_2465_h_prds" spreadMethod="pad" x1="0" x2="1.449" y1="-0.72" y2="3.053">
                                            <stop offset="0" stop-color="#cbc9c9" stop-opacity="1"></stop>
                                            <stop offset="1" stop-color="#e2e2e2" stop-opacity="1"></stop>
                                        </linearGradient>
                                        <rect id="Rectangle_2465_h_prds" rx="3.700000047683716" ry="3.700000047683716" x="0" y="0" width="83.592" height="7.4">
                                        </rect>
                                    </svg>
                                    <svg className="Rectangle_2466_h_prds">
                                        <linearGradient id="Rectangle_2466_h_prds" spreadMethod="pad" x1="0" x2="1.449" y1="-0.72" y2="3.053">
                                            <stop offset="0" stop-color="#cbc9c9" stop-opacity="1"></stop>
                                            <stop offset="1" stop-color="#e2e2e2" stop-opacity="1"></stop>
                                        </linearGradient>
                                        <rect id="Rectangle_2466_h_prds" rx="3.700000047683716" ry="3.700000047683716" x="0" y="0" width="131.592" height="7.4">
                                        </rect>
                                    </svg>
                                </div>

                                <div id="Group_7180_prds">
                                    <svg className="Rectangle_2241_prds">
                                        <rect id="Rectangle_2241_prds" rx="7" ry="7" x="0" y="0" width="129" height="140">
                                        </rect>
                                    </svg>
                                    <div id="Group_4562_prds">
                                        <div id="powered_by_prds">
                                            <span>powered by</span>
                                        </div>
                                        <div id="chpter_prds">
                                            <span>chpter.</span>
                                        </div>
                                    </div>
                                    <div id="Group_4707_prds">
                                        <svg className="Rectangle_2148_prds">
                                            <rect id="Rectangle_2148_prds" rx="1.5" ry="1.5" x="0" y="0" width="97" height="3">
                                            </rect>
                                        </svg>
                                        <svg className="Ellipse_769_prds">
                                            <ellipse id="Ellipse_769_prds" rx="1" ry="1" cx="1" cy="1">
                                            </ellipse>
                                        </svg>
                                        <svg className="Ellipse_770_prds">
                                            <ellipse id="Ellipse_770_prds" rx="1" ry="1" cx="1" cy="1">
                                            </ellipse>
                                        </svg>
                                        <svg className="Ellipse_771_prds">
                                            <ellipse id="Ellipse_771_prds" rx="1" ry="1" cx="1" cy="1">
                                            </ellipse>
                                        </svg>
                                    </div>
                                    <div id="The_most_comfortable_running_s_prds">
                                        <span>The most comfortable running shoe.</span>
                                    </div>
                                    <div id="Group_5114_prds">
                                        <div id="Group_5366_prds">
                                            <div id="Group_3969_prds">
                                                <svg className="Rectangle_2057_ip_prds">
                                                    <linearGradient id="Rectangle_2057_ip_prds" spreadMethod="pad" x1="0.066" x2="0.79" y1="0" y2="1.3">
                                                        <stop offset="0" stop-color="#fff" stop-opacity="1"></stop>
                                                        <stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
                                                    </linearGradient>
                                                    <rect id="Rectangle_2057_ip_prds" rx="5" ry="5" x="0" y="0" width="93" height="15">
                                                    </rect>
                                                </svg>
                                            </div>
                                            <div id="Group_5367_prds">
                                                <div id="chptercheckout_prds">
                                                    <span>chpter.checkout</span>
                                                </div>
                                                <div id="Layer_2_is_prds">
                                                    <div id="lock_prds">
                                                        <svg className="Rectangle_2108_prds">
                                                            <rect id="Rectangle_2108_prds" rx="0" ry="0" x="0" y="0" width="8" height="8">
                                                            </rect>
                                                        </svg>
                                                        <svg className="Path_9082_prds" viewBox="4 2.11 3.765 5.4">
                                                            <path id="Path_9082_prds" d="M 7.059389114379883 3.709123373031616 L 6.824050903320312 3.709123373031616 L 6.824050903320312 3.195992708206177 C 6.824050903320312 2.59621524810791 6.402594566345215 2.110000133514404 5.88270092010498 2.110000133514404 C 5.362807273864746 2.110000133514404 4.941349983215332 2.59621524810791 4.941349983215332 3.195992708206177 L 4.941349983215332 3.709123373031616 L 4.706012725830078 3.709123373031616 C 4.316092491149902 3.709123373031616 4 4.073785305023193 4 4.523617744445801 L 4 6.695601463317871 C 4 7.14543342590332 4.316092491149902 7.510095596313477 4.706012725830078 7.510095596313477 L 7.059389114379883 7.510095596313477 C 7.449308395385742 7.510095596313477 7.765400886535645 7.14543342590332 7.765400886535645 6.695601463317871 L 7.765400886535645 4.523617267608643 C 7.765400886535645 4.073785305023193 7.449308395385742 3.709123373031616 7.059389114379883 3.709123373031616 Z M 5.412025451660156 3.195992708206177 C 5.405434608459473 2.888103723526001 5.615849494934082 2.632007837295532 5.88270092010498 2.623131513595581 C 6.149552345275879 2.632007837295532 6.359967231750488 2.888103723526001 6.353375434875488 3.195992708206177 L 6.353375434875488 3.709123373031616 L 5.412025451660156 3.709123373031616 L 5.412025451660156 3.195992708206177 Z M 7.29472541809082 6.695601463317871 C 7.29472541809082 6.845545291900635 7.189361572265625 6.967099189758301 7.059389114379883 6.967099189758301 L 4.706012725830078 6.967099189758301 C 4.57603931427002 6.967099189758301 4.470674991607666 6.845545291900635 4.470674991607666 6.695601463317871 L 4.470674991607666 4.523617267608643 C 4.470674991607666 4.373673439025879 4.57603931427002 4.252120018005371 4.706012725830078 4.252120018005371 L 7.059389114379883 4.252120018005371 C 7.189361572265625 4.252120018005371 7.29472541809082 4.373673439025879 7.29472541809082 4.523617267608643 L 7.29472541809082 6.695601463317871 Z">
                                                            </path>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="Group_7179_prds">
                                        <div id="Layer_2_ix_prds">
                                            <div id="image_prds">
                                                <svg className="Rectangle_2245_prds">
                                                    <rect id="Rectangle_2245_prds" rx="0" ry="0" x="0" y="0" width="35" height="35">
                                                    </rect>
                                                </svg>
                                                <svg className="Path_9163_prds" viewBox="3 3 25.996 25.996">
                                                    <path id="Path_9163_prds" d="M 24.6632080078125 3 L 7.3326416015625 3 C 4.939789295196533 3 2.999999523162842 4.939790725708008 3 7.332642555236816 L 3 24.6632080078125 C 3 27.05606079101562 4.939789295196533 28.995849609375 7.332640647888184 28.995849609375 L 24.6632080078125 28.995849609375 C 27.05606079101562 28.995849609375 28.995849609375 27.05606079101562 28.995849609375 24.6632080078125 L 28.995849609375 7.3326416015625 C 28.995849609375 4.939789295196533 27.05606079101562 3 24.6632080078125 3 Z M 7.3326416015625 5.888427734375 L 24.6632080078125 5.888427734375 C 25.46082496643066 5.888427734375 26.107421875 6.535024166107178 26.107421875 7.3326416015625 L 26.107421875 19.40626907348633 L 21.48593711853027 15.46356391906738 C 20.00921058654785 14.24849128723145 17.87903213500977 14.24848937988281 16.40230560302734 15.46356391906738 L 5.888427734375 24.22994422912598 L 5.888427734375 7.3326416015625 C 5.888427734375 6.535024166107178 6.535024166107178 5.888427734375 7.3326416015625 5.888427734375 Z M 24.6632080078125 26.107421875 L 8.141401290893555 26.107421875 L 18.25089836120605 17.6732120513916 C 18.64965057373047 17.37712669372559 19.19526481628418 17.37712669372559 19.59401893615723 17.6732120513916 L 26.107421875 23.218994140625 L 26.107421875 24.6632080078125 C 26.107421875 25.46082496643066 25.46082496643066 26.107421875 24.6632080078125 26.107421875 Z">
                                                    </path>
                                                </svg>
                                                <svg className="Ellipse_780_prds">
                                                    <ellipse id="Ellipse_780_prds" rx="2.16632080078125" ry="2.16632080078125" cx="2.16632080078125" cy="2.16632080078125">
                                                    </ellipse>
                                                </svg>
                                            </div>
                                        </div>
                                        <svg className="Rectangle_2409_ja_prds">
                                            <linearGradient id="Rectangle_2409_ja_prds" spreadMethod="pad" x1="0" x2="1.449" y1="-0.72" y2="3.053">
                                                <stop offset="0" stop-color="#747474" stop-opacity="1"></stop>
                                                <stop offset="1" stop-color="#5f5f5f" stop-opacity="1"></stop>
                                            </linearGradient>
                                            <rect id="Rectangle_2409_ja_prds" rx="3.700000047683716" ry="3.700000047683716" x="0" y="0" width="62.408" height="7.4">
                                            </rect>
                                        </svg>
                                        <svg className="Rectangle_2467_i_prds">
                                            <linearGradient id="Rectangle_2467_i_prds" spreadMethod="pad" x1="0" x2="1.449" y1="-0.72" y2="3.053">
                                                <stop offset="0" stop-color="#cbc9c9" stop-opacity="1"></stop>
                                                <stop offset="1" stop-color="#5f5f5f" stop-opacity="1"></stop>
                                            </linearGradient>
                                            <rect id="Rectangle_2467_i_prds" rx="3.700000047683716" ry="3.700000047683716" x="0" y="0" width="115.592" height="7.4">
                                            </rect>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </NavLink>
                    <NavLink to={'/createproductcategory'} className="nav-link">
                        <div id="Group_6972_prds">
                            <div id="Create_product_category_prds">
                                <span>Create category</span>
                            </div>
                            <div id="Group_6971_prds">
                                <div id="Start_iq_prds">
                                    <span>Start</span>
                                </div>
                                <div id="Group_5329_ir_prds">
                                    <div id="Group_5321_is_prds">
                                        <div id="Layer_2_it_prds">
                                            <div id="arrow-forward_iu_prds">
                                                <svg className="Rectangle_2234_iv_prds">
                                                    <rect id="Rectangle_2234_iv_prds" rx="0" ry="0" x="0" y="0" width="20" height="20">
                                                    </rect>
                                                </svg>
                                                <svg className="Path_9157_iw_prds" viewBox="4 5 13.187 11.539">
                                                    <path id="Path_9157_iw_prds" d="M 4.82415771484375 11.59326171875 L 14.59866905212402 11.59326171875 L 11.60697650909424 15.18659019470215 C 11.3156681060791 15.53706836700439 11.3636360168457 16.05734062194824 11.71411609649658 16.34865188598633 C 12.06459712982178 16.63995933532715 12.58486938476562 16.59199142456055 12.87617874145508 16.24151229858398 L 16.99696731567383 11.29656600952148 C 17.02469253540039 11.25723457336426 17.04948425292969 11.21591472625732 17.07114219665527 11.17294311523438 C 17.07114219665527 11.13173484802246 17.11234855651855 11.10700988769531 17.12883186340332 11.0658016204834 C 17.16618919372559 10.97130489349365 17.18574714660645 10.87071323394775 17.1865234375 10.76910400390625 C 17.18574714660645 10.66749477386475 17.16618919372559 10.56690406799316 17.12883186340332 10.47240734100342 C 17.12883186340332 10.4311990737915 17.08762550354004 10.40647506713867 17.07114219665527 10.36526679992676 C 17.04948425292969 10.32229518890381 17.02469253540039 10.28097534179688 16.99696731567383 10.24164390563965 L 12.87617874145508 5.296696662902832 C 12.71929550170898 5.108343601226807 12.48670864105225 4.999601364135742 12.2415771484375 4.999999523162842 C 12.04901123046875 4.999622821807861 11.86239147186279 5.066689014434814 11.71411609649658 5.189555644989014 C 11.54557037353516 5.32928991317749 11.43954944610596 5.530345439910889 11.41944885253906 5.748357772827148 C 11.39934921264648 5.966370582580566 11.46682071685791 6.183421611785889 11.60697555541992 6.35161828994751 L 14.59866905212402 9.9449462890625 L 4.82415771484375 9.9449462890625 C 4.368988037109375 9.9449462890625 4 10.31393432617188 4 10.76910400390625 C 4 11.22427368164062 4.368988037109375 11.59326171875 4.82415771484375 11.59326171875 Z">
                                                    </path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="Group_7185_prds">
                                <div id="Group_7184_prds">
                                    <svg className="Rectangle_2144_prds">
                                        <rect id="Rectangle_2144_prds" rx="7" ry="7" x="0" y="0" width="276" height="154">
                                        </rect>
                                    </svg>
                                    <div id="n_2628_jz_prds">
                                        <span>Quantity 1</span>
                                    </div>
                                    <div id="n_2628_j_prds">
                                        <span>Quantity 1</span>
                                    </div>
                                    <div id="n_2628_ka_prds">
                                        <span>Quantity 1</span>
                                    </div>
                                    <div id="Includes_prds">
                                        <span>Includes:</span>
                                    </div>
                                    <div id="Group_7182_prds">
                                        <div id="Group_4940_j_prds">
                                            <svg className="Rectangle_2151_j_prds">
                                                <rect id="Rectangle_2151_j_prds" rx="5" ry="5" x="0" y="0" width="29.537" height="29.537">
                                                </rect>
                                            </svg>
                                        </div>
                                        <div id="Group_7183_prds">
                                            <svg className="Rectangle_2409_ka_prds">
                                                <linearGradient id="Rectangle_2409_ka_prds" spreadMethod="pad" x1="0" x2="1.449" y1="-0.72" y2="3.053">
                                                    <stop offset="0" stop-color="#747474" stop-opacity="1"></stop>
                                                    <stop offset="1" stop-color="#5f5f5f" stop-opacity="1"></stop>
                                                </linearGradient>
                                                <rect id="Rectangle_2409_ka_prds" rx="3.700000047683716" ry="3.700000047683716" x="0" y="0" width="85.592" height="7.4">
                                                </rect>
                                            </svg>
                                            <svg className="Rectangle_2411_ka_prds">
                                                <linearGradient id="Rectangle_2411_ka_prds" spreadMethod="pad" x1="0" x2="1.449" y1="-0.72" y2="3.053">
                                                    <stop offset="0" stop-color="#cbc9c9" stop-opacity="1"></stop>
                                                    <stop offset="1" stop-color="#e2e2e2" stop-opacity="1"></stop>
                                                </linearGradient>
                                                <rect id="Rectangle_2411_ka_prds" rx="3.700000047683716" ry="3.700000047683716" x="0" y="0" width="40.592" height="7.4">
                                                </rect>
                                            </svg>
                                        </div>
                                    </div>
                                    <svg className="Rectangle_2468_kc_prds">
                                        <linearGradient id="Rectangle_2468_kc_prds" spreadMethod="pad" x1="0" x2="1.449" y1="-0.72" y2="3.053">
                                            <stop offset="0" stop-color="#cbc9c9" stop-opacity="1"></stop>
                                            <stop offset="1" stop-color="#e2e2e2" stop-opacity="1"></stop>
                                        </linearGradient>
                                        <rect id="Rectangle_2468_kc_prds" rx="3.700000047683716" ry="3.700000047683716" x="0" y="0" width="85.592" height="7.4">
                                        </rect>
                                    </svg>
                                    <svg className="Rectangle_2469_ke_prds">
                                        <linearGradient id="Rectangle_2469_ke_prds" spreadMethod="pad" x1="0" x2="1.449" y1="-0.72" y2="3.053">
                                            <stop offset="0" stop-color="#cbc9c9" stop-opacity="1"></stop>
                                            <stop offset="1" stop-color="#e2e2e2" stop-opacity="1"></stop>
                                        </linearGradient>
                                        <rect id="Rectangle_2469_ke_prds" rx="3.700000047683716" ry="3.700000047683716" x="0" y="0" width="134.521" height="7.4">
                                        </rect>
                                    </svg>
                                    <svg className="Rectangle_2470_kg_prds">
                                        <linearGradient id="Rectangle_2470_kg_prds" spreadMethod="pad" x1="0" x2="1.449" y1="-0.72" y2="3.053">
                                            <stop offset="0" stop-color="#cbc9c9" stop-opacity="1"></stop>
                                            <stop offset="1" stop-color="#e2e2e2" stop-opacity="1"></stop>
                                        </linearGradient>
                                        <rect id="Rectangle_2470_kg_prds" rx="3.700000047683716" ry="3.700000047683716" x="0" y="0" width="111.201" height="7.4">
                                        </rect>
                                    </svg>
                                </div>
                                <div id="Group_6967_prds">
                                    <svg className="Rectangle_2103_ki_prds">
                                        <rect id="Rectangle_2103_ki_prds" rx="4" ry="4" x="0" y="0" width="138.396" height="69.876">
                                        </rect>
                                    </svg>
                                    <div id="Adidas_Ultraboost_kj_prds">
                                        <span>Adidas Ultraboost</span>
                                    </div>
                                    <div id="Group_6966_kk_prds">
                                        <svg className="Rectangle_2397_km_prds">
                                            <linearGradient id="Rectangle_2397_km_prds" spreadMethod="pad" x1="0" x2="1.449" y1="-0.72" y2="3.053">
                                                <stop offset="0" stop-color="#bcbcbc" stop-opacity="1"></stop>
                                                <stop offset="1" stop-color="#e2e2e2" stop-opacity="1"></stop>
                                            </linearGradient>
                                            <rect id="Rectangle_2397_km_prds" rx="2.609750270843506" ry="2.609750270843506" x="0" y="0" width="68.269" height="5.22">
                                            </rect>
                                        </svg>
                                        <svg className="Rectangle_2402_ko_prds">
                                            <linearGradient id="Rectangle_2402_ko_prds" spreadMethod="pad" x1="0" x2="1.449" y1="-0.72" y2="3.053">
                                                <stop offset="0" stop-color="#cbc9c9" stop-opacity="1"></stop>
                                                <stop offset="1" stop-color="#e2e2e2" stop-opacity="1"></stop>
                                            </linearGradient>
                                            <rect id="Rectangle_2402_ko_prds" rx="2.609750270843506" ry="2.609750270843506" x="0" y="0" width="49.127" height="5.22">
                                            </rect>
                                        </svg>
                                        <svg className="Rectangle_2403_kq_prds">
                                            <linearGradient id="Rectangle_2403_kq_prds" spreadMethod="pad" x1="0" x2="1.449" y1="-0.72" y2="3.053">
                                                <stop offset="0" stop-color="#cbc9c9" stop-opacity="1"></stop>
                                                <stop offset="1" stop-color="#e2e2e2" stop-opacity="1"></stop>
                                            </linearGradient>
                                            <rect id="Rectangle_2403_kq_prds" rx="2.57495379447937" ry="2.57495379447937" x="0" y="0" width="28.249" height="5.15">
                                            </rect>
                                        </svg>
                                    </div>
                                    <div id="Group_6573_kr_prds">
                                        {/* <svg className="Rectangle_2210_ks_prds">
                                            <rect id="Rectangle_2210_ks_prds" rx="7" ry="7" x="0" y="0" width="33.794" height="33.551">
                                            </rect>
                                        </svg>
                                        <img id="Screenshot_2022-07-25_at_16425_prds" src="media/Screenshot_2022-07-25_at_16425.png"/> */}

                                        <div id="Layer_2_jr_prds">
                                            <div id="image_js_prds">
                                                <svg className="Rectangle_2245_jt_prds">
                                                    <rect id="Rectangle_2245_jt_prds" rx="0" ry="0" x="0" y="0" width="25" height="25">
                                                    </rect>
                                                </svg>
                                                <svg className="Path_9163_ju_prds" viewBox="3 3 19.166 19.166">
                                                    <path id="Path_9163_ju_prds" d="M 18.97178077697754 3 L 6.194355964660645 3 C 4.430161476135254 3 2.999999761581421 4.43016242980957 3 6.194356918334961 L 3 18.97178077697754 C 3 20.73597526550293 4.430161476135254 22.1661376953125 6.194355010986328 22.1661376953125 L 18.97178077697754 22.1661376953125 C 20.73597526550293 22.1661376953125 22.1661376953125 20.73597526550293 22.1661376953125 18.97178077697754 L 22.1661376953125 6.194355964660645 C 22.1661376953125 4.430161476135254 20.73597526550293 3 18.97178077697754 3 Z M 6.194355964660645 5.129570960998535 L 18.97178077697754 5.129570960998535 C 19.55984497070312 5.129570960998535 20.03656578063965 5.606290817260742 20.03656578063965 6.194355964660645 L 20.03656578063965 15.09596157073975 L 16.62925148010254 12.18909645080566 C 15.54049682617188 11.29325199127197 13.9699649810791 11.29325008392334 12.88120937347412 12.18909645080566 L 5.129570960998535 18.65234565734863 L 5.129570960998535 6.194355964660645 C 5.129570960998535 5.606290817260742 5.606290817260742 5.129570960998535 6.194355964660645 5.129570960998535 Z M 18.97178077697754 20.03656578063965 L 6.79063606262207 20.03656578063965 L 14.24413394927979 13.81821918487549 C 14.53812408447266 13.59992218017578 14.94039344787598 13.59992218017578 15.23438549041748 13.81821918487549 L 20.03656578063965 17.90699577331543 L 20.03656578063965 18.97178077697754 C 20.03656578063965 19.55984497070312 19.55984497070312 20.03656578063965 18.97178077697754 20.03656578063965 Z">
                                                    </path>
                                                </svg>
                                                <svg className="Ellipse_780_jv_prds">
                                                    <ellipse id="Ellipse_780_jv_prds" rx="1.5971779823303223" ry="1.5971779823303223" cx="1.5971779823303223" cy="1.5971779823303223">
                                                    </ellipse>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="Group_6968_prds">
                                    <svg className="Rectangle_2103_jg_prds">
                                        <rect id="Rectangle_2103_jg_prds" rx="4" ry="4" x="0" y="0" width="138.396" height="69.876">
                                        </rect>
                                    </svg>
                                    <div id="Adidas_Ultraboost_jh_prds">
                                        <span>Adidas Ultraboost</span>
                                    </div>
                                    <div id="Group_6966_prds">
                                        <svg className="Rectangle_2397_jk_prds">
                                            <linearGradient id="Rectangle_2397_jk_prds" spreadMethod="pad" x1="0" x2="1.449" y1="-0.72" y2="3.053">
                                                <stop offset="0" stop-color="#bcbcbc" stop-opacity="1"></stop>
                                                <stop offset="1" stop-color="#e2e2e2" stop-opacity="1"></stop>
                                            </linearGradient>
                                            <rect id="Rectangle_2397_jk_prds" rx="2.609750270843506" ry="2.609750270843506" x="0" y="0" width="68.269" height="5.22">
                                            </rect>
                                        </svg>
                                        <svg className="Rectangle_2402_jm_prds">
                                            <linearGradient id="Rectangle_2402_jm_prds" spreadMethod="pad" x1="0" x2="1.449" y1="-0.72" y2="3.053">
                                                <stop offset="0" stop-color="#cbc9c9" stop-opacity="1"></stop>
                                                <stop offset="1" stop-color="#e2e2e2" stop-opacity="1"></stop>
                                            </linearGradient>
                                            <rect id="Rectangle_2402_jm_prds" rx="2.609750270843506" ry="2.609750270843506" x="0" y="0" width="49.127" height="5.22">
                                            </rect>
                                        </svg>
                                        <svg className="Rectangle_2403_jo_prds">
                                            <linearGradient id="Rectangle_2403_jo_prds" spreadMethod="pad" x1="0" x2="1.449" y1="-0.72" y2="3.053">
                                                <stop offset="0" stop-color="#cbc9c9" stop-opacity="1"></stop>
                                                <stop offset="1" stop-color="#e2e2e2" stop-opacity="1"></stop>
                                            </linearGradient>
                                            <rect id="Rectangle_2403_jo_prds" rx="2.57495379447937" ry="2.57495379447937" x="0" y="0" width="28.249" height="5.15">
                                            </rect>
                                        </svg>
                                    </div>
                                    <div id="Group_6573_prds">
                                        <svg className="Rectangle_2210_prds">
                                            <rect id="Rectangle_2210_prds" rx="7" ry="7" x="0" y="0" width="33.794" height="33.551">
                                            </rect>
                                        </svg>
                                        <div id="Layer_2_jr_prds">
                                            <div id="image_js_prds">
                                                <svg className="Rectangle_2245_jt_prds">
                                                    <rect id="Rectangle_2245_jt_prds" rx="0" ry="0" x="0" y="0" width="25" height="25">
                                                    </rect>
                                                </svg>
                                                <svg className="Path_9163_ju_prds" viewBox="3 3 19.166 19.166">
                                                    <path id="Path_9163_ju_prds" d="M 18.97178077697754 3 L 6.194355964660645 3 C 4.430161476135254 3 2.999999761581421 4.43016242980957 3 6.194356918334961 L 3 18.97178077697754 C 3 20.73597526550293 4.430161476135254 22.1661376953125 6.194355010986328 22.1661376953125 L 18.97178077697754 22.1661376953125 C 20.73597526550293 22.1661376953125 22.1661376953125 20.73597526550293 22.1661376953125 18.97178077697754 L 22.1661376953125 6.194355964660645 C 22.1661376953125 4.430161476135254 20.73597526550293 3 18.97178077697754 3 Z M 6.194355964660645 5.129570960998535 L 18.97178077697754 5.129570960998535 C 19.55984497070312 5.129570960998535 20.03656578063965 5.606290817260742 20.03656578063965 6.194355964660645 L 20.03656578063965 15.09596157073975 L 16.62925148010254 12.18909645080566 C 15.54049682617188 11.29325199127197 13.9699649810791 11.29325008392334 12.88120937347412 12.18909645080566 L 5.129570960998535 18.65234565734863 L 5.129570960998535 6.194355964660645 C 5.129570960998535 5.606290817260742 5.606290817260742 5.129570960998535 6.194355964660645 5.129570960998535 Z M 18.97178077697754 20.03656578063965 L 6.79063606262207 20.03656578063965 L 14.24413394927979 13.81821918487549 C 14.53812408447266 13.59992218017578 14.94039344787598 13.59992218017578 15.23438549041748 13.81821918487549 L 20.03656578063965 17.90699577331543 L 20.03656578063965 18.97178077697754 C 20.03656578063965 19.55984497070312 19.55984497070312 20.03656578063965 18.97178077697754 20.03656578063965 Z">
                                                    </path>
                                                </svg>
                                                <svg className="Ellipse_780_jv_prds">
                                                    <ellipse id="Ellipse_780_jv_prds" rx="1.5971779823303223" ry="1.5971779823303223" cx="1.5971779823303223" cy="1.5971779823303223">
                                                    </ellipse>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="Group_6969_prds">
                                    <svg className="Rectangle_2103_kv_prds">
                                        <rect id="Rectangle_2103_kv_prds" rx="4" ry="4" x="0" y="0" width="138.396" height="69.876">
                                        </rect>
                                    </svg>
                                    <div id="Adidas_Ultraboost_kw_prds">
                                        <span>Adidas Ultraboost</span>
                                    </div>
                                    <div id="Group_6966_kx_prds">
                                        <svg className="Rectangle_2397_kz_prds">
                                            <linearGradient id="Rectangle_2397_kz_prds" spreadMethod="pad" x1="0" x2="1.449" y1="-0.72" y2="3.053">
                                                <stop offset="0" stop-color="#bcbcbc" stop-opacity="1"></stop>
                                                <stop offset="1" stop-color="#e2e2e2" stop-opacity="1"></stop>
                                            </linearGradient>
                                            <rect id="Rectangle_2397_kz_prds" rx="2.609750270843506" ry="2.609750270843506" x="0" y="0" width="68.269" height="5.22">
                                            </rect>
                                        </svg>
                                        <svg className="Rectangle_2402_la_prds">
                                            <linearGradient id="Rectangle_2402_la_prds" spreadMethod="pad" x1="0" x2="1.449" y1="-0.72" y2="3.053">
                                                <stop offset="0" stop-color="#cbc9c9" stop-opacity="1"></stop>
                                                <stop offset="1" stop-color="#e2e2e2" stop-opacity="1"></stop>
                                            </linearGradient>
                                            <rect id="Rectangle_2402_la_prds" rx="2.609750270843506" ry="2.609750270843506" x="0" y="0" width="49.127" height="5.22">
                                            </rect>
                                        </svg>
                                        <svg className="Rectangle_2403_la_prds">
                                            <linearGradient id="Rectangle_2403_la_prds" spreadMethod="pad" x1="0" x2="1.449" y1="-0.72" y2="3.053">
                                                <stop offset="0" stop-color="#cbc9c9" stop-opacity="1"></stop>
                                                <stop offset="1" stop-color="#e2e2e2" stop-opacity="1"></stop>
                                            </linearGradient>
                                            <rect id="Rectangle_2403_la_prds" rx="2.57495379447937" ry="2.57495379447937" x="0" y="0" width="28.249" height="5.15">
                                            </rect>
                                        </svg>
                                    </div>
                                    <div id="Group_6573_k_prds">
                                        <svg className="Rectangle_2210_k_prds">
                                            <rect id="Rectangle_2210_k_prds" rx="7" ry="7" x="0" y="0" width="33.794" height="33.551">
                                            </rect>
                                        </svg>
                                        <div id="Layer_2_k_prds">
                                            <div id="image_k_prds">
                                                <svg className="Rectangle_2245_k_prds">
                                                    <rect id="Rectangle_2245_k_prds" rx="0" ry="0" x="0" y="0" width="25" height="25">
                                                    </rect>
                                                </svg>
                                                <svg className="Path_9163_k_prds" viewBox="3 3 19.166 19.166">
                                                    <path id="Path_9163_k_prds" d="M 18.97178077697754 3 L 6.194355964660645 3 C 4.430161476135254 3 2.999999761581421 4.43016242980957 3 6.194356918334961 L 3 18.97178077697754 C 3 20.73597526550293 4.430161476135254 22.1661376953125 6.194355010986328 22.1661376953125 L 18.97178077697754 22.1661376953125 C 20.73597526550293 22.1661376953125 22.1661376953125 20.73597526550293 22.1661376953125 18.97178077697754 L 22.1661376953125 6.194355964660645 C 22.1661376953125 4.430161476135254 20.73597526550293 3 18.97178077697754 3 Z M 6.194355964660645 5.129570960998535 L 18.97178077697754 5.129570960998535 C 19.55984497070312 5.129570960998535 20.03656578063965 5.606290817260742 20.03656578063965 6.194355964660645 L 20.03656578063965 15.09596157073975 L 16.62925148010254 12.18909645080566 C 15.54049682617188 11.29325199127197 13.9699649810791 11.29325008392334 12.88120937347412 12.18909645080566 L 5.129570960998535 18.65234565734863 L 5.129570960998535 6.194355964660645 C 5.129570960998535 5.606290817260742 5.606290817260742 5.129570960998535 6.194355964660645 5.129570960998535 Z M 18.97178077697754 20.03656578063965 L 6.79063606262207 20.03656578063965 L 14.24413394927979 13.81821918487549 C 14.53812408447266 13.59992218017578 14.94039344787598 13.59992218017578 15.23438549041748 13.81821918487549 L 20.03656578063965 17.90699577331543 L 20.03656578063965 18.97178077697754 C 20.03656578063965 19.55984497070312 19.55984497070312 20.03656578063965 18.97178077697754 20.03656578063965 Z">
                                                    </path>
                                                </svg>
                                                <svg className="Ellipse_780_la_prds">
                                                    <ellipse id="Ellipse_780_la_prds" rx="1.5971779823303223" ry="1.5971779823303223" cx="1.5971779823303223" cy="1.5971779823303223">
                                                    </ellipse>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </NavLink>
                    <NavLink to={'/createproductsubcategory'} className="nav-link">
                        <div id="Group_6972_1_prds">
                            <div id="Create_product_category_prds">
                                <span>Create subcategory</span>
                            </div>
                            <div id="Group_6971_prds">
                                <div id="Start_iq_prds">
                                    <span>Start</span>
                                </div>
                                <div id="Group_5329_ir_prds">
                                    <div id="Group_5321_is_prds">
                                        <div id="Layer_2_it_prds">
                                            <div id="arrow-forward_iu_prds">
                                                <svg className="Rectangle_2234_iv_prds">
                                                    <rect id="Rectangle_2234_iv_prds" rx="0" ry="0" x="0" y="0" width="20" height="20">
                                                    </rect>
                                                </svg>
                                                <svg className="Path_9157_iw_prds" viewBox="4 5 13.187 11.539">
                                                    <path id="Path_9157_iw_prds" d="M 4.82415771484375 11.59326171875 L 14.59866905212402 11.59326171875 L 11.60697650909424 15.18659019470215 C 11.3156681060791 15.53706836700439 11.3636360168457 16.05734062194824 11.71411609649658 16.34865188598633 C 12.06459712982178 16.63995933532715 12.58486938476562 16.59199142456055 12.87617874145508 16.24151229858398 L 16.99696731567383 11.29656600952148 C 17.02469253540039 11.25723457336426 17.04948425292969 11.21591472625732 17.07114219665527 11.17294311523438 C 17.07114219665527 11.13173484802246 17.11234855651855 11.10700988769531 17.12883186340332 11.0658016204834 C 17.16618919372559 10.97130489349365 17.18574714660645 10.87071323394775 17.1865234375 10.76910400390625 C 17.18574714660645 10.66749477386475 17.16618919372559 10.56690406799316 17.12883186340332 10.47240734100342 C 17.12883186340332 10.4311990737915 17.08762550354004 10.40647506713867 17.07114219665527 10.36526679992676 C 17.04948425292969 10.32229518890381 17.02469253540039 10.28097534179688 16.99696731567383 10.24164390563965 L 12.87617874145508 5.296696662902832 C 12.71929550170898 5.108343601226807 12.48670864105225 4.999601364135742 12.2415771484375 4.999999523162842 C 12.04901123046875 4.999622821807861 11.86239147186279 5.066689014434814 11.71411609649658 5.189555644989014 C 11.54557037353516 5.32928991317749 11.43954944610596 5.530345439910889 11.41944885253906 5.748357772827148 C 11.39934921264648 5.966370582580566 11.46682071685791 6.183421611785889 11.60697555541992 6.35161828994751 L 14.59866905212402 9.9449462890625 L 4.82415771484375 9.9449462890625 C 4.368988037109375 9.9449462890625 4 10.31393432617188 4 10.76910400390625 C 4 11.22427368164062 4.368988037109375 11.59326171875 4.82415771484375 11.59326171875 Z">
                                                    </path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="Group_7185_prds">
                                <div id="Group_7184_prds">
                                    <svg className="Rectangle_2144_prds">
                                        <rect id="Rectangle_2144_prds" rx="7" ry="7" x="0" y="0" width="276" height="154">
                                        </rect>
                                    </svg>
                                    <div id="n_2628_jz_prds">
                                        <span>Quantity 1</span>
                                    </div>
                                    <div id="n_2628_j_prds">
                                        <span>Quantity 1</span>
                                    </div>
                                    <div id="n_2628_ka_prds">
                                        <span>Quantity 1</span>
                                    </div>
                                    <div id="Includes_prds">
                                        <span>Includes:</span>
                                    </div>
                                    <div id="Group_7182_prds">
                                        <div id="Group_4940_j_prds">
                                            <svg className="Rectangle_2151_j_prds">
                                                <rect id="Rectangle_2151_j_prds" rx="5" ry="5" x="0" y="0" width="29.537" height="29.537">
                                                </rect>
                                            </svg>
                                        </div>
                                        <div id="Group_7183_prds">
                                            <svg className="Rectangle_2409_ka_prds">
                                                <linearGradient id="Rectangle_2409_ka_prds" spreadMethod="pad" x1="0" x2="1.449" y1="-0.72" y2="3.053">
                                                    <stop offset="0" stop-color="#747474" stop-opacity="1"></stop>
                                                    <stop offset="1" stop-color="#5f5f5f" stop-opacity="1"></stop>
                                                </linearGradient>
                                                <rect id="Rectangle_2409_ka_prds" rx="3.700000047683716" ry="3.700000047683716" x="0" y="0" width="85.592" height="7.4">
                                                </rect>
                                            </svg>
                                            <svg className="Rectangle_2411_ka_prds">
                                                <linearGradient id="Rectangle_2411_ka_prds" spreadMethod="pad" x1="0" x2="1.449" y1="-0.72" y2="3.053">
                                                    <stop offset="0" stop-color="#cbc9c9" stop-opacity="1"></stop>
                                                    <stop offset="1" stop-color="#e2e2e2" stop-opacity="1"></stop>
                                                </linearGradient>
                                                <rect id="Rectangle_2411_ka_prds" rx="3.700000047683716" ry="3.700000047683716" x="0" y="0" width="40.592" height="7.4">
                                                </rect>
                                            </svg>
                                        </div>
                                    </div>
                                    <svg className="Rectangle_2468_kc_prds">
                                        <linearGradient id="Rectangle_2468_kc_prds" spreadMethod="pad" x1="0" x2="1.449" y1="-0.72" y2="3.053">
                                            <stop offset="0" stop-color="#cbc9c9" stop-opacity="1"></stop>
                                            <stop offset="1" stop-color="#e2e2e2" stop-opacity="1"></stop>
                                        </linearGradient>
                                        <rect id="Rectangle_2468_kc_prds" rx="3.700000047683716" ry="3.700000047683716" x="0" y="0" width="85.592" height="7.4">
                                        </rect>
                                    </svg>
                                    <svg className="Rectangle_2469_ke_prds">
                                        <linearGradient id="Rectangle_2469_ke_prds" spreadMethod="pad" x1="0" x2="1.449" y1="-0.72" y2="3.053">
                                            <stop offset="0" stop-color="#cbc9c9" stop-opacity="1"></stop>
                                            <stop offset="1" stop-color="#e2e2e2" stop-opacity="1"></stop>
                                        </linearGradient>
                                        <rect id="Rectangle_2469_ke_prds" rx="3.700000047683716" ry="3.700000047683716" x="0" y="0" width="134.521" height="7.4">
                                        </rect>
                                    </svg>
                                    <svg className="Rectangle_2470_kg_prds">
                                        <linearGradient id="Rectangle_2470_kg_prds" spreadMethod="pad" x1="0" x2="1.449" y1="-0.72" y2="3.053">
                                            <stop offset="0" stop-color="#cbc9c9" stop-opacity="1"></stop>
                                            <stop offset="1" stop-color="#e2e2e2" stop-opacity="1"></stop>
                                        </linearGradient>
                                        <rect id="Rectangle_2470_kg_prds" rx="3.700000047683716" ry="3.700000047683716" x="0" y="0" width="111.201" height="7.4">
                                        </rect>
                                    </svg>
                                </div>
                                <div id="Group_6967_prds">
                                    <svg className="Rectangle_2103_ki_prds">
                                        <rect id="Rectangle_2103_ki_prds" rx="4" ry="4" x="0" y="0" width="138.396" height="69.876">
                                        </rect>
                                    </svg>
                                    <div id="Adidas_Ultraboost_kj_prds">
                                        <span>Adidas Ultraboost</span>
                                    </div>
                                    <div id="Group_6966_kk_prds">
                                        <svg className="Rectangle_2397_km_prds">
                                            <linearGradient id="Rectangle_2397_km_prds" spreadMethod="pad" x1="0" x2="1.449" y1="-0.72" y2="3.053">
                                                <stop offset="0" stop-color="#bcbcbc" stop-opacity="1"></stop>
                                                <stop offset="1" stop-color="#e2e2e2" stop-opacity="1"></stop>
                                            </linearGradient>
                                            <rect id="Rectangle_2397_km_prds" rx="2.609750270843506" ry="2.609750270843506" x="0" y="0" width="68.269" height="5.22">
                                            </rect>
                                        </svg>
                                        <svg className="Rectangle_2402_ko_prds">
                                            <linearGradient id="Rectangle_2402_ko_prds" spreadMethod="pad" x1="0" x2="1.449" y1="-0.72" y2="3.053">
                                                <stop offset="0" stop-color="#cbc9c9" stop-opacity="1"></stop>
                                                <stop offset="1" stop-color="#e2e2e2" stop-opacity="1"></stop>
                                            </linearGradient>
                                            <rect id="Rectangle_2402_ko_prds" rx="2.609750270843506" ry="2.609750270843506" x="0" y="0" width="49.127" height="5.22">
                                            </rect>
                                        </svg>
                                        <svg className="Rectangle_2403_kq_prds">
                                            <linearGradient id="Rectangle_2403_kq_prds" spreadMethod="pad" x1="0" x2="1.449" y1="-0.72" y2="3.053">
                                                <stop offset="0" stop-color="#cbc9c9" stop-opacity="1"></stop>
                                                <stop offset="1" stop-color="#e2e2e2" stop-opacity="1"></stop>
                                            </linearGradient>
                                            <rect id="Rectangle_2403_kq_prds" rx="2.57495379447937" ry="2.57495379447937" x="0" y="0" width="28.249" height="5.15">
                                            </rect>
                                        </svg>
                                    </div>
                                    <div id="Group_6573_kr_prds">
                                        {/* <svg className="Rectangle_2210_ks_prds">
                                            <rect id="Rectangle_2210_ks_prds" rx="7" ry="7" x="0" y="0" width="33.794" height="33.551">
                                            </rect>
                                        </svg>
                                        <img id="Screenshot_2022-07-25_at_16425_prds" src="media/Screenshot_2022-07-25_at_16425.png"/> */}

                                        <div id="Layer_2_jr_prds">
                                            <div id="image_js_prds">
                                                <svg className="Rectangle_2245_jt_prds">
                                                    <rect id="Rectangle_2245_jt_prds" rx="0" ry="0" x="0" y="0" width="25" height="25">
                                                    </rect>
                                                </svg>
                                                <svg className="Path_9163_ju_prds" viewBox="3 3 19.166 19.166">
                                                    <path id="Path_9163_ju_prds" d="M 18.97178077697754 3 L 6.194355964660645 3 C 4.430161476135254 3 2.999999761581421 4.43016242980957 3 6.194356918334961 L 3 18.97178077697754 C 3 20.73597526550293 4.430161476135254 22.1661376953125 6.194355010986328 22.1661376953125 L 18.97178077697754 22.1661376953125 C 20.73597526550293 22.1661376953125 22.1661376953125 20.73597526550293 22.1661376953125 18.97178077697754 L 22.1661376953125 6.194355964660645 C 22.1661376953125 4.430161476135254 20.73597526550293 3 18.97178077697754 3 Z M 6.194355964660645 5.129570960998535 L 18.97178077697754 5.129570960998535 C 19.55984497070312 5.129570960998535 20.03656578063965 5.606290817260742 20.03656578063965 6.194355964660645 L 20.03656578063965 15.09596157073975 L 16.62925148010254 12.18909645080566 C 15.54049682617188 11.29325199127197 13.9699649810791 11.29325008392334 12.88120937347412 12.18909645080566 L 5.129570960998535 18.65234565734863 L 5.129570960998535 6.194355964660645 C 5.129570960998535 5.606290817260742 5.606290817260742 5.129570960998535 6.194355964660645 5.129570960998535 Z M 18.97178077697754 20.03656578063965 L 6.79063606262207 20.03656578063965 L 14.24413394927979 13.81821918487549 C 14.53812408447266 13.59992218017578 14.94039344787598 13.59992218017578 15.23438549041748 13.81821918487549 L 20.03656578063965 17.90699577331543 L 20.03656578063965 18.97178077697754 C 20.03656578063965 19.55984497070312 19.55984497070312 20.03656578063965 18.97178077697754 20.03656578063965 Z">
                                                    </path>
                                                </svg>
                                                <svg className="Ellipse_780_jv_prds">
                                                    <ellipse id="Ellipse_780_jv_prds" rx="1.5971779823303223" ry="1.5971779823303223" cx="1.5971779823303223" cy="1.5971779823303223">
                                                    </ellipse>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="Group_6968_prds">
                                    <svg className="Rectangle_2103_jg_prds">
                                        <rect id="Rectangle_2103_jg_prds" rx="4" ry="4" x="0" y="0" width="138.396" height="69.876">
                                        </rect>
                                    </svg>
                                    <div id="Adidas_Ultraboost_jh_prds">
                                        <span>Adidas Ultraboost</span>
                                    </div>
                                    <div id="Group_6966_prds">
                                        <svg className="Rectangle_2397_jk_prds">
                                            <linearGradient id="Rectangle_2397_jk_prds" spreadMethod="pad" x1="0" x2="1.449" y1="-0.72" y2="3.053">
                                                <stop offset="0" stop-color="#bcbcbc" stop-opacity="1"></stop>
                                                <stop offset="1" stop-color="#e2e2e2" stop-opacity="1"></stop>
                                            </linearGradient>
                                            <rect id="Rectangle_2397_jk_prds" rx="2.609750270843506" ry="2.609750270843506" x="0" y="0" width="68.269" height="5.22">
                                            </rect>
                                        </svg>
                                        <svg className="Rectangle_2402_jm_prds">
                                            <linearGradient id="Rectangle_2402_jm_prds" spreadMethod="pad" x1="0" x2="1.449" y1="-0.72" y2="3.053">
                                                <stop offset="0" stop-color="#cbc9c9" stop-opacity="1"></stop>
                                                <stop offset="1" stop-color="#e2e2e2" stop-opacity="1"></stop>
                                            </linearGradient>
                                            <rect id="Rectangle_2402_jm_prds" rx="2.609750270843506" ry="2.609750270843506" x="0" y="0" width="49.127" height="5.22">
                                            </rect>
                                        </svg>
                                        <svg className="Rectangle_2403_jo_prds">
                                            <linearGradient id="Rectangle_2403_jo_prds" spreadMethod="pad" x1="0" x2="1.449" y1="-0.72" y2="3.053">
                                                <stop offset="0" stop-color="#cbc9c9" stop-opacity="1"></stop>
                                                <stop offset="1" stop-color="#e2e2e2" stop-opacity="1"></stop>
                                            </linearGradient>
                                            <rect id="Rectangle_2403_jo_prds" rx="2.57495379447937" ry="2.57495379447937" x="0" y="0" width="28.249" height="5.15">
                                            </rect>
                                        </svg>
                                    </div>
                                    <div id="Group_6573_prds">
                                        <svg className="Rectangle_2210_prds">
                                            <rect id="Rectangle_2210_prds" rx="7" ry="7" x="0" y="0" width="33.794" height="33.551">
                                            </rect>
                                        </svg>
                                        <div id="Layer_2_jr_prds">
                                            <div id="image_js_prds">
                                                <svg className="Rectangle_2245_jt_prds">
                                                    <rect id="Rectangle_2245_jt_prds" rx="0" ry="0" x="0" y="0" width="25" height="25">
                                                    </rect>
                                                </svg>
                                                <svg className="Path_9163_ju_prds" viewBox="3 3 19.166 19.166">
                                                    <path id="Path_9163_ju_prds" d="M 18.97178077697754 3 L 6.194355964660645 3 C 4.430161476135254 3 2.999999761581421 4.43016242980957 3 6.194356918334961 L 3 18.97178077697754 C 3 20.73597526550293 4.430161476135254 22.1661376953125 6.194355010986328 22.1661376953125 L 18.97178077697754 22.1661376953125 C 20.73597526550293 22.1661376953125 22.1661376953125 20.73597526550293 22.1661376953125 18.97178077697754 L 22.1661376953125 6.194355964660645 C 22.1661376953125 4.430161476135254 20.73597526550293 3 18.97178077697754 3 Z M 6.194355964660645 5.129570960998535 L 18.97178077697754 5.129570960998535 C 19.55984497070312 5.129570960998535 20.03656578063965 5.606290817260742 20.03656578063965 6.194355964660645 L 20.03656578063965 15.09596157073975 L 16.62925148010254 12.18909645080566 C 15.54049682617188 11.29325199127197 13.9699649810791 11.29325008392334 12.88120937347412 12.18909645080566 L 5.129570960998535 18.65234565734863 L 5.129570960998535 6.194355964660645 C 5.129570960998535 5.606290817260742 5.606290817260742 5.129570960998535 6.194355964660645 5.129570960998535 Z M 18.97178077697754 20.03656578063965 L 6.79063606262207 20.03656578063965 L 14.24413394927979 13.81821918487549 C 14.53812408447266 13.59992218017578 14.94039344787598 13.59992218017578 15.23438549041748 13.81821918487549 L 20.03656578063965 17.90699577331543 L 20.03656578063965 18.97178077697754 C 20.03656578063965 19.55984497070312 19.55984497070312 20.03656578063965 18.97178077697754 20.03656578063965 Z">
                                                    </path>
                                                </svg>
                                                <svg className="Ellipse_780_jv_prds">
                                                    <ellipse id="Ellipse_780_jv_prds" rx="1.5971779823303223" ry="1.5971779823303223" cx="1.5971779823303223" cy="1.5971779823303223">
                                                    </ellipse>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="Group_6969_prds">
                                    <svg className="Rectangle_2103_kv_prds">
                                        <rect id="Rectangle_2103_kv_prds" rx="4" ry="4" x="0" y="0" width="138.396" height="69.876">
                                        </rect>
                                    </svg>
                                    <div id="Adidas_Ultraboost_kw_prds">
                                        <span>Adidas Ultraboost</span>
                                    </div>
                                    <div id="Group_6966_kx_prds">
                                        <svg className="Rectangle_2397_kz_prds">
                                            <linearGradient id="Rectangle_2397_kz_prds" spreadMethod="pad" x1="0" x2="1.449" y1="-0.72" y2="3.053">
                                                <stop offset="0" stop-color="#bcbcbc" stop-opacity="1"></stop>
                                                <stop offset="1" stop-color="#e2e2e2" stop-opacity="1"></stop>
                                            </linearGradient>
                                            <rect id="Rectangle_2397_kz_prds" rx="2.609750270843506" ry="2.609750270843506" x="0" y="0" width="68.269" height="5.22">
                                            </rect>
                                        </svg>
                                        <svg className="Rectangle_2402_la_prds">
                                            <linearGradient id="Rectangle_2402_la_prds" spreadMethod="pad" x1="0" x2="1.449" y1="-0.72" y2="3.053">
                                                <stop offset="0" stop-color="#cbc9c9" stop-opacity="1"></stop>
                                                <stop offset="1" stop-color="#e2e2e2" stop-opacity="1"></stop>
                                            </linearGradient>
                                            <rect id="Rectangle_2402_la_prds" rx="2.609750270843506" ry="2.609750270843506" x="0" y="0" width="49.127" height="5.22">
                                            </rect>
                                        </svg>
                                        <svg className="Rectangle_2403_la_prds">
                                            <linearGradient id="Rectangle_2403_la_prds" spreadMethod="pad" x1="0" x2="1.449" y1="-0.72" y2="3.053">
                                                <stop offset="0" stop-color="#cbc9c9" stop-opacity="1"></stop>
                                                <stop offset="1" stop-color="#e2e2e2" stop-opacity="1"></stop>
                                            </linearGradient>
                                            <rect id="Rectangle_2403_la_prds" rx="2.57495379447937" ry="2.57495379447937" x="0" y="0" width="28.249" height="5.15">
                                            </rect>
                                        </svg>
                                    </div>
                                    <div id="Group_6573_k_prds">
                                        <svg className="Rectangle_2210_k_prds">
                                            <rect id="Rectangle_2210_k_prds" rx="7" ry="7" x="0" y="0" width="33.794" height="33.551">
                                            </rect>
                                        </svg>
                                        <div id="Layer_2_k_prds">
                                            <div id="image_k_prds">
                                                <svg className="Rectangle_2245_k_prds">
                                                    <rect id="Rectangle_2245_k_prds" rx="0" ry="0" x="0" y="0" width="25" height="25">
                                                    </rect>
                                                </svg>
                                                <svg className="Path_9163_k_prds" viewBox="3 3 19.166 19.166">
                                                    <path id="Path_9163_k_prds" d="M 18.97178077697754 3 L 6.194355964660645 3 C 4.430161476135254 3 2.999999761581421 4.43016242980957 3 6.194356918334961 L 3 18.97178077697754 C 3 20.73597526550293 4.430161476135254 22.1661376953125 6.194355010986328 22.1661376953125 L 18.97178077697754 22.1661376953125 C 20.73597526550293 22.1661376953125 22.1661376953125 20.73597526550293 22.1661376953125 18.97178077697754 L 22.1661376953125 6.194355964660645 C 22.1661376953125 4.430161476135254 20.73597526550293 3 18.97178077697754 3 Z M 6.194355964660645 5.129570960998535 L 18.97178077697754 5.129570960998535 C 19.55984497070312 5.129570960998535 20.03656578063965 5.606290817260742 20.03656578063965 6.194355964660645 L 20.03656578063965 15.09596157073975 L 16.62925148010254 12.18909645080566 C 15.54049682617188 11.29325199127197 13.9699649810791 11.29325008392334 12.88120937347412 12.18909645080566 L 5.129570960998535 18.65234565734863 L 5.129570960998535 6.194355964660645 C 5.129570960998535 5.606290817260742 5.606290817260742 5.129570960998535 6.194355964660645 5.129570960998535 Z M 18.97178077697754 20.03656578063965 L 6.79063606262207 20.03656578063965 L 14.24413394927979 13.81821918487549 C 14.53812408447266 13.59992218017578 14.94039344787598 13.59992218017578 15.23438549041748 13.81821918487549 L 20.03656578063965 17.90699577331543 L 20.03656578063965 18.97178077697754 C 20.03656578063965 19.55984497070312 19.55984497070312 20.03656578063965 18.97178077697754 20.03656578063965 Z">
                                                    </path>
                                                </svg>
                                                <svg className="Ellipse_780_la_prds">
                                                    <ellipse id="Ellipse_780_la_prds" rx="1.5971779823303223" ry="1.5971779823303223" cx="1.5971779823303223" cy="1.5971779823303223">
                                                    </ellipse>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </NavLink>
                </div>
            </div>
            <div id="Group_4589_prds">
                <div id="Products_prds">
                    <span>Products</span>
                </div>
            </div>
        </nav>

    )
}

export default HotlinksProducts;
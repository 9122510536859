import React from 'react';


export const Spinner = ({style}:any) => {
    return  <div className='loader-container' style={style}>
                <div className="loader"></div>
            </div>
  }
  
export const FullPageSpinner = () => {
return  <div className='loader-container-full-page'>
            <div className="loader_fullpage"></div>
        </div>
}
  

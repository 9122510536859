import axios from 'axios';
import React, { useEffect, useState, SyntheticEvent } from 'react';
import { NavLink } from 'react-router-dom';
import '../../styles/Settings/settingspersonalprofile_mobile.css'
import '../../styles/Components/NavBar.css'
import '../../styles/Components/UserRibbon.css'
import '../../styles/Components/HotLinks_Settings.css'
import Wrapper from "../../components/Wrapper";
import HotLinks_Settings from '../../components/HotLinks_Settings';
import { SuccessfulPayments } from "../../models/payments";
import { Withdrawal } from "../../models/wallet";
import validator from 'validator';
import { nationalityList } from "../../utils/nationality-list";
import { get_merchant_profile } from "../../services/Settings/merchantprofileinfo";

import { countrycode_flagList } from "../../utils/nationality-list";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import MediaQuery from 'react-responsive';
import 'react-toastify/dist/ReactToastify.css';
import '../../styles/Components/Toastify.css'


const SettingsPersonalProfile_Mobile = () => {
    const [formErrors, setFormErrors] = useState<Record<string, string>>({});
    const [redirect, setredirect] = useState(false)
    const [profileData, setProfileData] = useState([] as any);
    const [nationality, setNationality] = useState("Kenyan")
    const [notify_personal_email, setNotifyPersonalEmail] = useState(true)
    const [notify_mobile_number, setNotifyMobileNumber] = useState(true)
    const [country_code, setCountryCode] = useState("KE")
    const isEmailValidator = require('validator').isEmail
    const [formValues, setFormValues] = useState({} as any);


    useEffect(() => {
        async function fetchMerchantProfile() {
            var merchant = await get_merchant_profile();
            setProfileData(merchant);

            const initialValues = {
                first_name: merchant.first_name,
                last_name: merchant.last_name,
                personal_email: merchant.personal_email,
                nationality: merchant.nationality,
                mobile_number: merchant.mobile_number,

            };

            setFormValues(initialValues);
        }

        fetchMerchantProfile();

    }, []);



    function handleChange(event: { target: { name: any; value: any; }; }) {
        const { name, value } = event.target;
        setFormValues({ ...formValues, [name]: value });
        setProfileData((prevData: any) => ({
            ...prevData,
            [name]: value
        }));
    };


    const checkFormValidity = (formValues: {
        first_name: any; last_name: any; personal_email: any; nationality: any; mobile_number: any;
    }) => {

        const errors: any = {};
        if (!formValues.first_name) {
            toast("First name is required", { type: "error" });
            errors.first_name = "First name is required";
        }
        else if (!formValues.last_name) {
            toast("Last name is required", { type: "error" });
            errors.last_name = 'Last Name is required';
        }
        else if (!formValues.personal_email) {
            toast("Personal Email is required", { type: "error" });
            errors.personal_email = 'Personal Email is required';
        }
        else if (!validator.isEmail(formValues.personal_email)) {
            toast("Personal Email is not valid", { type: "error" });
            errors.personal_email = 'Personal Email is not valid';
        }
        else if (!formValues.nationality) {
            toast("Nationality is required", { type: "error" });
            errors.nationality = 'Nationality  is required';
        }
        else if (!formValues.mobile_number) {
            toast("Phone number is required", { type: "error" });
            errors.mobile_number = 'Phone number is required';
        }
        setFormErrors(errors);
        if (Object.keys(errors).length > 0) {
            let errorMessage = "";
            for (const key in formErrors) {
                if (formErrors[key]) {
                    errorMessage += formErrors[key] + "\n";
                }
            }

        }
        return Object.keys(errors).length === 0;
    };



    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();

        const isValid = checkFormValidity(formValues);
        if (!isValid) {
            return;
        }

        let api_url = process.env.REACT_APP_CHPTER_API_URL + '/merchants/profile'
        let headers = {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("JWT"),
        };

        const data = formValues;


        await axios.post(api_url, data, { headers: headers })
            .then((response) => {


                setredirect(true)

            })
            .catch((error) => {
                // Error
                if (error.response) {

                } else if (error.request) {

                } else {
     
                }
            });

    }

    return (
        <>

            <MediaQuery maxWidth={1000}>
                <div id="MobileSettingsProfile_pp_mob"
                    style={{
                        transformOrigin: "0px 0px",
                        transform: "scale(0.930)",
                        display: "flex",
                        justifyContent: "center", // Horizontally center items
                        alignItems: "center", // Vertically center items
                        right: "-23px"
                    }}>
                    <div id="All_pp_mob">
                        <span>Personal Profile</span>
                    </div>
                    <a href="ProjectOverview.html">
                        <div id="All_gq_pp_mob">
                            <span>Account Information</span>
                        </div>
                    </a>
                    <div id="Group_7630_pp_mob">

                        <div id="Group_7629_pp_mob">
                            <input id="Rectangle_2128_pp_mob" type="text" name="last_name" placeholder="Input Last Name" value={profileData.last_name || ''} onChange={handleChange} required />
                            <div id="All_gs_pp_mob">
                                <span>Last Name</span>
                            </div>
                        </div>
                    </div>


                    <div id="Group_7626_pp_mob">

                        <div id="Group_7625_pp_mob">
                            <select id="Rectangle_2128_gz_pp_mob" name="nationality" value={profileData.nationality || ''} onChange={handleChange} required>
                                {nationalityList.map(nationality => (
                                    <option key={nationality} value={nationality} selected={nationality === 'Kenyan'}>{nationality}</option>
                                ))}
                            </select>
                            <div id="All_gx_pp_mob">
                                <span>Nationality</span>
                            </div>
                        </div>
                    </div>


                    <div id="Group_7632_pp_mob">

                        <div id="Group_7631_pp_mob">

                            <input id="Rectangle_2155_pp_mob" type="text" name="first_name" placeholder="Input First Name" value={profileData.first_name || ''} onChange={handleChange} required />

                            <div id="All_g_pp_mob">
                                <span>First Name</span>
                            </div>

                        </div>
                    </div>
                    <div id="Group_7628_pp_mob">
                        <div id="Group_7627_pp_mob">
                            <input id="Rectangle_2128_g_pp_mob" type="text" name="personal_email" placeholder="Input Personal Email" value={profileData.personal_email || ''} onChange={handleChange} required />
                            <div id="All_ha_pp_mob">
                                <span>Personal Email</span>
                            </div>
                        </div>

                        <div id="Group_7054_pp_mob">
                            <div id="Enable_notifications_pp_mob">
                                {/* <span>Enable </span><span style="text-transform:capitalize;">notifications</span> */}
                            </div>
                            <svg className="Rectangle_2461_pp_mob">
                                <rect id="Rectangle_2461_pp_mob" rx="3" ry="3" x="0" y="0" width="15" height="15">
                                </rect>
                            </svg>
                        </div>
                    </div>
                    <div id="Group_7624_pp_mob">

                        <div id="Group_7623_pp_mob">


                            <input id="Rectangle_2128_hh_pp_mob" type="number" name="mobile_number" placeholder="700001234" value={profileData.mobile_number || ''} onChange={handleChange} required />

                            <div id="All_hf_pp_mob">
                                <span>Phone Number</span>
                            </div>
                        </div>
                        <div id="Group_7622_pp_mob">
                            <div id="Enable_notifications_hk_pp_mob">
                                {/* <span>Enable </span><span style="text-transform:capitalize;">notifications</span> */}
                            </div>
                            <svg className="Rectangle_2461_hl_pp_mob">
                                <rect id="Rectangle_2461_hl_pp_mob" rx="3" ry="3" x="0" y="0" width="15" height="15">
                                </rect>
                            </svg>
                        </div>
                    </div>
                    <div id="Group_4741_pp_mob">
                        <div id="Group_3969_pp_mob">
                            <svg className="Rectangle_2057_pp_mob">
                                <rect id="Rectangle_2057_pp_mob" rx="5" ry="5" x="0" y="0" width="199" height="30">
                                </rect>
                            </svg>
                        </div>
                        <div id="Group_4628_pp_mob" onClick={submit}>

                            <div id="SAVE_CHANGES_pp_mob">
                                <span>SAVE CHANGES</span>
                            </div>

                        </div>
                    </div>
                    <div id="Group_4742_pp_mob">
                        <div id="Group_3969_hs_pp_mob">
                            <svg className="Rectangle_2057_ht_pp_mob">
                                <rect id="Rectangle_2057_ht_pp_mob" rx="5" ry="5" x="0" y="0" width="75" height="30">
                                </rect>
                            </svg>
                        </div>
                        <div id="Group_4628_hu_pp_mob">

                            <div id="CANCEL_pp_mob">
                                <span>CANCEL</span>
                            </div>

                        </div>
                    </div>
                    <NavLink to={'/settings-mobile'} className="nav-link">
                        <div id="Group_7465_pp_mob">
                            <svg className="Rectangle_2103_pp_mob">
                                <rect id="Rectangle_2103_pp_mob" rx="5" ry="5" x="0" y="0" width="32" height="32">
                                </rect>
                            </svg>
                            <svg className="Path_9390_pp_mob" viewBox="0.636 0.05 10.513 18">
                                <path id="Path_9390_pp_mob" d="M 5.357423305511475 10.11950778961182 C 4.766643047332764 9.528789520263672 4.766643047332764 8.571026802062988 5.357423305511475 7.980309963226318 L 10.7065315246582 2.631746768951416 C 11.29722785949707 2.041119337081909 11.2972297668457 1.083506107330322 10.7065315246582 0.4928695559501648 L 10.7065315246582 0.4928695559501648 C 10.11583614349365 -0.09776583313941956 9.158125877380371 -0.09776552766561508 8.567437171936035 0.4928695559501648 L 1.079220414161682 7.980309963226318 C 0.4884408712387085 8.571026802062988 0.4884408712387085 9.528774261474609 1.079220414161682 10.11950778961182 L 8.567437171936035 17.60687637329102 C 9.158125877380371 18.19756317138672 10.11583614349365 18.19756317138672 10.7065315246582 17.60687637329102 L 10.7065315246582 17.60687637329102 C 11.2972297668457 17.0163402557373 11.2972297668457 16.05868339538574 10.7065315246582 15.46799468994141 L 5.357423305511475 10.11950778961182 Z">
                                </path>
                            </svg>
                        </div>
                    </NavLink>
                </div>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick={false}
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    toastClassName="custom-toast"
                    className="toast-container"
                    style={{
                        position: "fixed",
                        color: "white",
                        top: "10%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        height: "10vh",
                        width: "70vw",
                    }}
                />


            </MediaQuery>





        </>




    )











};


export default SettingsPersonalProfile_Mobile;
import axios from 'axios';
import React, { useEffect, useState, useRef, createContext } from 'react';
import { NavLink } from 'react-router-dom';
import '../../styles/Wallet/payoutinitiate.css'


import { Withdrawal } from "../../models/wallet";
import { data } from 'jquery';
import { get_all_bank_account_info } from '../../services/Settings/bankaccountinfo';
import { get_merchant_profile } from '../../services/Settings/merchantprofileinfo';
import { useNavigate } from "react-router-dom";
import { get_wallet_balance } from '../../services/Wallet/walletbalanceinfo';
import MediaQuery from 'react-responsive';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../styles/Components/Toastify.css'


const PayoutInitiate = () => {
    const [WalletTotals, setWalletTotals] = useState([] as any);
    const [BankAccount, setBankAccount] = useState([] as any);
    const [MpesaDetails, setMpesaDetails] = useState([] as any);
    const [PayoutDetails, setPayoutDetails] = useState([] as any);
    const [PayoutType, setPayoutType] = useState('Mpesa');
    const [PhoneNumber, setPhoneNumber] = useState('')
    const [CompanyName, setCompanyName] = useState('')
    const [HashedBankAccountNumber, setHashedBankAccountNumber] = useState('')



    const [WithdrawalAmount, setWithdrawalAmount] = useState('');
    const KES_total = WalletTotals;
    const [KES_wallet_balance, setKESWalletBalance] = useState(WalletTotals);
    const [Service_Fee, setServiceFee] = useState(0);
    const [bankAccountAvailable, setBankAccountAvailable] = useState(false)


    useEffect(() => {
        // Fetch Wallet Balances
        async function fetchWalletBalances() {
            await get_wallet_balance().then(wallet => {
                setKESWalletBalance(wallet.available_balance_kes)
                setWalletTotals(wallet.total_balance_kes)
                



            }).catch(error => {

            }).finally(() => {
            })
        }
        fetchWalletBalances();


        // Get Bank Accounts 
        async function fetchBankAccounts() {

            await get_all_bank_account_info().then(bank_account => {
                if (bank_account.length > 0) {
                    setBankAccountAvailable(true)
                }
                setBankAccount(bank_account);


            }).catch(error => {

            }).finally(() => {

            })
        }
        fetchBankAccounts();


        // Get MPesa Details
        async function fetchMpesaDetails() {
            await get_merchant_profile().then(merchant => {

                console.log(merchant)

                setMpesaDetails(merchant);
                setPhoneNumber(merchant.mobile_number)
                setCompanyName(merchant.company_name)
                setPayoutDetails(merchant);
                setPayoutType('Mpesa')
            }).catch(error => {

            }).finally(() => {
            })
        }
        fetchMpesaDetails();


    }, []);


    // Hash Phone Number
    const phoneNumber = PhoneNumber;
    const firstFourDigits = phoneNumber.substring(0, 4);
    const lastFourDigits = phoneNumber.substring(phoneNumber.length - 3);
    const HashedPhoneNumber = firstFourDigits + "****" + lastFourDigits


    // Selecting a Payment Method
    const navigate = useNavigate();
    const [selectedDiv, setSelectedDiv] = useState(-1);
    const handleBankAccClick = (index: React.SetStateAction<number>) => {
        setSelectedDiv(index);
        setPayoutDetails(BankAccount[index.toString()]);
        setPayoutType('Bank')

        // Hash Bank Account Number
        var bankaccountNumber = BankAccount[index.toString()].bank_account_number;
        const firstFourDigits = bankaccountNumber.substring(0, 4);
        const lastFourDigits = bankaccountNumber.substring(bankaccountNumber.length - 4);
        const hashedbankAccountNumber = firstFourDigits + "****" + lastFourDigits
        setHashedBankAccountNumber(hashedbankAccountNumber)
        setSelectedDivMob(index);

    };

    const handleMpesaClick = (index: React.SetStateAction<number>) => {
        setSelectedDiv(index);
        setPayoutDetails(MpesaDetails);
        setPayoutType('Mpesa')
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let WithdrawalValue = parseInt(event.target.value);


        // Service Fee
        let service = 0
        setServiceFee(service)


        setWithdrawalAmount(WithdrawalValue.toString());

        if (WithdrawalAmount === '' || isNaN(WithdrawalValue)) {
            setWithdrawalAmount(WithdrawalValue.toString());
            setKESWalletBalance(KES_total)
            
        } else {
            if (WithdrawalValue <= 0) {
                WithdrawalValue = 0
            } else if (WithdrawalValue > parseInt(event.target.max)) {
                WithdrawalValue = parseInt(event.target.max)
            }
            setKESWalletBalance(Number.isNaN((KES_total - WithdrawalValue - Service_Fee)) ? 0 : (KES_total - WithdrawalValue - Service_Fee))
        }
    };



    const handleRightClick = () => {
        if (selectedDiv < BankAccount.length - 1) {
            setSelectedDiv(selectedDiv + 1);
            calculateTranslateX(selectedDiv);
        }
    };
    const handleLeftClick = () => {
        if (selectedDiv > 0) {
            setSelectedDiv(selectedDiv - 1);
            calculateTranslateX(selectedDiv)
        }
    };

    const calculateTranslateX = (selectedDiv: number) => {
        const width = 348;
        const translateX = `-${selectedDiv * width}px`;
        return translateX;

    };


    // Mobile Scrolling 
    const [selectedDivMob, setSelectedDivMob] = useState<number>(-1);



    const handleLeftClickMob = () => {
        setSelectedDivMob((prevSelectedDiv) => {
            const newIndex = prevSelectedDiv === 0 ? BankAccount.length - 1 : prevSelectedDiv - 1;
            setPayoutDetails(BankAccount[newIndex]?.toString());
            return newIndex;
        });
    };

    const handleRightClickMob = () => {
        setSelectedDivMob((prevSelectedDiv) => {
            const newIndex = prevSelectedDiv === BankAccount.length - 1 ? 0 : prevSelectedDiv + 1;
            setPayoutDetails(BankAccount[newIndex]?.toString());
            return newIndex;
        });
    };

    const [selectedBankAccountMob, setSelectedBankAccountMob] = useState<number>(-1);
    const [selectedMpesaMob, setSelectedMpesaMob] = useState<boolean>(true);

    const handleBankAccClickMob = (index: number) => {
        setSelectedBankAccountMob(index);
        setSelectedMpesaMob(false);
        setPayoutDetails(BankAccount[index.toString()]);
        setPayoutType('Bank');
        setSelectedDivMob(index);
    };

    const handleMpesaClickMob = (index: number) => {
        setSelectedBankAccountMob(-1);
        setSelectedMpesaMob(true);
        setPayoutDetails(MpesaDetails);
        setPayoutType('Mpesa');
        setSelectedDivMob(-1);

    };

    const available_balance_kes = parseFloat(KES_wallet_balance).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');



    // Navigate to the payout confirm page
    const submit = () => {

        if (!WithdrawalAmount || parseFloat(WithdrawalAmount) === 0 || isNaN(parseFloat(WithdrawalAmount))) {
            toast.error("Please enter a valid input amount", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000
            });
            return;
        }


        navigate("/withdraw-confirm", { state: { PayoutDetails, selectedBankAccount: BankAccount[selectedDivMob], selectedDivMob, PayoutType, WithdrawalAmount, KES_wallet_balance, Service_Fee, HashedPhoneNumber, HashedBankAccountNumber } });
    }


    return (

        <main className="form-signin">

            <MediaQuery maxWidth={1000}>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick={false}
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    toastClassName="custom-toast"
                    className="toast-container"
                    style={{
                        position: "fixed",
                        color: "white",
                        top: "10%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        height: "10vh",
                        width: "70vw",
                    }} />






                <div className="withdrawalinitiate_ww_mob">
                    <div className="withdrawalinitiate-inner_ww_mob">
                        <div className="group-wrapper_ww_mob">
                            <div className="group-wrapper_ww_mob">
                                <div className="initiate_ww_mob">
                                    <div className="header_ww_mob">
                                        <div className="frame-parent_ww_mob">
                                            <NavLink to={'/wallet'} style={{ textDecoration: 'none' }}>

                                                <div className="group-container_ww_mob">
                                                    <div className="rectangle-parent_ww_mob">
                                                        <div className="group-child_ww_mob" />


                                                        <svg className="path-9390-icon_ww_mob" viewBox="0.636 0.05 10.513 18">
                                                            <path id="Path_9390_pad_mob" d="M 5.357423305511475 10.11950778961182 C 4.766643047332764 9.528789520263672 4.766643047332764 8.571026802062988 5.357423305511475 7.980309963226318 L 10.7065315246582 2.631746768951416 C 11.29722785949707 2.041119337081909 11.2972297668457 1.083506107330322 10.7065315246582 0.4928695559501648 L 10.7065315246582 0.4928695559501648 C 10.11583614349365 -0.09776583313941956 9.158125877380371 -0.09776552766561508 8.567437171936035 0.4928695559501648 L 1.079220414161682 7.980309963226318 C 0.4884408712387085 8.571026802062988 0.4884408712387085 9.528774261474609 1.079220414161682 10.11950778961182 L 8.567437171936035 17.60687637329102 C 9.158125877380371 18.19756317138672 10.11583614349365 18.19756317138672 10.7065315246582 17.60687637329102 L 10.7065315246582 17.60687637329102 C 11.2972297668457 17.0163402557373 11.2972297668457 16.05868339538574 10.7065315246582 15.46799468994141 L 5.357423305511475 10.11950778961182 Z">
                                                            </path>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </NavLink>


                                            <div className="payment-detail_ww_mob">
                                                Withdraw to  {CompanyName}
                                            </div>

                                        </div>
                                    </div>


                                    <div className="selectpayoutmethod_ww_mob">
                                        <div className="add-a-new-product-wrapper_ww_mob">
                                            <div className="add-a-new_ww_mob">Select Payout Method</div>
                                        </div>
                                    </div>


                                    <div className="frame-group_ww_mob">
                                        {bankAccountAvailable ? (
                                            <><div className="frame-wrapper_ww_mob">
                                                <div className="frame-container_ww_mob">
                                                    <div className="path-9390-wrapper_ww_mob" onClick={handleLeftClickMob}>
                                                        <svg className="path-9406-icon_ww_mob" viewBox="0.636 0.05 10.513 18">
                                                            <path id="Path_9390_ww_mob" d="M 5.357423305511475 10.11950778961182 C 4.766643047332764 9.528789520263672 4.766643047332764 8.571026802062988 5.357423305511475 7.980309963226318 L 10.7065315246582 2.631746768951416 C 11.29722785949707 2.041119337081909 11.2972297668457 1.083506107330322 10.7065315246582 0.4928695559501648 L 10.7065315246582 0.4928695559501648 C 10.11583614349365 -0.09776583313941956 9.158125877380371 -0.09776552766561508 8.567437171936035 0.4928695559501648 L 1.079220414161682 7.980309963226318 C 0.4884408712387085 8.571026802062988 0.4884408712387085 9.528774261474609 1.079220414161682 10.11950778961182 L 8.567437171936035 17.60687637329102 C 9.158125877380371 18.19756317138672 10.11583614349365 18.19756317138672 10.7065315246582 17.60687637329102 L 10.7065315246582 17.60687637329102 C 11.2972297668457 17.0163402557373 11.2972297668457 16.05868339538574 10.7065315246582 15.46799468994141 L 5.357423305511475 10.11950778961182 Z">
                                                            </path>
                                                        </svg>
                                                    </div>

                                                    <div key={0} className={['frame-div_ww_mob', selectedBankAccountMob === 0 ? 'border-active' : 'grey-border', 'flexbox_container'].join(' ')} onClick={() => handleBankAccClickMob(0)}>

                                                        <div className="frame-parent1_ww_mob">
                                                            <div className="icons8-bank-96-wrapper_ww_mob">
                                                                <img
                                                                    className="icons8-bank-96_ww_mob"
                                                                    alt=""
                                                                    src="media/icons8-bank-96.png" />
                                                            </div>
                                                            <div className="stardard-chartered-bank-parent_ww_mob">
                                                                <div className="stardard-chartered-bank-container_ww_mob">
                                                                    <span className="star_ww_mob">
                                                                        {selectedDivMob !== -1 ? BankAccount[selectedDivMob]?.bank_account_name : BankAccount[0]?.bank_account_name}
                                                                    </span>
                                                                </div>
                                                                <div className="account-parent_ww_mob">
                                                                    <div className="stardard-chartered-bank-container_ww_mob">
                                                                        Account
                                                                    </div>
                                                                    <div className="div_ww_mob">
                                                                        {selectedDivMob !== -1 ? `${BankAccount[selectedDivMob]?.bank_account_number.slice(0, 4)}****${BankAccount[selectedDivMob]?.bank_account_number.slice(-4)}` : `${BankAccount[0]?.bank_account_number.slice(0, 4)}****${BankAccount[0]?.bank_account_number.slice(-4)}`}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>



                                                    <div className="path-9406-wrapper_ww_mob" onClick={handleRightClickMob}>

                                                        <svg className="path-9406-icon_ww_mob" viewBox="0.636 0.05 10.513 18">
                                                            <path id="Path_9406_ww_mob" d="M 6.428267478942871 10.11950778961182 C 7.019047737121582 9.528789520263672 7.019047737121582 8.571026802062988 6.428267478942871 7.980309963226318 L 1.079158782958984 2.631746768951416 C 0.4884624481201172 2.041119337081909 0.4884605407714844 1.083506107330322 1.079158782958984 0.4928695559501648 L 1.079158782958984 0.4928695559501648 C 1.669854164123535 -0.09776583313941956 2.627564430236816 -0.09776552766561508 3.218253135681152 0.4928695559501648 L 10.70647048950195 7.980309963226318 C 11.29725074768066 8.571026802062988 11.29725074768066 9.528774261474609 10.70647048950195 10.11950778961182 L 3.218253135681152 17.60687637329102 C 2.627564430236816 18.19756317138672 1.669854164123535 18.19756317138672 1.079158782958984 17.60687637329102 L 1.079158782958984 17.60687637329102 C 0.4884605407714844 17.0163402557373 0.4884605407714844 16.05868339538574 1.079158782958984 15.46799468994141 L 6.428267478942871 10.11950778961182 Z">
                                                            </path>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                                <div className="frame-wrapper1_ww_mob">
                                                    <div className="frame-wrapper2_ww_mob">
                                                        <div
                                                            key={-1}
                                                            className={[
                                                                'frame-div_ww_mob',
                                                                selectedMpesaMob ? 'border-active' : 'no-border'
                                                            ].join(' ')}
                                                            onClick={() => handleMpesaClickMob(-1)}
                                                        >
                                                            <div className="frame-parent2_ww_mob">
                                                                <div className="group-frame_ww_mob">
                                                                    <div className="px-m-pesa-logo-01svg-wrapper_ww_mob">
                                                                        <img
                                                                            className="px-m-pesa-logo-01svg-icon_ww_mob"
                                                                            alt=""
                                                                            src="media/n_024px-M-PESA_LOGO-01svg.png" />
                                                                    </div>
                                                                </div>
                                                                <div className="stardard-chartered-bank-parent_ww_mob">
                                                                    <div className="stardard-chartered-bank-container_ww_mob">
                                                                        <span className="star_ww_mob">MOBI</span>LE MONEY WALLET
                                                                    </div>
                                                                    <div className="account-parent_ww_mob">
                                                                        <div className="stardard-chartered-bank-container_ww_mob">
                                                                            Account
                                                                        </div>
                                                                        <div className="div_ww_mob">{firstFourDigits}****{lastFourDigits}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>

                                                </div>
                                            </>


                                        ) : (

                                            <div className="frame-wrapper1_ww_mob">
                                                <div className="frame-wrapper2_ww_mob">


                                                    <div
                                                        key={-1}
                                                        className={[
                                                            'frame-div_ww_mob',
                                                            -1 === selectedDivMob ? 'border-active' : selectedDivMob !== null ? 'no-border' : 'grey-border'
                                                        ].join(' ')}
                                                        onClick={() => handleMpesaClickMob(-1)}
                                                    >
                                                        <div className="frame-parent2_ww_mob">
                                                            <div className="group-frame_ww_mob">
                                                                <div className="px-m-pesa-logo-01svg-wrapper_ww_mob">
                                                                    <img
                                                                        className="px-m-pesa-logo-01svg-icon_ww_mob"
                                                                        alt=""
                                                                        src="media/n_024px-M-PESA_LOGO-01svg.png"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="stardard-chartered-bank-parent_ww_mob">
                                                                <div className="stardard-chartered-bank-container_ww_mob">
                                                                    <span className="star_ww_mob">MOBI</span>LE MONEY WALLET
                                                                </div>
                                                                <div className="account-parent_ww_mob">
                                                                    <div className="stardard-chartered-bank-container_ww_mob">
                                                                        Account
                                                                    </div>
                                                                    <div className="div_ww_mob">{firstFourDigits}****{lastFourDigits}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>

                                            </div>
                                        )
                                        }
                                    </div>




                                    <div className="frame-parent3_ww_mob">
                                        <div className="frame-parent4_ww_mob">
                                            <div className="frame-parent5_ww_mob">
                                                <div className="add-a-new-product-wrapper_ww_mob">
                                                    <div className="add-a-new1_ww_mob">Enter Amount</div>
                                                </div>
                                                <div className="add-a-new-product-parent_ww_mob">
                                                    <div className="add-a-new_ww_mob">KES</div>
                                                    <input className="add-a-new3_ww_mob"
                                                        type="number"
                                                        name="amount"
                                                        placeholder="00.00"
                                                        min="0"
                                                        max={KES_total}
                                                        onChange={handleChange} value={WithdrawalAmount}

                                                    />
                                                </div>
                                            </div>
                                            <div className="line-wrapper_ww_mob">
                                                <div className="frame-child_ww_mob" />
                                            </div>
                                        </div>
                                        <div className="frame-parent4_ww_mob">
                                            <div className="frame-parent5_ww_mob">
                                                <div className="add-a-new-product-wrapper_ww_mob">
                                                    <div className="add-a-new1_ww_mob">Your Wallet Balance</div>
                                                </div>
                                                <div className="add-a-new-product-parent_ww_mob">
                                                    <div className="add-a-new_ww_mob">KES</div>
                                                    <div className="add-a-new3_ww_mob">{KES_total}</div>
                                                </div>
                                            </div>
                                            <div className="line-wrapper_ww_mob">
                                                <div className="frame-child_ww_mob" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="footer_ww_mob">
                                        <NavLink to={'/wallet'} style={{ textDecoration: 'none', color: "black" }} className="cancel-wrapper_ww_mob">
                                            <div className="withdrawalinitiate-inner_ww_mob">CANCEL</div>
                                        </NavLink>
                                        <div className="save-changes-wrapper_ww_mob" onClick={submit}>
                                            <div className="withdrawalinitiate-inner_ww_mob">CONTINUE</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </MediaQuery>


            <MediaQuery minWidth={1001}>
                <ToastContainer
                    position="top-center"
                    autoClose={9000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick={false}
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    toastClassName="custom-toast"
                    className="toast-container"
                    style={{
                        top: "10%",
                        color: "white",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                    }} />
                <main className="form-signin">
                    <div id="WalletWithdraw_ww">
                        <div id='Group_619621_ww'>
                            <div id="All_ww">
                                <span>Withdraw to {CompanyName}</span>
                            </div>
                            {bankAccountAvailable ? (
                                <>
                                    <div id="Group_6661_ww">
                                        <img id="Chevron_Right" src="media/icons8-chevron-right-96.png" onClick={handleRightClick} />
                                        <img id="Chevron_Left" src="media/icons8-chevron-left-96.png" onClick={handleLeftClick} />
                                        <div id="BankAccounts_ww">
                                            {BankAccount.map((bank_account: { bank_account_name: any; bank_account_number: any; }, index: any) => {
                                                const firstFour = bank_account.bank_account_number.slice(0, 4);
                                                const lastFour = bank_account.bank_account_number.slice(-4); {
                                                    return (

                                                        <div id="Group_6654_ww" key={index} onClick={() => handleBankAccClick(index)} className={['div', index === selectedDiv ? 'border-active' : 'grey-border', 'flexbox_container'].join(' ')} style={{
                                                            display: 'inline-flex',
                                                            flexDirection: 'row',
                                                            flexWrap: 'wrap',
                                                            justifyContent: 'left',
                                                            transition: 'all 0.5s ease-in-out',
                                                            transform: `translateX(${calculateTranslateX(selectedDiv)})`
                                                        }}>
                                                            <div id="Group_5402_ww">
                                                                <div id="Stardard_Chartered_Bank___ww">
                                                                    <span>{bank_account.bank_account_name}</span>
                                                                </div>
                                                                <div id="Account_ww">
                                                                    <span>Account</span>
                                                                </div>
                                                                <div id="n_01277839173900_ww">
                                                                    <span>{firstFour}****{lastFour}</span>
                                                                </div>
                                                            </div>
                                                            <img id="icons8-bank-96_ww" src="media/icons8-bank-96.png" />
                                                        </div>

                                                    );
                                                }
                                            })}
                                        </div>
                                        <div id="MpesaAccount_ww">
                                            <div id="Group_6655_ww" key={-1} onClick={() => handleMpesaClick(-1)} className={`div ${-1 === selectedDiv ? 'border-active' : 'grey-border'}`}>
                                                <div id="Group_5402_e_ww">
                                                    <div id="MOBILE_MONEY_WALLET_ww">
                                                        <span>MOBILE MONEY WALLET</span>
                                                    </div>
                                                    <div id="Account_e_ww">
                                                        <span>Account</span>
                                                    </div>
                                                    <div id="n_54_723_889_003_ww">
                                                        <span>{firstFourDigits}****{lastFourDigits}</span>
                                                    </div>
                                                </div>
                                                <div id="Group_6576_ww">
                                                    <img id="n_024px-M-PESA_LOGO-01svg_ww" src="media/n_024px-M-PESA_LOGO-01svg.png" />

                                                </div>
                                            </div>
                                        </div>
                                        <div id="All_e_ww">
                                            <span>Select Payout Method</span>
                                        </div>
                                    </div>
                                    <div id="Group_6659_ww">
                                        <div id="All_fa_ww">
                                            <span>Enter Amount to send</span>
                                        </div>
                                        <div id="Group_6656_ww">
                                            <input id="All_fc_ww" type="number" name="amount" placeholder="00.00" min="0" max={KES_total} onChange={handleChange} value={WithdrawalAmount} />
                                            <div id="All_fd_ww">
                                                <span>Kes.</span>
                                            </div>
                                            <svg className="Line_445_ww" viewBox="0 0 326.5 1">
                                                <path id="Line_445_ww" d="M 0 0 L 326.5 0">
                                                </path>
                                            </svg>
                                        </div>
                                    </div>
                                    <div id="Group_6660_ww">
                                        <div id="All_fg_ww">
                                            <span>Your Wallet Balance</span>
                                        </div>
                                        <div id="Group_6657_ww">
                                            <div id="All_fi_ww">
                                                <span> {available_balance_kes} </span>
                                            </div>
                                            <svg className="Line_445_fj_ww" viewBox="0 0 326.5 1">
                                                <path id="Line_445_fj_ww" d="M 0 0 L 326.5 0">
                                                </path>
                                            </svg>
                                            <div id="All_fk_ww">
                                                <span>Kes.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="Group_6658_ww">

                                        <div id="Group_4741_ww" onClick={submit}>
                                            <div id="Group_3969_ww">
                                                <svg className="Rectangle_2057_ww">
                                                    <rect id="Rectangle_2057_ww" rx="5" ry="5" x="0" y="0" width="125" height="30">
                                                    </rect>
                                                </svg>
                                            </div>
                                            <div id="Group_4628_ww">
                                                <div id="CONTINUE_ww">
                                                    <span>CONTINUE</span>
                                                </div>
                                            </div>
                                        </div>
                                        <NavLink to={'/wallet'} className="nav-link" style={{ textDecoration: 'none' }}>
                                            <div id="Group_4742_ww">
                                                <div id="Group_3969_fs_ww">
                                                    <svg className="Rectangle_2057_ft_ww">
                                                        <rect id="Rectangle_2057_ft_ww" rx="5" ry="5" x="0" y="0" width="75" height="30">
                                                        </rect>
                                                    </svg>
                                                </div>
                                                <div id="Group_4628_fu_ww">
                                                    <div id="CANCEL_ww">
                                                        <span>CANCEL</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </NavLink>
                                    </div>
                                    <div id="All_fw_ww">
                                        <span>Service Fee KES. {Service_Fee}</span>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div id="Group_6661_ww">
                                        <div id="MpesaAccount_ww" style={{ top: "42px" }}>
                                            <div id="Group_6655_ww" key={-1} onClick={() => handleMpesaClick(-1)} className={`div ${-1 === selectedDiv ? 'border-active' : 'grey-border'}`}>
                                                <div id="Group_5402_e_ww">
                                                    <div id="MOBILE_MONEY_WALLET_ww">
                                                        <span>MOBILE MONEY WALLET</span>
                                                    </div>
                                                    <div id="Account_e_ww">
                                                        <span>Account</span>
                                                    </div>
                                                    <div id="n_54_723_889_003_ww">
                                                        <span>{firstFourDigits}****{lastFourDigits}</span>
                                                    </div>
                                                </div>
                                                <div id="Group_6576_ww">
                                                    <img id="n_024px-M-PESA_LOGO-01svg_ww" src="media/n_024px-M-PESA_LOGO-01svg.png" />

                                                </div>
                                            </div>
                                        </div>
                                        <div id="All_e_ww">
                                            <span>Select Payout Method</span>
                                        </div>
                                    </div>
                                    <div id='AmountsContainer_ww'>
                                        <div id="Group_6659_ww">
                                            <div id="All_fa_ww">
                                                <span>Enter Amount to send</span>
                                            </div>
                                            <div id="Group_6656_ww">
                                                <input id="All_fc_ww" type="number" name="amount" placeholder="00.00" min="0" max={KES_total} onChange={handleChange} value={WithdrawalAmount} />
                                                <div id="All_fd_ww">
                                                    <span>Kes.</span>
                                                </div>
                                                <svg className="Line_445_ww" viewBox="0 0 326.5 1">
                                                    <path id="Line_445_ww" d="M 0 0 L 326.5 0">
                                                    </path>
                                                </svg>
                                            </div>
                                        </div>
                                        <div id="Group_6660_ww">
                                            <div id="All_fg_ww">
                                                <span>Your Wallet Balance</span>
                                            </div>
                                            <div id="Group_6657_ww">
                                                <div id="All_fi_ww">
                                                    <span> {KES_wallet_balance} </span>
                                                </div>
                                                <svg className="Line_445_fj_ww" viewBox="0 0 326.5 1">
                                                    <path id="Line_445_fj_ww" d="M 0 0 L 326.5 0">
                                                    </path>
                                                </svg>
                                                <div id="All_fk_ww">
                                                    <span>Kes.</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="Group_6658_ww">

                                            <div id="Group_4741_ww" onClick={submit}>
                                                <div id="Group_3969_ww">
                                                    <svg className="Rectangle_2057_ww">
                                                        <rect id="Rectangle_2057_ww" rx="5" ry="5" x="0" y="0" width="125" height="30">
                                                        </rect>
                                                    </svg>
                                                </div>
                                                <div id="Group_4628_ww">
                                                    <div id="CONTINUE_ww">
                                                        <span>CONTINUE</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <NavLink to={'/wallet'} className="nav-link" style={{ textDecoration: 'none' }}>
                                                <div id="Group_4742_ww">
                                                    <div id="Group_3969_fs_ww">
                                                        <svg className="Rectangle_2057_ft_ww">
                                                            <rect id="Rectangle_2057_ft_ww" rx="5" ry="5" x="0" y="0" width="75" height="30">
                                                            </rect>
                                                        </svg>
                                                    </div>
                                                    <div id="Group_4628_fu_ww">
                                                        <div id="CANCEL_ww">
                                                            <span>CANCEL</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </div>
                                        <div id="All_fw_ww">
                                            <span>Service Fee KES. {Service_Fee}</span>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </main>
            </MediaQuery>
        </main>

    )
};

export default PayoutInitiate;

import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import '../../styles/Payments/paymentlinksdetail.css'
import '../../styles/Payments/invoicedetail.css'
import '../../styles/Components/NavBar.css'
import '../../styles/Components/UserRibbon.css'
import Wrapper from "../../components/Wrapper";
import { get_paymentlink_detail } from '../../services/Payments/paymentlinksinfo';
import { CopyToClipboard } from "react-copy-to-clipboard"
import { dateFormatter, datetimeFormatter_human } from '../../utils/date_formatter';
import MediaQuery from "react-responsive";
import { get_branding_info } from '../../services/Settings/brandinginfo';




const PaymentLinksDetail = () => {
  const [PaymentLinkDetail, setPaymentLinkDetail] = useState([] as any);
  const [brandingData, setBrandingData] = useState([] as any);
  const [brandAlias, setBrandAlias] = useState('');


  const current = new Date();
  const year = current.getFullYear();
  const month = String(current.getMonth() + 1).padStart(2, '0');
  const day = String(current.getDate()).padStart(2, '0');
  const currentDate = `${year}-${month}-${day}`;
  const isActive = PaymentLinkDetail.expiry_date >= currentDate;


  let { slug } = useParams();
  let paymentlink_slug = { slug }.slug



  useEffect(() => {
    async function fetchPaymentLinkDetail() {
      var paymentlink = await get_paymentlink_detail(paymentlink_slug);


      setPaymentLinkDetail(paymentlink);
    }
    async function fetchBrandingInfo() {
      var branding = await get_branding_info();
      setBrandingData(branding);
      setBrandAlias(branding.brand_alias)

    }

    fetchPaymentLinkDetail();
    fetchBrandingInfo();


  }, []);

  // Copy Text to Clipboard
  const [showDiv, setShowDiv] = useState(false);
  const handleCopy = () => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const brandAlias = brandingData.brand_alias;
    const slug = PaymentLinkDetail.slug;

    const text = `${baseUrl}/paymentlinks/${slug}/${brandAlias}/checkout`;
    const el = document.createElement('textarea');
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    setShowDiv(true);
    setTimeout(() => setShowDiv(false), 2000);
  };



  return (
    <>
      <MediaQuery minWidth={1001}>
        <main className="form-signin">
          <div id="Group_4649_paylinkdet">

            <div id="All_paylinkdet">
              <span>Amount</span>
            </div>
            <div id="Group_5168_paylinkdet">
              <div id="All_fd_paylinkdet">
                <span>{PaymentLinkDetail['amount']}</span>
              </div>
              <div id="All_fe_paylinkdet">
                <span>{PaymentLinkDetail['currency']}</span>
              </div>
            </div>
            <div id="Group_5169_paylinkdet">
              <div id="Copy_and_share_this_link__to_i_paylinkdet">
                <span>Copy and share this link  to initiate checkout and accept payments.</span>
              </div>
            </div>
            <div id="Group_5181_paylinkdet" style={{ display: showDiv ? "block" : "none" }}>
              <svg className="Rectangle_2057_paylinkdet">
                <rect id="Rectangle_2057_paylinkdet" rx="7" ry="7" x="0" y="0" width="154" height="27">
                </rect>
              </svg>
              <div id="Link_Copied_to_Clipboard_paylinkdet">
                <span>Link Copied to Clipboard</span>
              </div>
            </div>
            <div id="Component_75_paylinkdet">
              <svg className="Rectangle_2127_paylinkdet">
                <rect id="Rectangle_2127_paylinkdet" rx="5" ry="5" x="0" y="0" width="520" height="38">
                </rect>
              </svg>
              <div id="Search_Input_paylinkdet" >
                <div id="httpscheckoutchptercodrip_city_paylinkdet">
                  <span>{`${process.env.REACT_APP_BASE_URL}/paymentlinks/${brandingData.brand_alias}/${PaymentLinkDetail['slug']}/checkout`}</span>
                </div>
              </div>
            </div>

            <div id="Group_5171_paylinkdet" onClick={handleCopy}>
              <svg className="Rectangle_2126_paylinkdet">
                <rect id="Rectangle_2126_paylinkdet" rx="5" ry="5" x="0" y="0" width="38" height="38">
                </rect>
              </svg>
              <div id="Layer_2_paylinkdet">
                <div id="copy_paylinkdet">
                  <svg className="Rectangle_2125_paylinkdet">
                    <rect id="Rectangle_2125_paylinkdet" rx="0" ry="0" x="0" y="0" width="32" height="32">
                    </rect>
                  </svg>
                  <svg className="Path_9096_paylinkdet" viewBox="9 9 15.529 15.529">
                    <path id="Path_9096_paylinkdet" d="M 20.64683532714844 24.52911376953125 L 12.88227844238281 24.52911376953125 C 10.73815441131592 24.52911376953125 9 22.79095649719238 9 20.64683532714844 L 9 12.88227844238281 C 9 10.73815441131592 10.73815631866455 9 12.88228034973145 9 L 20.64683532714844 9 C 22.79095649719238 9 24.52911376953125 10.73815441131592 24.52911376953125 12.88227844238281 L 24.52911376953125 20.64683532714844 C 24.52911376953125 22.79095649719238 22.79095649719238 24.52911376953125 20.64683532714844 24.52911376953125 Z M 12.88227844238281 11.58818626403809 C 12.16757011413574 11.58818626403809 11.58818626403809 12.16757011413574 11.58818626403809 12.88227844238281 L 11.58818626403809 20.64683532714844 C 11.58818626403809 21.36154174804688 12.16757011413574 21.94092750549316 12.88227844238281 21.94092750549316 L 20.64683532714844 21.94092750549316 C 21.36154174804688 21.94092750549316 21.94092750549316 21.36154174804688 21.94092750549316 20.64683532714844 L 21.94092750549316 12.88227844238281 C 21.94092750549316 12.16757011413574 21.36154174804688 11.58818626403809 20.64683532714844 11.58818626403809 L 12.88227844238281 11.58818626403809 Z">
                    </path>
                  </svg>
                  <svg className="Path_9097_paylinkdet" viewBox="3 3 15.529 15.529">
                    <path id="Path_9097_paylinkdet" d="M 11.70924377441406 18.52911567687988 L 6.455227851867676 18.52911567687988 C 4.549904823303223 18.52202033996582 3.007095336914062 16.97921180725098 2.999999523162842 15.07388687133789 L 3.000000476837158 6.455227851867676 C 3.007095813751221 4.549904823303223 4.549906253814697 3.007095336914062 6.455228805541992 2.999999523162842 L 15.07388687133789 3.000000476837158 C 16.97921180725098 3.007095336914062 18.52202033996582 4.549904823303223 18.52911567687988 6.455228805541992 L 18.52911567687988 11.28219413757324 L 15.9409294128418 11.28219413757324 L 15.9409294128418 6.455227851867676 C 15.9409294128418 5.976373195648193 15.5527400970459 5.58818531036377 15.07388687133789 5.58818531036377 L 6.455227851867676 5.58818531036377 C 5.976373195648193 5.58818531036377 5.58818531036377 5.976373195648193 5.58818531036377 6.455227851867676 L 5.58818531036377 15.07388687133789 C 5.58818531036377 15.5527400970459 5.976373195648193 15.9409294128418 6.455227851867676 15.9409294128418 L 11.70924377441406 15.9409294128418 L 11.70924377441406 18.52911567687988 Z">
                    </path>
                  </svg>
                </div>
              </div>
            </div>
            <div id="Group_5172_paylinkdet" >
              <svg className="Rectangle_2126_fw_paylinkdet">
                <rect id="Rectangle_2126_fw_paylinkdet" rx="5" ry="5" x="0" y="0" width="38" height="38">
                </rect>
              </svg>
              <div id="Layer_2_fx_paylinkdet">
                <div id="more-horizotnal_paylinkdet">
                  <svg className="Rectangle_2135_paylinkdet">
                    <rect id="Rectangle_2135_paylinkdet" rx="0" ry="0" x="0" y="0" width="24" height="24">
                    </rect>
                  </svg>
                  <svg className="Ellipse_765_paylinkdet">
                    <ellipse id="Ellipse_765_paylinkdet" rx="2" ry="2" cx="2" cy="2">
                    </ellipse>
                  </svg>
                  <svg className="Ellipse_766_paylinkdet">
                    <ellipse id="Ellipse_766_paylinkdet" rx="2" ry="2" cx="2" cy="2">
                    </ellipse>
                  </svg>
                  <svg className="Ellipse_767_paylinkdet">
                    <ellipse id="Ellipse_767_paylinkdet" rx="2" ry="2" cx="2" cy="2">
                    </ellipse>
                  </svg>
                </div>
              </div>
            </div>
            <div id="Group_4599_paylinkdet">
              <div id="Group_3969_paylinkdet">
                <svg className="Rectangle_2057_f_paylinkdet">
                  <rect id="Rectangle_2057_f_paylinkdet" style={{ fill: isActive ? "" : "rgb(118, 162, 255)" }} rx="5" ry="5" x="0" y="0" width="93" height="30">
                  </rect>
                </svg>
              </div>
              <div id="ACTIVE_paylinkdet" style={{ color: isActive ? "" : "white" }}>
                <span>{isActive ? "ACTIVE" : "EXPIRED"}</span>
              </div>
            </div>
          </div>
          <div id="Group_4590_paylinkdet">
            <svg className="Line_paylinkdet" viewBox="0 1 1337.868 1">
              <path id="Line_paylinkdet" d="M 0 1 L 1337.867553710938 1">
              </path>
            </svg>
            <div id="All_f_paylinkdet">
              <span>Checkout Details</span>
            </div>

            <div id="Group_5174_paylinkdet">
              <div id="Date_Created_paylinkdet">
                <span>Date Created</span>
              </div>
              <div id="n_4_July_164500_PM_paylinkdet">
                <span>{datetimeFormatter_human(PaymentLinkDetail['created_at'])}</span>
              </div>
            </div>
            <div id="Group_5175_paylinkdet">
              <div id="Status_paylinkdet">
                <span>Status</span>
              </div>
              <div id="Group_4653_paylinkdet">
                <div id="Group_3969_gj_paylinkdet">
                  <svg className="Rectangle_2057_gk_paylinkdet">
                    <rect id="Rectangle_2057_gk_paylinkdet" style={{ fill: isActive ? "" : "rgb(118, 162, 255)" }} rx="7" ry="7" x="0" y="0" width="84" height="18">
                    </rect>
                  </svg>
                </div>
                <div id="active__paylinkdet">
                  <span>{isActive ? "active" : "expired"}</span>
                </div>
              </div>
            </div>
            <div id="Group_5176_paylinkdet">
              <div id="Allow_tipping_paylinkdet">
                <span>Expiry Date</span>
              </div>
              <div id="Yes_paylinkdet">
                <span>{datetimeFormatter_human(PaymentLinkDetail['expiry_date'])}</span>
              </div>
            </div>
          </div>

          <div id="Group_6591_paylinkdet">
            <div id="Group_4738_paylinkdet">
              <div id="Group_3969_g_paylinkdet">
                <svg className="Rectangle_2057_g_paylinkdet">
                  <rect id="Rectangle_2057_g_paylinkdet" rx="5" ry="5" x="0" y="0" width="135" height="35">
                  </rect>
                </svg>
              </div>
            </div>
            <div id="PAYMENT_Link_paylinkdet">
              <span>PAYMENT Link</span>
            </div>
          </div>
        </main>
        <Wrapper navigation="Payments"></Wrapper>
      </MediaQuery>




      <MediaQuery maxWidth={1000}>
        {/* <div id="MobilePaymentLinkDetail_paylinkdet_mob"
                  style={{
                    left:"20px",
                    transformOrigin: "0px 0px",
                    transform: "scale(0.83)",
                    display: "flex",
                    justifyContent: "center", 
                    alignItems: "center", 
                }}>
                    <div id="Group_7501_paylinkdet_mob">
                        <svg className="Line_paylinkdet_mob" viewBox="0 1 414 0.5">
                            <path id="Line_paylinkdet_mob" d="M 0 1 L 414 1">
                            </path>
                        </svg>
                        <div id="All_paylinkdet_mob">
                            <span>Payment link Details</span>
                        </div>
                        <div id="Group_7495_paylinkdet_mob">
                            <div id="Total_Amount_paylinkdet_mob">
                                <span>Total Amount</span>
                            </div>
                            <div id="Adidas_Ultraboost_paylinkdet_mob">
                                <span>{PaymentLinkDetail['currency']}. {PaymentLinkDetail['amount']}.00</span>
                            </div>
                        </div>
                        <div id="Group_7497_paylinkdet_mob">
                            <div id="Link_Expiry_paylinkdet_mob">
                                <span>Link Expiry</span>
                            </div>
                            <div id="n_4_July_2023_paylinkdet_mob">
                                <span>{datetimeFormatter_human(PaymentLinkDetail['expiry_date'])}</span>
                            </div>
                        </div>
                        <div id="Group_7496_paylinkdet_mob">
                            <div id="Status_paylinkdet_mob">
                                <span>Status</span>
                            </div>
                            <div id="Group_4653_paylinkdet_mob">
                                <div id="Group_3969_paylinkdet_mob">
                                    <svg className="Rectangle_2057_paylinkdet_mob">
                                        <rect id="Rectangle_2057_paylinkdet_mob" rx="7" ry="7" x="0" y="0" width="78" height="17">
                                        </rect>
                                    </svg>
                                </div>
                                <div id="Paid_paylinkdet_mob">
                                    <span>Paid</span>
                                </div>
                            </div>
                        </div>
                        <div id="Group_7498_paylinkdet_mob">
                            <div id="Reference_paylinkdet_mob">
                                <span>Reference</span>
                            </div>
                            <div id="LNK-LSCER23MhzsZRlxu97N7hF0uQt_paylinkdet_mob">
                                <span>{PaymentLinkDetail['slug']}</span>
                            </div>
                        </div>
                        <div id="Group_7499_paylinkdet_mob">
                            <div id="Created_paylinkdet_mob">
                                <span>Created</span>
                            </div>
                            <div id="n_3_July_2023_paylinkdet_mob">
                                <span>{datetimeFormatter_human(PaymentLinkDetail['created_at'])}</span>
                            </div>
                        </div>
                    </div>
                    <div id="Group_4854_paylinkdet_mob">
                        <svg className="Rectangle_2175_paylinkdet_mob">
                            <rect id="Rectangle_2175_paylinkdet_mob" rx="7.5" ry="7.5" x="0" y="0" width="350" height="50">
                            </rect>
                        </svg>
                        <div id="Copy_and_share_this_link__to_i_paylinkdet_mob">
                            <span>Copy and share this link  to initiate checkout <br />and accept payments.</span>
                        </div>
                        <div id="Layer_2_paylinkdet_mob">
                            <div id="link_paylinkdet_mob">
                                <svg className="Rectangle_2185_paylinkdet_mob">
                                    <rect id="Rectangle_2185_paylinkdet_mob" rx="0" ry="0" x="0" y="0" width="24" height="24">
                                    </rect>
                                </svg>
                                <svg className="Path_9119_paylinkdet_mob" viewBox="8 11 8 2">
                                    <path id="Path_9119_paylinkdet_mob" d="M 8 12 C 8 12.55228424072266 8.447714805603027 13 9 13 L 15 13 C 15.55228519439697 13 16 12.55228424072266 16 12 C 16 11.44771480560303 15.55228424072266 11 15 11 L 9 11 C 8.447714805603027 11 8 11.44771575927734 8 12 Z">
                                    </path>
                                </svg>
                                <svg className="Path_9120_paylinkdet_mob" viewBox="0.998 5.999 9.002 12.001">
                                    <path id="Path_9120_paylinkdet_mob" d="M 9 16 L 7.210000038146973 16 C 5.080501556396484 16.05684089660645 3.257140636444092 14.48468017578125 2.999999046325684 12.36999893188477 C 2.895629405975342 11.24662971496582 3.270514488220215 10.13142204284668 4.03226900100708 9.29920482635498 C 4.794022560119629 8.466989517211914 5.871800899505615 7.995162010192871 6.999999046325684 8 L 9 8 C 9.552285194396973 8 10 7.552284717559814 10 7 C 10 6.447715282440186 9.552284240722656 6 9 6 L 7.210000038146973 6 C 4.145279884338379 5.959044933319092 1.518198013305664 8.180975914001465 1.050000667572021 11.20999908447266 C 0.822291374206543 12.92437076568604 1.345400810241699 14.65362358093262 2.485186100006104 15.95432090759277 C 3.624971389770508 17.25501823425293 5.270570278167725 18.00064468383789 6.999999046325684 18 L 9 18 C 9.552285194396973 18 10 17.55228424072266 10 17 C 10 16.44771575927734 9.552284240722656 16 9 16 Z">
                                    </path>
                                </svg>
                                <svg className="Path_9121_paylinkdet_mob" viewBox="14 5.998 9.048 12.002">
                                    <path id="Path_9121_paylinkdet_mob" d="M 23 11.23999977111816 C 22.53731155395508 8.170629501342773 19.8631420135498 5.925011157989502 16.76000022888184 6 L 15.25 6 C 14.4399995803833 6 14 6.449999809265137 14 7 C 14 7.552284717559814 14.44771480560303 8 15 8 L 16.79000091552734 8 C 18.91949844360352 7.943160057067871 20.74285697937012 9.515320777893066 21 11.63000011444092 C 21.1043701171875 12.75337028503418 20.72948455810547 13.86857795715332 19.96773147583008 14.7007942199707 C 19.20597648620605 15.5330114364624 18.12819862365723 16.00483894348145 17 16 L 15 16 C 14.44771480560303 16 14 16.44771575927734 14 17 C 14 17.55228424072266 14.44771575927734 18 15 18 L 17 18 C 18.73217582702637 18.01395797729492 20.3858585357666 17.27861022949219 21.53573799133301 15.98307991027832 C 22.68561744689941 14.68754959106445 23.21947288513184 12.95827102661133 23 11.23999977111816 Z">
                                    </path>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div id="Group_4840_paylinkdet_mob">
                        <div id="Group_3969_hk_paylinkdet_mob">
                            <svg className="Rectangle_2057_hl_paylinkdet_mob">
                                <rect id="Rectangle_2057_hl_paylinkdet_mob" rx="7" ry="7" x="0" y="0" width="80" height="16">
                                </rect>
                            </svg>
                        </div>
                        <div id="Paid_hm_paylinkdet_mob">
                            <span>Paid</span>
                        </div>
                    </div>
                    <div id="Group_4845_paylinkdet_mob">
                        <div id="Component_73_paylinkdet_mob">
                            <svg className="Rectangle_2127_paylinkdet_mob">
                                <rect id="Rectangle_2127_paylinkdet_mob" rx="5" ry="5" x="0" y="0" width="234" height="30">
                                </rect>
                            </svg>
                            <div id="httpscheckoutchptercodrip_city_paylinkdet_mob">
                                <span>{`${process.env.REACT_APP_BASE_URL}/paymentlinks/${PaymentLinkDetail['slug']}/checkout`}</span>
                            </div>
                        </div>
                        <div id="Group_5593_paylinkdet_mob" onClick={handleCopy}>
                            <div id="Group_3969_hs_paylinkdet_mob">
                                <svg className="Rectangle_2057_ht_paylinkdet_mob">
                                    <rect id="Rectangle_2057_ht_paylinkdet_mob" rx="7" ry="7" x="0" y="0" width="104" height="30">
                                    </rect>
                                </svg>
                            </div>
                            <div id="Copy_link_paylinkdet_mob">
                                <span>Copy link</span>
                            </div>
                        </div>
                        <div id="Group_5594_paylinkdet_mob" style={{ display: showDiv ? "block" : "none" }}>
                            <svg className="Rectangle_2057_hw_paylinkdet_mob">
                                <rect id="Rectangle_2057_hw_paylinkdet_mob" rx="7" ry="7" x="0" y="0" width="49" height="19">
                                </rect>
                            </svg>
                            <div id="Copied__paylinkdet_mob">
                                <span>Copied! </span>
                            </div>
                        </div>
                    </div>
                    <div id="Payment_link_paylinkdet_mob">
                        <span>Payment link</span>
                    </div>
                    <NavLink to={'/payment-links'}>
                        <div id="Group_7465_paylinkdet_mob">
                            <svg className="Rectangle_2103_paylinkdet_mob">
                                <rect id="Rectangle_2103_paylinkdet_mob" rx="5" ry="5" x="0" y="0" width="32" height="32">
                                </rect>
                            </svg>
                            <svg className="Path_9390_paylinkdet_mob" viewBox="0.636 0.05 10.513 18">
                                <path id="Path_9390_paylinkdet_mob" d="M 5.357423305511475 10.11950778961182 C 4.766643047332764 9.528789520263672 4.766643047332764 8.571026802062988 5.357423305511475 7.980309963226318 L 10.7065315246582 2.631746768951416 C 11.29722785949707 2.041119337081909 11.2972297668457 1.083506107330322 10.7065315246582 0.4928695559501648 L 10.7065315246582 0.4928695559501648 C 10.11583614349365 -0.09776583313941956 9.158125877380371 -0.09776552766561508 8.567437171936035 0.4928695559501648 L 1.079220414161682 7.980309963226318 C 0.4884408712387085 8.571026802062988 0.4884408712387085 9.528774261474609 1.079220414161682 10.11950778961182 L 8.567437171936035 17.60687637329102 C 9.158125877380371 18.19756317138672 10.11583614349365 18.19756317138672 10.7065315246582 17.60687637329102 L 10.7065315246582 17.60687637329102 C 11.2972297668457 17.0163402557373 11.2972297668457 16.05868339538574 10.7065315246582 15.46799468994141 L 5.357423305511475 10.11950778961182 Z">
                                </path>
                            </svg>
                        </div>
                    </NavLink>
                    <div id="All_h_paylinkdet_mob">
                        <span>{PaymentLinkDetail['slug']}</span>
                    </div>
                    <div id="All_ia_paylinkdet_mob" style={{ textTransform: "uppercase" }}>
                        <span>{PaymentLinkDetail['currency']}. {PaymentLinkDetail['amount']}.00</span>
                    </div>
                    <div id="Next_Billing_22_December_2021_paylinkdet_mob">
                        <span>Next Billing: {datetimeFormatter_human(PaymentLinkDetail['created_at'])}</span>
                    </div>
                   
                </div> */}

        <div className="mobilepaymentlinkdetail_paylinkdet_mob">
          <div className="mobilepaymentlinkdetail-inner_paylinkdet_mob">
            <div className="group-wrapper_paylinkdet_mob">
              <div className="group-wrapper_paylinkdet_mob">
                <div className="header-parent_paylinkdet_mob">
                  <div className="header_paylinkdet_mob">
                    <div className="frame-parent_paylinkdet_mob">

                      <NavLink to={'/payment-links'}>
                        <div className="group-container_paylinkdet_mob">
                          <div className="rectangle-parent_paylinkdet_mob">
                            <div className="group-child_paylinkdet_mob" />
                            <svg className="path-9390-icon_paylinkdet_mob" viewBox="0.636 0.05 10.513 18">
                              <path id="Path_9390_paylinkdet_mob" d="M 5.357423305511475 10.11950778961182 C 4.766643047332764 9.528789520263672 4.766643047332764 8.571026802062988 5.357423305511475 7.980309963226318 L 10.7065315246582 2.631746768951416 C 11.29722785949707 2.041119337081909 11.2972297668457 1.083506107330322 10.7065315246582 0.4928695559501648 L 10.7065315246582 0.4928695559501648 C 10.11583614349365 -0.09776583313941956 9.158125877380371 -0.09776552766561508 8.567437171936035 0.4928695559501648 L 1.079220414161682 7.980309963226318 C 0.4884408712387085 8.571026802062988 0.4884408712387085 9.528774261474609 1.079220414161682 10.11950778961182 L 8.567437171936035 17.60687637329102 C 9.158125877380371 18.19756317138672 10.11583614349365 18.19756317138672 10.7065315246582 17.60687637329102 L 10.7065315246582 17.60687637329102 C 11.2972297668457 17.0163402557373 11.2972297668457 16.05868339538574 10.7065315246582 15.46799468994141 L 5.357423305511475 10.11950778961182 Z">
                              </path>
                            </svg>
                          </div>
                        </div>
                      </NavLink>


                      <div className="payment-detail_paylinkdet_mob">payment link</div>
                    </div>
                  </div>
                  <div className="paymentlinkdetail_paylinkdet_mob">
                    <div className="kes-15045689-parent_paylinkdet_mob">
                      <div className="all_paylinkdet_mob">{PaymentLinkDetail['currency']}. {PaymentLinkDetail['amount']}.00</div>
                      <div className="wallet-balance_paylinkdet_mob">
                        {PaymentLinkDetail['slug']}
                      </div>
                      <div className="next-billing-22_paylinkdet_mob">
                        Next Billing:{datetimeFormatter_human(PaymentLinkDetail['created_at'])}
                      </div>
                      <div className="group-parent_paylinkdet_mob">

                        {(PaymentLinkDetail.expiry_date >= currentDate) ? (
                          <>
                            <svg className="Rectangle_2057_invdet1_mob">
                                    <rect id="Rectangle_2057_invdet1_mob"  rx="7" ry="7" x="0" y="0" width="79" height="16">
                                    </rect>
                                  </svg>
                            <div className="active2_paylinkdet_mob">Active</div>
                          </>
                        ) : (
                          <>
                            <svg className="Rectangle_2057_invdet1_mob">
                                    <rect id="Rectangle_2057_invdet1_mob" style={{ fill: "rgb(118, 162, 255)" }} rx="7" ry="7" x="0" y="0" width="80" height="16">
                                    </rect>
                                  </svg>
                            <div className="active_paylinkdet_mob">Expired</div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="copylink_paylinkdet_mob" >
                    <div className="copylink-inner_paylinkdet_mob">
                      <div className="frame-container_paylinkdet_mob">
                        <div className="copied-wrapper_paylinkdet_mob" style={{ display: showDiv ? "block" : "none" }}>
                          <b className="mobilepaymentlinkdetail-inner_paylinkdet_mob">{`Copied! `}</b>
                        </div>
                      </div>
                    </div>


                    <div className="frame-group_paylinkdet_mob">
                      <div className="frame-div_paylinkdet_mob">
                        <div className="httpscheckoutchptercodri-wrapper_paylinkdet_mob">
                          <div className="httpscheckoutchptercodri_paylinkdet_mob">
                            {`${process.env.REACT_APP_BASE_URL}/paymentlinks/${PaymentLinkDetail['slug']}/checkout`}
                          </div>
                        </div>
                      </div>
                      <button className="copy-link-wrapper_paylinkdet_mob" onClick={handleCopy}>
                        <b className="copy-link_paylinkdet_mob">Copy link</b>
                      </button>
                    </div>


                  </div>
                  <div className="detailbuttons_paylinkdet_mob">
                    <div className="frame-parent1_paylinkdet_mob">
                      <div className="frame-wrapper1_paylinkdet_mob">
                        <div className="frame-wrapper2_paylinkdet_mob">


                          {/* <img className="link-icon_paylinkdet_mob" alt="" src="/link.svg" /> */}

                          <div className="link-parent_invet_mob">

                            <div className="link-icon_invet_mob">
                              <svg className="Rectangle_2185_invdet_mob">
                                <rect id="Rectangle_2185_invdet_mob" rx="0" ry="0" x="0" y="0" width="24" height="24">
                                </rect>
                              </svg>
                              <svg className="Path_9119_invdet_mob" viewBox="8 11 8 2">
                                <path id="Path_9119_invdet_mob" d="M 8 12 C 8 12.55228424072266 8.447714805603027 13 9 13 L 15 13 C 15.55228519439697 13 16 12.55228424072266 16 12 C 16 11.44771480560303 15.55228424072266 11 15 11 L 9 11 C 8.447714805603027 11 8 11.44771575927734 8 12 Z">
                                </path>
                              </svg>
                              <svg className="Path_9120_invdet_mob" viewBox="0.998 5.999 9.002 12.001">
                                <path id="Path_9120_invdet_mob" d="M 9 16 L 7.210000038146973 16 C 5.080501556396484 16.05684089660645 3.257140636444092 14.48468017578125 2.999999046325684 12.36999893188477 C 2.895629405975342 11.24662971496582 3.270514488220215 10.13142204284668 4.03226900100708 9.29920482635498 C 4.794022560119629 8.466989517211914 5.871800899505615 7.995162010192871 6.999999046325684 8 L 9 8 C 9.552285194396973 8 10 7.552284717559814 10 7 C 10 6.447715282440186 9.552284240722656 6 9 6 L 7.210000038146973 6 C 4.145279884338379 5.959044933319092 1.518198013305664 8.180975914001465 1.050000667572021 11.20999908447266 C 0.822291374206543 12.92437076568604 1.345400810241699 14.65362358093262 2.485186100006104 15.95432090759277 C 3.624971389770508 17.25501823425293 5.270570278167725 18.00064468383789 6.999999046325684 18 L 9 18 C 9.552285194396973 18 10 17.55228424072266 10 17 C 10 16.44771575927734 9.552284240722656 16 9 16 Z">
                                </path>
                              </svg>
                              <svg className="Path_9121_invdet_mob" viewBox="14 5.998 9.048 12.002">
                                <path id="Path_9121_invdet_mob" d="M 23 11.23999977111816 C 22.53731155395508 8.170629501342773 19.8631420135498 5.925011157989502 16.76000022888184 6 L 15.25 6 C 14.4399995803833 6 14 6.449999809265137 14 7 C 14 7.552284717559814 14.44771480560303 8 15 8 L 16.79000091552734 8 C 18.91949844360352 7.943160057067871 20.74285697937012 9.515320777893066 21 11.63000011444092 C 21.1043701171875 12.75337028503418 20.72948455810547 13.86857795715332 19.96773147583008 14.7007942199707 C 19.20597648620605 15.5330114364624 18.12819862365723 16.00483894348145 17 16 L 15 16 C 14.44771480560303 16 14 16.44771575927734 14 17 C 14 17.55228424072266 14.44771575927734 18 15 18 L 17 18 C 18.73217582702637 18.01395797729492 20.3858585357666 17.27861022949219 21.53573799133301 15.98307991027832 C 22.68561744689941 14.68754959106445 23.21947288513184 12.95827102661133 23 11.23999977111816 Z">
                                </path>
                              </svg>
                            </div>



                            <div className="copy-and-share-this-link-with-wrapper_invet_mob">
                              <div className="copy-and-share_invet_mob">
                                Copy and share this link to initiate checkout and
                                accept payments.
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                      
                    </div>
                  </div>
                  <div className="paymentlinkdetails_paylinkdet_mob">
                    <div className="paymentlinkdetails-inner_paylinkdet_mob">
                      <div className="frame-parent2_paylinkdet_mob">
                        <div className="all-wrapper_paylinkdet_mob">
                          <div className="all_paylinkdet_mob">Payment Link Details</div>
                        </div>
                        <div className="paymentlinkdetails-inner_paylinkdet_mob">


                          <svg className="line-icon_paylinkdet_mob " viewBox="0 1 414 0.5">
                            <path id="line-icon_paylinkdet_mob " d="M 0 1 L 414 1">
                            </path>
                          </svg>

                        </div>
                      </div>
                    </div>
                    <div className="paymentlinkdetails-child_paylinkdet_mob">
                      <div className="frame-parent3_paylinkdet_mob">
                        <div className="reference-parent_paylinkdet_mob">
                          <div className="reference_paylinkdet_mob">Total Amount</div>
                          <div className="adidas-ultraboost-wrapper_paylinkdet_mob">
                            <div className="adidas-ultraboost_paylinkdet_mob">{PaymentLinkDetail['currency']}. {PaymentLinkDetail['amount']}.00</div>
                          </div>
                        </div>
                        <div className="reference-parent_paylinkdet_mob">
                          <div className="reference_paylinkdet_mob">Created</div>
                          <div className="adidas-ultraboost-container_paylinkdet_mob">
                            <div className="adidas-ultraboost1_paylinkdet_mob">{datetimeFormatter_human(PaymentLinkDetail['expiry_date'])}</div>
                          </div>
                        </div>
                        <div className="reference-parent_paylinkdet_mob">
                          <div className="reference_paylinkdet_mob">Link Expiry</div>
                          <div className="adidas-ultraboost-wrapper_paylinkdet_mob">
                            <div className="adidas-ultraboost_paylinkdet_mob">{datetimeFormatter_human(PaymentLinkDetail['expiry_date'])}</div>
                          </div>
                        </div>
                        <div className="reference-parent_paylinkdet_mob">
                          <div className="reference_paylinkdet_mob">Reference</div>
                          <div className="adidas-ultraboost-wrapper_paylinkdet_mob">
                            <div className="adidas-ultraboost3_paylinkdet_mob">
                              {PaymentLinkDetail['slug']}
                            </div>
                          </div>
                        </div>
                        <div className="reference-parent_paylinkdet_mob">
                          <div className="status_paylinkdet_mob">Status</div>
                          <div className="frame-wrapper3_paylinkdet_mob">
                            <div className="frame-parent4_paylinkdet_mob">
                              <div className="frame-child_paylinkdet_mob" />
                              {(PaymentLinkDetail.expiry_date >= currentDate) ? (
                                <>
                                  <svg className="Rectangle_2057_invdet_mob">
                                    <rect id="Rectangle_2057_invdet_mob"  rx="7" ry="7" x="0" y="0" width="79" height="16">
                                    </rect>
                                  </svg><div className="success_paylinkdet_mob">Active</div>
                                </>
                              ) : (
                                <>
                                  <svg className="Rectangle_2057_invdet_mob">
                                    <rect id="Rectangle_2057_invdet_mob" style={{ fill: "rgb(118, 162, 255)" }} rx="7" ry="7" x="0" y="0" width="80" height="16">
                                    </rect>
                                  </svg><div className="success_paylinkdet_mob">Expired</div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="customerdetails_paylinkdet_mob">
          <div className="paymentlinkdetails-inner_paylinkdet_mob">
            <div className="frame-parent2_paylinkdet_mob">
              <div className="all-wrapper_paylinkdet_mob">
                <div className="all_paylinkdet_mob">Customer Details</div>
              </div>
              <div className="paymentlinkdetails-inner_paylinkdet_mob">
                <img className="line-icon_paylinkdet_mob" alt="" src="/line.svg" />
              </div>
            </div>
          </div>
          <div className="paymentlinkdetails-child_paylinkdet_mob">
            <div className="frame-parent3_paylinkdet_mob">
              <div className="reference-parent_paylinkdet_mob">
                <div className="reference_paylinkdet_mob">Paid By</div>
                <div className="adidas-ultraboost-wrapper_paylinkdet_mob">
                  <div className="adidas-ultraboost_paylinkdet_mob">
                    jessicalagat23@gmail.com
                  </div>
                </div>
              </div>
              <div className="reference-parent_paylinkdet_mob">
                <div className="reference_paylinkdet_mob">Customer Name</div>
                <div className="adidas-ultraboost-wrapper_paylinkdet_mob">
                  <div className="adidas-ultraboost_paylinkdet_mob">Jessica Lagat</div>
                </div>
              </div>
              <div className="reference-parent_paylinkdet_mob">
                <div className="reference_paylinkdet_mob">Phone No.</div>
                <div className="adidas-ultraboost-container_paylinkdet_mob">
                  <div className="adidas-ultraboost1_paylinkdet_mob">07 23 556 762</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="payment-history_paylinkdet_mob">
          <div className="paymentlinkdetails-inner_paylinkdet_mob">
            <div className="frame-parent2_paylinkdet_mob">
              <div className="all-wrapper_paylinkdet_mob">
                <div className="all_paylinkdet_mob">Payment History</div>
              </div>
              <div className="paymentlinkdetails-inner_paylinkdet_mob">
                <img className="line-icon_paylinkdet_mob" alt="" src="/line.svg" />
              </div>
            </div>
          </div>
          <div className="payment-history-child_paylinkdet_mob">
            <div className="frame-parent8_paylinkdet_mob">
              <div className="adidas-ultraboost-parent_paylinkdet_mob">
                <div className="reference_paylinkdet_mob">KES 3,500.00</div>
                <div className="ksh-4500_paylinkdet_mob">
                  SUB-LSCER23MhzsZRlxu97N7hF0uQt
                </div>
              </div>
              <div className="quantity-wrapper_paylinkdet_mob">
                <div className="quantity_paylinkdet_mob">24 July, 12:23 PM</div>
              </div>
            </div>
          </div>
        </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>


      </MediaQuery>
    </>
  )
};

export default PaymentLinksDetail;

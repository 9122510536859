import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import MediaQuery from "react-responsive";
import Avatar from 'react-avatar';
import { datetimeFormatter_year } from '../../utils/date_formatter';


const ChatThreadHeader = (props: any) => {
    const { index, customerWhatsappProfile, hideMessagesFunc } = props.headerPropObj;

    const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const current = new Date();
    const date = `${current.getDate()} ${month[current.getMonth()]}, ${current.getFullYear()}`;

    return (
        <>

            <MediaQuery minWidth={1001}>
                <>
                    <main className="form-signin">
                        <div id="Chat_Thread_Header_thrdhed">
                            <div id="Component_94__5_thrdhed" className="Component_94___5_thrdhed">
                                <div id="Group_7028_thrdhed">
                                    <Avatar name={customerWhatsappProfile.name != '' ? customerWhatsappProfile.name : 'Customer ' + (index + 1)} size="80" round="40px" color="rgb(195,230,179)" />

                                </div>
                                <div id="Fernando_thrdhed">
                                    <span>{customerWhatsappProfile.name != '' ? customerWhatsappProfile.name : 'Customer ' + (index + 1)}</span>
                                </div>
                                <div id="This_is_the_beginning_of_your__thrdhed">
                                    <span>+{customerWhatsappProfile.phone_number}</span>
                                </div>
                                <div id="Group_7029_thrdhed">
                                    <div id="n_9_January_2023_thrdhed">
                                        <span>{datetimeFormatter_year(customerWhatsappProfile.updated_at)}</span>
                                    </div>
                                    <svg className="Line_thrdhed" viewBox="0 1 325 1">
                                        <path id="Line_thrdhed" d="M 0 1 L 325 1">
                                        </path>
                                    </svg>
                                    <svg className="Line_fs_thrdhed" viewBox="0 1 325 1">
                                        <path id="Line_fs_thrdhed" d="M 0 1 L 325 1">
                                        </path>
                                    </svg>
                                </div>
                            </div>
                            <div id="Group_7406_thrdhed">
                                <svg className="Rectangle_2541_thrdhed">
                                    <rect id="Rectangle_2541_thrdhed" rx="0" ry="0" x="0" y="0" width="947" height="70">
                                    </rect>
                                </svg>
                                <div id="Group_7404_thrdhed">
                                    <svg className="Rectangle_2537_thrdhed">
                                        <rect id="Rectangle_2537_thrdhed" rx="20" ry="20" x="0" y="0" width="703" height="40">
                                        </rect>
                                    </svg>
                                    <div id="Layer_2_thrdhed">
                                        <div id="smiling-face_thrdhed">
                                            <div id="smiling-face_fz_thrdhed">
                                                <svg className="Rectangle_2539_thrdhed">
                                                    <rect id="Rectangle_2539_thrdhed" rx="0" ry="0" x="0" y="0" width="25" height="25">
                                                    </rect>
                                                </svg>
                                                <svg className="n_Icon-olor_thrdhed" viewBox="2 2 17.867 18.867">
                                                    <path id="n_Icon-olor_thrdhed" d="M 10.93356037139893 2 C 15.8675651550293 2 19.86712074279785 6.223405838012695 19.86712074279785 11.43356037139893 C 19.86712074279785 16.64371299743652 15.8675651550293 20.86712074279785 10.93356037139893 20.86712074279785 C 5.999556064605713 20.86712074279785 2 16.64371681213379 2 11.43356037139893 C 2 6.223404407501221 5.999555587768555 2 10.93356037139893 2 Z M 10.93356037139893 3.886712074279785 C 6.986464500427246 3.886712074279785 3.786712408065796 7.265551567077637 3.786712408065796 11.43356037139893 C 3.786712408065796 15.60156917572021 6.986464977264404 18.98040771484375 10.93356037139893 18.98040771484375 C 14.88065528869629 18.98040771484375 18.08040809631348 15.60156917572021 18.08040809631348 11.43356037139893 C 18.08040809631348 7.26555061340332 14.88065528869629 3.886712074279785 10.93356037139893 3.886712074279785 Z M 15.40034103393555 12.37691688537598 C 15.40034103393555 14.98192310333252 13.4004955291748 17.09369659423828 10.93356037139893 17.09369659423828 C 8.466626167297363 17.09369659423828 6.466780662536621 14.98192310333252 6.466780185699463 12.37691879272461 L 15.40034103393555 12.37691688537598 Z">
                                                    </path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="Group_7403_thrdhed">
                                    <svg className="Ellipse_800_thrdhed">
                                        <ellipse id="Ellipse_800_thrdhed" rx="20.5" ry="20.5" cx="20.5" cy="20.5">
                                        </ellipse>
                                    </svg>
                                    <div id="Layer_2_f_thrdhed">
                                        <div id="plus_thrdhed">
                                            <svg className="Rectangle_2538_thrdhed">
                                                <rect id="Rectangle_2538_thrdhed" rx="0" ry="0" x="0" y="0" width="25" height="25">
                                                </rect>
                                            </svg>
                                            <svg className="Path_9335_thrdhed" viewBox="4 4 12.694 12.694">
                                                <path id="Path_9335_thrdhed" d="M 15.90034008026123 9.553492546081543 L 11.14020442962646 9.553492546081543 L 11.14020442962646 4.793355941772461 C 11.14020442962646 4.355197906494141 10.78500652313232 4 10.346848487854 4 C 9.908688545227051 4 9.553492546081543 4.355197906494141 9.553492546081543 4.793355941772461 L 9.553492546081543 9.553492546081543 L 4.793355941772461 9.553492546081543 C 4.355197906494141 9.553492546081543 4 9.908690452575684 4 10.346848487854 C 4 10.78500747680664 4.355197906494141 11.14020442962646 4.793355941772461 11.14020442962646 L 9.553492546081543 11.14020442962646 L 9.553492546081543 15.90034008026123 C 9.553492546081543 16.33849906921387 9.908688545227051 16.69369697570801 10.346848487854 16.69369697570801 C 10.78500652313232 16.69369697570801 11.14020442962646 16.33849906921387 11.14020442962646 15.90034008026123 L 11.14020442962646 11.14020442962646 L 15.90034008026123 11.14020442962646 C 16.33849906921387 11.14020442962646 16.69369697570801 10.78500652313232 16.69369697570801 10.346848487854 C 16.69369697570801 9.908688545227051 16.33849906921387 9.553492546081543 15.90034008026123 9.553492546081543 Z">
                                                </path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div id="Group_7402_thrdhed">
                                    <svg className="Ellipse_800_f_thrdhed">
                                        <ellipse id="Ellipse_800_f_thrdhed" rx="20.5" ry="20.5" cx="20.5" cy="20.5">
                                        </ellipse>
                                    </svg>
                                    <div id="Layer_2_ga_thrdhed">
                                        <div id="message-square_thrdhed">
                                            <svg className="Rectangle_2540_thrdhed">
                                                <rect id="Rectangle_2540_thrdhed" rx="0" ry="0" x="0" y="0" width="25" height="25">
                                                </rect>
                                            </svg>
                                            <svg className="Ellipse_801_thrdhed">
                                                <ellipse id="Ellipse_801_thrdhed" rx="1" ry="1" cx="1" cy="1">
                                                </ellipse>
                                            </svg>
                                            <svg className="Ellipse_802_thrdhed">
                                                <ellipse id="Ellipse_802_thrdhed" rx="1" ry="1" cx="1" cy="1">
                                                </ellipse>
                                            </svg>
                                            <svg className="Ellipse_803_thrdhed">
                                                <ellipse id="Ellipse_803_thrdhed" rx="1" ry="1" cx="1" cy="1">
                                                </ellipse>
                                            </svg>
                                            <svg className="Path_9338_thrdhed" viewBox="2 3 21 20">
                                                <path id="Path_9338_thrdhed" d="M 19.84999847412109 3.000000476837158 L 5.150000095367432 3.000000476837158 C 3.410302877426147 3.000000476837158 1.999999284744263 4.413837909698486 1.999999761581421 6.157894611358643 L 1.999999761581421 21.94736862182617 C 2.000652313232422 22.32711982727051 2.205288648605347 22.67707824707031 2.535500049591064 22.86315727233887 C 2.692428350448608 22.95231819152832 2.869621992111206 22.99944496154785 3.049999952316284 23 C 3.238457441329956 22.99994468688965 3.423426866531372 22.94904136657715 3.58549976348877 22.85263061523438 L 8.299999237060547 19.98947334289551 C 8.474263191223145 19.88546752929688 8.67484188079834 19.83428382873535 8.877497673034668 19.84210395812988 L 19.84999847412109 19.84210395812988 C 21.58969497680664 19.84210395812988 22.99999809265137 18.42826843261719 22.99999809265137 16.68421173095703 L 22.99999809265137 6.157894611358643 C 22.99999809265137 4.413837432861328 21.58969497680664 3.000000476837158 19.84999847412109 3.000000476837158 Z M 20.89999771118164 16.68421173095703 C 20.89999771118164 17.26556396484375 20.42989730834961 17.73684120178223 19.84999847412109 17.73684120178223 L 8.877497673034668 17.73684120178223 C 8.304013252258301 17.73638343811035 7.741284370422363 17.89289093017578 7.249999046325684 18.18947410583496 L 4.099999904632568 20.08420944213867 L 4.099999904632568 6.157894611358643 C 4.099999904632568 5.576541900634766 4.570100784301758 5.105262756347656 5.150000095367432 5.105262756347656 L 19.84999847412109 5.105262756347656 C 20.42989730834961 5.105262756347656 20.89999771118164 5.576541900634766 20.89999771118164 6.157894611358643 L 20.89999771118164 16.68421173095703 Z">
                                                </path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div id="Group_7405_thrdhed">
                                    <svg className="Ellipse_799_thrdhed">
                                        <ellipse id="Ellipse_799_thrdhed" rx="20.5" ry="20.5" cx="20.5" cy="20.5">
                                        </ellipse>
                                    </svg>
                                    <div id="Group_7068_thrdhed">
                                        <svg className="Path_9336_thrdhed" viewBox="-7.49 9.102 29.11 8.331">
                                            <path id="Path_9336_thrdhed" d="M -7.490234375 9.1015625 L 21.6201171875 17.43298721313477 L -4.630783557891846 16.43239974975586 L -7.490234375 9.1015625 Z">
                                            </path>
                                        </svg>
                                        <svg className="Path_9337_thrdhed" viewBox="-7.49 9.102 29.11 8.331">
                                            <path id="Path_9337_thrdhed" d="M -7.490234375 17.43298721313477 L 21.6201171875 9.1015625 L -4.630783557891846 10.10214996337891 L -7.490234375 17.43298721313477 Z">
                                            </path>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </>
            </MediaQuery>

            <MediaQuery maxWidth={1000}>
                <>
                    

                    <div className="chatheader_thrdbdy_mob">
                        <div className="chatinfo_thrdbdy_mob">
                            <div className="frame-parent9_thrdbdy_mob">
                                <div className="group-wrapper8_thrdbdy_mob" onClick={hideMessagesFunc}>
                                    <div className="group-container_lnkbsktdet_mob">
                                        <div className="rectangle-parent_lnkbsktdet_mob">
                                            <div className="group-child_lnkbsktdet_mob"></div>
                                            <svg className="path-9390-icon_lnkbsktdet_mob" viewBox="-2531.439 -24548.92 9.122 17.701"><path id="Path_9314_lnkbsktdet_mob" d="M -2522.3173828125 -24548.919921875 C -2523.570068359375 -24547.79296875 -2531.48486328125 -24540.103515625 -2531.43896484375 -24540.0703125 C -2531.452392578125 -24540.060546875 -2522.74560546875 -24531.21875 -2522.74560546875 -24531.21875">
                                            </path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="frame-parent10_thrdbdy_mob">
                                <div className="group-wrapper9_thrdbdy_mob">
                                    <div className="ellipse-parent_thrdbdy_mob">

                                        {customerWhatsappProfile.profile_image === "" ? (
                                            <Avatar name={customerWhatsappProfile.name != '' ? customerWhatsappProfile.name : 'Customer ' + (index + 1)} size="50" round="50px" color="rgb(195,230,179)"/>
                                        ) : (
                                            <img id="Rectangle_2524_thrdbdy_mob" src={customerWhatsappProfile.profile_image} />
                                        )}
                                    </div>
                                </div>
                                <div className="fernando-parent_thrdbdy_mob">
                                    <div className="fernando_thrdbdy_mob">{customerWhatsappProfile.name != '' ? customerWhatsappProfile.name : 'Customer ' + (index + 1)}</div>
                                    <div className="xx-xxx-xxx_thrdbdy_mob">+{customerWhatsappProfile.phone_number}</div>
                                </div>
                            </div>
                        </div>
                        <div className="chatheader-inner_thrdbdy_mob">

                            <div className="summary-inner_chktlnk_mob">
                                <div className="alpineallstars-wrapper_chktlnk_mob">
                                    <div className="line-container_chktlnk_mob">
                                        <div className="line-div_chktlnk_mob" />
                                    </div>
                                    <div className="alpineallstars-wrapper_chktlnk_mob">
                                        <div className="your-summary_chktlnk_mob">{datetimeFormatter_year(customerWhatsappProfile.updated_at)}</div>
                                    </div>
                                    <div className="line-container_chktlnk_mob">
                                        <div className="line-div_chktlnk_mob" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </MediaQuery>


        </>
    )
};

export default ChatThreadHeader;
ChatThreadHeader.displayName = 'ChatThreadHeader';

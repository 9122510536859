import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import '../../styles/Wallet/swapresponse.css'
import MediaQuery from 'react-responsive';


const SwapResponse = () => {

  const location = useLocation();
  const converted_swap_value = location.state.converted_swap_value
  const ExchangeRate = location.state.ExchangeRate
  const swap_value = location.state.swap_value
  const KES_wallet_balance = location.state.KES_wallet_balance


  return (
    <>

      <MediaQuery maxWidth={1000}>

        <div className="swapcomplete_waswa_mob">
          <div className="swapcomplete-inner_waswa_mob">
            <div className="group-wrapper_waswa_mob">
              <div className="group-wrapper_waswa_mob">
                <div className="complete_waswa_mob">
                  <div className="header_waswa_mob">
                    <div className="frame-parent_waswa_mob">



                      <div className="payment-detail_waswa_mob">Swap Request Complete!</div>
                    </div>
                  </div>
                  <div className="checkmark-circle-2-wrapper_waswa_mob">


                    <div className="checkmark-circle-2-icon_waswa_mob">
                      <div id="checkmark-circle-2_waswa">
                        <svg className="Rectangle_2089_waswa">
                          <rect id="Rectangle_2089_waswa" rx="0" ry="0" x="0" y="0" width="194" height="194">
                          </rect>
                        </svg>
                        <svg className="Path_9060_waswa" viewBox="2 2 163.357 163.357">
                          <path id="Path_9060_waswa" d="M 83.67869567871094 2.000000238418579 C 38.56880187988281 2.000000238418579 2.000000238418579 38.56880569458008 2.000000238418579 83.67869567871094 C 2.000000238418579 128.7886199951172 38.56880569458008 165.3573913574219 83.67869567871094 165.3573913574219 C 128.7886199951172 165.3573913574219 165.3573913574219 128.78857421875 165.3573913574219 83.67869567871094 C 165.3573913574219 38.56880187988281 128.78857421875 2.000000238418579 83.67869567871094 2.000000238418579 Z M 83.67869567871094 149.0216522216797 C 47.59078216552734 149.0216522216797 18.33574104309082 119.7666244506836 18.33574104309082 83.67869567871094 C 18.33574104309082 47.59078216552734 47.59078598022461 18.33574104309082 83.67869567871094 18.33574104309082 C 119.7666244506836 18.33574104309082 149.0216522216797 47.59078598022461 149.0216522216797 83.67869567871094 C 149.0216522216797 119.7666244506836 119.7666244506836 149.0216522216797 83.67869567871094 149.0216522216797 Z">
                          </path>
                        </svg>
                        <svg className="Path_9061_waswa" viewBox="7.499 7.994 73.489 65.393">
                          <path id="Path_9061_waswa" d="M 66.31731414794922 11.22909736633301 L 35.44277191162109 52.06844329833984 L 22.12914276123047 34.83424377441406 C 19.35488319396973 31.27055549621582 14.21696662902832 30.63060188293457 10.65328407287598 33.40486145019531 C 7.089601993560791 36.17913055419922 6.449650287628174 41.31703948974609 9.223909378051758 44.88071441650391 L 29.07183837890625 70.28277587890625 C 30.63043212890625 72.25524139404297 33.01054763793945 73.40010833740234 35.52445220947266 73.38658142089844 C 38.05238342285156 73.3804931640625 40.43503952026367 72.20426940917969 41.97706604003906 70.20109558105469 L 79.30424499511719 21.19388580322266 C 82.05593872070312 17.5851001739502 81.36112976074219 12.42889404296875 77.75234222412109 9.677190780639648 C 74.1435546875 6.925485610961914 68.98735809326172 7.620294570922852 66.23563385009766 11.22908210754395 Z">
                          </path>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="swapamount_waswa_mob">
                    <div className="add-a-new-product-wrapper_waswa_mob">
                      <div className="add-a-new_waswa_mob">Swap Amount</div>
                    </div>
                    <div className="swapamount-inner_waswa_mob">
                      <div className="exchange-rate-of-12250-parent_waswa_mob">
                        <div className="exchange-rate-of_waswa_mob">
                          exchange rate of {ExchangeRate}
                        </div>


                        <svg className="icons8-info-48_waswa_mob" viewBox="2 2 11.66 11.66">
                          <path id="icons8-info-48_waswa" d="M 7.830078125 2 C 4.617120265960693 2 2 4.617120265960693 2 7.830078125 C 2 11.04303646087646 4.617120265960693 13.66015625 7.830078125 13.66015625 C 11.04303646087646 13.66015625 13.66015625 11.04303646087646 13.66015625 7.830078125 C 13.66015625 4.617120265960693 11.04303646087646 2 7.830078125 2 Z M 7.830078125 3.166015625 C 10.41287422180176 3.166015625 12.494140625 5.247282028198242 12.494140625 7.830078125 C 12.494140625 10.41287422180176 10.41287422180176 12.494140625 7.830078125 12.494140625 C 5.247282028198242 12.494140625 3.166015625 10.41287422180176 3.166015625 7.830078125 C 3.166015625 5.247282028198242 5.247282028198242 3.166015625 7.830078125 3.166015625 Z M 7.2470703125 4.9150390625 L 7.2470703125 6.0810546875 L 8.4130859375 6.0810546875 L 8.4130859375 4.9150390625 L 7.2470703125 4.9150390625 Z M 7.2470703125 7.2470703125 L 7.2470703125 10.7451171875 L 8.4130859375 10.7451171875 L 8.4130859375 7.2470703125 L 7.2470703125 7.2470703125 Z">
                          </path>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="complete-inner_waswa_mob">
                    <div className="frame-wrapper_waswa_mob">
                      <div className="add-a-new-product-parent_waswa_mob">
                        <div className="add-a-new1_waswa_mob">USD</div>
                        <div className="add-a-new2_waswa_mob">{swap_value}</div>
                      </div>
                    </div>
                  </div>
                  <div className="kesamount_waswa_mob">
                    <div className="add-a-new-product-container_waswa_mob">
                      <div className="add-a-new_waswa_mob">KES Amount</div>
                    </div>
                  </div>
                  <div className="complete-child_waswa_mob">
                    <div className="frame-wrapper_waswa_mob">
                      <div className="add-a-new-product-parent_waswa_mob">
                        <div className="add-a-new1_waswa_mob">KES</div>
                        <div className="add-a-new2_waswa_mob">{converted_swap_value}</div>
                      </div>
                    </div>
                  </div>
                  <div className="footer_waswa_mob">
                    <NavLink to={'/wallet'} style={{ textDecoration: 'none', color: "white" }} className="save-changes-wrapper_waswa_mob">
                      <div className="swapcomplete-inner_waswa_mob">PROCEED</div>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </MediaQuery>

      <MediaQuery minWidth={1001}>
        <main className="form-signin">
          <div id="WalletSwapToKESSuccessful_waswa">
            <div id="Group_69621_waswa">
              <div id="All_waswa">
                <span>Swap Request Complete!</span>
              </div>
              <div id="Layer_2_waswa">
                <div id="checkmark-circle-2_waswa">
                  <svg className="Rectangle_2089_waswa">
                    <rect id="Rectangle_2089_waswa" rx="0" ry="0" x="0" y="0" width="194" height="194">
                    </rect>
                  </svg>
                  <svg className="Path_9060_waswa" viewBox="2 2 163.357 163.357">
                    <path id="Path_9060_waswa" d="M 83.67869567871094 2.000000238418579 C 38.56880187988281 2.000000238418579 2.000000238418579 38.56880569458008 2.000000238418579 83.67869567871094 C 2.000000238418579 128.7886199951172 38.56880569458008 165.3573913574219 83.67869567871094 165.3573913574219 C 128.7886199951172 165.3573913574219 165.3573913574219 128.78857421875 165.3573913574219 83.67869567871094 C 165.3573913574219 38.56880187988281 128.78857421875 2.000000238418579 83.67869567871094 2.000000238418579 Z M 83.67869567871094 149.0216522216797 C 47.59078216552734 149.0216522216797 18.33574104309082 119.7666244506836 18.33574104309082 83.67869567871094 C 18.33574104309082 47.59078216552734 47.59078598022461 18.33574104309082 83.67869567871094 18.33574104309082 C 119.7666244506836 18.33574104309082 149.0216522216797 47.59078598022461 149.0216522216797 83.67869567871094 C 149.0216522216797 119.7666244506836 119.7666244506836 149.0216522216797 83.67869567871094 149.0216522216797 Z">
                    </path>
                  </svg>
                  <svg className="Path_9061_waswa" viewBox="7.499 7.994 73.489 65.393">
                    <path id="Path_9061_waswa" d="M 66.31731414794922 11.22909736633301 L 35.44277191162109 52.06844329833984 L 22.12914276123047 34.83424377441406 C 19.35488319396973 31.27055549621582 14.21696662902832 30.63060188293457 10.65328407287598 33.40486145019531 C 7.089601993560791 36.17913055419922 6.449650287628174 41.31703948974609 9.223909378051758 44.88071441650391 L 29.07183837890625 70.28277587890625 C 30.63043212890625 72.25524139404297 33.01054763793945 73.40010833740234 35.52445220947266 73.38658142089844 C 38.05238342285156 73.3804931640625 40.43503952026367 72.20426940917969 41.97706604003906 70.20109558105469 L 79.30424499511719 21.19388580322266 C 82.05593872070312 17.5851001739502 81.36112976074219 12.42889404296875 77.75234222412109 9.677190780639648 C 74.1435546875 6.925485610961914 68.98735809326172 7.620294570922852 66.23563385009766 11.22908210754395 Z">
                    </path>
                  </svg>
                </div>
              </div>
              <div id="Group_6961_waswa">
                <div id="Group_6958_waswa">
                  <svg className="Rectangle_2429_waswa">
                    <rect id="Rectangle_2429_waswa" rx="5" ry="5" x="0" y="0" width="326" height="97">
                    </rect>
                  </svg>
                  <div id="Group_6656_waswa">
                    <div id="All_fa_waswa">
                      <span>{swap_value}</span>
                    </div>
                    <div id="All_fb_waswa">
                      <span>USD</span>
                    </div>
                  </div>
                </div>
                <div id="All_fc_waswa">
                  <span>Swap Amount</span>
                </div>
                <div id="exchange_rate_of_12250_waswa">
                  <span>exchange rate of {ExchangeRate}</span>
                </div>
                <svg className="icons8-info-48_waswa" viewBox="2 2 11.66 11.66">
                  <path id="icons8-info-48_waswa" d="M 7.830078125 2 C 4.617120265960693 2 2 4.617120265960693 2 7.830078125 C 2 11.04303646087646 4.617120265960693 13.66015625 7.830078125 13.66015625 C 11.04303646087646 13.66015625 13.66015625 11.04303646087646 13.66015625 7.830078125 C 13.66015625 4.617120265960693 11.04303646087646 2 7.830078125 2 Z M 7.830078125 3.166015625 C 10.41287422180176 3.166015625 12.494140625 5.247282028198242 12.494140625 7.830078125 C 12.494140625 10.41287422180176 10.41287422180176 12.494140625 7.830078125 12.494140625 C 5.247282028198242 12.494140625 3.166015625 10.41287422180176 3.166015625 7.830078125 C 3.166015625 5.247282028198242 5.247282028198242 3.166015625 7.830078125 3.166015625 Z M 7.2470703125 4.9150390625 L 7.2470703125 6.0810546875 L 8.4130859375 6.0810546875 L 8.4130859375 4.9150390625 L 7.2470703125 4.9150390625 Z M 7.2470703125 7.2470703125 L 7.2470703125 10.7451171875 L 8.4130859375 10.7451171875 L 8.4130859375 7.2470703125 L 7.2470703125 7.2470703125 Z">
                  </path>
                </svg>
              </div>
              <div id="Group_6790_waswa">
                <svg className="Rectangle_2429_fg_waswa">
                  <rect id="Rectangle_2429_fg_waswa" rx="5" ry="5" x="0" y="0" width="326" height="97">
                  </rect>
                </svg>
                <div id="Group_6656_fh_waswa">
                  <div id="All_fi_waswa">
                    <span>{converted_swap_value}</span>
                  </div>
                  <div id="All_fj_waswa">
                    <span>Kes.</span>
                  </div>
                </div>
                <div id="All_fk_waswa">
                  <span>Payout Amount</span>
                </div>
              </div>
              <NavLink to={'/wallet'} className="nav-link" style={{ textDecoration: 'none' }}>
                <div id="Group_6658_waswa">
                  <div id="Group_4741_waswa">
                    <div id="Group_3969_waswa">
                      <svg className="Rectangle_2057_waswa">
                        <rect id="Rectangle_2057_waswa" rx="5" ry="5" x="0" y="0" width="125" height="30">
                        </rect>
                      </svg>
                    </div>
                    <div id="Group_4628_waswa">
                      <div id="PROCEED_waswa">
                        <span>PROCEED</span>
                      </div>
                    </div>
                  </div>
                </div>
              </NavLink>
            </div>
          </div>
        </main>


      </MediaQuery>



    </>
  )
};

export default SwapResponse;

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import '../../styles/Settings/settingsintegrations.css'
import '../../styles/Components/NavBar.css'
import '../../styles/Components/UserRibbon.css'
import '../../styles/Components/HotLinks_Settings.css'
import Wrapper from "../../components/Wrapper";
import HotLinks_Settings from '../../components/HotLinks_Settings';
import MediaQuery from "react-responsive";

import { SuccessfulPayments } from "../../models/payments";
import { Withdrawal } from "../../models/wallet";



const SettingsIntegrations = () => {
    const [facebookSetUpDone, setFacebookSetUpDone] = useState(false)
    const [instagramSetUpDone, setInstagramSetUpDone] = useState(false)
    const [whatsappSetUpDone, setWhatsappSetUpDone] = useState(false)


    const searchParams = new URLSearchParams(window.location.hash.slice(1)); 
    const accessToken = searchParams.get('access_token'); 

    function facebookURL() {
        localStorage.setItem("facebookConnecting", "connecting");
        window.location.href = `https://www.facebook.com/v16.0/dialog/oauth?client_id=697050458773513&display=page&redirect_uri=${process.env.REACT_APP_BASE_URL}/settings-integrations/&response_type=token&scope=pages_show_list%2Cpages_messaging%2Cpages_read_engagement%2Cpages_manage_metadata`
    }

    function instagramURL() {
        localStorage.setItem("instagramConnecting", "connecting");
        window.location.href = `https://www.facebook.com/v16.0/dialog/oauth?client_id=697050458773513&display=page&extras={%22setup%22:{%22channel%22:%22IG_API_ONBOARDING%22}}&redirect_uri=${process.env.REACT_APP_BASE_URL}/settings-integrations/&response_type=token&scope=instagram_basic,instagram_manage_comments,instagram_manage_insights,instagram_manage_messages`
    }

    function whatsappURL() {
        localStorage.setItem("whatsappConnecting", "connecting");
        window.location.href = `https://www.facebook.com/v16.0/dialog/oauth?client_id=697050458773513&display=page&redirect_uri=${process.env.REACT_APP_BASE_URL}/settings-integrations/&response_type=token&scope=whatsapp_business_management%2Cbusiness_management%2Cwhatsapp_business_messaging`
    }


    useEffect(() => {

        async function getPlatform(accessToken: string) {

            if (localStorage.getItem("facebookConnecting") === "connecting") {
                await facebookIntegration(accessToken);
            }

            if (localStorage.getItem("instagramConnecting") === "connecting") {
                await instagramIntegration(accessToken);
            }

            if (localStorage.getItem("whatsappConnecting") === "connecting") {
                await whatsappIntegration(accessToken);
            }

        }

        if (accessToken) {
            getPlatform(accessToken);
        }

        if (localStorage.getItem("facebookConnected") == "true") {
            localStorage.removeItem("facebookConnecting")
            setFacebookSetUpDone(true)
        }

        if (localStorage.getItem("instagramConnected") == "true") {
            localStorage.removeItem("instagramConnecting")
            setInstagramSetUpDone(true)
        }

        if (localStorage.getItem("whatsappConnected") == "true") {
            localStorage.removeItem("whatsappConnecting")
            setWhatsappSetUpDone(true)
        }

    }, [accessToken, setFacebookSetUpDone, setInstagramSetUpDone, setWhatsappSetUpDone])





    // Facebook
    async function facebookIntegration(accessToken: string) {
        let url = 'https://api.chpter.co/v1/facebook/session/' + accessToken
        let header = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem("JWT")
        }

        await axios.put(url, null, { headers: header })
            .then((response) => {


                localStorage.setItem("facebookConnected", "true");
                setFacebookSetUpDone(true)

            })
            .catch((error) => {
                // Error
                if (error.response) {

                } else if (error.request) {

                } else {

                }
            });
    }


    // Instagram
    async function instagramIntegration(accessToken: string) {
        let url = 'https://api.chpter.co/v1/instagram/session/' + accessToken
        let header = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem("JWT")
        }

        await axios.put(url, null, { headers: header })
            .then((response) => {
          

                localStorage.setItem("instagramConnected", "true");
                setInstagramSetUpDone(true)

            })
            .catch((error) => {
                // Error
                if (error.response) {

                } else if (error.request) {

                } else {
                   
                }
            });
    }


    // Whatsapp
    async function whatsappIntegration(accessToken: string) {
        let url = 'https://api.chpter.co/v1/whatsapp/session/' + accessToken
        let header = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem("JWT")
        }

        await axios.put(url, null, { headers: header })
            .then((response) => {

                localStorage.setItem("whatsappConnected", "true");
                setWhatsappSetUpDone(true)

            })
            .catch((error) => {
                // Error
                if (error.response) {

                } else if (error.request) {

                } else {

                }
            });
    }


    const searchParam = new URLSearchParams(window.location.search); 
    const errorCode = searchParam.get('error_code');

    if (errorCode) {
    }


    return (
        <>
            <MediaQuery minWidth={1001}>
                <main className="form-signin">
                    <div id="All_seting">
                        <span>Add channels to your app</span>
                    </div>
                    <div id="Enhance_your_app_experience_Bo_seting">
                        <span>Enhance your app experience. Boost engagement and user satisfaction by integrating your app with various channels.</span>
                    </div>
                    <div id="Group_7887_seting">
                        <div id="Component_119__1_seting" className="Component_119___1_seting">
                            <svg className="Rectangle_2596_seting">
                                <rect id="Rectangle_2596_seting" rx="7" ry="7" x="0" y="0" width="348" height="251">
                                </rect>
                            </svg>
                            <div id="Group_7883_seting">
                                <div id="n_2628_seting">
                                    <span>whatsApp for Business</span>
                                </div>
                                <div id="The_easiest_way_to_stay_in_tou_seting">
                                    <span>The easiest way to stay in touch - get personal with your customers on whatsApp</span>
                                </div>
                                {/* <img id="whatsapp-logo_seting" src="media/whatsapp-logo.png" /> */}
                                <img id="whatsapp-logo_seting" src={"https://chpter-3-jarvis-bucket.s3.amazonaws.com/branding/3tN48O5-whatsapp-logo.png"} />


                            </div>

                            {whatsappSetUpDone ? (
                                <>
                                    <div id="Group_7884__seting">
                                        <NavLink to={{ pathname: '/chat', state: { component: 'whatsapp' } } as any}>
                                            <div id="Group_7882__seting">
                                                <svg className="Rectangle_2057_seting">
                                                    <rect id="Rectangle_2057_seting" style={{ fill: "rgb(220, 248, 198)" }} rx="5" ry="5" x="0" y="0" width="100" height="31">
                                                    </rect>
                                                </svg>
                                                <div id="Set_up_seting">
                                                    <span>Go to Chat</span>
                                                </div>
                                            </div>
                                            <div id="View_steps_seting" style={{ color: "green" }}>
                                                <span>Connected</span>
                                            </div>
                                        </NavLink>
                                        <svg className="Line_seting" viewBox="0 1 293.56 0.4000000059604645">
                                            <path id="Line_seting" d="M 0 1 L 293.5595703125 1">
                                            </path>
                                        </svg>
                                    </div>

                                </>

                            ) : (
                                <>
                                    {/* <div id="Group_7884_seting">
                                    <div id="Group_7882_seting">
                                        <svg className="Rectangle_2057_seting">
                                            <rect id="Rectangle_2057_seting" rx="5" ry="5" x="0" y="0" width="69" height="31">
                                            </rect>
                                        </svg>
                                        <div id="Set_up_seting" onClick={whatsappURL}>
                                            <span>Set up</span>
                                        </div>
                                    </div>
                                    <svg className="Line_seting" viewBox="0 1 293.56 0.4000000059604645">
                                        <path id="Line_seting" d="M 0 1 L 293.5595703125 1">
                                        </path>
                                    </svg>
                                    <div id="View_steps_seting">
                                        <span>View steps</span>
                                    </div>
                                </div> */}

                                    <div id="Group_7884_seting">
                                        <div id="Group_7882_seting" style={{ width: "170px", height: "31px", left: "123.5px" }}>
                                            <svg className="Rectangle_2057_seting" style={{ width: "170px", height: "31px" }}>
                                                <rect id="Rectangle_2057_seting" style={{ fill: "rgb(36,115,242)" }} rx="5" ry="5" x="0" y="0" width="170" height="31">
                                                </rect>
                                            </svg>
                                            <div id="Set_up_seting" style={{ left: "9px", paddingLeft: "25px", color: "white", top: "8px" }} onClick={whatsappURL}>
                                                <span>Login with Facebook</span>
                                                {/* <img id="Facebook_logo_setting" src="media/icons8-facebook-48.png" /> */}
                                                <img id="Facebook_logo_setting" src={"https://chpter-3-jarvis-bucket.s3.amazonaws.com/branding/RpuEpsw-icons8-facebook-488.png"} />
                                            </div>
                                        </div>
                                        <svg className="Line_seting" viewBox="0 1 293.56 0.4000000059604645">
                                            <path id="Line_seting" d="M 0 1 L 293.5595703125 1">
                                            </path>
                                        </svg>
                                        <div id="View_steps_seting">
                                            {/* <span>View steps</span> */}
                                            <span>Integarate</span>
                                        </div>
                                    </div>

                                </>
                            )}

                        </div>
                        {/* TWITTER */}
                        {/* <div id="Component_119__4_seting" className="Component_119___4_seting">
                            <svg className="Rectangle_2596_hn_seting">
                                <rect id="Rectangle_2596_hn_seting" rx="7" ry="7" x="0" y="0" width="348" height="251">
                                </rect>
                            </svg>
                            <div id="Group_7883_ho_seting">
                                <div id="n_2628_hp_seting">
                                    <span>Twitter</span>
                                </div>
                                <div id="Join_the_conversation_on_Twitt_seting">
                                    <span>Join the conversation on Twitter - share updates and connect with your followers.</span>
                                </div>
                                <img id="twitter-logo_seting" src={"https://chpter-3-jarvis-bucket.s3.amazonaws.com/branding/BCyHh0f-twitter-logo.png"}/>


                            </div>
                            <div id="Group_7884_hs_seting">
                                <div id="View_steps_ht_seting">
                                    <span>View steps</span>
                                </div>
                                <div id="Group_7882_hu_seting">
                                    <svg className="Rectangle_2057_hv_seting">
                                        <rect id="Rectangle_2057_hv_seting" rx="5" ry="5" x="0" y="0" width="69" height="31">
                                        </rect>
                                    </svg>
                                    <div id="Set_up_hw_seting">
                                        <span>Set up</span>
                                    </div>
                                </div>
                                <svg className="Line_hx_seting" viewBox="0 1 293.56 0.4000000059604645">
                                    <path id="Line_hx_seting" d="M 0 1 L 293.5595703125 1">
                                    </path>
                                </svg>
                            </div>
                        </div> */}
                        <div id="Component_119__2_seting" className="Component_119___2_seting">
                            <svg className="Rectangle_2596_hz_seting">
                                <rect id="Rectangle_2596_hz_seting" rx="7" ry="7" x="0" y="0" width="348" height="251">
                                </rect>
                            </svg>
                            <div id="Group_7883_h_seting">
                                <div id="n_2628_h_seting">
                                    <span>Instagram</span>
                                </div>
                                <div id="Grow_your_business_and_engage__seting">
                                    <span>Grow your business and engage community by connecting on Instagram</span>
                                </div>
                                {/* <img id="instagram-logo_seting" src="media/instagram-logo.png" /> */}
                                <img id="instagram-logo_seting" src={"https://chpter-3-jarvis-bucket.s3.amazonaws.com/branding/K00YCbn-instagram-logo.png"} />

                            </div>
                            <div id="Group_7884_h_seting">

                                {instagramSetUpDone ? (
                                    <>
                                        <div id="Group_7882_h__seting">
                                            <NavLink to={'/chat'}>
                                                <svg className="Rectangle_2057_h_seting" >
                                                    <rect id="Rectangle_2057_h_seting" style={{ fill: "rgba(64, 93, 230)" }} rx="5" ry="5" x="0" y="0" width="100" height="31">
                                                    </rect>
                                                </svg>
                                                <div id="Set_up_h_seting" style={{ color: "White" }}>
                                                    <span>Go To Chat</span>
                                                </div>
                                            </NavLink>
                                        </div>
                                        <div id="View_steps_h_seting" style={{ color: "green" }}>
                                            <span>Connected</span>
                                        </div>
                                    </>

                                ) : (
                                    <>
                                        {/* <div id="Group_7882_h_seting" onClick={instagramURL}>
                                        <svg className="Rectangle_2057_h_seting">
                                            <rect id="Rectangle_2057_h_seting" rx="5" ry="5" x="0" y="0" width="69" height="31">
                                            </rect>
                                        </svg>
                                        <div id="Set_up_h_seting">
                                            <span>Set up</span>
                                        </div>
                                    </div>
                                    <div id="View_steps_h_seting">
                                        <span>View steps</span>
                                    </div> */}
                                        <div id="Group_7882_h_seting" style={{ width: "170px", height: "31px", left: "123.5px" }} onClick={instagramURL}>
                                            <svg className="Rectangle_2057_h_seting" style={{ width: "170px", height: "31px" }}>
                                                <rect id="Rectangle_2057_h_seting" style={{ fill: "rgb(36,115,242)" }} rx="5" ry="5" x="0" y="0" width="170" height="31">
                                                </rect>
                                            </svg>
                                            <div id="Set_up_h_seting" style={{ left: "9px", paddingLeft: "25px", color: "white", top: "8px" }}>
                                                <span>Login with Facebook</span>
                                                {/* <img id="Facebook_logo_setting" src="media/icons8-facebook-48.png" /> */}
                                                <img id="Facebook_logo_setting" src={"https://chpter-3-jarvis-bucket.s3.amazonaws.com/branding/RpuEpsw-icons8-facebook-488.png"} />

                                            </div>
                                        </div>
                                        <div id="View_steps_h_seting">
                                            {/* <span>View steps</span> */}
                                            <span>Integrate</span>
                                        </div>

                                    </>

                                )}
                                <svg className="Line_h_seting" viewBox="0 1 293.56 0.4000000059604645">
                                    <path id="Line_h_seting" d="M 0 1 L 293.5595703125 1">
                                    </path>
                                </svg>
                            </div>
                        </div>
                        {/* QUICKBOOKS */}
                        {/* <div id="Component_119__5_seting" className="Component_119___5_seting">
                            <svg className="Rectangle_2596_ib_seting">
                                <rect id="Rectangle_2596_ib_seting" rx="7" ry="7" x="0" y="0" width="348" height="251">
                                </rect>
                            </svg>
                            <div id="Group_7883_ic_seting">
                                <div id="n_2628_id_seting">
                                    <span>Quickbooks</span>
                                </div>
                                <div id="Stay_on_top_of_your_finances_w_seting">
                                    <span>Stay on top of your finances with QuickBooks - simple and easy accounting.</span>
                                </div>
                                <img id="clipart702526_seting" src={"https://chpter-3-jarvis-bucket.s3.amazonaws.com/branding/4pUb220-clipart702526.png"}/>

                            </div>
                            <div id="Group_7884_ig_seting">
                                <div id="View_steps_ih_seting">
                                    
                                </div>
                                <div id="Group_7882_ii_seting">
                                    <svg className="Rectangle_2057_ij_seting">
                                        <rect id="Rectangle_2057_ij_seting" rx="5" ry="5" x="0" y="0" width="69" height="31">
                                        </rect>
                                    </svg>
                                    <div id="Set_up_ik_seting">
                                        <span>Set up</span>
                                    </div>
                                </div>
                                <svg className="Line_il_seting" viewBox="0 1 293.56 0.4000000059604645">
                                    <path id="Line_il_seting" d="M 0 1 L 293.5595703125 1">
                                    </path>
                                </svg>
                            </div>
                        </div>  */}
                        <div id="Component_119__3_seting" className="Component_119___3_seting">
                            <svg className="Rectangle_2596_in_seting">
                                <rect id="Rectangle_2596_in_seting" rx="7" ry="7" x="0" y="0" width="348" height="251">
                                </rect>
                            </svg>
                            <div id="Group_7883_io_seting">
                                <div id="n_2628_ip_seting">
                                    <span>Facebook</span>
                                </div>
                                <div id="Reach_more_customers_and_conne_seting">
                                    <span>Reach more customers and connect with your audience on Facebook</span>
                                </div>
                                {/* <img id="facebook-logo_seting" src="media/Facebook-logo.png" /> */}
                                <img id="facebook-logo_seting" src={"https://chpter-3-jarvis-bucket.s3.amazonaws.com/branding/vOBItJq-Facebook-logo.png"} />

                            </div>
                            <div id="Group_7884_is_seting">
                                {facebookSetUpDone ? (
                                    <>
                                        <div id="Group_7882_iu__seting">
                                            <NavLink to={{ pathname: '/chat', state: { component: 'facebook' } } as any}>

                                                <svg className="Rectangle_2057_iv_seting" >
                                                    <rect id="Rectangle_2057_iv_seting" style={{ fill: "rgba(0, 132, 255)" }} rx="5" ry="5" x="0" y="0" width="100" height="31">
                                                    </rect>
                                                </svg>
                                                <div id="Set_up_h_seting" style={{ color: "White" }}>
                                                    <span>Go To Chat</span>
                                                </div>
                                            </NavLink>
                                        </div>
                                        <div id="View_steps_it_seting" style={{ color: "green" }}>
                                            <span>Connected</span>
                                        </div>
                                    </>

                                ) : (
                                    <>
                                        {/* <div id="Group_7882_iu_seting" onClick={facebookURL}>
                                            <svg className="Rectangle_2057_iv_seting">
                                                <rect id="Rectangle_2057_iv_seting" rx="5" ry="5" x="0" y="0" width="69" height="31">
                                                </rect>
                                            </svg>
                                            <div id="Set_up_iw_seting">
                                                <span>Set up</span>
                                            </div>
                                        </div>
                                        <div id="View_steps_it_seting">
                                            
                                        </div> */}

                                        <div id="Group_7882_iu_seting" style={{ width: "170px", height: "31px", left: "123.5px" }} onClick={facebookURL}>
                                            <svg className="Rectangle_2057_iv_seting" style={{ width: "170px", height: "31px" }}>
                                                <rect id="Rectangle_2057_iv_seting" style={{ fill: "rgb(36,115,242)" }} rx="5" ry="5" x="0" y="0" width="170" height="31">
                                                </rect>
                                            </svg>
                                            <div id="Set_up_iw_seting" style={{ left: "9px", paddingLeft: "25px", color: "white", top: "8px" }}>
                                                <span>Login with Facebook</span>
                                                {/* <img id="Facebook_logo_setting" src="media/icons8-facebook-48.png" /> */}
                                                <img id="Facebook_logo_setting" src={"https://chpter-3-jarvis-bucket.s3.amazonaws.com/branding/RpuEpsw-icons8-facebook-488.png"} />

                                            </div>
                                        </div>
                                        <div id="View_steps_it_seting">
                                            {/* <span>View steps</span> */}
                                            <span>Integrate</span>
                                        </div>
                                    </>
                                )}


                                <svg className="Line_ix_seting" viewBox="0 1 293.56 0.4000000059604645">
                                    <path id="Line_ix_seting" d="M 0 1 L 293.5595703125 1">
                                    </path>
                                </svg>
                            </div>
                        </div>
                    </div>

                </main>
                <HotLinks_Settings></HotLinks_Settings>
                <Wrapper navigation='Settings'></Wrapper>
            </MediaQuery>


            <MediaQuery maxWidth={1000}>


                <div className="mobilesettingsintergrations_setintg_mob">
                    <div className="mobilesettingsintergrations-inner_setintg_mob">
                        <div className="group-wrapper_setintg_mob">
                            <div className="group-wrapper_setintg_mob">
                                <div className="settingsintegrations_setintg_mob">
                                    <div className="header_setintg_mob">
                                        <div className="frame-parent_setintg_mob">
                                            <NavLink to="/settings-mobile">


                                                <div className="group-container_setintg_mob">
                                                    <div className="rectangle-parent_setintg_mob">
                                                        <div className="group-child_setintg_mob" />


                                                        <svg className="path-9390-icon_setintg_mob" viewBox="0.636 0.05 10.513 18">
                                                            <path id="Path_9390_setintg_mb" d="M 5.357423305511475 10.11950778961182 C 4.766643047332764 9.528789520263672 4.766643047332764 8.571026802062988 5.357423305511475 7.980309963226318 L 10.7065315246582 2.631746768951416 C 11.29722785949707 2.041119337081909 11.2972297668457 1.083506107330322 10.7065315246582 0.4928695559501648 L 10.7065315246582 0.4928695559501648 C 10.11583614349365 -0.09776583313941956 9.158125877380371 -0.09776552766561508 8.567437171936035 0.4928695559501648 L 1.079220414161682 7.980309963226318 C 0.4884408712387085 8.571026802062988 0.4884408712387085 9.528774261474609 1.079220414161682 10.11950778961182 L 8.567437171936035 17.60687637329102 C 9.158125877380371 18.19756317138672 10.11583614349365 18.19756317138672 10.7065315246582 17.60687637329102 L 10.7065315246582 17.60687637329102 C 11.2972297668457 17.0163402557373 11.2972297668457 16.05868339538574 10.7065315246582 15.46799468994141 L 5.357423305511475 10.11950778961182 Z">
                                                            </path>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </NavLink>


                                            <div className="payment-detail_setintg_mob">Integrations</div>
                                        </div>
                                    </div>
                                    <div className="integrationsbio_setintg_mob">
                                        <div className="all_setintg_mob">
                                            Enhance your app experience. Boost engagement and user
                                            satisfaction by integrating your app with various channels.
                                        </div>
                                    </div>
                                    <div className="integrationmenu_setintg_mob">
                                        <div className="integrationmenu-inner_setintg_mob">
                                            <div className="frame-wrapper_setintg_mob">
                                                <div className="frame-group_setintg_mob">
                                                    <div className="frame-container_setintg_mob">
                                                        <div className="whatsapp-logo-wrapper_setintg_mob">
                                                            <img
                                                                className="whatsapp-logo-icon_setintg_mob"
                                                                alt=""
                                                                src={"https://chpter-3-jarvis-bucket.s3.amazonaws.com/branding/3tN48O5-whatsapp-logo.png"}
                                                            />
                                                        </div>
                                                        <div className="parent_setintg_mob">
                                                            <b className="b_setintg_mob">whatsApp for Business</b>
                                                            <div className="the-easiest-way_setintg_mob">
                                                                The easiest way to stay in touch - get personal with
                                                                your customers on whatsApp
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="line-wrapper_setintg_mob">

                                                        <svg className="line-icon_setintg_mob" viewBox="0 1 284 0.4000000059604645">
                                                            <path id="Line_setintg_mb" d="M 0 1 L 284.0000305175781 1">
                                                            </path>
                                                        </svg>
                                                    </div>

                                                       {whatsappSetUpDone ? (

                                                        <div className="frame-div_setintg_mob">
                                                            <div className="whatsapp-logo-wrapper_setintg_mob">
                                                                <div className="integrate_setintg_mob" style={{ color: "green" }}>Connected</div>
                                                            </div>
                                                            <NavLink to={{ pathname: '/chat', state: { component: 'whatsapp' } } as any} className="group-frame_setintg_mob">
                                                                <div className="rectangle-group_setintg_mob" style={{ fill: "rgb(220, 248, 198)" }}>
                                                                    <div className="group-item_setintg_mob" />
                                                                    <div className="login-with-facebook_setintg_mob">
                                                                        Go to chat
                                                                    </div>
                                                                </div>
                                                            </NavLink>
                                                        </div>



                                                    ) : (


                                                        <div className="frame-div_setintg_mob">
                                                            <div className="whatsapp-logo-wrapper_setintg_mob">
                                                                <div className="integrate_setintg_mob">Integrate</div>
                                                            </div>
                                                            <div className="group-frame_setintg_mob"onClick={whatsappURL}>
                                                                <div className="rectangle-group_setintg_mob">
                                                                    <div className="group-item_setintg_mob" />
                                                                    <div className="login-with-facebook_setintg_mob">
                                                                        Login with Facebook
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    )}


                                                </div>
                                            </div>
                                        </div>
                                        <div className="integrationmenu-inner_setintg_mob">
                                            <div className="frame-wrapper_setintg_mob">
                                                <div className="frame-group_setintg_mob">
                                                    <div className="frame-container_setintg_mob">
                                                        <img
                                                            className="whatsapp-logo-icon_setintg_mob"
                                                            alt=""
                                                            src={"https://chpter-3-jarvis-bucket.s3.amazonaws.com/branding/K00YCbn-instagram-logo.png"}
                                                        />
                                                        <div className="parent_setintg_mob">
                                                            <b className="b_setintg_mob">Instagram</b>
                                                            <div className="the-easiest-way_setintg_mob">
                                                                Grow your business and engage community by
                                                                connecting Instagram
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="line-wrapper_setintg_mob">
                                                        <svg className="line-icon_setintg_mob" viewBox="0 1 284 0.4000000059604645">
                                                            <path id="Line_setintg_mb" d="M 0 1 L 284.0000305175781 1">
                                                            </path>
                                                        </svg>
                                                    </div>

                                                    {instagramSetUpDone ? (

                                                        <div className="frame-div_setintg_mob">
                                                            <div className="whatsapp-logo-wrapper_setintg_mob">
                                                                <div className="integrate_setintg_mob" style={{ color: "green" }}>Connected</div>
                                                            </div>
                                                            <NavLink to={{ pathname: '/chat', state: { component: 'whatsapp' } } as any} className="group-frame_setintg_mob">
                                                                <div className="rectangle-group_setintg_mob" style={{ fill: "rgb(220, 248, 198)" }}>
                                                                    <div className="group-item_setintg_mob" />
                                                                    <div className="login-with-facebook_setintg_mob">
                                                                        Go to chat
                                                                    </div>
                                                                </div>
                                                            </NavLink>
                                                        </div>



                                                    ) : (


                                                        <div className="frame-div_setintg_mob">
                                                            <div className="whatsapp-logo-wrapper_setintg_mob">
                                                                <div className="integrate_setintg_mob">Integrate</div>
                                                            </div>
                                                            <div className="group-frame_setintg_mob"onClick={instagramURL}>
                                                                <div className="rectangle-group_setintg_mob">
                                                                    <div className="group-item_setintg_mob" />
                                                                    <div className="login-with-facebook_setintg_mob">
                                                                        Login with Facebook
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    )}









                                                </div>
                                            </div>
                                        </div>
                                        <div className="integrationmenu-inner_setintg_mob">
                                            <div className="frame-wrapper_setintg_mob">
                                                <div className="frame-group_setintg_mob">
                                                    <div className="frame-container_setintg_mob">
                                                        <div className="whatsapp-logo-wrapper_setintg_mob">
                                                            <img
                                                                className="whatsapp-logo-icon_setintg_mob"
                                                                alt=""
                                                                src={"https://chpter-3-jarvis-bucket.s3.amazonaws.com/branding/vOBItJq-Facebook-logo.png"}
                                                            />
                                                        </div>
                                                        <div className="parent_setintg_mob">
                                                            <b className="b_setintg_mob"> Facebook</b>
                                                            <div className="the-easiest-way_setintg_mob">
                                                                Reach more customers and connect with your audience
                                                                on Facebook
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="line-wrapper_setintg_mob">
                                                        <svg className="line-icon_setintg_mob" viewBox="0 1 284 0.4000000059604645">
                                                            <path id="Line_setintg_mb" d="M 0 1 L 284.0000305175781 1">
                                                            </path>
                                                        </svg>
                                                    </div>

                                                    {facebookSetUpDone? (

<div className="frame-div_setintg_mob">
    <div className="whatsapp-logo-wrapper_setintg_mob">
        <div className="integrate_setintg_mob" style={{ color: "green" }}>Connected</div>
    </div>
    <NavLink to={{ pathname: '/chat', state: { component: 'whatsapp' } } as any} className="group-frame_setintg_mob">
        <div className="rectangle-group_setintg_mob" style={{ fill: "rgb(220, 248, 198)" }}>
            <div className="group-item_setintg_mob" />
            <div className="login-with-facebook_setintg_mob">
                Go to chat
            </div>
        </div>
    </NavLink>
</div>



) : (


<div className="frame-div_setintg_mob">
    <div className="whatsapp-logo-wrapper_setintg_mob">
        <div className="integrate_setintg_mob">Integrate</div>
    </div>
    <div className="group-frame_setintg_mob" onClick={facebookURL}>
        <div className="rectangle-group_setintg_mob">
            <div className="group-item_setintg_mob" />
            <div className="login-with-facebook_setintg_mob">
                Login with Facebook
            </div>
        </div>
    </div>
</div>

)}


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>










                {/* <main className="form-signin">
                <div id="MobileSettingsIntegrations_setintg_mb"
                    style={{
                        right: "-41px",
                        transformOrigin: "0px 0px",
                        transform: "scale(0.85)",
                        display: "flex",
                        justifyContent: "center", 
                        alignItems: "center",
                    }}>  
                    <div id="All_setintg_mb">
                        <span>Integrations</span>
                    </div>
                    <NavLink to="/settings-mobile">
                        <div id="Group_7465_setintg_mb" >
                            <svg className="Rectangle_2103_setintg_mb">
                                <rect id="Rectangle_2103_setintg_mb" rx="5" ry="5" x="0" y="0" width="32" height="32">
                                </rect>
                            </svg>
                            <svg className="Path_9390_setintg_mb" viewBox="0.636 0.05 10.513 18">
                                <path id="Path_9390_setintg_mb" d="M 5.357423305511475 10.11950778961182 C 4.766643047332764 9.528789520263672 4.766643047332764 8.571026802062988 5.357423305511475 7.980309963226318 L 10.7065315246582 2.631746768951416 C 11.29722785949707 2.041119337081909 11.2972297668457 1.083506107330322 10.7065315246582 0.4928695559501648 L 10.7065315246582 0.4928695559501648 C 10.11583614349365 -0.09776583313941956 9.158125877380371 -0.09776552766561508 8.567437171936035 0.4928695559501648 L 1.079220414161682 7.980309963226318 C 0.4884408712387085 8.571026802062988 0.4884408712387085 9.528774261474609 1.079220414161682 10.11950778961182 L 8.567437171936035 17.60687637329102 C 9.158125877380371 18.19756317138672 10.11583614349365 18.19756317138672 10.7065315246582 17.60687637329102 L 10.7065315246582 17.60687637329102 C 11.2972297668457 17.0163402557373 11.2972297668457 16.05868339538574 10.7065315246582 15.46799468994141 L 5.357423305511475 10.11950778961182 Z">
                                </path>
                            </svg>
                        </div>
                    </NavLink>
                    <div id="All_hb_setintg_mb">
                        <span>Enhance your app experience. Boost engagement <br />and user satisfaction by integrating your app with <br />various channels.</span>
                    </div>
                    <div id="Component_120__1_setintg_mb" className="Component_120___1_setintg_mb">
                        <svg className="Rectangle_2596_setintg_mb">
                            <rect id="Rectangle_2596_setintg_mb" rx="7" ry="7" x="0" y="0" width="328" height="139">
                            </rect>
                        </svg>
                        <div id="Group_7885_setintg_mb">
                            <div id="The_easiest_way_to_stay_in_tou_setintg_mb">
                                <span>The easiest way to stay in touch - get personal with your customers on whatsApp</span>
                            </div>
                            <div id="n_2628_setintg_mb">
                                <span>whatsApp for Business</span>
                            </div>
                        </div>
                        <img id="whatsapp-logo_setintg_mb" src="media/whatsapp-logo.png" />
                        <img id="whatsapp-logo_setintg_mb" src={"https://chpter-3-jarvis-bucket.s3.amazonaws.com/branding/3tN48O5-whatsapp-logo.png"}/>

                        <div id="Group_7886_setintg_mb">

                            {whatsappSetUpDone ? (
                                <>
                                    <div id="Group_7882_setintg__mb">
                                        <NavLink to={{ pathname: '/chat', state: { component: 'whatsapp' } } as any}>
                                            <svg className="Rectangle_2057_setintg_mb">
                                                <rect id="Rectangle_2057_setintg_mb" style={{ fill: "rgb(220, 248, 198)" }} rx="5" ry="5" x="0" y="0" width="80" height="28">
                                                </rect>
                                            </svg>
                                            <div id="Set_up_setintg_mb">
                                                <span>Go to Chat</span>
                                            </div>
                                        </NavLink>
                                    </div>
                                    <div id="View_steps_setintg_mb" style={{ color: "green" }}>
                                        <span>Connected</span>
                                    </div>
                                </>

                            ) : (
                                <>

                                    <div id="Group_7882_setintg_mb">
                                        <svg className="Rectangle_2057_setintg_mb">
                                            <rect id="Rectangle_2057_setintg_mb" rx="5" ry="5" x="0" y="0" width="59" height="28">
                                            </rect>
                                        </svg>
                                        <div id="Set_up_setintg_mb" onClick={whatsappURL}>
                                            <span>Set up</span>
                                        </div>
                                    </div>
                                    <div id="View_steps_setintg_mb">
                                        <span>View steps</span>
                                    </div>

                                    <div id="Group_7882_setintg_mb" style={{ width: "153px", height: "28px", left: "125.5px" }}>
                                        <svg className="Rectangle_2057_setintg_mb" style={{ width: "153px", height: "28px" }}>
                                            <rect id="Rectangle_2057_setintg_mb" style={{ fill: "rgb(36,115,242)" }} rx="5" ry="5" x="0" y="0" width="153" height="28">
                                            </rect>
                                        </svg>
                                        <div id="Set_up_setintg_mb" style={{ left: "9px", paddingLeft: "25px", color: "white", top: "7px" }} onClick={whatsappURL}>
                                            <span>Login with Facebook</span>
                                            <img id="Facebook_logo_setting" src="media/icons8-facebook-48.png" />
                                            <img id="Facebook_logo_setting" src={"https://chpter-3-jarvis-bucket.s3.amazonaws.com/branding/RpuEpsw-icons8-facebook-488.png"}/>

                                        </div>
                                    </div>
                                    <div id="View_steps_setintg_mb">
                                        <span>View steps</span>
                                        <span>Integrate</span>
                                    </div>
                                </>


                            )}

                        </div>
                        <svg className="Line_setintg_mb" viewBox="0 1 284 0.4000000059604645">
                            <path id="Line_setintg_mb" d="M 0 1 L 284.0000305175781 1">
                            </path>
                        </svg>
                    </div>
                    <div id="Component_120__4_setintg_mb" className="Component_120___4_setintg_mb">
                        <svg className="Rectangle_2596_hp_setintg_mb">
                            <rect id="Rectangle_2596_hp_setintg_mb" rx="7" ry="7" x="0" y="0" width="328" height="139">
                            </rect>
                        </svg>
                        <div id="Group_7885_hq_setintg_mb">
                            <div id="Reach_more_customers_and_conne_setintg_mb">
                                <span>Reach more customers and connect with your audience on Facebook</span>
                            </div>
                            <div id="n_2628_hs_setintg_mb">
                                <span>Facebook</span>
                            </div>
                        </div>
                        <div id="Group_7886_ht_setintg_mb">

                            {facebookSetUpDone ? (
                                <>
                                    <div id="Group_7882_hv_setintg__mb">
                                        <NavLink to={'/chat'}>
                                            <svg className="Rectangle_2057_hw_setintg_mb">
                                                <rect id="Rectangle_2057_hw_setintg_mb" style={{ fill: "rgba(0, 132, 255)" }} rx="5" ry="5" x="0" y="0" width="80" height="28">
                                                </rect>
                                            </svg>
                                            <div id="Set_up_hx_setintg_mb" style={{ color: "White" }}>
                                                <span>Go to Chat</span>
                                            </div>
                                        </NavLink>

                                    </div>
                                    <div id="View_steps_hu_setintg_mb" style={{ color: "green" }}>
                                        <span>Connected</span>
                                    </div>
                                </>

                            ) : (
                                <>
                                    <div id="Group_7882_hv_setintg_mb" onClick={facebookURL}>
                                        <svg className="Rectangle_2057_hw_setintg_mb">
                                            <rect id="Rectangle_2057_hw_setintg_mb" rx="5" ry="5" x="0" y="0" width="59" height="28">
                                            </rect>
                                        </svg>
                                        <div id="Set_up_hx_setintg_mb">
                                            <span>Set up</span>
                                        </div>
                                    </div>
                                    <div id="View_steps_hu_setintg_mb">
                                        <span>View steps</span>
                                    </div>

                                    <div id="Group_7882_hv_setintg_mb" style={{ width: "153px", height: "28px", left: "125.5px" }} onClick={facebookURL}>
                                        <svg className="Rectangle_2057_hw_setintg_mb" style={{ width: "153px", height: "28px" }}>
                                            <rect id="Rectangle_2057_hw_setintg_mb" style={{ fill: "rgb(36,115,242)" }} rx="5" ry="5" x="0" y="0" width="153" height="28">
                                            </rect>
                                        </svg>
                                        <div id="Set_up_hx_setintg_mb" style={{ left: "9px", paddingLeft: "25px", color: "white", top: "7px" }}>
                                            <span>Login with Facebook</span>
                                            <img id="Facebook_logo_setting" src="media/icons8-facebook-48.png" />
                                            <img id="Facebook_logo_setting" src={"https://chpter-3-jarvis-bucket.s3.amazonaws.com/branding/RpuEpsw-icons8-facebook-488.png"}/>

                                        </div>
                                    </div>
                                    <div id="View_steps_hu_setintg_mb">
                                        <span>View steps</span>
                                        <span>Integrate</span>
                                    </div>
                                </>
                            )}



                        </div>
                        <svg className="Line_hy_setintg_mb" viewBox="0 1 284 0.4000000059604645">
                            <path id="Line_hy_setintg_mb" d="M 0 1 L 284.0000305175781 1">
                            </path>
                        </svg>
                        <img id="facebook-logo_setintg_mb" src="media/Facebook-logo.png" />
                        <img id="facebook-logo_setintg_mb" src={"https://chpter-3-jarvis-bucket.s3.amazonaws.com/branding/vOBItJq-Facebook-logo.png"}/>

                    </div>
                    <div id="Component_120__2_setintg_mb" className="Component_120___2_setintg_mb">
                        <svg className="Rectangle_2596_h_setintg_mb">
                            <rect id="Rectangle_2596_h_setintg_mb" rx="7" ry="7" x="0" y="0" width="328" height="139">
                            </rect>
                        </svg>
                        <div id="Group_7885_h_setintg_mb">
                            <div id="Grow_your_business_and_engage__setintg_mb">
                                <span>Grow your business and engage community by connecting Instagram</span>
                            </div>
                            <div id="n_2628_h_setintg_mb">
                                <span>Instagram</span>
                            </div>
                        </div>
                        <div id="Group_7886_h_setintg_mb">

                            {instagramSetUpDone ? (
                                <>
                                    <div id="Group_7882_h_setintg__mb">
                                        <NavLink to={'/chat'}>
                                            <svg className="Rectangle_2057_h_setintg_mb">
                                                <rect id="Rectangle_2057_h_setintg_mb" style={{ fill: "rgba(64, 93, 230)" }} rx="5" ry="5" x="0" y="0" width="80" height="28">
                                                </rect>
                                            </svg>
                                            <div id="Set_up_h_setintg_mb" style={{ color: "White" }}>
                                                <span>Go to Chat</span>
                                            </div>
                                        </NavLink>
                                    </div>

                                    <div id="View_steps_h_setintg_mb" style={{ color: "green" }}>
                                        <span>Connected</span>
                                    </div>
                                </>

                            ) : (
                                <>
                                    <div id="Group_7882_h_setintg_mb" onClick={instagramURL}>
                                        <svg className="Rectangle_2057_h_setintg_mb">
                                            <rect id="Rectangle_2057_h_setintg_mb" rx="5" ry="5" x="0" y="0" width="59" height="28">
                                            </rect>
                                        </svg>
                                        <div id="Set_up_h_setintg_mb">
                                            <span>Set up</span>
                                        </div>
                                    </div>
                                    <div id="View_steps_h_setintg_mb">
                                        <span>View steps</span>
                                    </div>

                                    <div id="Group_7882_h_setintg_mb" style={{ width: "153px", height: "28px", left: "125.5px" }} onClick={instagramURL}>
                                        <svg className="Rectangle_2057_h_setintg_mb" style={{ width: "153px", height: "28px" }}>
                                            <rect id="Rectangle_2057_h_setintg_mb" style={{ fill: "rgb(36,115,242)" }} rx="5" ry="5" x="0" y="0" width="153" height="28">
                                            </rect>
                                        </svg>
                                        <div id="Set_up_h_setintg_mb" style={{ left: "9px", paddingLeft: "25px", color: "white", top: "7px" }}>
                                            <span>Login with Facebook</span>
                                            <img id="Facebook_logo_setting" src="media/icons8-facebook-48.png" />
                                            <img id="Facebook_logo_setting" src={"https://chpter-3-jarvis-bucket.s3.amazonaws.com/branding/RpuEpsw-icons8-facebook-488.png"}/>

                                        </div>
                                    </div>
                                    <div id="View_steps_h_setintg_mb">
                                        <span>View steps</span>
                                        <span>Integrate</span>
                                    </div>
                                </>
                            )}
                        </div>
                        <svg className="Line_ia_setintg_mb" viewBox="0 1 284 0.4000000059604645">
                            <path id="Line_ia_setintg_mb" d="M 0 1 L 284.0000305175781 1">
                            </path>
                        </svg>
                        <img id="instagram-logo_setintg_mb" src="media/instagram-logo.png" />
                        <img id="instagram-logo_setintg_mb" src={"https://chpter-3-jarvis-bucket.s3.amazonaws.com/branding/K00YCbn-instagram-logo.png"}/>

                    </div>
                    </div>
                    </main> */}
                {/* TWITTER */}
                {/* <div id="Component_120__3_setintg_mb" className="Component_120___3_setintg_mb">
                        <svg className="Rectangle_2596_id_setintg_mb">
                            <rect id="Rectangle_2596_id_setintg_mb" rx="7" ry="7" x="0" y="0" width="328" height="139">
                            </rect>
                        </svg>
                        <div id="Group_7885_ie_setintg_mb">
                            <div id="The_easiest_way_to_stay_in_tou_if_setintg_mb">
                                <span>The easiest way to stay in touch - get personal with your customers on whatsApp</span>
                            </div>
                            <div id="n_2628_ig_setintg_mb">
                                <span>Twitter </span>
                            </div>
                        </div>
                        <div id="Group_7886_ih_setintg_mb">
                            <div id="View_steps_ii_setintg_mb">
                                <span>View steps</span>
                            </div>
                            <div id="Group_7882_ij_setintg_mb">
                                <svg className="Rectangle_2057_ik_setintg_mb">
                                    <rect id="Rectangle_2057_ik_setintg_mb" rx="5" ry="5" x="0" y="0" width="59" height="28">
                                    </rect>
                                </svg>
                                <div id="Set_up_il_setintg_mb">
                                    <span>Set up</span>
                                </div>
                            </div>
                        </div>
                        <svg className="Line_im_setintg_mb" viewBox="0 1 284 0.4000000059604645">
                            <path id="Line_im_setintg_mb" d="M 0 1 L 284.0000305175781 1">
                            </path>
                        </svg>
                        <img id="twitter-logo_setintg_mb" src={"https://chpter-3-jarvis-bucket.s3.amazonaws.com/branding/BCyHh0f-twitter-logo.png"}/>

                    </div>  */}
                {/* QUICKBOOKS */}
                {/* <div id="Component_120__5_setintg_mb" className="Component_120___5_setintg_mb">
                        <svg className="Rectangle_2596_ip_setintg_mb">
                            <rect id="Rectangle_2596_ip_setintg_mb" rx="7" ry="7" x="0" y="0" width="328" height="139">
                            </rect>
                        </svg>
                        <div id="Group_7885_iq_setintg_mb">
                            <div id="Stay_on_top_of_your_finances_w_setintg_mb">
                                <span>Stay on top of your finances with QuickBooks - simple and easy accounting.</span>
                            </div>
                            <div id="n_2628_is_setintg_mb">
                                <span>Quickbooks</span>
                            </div>
                        </div>
                        <div id="Group_7886_it_setintg_mb">
                            <div id="View_steps_iu_setintg_mb">
                                <span>View steps</span>
                            </div>
                            <div id="Group_7882_iv_setintg_mb">
                                <svg className="Rectangle_2057_iw_setintg_mb">
                                    <rect id="Rectangle_2057_iw_setintg_mb" rx="5" ry="5" x="0" y="0" width="59" height="28">
                                    </rect>
                                </svg>
                                <div id="Set_up_ix_setintg_mb">
                                    <span>Set up</span>
                                </div>
                            </div>
                        </div>
                        <svg className="Line_iy_setintg_mb" viewBox="0 1 284 0.4000000059604645">
                            <path id="Line_iy_setintg_mb" d="M 0 1 L 284.0000305175781 1">
                            </path>
                        </svg>
                        <img id="clipart702526_setintg_mb" src={"https://chpter-3-jarvis-bucket.s3.amazonaws.com/branding/4pUb220-clipart702526.png"}/>
                    </div> */}



            </MediaQuery>
        </>
    )
};

export default SettingsIntegrations;

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import '../../styles/Marketing/campaigns.css'
import '../../styles/Components/NavBar.css'
import '../../styles/Components/UserRibbon.css'
import '../../styles/Components/HotLinks_Customers.css'
import Wrapper from "../../components/Wrapper";
import MediaQuery from 'react-responsive';
import HotlinksMarketing from '../../components/HotLinks_Marketing';
import FilterableDataTable from '../../utils/data-table-search';
import { TableColumn } from 'react-data-table-component';
import {datetimeFormatter } from '../../utils/date_formatter';
import HotLinksMarketingMobile from '../../components/HotLinksMarketingMobile';


const Campaigns = () => {

    const [filterText, setFilterText] = React.useState('');


    const dummyData = [
        {

            campaing_name: "Easter",
            campaing_tag: "social media post",
            created_at: "03/04/2022",

        },
        {
            campaing_name: "Valentine",
            campaing_tag: "product and service",
            created_at: "03/10/2022",
        },
        {
            campaing_name: "Labour Day",
            campaing_tag: "social media post",
            created_at: "03/12/2022",
        }
    ];
    const columns: TableColumn<{ campaing_name: string; campaing_tag: string; created_at: string; }>[] = [


        {
            name: 'NAME',
            selector: (row) => row.campaing_name,
            sortable: true,
        },
        {
            name: 'CAMPAING TAG',
            selector: (row) => row.campaing_tag,
            sortable: true,


        },


        {
            name: 'CREATED AT',
            selector: (row) => datetimeFormatter(row.created_at),
            sortable: true,
        },

    ];
    const filteredItems = dummyData.filter(
        (item: { campaing_name: string; campaing_tag: string; created_at: string; }) =>
            item.campaing_name.toLowerCase().includes(filterText.toLowerCase()) ||
            item.campaing_tag.toLowerCase().includes(filterText.toLowerCase()) ||
            datetimeFormatter(item.created_at)
                .toLowerCase()
                .includes(filterText.toLowerCase())


    );
    const handleExport = () => {
        const columnNames = ['NAME', ' EMAIL ADDRESS', ' PHONE NUMBER', 'TIME',];
        const dataRows = dummyData.map((payment: { campaing_name: string; campaing_tag: string; created_at: any; }) => (
            [, payment.campaing_name, payment.campaing_tag, payment.created_at].join(',')
        ));
        const csv = [columnNames.join(','), ...dataRows].join('\n');
        const csvData = new Blob([csv], { type: 'text/csv' });
        const csvUrl = URL.createObjectURL(csvData);
        const hiddenElement = document.createElement('a');
        hiddenElement.href = csvUrl;
        hiddenElement.target = '_blank';
        hiddenElement.download = 'Campaing.csv';
        hiddenElement.click();
    };


    return (
        <>
            <MediaQuery minWidth={1001} >
                <div id="Marketing_campaigns">
                    <div id="Group_7336">
                        <FilterableDataTable data={filteredItems} columns={columns} scrollHeight={'660px'} />
                    </div>
                    <div id="Group_45999_mc">
                        <svg className="Line_e_mc" viewBox="0 1 1400 1">
                            <path id="Line_e_mc" d="M 0 1 L 1400 1">
                            </path>
                        </svg>
                    </div>
                </div>
                <Wrapper navigation="Marketing"></Wrapper>
                <HotlinksMarketing handleExport={handleExport}></HotlinksMarketing>
            </MediaQuery>


            <MediaQuery maxWidth={1000}>
                <div className="mobilepages">
                    <div className="mobilepages-inner">
                        <div className="group-frame">
                            <div className="group-frame">
                                <div className="mobilepages1">
                                    <HotLinksMarketingMobile></HotLinksMarketingMobile>

                                    {/* ListView */}
                                    <div className="listview_campg_mob">
                                        <div className="listview1_campg_mob">
                                            <div className="jessica-lagat-parent_campg_mob">
                                                <div className="jessica-lagat_campg_mob">New Product Launch</div>
                                                <div className="cus-op98_campg_mob">25 July. 2016</div>
                                            </div>
                                            <div className="listview1-inner_campg_mob">
                                                <div className="group-parent_campg_mob">
                                                    <div className="rectangle-wrapper_campg_mob">
                                                        <div className="group-child_campg_mob" />
                                                    </div>
                                                    <div className="social-media-post_campg_mob">Social Media Post</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="listview1_campg_mob">
                                            <div className="jessica-lagat-parent_campg_mob">
                                                <div className="jessica-lagat_campg_mob">Easter Offer</div>
                                                <div className="cus-op98_campg_mob">25 July. 2016</div>
                                            </div>
                                            <div className="listview1-inner_campg_mob">
                                                <div className="group-parent_campg_mob">
                                                    <div className="rectangle-wrapper_campg_mob">
                                                        <div className="group-child_campg_mob" />
                                                    </div>
                                                    <div className="social-media-post_campg_mob">Social Media Post</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </>
    )
};

export default Campaigns;
import axios from 'axios';
import React, { useEffect, useState, SyntheticEvent } from 'react';
import { Navigate, NavLink } from 'react-router-dom';
import '../../styles/Settings/settingsbankaccounts_edit.css'
import '../../styles/Components/NavBar.css'
import '../../styles/Components/UserRibbon.css'
import '../../styles/Components/HotLinks_Settings.css'
import Wrapper from "../../components/Wrapper";
import HotLinks_Settings from '../../components/HotLinks_Settings';
import { useParams } from "react-router-dom";
import { get_one_bank_account_info } from "../../services/Settings/bankaccountinfo";

import { bankslistKE } from "../../utils/banks-list";
import MediaQuery from 'react-responsive';
import { GoogleMapsAPIBankAccountDesktop } from '../../utils/google-maps-api';
import { GoogleMapsAPIBankAccountMobile } from '../../utils/google-maps-api';


const SettingsAddBankAccount = () => {
    const [redirect, setredirect] = useState(false)
    const [bankaccountData, setBankAccountData] = useState([] as any);

    const [wallet_type, setWalletType] = useState('');
    const [bank_name, setBankName] = useState('');
    const [bank_code, setBankCode] = useState('');
    const [country_code, setCountryCode] = useState('254');
    const [bank_account_name, setBankAccountName] = useState('');
    const [bank_account_number, setBankAccountNumber] = useState('');
    const [bank_branch, setBankBranch] = useState('');


    // Handles Input changes
    function handleChange(event: { target: { name: any; value: any; }; }) {
        const { name, value } = event.target;
        setBankAccountData((prevData: any) => ({
            ...prevData,
            [name]: value
        }));
    };

    let { id } = useParams();
    let bankaccount_id = { id }.id

    useEffect(() => {
        async function fetchBankAccountInfo() {
            var bank_account = await get_one_bank_account_info(bankaccount_id);
            setBankAccountData(bank_account);
            setBankName(bank_account.bank_name)
            setBankCode(bank_account.bank_code)
            setWalletType(bank_account.wallet_type)
            setBankAccountName(bank_account.bank_account_name)
            setBankAccountNumber(bank_account.bank_account_number)
            setBankBranch(bank_account.bank_branch)

        }
        fetchBankAccountInfo();

    }, []);

    const handleBankChange = (event: { target: { value: any; }; }) => {
        const [bank_name, bank_code] = event.target.value.split('|');
        setBankName(bank_name)
        setBankCode(bank_code)


    }


    const [location, setLocation] = useState([] as any);
    function handleLocationChange(newLocation: any) {
        setLocation(newLocation);
        setBankBranch(String(newLocation.address))
    }

    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();

        let api_url = process.env.REACT_APP_CHPTER_API_URL + '/merchants/' + bankaccount_id + '/bankaccount'
        let headers = {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("JWT"),
        };

        const data = {
            "wallet_type": wallet_type,
            "bank_name": bank_name,
            "bank_account_name": bank_account_name,
            "bank_account_number": bank_account_number,
            "bank_branch": bank_branch,
            "bank_code": bank_code,
            "country_code": country_code
        }


        await axios.put(api_url, data, { headers: headers })
            .then((response) => {


                setredirect(true)

            })
            .catch((error) => {
                // Error
                if (error.response) {
                    
                } else if (error.request) {
                  
                    if (error.response.status === 401) {
                        //Redirect to login Token expired
                        window.location.href = '/login';
                    }
                } else {
                  
                }
            });

    }


    if (redirect) {
        return <Navigate to={'/settings-bankaccounts'} />
    }


    return (
        <>
            <MediaQuery minWidth={1001}>
                <main className="form-signin" >
                    <div id="Group_6924_addbnk">
                        <div id="Group_4741_addbnk" onClick={submit}>
                            <div id="Group_3969_addbnk">
                                <svg className="Rectangle_2057_addbnk">
                                    <rect id="Rectangle_2057_addbnk" rx="5" ry="5" x="0" y="0" width="169.925" height="30">
                                    </rect>
                                </svg>
                            </div>
                            <div id="Group_4628_addbnk">
                                <div id="SEND_INVITE_addbnk">
                                    <span>SAVE CHANGES</span>
                                </div>
                            </div>
                        </div>
                        <NavLink to={'/settings-bankaccounts'}>
                            <div id="Group_4742_addbnk">
                                <div id="Group_3969_fj_addbnk">
                                    <svg className="Rectangle_2057_fk_addbnk">
                                        <rect id="Rectangle_2057_fk_addbnk" rx="5" ry="5" x="0" y="0" width="75" height="30">
                                        </rect>
                                    </svg>
                                </div>
                                <div id="Group_4628_fl_addbnk">
                                    <div id="CANCEL_addbnk">
                                        <span>CANCEL</span>
                                    </div>
                                </div>
                            </div>
                        </NavLink>
                    </div>
                    <div id="All_addbnk">
                        <span>Bank Account</span>
                    </div>
                    <div id="Group_7147_addbnk">
                        <div id="Group_7146_addbnk">
                            <input id="Rectangle_2002_addbnk" type="NUMBER" name="bank_account_number" placeholder="098765432109876" value={bank_account_number || ''} onChange={e => setBankAccountNumber(e.target.value)} required />

                        </div>
                        <div id="Bank_Account_Number_addbnk">
                            <span>Bank Account Number</span>
                        </div>
                    </div>
                    <div id="Group_7151_addbnk">
                        <div id="Select_Your_Wallet_addbnk">
                            <span>Select Your Wallet</span>
                        </div>
                        <div id="Group_7150_addbnk">
                            <select id="Rectangle_2002_fw_addbnk" placeholder="Select Wallet Type" name="wallet_type" value={wallet_type || ''} onChange={e => setWalletType(e.target.value)} required>
                                <option value="KES">KES Wallet</option>
                                <option value="US">USD Wallet</option>
                                {/* <option value="GBP">GBP Wallet</option> */}
                            </select>
                        </div>
                    </div>
                    <div id="Group_7152_addbnk">
                        <div id="Select_Your_Bank_Account_addbnk">
                            <span>Select Your Bank</span>
                        </div>
                        <div id="Group_7149_addbnk">
                            <select id="Rectangle_2002_f_addbnk" onChange={handleBankChange} value={`${bank_name}|${bank_code}`} required>
                                {bankslistKE.map(banks => (
                                    <option key={banks.bank_code} value={`${banks.bank_name}|${banks.bank_code}`}>{banks.bank_name}</option>
                                ))}
                            </select>
                            <div id="ABSA_BANK_KENYA_addbnk">
                                {/* <span>ABSA BANK KENYA</span> */}
                            </div>
                        </div>
                    </div>
                    <div id="Group_7089_addbnk">
                        <input id="Rectangle_2002_ga_addbnk" type="text" name="bank_location" placeholder="Bank Branch Location" value={bank_branch || ''} onChange={e => setBankBranch(e.target.value)} required />
                        <div id="Location_addbnk">
                            <span>Location</span>
                        </div>
                        {/* <GoogleMapsAPIBankAccountDesktop onChange={handleLocationChange} value={bank_branch || ''}/> */}
                    </div>
                    <div id="Group_7148_addbnk">
                        <div id="Group_7145_addbnk">
                            <input id="Rectangle_2002_gb_addbnk" type="text" name="bank_account" placeholder="Account Name" value={bank_account_name || ''} onChange={e => setBankAccountName(e.target.value)} required />
                        </div>
                        <div id="Bank_Account_Name_addbnk">
                            <span>Account Name</span>
                        </div>
                    </div>
                </main>
                <HotLinks_Settings innerPage="EditBank"></HotLinks_Settings>
                <Wrapper navigation='Settings'></Wrapper>
            </MediaQuery>

            <MediaQuery maxWidth={1000}>
                <div className="mobileaddbankaccount_bnk_mob">
                    <div className="mobileaddbankaccount-inner_bnk_mob">
                        <div className="group-wrapper_bnk_mob">
                            <div className="group-wrapper_bnk_mob">
                                <div className="frame-container_bnk_mob">
                                    <div className="header-parent_bnk_mob">
                                        <div className="header_bnk_mob">
                                            <div className="all_bnk_mob">Bank Account</div>
                                            <NavLink to={'/settings-bankaccounts'}>


                                                <div className="rectangle-parent_bnk_mob">
                                                    <div className="group-child_bnk_mob" />

                                                    <svg className="path-9390-icon_bnk_mob" viewBox="0.636 0.05 10.513 18">
                                                        <path id="Path_9390_bnk_mob" d="M 5.357423305511475 10.11950778961182 C 4.766643047332764 9.528789520263672 4.766643047332764 8.571026802062988 5.357423305511475 7.980309963226318 L 10.7065315246582 2.631746768951416 C 11.29722785949707 2.041119337081909 11.2972297668457 1.083506107330322 10.7065315246582 0.4928695559501648 L 10.7065315246582 0.4928695559501648 C 10.11583614349365 -0.09776583313941956 9.158125877380371 -0.09776552766561508 8.567437171936035 0.4928695559501648 L 1.079220414161682 7.980309963226318 C 0.4884408712387085 8.571026802062988 0.4884408712387085 9.528774261474609 1.079220414161682 10.11950778961182 L 8.567437171936035 17.60687637329102 C 9.158125877380371 18.19756317138672 10.11583614349365 18.19756317138672 10.7065315246582 17.60687637329102 L 10.7065315246582 17.60687637329102 C 11.2972297668457 17.0163402557373 11.2972297668457 16.05868339538574 10.7065315246582 15.46799468994141 L 5.357423305511475 10.11950778961182 Z">
                                                        </path>
                                                    </svg>
                                                </div>
                                            </NavLink>


                                            <div className="all1_bnk_mob">Add Bank Account</div>
                                        </div>
                                        <div className="body_bnk_mob">
                                            <div className="inputfields_bnk_mob">
                                                <div className="select-your-wallet-parent_bnk_mob">
                                                    <div className="select-your-wallet_bnk_mob">
                                                        Select Your Wallet
                                                    </div>
                                                    <select className="kes-parent_bnk_mob"
                                                        name="wallet_type"
                                                        value={wallet_type || ''}
                                                        onChange={e => setWalletType(e.target.value)} required

                                                    >
                                                        <option value="KES_bnk_mob">KES</option>
                                                        <option value="USD_bnk_mob">USD</option>
                                                    </select>
                                                </div>
                                                <div className="select-your-wallet-parent_bnk_mob">
                                                    <div className="select-your-wallet_bnk_mob">
                                                        Select Your Bank Account
                                                    </div>
                                                    <select className="absa-bank-parent_bnk_mob"
                                                        onChange={handleBankChange}
                                                        value={bank_name + '|' + bank_code} required

                                                    >
                                                        {bankslistKE.map(banks => (
                                                            <option key={banks.bank_code} value={banks.bank_name + '|' + banks.bank_code} >{banks.bank_name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="select-your-wallet-parent_bnk_mob">
                                                    <div className="select-your-wallet_bnk_mob">
                                                        Bank Account Name
                                                    </div>
                                                    <input
                                                        className="frame-child_bnk_mob"
                                                        type="text"
                                                        placeholder="Account Name"
                                                        name="bank_account"
                                                        value={bank_account_name || ''}
                                                        onChange={e => setBankAccountName(e.target.value)} required
                                                    />
                                                </div>
                                                <div className="select-your-wallet-parent_bnk_mob">
                                                    <div className="select-your-wallet_bnk_mob">
                                                        Bank Account Number
                                                    </div>
                                                    <input
                                                        className="frame-child_bnk_mob"
                                                        type="NUMBER"
                                                        placeholder="098765432109876"
                                                        name="bank_account_number"
                                                        value={bank_account_number || ''}
                                                        onChange={e => setBankAccountNumber(e.target.value)} required  
                                                        
                                                    />
                                                </div>
                                                <div className="select-your-wallet-parent_bnk_mob">
                                                    <div className="select-your-wallet_bnk_mob">Location</div>
                                                    <input
                                                        className="frame-child_bnk_mob"
                                                        type="text"
                                                        placeholder="Input Bank Location"
                                                        name="bank_location"
                                                        value={bank_branch || ''}
                                                        onChange={e => setBankBranch(e.target.value)} required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="footer_bnk_mob">
                                        <NavLink to={'/settings-bankaccounts'}  style={{ textDecoration: 'none' }} className="rectangle-group_bnk_mob">
                                                <div className="rectangle-div_bnk_mob" />
                                                <div className="cancel_bnk_mob">CANCEL</div>
                                            </NavLink>
                                            <div className="rectangle-container_bnk_mob"onClick={submit}>
                                                <div className="frame-child1_bnk_mob" />
                                                <div className="save-changes_bnk_mob">{`SAVE `}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


               

            </MediaQuery>
        </>
    )
};

export default SettingsAddBankAccount;

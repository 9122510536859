import axios from 'axios';
import React, { useEffect, useState, SyntheticEvent } from 'react';
import '../../styles/Settings/settingsapis.css'
import '../../styles/Components/NavBar.css'
import '../../styles/Components/UserRibbon.css'
import '../../styles/Components/HotLinks_Settings.css'
import MediaQuery from 'react-responsive';
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs';
import HotLinks_Settings from '../../components/HotLinks_Settings';
import Wrapper from '../../components/Wrapper';
import { NavLink } from 'react-router-dom';
import { get_api_keys } from '../../services/Settings/apikeys';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import ShopifyHotLinksSettings from '../../components/ShopifyHotLinksSettings';
import WrapperShopify from '../../components/ShopifyWrapper';

import 'react-toastify/dist/ReactToastify.css';
import '../../styles/Components/Toastify.css'

const APIKeys = () => {
    const [livePublicKey, setLivePublicKey] = useState('');
    const [liveSecretKey, setLiveSecretKey] = useState('');
    const [testPublicKey, setTestPublicKey] = useState('');
    const [testSecretKey, setTestSecretKey] = useState('');

    const [decodedLivePublicKey, setDecodedLivePublicKey] = useState('');
    const [decodedTestPublicKey, setDecodedTestPublicKey] = useState('');
    const [reload, setReload] = useState(true);



    function fetchAPIKeys() {
        get_api_keys().then(APIs => {
            setLivePublicKey(APIs?.public_key);
            setLiveSecretKey(APIs?.secret_key);
            setTestPublicKey(APIs?.test_public_key);
            setTestSecretKey(APIs?.test_secret_key);
            console.log("APIs", APIs);

        }).catch(error => {
            // Error handling code...
            toast.error('API Keys are not yet generated', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
            });
        });
    }

    useEffect(() => {
        fetchAPIKeys();
    }, [reload]);

    useEffect(() => {

        const decodeBase64 = () => {
            const decodedLiveKey = window.atob(livePublicKey);
            const decodedTestKey = window.atob(testPublicKey);

            setDecodedLivePublicKey(decodedLiveKey);
            setDecodedTestPublicKey(decodedTestKey)
            console.log("DECODED LIVE THEN TEST", decodedLivePublicKey, decodedTestPublicKey);
        };
        if (livePublicKey && testPublicKey) {
            decodeBase64();
        }

    }, [livePublicKey, testPublicKey]);


    const handleGenerateLiveKeys = () => {
        let api_url = process.env.REACT_APP_CHPTER_API_URL + '/merchants/auth/refresh'
        let headers = {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("JWT"),
        };

        try {
            axios.put(api_url, null, { headers: headers });
            toast.success('Live Keys Generated', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
            });

            setReload(!reload)

        }
        catch (error: any) {
            // Error
            if (error.response) {
                console.log(error.response.data.message);
                console.log(error.response.data.success);
                console.log(error.response.status);
                // console.log(error.response.headers);
                if (error.response.status === 401) {
                    //Redirect to login Token expired
                    window.location.href = '/login';
                }
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the 
                // browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            console.log(error.config);
            setReload(!reload)
        };

    }

    const handleGenerateTestKeys = () => {
        let api_url = process.env.REACT_APP_CHPTER_API_URL + '/merchants/auth/refresh'
        let headers = {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("JWT"),
        };

        try {
            axios.put(api_url, null, { headers: headers });
            toast.success('Test Keys Generated', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
            });
            setReload(!reload)

        }
        catch (error: any) {
            // Error
            if (error.response) {
                console.log(error.response.data.message);
                console.log(error.response.data.success);
                console.log(error.response.status);
                // console.log(error.response.headers);
                if (error.response.status === 401) {
                    //Redirect to login Token expired
                    window.location.href = '/login';
                }
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the 
                // browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            console.log(error.config);
            setReload(!reload)
        };
    }


    // Copy Text to Clipboard
    const [showDivLive, setShowDivLive] = useState(false);
    const [showDivTest, setShowDivTest] = useState(false);

    const handleLivePublicKeyCopy = () => {
        var text = decodedLivePublicKey;

        const el = document.createElement('textarea');
        el.value = text;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        setShowDivLive(true);
        setTimeout(() => setShowDivLive(false), 2000);

    };

    const handleTestPublicKeyCopy = () => {
        var text = decodedTestPublicKey;
        const el = document.createElement('textarea');
        el.value = text;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        setShowDivTest(true);
        setTimeout(() => setShowDivTest(false), 2000);

    };


    const [showApiLive, setShowApiLive] = useState(false);
    const [showApiTest, setShowApiTest] = useState(false);


    const handleEyeClickLive = () => {
        setShowApiLive(!showApiLive);
    };

    const handleEyeClickTest = () => {
        setShowApiTest(!showApiTest);
    };
    const apiValueLive = showApiLive ? liveSecretKey : null;
    const apiValueTest = showApiTest ? testSecretKey : null;




    return (
        <>
            <MediaQuery minWidth={1001}>
                <ToastContainer
                    position="top-center"
                    autoClose={9000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick={false}
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    toastClassName="custom-toast"
                    className="toast-container"
                    style={{
                        top: "10%",
                        color: "white",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                    }} />


                <main className="form-signin">
                    <div id="Group_7065_setapi">
                        <div id="All_setapi">
                            <span>API Configuration - Live Keys</span>
                        </div>
                        <div id="Group_7043_setapi">
                            <div id="Rectangle_2002_fw_setapi" onClick={handleLivePublicKeyCopy}>
                                {decodedLivePublicKey}
                            </div>
                            <div id="LiveSecretKey__setapi">
                                <span>Live Public Key </span>
                            </div>
                            {showDivLive ?
                                <img id="copy_icon1_setapi" width="30" height="30" src="https://img.icons8.com/ios-glyphs/30/000000/checkmark--v1.png" alt="checkmark--v1" />
                                :
                                <img id="copy_icon1_setapi" onClick={handleLivePublicKeyCopy} width="20" height="20" src="https://img.icons8.com/external-anggara-basic-outline-anggara-putra/48/external-copy-ui-anggara-basic-outline-anggara-putra.png" alt="external-copy-ui-anggara-basic-outline-anggara-putra" />
                            }
                            <div id="generate-new-secret-key-parent_setapi">
                                <div id="generate-new-secret_setapi" onClick={handleGenerateLiveKeys}>
                                    Generate new live keys
                                </div>
                                <img id="info-icon_setapi" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC6klEQVR4nO1abWvbMBB+YtfOWFn7MW2gZSExLCyh7ce6XbZkNGVdSpr+/x8zjj2Cw3EcW5Zit/SBQLDsk0669xPwgfeLGEAfwBRACuABwDOAF/6e+SzlO+cAIrQEIYBLAPcANgBeK/7kmzvSCJpiIAHwpBYlu/4DwDeezBfueMBfxGcyNua7L+p7oZWQ9kFwBuBRLWAO4KulmET8dqHoPQLowSNkp27UhAvHEwqt34r+tY/T+aR2bQ1gCKDjehL8pzniHOa0u66IHytREqtzAv84BbDknEuuoRa6iuCcJvZQiAD8VHojUmGFUInTLwBHODyOOLfZSCuduVHiZHMSYnInNKtP/G/jK2IlFWIAKptYo9i2OjHJcYDyzFZn1qRR2lKGSrnFOtlCO0vt9GwxUspfSsQS5Sc6LWKko/yMMFWIUC2grrPLE63vNWn2SOfPPn27VBaiLlwpexbGkl6gAPd8SeKftmLANUrUvNPMbRiRtiZPyEHENW52rbNPTiW8bjtmXKskZ1uYclDyibZjXOSX0iIuK2JXRugKfdK7zRs0YYBkcW1n5ESFT1tYcdBlhOuLkZj0/uYNmvw5eAOMBKpO8H4ZWb0h0eoWiZZLZTdoRNlTh+bXoBHzO+WgOJu2MzIucog+QhRfjMxITzLZLUQegkYfjMT7gkYwNHYZxvtgZEBaotM7caHS3LYysiiTWAVMI12kuj4YOSub6rosPrhmpKOKD8Oq5aC91YqK0W8dhhJVDgqqVivWLI41zcipTYHO4FrtwCGL10WF9CtYIGRZqOki9lyVqII6u/Hgo+lSAnGmrVB77uNM08VGZ+o2ej67ItxVR7ymNfPVekt8td60zhgD8Eqb7rIZKs5ON0OvfPfee+rYjeMcWAaaMb/V7eml7/Z09nRGKpwx+fOMuUKfWVysLgzEfGYuDMwyFwaE1rCpGxABg7e7Glc4UtJohIE8REyTJ+pSzUpdqlnx2S3fadWlmg/AMf4B4ZB6kNCrxNMAAAAASUVORK5CYII=" />
                            </div>
                        </div>
                        <div id="Group_70431_setapi">
                            <div id="Rectangle_20021_fw_setapi">
                                {showApiLive && (
                                    <span className="api-value">{apiValueLive}</span>
                                )}
                                {!showApiLive && (
                                    <span className="api-value">{'*'.repeat(47)}</span>
                                )}
                            </div>
                            <div id="LiveSecretKey1__setapi">
                                <span>Live Secret Key </span>
                            </div>
                            <div id="eye_icon_setapi">
                                {showApiLive ? (
                                    <BsEyeSlashFill onClick={handleEyeClickLive} style={{ color: 'rgb(118, 118, 118, 1)', height: '1.5em', width: '1.5em', marginLeft: '3px' }} />
                                ) : (
                                    <BsEyeFill onClick={handleEyeClickLive} style={{ color: 'rgb(118, 118, 118, 1)', height: '1.5em', width: '1.5em', marginLeft: '3px' }} />
                                )}
                            </div>
                        </div>
                        <div id="Group_70432_setapi">
                            <input id="Rectangle_20021_fw_setapi" type="" placeholder='https://example.com' />
                            <div id="LiveSecretKey1__setapi">
                                <span>Live Callback URL </span>
                            </div>
                            <div id="savechanges_setapi">
                                <div id="save-changes-wrapper_setapi">
                                    <div id="save-changes_setapi">{`SAVE`}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="Group_70651_setapi">
                        <div id="All_setapi">
                            <span>API Configuration - Test Keys</span>
                        </div>
                        <div id="Group_7043_setapi">
                            <div id="Rectangle_2002_fw_setapi" onClick={handleTestPublicKeyCopy}>
                                {decodedTestPublicKey}
                            </div>
                            <div id="LiveSecretKey__setapi">
                                <span>Test Public Key </span>
                            </div>
                            {showDivTest ?
                                    <img id="copy_icon1_setapi" width="30" height="30" src="https://img.icons8.com/ios-glyphs/30/000000/checkmark--v1.png" alt="checkmark--v1" />
                                    :
                                    <img id="copy_icon1_setapi" onClick={handleTestPublicKeyCopy} width="20" height="20" src="https://img.icons8.com/external-anggara-basic-outline-anggara-putra/48/external-copy-ui-anggara-basic-outline-anggara-putra.png" alt="external-copy-ui-anggara-basic-outline-anggara-putra" />
                                }
                            <div id="generate-new-secret-key-parent_setapi">
                                <div id="generate-new-secret_setapi" onClick={handleGenerateTestKeys}>
                                    Generate new test keys
                                </div>
                                <img id="info-icon_setapi" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC6klEQVR4nO1abWvbMBB+YtfOWFn7MW2gZSExLCyh7ce6XbZkNGVdSpr+/x8zjj2Cw3EcW5Zit/SBQLDsk0669xPwgfeLGEAfwBRACuABwDOAF/6e+SzlO+cAIrQEIYBLAPcANgBeK/7kmzvSCJpiIAHwpBYlu/4DwDeezBfueMBfxGcyNua7L+p7oZWQ9kFwBuBRLWAO4KulmET8dqHoPQLowSNkp27UhAvHEwqt34r+tY/T+aR2bQ1gCKDjehL8pzniHOa0u66IHytREqtzAv84BbDknEuuoRa6iuCcJvZQiAD8VHojUmGFUInTLwBHODyOOLfZSCuduVHiZHMSYnInNKtP/G/jK2IlFWIAKptYo9i2OjHJcYDyzFZn1qRR2lKGSrnFOtlCO0vt9GwxUspfSsQS5Sc6LWKko/yMMFWIUC2grrPLE63vNWn2SOfPPn27VBaiLlwpexbGkl6gAPd8SeKftmLANUrUvNPMbRiRtiZPyEHENW52rbNPTiW8bjtmXKskZ1uYclDyibZjXOSX0iIuK2JXRugKfdK7zRs0YYBkcW1n5ESFT1tYcdBlhOuLkZj0/uYNmvw5eAOMBKpO8H4ZWb0h0eoWiZZLZTdoRNlTh+bXoBHzO+WgOJu2MzIucog+QhRfjMxITzLZLUQegkYfjMT7gkYwNHYZxvtgZEBaotM7caHS3LYysiiTWAVMI12kuj4YOSub6rosPrhmpKOKD8Oq5aC91YqK0W8dhhJVDgqqVivWLI41zcipTYHO4FrtwCGL10WF9CtYIGRZqOki9lyVqII6u/Hgo+lSAnGmrVB77uNM08VGZ+o2ej67ItxVR7ymNfPVekt8td60zhgD8Eqb7rIZKs5ON0OvfPfee+rYjeMcWAaaMb/V7eml7/Z09nRGKpwx+fOMuUKfWVysLgzEfGYuDMwyFwaE1rCpGxABg7e7Glc4UtJohIE8REyTJ+pSzUpdqlnx2S3fadWlmg/AMf4B4ZB6kNCrxNMAAAAASUVORK5CYII=" />
                            </div>
                        </div>
                        <div id="Group_70431_setapi">
                            <div id="Rectangle_20021_fw_setapi">
                                {showApiTest && (
                                    <span className="api-value">{apiValueTest}</span>
                                )}
                                {!showApiTest && (
                                    <span className="api-value">{'*'.repeat(47)}</span>
                                )}
                            </div>
                            <div id="LiveSecretKey1__setapi">
                                <span>Test Secret Key </span>
                            </div>
                            <div id="eye_icon_setapi">
                                {showApiTest ? (
                                    <BsEyeSlashFill onClick={handleEyeClickTest} style={{ color: 'rgb(118, 118, 118, 1)', height: '1.5em', width: '1.5em', marginLeft: '3px' }} />
                                ) : (
                                    <BsEyeFill onClick={handleEyeClickTest} style={{ color: 'rgb(118, 118, 118, 1)', height: '1.5em', width: '1.5em', marginLeft: '3px' }} />
                                )}
                            </div>
                        </div>
                    </div>
                </main>

                <ShopifyHotLinksSettings></ShopifyHotLinksSettings>
                <WrapperShopify navigation="Settings"></WrapperShopify>
                
                {/* <HotLinks_Settings></HotLinks_Settings>
                <Wrapper navigation='Settings'></Wrapper> */}

            </MediaQuery>


            {/* <MediaQuery maxWidth={1000}>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick={false}
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    toastClassName="custom-toast"
                    className="toast-container"
                    style={{
                        position: "fixed",
                        color: "white",
                        top: "10%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        height: "10vh",
                        width: "70vw",
                    }}
                />
                <div className="api-keys_setapi_mob">
                    <div className="api-keys-inner_setapi_mob">
                        <div className="group-wrapper_setapi_mob">
                            <div className="group-wrapper_setapi_mob">
                                <div className="api_setapi_mob">
                                    <div className="header_setapi_mob">
                                        <div className="frame-parent_setapi_mob">
                                            <NavLink to={'/settings-mobile'}>
                                                <div className="group-container_setapi_mob">
                                                    <div className="rectangle-parent_setapi_mob">
                                                        <div className="group-child_setapi_mob" />
                                                        <svg className="path-9390-icon_setapi_mob" viewBox="0.636 0.05 10.513 18">
                                                            <path id="path-9390-icon_setapi_mob" d="M 5.357423305511475 10.11950778961182 C 4.766643047332764 9.528789520263672 4.766643047332764 8.571026802062988 5.357423305511475 7.980309963226318 L 10.7065315246582 2.631746768951416 C 11.29722785949707 2.041119337081909 11.2972297668457 1.083506107330322 10.7065315246582 0.4928695559501648 L 10.7065315246582 0.4928695559501648 C 10.11583614349365 -0.09776583313941956 9.158125877380371 -0.09776552766561508 8.567437171936035 0.4928695559501648 L 1.079220414161682 7.980309963226318 C 0.4884408712387085 8.571026802062988 0.4884408712387085 9.528774261474609 1.079220414161682 10.11950778961182 L 8.567437171936035 17.60687637329102 C 9.158125877380371 18.19756317138672 10.11583614349365 18.19756317138672 10.7065315246582 17.60687637329102 L 10.7065315246582 17.60687637329102 C 11.2972297668457 17.0163402557373 11.2972297668457 16.05868339538574 10.7065315246582 15.46799468994141 L 5.357423305511475 10.11950778961182 Z">
                                                            </path>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </NavLink>
                                            <div className="payment-detail_setapi_mob">API Keys</div>
                                        </div>
                                    </div>
                                    <div className="frame-group_setapi_mob">
                                        <div className="livekeys-parent_setapi_mob">
                                            <div className="livekeys_setapi_mob">
                                                <div className="livekeys-inner_setapi_mob">
                                                    <div className="frame-wrapper_setapi_mob">
                                                        <div className="all-wrapper_setapi_mob">
                                                            <div className="all_setapi_mob">
                                                                API configuration - Live Keys
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="password-parent_setapi_mob">
                                                    <div className="password1_setapi_mob">Live Public Key</div>
                                                    <div className="frame-container_setapi_mob">
                                                        <div className="inputplaceholder-wrapper_setapi_mob" onClick={handleLivePublicKeyCopy}>
                                                            <div className="inputplaceholder1_setapi_mob">
                                                                {decodedLivePublicKey}
                                                            </div>
                                                        </div>
                                                        {showDivLive ?
                                                            <img className="tick1_setapi_mob" width="30" height="30" src="https://img.icons8.com/ios-glyphs/30/000000/checkmark--v1.png" alt="checkmark--v1" />
                                                            :
                                                            <img className="eye-icon1_setapi_mob" onClick={handleLivePublicKeyCopy} width="20" height="20" src="https://img.icons8.com/external-anggara-basic-outline-anggara-putra/48/external-copy-ui-anggara-basic-outline-anggara-putra.png" alt="external-copy-ui-anggara-basic-outline-anggara-putra" />
                                                        }
                                                    </div>
                                                    <div className="generate-new-secret-key-parent_setapi_mob">
                                                        <div className="generate-new-secret_setapi_mob" onClick={handleGenerateLiveKeys}>
                                                            Generate new live keys
                                                        </div>
                                                        <img className="info-icon_setapi_mob" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC6klEQVR4nO1abWvbMBB+YtfOWFn7MW2gZSExLCyh7ce6XbZkNGVdSpr+/x8zjj2Cw3EcW5Zit/SBQLDsk0669xPwgfeLGEAfwBRACuABwDOAF/6e+SzlO+cAIrQEIYBLAPcANgBeK/7kmzvSCJpiIAHwpBYlu/4DwDeezBfueMBfxGcyNua7L+p7oZWQ9kFwBuBRLWAO4KulmET8dqHoPQLowSNkp27UhAvHEwqt34r+tY/T+aR2bQ1gCKDjehL8pzniHOa0u66IHytREqtzAv84BbDknEuuoRa6iuCcJvZQiAD8VHojUmGFUInTLwBHODyOOLfZSCuduVHiZHMSYnInNKtP/G/jK2IlFWIAKptYo9i2OjHJcYDyzFZn1qRR2lKGSrnFOtlCO0vt9GwxUspfSsQS5Sc6LWKko/yMMFWIUC2grrPLE63vNWn2SOfPPn27VBaiLlwpexbGkl6gAPd8SeKftmLANUrUvNPMbRiRtiZPyEHENW52rbNPTiW8bjtmXKskZ1uYclDyibZjXOSX0iIuK2JXRugKfdK7zRs0YYBkcW1n5ESFT1tYcdBlhOuLkZj0/uYNmvw5eAOMBKpO8H4ZWb0h0eoWiZZLZTdoRNlTh+bXoBHzO+WgOJu2MzIucog+QhRfjMxITzLZLUQegkYfjMT7gkYwNHYZxvtgZEBaotM7caHS3LYysiiTWAVMI12kuj4YOSub6rosPrhmpKOKD8Oq5aC91YqK0W8dhhJVDgqqVivWLI41zcipTYHO4FrtwCGL10WF9CtYIGRZqOki9lyVqII6u/Hgo+lSAnGmrVB77uNM08VGZ+o2ej67ItxVR7ymNfPVekt8td60zhgD8Eqb7rIZKs5ON0OvfPfee+rYjeMcWAaaMb/V7eml7/Z09nRGKpwx+fOMuUKfWVysLgzEfGYuDMwyFwaE1rCpGxABg7e7Glc4UtJohIE8REyTJ+pSzUpdqlnx2S3fadWlmg/AMf4B4ZB6kNCrxNMAAAAASUVORK5CYII=" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="password-group_setapi_mob">
                                                <div className="password1_setapi_mob">Live Secret Key</div>
                                                <div className="frame-div_setapi_mob">
                                                    <div className="inputplaceholder-container_setapi_mob">
                                                        <div className="inputplaceholder1_setapi_mob">
                                                            {showApiLive && (
                                                                <span className="api-value">{apiValueLive}</span>
                                                            )}
                                                            {!showApiLive && (
                                                                <span className="api-value">{'*'.repeat(47)}</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="eye-icon_setapi_mob">
                                                        {showApiLive ? (
                                                            <BsEyeSlashFill onClick={handleEyeClickLive} style={{ color: 'rgb(118, 118, 118, 1)', height: '1.5em', width: '1.5em', marginLeft: '3px' }} />
                                                        ) : (
                                                            <BsEyeFill onClick={handleEyeClickLive} style={{ color: 'rgb(118, 118, 118, 1)', height: '1.5em', width: '1.5em', marginLeft: '3px' }} />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="frame-wrapper_setapi_mob">
                                            <div className="testkeys-inner_setapi_mob">
                                                <div className="all-wrapper_setapi_mob">
                                                    <div className="all_setapi_mob">API configuration - Test Keys</div>
                                                </div>
                                            </div>
                                            <div className="password-parent_setapi_mob">
                                                <div className="password1_setapi_mob">Test Public Key</div>
                                                <div className="frame-container_setapi_mob">
                                                    <div className="inputplaceholder-wrapper_setapi_mob" onClick={handleTestPublicKeyCopy}>
                                                        <div className="inputplaceholder1_setapi_mob">

                                                            {decodedTestPublicKey}
                                                        </div>
                                                    </div>
                                                    {showDivTest ?
                                                        <img className="tick1_setapi_mob" width="30" height="30" src="https://img.icons8.com/ios-glyphs/30/000000/checkmark--v1.png" alt="checkmark--v1" />
                                                        :
                                                        <img className="eye-icon1_setapi_mob" onClick={handleTestPublicKeyCopy} width="20" height="20" src="https://img.icons8.com/external-anggara-basic-outline-anggara-putra/48/external-copy-ui-anggara-basic-outline-anggara-putra.png" alt="external-copy-ui-anggara-basic-outline-anggara-putra" />
                                                    }

                                                </div>
                                                <div className="generate-new-secret-key-parent_setapi_mob">
                                                    <div className="generate-new-secret_setapi_mob" onClick={handleGenerateTestKeys}>
                                                        Generate new test keys
                                                    </div>
                                                    <img className="info-icon_setapi_mob" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC6klEQVR4nO1abWvbMBB+YtfOWFn7MW2gZSExLCyh7ce6XbZkNGVdSpr+/x8zjj2Cw3EcW5Zit/SBQLDsk0669xPwgfeLGEAfwBRACuABwDOAF/6e+SzlO+cAIrQEIYBLAPcANgBeK/7kmzvSCJpiIAHwpBYlu/4DwDeezBfueMBfxGcyNua7L+p7oZWQ9kFwBuBRLWAO4KulmET8dqHoPQLowSNkp27UhAvHEwqt34r+tY/T+aR2bQ1gCKDjehL8pzniHOa0u66IHytREqtzAv84BbDknEuuoRa6iuCcJvZQiAD8VHojUmGFUInTLwBHODyOOLfZSCuduVHiZHMSYnInNKtP/G/jK2IlFWIAKptYo9i2OjHJcYDyzFZn1qRR2lKGSrnFOtlCO0vt9GwxUspfSsQS5Sc6LWKko/yMMFWIUC2grrPLE63vNWn2SOfPPn27VBaiLlwpexbGkl6gAPd8SeKftmLANUrUvNPMbRiRtiZPyEHENW52rbNPTiW8bjtmXKskZ1uYclDyibZjXOSX0iIuK2JXRugKfdK7zRs0YYBkcW1n5ESFT1tYcdBlhOuLkZj0/uYNmvw5eAOMBKpO8H4ZWb0h0eoWiZZLZTdoRNlTh+bXoBHzO+WgOJu2MzIucog+QhRfjMxITzLZLUQegkYfjMT7gkYwNHYZxvtgZEBaotM7caHS3LYysiiTWAVMI12kuj4YOSub6rosPrhmpKOKD8Oq5aC91YqK0W8dhhJVDgqqVivWLI41zcipTYHO4FrtwCGL10WF9CtYIGRZqOki9lyVqII6u/Hgo+lSAnGmrVB77uNM08VGZ+o2ej67ItxVR7ymNfPVekt8td60zhgD8Eqb7rIZKs5ON0OvfPfee+rYjeMcWAaaMb/V7eml7/Z09nRGKpwx+fOMuUKfWVysLgzEfGYuDMwyFwaE1rCpGxABg7e7Glc4UtJohIE8REyTJ+pSzUpdqlnx2S3fadWlmg/AMf4B4ZB6kNCrxNMAAAAASUVORK5CYII=" />
                                                </div>
                                            </div>
                                            <div className="password-group_setapi_mob">
                                                <div className="password1_setapi_mob">Test Secret Key</div>

                                                <div className="frame-div_setapi_mob">
                                                    <div className="inputplaceholder-container_setapi_mob">
                                                        <div className="inputplaceholder1_setapi_mob">
                                                            {showApiTest && (
                                                                <span className="api-value">{apiValueTest}</span>
                                                            )}
                                                            {!showApiTest && (
                                                                <span className="api-value">{'*'.repeat(47)}</span>
                                                            )}
                                                        </div>
                                                    </div>


                                                    <div className="eye-icon_setapi_mob">
                                                        {showApiTest ? (
                                                            <BsEyeSlashFill onClick={handleEyeClickTest} style={{ color: 'rgb(118, 118, 118, 1)', height: '1.5em', width: '1.5em', marginLeft: '3px' }} />
                                                        ) : (
                                                            <BsEyeFill onClick={handleEyeClickTest} style={{ color: 'rgb(118, 118, 118, 1)', height: '1.5em', width: '1.5em', marginLeft: '3px' }} />
                                                        )}
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="callback_setapi_mob">
                                        <div className="frame-wrapper_setapi_mob">
                                            <div className="frame-wrapper1_setapi_mob">
                                                <div className="all-wrapper_setapi_mob">
                                                    <div className="all_setapi_mob">Callback URL</div>
                                                </div>
                                            </div>
                                            <div className="password-parent2_setapi_mob">
                                                <div className="password1_setapi_mob">Live Callback URL</div>
                                                <input
                                                    className="frame-child_setapi_mob"
                                                    type="text"
                                                    placeholder="https://example.com"
                                                />
                                            </div>
                                        </div>

                                        <div className="savechanges_setapi_mob">
                                            <div className="save-changes-wrapper_setapi_mob">
                                                <div className="save-changes_setapi_mob">{`SAVE`}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery > */}
        </>
    )
}

export default APIKeys;
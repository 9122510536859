import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import '../../styles/Marketing/customers.css'
import '../../styles/Components/NavBar.css'
import '../../styles/Components/UserRibbon.css'
import '../../styles/Components/HotLinks_Marketing.css'
import '../../styles/Components/HotLinksMarketingMobile.css'
import Wrapper from "../../components/Wrapper";
import HotlinksMarketing from '../../components/HotLinks_Marketing';
import { CustomersInfo } from "../../models/customers";
import { get_all_customers } from "../../services/Customers/customersinfo";
import { dateFormatter_, datetimeFormatter_human } from '../../utils/date_formatter';
import MediaQuery from 'react-responsive';
import FilterableDataTable from '../../utils/data-table-search';
import { TableColumn } from 'react-data-table-component';
import { BouncingDotsLoaderDataTable } from '../../components/loading-dots';
import HotLinksMarketingMobile from '../../components/HotLinksMarketingMobile';


const Customers = () => {
    const [CustomersInfo, setCustomers] = useState([] as any);
    const [loading, setLoading] = useState(false)
    const [filterText, setFilterText] = React.useState('');


    useEffect(() => {
        async function fetchCustomers() {
            setLoading(true)
            await get_all_customers().then(customers => {

                setCustomers(customers);
            }).catch(error => {

            }).finally(() => {
                setLoading(false)

            })

        }

        fetchCustomers();



    }, []);
    const columns: TableColumn<{ customer_name: string; slug: string; created_at: string; email: string; phone_number: any; }>[] = [


        {
            name: 'NAME',
            selector: (row) => row.customer_name,
            sortable: true,
        },
        {
            name: 'EMAIL ADDRESS',
            selector: (row) => row.email ? row.email : "-",
            sortable: true,


        },
        {
            name: 'PHONE NUMBER',
            selector: (row) => row.phone_number ? row.phone_number : "-",
            sortable: true,
        },

        {
            name: 'TIME',
            selector: (row) => datetimeFormatter_human(row.created_at),
            sortable: true,
        },

    ];
    const filteredItems = CustomersInfo.filter(
        (item: { customer_name: string; phone_number: string; email: string; created_at: string; }) =>
            item.customer_name.toLowerCase().includes(filterText.toLowerCase()) ||
            item.phone_number.toLowerCase().includes(filterText.toLowerCase()) ||
            item.email.toLowerCase().includes(filterText.toLowerCase()) ||
            datetimeFormatter_human(item.created_at)
                .toLowerCase()
                .includes(filterText.toLowerCase())


    );

    const handleExport = () => {
        const columnNames = ['NAME', ' EMAIL ADDRESS', ' PHONE NUMBER', 'TIME',];
        const dataRows = CustomersInfo.map((payment: { customer_name: any; email: any; phone_number: any; created_at: any; }) => (
            [payment.customer_name, payment.email, payment.phone_number, payment.created_at].join(',')
        ));
        const csv = [columnNames.join(','), ...dataRows].join('\n');
        const csvData = new Blob([csv], { type: 'text/csv' });
        const csvUrl = URL.createObjectURL(csvData);
        const hiddenElement = document.createElement('a');
        hiddenElement.href = csvUrl;
        hiddenElement.target = '_blank';
        hiddenElement.download = 'Customers.csv';
        hiddenElement.click();
    };



    return (
        <>
            <MediaQuery minWidth={1001}>
                <main className="form-signin">
                    {loading ? (<BouncingDotsLoaderDataTable></BouncingDotsLoaderDataTable>
                    ) : (
                        <div id="Group_4666_cuslit">
                            <FilterableDataTable data={filteredItems} columns={columns} redirect_url={`customerdetail`} type={`id`} scrollHeight={'660px'} />
                        </div>
                    )}
                    <div id="Group_45999_cuslit">
                        <svg className="Line_e_cuslit" viewBox="0 1 1400 1">
                            <path id="Line_e_cuslit" d="M 0 1 L 1400 1">
                            </path>
                        </svg>
                    </div>
                </main>
                <HotlinksMarketing handleExport={handleExport}></HotlinksMarketing>
                <Wrapper navigation="Marketing"></Wrapper>


            </MediaQuery>


            


            <MediaQuery maxWidth={1000}>
                <div className="mobilepages">
                    <div className="mobilepages-inner">
                        <div className="group-frame">
                            <div className="group-frame">
                                <div className="mobilepages1">
                                    <HotLinksMarketingMobile></HotLinksMarketingMobile>

                                    {/* ListView */}

                                    {CustomersInfo.map((customer_info: CustomersInfo) => {
                                        return (

                                            <NavLink to={`/customerdetail/${customer_info.id}`} className="listviewcustomer_cust_mobb" style={{ textDecoration: 'none' }}>
                                                <div className="listview1customer_cust_mobb">
                                                    <div className="jessica-lagat-parent_cust_mobb">
                                                        <div className="jessica-lagat_cust_mobb">{customer_info.customer_name}</div>
                                                        <div className="cus-op98"> {customer_info.email ? customer_info.email : "-"}</div>
                                                    </div>
                                                    <div className="jessica-lagat-parent_cust_mobb">
                                                        <div className="jessica-lagat1_cust_mobb">{customer_info.phone_number ? customer_info.phone_number : "-"}</div>
                                                        <div className="cus-op981_cust_mobb">{dateFormatter_(customer_info.created_at)}</div>
                                                    </div>
                                                </div>
                                            </NavLink>

                                        )
                                    })}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </>
    )
};

export default Customers;
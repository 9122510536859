// Get Subscriptions 
export async function get_subscriptions(){

    const response = await fetch(process.env.REACT_APP_CHPTER_API_URL+"/subscriptions/Active", {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer "+localStorage.getItem("JWT"),
          'Accept': 'application/json',
        },
      });
    const data = await response.json();
    console.log(data.status);
  
    if(data.status === 401){
      //Redirect to login Token expired
      window.location.href = '/login';

    }    
    if(data.status === 200){
        return data.subscriptions;
    }
  
    return [];
}


// Get Subscription Detail
export async function get_subscription_detail(subscription_slug: string | undefined){

    const response = await fetch(process.env.REACT_APP_CHPTER_API_URL+"/subscription/" + subscription_slug, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        });
    const data = await response.json();
    return data;
   
}

// Get Subscription History 
export async function get_subscription_history_detail(customer_id:string | undefined){

    const response = await fetch(process.env.REACT_APP_CHPTER_API_URL+"/customer/subscriptions/" + customer_id, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': "Bearer "+localStorage.getItem("JWT"),
            'Accept': 'application/json',
        },
        });
    const data = await response.json();
    console.log(data.status);

    if(data.status === 401){
        //Redirect to login Token expired
        
    }

    if(data.status === 200){
        return data.subscriptions;
    }

    return [];
}
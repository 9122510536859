import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import { Spinner } from '../components/spinner';
import '../styles/Payments/successfulpayments.css'



const FilterableDataTable = ({ data, columns, redirect_url, type, scrollHeight, rowHeight}: any) => {

  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);


  const handleFilter = (e: { target: { value: string; }; }) => {
    const value = e.target.value || '';
    setFilterText(value.toLowerCase());
  };

  
  const filteredData = data.filter((item:any) =>
    Object.values(item)
      .join('')
      .toLowerCase()
      .includes(filterText)
  );

  const handleRowClick = (row: any) => {
    if(type == 'slug' && row.rowIndex !== 1 && row.rowIndex !== 2){
        const selectedDiv = row.slug;
        if (selectedDiv) {
          window.location.href = `/${redirect_url}/${selectedDiv}`;
        }
    }

    if(type == 'invoice_number'){
        const selectedDiv = row.invoice_number;
        if (selectedDiv) {
          window.location.href = `/${redirect_url}/${selectedDiv}`;
        }
    }

    if(type == 'id'){
        const selectedDiv = row.id;
        if (selectedDiv) {
          window.location.href = `/${redirect_url}/${selectedDiv}`;
        }
    }
    
};

const customStyles = {
    rows: {
        style: {
            minHeight: '59.5px', 
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px',
            color: 'rgba(0,0,0,1)',

        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', 
            paddingRight: '8px',
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px',
            color: 'rgba(0,0,0,1)',
        },
    },
    cells: {
        style: {
            paddingLeft: '8px',
            paddingRight: '8px',
        },
    },
};

if(rowHeight === 'wide'){
    customStyles.rows.style.minHeight = '74.5px';
    customStyles.cells.style.paddingLeft = '0px';

} 
else if (rowHeight === 'normal'){
}



const CustomLoader = () => (
    <Spinner />
);

const fixedHeader = true;

 
  return (
    <>
    <div id="Group_5739_filter">
            <div id="Group_3430_filter">
                <div id="Group_2663_filter">
                    <input id="Rectangle_1472_filter" type="text" onChange={handleFilter} placeholder="Search . . . ." />
                </div>
            </div>
            <div id="Group_5733_filter">
                <svg className="Search_Icon_filter" viewBox="0 0 18 18">
                    <path id="Search_Icon_filter" d="M 14.28750038146973 12.63354206085205 C 15.30000019073486 11.29192733764648 15.86250019073486 9.726709365844727 15.86250019073486 7.937889575958252 C 15.86250114440918 3.577640056610107 12.375 0 7.987500190734863 0 C 3.600000143051147 0 0 3.577640056610107 0 7.937888622283936 C 0 12.29813861846924 3.600000619888306 15.87577724456787 7.987500190734863 15.87577724456787 C 9.78749942779541 15.87577724456787 11.47500038146973 15.31677055358887 12.71249866485596 14.31056022644043 L 16.08750152587891 17.66459655761719 C 16.3125 17.8882007598877 16.65000152587891 18.00000190734863 16.875 18.00000190734863 C 17.10000038146973 18.00000190734863 17.4375 17.8882007598877 17.66250038146973 17.66459655761719 C 18.11249923706055 17.21739387512207 18.11249923706055 16.54658508300781 17.66250038146973 16.0993824005127 L 14.28750038146973 12.63354206085205 Z M 7.987500190734863 13.52795124053955 C 4.83750057220459 13.52795124053955 2.250000238418579 11.06832313537598 2.250000238418579 7.937888622283936 C 2.250000238418579 4.807454109191895 4.83750057220459 2.236025094985962 7.987500190734863 2.236025094985962 C 11.13750076293945 2.236025094985962 13.72500038146973 4.807454109191895 13.72500038146973 7.937888622283936 C 13.72500038146973 11.06832313537598 11.13750076293945 13.52795124053955 7.987500190734863 13.52795124053955 Z">
                    </path>
                </svg>
            </div>
        </div>

      <DataTable
            columns={columns}
            data={filteredData}
            customStyles={customStyles}
            onRowClicked={handleRowClick}
            pagination
            paginationResetDefaultPage={resetPaginationToggle} 
            persistTableHead
            pointerOnHover
            fixedHeader={fixedHeader}
            fixedHeaderScrollHeight={scrollHeight}
        />
    </>
  );
};
export default FilterableDataTable;

function classNames(arg0: any) {
    throw new Error('Function not implemented.');
}

import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import '../../styles/Wallet/wallet.css'
import '../../styles/Components/NavBar.css'
import '../../styles/Components/UserRibbon.css'
import '../../styles/Components/HotLinks_Wallet.css'
import Wrapper from "../../components/Wrapper";
import HotlinksWallet from '../../components/HotLinks_Wallet';

import { get_wallet_balance } from '../../services/Wallet/walletbalanceinfo';
import { get_payouts } from '../../services/Wallet/payoutsinfo';

import { ResponsiveContainer, LineChart, Line, Label, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import { get_monthly_total_payouts } from '../../services/Insights/insights';
import MediaQuery from "react-responsive";
import '../../styles/Components/HotLinksWalletMobile.css'
import { datetimeFormatter_human } from '../../utils/date_formatter';
import HotLinksWalletMobile from '../../components/HotLinksWalletMobile';


const Wallet = () => {
    const [WalletBalance, setWalletBalance] = useState([] as any);
    const [SuccessfulPayouts, setSuccessfulPayouts] = useState([] as any);
    const [MonthlyPayouts, setMonthlyPayouts] = useState([] as any);
    const [Payouts, setPayouts] = useState([] as any);
    const [SuccessfulLastPayouts, setSuccessfulLastPayouts] = useState([] as any);
    const [loading, setLoading] = useState(false)



    useEffect(() => {
        async function fetchWalletBalances() {
            await get_wallet_balance().then(wallet => {

                setWalletBalance(wallet);
                if (wallet == null) {
                    setWalletBalance([]);
                }
            }).catch(error => {
                setWalletBalance([]);
            }).finally(() => {
                setLoading(false)
            })
        }
        fetchWalletBalances();

        async function fetchSuccessfulPayouts() {
            await get_payouts("Successful").then(payouts => {

                setSuccessfulPayouts(payouts);
                setSuccessfulLastPayouts(payouts[0]);



                if (payouts == null) {
                    setSuccessfulPayouts([]);
                }
                else if (payouts[0] == null) {
                    setSuccessfulLastPayouts([]);

                }
            }).catch(error => {
                setSuccessfulPayouts([]);
            }).finally(() => {
                //Hide spinner
                setLoading(false)
            })

        }
        fetchSuccessfulPayouts();

        // Fetch Monthly Payouts
        async function fetchMonthlyPayouts() {
            var insights = await get_monthly_total_payouts();
            setMonthlyPayouts(insights.insights_data);
            setPayouts(insights)

        }

        fetchMonthlyPayouts();

    }, []);


    const totals = SuccessfulPayouts.map((SuccessfulPayouts: { amount: any; }) => SuccessfulPayouts.amount).reduce((a: any, b: any) => a + b, 0);
    const available_balance_kes = parseFloat(WalletBalance.total_balance_kes).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    const available_balance_usd = parseFloat(WalletBalance.total_balance_usd).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    
    // Graph: Balance Overview 
    const data = MonthlyPayouts
    data.sort((a: { date: { split: (arg0: string) => [any, any]; }; }, b: { date: { split: (arg0: string) => [any, any]; }; }) => {
        const [dayA, monthA] = a.date.split("/");
        const [dayB, monthB] = b.date.split("/");
        if (monthA === monthB) {
            return dayA - dayB;
        }
        return monthA - monthB;
    });


    // Export to CSV
    const handleExport = () => {
        const columnNames = ['Currency', 'Amount', 'Transaction ID', 'Customer', 'Time'];
        const dataRows = SuccessfulPayouts.map((payment: { currency: any; amount: any; reference: any; destination: any; created_at: any; }) => (
            [payment.currency, payment.amount, payment.reference, payment.destination, payment.created_at].join(',')
        ));
        const csv = [columnNames.join(','), ...dataRows].join('\n');
        const csvData = new Blob([csv], { type: 'text/csv' });
        const csvUrl = URL.createObjectURL(csvData);
        const hiddenElement = document.createElement('a');
        hiddenElement.href = csvUrl;
        hiddenElement.target = '_blank';
        hiddenElement.download = 'Successful_Payouts.csv';
        hiddenElement.click();
    };

    return (
        <>
            <MediaQuery minWidth={1001}>
                <main className="form-signin">
                    <div id="WalletLanding__5_wa">
                        <div id="Group_6780_wa">
                            <svg className="Rectangle_2451_wa">
                                <rect id="Rectangle_2451_wa" rx="10" ry="10" x="0" y="0" width="325" height="125">
                                </rect>
                            </svg>

                            <div id="Group_6769_wa">
                                <div id="Group_5298_wa">
                                    <div id="All_f_wa">
                                        <span>{available_balance_usd}</span>
                                    </div>
                                    <div id="All_ga_wa">
                                        <span>USD.</span>
                                    </div>
                                </div>
                                <div id="Group_6648_wa">
                                    <div id="USD_Balance_wa">
                                        <span>USD Balance</span>
                                    </div>
                                </div>
                                <NavLink to={'/swap'} className="nav-link" style={{ textDecoration: 'none' }}>
                                    <div id="Group_6776_wa">
                                        <svg className="Rectangle_2229_wa">
                                            <rect id="Rectangle_2229_wa" rx="5" ry="5" x="0" y="0" width="100" height="30">
                                            </rect>
                                        </svg>
                                        <div id="Swap_to_KES_wa">
                                            <span>Swap to KES</span>
                                        </div>
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                        {/* <div id="Group_6781_wa">
                        <svg className="Rectangle_2452_wa">
                            <rect id="Rectangle_2452_wa" rx="10" ry="10" x="0" y="0" width="325" height="125">
                            </rect>
                        </svg>
                        <div id="Group_6770_wa">
                            <div id="Group_5298_ga_wa">
                                <div id="All_gb_wa">
                                    <span>6,670.00</span>
                                </div>
                                <div id="All_gc_wa">
                                    <span>NGN.</span>
                                </div>
                            </div>
                            <div id="Group_6648_gd_wa">
                                <div id="NGN_Balance_wa">
                                    <span>NGN Balance</span>
                                </div>
                            </div>
                            <NavLink to={'/swap'} className="nav-link" style={{ textDecoration: 'none' }}>
                            <div id="Group_6777_wa">
                                <svg className="Rectangle_2229_gg_wa">
                                    <rect id="Rectangle_2229_gg_wa" rx="5" ry="5" x="0" y="0" width="100" height="30">
                                    </rect>
                                </svg>
                                <div id="Swap_to_KES_gh_wa">
                                    <span>Swap to KES</span>
                                </div>
                            </div>
                            </NavLink>
                        </div>
                    </div> */}
                        <div id="Group_6779_wa">
                            <svg className="Rectangle_2449_wa">
                                <rect id="Rectangle_2449_wa" rx="10" ry="10" x="0" y="0" width="325" height="125">
                                </rect>
                            </svg>
                            <div id="Group_6773_wa">
                                <div id="Group_5298_gl_wa">
                                    <div id="All_gm_wa">
                                        <span>{available_balance_kes}</span>
                                    </div>
                                    <div id="All_gn_wa">
                                        <span>KES.</span>
                                    </div>
                                </div>
                                <div id="Group_6648_go_wa">
                                    <div id="KES_Balance_wa">
                                        <span>KES Balance</span>
                                    </div>
                                </div>
                                <NavLink to={'/withdraw'} className="nav-link" style={{ textDecoration: 'none' }}>
                                    <div id="Group_6647_wa">
                                        <svg className="Rectangle_2229_gr_wa">
                                            <rect id="Rectangle_2229_gr_wa" rx="5" ry="5" x="0" y="0" width="100" height="30">
                                            </rect>
                                        </svg>
                                        <div id="Withdraw_wa">
                                            <span>Withdraw</span>
                                        </div>
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                        {/* <div id="Group_6778_wa">
                        <svg className="Rectangle_2450_wa">
                            <rect id="Rectangle_2450_wa" rx="10" ry="10" x="0" y="0" width="325" height="125">
                            </rect>
                        </svg>
                        <div id="Group_6772_wa">
                            <div id="Group_5298_gw_wa">
                                <div id="All_gx_wa">
                                    <span>76,000.00</span>
                                </div>
                                <div id="All_gy_wa">
                                    <span>Usd.</span>
                                </div>
                            </div>
                            <div id="Group_6648_gz_wa">
                                <div id="UGX_Balance_wa">
                                    <span>UGX Balance</span>
                                </div>
                            </div>
                            <NavLink to={'/swap'} className="nav-link" style={{ textDecoration: 'none' }}>
                            <div id="Group_6775_wa">
                                <svg className="Rectangle_2229_g_wa">
                                    <rect id="Rectangle_2229_g_wa" rx="5" ry="5" x="0" y="0" width="100" height="30">
                                    </rect>
                                </svg>
                                <div id="Swap_to_KES_g_wa">
                                    <span>Swap to KES</span>
                                </div>
                            </div>
                            </NavLink>
                        </div>
                    </div> */}
                        <div id="Group_6646_wa">
                            <div id="Group_6636_wa">
                                <div className="section col-md-6">
                                    <h3 className="section-title" style={{ fill: "black", fontStyle: "Roboto" }}></h3>
                                    <div className="section-content">
                                        <ResponsiveContainer width={481} height={304}>
                                            <LineChart data={data} margin={{ top: 15, right: 0, bottom: 15, left: 10 }}>
                                                <Tooltip />
                                                <XAxis dataKey="date" style={{ fill: "black", fontSize: "13" }} value={"Height (ft.)"} />
                                                <YAxis style={{ fill: "black", fontSize: "13" }}>
                                                    <Label style={{ fontSize: "130%", paddingBottom: '40px' }}
                                                        angle={-90}
                                                    // position={{ x: 50, y: -10 }}
                                                    // value="Payouts" 
                                                    />
                                                </YAxis>
                                                <Legend />
                                                <Line type="monotone" dataKey="data" stroke="#17A8F5" />
                                            </LineChart>
                                        </ResponsiveContainer>
                                    </div>
                                </div>

                            </div>
                            <div id="Group_6645_wa">
                                <div id="Group_5298_hi_wa">
                                    <div id="All_hj_wa">
                                        <span>{totals}</span>
                                    </div>
                                    <div id="All_hk_wa">
                                        <span>KES.</span>
                                    </div>
                                </div>
                                <div id="Group_5299_wa">
                                    <div id="Group_3969_hm_wa">
                                        <svg className="Rectangle_2057_hn_wa">
                                            <rect id="Rectangle_2057_hn_wa" rx="5" ry="5" x="0" y="0" width="75" height="25">
                                            </rect>
                                        </svg>
                                    </div>
                                    <div id="money_out_wa">
                                        <span>money out</span>
                                    </div>
                                </div>
                                <div id="Total_Payouts__wa">
                                    <span>Total Payouts </span>
                                </div>
                                <div id="All_hq_wa">
                                    <span>Past 30 days</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <HotlinksWallet handleExport={handleExport}></HotlinksWallet>
                <Wrapper navigation='Wallet'></Wrapper>
            </MediaQuery>


            <MediaQuery maxWidth={1000}>
                <div className="mobilewalletoverview__wa_mob">
                    <div className="mobilewalletoverview-inner_wa_mob">
                        <div className="group-frame_wa_mob">
                            <div className="group-frame_wa_mob">
                                <div className="walletoverview_wa_mob">
                                    <HotLinksWalletMobile></HotLinksWalletMobile>
                                    <div className="totalpayouts_wa_mob">
                                        <div className="frame-parent6_wa_mob">
                                            <div className="funds-in-your-wallet-group_wa_mob">
                                                <div className="reference_wa_mob">Total Payouts</div>
                                                <div className="all12_wa_mob">KES</div>
                                                <div className="all3_wa_mob">{totals}.00</div>
                                                <div className="group-div_wa_mob">
                                                    <div className="group-parent1_wa_mob">
                                                        <div className="rectangle-wrapper_wa_mob">
                                                            <div className="group-child1_wa_mob" />
                                                        </div>
                                                        <div className="money-out_wa_mob">money out</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="group-wrapper2_wa_mob">
                                                <div className="frame-child4_wa_mob">
                                                    <svg className="Line_359_wa_mob" viewBox="0 0 13.144 16.255">
                                                        <path id="Line_359_wa_mob" d="M 0 0 L 13.14381694793701 16.2552604675293">
                                                        </path>
                                                    </svg>
                                                    <svg className="Line_360_wa_mob" viewBox="0 0 5.715 15.005">
                                                        <path id="Line_360_wa_mob" d="M 0 0 L 5.714702606201172 15.00485706329346">
                                                        </path>
                                                    </svg>
                                                    <svg className="Line_361_wa_mob" viewBox="0 0 21.716 15.005">
                                                        <path id="Line_361_wa_mob" d="M 0 15.00485706329346 L 21.71586799621582 0">
                                                        </path>
                                                    </svg>
                                                    <svg className="Line_362_wa_mob" viewBox="0 0 29.145 26.259">
                                                        <path id="Line_362_wa_mob" d="M 0 0 L 29.14498329162598 26.25850105285645">
                                                        </path>
                                                    </svg>
                                                    <svg className="Line_363_wa_mob" viewBox="0 0 21.144 37.512">
                                                        <path id="Line_363_wa_mob" d="M 0 37.51214599609375 L 21.1443977355957 0">
                                                        </path>
                                                    </svg>
                                                    <svg className="Line_364_wa_mob" viewBox="0 0 17.144 41.263">
                                                        <path id="Line_364_wa_mob" d="M 0 0 L 17.14410781860352 41.26335906982422">
                                                        </path>
                                                    </svg>
                                                    <svg className="Line_365_wa_mob" viewBox="0 0 8.572 10.003">
                                                        <path id="Line_365_wa_mob" d="M 0 10.00323867797852 L 8.572053909301758 0">
                                                        </path>
                                                    </svg>
                                                    <svg className="Line_366_wa_mob" viewBox="0 0 34.288 8.753">
                                                        <path id="Line_366_wa_mob" d="M 0 8.752834320068359 L 34.28821563720703 0">
                                                        </path>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>




                                    <div className="paymentdetails_pad_mob">
                                        <div className="paymentdetails-inner_pad_mob">
                                            <div className="frame-parent1_pad_mob">
                                                <div className="all-wrapper_pad_mob">
                                                    <div className="all_pad_mob">Summary</div>
                                                </div>

                                                <div className="paymentdetails-inner_pad_mob">
                                                    <svg className="line-icon_pad_mob " viewBox="0 1 414 0.5">
                                                        <path id="line-icon_pad_mob " d="M 0 1 L 414 1">
                                                        </path>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="paymentdetails-child_pad_mob">
                                            <div className="frame-parent2_pad_mob">
                                                <div className="reference-parent_pad_mob">
                                                    <div className="reference_pad_mob">Date Updated</div>
                                                    <div className="adidas-ultraboost-wrapper_pad_mob">
                                                        <div className="adidas-ultraboost_pad_mob">
                                                            {datetimeFormatter_human(SuccessfulLastPayouts.updated_at ? datetimeFormatter_human(SuccessfulLastPayouts.updated_at) : "-")}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="reference-parent_pad_mob">
                                                    <div className="barry-oloo_pad_mob">Transaction Count</div>
                                                    <div className="adidas-ultraboost-wrapper_pad_mob">
                                                        <div className="adidas-ultraboost_pad_mob">{SuccessfulPayouts.length}</div>
                                                    </div>
                                                </div>
                                                <div className="reference-parent_pad_mob">
                                                    <div className="reference2_pad_mob">Fee</div>
                                                    <div className="adidas-ultraboost-wrapper_pad_mob">
                                                        <div className="adidas-ultraboost_pad_mob">KES. {totals}</div>
                                                    </div>
                                                </div>
                                                <div className="reference-parent_pad_mob">
                                                    <div className="barry-oloo_pad_mob">Last Transaction</div>
                                                    <div className="adidas-ultraboost-wrapper_pad_mob">
                                                        <div className="adidas-ultraboost_pad_mob">{SuccessfulLastPayouts.amount ? SuccessfulLastPayouts.amount : "-"}</div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </>
    )
};

export default Wallet;

const PageNotFound = () => {
    return (
        <>
            <div id="error-page">
                <div className="error-page__h1" >
                    <div className="_404_">404 | This page could not be found</div>
                </div>

            </div>


        </>
    )
}
export default PageNotFound
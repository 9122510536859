import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

const HotlinksSettings = (innerPage: any) => {

    const [titleSelected, setTitleSelected] = useState(1)
    const handleNavLinkClick = (index: React.SetStateAction<number>) => {
        setTitleSelected(index);
    }

    const location = useLocation();
    const currentRoutePath = location.pathname;


    useEffect(() => {
        if (currentRoutePath === '/settings-profile') {
            setTitleSelected(1);

        } else if (currentRoutePath === '/settings-bankaccounts' || innerPage.innerPage === "AddBank" || innerPage.innerPage === "EditBank") {
            setTitleSelected(2);

        } else if (currentRoutePath === '/settings-integrations') {
            setTitleSelected(3);

        } else if (currentRoutePath === '/settings_apikeys') {
            setTitleSelected(4);

        } else if (currentRoutePath === '/settings-team' || innerPage.innerPage === "AddUser") {
            setTitleSelected(5);

        }
    }, [currentRoutePath])





    return (

        <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse" >
            <div id="Component_80__10_setthotl" className="Component_80___10">
                <svg className="Line_setthotl" viewBox="0 1 1247.983 0.5">
                    <path id="Line_setthotl" d="M 0 1 L 1247.98291015625 1">
                    </path>
                </svg>
                <NavLink to={'/settings-profile'} className="nav-link" style={{ textDecoration: 'none' }}>
                    <div id="All_setthotl" key={1} onClick={() => handleNavLinkClick(1)} className={`div ${1 === titleSelected ? 'bold-title' : ''}`} >
                        <span>Business Profile</span>
                    </div>
                </NavLink>
                <NavLink to={'/settings-bankaccounts'} className="nav-link" style={{ textDecoration: 'none' }}>
                    <div id="All_fe_setthotl" key={2} onClick={() => handleNavLinkClick(2)} className={`div ${2 === titleSelected ? 'bold-title' : ''}`} >
                        <span>Bank Account</span>
                    </div>
                </NavLink>
                <NavLink to={'/settings-integrations'} className="nav-link" style={{ textDecoration: 'none' }}>
                    <div id="All_ff_setthotl" key={3} onClick={() => handleNavLinkClick(3)} className={`div ${3 === titleSelected ? 'bold-title' : ''}`} >
                        <span>Integrations</span>
                    </div>
                </NavLink>
                <NavLink to={'/settings_apikeys'} className="nav-link" style={{ textDecoration: 'none' }}>
                    <div id="All_fg_setthotl" key={4} onClick={() => handleNavLinkClick(4)} className={`div ${4 === titleSelected ? 'bold-title' : ''}`} >
                        <span>API Keys</span>
                    </div>
                </NavLink>
            </div>
        </nav>
    )
}

export default HotlinksSettings;
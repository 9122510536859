import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import '../../styles/Payments/subscriptions.css'
import '../../styles/Components/NavBar.css'
import '../../styles/Components/UserRibbon.css'
import '../../styles/Components/HotLinks_Checkout.css'
import Wrapper from "../../components/Wrapper";
import HotlinksCheckout from '../../components/HotLinks_Checkout';
import '../../styles/Components/HotLinksCheckoutMobile.css'
import { SubscriptionsInfo } from "../../models/customers";
import NavBar_Mobile from '../../components/NavBar_Mobile';
import '../../styles/Components/NavBar_Mobile.css'

import { get_available_products } from "../../services/Products/productsinfo";
import { Spinner } from '../../components/spinner';
import { get_subscriptions } from '../../services/Payments/subcritionsinfo';
import { datetimeFormatter_human, datetimeFormatter_, dateFormatter_subscription_human } from '../../utils/date_formatter';
import MediaQuery from 'react-responsive';

import FilterableDataTable from '../../utils/data-table-search';
import { TableColumn } from 'react-data-table-component';
import { BouncingDotsLoader, BouncingDotsLoaderDataTable } from '../../components/loading-dots';
import HotLinksCheckoutMobile from '../../components/HotLinksCheckoutMobile';
import '../../styles/Components/loading-dots.css'


const Subscriptions = () => {
    const [SubscriptionsInfo, setSubscriptions] = useState([] as any);
    const [loading, setLoading] = useState(false)
    const [filterText, setFilterText] = React.useState('');

    useEffect(() => {
        async function fetchSubscriptions() {
            setLoading(true)
            await get_subscriptions().then(subscriptions => {

                setSubscriptions(subscriptions);
                

            }).catch(error => {

            }).finally(() => {
                setLoading(false)

            })

        }
        fetchSubscriptions();

    }, []);


    function capitalizeFirstLetter(str: string) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }


    const columns: TableColumn<{ end_date: string; slug: string; currency: string; customer: { customer_name: string }; product: { product_name: string }; amount: any; billing_cycle: string }>[] = [
        {
            name: 'CUSTOMER',
            selector: (row) => row.customer.customer_name,
            sortable: true,
        },
        {
            name: 'PRODUCT',
            selector: (row) => row.product.product_name,
            sortable: true,
        },
        {
            name: 'AMOUNT DUE',
            selector: (row) => `${row.currency}. ${row.amount}`,
            sortable: true,
        },
        {
            name: 'BILLING CYCLE',
            selector: (row) => capitalizeFirstLetter(row.billing_cycle),
            sortable: true,
        },
        {
            name: 'DATE DUE',
            selector: (row) => datetimeFormatter_human(row.end_date),
            sortable: true,
        },
    ];


    // Filter
    const filteredItems = SubscriptionsInfo.filter((item: { customer: { customer_name: string }; product: { product_name: string }; amount: any; currency: string; billing_cycle: string; end_date: string; }) =>
        item.customer.customer_name?.toLowerCase().includes(filterText.toLowerCase()) ||
        item.product.product_name?.toLowerCase().includes(filterText.toLowerCase()) ||
        item.amount.toString().toLowerCase().includes(filterText.toLowerCase()) ||
        item.currency.toLowerCase().includes(filterText.toLowerCase()) ||
        item.billing_cycle.toLowerCase().includes(filterText.toLowerCase()) ||
        datetimeFormatter_human(item.end_date)
            .toLowerCase()
            .includes(filterText.toLowerCase())
    );

    // Export to CSV
    const handleExport = () => {
        const columnNames = ['Customer', 'Product', 'Currency', 'Amount', 'Billing Cycle', 'Date Due'];
        const dataRows = SubscriptionsInfo.map((subscription: { end_date: string; slug: string; currency: string; customer: { customer_name: string }; product: { product_name: string }; amount: any; billing_cycle: string }) => (
            [subscription.customer.customer_name, subscription.product.product_name, subscription.currency, subscription.amount, subscription.billing_cycle, subscription.end_date].join(',')
        ));
        const csv = [columnNames.join(','), ...dataRows].join('\n');
        const csvData = new Blob([csv], { type: 'text/csv' });
        const csvUrl = URL.createObjectURL(csvData);
        const hiddenElement = document.createElement('a');
        hiddenElement.href = csvUrl;
        hiddenElement.target = '_blank';
        hiddenElement.download = 'Subscription_Data.csv';
        hiddenElement.click();
    };



    return (
        <>
            <MediaQuery minWidth={1001}>
                <main className="form-signin">

                    <div id="Customers_cussub">
                        <div id="Group_4666_cussub">
                            {loading ? (<BouncingDotsLoaderDataTable></BouncingDotsLoaderDataTable>
                            ) : (
                                <FilterableDataTable data={filteredItems} columns={columns} redirect_url={'subscription-detail'} type={'slug'} rowHeight={'normal'} scrollHeight={'660px'} />

                            )
                            }
                        </div>
                    </div>

                </main>
                <HotlinksCheckout handleExport={handleExport}></HotlinksCheckout>
                <Wrapper navigation="Payments"></Wrapper>
            </MediaQuery>

        


            <MediaQuery maxWidth={1000}>
                <div className="mobilepages">
                    <div className="mobilepages-inner">
                        <div className="group-frame">
                            <div className="group-frame">
                                <div className="mobilepages1">
                                    <HotLinksCheckoutMobile></HotLinksCheckoutMobile>

                                    {/* ListView */}
                                    <div className="listview1_sub_mob">
                                        <div className="frame-parent31_sub_mob">
                                            {SubscriptionsInfo.map((subscription_info: { customer: { customer_name: any }, end_date: any, product: { product_name: any }, currency: any, amount: any, slug: any, billing_cycle: any, status: any }) => {
                                                return (
                                                    <NavLink to={`/subscription-detail/${subscription_info.slug}`} className="frame-parent32_sub_mob" style={{ textDecoration: 'none' }}>
                                                        <div className="frame-wrapper8_sub_mob">
                                                            <div className="barry-oloo-group_sub_mob">
                                                                <div className="barry-oloo3_sub_mob">{subscription_info.customer.customer_name}</div>
                                                                <div className="group-parent2_sub_mob">
                                                                    <div className="rectangle-parent12_sub_mob">
                                                                        <div className={subscription_info.status == "Active" ? "group-child2_sub_mob" : "group-child2_sub_mob.expired"}/>
                                                                        <div className="active_sub_mob">{subscription_info.status == "Active" ? "active" : "expired"}</div>
                                            
                                                                    </div>
                                                                    <div className="schedule_sub_mob">{capitalizeFirstLetter(subscription_info.billing_cycle)}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="due-24-july-2023-parent_sub_mob">
                                                            <div className="due-24-july_sub_mob">{datetimeFormatter_human(subscription_info.end_date)}</div>
                                                            <div className="kes-2399000-wrapper_sub_mob">
                                                                <div className="kes-2399000_sub_mob">{subscription_info.currency}. {subscription_info.amount}</div>
                                                            </div>
                                                        </div>
                                                    </NavLink>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </>
    )
};

export default Subscriptions;

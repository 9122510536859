// Get linkbasket 
export async function get_linkbasket_category_detail(brand_alias : string | undefined, categoryID : string | undefined){

    const response = await fetch(process.env.REACT_APP_CHPTER_API_URL+"/link-basket-category-detail/" + brand_alias + "/" + categoryID, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer "+localStorage.getItem("JWT"),
          'Accept': 'application/json',
        },
      });
    const data = await response.json();
    console.log(data.status);
    console.log("data :::: get_linkbasket_category_detail ::::", data);
  
    if(data.status === 401){
      //Redirect to login Token expired
    }    
    if(data.status === 200){
        return data.category;
    }
    
    return [];
}

// TODO -- Check if subcategories is true
//      -- Show subcategories if true or products if false
// Get linkbasket Navigation
export async function get_linkbasket_navigation(brand_alias : string | undefined){

    const response = await fetch(process.env.REACT_APP_CHPTER_API_URL+"/merchants/nocodenavigation/" + brand_alias, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer "+localStorage.getItem("JWT"),
          'Accept': 'application/json',
        },
      });
    const data = await response.json();
    console.log(data.status);
    // console.log("data :::: get_linkbasket_navigation ::::", data);
  
    if(data.status === 401){
      //Redirect to login Token expired
    }    
    if(data.status === 200){
        return data.paymentlinks;
    }
    
    return [];
}


export async function get_available_products(){

    // TODO -- Add Brand alias
    const response = await fetch(process.env.REACT_APP_CHPTER_API_URL+"/products/Available", {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer "+localStorage.getItem("JWT"),
          'Accept': 'application/json',
        },
      });
    const data = await response.json();
    console.log(data.status);
  
    if(data.status === 401){
      //Redirect to login Token expired
    }    
    if(data.status === 200){
        return data.products;
    }
  
    return [];
}

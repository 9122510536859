import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import '../../styles/Customers/invoices.css'
import '../../styles/Components/NavBar.css'
import '../../styles/Components/UserRibbon.css'
import '../../styles/Components/HotLinks_Checkout.css'
import Wrapper from "../../components/Wrapper";
import HotlinksCheckout from '../../components/HotLinks_Checkout';
import '../../styles/Components/HotLinksCheckoutMobile.css'
import { InvoicesInfo } from "../../models/customers";
import { get_invoices } from "../../services/Customers/invoicesinfo";
import { Spinner } from '../../components/spinner';
import { datetimeFormatter_human } from '../../utils/date_formatter';
import MediaQuery from 'react-responsive';
import NavBar_Mobile from '../../components/NavBar_Mobile';
import '../../styles/Components/NavBar_Mobile.css'
import { get_branding_info } from '../../services/Settings/brandinginfo';
import { BouncingDotsLoader, BouncingDotsLoaderDataTable } from '../../components/loading-dots';


import FilterableDataTable from '../../utils/data-table-search';
import { TableColumn } from 'react-data-table-component';
import HotLinksCheckoutMobile from '../../components/HotLinksCheckoutMobile';
import '../../styles/Components/loading-dots.css'


const Invoices = () => {
    const [InvoicesInfo, setInvoices] = useState([] as any);
    const [loading, setLoading] = useState(false)
    const [filterText, setFilterText] = React.useState('');
    const [brandingData, setBrandingData] = useState([] as any);
    const [brandAlias, setBrandAlias] = useState('');

    useEffect(() => {
        async function fetchInvoices() {
            setLoading(true)
            //show spinner
            await get_invoices().then(Invoices => {
                
                setInvoices(Invoices);

            }).catch(error => {

            }).finally(() => {
                //Hide spinner
                setLoading(false)
            })
        }

        fetchInvoices();

    }, []);

    useEffect(() => {
        async function fetchBrandingInfo() {
            var branding = await get_branding_info();
            setBrandingData(branding);
            setBrandAlias(branding.brand_alias)

        }


        fetchBrandingInfo();

    }, []);

    const columns: TableColumn<{ status: string; due_date: string; created_at: string; slug: string; currency: string; customer: { customer_name: string }; invoice_number: string; invoice_amount: any; }>[] = [
        {
            name: 'AMOUNT',
            selector: (row) => `${row.currency}. ${row.invoice_amount}`,
            sortable: true,
        },
        {
            name: 'CUSTOMER',
            selector: (row) => row.customer.customer_name,
            sortable: true,
        },
        {
            name: 'PAYMENT STATUS',
            cell: (row) => (
                <>
                    {(row.status === "Pending") ? (
                        <div id="Group_6691_cusin">
                            <div id="Group_3969_cusin">
                                <svg className="Rectangle_2057_f_cusin">
                                    <rect id="Rectangle_2057_f_cusin" style={{ fill: "rgb(118, 162, 255)" }} rx="3" ry="3" x="0" y="0" width="84" height="20">
                                    </rect>
                                </svg>
                            </div>
                            <div id="active_cusin" style={{ left: "22px" }}>
                                <span>Pending</span>
                            </div>
                            <div id="Layer_2_f_cusin" style={{ display: "none" }}>
                                <div id="checkmark_cusin">
                                    <svg className="Rectangle_2122_cusin">
                                        <rect id="Rectangle_2122_cusin" rx="0" ry="0" x="0" y="0" width="18" height="18">
                                        </rect>
                                    </svg>
                                    <svg className="Path_9092_cusin" viewBox="3.999 5.956 12.298 9.233">
                                        <path id="Path_9092_cusin" d="M 8.492274284362793 15.18946647644043 C 8.279788017272949 15.18878364562988 8.077116012573242 15.09994220733643 7.932625770568848 14.94414138793945 L 4.206743717193604 10.98060131072998 C 3.916711807250977 10.67151546478271 3.932157278060913 10.18583393096924 4.2412428855896 9.895801544189453 C 4.550328254699707 9.605769157409668 5.036009311676025 9.621214866638184 5.326041698455811 9.930300712585449 L 8.484606742858887 13.29586124420166 L 14.93206882476807 6.242752075195312 C 15.11035251617432 6.020743370056152 15.39699077606201 5.915829658508301 15.6764669418335 5.970292091369629 C 15.95594310760498 6.024755477905273 16.18221473693848 6.229621887207031 16.26408958435059 6.502328872680664 C 16.34596633911133 6.775036811828613 16.26995849609375 7.070656776428223 16.06669998168945 7.270052909851074 L 9.059587478637695 14.9364767074585 C 8.916444778442383 15.09511184692383 8.713593482971191 15.18681240081787 8.49993896484375 15.18946933746338 Z">
                                        </path>
                                    </svg>
                                </div>
                            </div>
                        </div>

                    ) : (
                        <div id="Group_6691_cusin">
                            <div id="Group_3969_cusin">
                                <svg className="Rectangle_2057_f_cusin">
                                    <rect id="Rectangle_2057_f_cusin" rx="3" ry="3" x="0" y="0" width="84" height="20">
                                    </rect>
                                </svg>
                            </div>
                            <div id="active_cusin">
                                <span>Paid</span>
                            </div>
                            <div id="Layer_2_f_cusin">
                                <div id="checkmark_cusin">
                                    <svg className="Rectangle_2122_cusin">
                                        <rect id="Rectangle_2122_cusin" rx="0" ry="0" x="0" y="0" width="18" height="18">
                                        </rect>
                                    </svg>
                                    <svg className="Path_9092_cusin" viewBox="3.999 5.956 12.298 9.233">
                                        <path id="Path_9092_cusin" d="M 8.492274284362793 15.18946647644043 C 8.279788017272949 15.18878364562988 8.077116012573242 15.09994220733643 7.932625770568848 14.94414138793945 L 4.206743717193604 10.98060131072998 C 3.916711807250977 10.67151546478271 3.932157278060913 10.18583393096924 4.2412428855896 9.895801544189453 C 4.550328254699707 9.605769157409668 5.036009311676025 9.621214866638184 5.326041698455811 9.930300712585449 L 8.484606742858887 13.29586124420166 L 14.93206882476807 6.242752075195312 C 15.11035251617432 6.020743370056152 15.39699077606201 5.915829658508301 15.6764669418335 5.970292091369629 C 15.95594310760498 6.024755477905273 16.18221473693848 6.229621887207031 16.26408958435059 6.502328872680664 C 16.34596633911133 6.775036811828613 16.26995849609375 7.070656776428223 16.06669998168945 7.270052909851074 L 9.059587478637695 14.9364767074585 C 8.916444778442383 15.09511184692383 8.713593482971191 15.18681240081787 8.49993896484375 15.18946933746338 Z">
                                        </path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    )}

                </>
            ),
        },
        {
            name: 'DATE DUE',
            selector: (row) => datetimeFormatter_human(row.due_date),
            sortable: true,
        },
        {
            name: 'DATE CREATED',
            selector: (row) => datetimeFormatter_human(row.created_at),
            sortable: true,
        },
    ];


    // Filter
    const filteredItems = InvoicesInfo.filter((item: { customer: { customer_name: string }; invoice_amount: any; invoice_number: string; currency: string; created_at: string; due_date: string; slug: string; }) =>
        item.customer.customer_name.toLowerCase().includes(filterText.toLowerCase()) ||
        item.invoice_amount.toString().toLowerCase().includes(filterText.toLowerCase()) ||
        item.invoice_number.toLowerCase().includes(filterText.toLowerCase()) ||
        item.currency.toLowerCase().includes(filterText.toLowerCase()) ||
        datetimeFormatter_human(item.created_at)
            .toLowerCase()
            .includes(filterText.toLowerCase()) ||
        datetimeFormatter_human(item.due_date)
            .toLowerCase()
            .includes(filterText.toLowerCase())
    );

    // Export to CSV
    const handleExport = () => {
        const columnNames = ['Currency', 'Amount', 'Customer', 'Invoice No.', 'Date Due', 'Date Created'];
        const dataRows = InvoicesInfo.map((invoice: { due_date: string; created_at: string; slug: string; currency: string; customer: { customer_name: string }; invoice_number: string; invoice_amount: any; }) => (
            [invoice.currency, invoice.invoice_amount, invoice.customer.customer_name, invoice.invoice_number, invoice.due_date, invoice.created_at].join(',')
        ));
        const csv = [columnNames.join(','), ...dataRows].join('\n');
        const csvData = new Blob([csv], { type: 'text/csv' });
        const csvUrl = URL.createObjectURL(csvData);
        const hiddenElement = document.createElement('a');
        hiddenElement.href = csvUrl;
        hiddenElement.target = '_blank';
        hiddenElement.download = 'Invoice_Data.csv';
        hiddenElement.click();
    };


    return (
        <>
            <MediaQuery minWidth={1001}>
                <main className="form-signin">
                    <div id="CustomerInvoices_cusin">

                        <div id="Group_4666_cusin">

                            {loading ? (<BouncingDotsLoaderDataTable></BouncingDotsLoaderDataTable>
                            ) : (
                                <FilterableDataTable data={filteredItems} columns={columns} redirect_url={'invoicedetail'} type={'invoice_number'} rowHeight={'normal'} scrollHeight={'660px'} />


                            )}
                        </div>

                    </div>
                </main>
                <HotlinksCheckout handleExport={handleExport}></HotlinksCheckout>
                <Wrapper navigation='Payments'></Wrapper>
            </MediaQuery>

            <MediaQuery maxWidth={1000}>
                <div className="mobilepages">
                    <div className="mobilepages-inner">
                        <div className="group-frame">
                            <div className="group-frame">
                                <div className="mobilepages1">
                                    <HotLinksCheckoutMobile></HotLinksCheckoutMobile>

                                    {/* ListView */}
                                    <div className="listview_inv_mob">
                                        {InvoicesInfo.map((invoice_info: {
                                            status: string; invoice_amount: any; created_at: any; customer: { customer_name: any; }; due_date: any; invoice_number: any; currency: any
                                        }) => (
                                            <NavLink to={`/invoicedetail/${invoice_info.invoice_number}`} className="frame-parent34_inv_mob" style={{ textDecoration: 'none' }}>
                                                <div className="frame-parent35_inv_mob">
                                                    <div className="frame-wrapper8_inv_mob">
                                                        <div className="kes-1490000-parent_inv_mob">
                                                            <div className="kes-1490000_inv_mob">{invoice_info.currency}. {invoice_info.invoice_amount}</div>
                                                            <div className="barry-oloo1_inv_mob">{invoice_info.customer.customer_name}</div>
                                                        </div>
                                                    </div>
                                                    <div className="july-2023-parent_inv_mob">
                                                        <div className="july-2023_inv_mob">{datetimeFormatter_human(invoice_info.due_date)}</div>

                                                        {invoice_info.status === "Pending" ? (
                                                            <div className="group-wrapper15_inv_mob">
                                                                <div className="group-parent2_inv_mob">
                                                                    <div className="rectangle-wrapper_inv_mob">
                                                                        <div className="group-child3_inv_mob" style={{ fill: "rgb(118, 162, 255)" }} />
                                                                    </div>
                                                                    <div className="paid_inv1_mob">Pending</div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="group-wrapper15_inv_mob">
                                                                <div className="group-parent2_inv_mob">
                                                                    <div className="rectangle-wrapper_inv_mob">
                                                                        <div className="group-child2_inv_mob" />
                                                                    </div>
                                                                    <div className="paid_inv_mob">Paid</div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </NavLink>
                                        )
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </>
    )
};

export default Invoices;

import axios from 'axios';
import React, { useEffect, useState, SyntheticEvent } from 'react';
import { NavLink } from 'react-router-dom';
import '../../styles/Settings/settingsteam_adduser.css'
import '../../styles/Components/NavBar.css'
import '../../styles/Components/UserRibbon.css'
import '../../styles/Components/HotLinks_Settings.css'
import Wrapper from "../../components/Wrapper";
import HotLinks_Settings from '../../components/HotLinks_Settings';
import { SuccessfulPayments } from "../../models/payments";
import { Withdrawal } from "../../models/wallet";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import 'react-toastify/dist/ReactToastify.css';
import '../../styles/Components/Toastify.css'

const SettingsTeamAddUser = () => {
    const initialValues = {first_name:"", last_name: "",email:"", phone_number:"", legal_entity:"", user_role:""};
    const [formValues, setFormValues]= useState(initialValues); 
    const [formErrors, setFormErrors]= useState<Record<string, string>>({});
    const [redirect, setredirect] = useState(false)
    const handleChange =(e: { target: { name: any; value: any; }; }) =>{
    
        const { name, value} = e.target;
        setFormValues({ ...formValues, [name]: value});
    
        };
        const isEmailValidator =  require('validator').isEmail

        const Validate = (Values: { first_name: any;last_name: any; email: any;phone_number: any; legal_entity:any; user_role: any;  }) =>{
            const errors:any = {};
            if (!Values.first_name) { 
                toast("First name is required", { type: "error" });
                errors.first_name = "First name is required"; }
             else if(!Values.last_name) {
                toast("Last name is required", { type: "error" });
                errors.last_name = "Last name is required"; }
            else if (!Values.email){
                toast("Email is required", { type: "error" });
                errors.email ="Email is required";}
            else if (!isEmailValidator(Values.email)){
                toast("Invalid Email formart", { type: "error" });
                errors.email="Invalid Email formart"; }
             else if (!Values.phone_number){
                toast(" phone number required", { type: "error" });
                errors.phone_number = " phone number required";}
             else if (!Values.legal_entity) {
                toast("Legal entity is required", { type: "error" });
                errors.legal_entity = "Legal entity is required";}
             else if (!Values.user_role) {
                toast("Users role is required", { type: "error" });
                errors.user_role = "Users role is required";}
            
    
    
            setFormErrors(errors)
            if (Object.keys(errors).length > 0) {
                let errorMessage = "";
                for (const key in formErrors) {
                  if (formErrors[key]) {
                    errorMessage += formErrors[key] + "\n";
                  }
                }
              
              }
            return Object.keys(errors).length === 0;
        };

    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();
        const isValid = Validate(formValues);
        if (!isValid) {
         return;
             }

        let api_url = process.env.REACT_APP_CHPTER_API_URL + '/merchants/profile'
        let headers = {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("JWT"),
        };

        const data = JSON.stringify(formValues)


        await axios.post(api_url, data, { headers: headers })
            .then((response) => {


                setredirect(true)

            })
            .catch((error) => {
                // Error
                if (error.response) {
                    
                } else if (error.request) {
                    
                } else {
   
                }
            });

    }


    return (
        <>
            <main className="form-signin">
                <form onSubmit={submit}>
                    <div id="Group_6924_teamadd">
                        <div id="Group_4741_teamadd" onClick={submit}>
                            <div id="Group_3969_teamadd">
                                <svg className="Rectangle_2057_teamadd">
                                    <rect id="Rectangle_2057_teamadd" rx="5" ry="5" x="0" y="0" width="169.925" height="30">
                                    </rect>
                                </svg>
                            </div>
                            <div id="Group_4628_teamadd">
                                <div id="SEND_INVITE_teamadd">
                                    <span>SEND INVITE</span>
                                </div>
                            </div>
                        </div>
                        <div id="Group_4742_teamadd">
                            <div id="Group_3969_fh_teamadd">
                                <svg className="Rectangle_2057_fi_teamadd">
                                    <rect id="Rectangle_2057_fi_teamadd" rx="5" ry="5" x="0" y="0" width="75" height="30">
                                    </rect>
                                </svg>
                            </div>
                            <div id="Group_4628_fj_teamadd">
                                <div id="CANCEL_teamadd">
                                    <span>CANCEL</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="Group_7090_teamadd">
                        <div id="All_teamadd">
                            <span>Add New User and Role</span>
                        </div>
                        <div id="Group_7042_teamadd">
                            <input id="Rectangle_2002_teamadd" type="text" name="first_name" placeholder="Input First Name" required
                                onChange={handleChange} />
                            <div id="First_Name__teamadd">
                                <span>First Name </span>
                            </div>
                        </div>
                        <div id="Group_7041_teamadd">
                            <input id="Rectangle_2002_fs_teamadd" type="text" name="last_name" placeholder="Input Last Name" required
                                onChange={handleChange} />
                            <div id="Last_Name__teamadd">
                                <span>Last Name </span>
                            </div>
                        </div>
                        <div id="Group_7043_teamadd">
                            <input id="Rectangle_2002_fw_teamadd" type="text" name="email" placeholder="Input Email Address" required
                                onChange={handleChange} />
                            <div id="Email__teamadd">
                                <span>Email </span>
                            </div>
                        </div>
                        <div id="Group_7044_teamadd">
                            <select id="Rectangle_2002_f_teamadd" name="legal_entity"placeholder="Select Legal Entity" onChange={handleChange}>
                                <option value="">Select Legal Entity</option>
                                <option value="Option 1">Option 1</option>
                                <option value="Option 2">Option 2</option>
                            </select>
                            <div id="Legal_entities_teamadd">
                                <span>Legal entities</span>
                            </div>
                        </div>
                        <div id="Group_7089_teamadd">
                            <select id="Rectangle_2002_ga_teamadd"  name="user_role"placeholder="Select User Role" onChange={handleChange}>
                                <option value="">Select User Role</option>
                                <option value="Developer">Developer</option>
                                <option value="Support">Support</option>
                                <option value="View Only">View Only</option>
                            </select>
                            <div id="User_Role_teamadd">
                                <span>User Role</span>
                            </div>
                        </div>
                        <div id="Group_7059_teamadd">
                            <div id="Phone_Number_teamadd">
                                <span>Phone Number</span>
                            </div>
                            <svg className="Rectangle_2002_gd_teamadd">
                                <rect id="Rectangle_2002_gd_teamadd" rx="5" ry="5" x="0" y="0" width="470" height="44">
                                </rect>
                            </svg>
                            <input id="Rectangle_2002_gd_teamadd_" type="number" name="phone_number" placeholder="713480900" required
                                onChange={handleChange} />
                            <svg className="Line_450_teamadd" viewBox="0 0 0.5 44">
                                <path id="Line_450_teamadd" d="M 0 0 L 0 44">
                                </path>
                            </svg>
                            <div id="n_54_teamadd">
                                <span>254</span>
                            </div>
                            <svg className="Path_9296_teamadd" viewBox="4.999 8.999 8.235 4.94">
                                <path id="Path_9296_teamadd" d="M 9.109603881835938 13.93716621398926 C 8.972411155700684 13.9374885559082 8.839458465576172 13.88008880615234 8.733819007873535 13.77493476867676 L 5.210851669311523 10.24811553955078 C 4.961155891418457 9.998798370361328 4.926980972290039 9.553516387939453 5.134520053863525 9.253555297851562 C 5.34205961227417 8.953592300415039 5.712721824645996 8.912540435791016 5.962418079376221 9.161857604980469 L 9.109603881835938 12.32188606262207 L 12.25678825378418 9.274715423583984 C 12.37809181213379 9.156375885009766 12.53365898132324 9.101005554199219 12.68904781341553 9.120864868164062 C 12.84443473815918 9.140724182128906 12.9868221282959 9.234170913696289 13.08468627929688 9.380519866943359 C 13.19339084625244 9.527135848999023 13.24630546569824 9.721546173095703 13.23070526123047 9.916990280151367 C 13.2151050567627 10.1124324798584 13.13239860534668 10.29122161865234 13.00248432159424 10.41035079956055 L 9.47951602935791 13.8172550201416 C 9.370841026306152 13.90578842163086 9.2406005859375 13.94800758361816 9.109603881835938 13.93716621398926 Z">
                                </path>
                            </svg>
                            <img id="icons8-kenya-96_teamadd" src="media/icons8-kenya-96.png" />

                        </div>
                    </div>
                </form>
                <ToastContainer
                    position="top-center"
                    autoClose={9000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick={false}
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    toastClassName="custom-toast"
                    className="toast-container"
                    style={{
                        top: "10%",
                        color: "white",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                    }}
                />
            </main>
            <HotLinks_Settings innerPage="AddUser"></HotLinks_Settings>
            <Wrapper navigation='Settings'></Wrapper>
        </>
    )
};

export default SettingsTeamAddUser;

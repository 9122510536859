// Get All Customers
export async function get_invoices(){
  
  const response = await fetch(process.env.REACT_APP_CHPTER_API_URL+"/customers/invoice/Active", {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("JWT"),
        'Accept': 'application/json',
      },
    });
  const data = await response.json();
  console.log(data.status);

  if(data.status === 401){
    //Redirect to login Token expired
    window.location.href = '/login';

  }

  if(data.status === 200){
      return data.invoices;
  }

  return [];
}

// Get Invoice Detail
export async function get_invoice_detail(invoice_number: string | undefined){

    const response = await fetch(process.env.REACT_APP_CHPTER_API_URL+"/customers/invoice-details/" + invoice_number, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      });
    const data = await response.json();
    console.log(data.status);

    if(data.status === 401){
      //Redirect to login Token expired
      
    }

    if(data.status === 200){
        return data;
    }

    return [];
}


// Get Invoice History 
export async function get_invoice_history_detail(invoice_id:string | undefined){

const response = await fetch(process.env.REACT_APP_CHPTER_API_URL+"/payments/invoice/" + invoice_id + "/status/Successful", {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer "+localStorage.getItem("JWT"),
        'Accept': 'application/json',
    },
    });
const data = await response.json();
console.log(data.status);

if(data.status === 401){
    //Redirect to login Token expired
    
}

if(data.status === 200){
    return data.invoices;
}

return [];
}
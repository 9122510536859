
// Get linkbasket 
export async function get_linkbasket(brand_alias : string | undefined){

    const response = await fetch(process.env.REACT_APP_CHPTER_API_URL+"/link-basket/" + brand_alias, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer "+localStorage.getItem("JWT"),
          'Accept': 'application/json',
        },
      });
    const data = await response.json();
    console.log(data.status);
    // console.log("data :::: get_linkbasket ::::", data);
  
    if(data.status === 401){
      //Redirect to login Token expired
    }    
    if(data.status === 200){
        return data;
    }
    
    return [];
}


// Get linkbasket Navigation
export async function get_linkbasket_navigation(brand_alias : string | undefined){

    const response = await fetch(process.env.REACT_APP_CHPTER_API_URL+"/merchants/nocodenavigation/" + brand_alias, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer "+localStorage.getItem("JWT"),
          'Accept': 'application/json',
        },
      });
    const data = await response.json();
    console.log(data.status);
    console.log("data :::: get_linkbasket_navigation ::::", data);
  
    if(data.status === 401){
      //Redirect to login Token expired
    }    
    if(data.status === 200){
        return data.paymentlinks;
    }
    
    return [];
}


// Get linkbasket Navigation
export async function getLinkbasketNavigation(brand_alias : string | undefined){

  const response = await fetch(process.env.REACT_APP_CHPTER_API_URL+"/merchants/nocodenavigation/" + brand_alias, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer "+localStorage.getItem("JWT"),
        'Accept': 'application/json',
      },
    });
  const data = await response.json();
  console.log(data.status);
  console.log("data :::: get_linkbasket_navigation ::::", data);

  if(data.status === 401){
    //Redirect to login Token expired
  }    
  if(data.status === 200){
      return data;
  }
  
  return [];
}

// Get linkbasket Navigation
export async function getLinkbasketCategories(brand_alias : string | undefined){

  const response = await fetch(
    process.env.REACT_APP_CHPTER_API_URL+"/categories/" + brand_alias, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  const data = await response.json();
  return data;
}

// Get linkbasket Navigation
export async function getLinkbasketSubCategoriesInCategories(category_id : string | undefined){

  const response = await fetch(
    process.env.REACT_APP_CHPTER_API_URL+"/sub_categories/" + category_id, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  const data = await response.json();
  return data;
}

// Get linkbasket Navigation
export async function getLinkbasketProductsInCategories(category_id : string | undefined){

  const response = await fetch(
    process.env.REACT_APP_CHPTER_API_URL+"/category/products/" + category_id, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  const data = await response.json();
  return data;
}

export async function getLinkbasketProductsOnly(brand_alias : string | undefined){

  const response = await fetch(
    process.env.REACT_APP_CHPTER_API_URL+"/products/only/" + brand_alias, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  const data = await response.json();
  console.log("Products only response")
  console.log(data)
  return data;
}

export async function getLinkbasketBranding(brand_alias : string | undefined){

  const response = await fetch(
    process.env.REACT_APP_CHPTER_API_URL+"/merchants/branding/" + brand_alias, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  const data = await response.json();
  console.log("Branding")
  console.log(data)
  return data;
}


export async function getLinkbasketProductsInSubCategories(brand_alias : string | undefined, sub_category_id : string | undefined){

  const response = await fetch(
    process.env.REACT_APP_CHPTER_API_URL+"/sub-category/products/" + brand_alias+"/"+sub_category_id, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  const data = await response.json();
  console.log("Branding")
  console.log(data)
  return data;
}




import axios from 'axios';
import React, { useEffect, useState, SyntheticEvent } from 'react';
import { Navigate, NavLink, useNavigate } from 'react-router-dom';
import Component from "../../components/NoCode_Component";
import Preview from "../../components/NoCode_Preview";
import MediaQuery from 'react-responsive';
import '../../styles/Settings/settingsbranding_mobile.css'
import { get_branding_info } from "../../services/Settings/brandinginfo";
import AWS from 'aws-sdk';
import randomstring from 'randomstring';

const SettingsBrandingMobile = () => {
    const [redirect, setredirect] = useState(false)
    const [brandingData, setBrandingData] = useState([] as any);
    const [color, setBrandColour] = useState('')
    const [branding_logo, setBrandingLogo] = useState<any | null>(null);
    const [new_branding_logo, setNewBrandingLogo] = useState<any | null>(null);
    const [imagefile, setImageFile] = useState<any | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [error, setError] = useState(null);
    const [fileName, setFileName] = useState('')
    const [fileNameImage, setfileNameImage] = useState<any | null>(null);

    const handleChange = (event: { target: { name: any; value: any; type: any; checked: any; }; }) => {
        const { name, value, type, checked } = event.target;
        setBrandingData((prevData: any) => ({
            ...prevData,
            [name]: value
        }));
    };


    function setColor(event: { target: { name: any; value: any; type: any; checked: any; }; }) {
        setBrandColour(event.target.value);
        setBrandingData((prevData: any) => ({
            ...prevData,
            brand_colour: event.target.value
        }));
    }



    useEffect(() => {
        async function fetchBrandingInfo() {
            var branding = await get_branding_info();
            setBrandingData(branding);
            setBrandingLogo(branding.logo)


            if (!fileNameImage) {
                setfileNameImage(branding.logo)
            }

        }

        fetchBrandingInfo();

    }, []);


    const [showDiv, setShowDiv] = useState(false);
    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setNewBrandingLogo(URL.createObjectURL(e.target.files[0]));
            setImageFile(e.target.files);
            setShowDiv(true)

            let file = uploadFile(e.target.files[0])
            setFileName(file)
            setfileNameImage(file)
        }

    };


    AWS.config.update({
        // accessKeyId: process.env.AWS_ACCESS_KEY_ID,
        // secretAccessKey: process.env.AWS_SECRET_ACCESS_KEY
        accessKeyId: 'AKIA5ENPD2MWPRJEYPPP',
        secretAccessKey: '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'
    })


    const myBucket = new AWS.S3({
        //     params: { Bucket: process.env.AWS_BUCKET_NAME},
        //     region: process.env.AWS_REGION,
        params: { Bucket: 'chpter-3-jarvis-bucket' },
        region: 'us-east-1',
    })



    const uploadFile = (file: { name: any; }) => {
        setIsLoading(true);

        const fileName = `${randomstring.generate(7)}-${file.name}`;

        const params = {
            Body: file,
            // Bucket: process.env.AWS_BUCKET_NAME + '/branding',
            Bucket: 'chpter-3-jarvis-bucket/branding',
            Key: fileName
        };

        myBucket.putObject(params)
            .on('httpUploadProgress', (evt) => {
                setProgress(Math.round((evt.loaded / evt.total) * 100))
            })
            .send((err) => {
                setIsLoading(false);
                if (err) {

                } else {
                    setError(null);
                }
            })
        return fileName;

    }


    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();


        let api_url = process.env.REACT_APP_CHPTER_API_URL + '/merchants/branding'
        let headers = {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("JWT"),
        };

        const data = {
            "brand_alias": brandingData.brand_alias,
            "tagline": brandingData.tagline,
            "receipt_message": brandingData.receipt_message,
            "logo": fileNameImage,
            "facebook_url": brandingData.facebook_url,
            "website_url": brandingData.website_url,
            "instagram_url": brandingData.instagram_url,
            "twitter_url": brandingData.twitter_url,
            "tiktok_url": brandingData.tiktok_url,
            "brand_colour": brandingData.brand_colour,
        }

        console.log("branding_data", data)

        await axios.post(api_url, data, { headers: headers })
            .then((response) => {

            })
            .catch((error) => {
                // Error
                if (error.response) {
                    
                } else if (error.request) {
                   
                } else {
                    
                }
            });

    }

    if (redirect) {
        return <Navigate to={'/no-code-appearance'} />
    }




    return (
        <>
            <MediaQuery maxWidth={1000}>
                <div id="MobileSettingsBranding_brnd_mob">
                    <a href="ProjectOverview.html">
                        <div id="All_brnd_mob">
                            <span>Your branding represents your brand visually—from logo to your company tag line.</span>
                        </div>
                    </a>
                    <div id="All_gq_brnd_mob">
                        <span>Branding</span>
                    </div>
                    <a href="ProjectOverview.html">
                        <div id="All_gr_brnd_mob">
                            <span>Brand Details</span>
                        </div>
                    </a>
                    <a href="ProjectOverview.html">
                        <div id="All_gs_brnd_mob">
                            <span>Instagram Url</span>
                        </div>
                    </a>
                    <a href="ProjectOverview.html">
                        <div id="All_gt_brnd_mob">
                            <span>Twitter Url</span>
                        </div>
                    </a>
                    <div id="Group_7634_brnd_mob">
                        <a href="ProjectOverview.html">
                            <div id="All_gv_brnd_mob">


                                <span>Add Logo</span>
                            </div>
                        </a>

                        <div id="Group_7633_brnd_mob">
                            <label className="btn-btn-primary">
                                <input type="file" accept='image/png, image/jpg, image/jpeg' onChange={handleImageChange} />
                            </label>
                            <img id="brandinglogo_brnd_mob" src={"https://chpter-3-jarvis-bucket.s3.amazonaws.com/branding/" + branding_logo

                            } style={{ display: showDiv ? "none" : "block" }} />
                            {new_branding_logo && <img id="brandinglogo_brnd_mob" src={new_branding_logo} style={{ display: showDiv ? "block" : "none" }} />}
                        </div>

                        {!branding_logo ? (
                            <>

                            </>
                        ) : (null)
                        }
                    </div>
                    <NavLink to={"/settings-mobile"}>
                        <div id="Group_7465_brnd_mob">
                            <svg className="Rectangle_2103_brnd_mob">
                                <rect id="Rectangle_2103_brnd_mob" rx="5" ry="5" x="0" y="0" width="32" height="32">
                                </rect>
                            </svg>
                            <svg className="Path_9390_brnd_mob" viewBox="0.636 0.05 10.513 18">
                                <path id="Path_9390_brnd_mob" d="M 5.357423305511475 10.11950778961182 C 4.766643047332764 9.528789520263672 4.766643047332764 8.571026802062988 5.357423305511475 7.980309963226318 L 10.7065315246582 2.631746768951416 C 11.29722785949707 2.041119337081909 11.2972297668457 1.083506107330322 10.7065315246582 0.4928695559501648 L 10.7065315246582 0.4928695559501648 C 10.11583614349365 -0.09776583313941956 9.158125877380371 -0.09776552766561508 8.567437171936035 0.4928695559501648 L 1.079220414161682 7.980309963226318 C 0.4884408712387085 8.571026802062988 0.4884408712387085 9.528774261474609 1.079220414161682 10.11950778961182 L 8.567437171936035 17.60687637329102 C 9.158125877380371 18.19756317138672 10.11583614349365 18.19756317138672 10.7065315246582 17.60687637329102 L 10.7065315246582 17.60687637329102 C 11.2972297668457 17.0163402557373 11.2972297668457 16.05868339538574 10.7065315246582 15.46799468994141 L 5.357423305511475 10.11950778961182 Z">
                                </path>
                            </svg>
                        </div>
                    </NavLink>


                    <div id="All_g_brnd_mob">
                        <span>Company Name</span>
                    </div>


                    <div id="Group_7617_brnd_mob">
                        <input id="Rectangle_2155_brnd_mob" type="text" name="brand_alias" placeholder=" Company Name" value={brandingData.brand_alias || ''} onChange={handleChange} required />
                    </div>



                    <div id="Group_7635_brnd_mob">
                        <input id="Rectangle_2155_hb_brnd_mob" type="text" name="tagline" placeholder="Input TagLine" value={brandingData.tagline || ''} onChange={handleChange} required />


                    </div>

                    <div id="Group_7636_brnd_mob">

                        <input id="Rectangle_2155_he_brnd_mob" type="text" name="receipt_message" placeholder="Input reciept message" value={brandingData.receipt_message || ''} onChange={handleChange} required />

                    </div>

                    <div id="Group_7637_brnd_mob">
                        <input id="Rectangle_2155_hh_brnd_mob" type="text" name="website_url" placeholder="Input Website url" value={brandingData.website_url || ''} onChange={handleChange} required />
                    </div>


                    <div id="Group_7638_brnd_mob">
                        <svg className="Rectangle_2155_hk_brnd_mob">
                            <rect id="Rectangle_2155_hk_brnd_mob" rx="5" ry="5" x="0" y="0" width="300" height="40">
                            </rect>
                        </svg>
                        <input id='Rectangle_2165_brnd_mob' type="color" name="brand_colour" value={brandingData.brand_colour} onChange={setColor} required />
                        <div id="Input_Brand_Colour_brnd_mob">
                            <span>{brandingData.brand_colour}</span>
                        </div>

                    </div>


                    <div id="Group_7639_brnd_mob">
                        <input id="Rectangle_2155_hn_brnd_mob" type="text" name="facebook_url" placeholder="Input Facebook url" value={brandingData.facebook_url || ''} onChange={handleChange} required />
                    </div>


                    <div id="Group_7640_brnd_mob">
                        <input id="Rectangle_2155_hq_brnd_mob" type="text" name="instagram_url" placeholder="Input Instagram url" value={brandingData.instagram_url || ''} onChange={handleChange} required />
                    </div>
                    <div id="Group_7641_brnd_mob">
                        <input id="Rectangle_2155_ht_brnd_mob" type="text" name="twitter_url" placeholder="Input twitter url" value={brandingData.twitter_url || ''} onChange={handleChange} required />
                    </div>
                    <a href="ProjectOverview.html">
                        <div id="All_hv_brnd_mob">
                            <span>Tagline</span>
                        </div>
                    </a>
                    <a href="ProjectOverview.html">
                        <div id="All_hw_brnd_mob">
                            <span>Receipt Message</span>
                        </div>
                    </a>
                    <a href="ProjectOverview.html">
                        <div id="All_hx_brnd_mob">
                            <span>Website Url</span>
                        </div>
                    </a>
                    <a href="ProjectOverview.html">
                        <div id="All_hy_brnd_mob">
                            <span>Brand Colour</span>
                        </div>
                    </a>
                    <a href="ProjectOverview.html">
                        <div id="All_hz_brnd_mob">
                            <span>Facebook Url</span>
                        </div>
                    </a>
                    <div id="Group_7642_brnd_mob">
                        <div id="Group_3969_brnd_mob">
                            <svg className="Rectangle_2057_brnd_mob">
                                <rect id="Rectangle_2057_brnd_mob" rx="5" ry="5" x="0" y="0" width="199" height="30">
                                </rect>
                            </svg>
                        </div>
                        <div id="Group_4628_brnd_mob">
                            <a href="SignIn.html_brnd_mob">
                                <div id="SAVE_CHANGES_brnd_mob" onClick={submit}>
                                    <span>SAVE CHANGES</span>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div id="Group_7643_brnd_mob">
                        <div id="Group_3969_h_brnd_mob">
                            <svg className="Rectangle_2057_h_brnd_mob">
                                <rect id="Rectangle_2057_h_brnd_mob" rx="5" ry="5" x="0" y="0" width="75" height="30">
                                </rect>
                            </svg>
                        </div>
                        <div id="Group_4628_h_brnd_mob">

                            <div id="CANCEL_brnd_mob_brnd_mob">
                                <span>CANCEL</span>
                            </div>

                        </div>
                    </div>
                </div>
            </MediaQuery>


        </>






    )


};
export default SettingsBrandingMobile;
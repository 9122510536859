export async function VerifyEmail(verificationToken:any){

    const response = await fetch(process.env.REACT_APP_CHPTER_API_URL+"/merchants/account/email-verify/"+verificationToken, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        method: "PUT",
      });

    return await response.json();
}
import React, {Dispatch, useEffect, useState} from 'react';
import ShopifyNavBar from "./ShopifyNavBar";
import ShopifyUserRibbon from "./ShopifyUserRibbon";

interface NavigationProps {
    navigation: string;
  }


const WrapperShopify = (navigation: any) => {
    return (
        <>
            <ShopifyNavBar navigation={navigation}></ShopifyNavBar>
            <ShopifyUserRibbon></ShopifyUserRibbon>
        </>
    );
}

export default WrapperShopify;
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import '../../styles/Developers/apikeys.css'
import '../../styles/Components/NavBar.css'
import '../../styles/Components/UserRibbon.css'
import '../../styles/Components/HotLinks_Developers.css'
import Wrapper from "../../components/Wrapper";
import HotlinksDevelopers from '../../components/HotLinks_Developers';


const APIkeys = () => {
    return (
        <>
            <main className="form-signin">
                <div id="DevelopersAPIkeys_devapi">
                    <div id="Group_6861_devapi">
                        <div id="API_Keys_devapi">
                            <span>API Keys</span>
                        </div>
                        <div id="Group_6853_devapi">
                            <div id="Learn_about_API_authentication_devapi">
                                <span>Learn about API authentication</span>
                            </div>
                            <div id="Group_5329_devapi">
                                <div id="Group_5321_devapi">
                                    <div id="Layer_2_devapi">
                                        <div id="arrow-forward_devapi">
                                            <svg className="Rectangle_2234_devapi">
                                                <rect id="Rectangle_2234_devapi" rx="0" ry="0" x="0" y="0" width="20" height="20">
                                                </rect>
                                            </svg>
                                            <svg className="Path_9157_devapi" viewBox="4 5 13.187 11.539">
                                                <path id="Path_9157_devapi" d="M 4.82415771484375 11.59326171875 L 14.59866905212402 11.59326171875 L 11.60697650909424 15.18659019470215 C 11.3156681060791 15.53706836700439 11.3636360168457 16.05734062194824 11.71411609649658 16.34865188598633 C 12.06459712982178 16.63995933532715 12.58486938476562 16.59199142456055 12.87617874145508 16.24151229858398 L 16.99696731567383 11.29656600952148 C 17.02469253540039 11.25723457336426 17.04948425292969 11.21591472625732 17.07114219665527 11.17294311523438 C 17.07114219665527 11.13173484802246 17.11234855651855 11.10700988769531 17.12883186340332 11.0658016204834 C 17.16618919372559 10.97130489349365 17.18574714660645 10.87071323394775 17.1865234375 10.76910400390625 C 17.18574714660645 10.66749477386475 17.16618919372559 10.56690406799316 17.12883186340332 10.47240734100342 C 17.12883186340332 10.4311990737915 17.08762550354004 10.40647506713867 17.07114219665527 10.36526679992676 C 17.04948425292969 10.32229518890381 17.02469253540039 10.28097534179688 16.99696731567383 10.24164390563965 L 12.87617874145508 5.296696662902832 C 12.71929550170898 5.108343601226807 12.48670864105225 4.999601364135742 12.2415771484375 4.999999523162842 C 12.04901123046875 4.999622821807861 11.86239147186279 5.066689014434814 11.71411609649658 5.189555644989014 C 11.54557037353516 5.32928991317749 11.43954944610596 5.530345439910889 11.41944885253906 5.748357772827148 C 11.39934921264648 5.966370582580566 11.46682071685791 6.183421611785889 11.60697555541992 6.35161828994751 L 14.59866905212402 9.9449462890625 L 4.82415771484375 9.9449462890625 C 4.368988037109375 9.9449462890625 4 10.31393432617188 4 10.76910400390625 C 4 11.22427368164062 4.368988037109375 11.59326171875 4.82415771484375 11.59326171875 Z">
                                                </path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="API_Keys_are_used_for_client_a_devapi">
                            <span>API Keys are used for client and server-side integrations and do not expire.</span>
                        </div>
                        <div id="Group_6859_devapi">
                            <svg className="Rectangle_2449_devapi">
                                <rect id="Rectangle_2449_devapi" rx="10" ry="10" x="0" y="0" width="1346" height="169">
                                </rect>
                            </svg>
                            <div id="Group_6855_devapi">
                                <div id="Name_devapi">
                                    <span>Name</span>
                                </div>
                                <div id="Token_devapi">
                                    <span>Token</span>
                                </div>
                                <div id="Last_Used_devapi">
                                    <span>Last Used</span>
                                </div>
                            </div>
                            <div id="Group_6858_devapi">
                                <div id="All_devapi">
                                    <span>Drip city api key</span>
                                </div>
                                <div id="All_fc_devapi">
                                    <span>chpter_pk_539e790785ec31dbae14a22116ebe231448976aada218a3e5<br />bc1de4aa9fab3fcf06bcde0ce01e397</span>
                                </div>
                                <div id="n_4_July_164500_PM_devapi">
                                    <span>24 July, 16:45:00 PM</span>
                                </div>
                                <div id="Group_6865_devapi">
                                    <svg className="Rectangle_2456_devapi">
                                        <rect id="Rectangle_2456_devapi" rx="7" ry="7" x="0" y="0" width="32" height="32">
                                        </rect>
                                    </svg>
                                    <div id="Layer_2_fg_devapi">
                                        <div id="eye_devapi">
                                            <svg className="Rectangle_2454_devapi">
                                                <rect id="Rectangle_2454_devapi" rx="0" ry="0" x="0" y="0" width="19" height="18">
                                                </rect>
                                            </svg>
                                            <svg className="Path_9275_devapi" viewBox="1.996 4.996 15.655 10.957">
                                                <path id="Path_9275_devapi" d="M 17.54585838317871 10.08481407165527 C 17.04510879516602 9.216325759887695 14.29098415374756 4.858235836029053 9.612100601196289 4.999071598052979 C 5.28530740737915 5.1086106300354 2.781558036804199 8.91118049621582 2.100850343704224 10.08481407165527 C 1.961084008216858 10.32689476013184 1.961083889007568 10.62515258789062 2.100850343704224 10.86723518371582 C 2.593776226043701 11.7200756072998 5.230537414550781 15.95297813415527 9.839003562927246 15.95297813415527 L 10.03460884094238 15.95297813415527 C 14.36140251159668 15.84343910217285 16.87297630310059 12.04086875915527 17.54585838317871 10.86723518371582 C 17.68562507629395 10.62515258789062 17.68562507629395 10.32689666748047 17.54585838317871 10.08481407165527 Z M 9.995487213134766 14.38813400268555 C 6.62324857711792 14.46637725830078 4.424643516540527 11.57923889160156 3.736112117767334 10.47602462768555 C 4.518533706665039 9.216325759887695 6.560654640197754 6.642157554626465 9.690342903137207 6.563915252685547 C 13.04693222045898 6.477849006652832 15.25336265563965 9.372810363769531 15.94971752166748 10.47602462768555 C 15.14382266998291 11.7357234954834 13.1251745223999 14.30989265441895 9.995487213134766 14.38813400268555 Z">
                                                </path>
                                            </svg>
                                            <svg className="Path_9276_devapi" viewBox="8.5 8.5 5.477 5.477">
                                                <path id="Path_9276_devapi" d="M 11.23847675323486 8.5 C 9.726058959960938 8.500000953674316 8.500000953674316 9.726058959960938 8.500000953674316 11.23847770690918 C 8.500000953674316 12.75089645385742 9.726058959960938 13.97695350646973 11.23847770690918 13.97695350646973 C 12.75089645385742 13.97695350646973 13.97695350646973 12.75089645385742 13.97695350646973 11.23847770690918 C 13.97695350646973 9.726057052612305 12.75089550018311 8.499999046325684 11.23847579956055 8.5 Z M 11.23847675323486 12.412109375 C 10.59029674530029 12.412109375 10.06484413146973 11.88665580749512 10.06484413146973 11.23847675323486 C 10.06484413146973 10.59029674530029 10.59029674530029 10.06484413146973 11.23847675323486 10.06484413146973 C 11.88665580749512 10.06484413146973 12.412109375 10.59029674530029 12.412109375 11.23847675323486 C 12.412109375 11.88665580749512 11.88665580749512 12.412109375 11.23847675323486 12.412109375 Z">
                                                </path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div id="Group_6866_devapi">
                                    <div id="Group_6864_devapi">
                                        <div id="Layer_2_fn_devapi">
                                            <div id="copy_devapi">
                                                <svg className="Rectangle_2455_devapi">
                                                    <rect id="Rectangle_2455_devapi" rx="0" ry="0" x="0" y="0" width="19" height="18">
                                                    </rect>
                                                </svg>
                                                <svg className="Path_9277_devapi" viewBox="9 9 9.389 9.389">
                                                    <path id="Path_9277_devapi" d="M 16.04179763793945 18.38906288146973 L 11.34726619720459 18.38906288146973 C 10.05090713500977 18.38906288146973 9 17.33815574645996 9 16.04179763793945 L 9 11.34726619720459 C 9 10.05090618133545 10.05090713500977 9 11.34726715087891 9 L 16.04179763793945 9 C 17.33815574645996 9 18.38906288146973 10.05090618133545 18.38906288146973 11.34726619720459 L 18.38906288146973 16.04179763793945 C 18.38906288146973 17.33815574645996 17.33815574645996 18.38906288146973 16.04179763793945 18.38906288146973 Z M 11.34726619720459 10.56484413146973 C 10.91514587402344 10.56484413146973 10.56484413146973 10.91514682769775 10.56484413146973 11.34726619720459 L 10.56484413146973 16.04179763793945 C 10.56484413146973 16.47391700744629 10.91514587402344 16.82421875 11.34726619720459 16.82421875 L 16.04179763793945 16.82421875 C 16.47391700744629 16.82421875 16.82421875 16.47391700744629 16.82421875 16.04179763793945 L 16.82421875 11.34726619720459 C 16.82421875 10.91514587402344 16.47391700744629 10.56484413146973 16.04179763793945 10.56484413146973 L 11.34726619720459 10.56484413146973 Z">
                                                    </path>
                                                </svg>
                                                <svg className="Path_9278_devapi" viewBox="3 3 9.389 9.389">
                                                    <path id="Path_9278_devapi" d="M 8.26569938659668 12.38906288146973 L 5.089066505432129 12.38906288146973 C 3.937088489532471 12.38477420806885 3.004289627075195 11.45197582244873 2.999999761581421 10.2999963760376 L 3 5.089066505432129 C 3.004290342330933 3.937088489532471 3.937089204788208 3.004289865493774 5.089066982269287 2.999999761581421 L 10.2999963760376 3 C 11.45197582244873 3.004289865493774 12.38477420806885 3.937088489532471 12.38906383514404 5.089066982269287 L 12.38906288146973 8.007499694824219 L 10.82421970367432 8.007499694824219 L 10.82421970367432 5.089066505432129 C 10.82421970367432 4.799546241760254 10.58951663970947 4.564843654632568 10.2999963760376 4.564843654632568 L 5.089066505432129 4.564843654632568 C 4.799546241760254 4.564843654632568 4.564843654632568 4.799546241760254 4.564843654632568 5.089066505432129 L 4.564843654632568 10.2999963760376 C 4.564843654632568 10.58951663970947 4.799546241760254 10.82421970367432 5.089066505432129 10.82421970367432 L 8.26569938659668 10.82421970367432 L 8.26569938659668 12.38906288146973 Z">
                                                    </path>
                                                </svg>
                                            </div>
                                        </div>
                                        <svg className="Rectangle_2457_devapi">
                                            <rect id="Rectangle_2457_devapi" rx="7" ry="7" x="0" y="0" width="32" height="32">
                                            </rect>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="Group_6863_devapi">
                        <div id="Domain_devapi">
                            <span>Domain</span>
                        </div>
                        <div id="Domain_is_used_for_client_and__devapi">
                            <span>Domain is used for client and server-side integrations and do not expire.</span>
                        </div>
                        <div id="Group_6860_devapi">
                            <svg className="Rectangle_2449_fx_devapi">
                                <rect id="Rectangle_2449_fx_devapi" rx="10" ry="10" x="0" y="0" width="1346" height="135">
                                </rect>
                            </svg>
                            <div id="Group_6855_fy_devapi">
                                <div id="Name_fz_devapi">
                                    <span>Name</span>
                                </div>
                                <div id="Domain_f_devapi">
                                    <span>Domain</span>
                                </div>
                                <div id="Last_Used_f_devapi">
                                    <span>Last Used</span>
                                </div>
                            </div>
                            <div id="Group_6858_f_devapi">
                                <div id="All_f_devapi">
                                    <span>Drip city domain</span>
                                </div>
                                <div id="All_ga_devapi">
                                    <span>https://www.dripcity.co.ke</span>
                                </div>
                                <div id="n_4_July_164500_PM_f_devapi">
                                    <span>24 July, 16:45:00 PM</span>
                                </div>
                                <div id="Group_6856_devapi">
                                    <svg className="Rectangle_2456_f_devapi">
                                        <rect id="Rectangle_2456_f_devapi" rx="7" ry="7" x="0" y="0" width="32" height="32">
                                        </rect>
                                    </svg>
                                    <div id="Layer_2_f_devapi">
                                        <div id="eye_f_devapi">
                                            <svg className="Rectangle_2454_ga_devapi">
                                                <rect id="Rectangle_2454_ga_devapi" rx="0" ry="0" x="0" y="0" width="19" height="18">
                                                </rect>
                                            </svg>
                                            <svg className="Path_9275_gb_devapi" viewBox="1.996 4.996 15.655 10.957">
                                                <path id="Path_9275_gb_devapi" d="M 17.54585838317871 10.08481407165527 C 17.04510879516602 9.216325759887695 14.29098415374756 4.858235836029053 9.612100601196289 4.999071598052979 C 5.28530740737915 5.1086106300354 2.781558036804199 8.91118049621582 2.100850343704224 10.08481407165527 C 1.961084008216858 10.32689476013184 1.961083889007568 10.62515258789062 2.100850343704224 10.86723518371582 C 2.593776226043701 11.7200756072998 5.230537414550781 15.95297813415527 9.839003562927246 15.95297813415527 L 10.03460884094238 15.95297813415527 C 14.36140251159668 15.84343910217285 16.87297630310059 12.04086875915527 17.54585838317871 10.86723518371582 C 17.68562507629395 10.62515258789062 17.68562507629395 10.32689666748047 17.54585838317871 10.08481407165527 Z M 9.995487213134766 14.38813400268555 C 6.62324857711792 14.46637725830078 4.424643516540527 11.57923889160156 3.736112117767334 10.47602462768555 C 4.518533706665039 9.216325759887695 6.560654640197754 6.642157554626465 9.690342903137207 6.563915252685547 C 13.04693222045898 6.477849006652832 15.25336265563965 9.372810363769531 15.94971752166748 10.47602462768555 C 15.14382266998291 11.7357234954834 13.1251745223999 14.30989265441895 9.995487213134766 14.38813400268555 Z">
                                                </path>
                                            </svg>
                                            <svg className="Path_9276_gc_devapi" viewBox="8.5 8.5 5.477 5.477">
                                                <path id="Path_9276_gc_devapi" d="M 11.23847675323486 8.5 C 9.726058959960938 8.500000953674316 8.500000953674316 9.726058959960938 8.500000953674316 11.23847770690918 C 8.500000953674316 12.75089645385742 9.726058959960938 13.97695350646973 11.23847770690918 13.97695350646973 C 12.75089645385742 13.97695350646973 13.97695350646973 12.75089645385742 13.97695350646973 11.23847770690918 C 13.97695350646973 9.726057052612305 12.75089550018311 8.499999046325684 11.23847579956055 8.5 Z M 11.23847675323486 12.412109375 C 10.59029674530029 12.412109375 10.06484413146973 11.88665580749512 10.06484413146973 11.23847675323486 C 10.06484413146973 10.59029674530029 10.59029674530029 10.06484413146973 11.23847675323486 10.06484413146973 C 11.88665580749512 10.06484413146973 12.412109375 10.59029674530029 12.412109375 11.23847675323486 C 12.412109375 11.88665580749512 11.88665580749512 12.412109375 11.23847675323486 12.412109375 Z">
                                                </path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div id="Group_6857_devapi">
                                    <div id="Group_6864_ge_devapi">
                                        <div id="Layer_2_gf_devapi">
                                            <div id="copy_gg_devapi">
                                                <svg className="Rectangle_2455_gh_devapi">
                                                    <rect id="Rectangle_2455_gh_devapi" rx="0" ry="0" x="0" y="0" width="19" height="18">
                                                    </rect>
                                                </svg>
                                                <svg className="Path_9277_gi_devapi" viewBox="9 9 9.389 9.389">
                                                    <path id="Path_9277_gi_devapi" d="M 16.04179763793945 18.38906288146973 L 11.34726619720459 18.38906288146973 C 10.05090713500977 18.38906288146973 9 17.33815574645996 9 16.04179763793945 L 9 11.34726619720459 C 9 10.05090618133545 10.05090713500977 9 11.34726715087891 9 L 16.04179763793945 9 C 17.33815574645996 9 18.38906288146973 10.05090618133545 18.38906288146973 11.34726619720459 L 18.38906288146973 16.04179763793945 C 18.38906288146973 17.33815574645996 17.33815574645996 18.38906288146973 16.04179763793945 18.38906288146973 Z M 11.34726619720459 10.56484413146973 C 10.91514587402344 10.56484413146973 10.56484413146973 10.91514682769775 10.56484413146973 11.34726619720459 L 10.56484413146973 16.04179763793945 C 10.56484413146973 16.47391700744629 10.91514587402344 16.82421875 11.34726619720459 16.82421875 L 16.04179763793945 16.82421875 C 16.47391700744629 16.82421875 16.82421875 16.47391700744629 16.82421875 16.04179763793945 L 16.82421875 11.34726619720459 C 16.82421875 10.91514587402344 16.47391700744629 10.56484413146973 16.04179763793945 10.56484413146973 L 11.34726619720459 10.56484413146973 Z">
                                                    </path>
                                                </svg>
                                                <svg className="Path_9278_gj_devapi" viewBox="3 3 9.389 9.389">
                                                    <path id="Path_9278_gj_devapi" d="M 8.26569938659668 12.38906288146973 L 5.089066505432129 12.38906288146973 C 3.937088489532471 12.38477420806885 3.004289627075195 11.45197582244873 2.999999761581421 10.2999963760376 L 3 5.089066505432129 C 3.004290342330933 3.937088489532471 3.937089204788208 3.004289865493774 5.089066982269287 2.999999761581421 L 10.2999963760376 3 C 11.45197582244873 3.004289865493774 12.38477420806885 3.937088489532471 12.38906383514404 5.089066982269287 L 12.38906288146973 8.007499694824219 L 10.82421970367432 8.007499694824219 L 10.82421970367432 5.089066505432129 C 10.82421970367432 4.799546241760254 10.58951663970947 4.564843654632568 10.2999963760376 4.564843654632568 L 5.089066505432129 4.564843654632568 C 4.799546241760254 4.564843654632568 4.564843654632568 4.799546241760254 4.564843654632568 5.089066505432129 L 4.564843654632568 10.2999963760376 C 4.564843654632568 10.58951663970947 4.799546241760254 10.82421970367432 5.089066505432129 10.82421970367432 L 8.26569938659668 10.82421970367432 L 8.26569938659668 12.38906288146973 Z">
                                                    </path>
                                                </svg>
                                            </div>
                                        </div>
                                        <svg className="Rectangle_2457_gk_devapi">
                                            <rect id="Rectangle_2457_gk_devapi" rx="7" ry="7" x="0" y="0" width="32" height="32">
                                            </rect>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <HotlinksDevelopers></HotlinksDevelopers>
            <Wrapper></Wrapper>
        </>
    )
};

export default APIkeys;

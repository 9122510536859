import axios from 'axios';
import React, { useEffect, useState, SyntheticEvent } from 'react';
import { NavLink } from 'react-router-dom';
import '../../styles/Settings/settingsprofile.css'
import '../../styles/Components/NavBar.css'
import '../../styles/Components/UserRibbon.css'
import '../../styles/Components/HotLinks_Settings.css'
import Wrapper from "../../components/Wrapper";
import HotLinks_Settings from '../../components/HotLinks_Settings';
import { SuccessfulPayments } from "../../models/payments";
import { Withdrawal } from "../../models/wallet";
import validator from 'validator';
import { nationalityList } from "../../utils/nationality-list";
import { get_merchant_profile } from "../../services/Settings/merchantprofileinfo";

import { countrycode_flagList } from "../../utils/nationality-list";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import MediaQuery from 'react-responsive';
import WrapperShopify from '../../components/ShopifyWrapper';
import ShopifyHotLinksSettings from '../../components/ShopifyHotLinksSettings';
import 'react-toastify/dist/ReactToastify.css';
import '../../styles/Components/Toastify.css'

interface ErrorResponse {
    message: string;
    success: boolean;
    status: number;
}

const ShopifyProfileSettings = () => {

    const [formErrors, setFormErrors] = useState<Record<string, string>>({});

    const [redirect, setredirect] = useState(false)
    const [profileData, setProfileData] = useState([] as any);

    const [nationality, setNationality] = useState("Kenyan")
    const [notify_personal_email, setNotifyPersonalEmail] = useState(true)
    const [notify_mobile_number, setNotifyMobileNumber] = useState(true)
    const [notify_business_email, setNotifyBusinessEmail] = useState(true)
    const [notify_office_number, setNotifyOfficeNumber] = useState(true)
    const [country_code, setCountryCode] = useState("KE")
    const isEmailValidator = require('validator').isEmail
    const [formValues, setFormValues] = useState({} as any);
    const [isAccountSetUpComplete, setIsAccountSetUpComplete] = useState(false)

    //Get Merchant Profile Info 
    useEffect(() => {
        async function fetchMerchantProfile() {
            var merchant = await get_merchant_profile();
            setProfileData(merchant);
            console.log('merchant', merchant)

            const initialValues = {
                first_name: merchant.first_name,
                last_name: merchant.last_name,
                personal_email: merchant.personal_email,
                nationality: merchant.nationality,
                mobile_number: merchant.mobile_number,
                company_name: merchant.company_name,
                industry: merchant.industry,
                business_email: merchant.business_email,
                office_number: merchant.office_number,
                address: merchant.address,
                pricing_model: merchant.pricing_model || 'Business',
                notify_personal_email: merchant.notify_personal_email,
                notify_mobile_number: merchant.notify_mobile_number,
                notify_business_email: merchant.notify_business_email,
                notify_office_number: merchant.notify_office_number,
                is_account_setup_complete: true
            };

            setFormValues(initialValues);
        }

        fetchMerchantProfile();

    }, []);


    const checkFormValidity = (formValues: {
        first_name: any; last_name: any; personal_email: any; nationality: any; mobile_number: any; company_name: any; industry: any;
        business_email: any; office_number: any; address: any; pricing_model: any; notify_personal_email: any;
        notify_mobile_number: any; notify_business_email: any; notify_office_number: any;
    }) => {
        const errors: any = {};

        if (!formValues.first_name) {
            toast("First name is required", { type: "error" });
            errors.first_name = "First name is required";
        }
        else if (!formValues.last_name) {
            toast("Last name is required", { type: "error" });
            errors.last_name = 'Last Name is required';
        }
        else if (!formValues.personal_email) {
            toast("Personal Email is required", { type: "error" });
            errors.personal_email = 'Personal Email is required';
        }
        else if (!validator.isEmail(formValues.personal_email)) {
            toast("Personal Email is not valid", { type: "error" });
            errors.personal_email = 'Personal Email is not valid';
        }
        else if (!formValues.nationality) {
            toast("Nationality is required", { type: "error" });
            errors.nationality = 'Nationality  is required';
        }
        else if (!formValues.mobile_number) {
            toast("Phone number is required", { type: "error" });
            errors.mobile_number = 'Phone number is required';
        }
        // else if (!/^(\+254|0)[1-9]\d{8}$/.test(formValues.mobile_number)) {
        //     toast("Invalid Phone Number", { type: "error" });
        //     errors.mobile_number = "Invalid Phone Number";
        // }
        else if (!formValues.company_name) {
            toast("Company Name is required", { type: "error" });
            errors.company_name = 'Company Name is required';

        }
        else if (!formValues.business_email) {
            toast("Business Email is required", { type: "error" });
            errors.business_email = 'Business Email is required';
        }
        else if (!validator.isEmail(formValues.business_email)) {
            toast("Business Email  is not valid", { type: "error" });
            errors.business_email = 'Business Email  is not valid';
        }
        else if (!formValues.office_number) {
            toast("Office Number is required", { type: "error" });
            errors.office_number = 'Office Number is required';
        }
        // else if (!/^(\+254|0)[1-9]\d{8}$/.test(formValues.office_number)) {
        //     toast("Invalid Office Number", { type: "error" });
        //     errors.office_number = "Invalid Office Number";
        // }
        else if (!formValues.pricing_model) {
            toast("Pricing model is required", { type: "error" });
            errors.pricing_model = 'Pricing model is required';
        }


        setFormErrors(errors);
        if (Object.keys(errors).length > 0) {
            let errorMessage = "";
            for (const key in formErrors) {
                if (formErrors[key]) {
                    errorMessage += formErrors[key] + "\n";
                }
            }

        }
        return Object.keys(errors).length === 0;
    };


    // Handles Input changes
    function handleChange(event: { target: { name: any; value: any; }; }) {
        const { name, value } = event.target;
        setFormValues({ ...formValues, [name]: value });
        setProfileData((prevData: any) => ({
            ...prevData,
            [name]: value
        }));
    };

    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();

        const isValid = checkFormValidity(formValues);
        if (!isValid) {
            return;
        } else {
            console.log("FORMVALUES")
            setIsAccountSetUpComplete(true)
            setFormValues((prevFormValues: any) => ({ ...prevFormValues, is_account_setup_complete: true }));
        }

        let api_url = process.env.REACT_APP_CHPTER_API_URL + '/merchants/profile'
        let headers = {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("JWT"),
        };

        const data = formValues;

        console.log('data', data)

        await axios.post(api_url, data, { headers: headers })
            .then((response) => {
                //Successful request
                console.log('auth_token', response.data.auth_token);
                console.log(response.data.message);
                console.log(response.data.success);
                console.log(response.status);

                setredirect(true)

            })
            .catch((error) => {
                // Error
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    //Response Example
                    // "message": "Email Exists",
                    // "success": false,
                    // "status": 409
                    console.log(error.response.data.message);
                    console.log(error.response.data.success);
                    console.log(error.response.status);
                    // console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the 
                    // browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);

            });
        try {
            const response = await axios.post(api_url, data, { headers: headers });
            console.log(response.data.auth_token);
            console.log(response.data.message);
            console.log(response.data.success);
            console.log(response.status);
            setredirect(true);
        }




        catch (error: any) {
            // Cast error as 'any' to avoid type checking
            if (error.response) {
                const errorResponse: ErrorResponse = error.response.data;
                console.log(errorResponse.message);
                console.log(errorResponse.success);
                console.log(errorResponse.status);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
            console.log(error.config);
        }

        toast.success('Changes saved successfully!', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
        });

    }



    return (
        <>
            <MediaQuery minWidth={1001}>
                <main className="form-signin">
                    <form onSubmit={submit}>
                        <div id="Group_6658_setprof">
                            <div id="Group_4741_setprof" onClick={submit}>
                                <div id="Group_3969_setprof">
                                    <svg className="Rectangle_2057_setprof">
                                        <rect id="Rectangle_2057_setprof" rx="5" ry="5" x="0" y="0" width="157.305" height="30">
                                        </rect>
                                    </svg>
                                </div>
                                <div id="Group_4628_setprof">
                                    <div id="SAVE_CHANGES_setprof">
                                        <span>SAVE CHANGES</span>
                                    </div>
                                </div>
                            </div>
                            <div id="Group_4742_setprof">
                                <div id="Group_3969_fh_setprof">
                                    <svg className="Rectangle_2057_fi_setprof">
                                        <rect id="Rectangle_2057_fi_setprof" rx="5" ry="5" x="0" y="0" width="75" height="30">
                                        </rect>
                                    </svg>
                                </div>
                                <div id="Group_4628_fj_setprof">
                                    <div id="CANCEL_setprof">
                                        <span>CANCEL</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="Group_7065_setprof">
                            <div id="All_setprof">
                                <span>Account Information</span>
                            </div>
                            <div id="Group_7042_setprof">
                                <input id="Rectangle_2002_setprof" type="text" name="first_name" placeholder="Input First Name" value={profileData.first_name || ''} onChange={handleChange} required />
                                <div id="First_Name__setprof">
                                    <span>First Name </span>
                                </div>
                            </div>
                            <div id="Group_7041_setprof">
                                <input id="Rectangle_2002_fs_setprof" type="text" name="last_name" placeholder="Input Last Name" value={profileData.last_name || ''} onChange={handleChange} required />
                                <div id="Last_Name__setprof">
                                    <span>Last Name </span>
                                </div>
                            </div>
                            <div id="Group_7043_setprof">
                                <input id="Rectangle_2002_fw_setprof" type="text" name="personal_email" placeholder="Input Personal Email" value={profileData.personal_email || ''} onChange={handleChange} required />
                                <div id="Email__setprof">
                                    <span>Email </span>
                                </div>
                            </div>

                            <div id="Group_7044_setprof">
                                <select id="Rectangle_2002_f_setprof" name="nationality" value={profileData.nationality || ''} onChange={handleChange} required>
                                    <option disabled selected value="">Select Nationality</option>
                                    {nationalityList.map(nationality => (
                                        <option key={nationality} value={nationality} selected={nationality === 'Kenyan'}>{nationality}</option>
                                    ))}
                                </select>
                                <div id="Company_setprof">
                                    <span>Nationality</span>
                                </div>
                            </div>
                            <div id="Group_7059_setprof">
                                <div id="Phone_Number_setprof">
                                    <span>Phone Number</span>
                                </div>
                                {/* <svg className="Rectangle_2002_ga_setprof">
                                    <rect id="Rectangle_2002_ga_setprof" rx="5" ry="5" x="0" y="0" width="468" height="44">
                                    </rect>
                                </svg> */}
                                <input id="Rectangle_2002_ga_setprof_" type="number" name="mobile_number" placeholder="700001234" value={profileData.mobile_number || ''} onChange={handleChange} required />
                                <svg className="Line_450_setprof" viewBox="0 0 0.5 44">
                                    <path id="Line_450_setprof" d="M 0 0 L 0 44">
                                    </path>
                                </svg>

                                {/* <select id="n_54_setprof" onChange={e => setCountryCode(e.target.value)} required>
                        {countryListISO.map(country_code => (

                            <option value={country_code} selected = {country_code === 'KE'}>{country_code}</option>
                        ))}
                    </select> */}

                                <select id="n_54_setprof" onChange={e => setCountryCode(e.target.value)} required>

                                    {countrycode_flagList.map((country) => (
                                        <option key={country.code} value={country.code} selected={country.code === 'KE'}>{country.flag} &nbsp;{country.code}</option>
                                    ))}
                                </select>


                                {/* <img id="icons8-kenya-96_setprof" src="media/icons8-kenya-96.png"/> */}

                            </div>
                            <div id="Group_7054_setprof">
                                <div id="Enable_notifications_setprof">
                                    <span>Enable Notifications</span>
                                </div>
                                <input id="Rectangle_2461_setprof" type="checkbox" checked={notify_personal_email} onChange={() => setNotifyPersonalEmail(!notify_personal_email)} />
                            </div>
                            <div id="Group_7058_setprof">
                                <div id="Enable_notifications_gf_setprof">
                                    <span>Enable Notifications</span>
                                </div>
                                <input id="Rectangle_2461_gg_setprof" type="checkbox" checked={notify_mobile_number} onChange={() => setNotifyMobileNumber(!notify_mobile_number)} />
                            </div>
                            {/* Change Password */}
                            <NavLink to="/password-change">
                                <div id="Enable_notifications_gf_1_setprof">
                                    <span>Password</span>
                                </div>
                                <div id="Group_7882_ii_setprof">
                                    <svg className="Rectangle_2057_ij_setprof">
                                        <rect id="Rectangle_2057_ij_setprof" rx="5" ry="5" x="0" y="0" width="150" height="31">
                                        </rect>
                                    </svg>
                                    <div id="Set_up_ik_setprof">
                                        <span>Change Password</span>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div id="Group_7064_setprof">
                            <div id="All_gi_setprof">
                                <span>Business Information</span>
                            </div>
                            <div id="Group_7049_setprof">
                                <input id="Rectangle_2002_gk_setprof" type="text" name="company_name" placeholder="Input Company Name" value={profileData.company_name || ''} onChange={handleChange} required />
                                <div id="Company_Name_setprof">
                                    <span>Company Name</span>
                                </div>
                            </div>
                            <div id="Group_7050_setprof">
                                <select id="Rectangle_2002_go_setprof" name="industry" placeholder="Input Industry" value={profileData.industry || ''} onChange={handleChange} required >
                                    <option disabled selected value="">Select Industry</option>
                                    <option value="Alcoholic Beverage Retail">Alcoholic Beverage Retail</option>
                                    <option value="Bar & Restaurant">Bar & Restaurant</option>
                                    <option value="Car Dealership">Car Dealership</option>
                                    <option value="Clothes & Fashion">Clothes & Fashion</option>
                                    <option value="Cosmetics">Cosmetics</option>
                                    <option value="Event">Event</option>
                                    <option value="Gym">Gym</option>
                                    <option value="Merchandise">Merchandise</option>
                                    <option value="Shipping">Shipping</option>
                                    <option value="Phamarcy">Phamarcy</option>
                                    <option value="Real Estate">Real Estate</option>
                                    <option value="Other">Other</option>
                                </select>
                                <div id="Industry_setprof">
                                    <span>Industry</span>
                                </div>
                            </div>
                            <div id="Group_7051_setprof">
                                <div id="Group_7048_setprof">
                                    <input id="Rectangle_2002_gt_setprof" type="text" name="business_email" placeholder="Input Business Email" value={profileData.business_email || ''} onChange={handleChange} required />
                                    <div id="Business_Email__setprof">
                                        <span>Business Email </span>
                                    </div>
                                </div>
                            </div>
                            <div id="Group_7053_setprof">
                                <input id="Rectangle_2002_gt_setprof" type="text" name="address" placeholder="Input Business Address" value={profileData.address || ''} onChange={handleChange} required />
                                <div id="Location_setprof">
                                    <span>Business Address</span>
                                </div>
                            </div>
                            <div id="Group_7056_setprof">
                                <div id="Pricing_setprof">
                                    <span>Pricing Model</span>
                                </div>
                                <div id="Group_7150_setprof">
                                    <select id="Rectangle_2002_fw_addbnk" name="pricing_model" placeholder="Input Pricing Model" value={profileData.pricing_model || 'Business'} onChange={handleChange} required>
                                        <option value="Business">Business</option>
                                        <option value="Customer">Customer </option>
                                        <option value="Shared"> Shared</option>
                                    </select>
                                </div>
                            </div>

                            <div id="Group_7060_setprof">
                                <div id="Enable_notifications_g_setprof">
                                    <span>Enable Notifications</span>
                                </div>
                                <input id="Rectangle_2461_g_setprof" type="checkbox" checked={notify_business_email} onChange={() => setNotifyBusinessEmail(!notify_business_email)} />
                            </div>
                            <div id="Group_7061_setprof">
                                <div id="Enable_notifications_ha_setprof">
                                    <span>Enable Notifications</span>
                                </div>
                                <input id="Rectangle_2461_ha_setprof" type="checkbox" checked={notify_office_number} onChange={() => setNotifyOfficeNumber(!notify_business_email)} />
                            </div>
                            <div id="Group_7063_setprof">
                                <div id="Phone_Number_g_setprof">
                                    <span>Office Number</span>
                                </div>
                                <div id="Group_7062_setprof">
                                    {/* <svg className="Rectangle_2002_g_setprof">
                                        <rect id="Rectangle_2002_g_setprof" rx="5" ry="5" x="0" y="0" width="468" height="44">
                                        </rect>
                                    </svg> */}
                                    <input id="Rectangle_2002_g_setprof_" type="number" name="office_number" placeholder="700001234" value={profileData.office_number || ''} onChange={handleChange} required />
                                    <svg className="Line_450_hb_setprof" viewBox="0 0 0.5 44">
                                        <path id="Line_450_hb_setprof" d="M 0 0 L 0 44">
                                        </path>
                                    </svg>

                                    {/* <select id="n_54_hc_setprof" onChange={e => setNationality(e.target.value)} required>
                                    {countryListISO.map(nationality => (
                                        <option value={nationality} selected = {nationality === 'Kenyan'}>{nationality}</option>
                                    ))}
                                </select> */}
                                    <select id="n_54_hc_setprof" onChange={e => setCountryCode(e.target.value)} required>
                                        {countrycode_flagList.map((country) => (
                                            <option key={country.code} value={country.code} selected={country.code === 'KE'}>{country.flag} &nbsp;{country.code}</option>
                                        ))}
                                    </select>
                                    {/* <img id="icons8-kenya-96_he_setprof" src="media/icons8-kenya-96.png"/> */}


                                </div>
                            </div>
                        </div>
                    </form>
                    <ToastContainer
                        position="top-center"
                        autoClose={9000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick={false}
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        toastClassName="custom-toast"
                        className="toast-container"
                        style={{
                            top: "10%",
                            color: "white",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                        }}
                    />
                </main>

                <ShopifyHotLinksSettings></ShopifyHotLinksSettings>
                <WrapperShopify navigation="Settings"></WrapperShopify>

                {/* <HotLinks_Settings></HotLinks_Settings>
                <Wrapper navigation='Settings'></Wrapper> */}
            </MediaQuery>

            {/* <MediaQuery maxWidth={1000}>
                <div className="mobilesettingsprofile_pp_mob">
                    <div className="mobilesettingsprofile-inner_pp_mob">
                        <div className="group-wrapper_pp_mob">
                            <div className="group-wrapper_pp_mob">
                                <div className="frame8_pp_mob">
                                    <div className="header_pp_mob">
                                        <div className="frame-parent_pp_mob">

                                            <NavLink to={'/settings-mobile'}>
                                                <div className="group-container_pp_mob">
                                                    <div className="rectangle-parent_pp_mob">
                                                        <div className="group-child_pp_mob" />


                                                        <svg className="path-9390-icon_pp_mob" viewBox="0.636 0.05 10.513 18">
                                                            <path id="Path_9390_pp_mob" d="M 5.357423305511475 10.11950778961182 C 4.766643047332764 9.528789520263672 4.766643047332764 8.571026802062988 5.357423305511475 7.980309963226318 L 10.7065315246582 2.631746768951416 C 11.29722785949707 2.041119337081909 11.2972297668457 1.083506107330322 10.7065315246582 0.4928695559501648 L 10.7065315246582 0.4928695559501648 C 10.11583614349365 -0.09776583313941956 9.158125877380371 -0.09776552766561508 8.567437171936035 0.4928695559501648 L 1.079220414161682 7.980309963226318 C 0.4884408712387085 8.571026802062988 0.4884408712387085 9.528774261474609 1.079220414161682 10.11950778961182 L 8.567437171936035 17.60687637329102 C 9.158125877380371 18.19756317138672 10.11583614349365 18.19756317138672 10.7065315246582 17.60687637329102 L 10.7065315246582 17.60687637329102 C 11.2972297668457 17.0163402557373 11.2972297668457 16.05868339538574 10.7065315246582 15.46799468994141 L 5.357423305511475 10.11950778961182 Z">
                                                            </path>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </NavLink>


                                            <div className="payment-detail_pp_mob">Account Information</div>
                                        </div>
                                    </div>
                                    <div className="body_pp_mob">
                                        <div className="inputfields_pp_mob">
                                            <div className="inputname-parent_pp_mob">
                                                <div className="inputname_pp_mob">First Name</div>
                                                <input
                                                    className="frame-child_pp_mob"
                                                    type="text"
                                                    placeholder="Input First Name"
                                                    name="first_name"
                                                    value={profileData.first_name || ''}
                                                    onChange={handleChange} required
                                                />
                                            </div>
                                            <div className="inputname-parent_pp_mob">
                                                <div className="inputname_pp_mob">Last Name</div>
                                                <input
                                                    className="frame-child_pp_mob"
                                                    type="text"
                                                    name="last_name" 
                                                    placeholder="Input Last Name"
                                                    value={profileData.last_name || ''}
                                                    onChange={handleChange} required
                                                />
                                            </div>
                                            <div className="inputname-parent_pp_mob">
                                                <div className="inputname_pp_mob">Personal Email</div>
                                                <input
                                                    className="frame-child_pp_mob"
                                                    type="text"
                                                    placeholder="Input Personal Email"
                                                    name="personal_email"
                                                    value={profileData.personal_email || ''}
                                                    onChange={handleChange} required
                                                />
                                                <div className="rectangle-group_pp_mob">
                                                    <input
                                                        className="rectangle-input_pp_mob"
                                                        type="checkbox"
                                                        defaultChecked={true}
                                                    />
                                                    <div className="enable-notifications_pp_mob">
                                                        {`Enable `}
                                                        <span className="notifications_pp_mob">notifications</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="inputname-parent_pp_mob">
                                                <div className="inputname_pp_mob">Nationality</div>
                                                <select
                                                    className="frame-child_pp_mob"
                                                    placeholder="Input Nationality"
                                                    name="nationality" 
                                                    value={profileData.nationality || ''}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option disabled value="">Select Nationality</option>
                                                    {nationalityList.map(nationality => (
                                                        <option key={nationality} value={nationality} selected={nationality === 'Kenyan'}>
                                                            {nationality}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="inputname-parent_pp_mob">
                                                <div className="inputname_pp_mob">Phone Number</div>
                                                <input
                                                    className="frame-child_pp_mob"
                                                    type="number"
                                                    name="mobile_number"
                                                    placeholder="Input Phone Number"
                                                    value={profileData.mobile_number || ''}
                                                    onChange={handleChange} required
                                                />
                                                <div className="rectangle-group_pp_mob">
                                                    <input
                                                        className="rectangle-input_pp_mob"
                                                        type="checkbox"
                                                        defaultChecked={true}
                                                    />
                                                    <div className="enable-notifications_pp_mob">
                                                        {`Enable `}
                                                        <span className="notifications_pp_mob">notifications</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="inputname-parent_pp_mob">
                                                <div className="inputname_pp_mob">Company Name</div>
                                                <input
                                                    className="frame-child_pp_mob"
                                                    type="text"
                                                    name='company_name'
                                                    placeholder="Input Company Name"
                                                    value={profileData.company_name || ''}
                                                    onChange={handleChange} required
                                                />
                                            </div>
                                            <div className="inputname-parent_pp_mob">
                                                <div className="inputname_pp_mob">Industry</div>
                                                <select
                                                    className="frame-child_pp_mob"
                                                    placeholder="Input Industry"
                                                    name="industry"
                                                    value={profileData.industry || ''}
                                                    onChange={handleChange} required
                                                >
                                                    <option disabled selected value="">Select Industry</option>
                                                    <option value="Alcoholic Beverage Retail">Alcoholic Beverage Retail</option>
                                                    <option value="Bar & Restaurant">Bar & Restaurant</option>
                                                    <option value="Car Dealership">Car Dealership</option>
                                                    <option value="Clothes & Fashion">Clothes & Fashion</option>
                                                    <option value="Cosmetics">Cosmetics</option>
                                                    <option value="Event">Event</option>
                                                    <option value="Gym">Gym</option>
                                                    <option value="Merchandise">Merchandise</option>
                                                    <option value="Shipping">Shipping</option>
                                                    <option value="Phamarcy">Phamarcy</option>
                                                    <option value="Real Estate">Real Estate</option>
                                                    <option value="Other">Other</option>


                                                </select>
                                            </div>
                                            <div className="inputname-parent_pp_mob">
                                                <div className="inputname_pp_mob">Business Email</div>
                                                <input
                                                    className="frame-child_pp_mob"
                                                    type="text"
                                                    placeholder="Input Business Email"
                                                    name="business_email"
                                                    value={profileData.business_email || ''}
                                                    onChange={handleChange} required
                                                />
                                                <div className="rectangle-group_pp_mob">
                                                    <input
                                                        className="rectangle-input_pp_mob"
                                                        type="checkbox"
                                                        defaultChecked={true}
                                                    />
                                                    <div className="enable-notifications_pp_mob">
                                                        {`Enable `}
                                                        <span className="notifications_pp_mob">notifications</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="inputname-parent_pp_mob">
                                                <div className="inputname_pp_mob">Business Address</div>
                                                <input
                                                    className="frame-child_pp_mob"
                                                    type="text"
                                                    placeholder="Input Business Address"
                                                    name="address" 
                                                    value={profileData.address || ''}
                                                    onChange={handleChange} required
                                                />
                                            </div>
                                            <div className="inputname-parent_pp_mob">
                                                <div className="inputname_pp_mob">Office Number</div>
                                                <input
                                                    className="frame-child_pp_mob"
                                                    type="text"
                                                    placeholder="Input Office Number"
                                                    name="office_number"
                                                    value={profileData.office_number || ''}
                                                    onChange={handleChange} required
                                                />
                                                <div className="rectangle-group_pp_mob">
                                                    <input
                                                        className="rectangle-input_pp_mob"
                                                        type="checkbox"
                                                        defaultChecked={true}
                                                    />
                                                    <div className="enable-notifications_pp_mob">
                                                        {`Enable `}
                                                        <span className="notifications_pp_mob">notifications</span>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="inputname-parent_pp_mob">
                                                <div className="inputname_pp_mob">Pricing Model</div>
                                                <select
                                                    className="frame-child_pp_mob"
                                                    placeholder="Input Pricing Model"
                                                    name="pricing_model"
                                                    value={profileData.pricing_model || 'Business'}
                                                    onChange={handleChange} required
                                                >
                                                    <option value="Business">Business</option>
                                                    <option value="Customer">Customer </option>
                                                    <option value="Shared">Shared</option>

                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="footer_pp_mob">
                                        <NavLink to={'/settings-mobile'} className="rectangle-parent3_pp_mob">
                                            <div className="rectangle-div_pp_mob" />
                                            <div className="cancel_pp_mob">CANCEL</div>
                                        </NavLink>
                                        <div className="rectangle-parent4_pp_mob" onClick={submit}>
                                            <div className="frame-child10_pp_mob" />
                                            <div className="save-changes_pp_mob">{`SAVE `}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick={false}
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    toastClassName="custom-toast"
                    className="toast-container"
                    style={{
                        position: "fixed",
                        color: "white",
                        top: "10%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        height: "10vh",
                        width: "70vw",
                    }}
                />


            </MediaQuery> */}
        </>
    )
};

export default ShopifyProfileSettings;

import axios from 'axios';
import React, { useEffect, useState, SyntheticEvent } from 'react';
import { NavLink } from 'react-router-dom';
import NavBar_Mobile from '../../components/NavBar_Mobile';
import { get_merchant_profile } from '../../services/Settings/merchantprofileinfo';
import '../../styles/Settings/settings_mobile.css'
import NavBarMobile from '../../components/NavBarMobile';


const SettingsProfile_Mobile = () => {
    const [MerchantInfo, setMerchant] = useState([] as any);


    useEffect(() => {
        // Get Merchant Information
        async function fetchMerchantInfo() {
            var merchant = await get_merchant_profile();
            setMerchant(merchant);
        }
        fetchMerchantInfo();


    }, []);


    return (
        <>


            <div className="mobilesettings_set_mob">
                <div className="mobilesettings-inner_set_mob">
                    <div className="group-wrapper_set_mob">
                        <div className="group-wrapper_set_mob">
                            <div className="settings_set_mob">

                                <NavBarMobile></NavBarMobile>
                                {/* <div className="frame-parent_set_mob">
                                        <div className="group-container_set_mob">
                                            <img className="frame-child_set_mob" alt="" src="/group-7465.svg" />
                                        </div>
                                        <div className="payment-detail_set_mob">chpter.</div>
                                    </div> */}

                                <div className="settingsbio_set_mob">
                                    <div className="settingsbio-inner_set_mob">
                                        <div className="chpter-parent_set_mob">
                                            <div className="chpter_set_mob">{MerchantInfo['first_name']} {MerchantInfo['last_name']}</div>
                                            <div className="jessicagmailcom_set_mob">{MerchantInfo['business_email']}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="profile-settings-wrapper_set_mob">
                                    <div className="profile-settings_set_mob">{`Profile & Settings`}</div>
                                </div>
                                <div className="frame-group_set_mob">
                                    <NavLink to={'/settings-profile'} style={{ textDecoration: 'none' }} className="frame-container_set_mob">
                                        <div className="profile-wrapper_set_mob">
                                            <div className="profile_set_mob">Profile</div>
                                        </div>
                                        <div className="profile-wrapper_set_mob">

                                            <svg className="path-9390-icon_set_mob" viewBox="0.636 0.05 6.95 11.9">
                                                <path id="Path_9390_set_mob" d="M 4.464879989624023 6.706850051879883 C 4.855400085449219 6.316329956054688 4.855400085449219 5.683159828186035 4.464879989624023 5.292640209197998 L 0.9289849996566772 1.756739974021912 C 0.5385199785232544 1.36627995967865 0.538519024848938 0.7332080006599426 0.9289849996566772 0.342741996049881 L 0.9289849996566772 0.342741996049881 C 1.3194500207901 -0.0477232001721859 1.95252001285553 -0.04772299900650978 2.342979907989502 0.342741996049881 L 7.292880058288574 5.292640209197998 C 7.68340015411377 5.683159828186035 7.68340015411377 6.316319942474365 7.292880058288574 6.706850051879883 L 2.342979907989502 11.65670013427734 C 1.95252001285553 12.04720020294189 1.3194500207901 12.04720020294189 0.9289849996566772 11.65670013427734 L 0.9289849996566772 11.65670013427734 C 0.538519024848938 11.26630020141602 0.538519024848938 10.63319969177246 0.9289849996566772 10.24269962310791 L 4.464879989624023 6.706850051879883 Z">
                                                </path>
                                            </svg>
                                        </div>
                                    </NavLink>
                                    <NavLink to={'/settings-bankaccounts'} style={{ textDecoration: 'none' }} className="frame-container_set_mob">
                                        <div className="profile-wrapper_set_mob">
                                            <div className="profile_set_mob">Bank Accounts</div>
                                        </div>
                                        <div className="profile-wrapper_set_mob">


                                            <svg className="path-9390-icon_set_mob" viewBox="0.636 0.05 6.95 11.9">
                                                <path id="Path_9390_g_set_mob" d="M 4.464879989624023 6.706850051879883 C 4.855400085449219 6.316329956054688 4.855400085449219 5.683159828186035 4.464879989624023 5.292640209197998 L 0.9289849996566772 1.756739974021912 C 0.5385199785232544 1.36627995967865 0.538519024848938 0.7332080006599426 0.9289849996566772 0.342741996049881 L 0.9289849996566772 0.342741996049881 C 1.3194500207901 -0.0477232001721859 1.95252001285553 -0.04772299900650978 2.342979907989502 0.342741996049881 L 7.292880058288574 5.292640209197998 C 7.68340015411377 5.683159828186035 7.68340015411377 6.316319942474365 7.292880058288574 6.706850051879883 L 2.342979907989502 11.65670013427734 C 1.95252001285553 12.04720020294189 1.3194500207901 12.04720020294189 0.9289849996566772 11.65670013427734 L 0.9289849996566772 11.65670013427734 C 0.538519024848938 11.26630020141602 0.538519024848938 10.63319969177246 0.9289849996566772 10.24269962310791 L 4.464879989624023 6.706850051879883 Z">
                                                </path>
                                            </svg>
                                        </div>


                                    </NavLink>
                                    <NavLink to={'/settings-integrations'} style={{ textDecoration: 'none' }} className="frame-container_set_mob">
                                        <div className="profile-wrapper_set_mob">
                                            <div className="profile_set_mob">Integration</div>
                                        </div>
                                        <div className="profile-wrapper_set_mob">

                                            <svg className="path-9390-icon_set_mob" viewBox="0.636 0.05 6.95 11.9">
                                                <path id="Path_9390_ha_set_mob" d="M 4.464879989624023 6.706850051879883 C 4.855400085449219 6.316329956054688 4.855400085449219 5.683159828186035 4.464879989624023 5.292640209197998 L 0.9289849996566772 1.756739974021912 C 0.5385199785232544 1.36627995967865 0.538519024848938 0.7332080006599426 0.9289849996566772 0.342741996049881 L 0.9289849996566772 0.342741996049881 C 1.3194500207901 -0.0477232001721859 1.95252001285553 -0.04772299900650978 2.342979907989502 0.342741996049881 L 7.292880058288574 5.292640209197998 C 7.68340015411377 5.683159828186035 7.68340015411377 6.316319942474365 7.292880058288574 6.706850051879883 L 2.342979907989502 11.65670013427734 C 1.95252001285553 12.04720020294189 1.3194500207901 12.04720020294189 0.9289849996566772 11.65670013427734 L 0.9289849996566772 11.65670013427734 C 0.538519024848938 11.26630020141602 0.538519024848938 10.63319969177246 0.9289849996566772 10.24269962310791 L 4.464879989624023 6.706850051879883 Z">
                                                </path>
                                            </svg>
                                        </div>


                                    </NavLink>
                                    <NavLink to={'/settings_apikeys'} style={{ textDecoration: 'none' }} className="frame-container_set_mob">
                                        <div className="profile-wrapper_set_mob">
                                            <div className="profile_set_mob">API Keys</div>
                                        </div>
                                        <div className="profile-wrapper_set_mob">
                                            <svg className="path-9390-icon_set_mob" viewBox="0.636 0.05 6.95 11.9">
                                                <path id="Path_9390_ha_set_mob" d="M 4.464879989624023 6.706850051879883 C 4.855400085449219 6.316329956054688 4.855400085449219 5.683159828186035 4.464879989624023 5.292640209197998 L 0.9289849996566772 1.756739974021912 C 0.5385199785232544 1.36627995967865 0.538519024848938 0.7332080006599426 0.9289849996566772 0.342741996049881 L 0.9289849996566772 0.342741996049881 C 1.3194500207901 -0.0477232001721859 1.95252001285553 -0.04772299900650978 2.342979907989502 0.342741996049881 L 7.292880058288574 5.292640209197998 C 7.68340015411377 5.683159828186035 7.68340015411377 6.316319942474365 7.292880058288574 6.706850051879883 L 2.342979907989502 11.65670013427734 C 1.95252001285553 12.04720020294189 1.3194500207901 12.04720020294189 0.9289849996566772 11.65670013427734 L 0.9289849996566772 11.65670013427734 C 0.538519024848938 11.26630020141602 0.538519024848938 10.63319969177246 0.9289849996566772 10.24269962310791 L 4.464879989624023 6.706850051879883 Z">
                                                </path>
                                            </svg>
                                        </div>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div id="MobileSettings_set_mob"
                style={{
                    transformOrigin: "0px 0px",
                    transform: "scale(0.930)",
                    display: "flex",
                    justifyContent: "center", // Horizontally center items
                    alignItems: "center", // Vertically center items
                }}>
                <svg className="Rectangle_2175_set_mob">
                    <rect id="Rectangle_2175_set_mob" rx="7.5" ry="7.5" x="0" y="0" width="353" height="75">
                    </rect>
                </svg>
                <div id="Group_4841_set_mob">
                    <div id="All_set_mob">
                        <span>{MerchantInfo['first_name']}{MerchantInfo['last_name']}</span>
                    </div>
                    <div id="jessicagmailcom_set_mob">
                        <span>{MerchantInfo['business_email']}</span>
                    </div>
                </div>
                <div id="Profile__Settings_set_mob">
                    <span>Profile & Settings</span>
                </div>
                <NavBar_Mobile />
                <NavLink to={'/settings-profile'} className="nav-link">

                    <div id="Group_7461_set_mob">
                        <div id="Profile_set_mob">
                            <span>Profile</span>
                        </div>
                        <div id="Component_103__1_set_mob" className="Component_103___1">
                            <svg className="Path_9390_set_mob" viewBox="0.636 0.05 6.95 11.9">
                                <path id="Path_9390_set_mob" d="M 4.464879989624023 6.706850051879883 C 4.855400085449219 6.316329956054688 4.855400085449219 5.683159828186035 4.464879989624023 5.292640209197998 L 0.9289849996566772 1.756739974021912 C 0.5385199785232544 1.36627995967865 0.538519024848938 0.7332080006599426 0.9289849996566772 0.342741996049881 L 0.9289849996566772 0.342741996049881 C 1.3194500207901 -0.0477232001721859 1.95252001285553 -0.04772299900650978 2.342979907989502 0.342741996049881 L 7.292880058288574 5.292640209197998 C 7.68340015411377 5.683159828186035 7.68340015411377 6.316319942474365 7.292880058288574 6.706850051879883 L 2.342979907989502 11.65670013427734 C 1.95252001285553 12.04720020294189 1.3194500207901 12.04720020294189 0.9289849996566772 11.65670013427734 L 0.9289849996566772 11.65670013427734 C 0.538519024848938 11.26630020141602 0.538519024848938 10.63319969177246 0.9289849996566772 10.24269962310791 L 4.464879989624023 6.706850051879883 Z">
                                </path>
                            </svg>
                        </div>
                    </div>
                </NavLink>

                <NavLink to={'/settings-bankaccounts'} className="nav-link" style={{ textDecoration: 'none' }}>

                    <div id="Group_7462_set_mob">
                        <div id="Business_Information_set_mob">
                            <span>Bank Account</span>
                        </div>
                        <div id="Component_103__2_set_mob" className="Component_103___2">
                            <svg className="Path_9390_g_set_mob" viewBox="0.636 0.05 6.95 11.9">
                                <path id="Path_9390_g_set_mob" d="M 4.464879989624023 6.706850051879883 C 4.855400085449219 6.316329956054688 4.855400085449219 5.683159828186035 4.464879989624023 5.292640209197998 L 0.9289849996566772 1.756739974021912 C 0.5385199785232544 1.36627995967865 0.538519024848938 0.7332080006599426 0.9289849996566772 0.342741996049881 L 0.9289849996566772 0.342741996049881 C 1.3194500207901 -0.0477232001721859 1.95252001285553 -0.04772299900650978 2.342979907989502 0.342741996049881 L 7.292880058288574 5.292640209197998 C 7.68340015411377 5.683159828186035 7.68340015411377 6.316319942474365 7.292880058288574 6.706850051879883 L 2.342979907989502 11.65670013427734 C 1.95252001285553 12.04720020294189 1.3194500207901 12.04720020294189 0.9289849996566772 11.65670013427734 L 0.9289849996566772 11.65670013427734 C 0.538519024848938 11.26630020141602 0.538519024848938 10.63319969177246 0.9289849996566772 10.24269962310791 L 4.464879989624023 6.706850051879883 Z">
                                </path>
                            </svg>
                        </div>
                    </div>

                </NavLink>
                <NavLink to={'/settings-integrations'}  >

                    <div id="Group_7463_set_mob">
                        <div id="Bank_Account_set_mob">
                            <span>Integrations</span>
                        </div>
                        <div id="Component_103__3_set_mob" className="Component_103___3">
                            <svg className="Path_9390_ha_set_mob" viewBox="0.636 0.05 6.95 11.9">
                                <path id="Path_9390_ha_set_mob" d="M 4.464879989624023 6.706850051879883 C 4.855400085449219 6.316329956054688 4.855400085449219 5.683159828186035 4.464879989624023 5.292640209197998 L 0.9289849996566772 1.756739974021912 C 0.5385199785232544 1.36627995967865 0.538519024848938 0.7332080006599426 0.9289849996566772 0.342741996049881 L 0.9289849996566772 0.342741996049881 C 1.3194500207901 -0.0477232001721859 1.95252001285553 -0.04772299900650978 2.342979907989502 0.342741996049881 L 7.292880058288574 5.292640209197998 C 7.68340015411377 5.683159828186035 7.68340015411377 6.316319942474365 7.292880058288574 6.706850051879883 L 2.342979907989502 11.65670013427734 C 1.95252001285553 12.04720020294189 1.3194500207901 12.04720020294189 0.9289849996566772 11.65670013427734 L 0.9289849996566772 11.65670013427734 C 0.538519024848938 11.26630020141602 0.538519024848938 10.63319969177246 0.9289849996566772 10.24269962310791 L 4.464879989624023 6.706850051879883 Z">
                                </path>
                            </svg>
                        </div>
                    </div>
                </NavLink>
              
            </div> */}
        </>
    )
};

export default SettingsProfile_Mobile;

import React from 'react';
import Nav from "./NavBar";
import UserRibbon from "./UserRibbon";


const Wrapper = (navigation:any) => {
    return (
        <>
            <Nav navigation={navigation}></Nav>
            <UserRibbon></UserRibbon>
        </>
    );
}

export default Wrapper;


import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { NavLink, Navigate } from 'react-router-dom';
import '../../styles/Products/categorydetail.css'
import '../../styles/Components/NavBar.css'
import '../../styles/Components/UserRibbon.css'
import Wrapper from "../../components/Wrapper";
import { useParams } from "react-router-dom";

import { get_category_detail } from "../../services/Products/categoriesinfo";
import { dateFormatter, datetimeFormatter_human } from '../../utils/date_formatter';
import { get_branding_info } from '../../services/Settings/brandinginfo';
import { get_available_products } from "../../services/Products/productsinfo";

import FilterableDataTable from '../../utils/data-table-search';
import { TableColumn } from 'react-data-table-component';

import { ResponsiveContainer, LineChart, Line, Label, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import { get_category_payments_insights } from '../../services/Insights/categoryinsights';
import MediaQuery from 'react-responsive';
import { Product } from 'aws-sdk/clients/ssm';
import { AvailableProducts } from '../../models/products';


const CategoryDetails = () => {
    const [categoryDetail, setCategoryDetail] = useState([] as any);
    const [products, setProducts] = useState([] as any)
    const [categoryPaymentsInsights, setCategoryPaymentsInsights] = useState([] as any);
    const [lastPayment, setLastPayment] = useState('');
    const [totalRevenue, setTotalRevenue] = useState('');
    const [branding, setBrandingInfo] = useState([] as any);
    const [showDiv, setShowDiv] = useState(false);
    const [brandAlias, setBrandAlias] = useState('');
    const [filterText, setFilterText] = React.useState('');
    const [AvailableProducts, setAvailableProducts] = useState([] as any);
    const [loading, setLoading] = useState(false)


    let { id } = useParams();
    let category_id = { id }.id

    useEffect(() => {
        fetchCategoryDetail();
        fetchBrandingInfo();
        fetchCategoryPaymentInsights(category_id);
        fetchAvailableProducts();
    }, []);

    // Category Detail
    async function fetchCategoryDetail() {
        var category = await get_category_detail(category_id);
        setCategoryDetail(category);
        setProducts(category.products)
    }

    // Category Payment Insights
    async function fetchCategoryPaymentInsights(category_id: any) {
        var insights = await get_category_payments_insights(category_id);
        setCategoryPaymentsInsights(insights.insights_data);
        setLastPayment(insights.last_spend)
        setTotalRevenue(insights.total_spend)
    }

    // Branding Info
    async function fetchBrandingInfo() {
        var branding = await get_branding_info();

        setBrandingInfo(branding);
        setBrandAlias(branding.brand_alias)

    }
    //Available Products
    async function fetchAvailableProducts() {
        setLoading(true)
        //show spinner
        await get_available_products().then(products => {

            setAvailableProducts(products);


        }).catch(error => {

        }).finally(() => {
            //Hide spinner
            setLoading(false)
        })
    }



    // Number of products
    var count = parseInt(products.length)


    // Copy Text to Clipboard
    const handleCopy = () => {
        var text = `${process.env.REACT_APP_BASE_URL}/linkbasket/${brandAlias}`
        const el = document.createElement('textarea');
        el.value = text;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        setShowDiv(true);
        setTimeout(() => setShowDiv(false), 2000);
    };



    // Product List
    const columns: TableColumn<{ product_name: string, product_price: any, product_units: any, slug: string }>[] = [
        {
            name: 'NAME',
            selector: (row) => row.product_name,
            sortable: true,
        },
        {
            name: 'PRICE',
            selector: (row) => `KES. ${row.product_price}`,
            sortable: true,
        },
        {
            name: 'IN STOCK',
            selector: (row) => `Available: ${row.product_units}`,
            sortable: true,
        },
        {
            name: '',
            cell: (row) => (
                <NavLink to={`/productdetail/${row.slug}`} className="nav-link" style={{ textDecoration: 'none' }}>
                    <div id="Group_4725_ctdl">
                        <div id="Group_3969_hp_ctdl">
                            <svg className="Rectangle_2057_hq_ctdl">
                                <rect id="Rectangle_2057_hq_ctdl" rx="7" ry="7" x="0" y="0" width="180" height="30">
                                </rect>
                            </svg>
                        </div>
                        <div id="View_product_ctdl">
                            <span>View product</span>
                        </div>
                        <div id="Layer_2_hs_ctdl">
                            <div id="eye_ctdl">
                                <svg className="Rectangle_2154_ctdl">
                                    <rect id="Rectangle_2154_ctdl" rx="0" ry="0" x="0" y="0" width="20" height="20">
                                    </rect>
                                </svg>
                                <svg className="Path_9112_ctdl" viewBox="1.996 4.996 16.402 11.48">
                                    <path id="Path_9112_ctdl" d="M 18.28842353820801 10.32783699035645 C 17.76375961303711 9.417875289916992 14.87811470031738 4.851669311523438 9.975796699523926 4.99923038482666 C 5.4423828125 5.11400032043457 2.819069862365723 9.098158836364746 2.105855941772461 10.32783699035645 C 1.959415316581726 10.5814790725708 1.959415197372437 10.8939790725708 2.105855941772461 11.14762210845947 C 2.622320652008057 12.04118919372559 5.384997844696045 16.47622680664062 10.21353435516357 16.47622680664062 L 10.41848087310791 16.47622680664062 C 14.95189476013184 16.36145782470703 17.58340644836426 12.37730026245117 18.28842353820801 11.14762210845947 C 18.43486404418945 10.8939790725708 18.43486404418945 10.58148002624512 18.28842353820801 10.32783699035645 Z M 10.37749195098877 14.83665657043457 C 6.844215869903564 14.91863632202148 4.540618896484375 11.89362716674805 3.819207906723022 10.73773002624512 C 4.638993263244629 9.417875289916992 6.778633117675781 6.720779895782471 10.05777454376221 6.638801574707031 C 13.57465362548828 6.548624515533447 15.88645172119141 9.581831932067871 16.61605834960938 10.73773002624512 C 15.77167892456055 12.05758380889893 13.65663242340088 14.75467777252197 10.37749099731445 14.83665657043457 Z">
                                    </path>
                                </svg>
                                <svg className="Path_9113_ctdl" viewBox="8.5 8.5 5.738 5.738">
                                    <path id="Path_9113_ctdl" d="M 11.36924934387207 8.5 C 9.78460693359375 8.500000953674316 8.500000953674316 9.78460693359375 8.500000953674316 11.36925029754639 C 8.500000953674316 12.95389270782471 9.78460693359375 14.23849964141846 11.36925029754639 14.23849964141846 C 12.95389270782471 14.23849964141846 14.23849964141846 12.95389270782471 14.23849964141846 11.36925029754639 C 14.23849964141846 9.784605979919434 12.95389270782471 8.499999046325684 11.36924839019775 8.5 Z M 11.36924934387207 12.59892749786377 C 10.6901159286499 12.59892749786377 10.13957118988037 12.04838180541992 10.13957118988037 11.36924934387207 C 10.13957118988037 10.6901159286499 10.6901159286499 10.13957118988037 11.36924934387207 10.13957118988037 C 12.04838180541992 10.13957118988037 12.59892749786377 10.6901159286499 12.59892749786377 11.36924934387207 C 12.59892749786377 12.04838180541992 12.04838180541992 12.59892749786377 11.36924934387207 12.59892749786377 Z">
                                    </path>
                                </svg>
                            </div>
                        </div>
                    </div>
                </NavLink>
            )
        }
    ];

    // Filter
    const filteredItems = products.filter(
        (item: { product_units: { toString: () => string; }; product_price: { toString: () => string; }; currency: string; product_name: string; }) =>
            item.product_units?.toString().toLowerCase().includes(filterText.toLowerCase()) ||
            item.product_price?.toString().toLowerCase().includes(filterText.toLowerCase()) ||
            item.currency?.toLowerCase().includes(filterText.toLowerCase()) ||
            item.product_name?.toLowerCase().includes(filterText.toLowerCase())

    );

    /// Export to CSV
    const handleExport = () => {
        const columnNames = ['Product Name', 'Currency', 'Product Price', 'Product Units'];
        const dataRows = products.map((product: { product_name: any, product_price: any, product_units: any, slug: any }) => (
            [product.product_name, 'KES', product.product_price, product.product_units].join(',')
        ));
        const csv = [columnNames.join(','), ...dataRows].join('\n');
        const csvData = new Blob([csv], { type: 'text/csv' });
        const csvUrl = URL.createObjectURL(csvData);
        const hiddenElement = document.createElement('a');
        hiddenElement.href = csvUrl;
        hiddenElement.target = '_blank';
        hiddenElement.download = `${categoryDetail['category_name']}'s Products.csv`;
        hiddenElement.click();
    };

    let data = [];
    // Graph: Category Payment Insights 
    if (categoryPaymentsInsights) {
        data = categoryPaymentsInsights
        data.sort((a: { date: { split: (arg0: string) => [any, any]; }; }, b: { date: { split: (arg0: string) => [any, any]; }; }) => {
            const [dayA, monthA] = a.date.split("/");
            const [dayB, monthB] = b.date.split("/");
            if (monthA === monthB) {
                return dayA - dayB;
            }

            return monthA - monthB;
        });
    }


    const todaysSpend = data?.[data.length - 1]?.data



    return (
        <>


            <MediaQuery minWidth={1001}>
                <main className="form-signin">
                    <div id="CategoryDetail_ctdl">
                        <div id="Group_4990_ctdl">
                            <div id="Group_2224_ctdl">
                                <div id="Description_ctdl">
                                    <span>Description</span>
                                </div>
                                <div id="The_most_comfortable_running_s_ctdl">
                                    <span>{categoryDetail['description']}</span>
                                </div>
                            </div>
                            <div id="Group_4716_ctdl">
                                <div id="Name_ctdl">
                                    <span>Name</span>
                                </div>
                                <div id="Adidas_Ultraboost_ctdl">
                                    <span>{categoryDetail['category_name']}</span>
                                </div>
                            </div>
                            <div id="Group_4717_ctdl">
                                <div id="Created_ctdl">
                                    <span>Created</span>
                                </div>
                                <div id="n_4_September_ctdl">
                                    <span>{datetimeFormatter_human(categoryDetail['created_at'])}</span>
                                </div>
                            </div>
                            <div id="Group_4718_ctdl">
                                <div id="Status_ctdl">
                                    <span>Status</span>
                                </div>
                                <div id="Available_ctdl">
                                    <span>{categoryDetail['status']}</span>
                                </div>
                            </div>
                            <div id="Group_4608_ctdl">
                                <div id="Payment_method_ctdl">
                                    <span>Payment method</span>
                                </div>
                                <div id="Group_4712_2_ctdl">
                                    <span>MPESA/CARD</span>
                                </div>
                            </div>
                            <div id="All_ctdl">
                                <span>Category Details</span>
                            </div>
                            <div id="All_fj_ctdl">
                                <span>Category Image</span>
                            </div>
                            <div id="Group_4998_ctdl">
                                <svg className="Line_ctdl" viewBox="0 1 1344.536 1">
                                    <path id="Line_ctdl" d="M 0 1 L 1344.5361328125 1">
                                    </path>
                                </svg>
                            </div>
                            <div id="Group_6982_ctdl">
                                <svg className="Rectangle_2151_ctdl">
                                    <rect id="Rectangle_2151_ctdl" rx="7" ry="7" x="0" y="0" width="173" height="173">
                                    </rect>
                                </svg>
                                {categoryDetail['image'] === "" ? (
                                    <img id="adidas-ultraboost_ctdl" src="/media/no-image-placeholder.png" />
                                ) : (
                                    <img id="adidas-ultraboost_ctdl" src={"https://chpter-3-jarvis-bucket.s3.amazonaws.com/categories/" + categoryDetail['image']} />
                                )}
                            </div>
                        </div>
                        <div id="Group_6591_ctdl">
                            <div id="Group_4738_ctdl">
                                <div id="Group_3969_ctdl">
                                    <svg className="Rectangle_2057_ctdl">
                                        <rect id="Rectangle_2057_ctdl" rx="5" ry="5" x="0" y="0" width="150" height="35">
                                        </rect>
                                    </svg>
                                </div>
                            </div>
                            <div id="CATEGORY_Detail_ctdl">
                                <span>CATEGORY Detail</span>
                            </div>
                        </div>
                        <div id="Group_6986_ctdl">
                            <div id="Group_6623_ctdl">
                                <div id="Group_6648_ctdl">
                                    <div id="Total_Spend_ctdl">
                                        <span>Total Spend, KES. {totalRevenue ? totalRevenue : 0.00}</span>
                                    </div>
                                    <div id="Today_KES_2756000_ctdl">
                                        <span>Today, KES. {todaysSpend ? todaysSpend : 0.00}</span>
                                    </div>
                                </div>
                            </div>
                            <div id="Group_6624_ctdl">
                                <div className="section col-md-6">
                                    <h3 className="section-title" style={{ fill: "black", fontStyle: "Roboto" }}></h3>
                                    <div className="section-content">
                                        <ResponsiveContainer width={400} height={250}>
                                            <LineChart data={data} margin={{ top: 15, right: 0, bottom: 15, left: 10 }}>
                                                <Tooltip />
                                                <XAxis dataKey="date" style={{ fill: "black", fontSize: "13" }} value={"Height (ft.)"} />
                                                <YAxis style={{ fill: "black", fontSize: "13" }}>
                                                    <Label style={{ fontSize: "130%", paddingBottom: '40px' }}
                                                        angle={-90}

                                                    />
                                                </YAxis>
                                                <Legend />
                                                <Line type="monotone" dataKey="data" stroke="#17A8F5" />
                                            </LineChart>
                                        </ResponsiveContainer>
                                    </div>
                                </div>
                            </div>
                            <div id="n_0_Transactions_ctdl">
                                {/* <span>10 Transactions</span> */}
                            </div>
                            <div id="Category_Insights_ctdl">
                                <span>Category Insights</span>
                            </div>
                        </div>
                        <div id="Group_6979_ctdl">
                            <div id="Group_4590_ctdl">
                                <div id="All_ga_ctdl">
                                    <span>{categoryDetail['category_name']}</span>
                                </div>

                                {count === 1 ? (
                                    <div id="All_gb_ctdl">
                                        <span>{count} product</span>
                                    </div>
                                ) : (
                                    <div id="All_gb_ctdl">
                                        <span>{count} products</span>
                                    </div>
                                )
                                }

                                <div id="Group_4986_ctdl">
                                    <div id="The_greatest_running_shoe_in_t_ctdl">
                                        <span>Category Link</span>
                                    </div>
                                </div>
                                <div id="Group_4987_ctdl">
                                    <svg className="Rectangle_2151_gf_ctdl">
                                        <rect id="Rectangle_2151_gf_ctdl" rx="7" ry="7" x="0" y="0" width="50" height="50">
                                        </rect>
                                    </svg>
                                    {categoryDetail['image'] === "" ? (
                                        <img id="adidas-ultraboost_gg_ctdl" src="/media/no-image-placeholder.png" />
                                    ) : (
                                        <img id="adidas-ultraboost_gg_ctdl" src={"https://chpter-3-jarvis-bucket.s3.amazonaws.com/categories/" + categoryDetail['image']} />
                                    )}

                                </div>
                            </div>
                            <div id="CopyLink_ctdl">
                                <div id="Group_5169_ctdl">
                                    <div id="Copy_and_share_this_link__to_i_ctdl">
                                        <span>Copy and share this link to directly access the linkbasket category link.</span>
                                    </div>
                                </div>
                                <div id="Group_5181_1_ctdl" style={{ display: showDiv ? "block" : "none" }}>
                                    <svg className="Rectangle_2057_1_ctdl">
                                        <rect id="Rectangle_2057_1_ctdl" rx="7" ry="7" x="0" y="0" width="154" height="27">
                                        </rect>
                                    </svg>
                                    <div id="Link_Copied_to_Clipboard_1_ctdl">
                                        <span>Link Copied to Clipboard</span>
                                    </div>
                                </div>
                                <div id="Component_75_ctdl">
                                    <svg className="Rectangle_2127_ctdl">
                                        <rect id="Rectangle_2127_ctdl" rx="5" ry="5" x="0" y="0" width="350" height="38">
                                        </rect>
                                    </svg>
                                    <div id="Search_Input_ctdl" >
                                        <div id="httpscheckoutchptercodrip_city_ctdl">
                                            <span>{`${process.env.REACT_APP_BASE_URL}/linkbasket/${branding['brand_alias']}`}</span>
                                        </div>
                                    </div>
                                </div>
                                <div id="Group_5171_ctdl" onClick={handleCopy}>
                                    <svg className="Rectangle_2126_ctdl">
                                        <rect id="Rectangle_2126_ctdl" rx="5" ry="5" x="0" y="0" width="38" height="38">
                                        </rect>
                                    </svg>
                                    <div id="Layer_22_ctdl">
                                        <div id="copy_ctdl">
                                            <svg className="Rectangle_2125_ctdl">
                                                <rect id="Rectangle_2125_ctdl" rx="0" ry="0" x="0" y="0" width="32" height="32">
                                                </rect>
                                            </svg>
                                            <svg className="Path_9096_ctdl" viewBox="9 9 15.529 15.529">
                                                <path id="Path_9096_ctdl" d="M 20.64683532714844 24.52911376953125 L 12.88227844238281 24.52911376953125 C 10.73815441131592 24.52911376953125 9 22.79095649719238 9 20.64683532714844 L 9 12.88227844238281 C 9 10.73815441131592 10.73815631866455 9 12.88228034973145 9 L 20.64683532714844 9 C 22.79095649719238 9 24.52911376953125 10.73815441131592 24.52911376953125 12.88227844238281 L 24.52911376953125 20.64683532714844 C 24.52911376953125 22.79095649719238 22.79095649719238 24.52911376953125 20.64683532714844 24.52911376953125 Z M 12.88227844238281 11.58818626403809 C 12.16757011413574 11.58818626403809 11.58818626403809 12.16757011413574 11.58818626403809 12.88227844238281 L 11.58818626403809 20.64683532714844 C 11.58818626403809 21.36154174804688 12.16757011413574 21.94092750549316 12.88227844238281 21.94092750549316 L 20.64683532714844 21.94092750549316 C 21.36154174804688 21.94092750549316 21.94092750549316 21.36154174804688 21.94092750549316 20.64683532714844 L 21.94092750549316 12.88227844238281 C 21.94092750549316 12.16757011413574 21.36154174804688 11.58818626403809 20.64683532714844 11.58818626403809 L 12.88227844238281 11.58818626403809 Z">
                                                </path>
                                            </svg>
                                            <svg className="Path_9097_ctdl" viewBox="3 3 15.529 15.529">
                                                <path id="Path_9097_ctdl" d="M 11.70924377441406 18.52911567687988 L 6.455227851867676 18.52911567687988 C 4.549904823303223 18.52202033996582 3.007095336914062 16.97921180725098 2.999999523162842 15.07388687133789 L 3.000000476837158 6.455227851867676 C 3.007095813751221 4.549904823303223 4.549906253814697 3.007095336914062 6.455228805541992 2.999999523162842 L 15.07388687133789 3.000000476837158 C 16.97921180725098 3.007095336914062 18.52202033996582 4.549904823303223 18.52911567687988 6.455228805541992 L 18.52911567687988 11.28219413757324 L 15.9409294128418 11.28219413757324 L 15.9409294128418 6.455227851867676 C 15.9409294128418 5.976373195648193 15.5527400970459 5.58818531036377 15.07388687133789 5.58818531036377 L 6.455227851867676 5.58818531036377 C 5.976373195648193 5.58818531036377 5.58818531036377 5.976373195648193 5.58818531036377 6.455227851867676 L 5.58818531036377 15.07388687133789 C 5.58818531036377 15.5527400970459 5.976373195648193 15.9409294128418 6.455227851867676 15.9409294128418 L 11.70924377441406 15.9409294128418 L 11.70924377441406 18.52911567687988 Z">
                                                </path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="summary_ctdl">
                                <div id="All_gh_ctdl">
                                    <span>Summary</span>
                                </div>
                                <div id="Group_4997_ctdl">
                                    <svg className="Line_gj_ctdl" viewBox="0 1 1344.536 1">
                                        <path id="Line_gj_ctdl" d="M 0 1 L 1344.5361328125 1">
                                        </path>
                                    </svg>
                                </div>
                                <svg className="Line_340_ctdl" viewBox="0 0 1 104">
                                    <path id="Line_340_ctdl" d="M 0 0 L 0 104">
                                    </path>
                                </svg>
                                <svg className="Line_341_ctdl" viewBox="0 0 1 104">
                                    <path id="Line_341_ctdl" d="M 0 0 L 0 104">
                                    </path>
                                </svg>
                                <div id="Group_3393_ctdl">
                                    <div id="ksh3500000_ctdl">
                                        <span>KES. {totalRevenue ? totalRevenue : 0.00}</span>
                                    </div>
                                    <div id="Total_Revenue_ctdl">
                                        <span>Total Revenue</span>
                                    </div>
                                </div>
                                <div id="Group_4602_ctdl">
                                    <div id="n_4_Nov_164500_PM_ctdl">
                                        <span>{datetimeFormatter_human(categoryDetail['created_at'])}</span>
                                    </div>
                                    <div id="Date_Created_ctdl">
                                        <span>Date Created</span>
                                    </div>
                                </div>
                                <div id="Group_4604_ctdl">
                                    <div id="ksh_3650000_ctdl">
                                        <span>KES. {lastPayment ? lastPayment : 0.00}</span>
                                    </div>
                                    <div id="Last_Payment_ctdl">
                                        <span>Last Payment</span>
                                    </div>
                                </div>
                            </div>
                            <NavLink to={`/${categoryDetail['id']}/editproductcategory`} className="nav-link" >
                                <div id="Group_6586_ctdl">
                                    <div id="Group_4117_ctdl">
                                        <svg className="Rectangle_1977_ctdl">
                                            <rect id="Rectangle_1977_ctdl" rx="5" ry="5" x="0" y="0" width="137" height="34">
                                            </rect>
                                        </svg>

                                        <div id="Edit_Category_ctdl">
                                            <span>Edit Category</span>
                                        </div>
                                        <div id="Layer_2_ctdl">
                                            <div id="edit-2_ctdl">
                                                <svg className="Rectangle_2459_ctdl">
                                                    <rect id="Rectangle_2459_ctdl" rx="0" ry="0" x="0" y="0" width="24" height="24">
                                                    </rect>
                                                </svg>
                                                <svg className="Path_9297_ctdl" viewBox="4 20 16 2">
                                                    <path id="Path_9297_ctdl" d="M 19 20 L 5 20 C 4.447715282440186 20 4 20.44771575927734 4 21 C 4 21.55228424072266 4.447715282440186 22 5 22 L 19 22 C 19.55228424072266 22 20 21.55228424072266 20 21 C 20 20.44771575927734 19.55228424072266 20 19 20 Z">
                                                    </path>
                                                </svg>
                                                <svg className="Path_9298_ctdl" viewBox="3.996 2.058 16 15.942">
                                                    <path id="Path_9298_ctdl" d="M 5 18 L 5.090000152587891 18 L 9.260000228881836 17.6200008392334 C 9.716796875 17.57449722290039 10.14403533935547 17.37323570251465 10.47000026702881 17.05000114440918 L 19.47000122070312 8.05000114440918 C 20.19729232788086 7.281663417816162 20.16598892211914 6.069767951965332 19.40000152587891 5.340001106262207 L 16.65999984741211 2.599999904632568 C 15.91972351074219 1.904654502868652 14.77582359313965 1.874551773071289 14.00000095367432 2.53000020980835 L 5 11.52999973297119 C 4.676766395568848 11.85596466064453 4.475504875183105 12.283203125 4.430000305175781 12.73999977111816 L 4 16.90999984741211 C 3.972726345062256 17.20654487609863 4.079037189483643 17.49981498718262 4.289999961853027 17.70999908447266 C 4.478718280792236 17.89718627929688 4.734196662902832 18.00153732299805 5 18 Z M 15.27000045776367 4 L 18 6.730000019073486 L 16 8.680000305175781 L 13.31999969482422 6 L 15.27000045776367 4 Z M 6.370000839233398 12.90999984741211 L 12 7.320000171661377 L 14.69999980926514 10.02000045776367 L 9.100000381469727 15.6200008392334 L 6.100000381469727 15.90000057220459 L 6.370000839233398 12.90999984741211 Z">
                                                    </path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div id="products_ctdl">
                            <div id="Group_4692_ctdl">
                                <div id="All_fq_ctdl">
                                    <span>Products</span>
                                </div>
                            </div>
                            <svg className="Line_337_ctdl" viewBox="0 0 1369.34 1_ctdl">
                                <path id="Line_337_ctdl" d="M 0 0 L 1369.339599609375 0_ctdl">
                                </path>
                            </svg>
                            <div id="Group_7009_ctdl">
                                <FilterableDataTable data={filteredItems} columns={columns} scrollHeight="400px" rowHeight={'normal'} />
                            </div>
                            <div id="Group_4972_ctdl" onClick={() => handleExport()}>
                                <div id="Group_4117_ctdl">
                                    <svg className="Rectangle_1977_ctdl">
                                        <rect id="Rectangle_1977_ctdl" rx="5" ry="5" x="0" y="0" width="75" height="34">
                                        </rect>
                                    </svg>
                                    <div id="Export_ctdl">
                                        <span>Export</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </main>
                <Wrapper navigation="Products"></Wrapper>

            </MediaQuery>


            <MediaQuery maxWidth={1000}>

                <div className="mobilecategorydetail_ctdl_mob">
                    <div className="mobilecategorydetail-inner_ctdl_mob">
                        <div className="group-wrapper_ctdl_mob">
                            <div className="group-wrapper_ctdl_mob">
                                <div className="category_ctdl_mob">
                                    <div className="header_ctdl_mob">
                                        <div className="frame-parent_ctdl_mob">

                                            <NavLink to={'/categories'}>

                                                <div className="group-container_ctdl_mob">
                                                    <div className="rectangle-parent_ctdl_mob">
                                                        <div className="group-child_ctdl_mob" />


                                                        <svg className="path-9390-icon_ctdl_mob" viewBox="0.636 0.05 10.513 18">
                                                            <path id="Path_9390_prdl_mob" d="M 5.357423305511475 10.11950778961182 C 4.766643047332764 9.528789520263672 4.766643047332764 8.571026802062988 5.357423305511475 7.980309963226318 L 10.7065315246582 2.631746768951416 C 11.29722785949707 2.041119337081909 11.2972297668457 1.083506107330322 10.7065315246582 0.4928695559501648 L 10.7065315246582 0.4928695559501648 C 10.11583614349365 -0.09776583313941956 9.158125877380371 -0.09776552766561508 8.567437171936035 0.4928695559501648 L 1.079220414161682 7.980309963226318 C 0.4884408712387085 8.571026802062988 0.4884408712387085 9.528774261474609 1.079220414161682 10.11950778961182 L 8.567437171936035 17.60687637329102 C 9.158125877380371 18.19756317138672 10.11583614349365 18.19756317138672 10.7065315246582 17.60687637329102 L 10.7065315246582 17.60687637329102 C 11.2972297668457 17.0163402557373 11.2972297668457 16.05868339538574 10.7065315246582 15.46799468994141 L 5.357423305511475 10.11950778961182 Z">
                                                            </path>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </NavLink>


                                            <div className="payment-detail_ctdl_mob">category detail</div>
                                        </div>
                                    </div>
                                    <div className="productbio_ctdl_mob">
                                        <div className="frame-group_ctdl_mob">
                                            <div className="frame-container_ctdl_mob">
                                                <div className="product-wrapper_ctdl_mob">
                                                    <div className="product_ctdl_mob">category</div>
                                                </div>
                                                <div className="kes-15045689-parent_ctdl_mob">
                                                    <div className="kes-15045689_ctdl_mob">{categoryDetail['category_name']}</div>
                                                    <div className="wallet-balance_ctdl_mob"> {count ? count : 0} Products</div>
                                                </div>

                                                <div className="the-greatest-running-shoe-in-t-wrapper_ctdl_mob">
                                                    <div className="the-greatest-running_ctdl_mob">
                                                        {categoryDetail && categoryDetail.description &&
                                                            <span >{categoryDetail.description.replace(/((?:\S+\s*){15})/g, '$1\n')}</span>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="group-frame_ctdl_mob">
                                                    <div className="group-parent_ctdl_mob">
                                                        <div className="rectangle-wrapper_ctdl_mob">
                                                            <div className="group-item_ctdl_mob" />
                                                        </div>
                                                        <div className="available_ctdl_mob">available</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="frame-div_ctdl_mob">
                                                {categoryDetail['image'] === "" ? (
                                                    <img
                                                        className="rectangle-group_ctdl_mob"
                                                        alt=""
                                                        src="/media/no-image-placeholder.png"
                                                    />
                                                ) : (
                                                    <img
                                                        className="rectangle-group_ctdl_mob"
                                                        alt=""
                                                        src={"https://chpter-3-jarvis-bucket.s3.amazonaws.com/categories/" + categoryDetail['image']}
                                                    />
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                    <div className="copylink_ctdl_mob">
                                        <div className="copylink-inner_ctdl_mob">
                                            <div className="frame-wrapper_ctdl_mob">
                                                <div className="copied-wrapper_ctdl_mob" style={{ display: showDiv ? "block" : "none" }}>
                                                    <b className="mobilecategorydetail-inner_ctdl_mob">{`Copied! `}</b>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="frame-parent1_ctdl_mob">
                                            <div className="frame-wrapper1_ctdl_mob">
                                                <div className="httpscheckoutchptercodri-wrapper_ctdl_mob">
                                                    <div className="httpscheckoutchptercodri_ctdl_mob">
                                                        {`${process.env.REACT_APP_BASE_URL}/linkbasket/${branding['brand_alias']}`}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="copy-link-wrapper_ctdl_mob" onClick={handleCopy}>
                                                <b className="copy-link_ctdl_mob">Copy link</b>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="detailbuttons_ctdl_mob">
                                        <div className="frame-parent2_ctdl_mob">
                                            <div className="frame-wrapper2_ctdl_mob">
                                                <div className="frame-wrapper3_ctdl_mob">
                                                    <div className="link-parent_ctdl_mob">


                                                        <div className="link-icon_ctdl_mob">
                                                            <svg className="Rectangle_2185_prdl_mob">
                                                                <rect id="Rectangle_2185_prdl_mob" rx="0" ry="0" x="0" y="0" width="24" height="24">
                                                                </rect>
                                                            </svg>
                                                            <svg className="Path_9119_prdl_mob" viewBox="8 11 8 2">
                                                                <path id="Path_9119_prdl_mob" d="M 8 12 C 8 12.55228424072266 8.447714805603027 13 9 13 L 15 13 C 15.55228519439697 13 16 12.55228424072266 16 12 C 16 11.44771480560303 15.55228424072266 11 15 11 L 9 11 C 8.447714805603027 11 8 11.44771575927734 8 12 Z">
                                                                </path>
                                                            </svg>
                                                            <svg className="Path_9120_prdl_mob" viewBox="0.998 5.999 9.002 12.001">
                                                                <path id="Path_9120_prdl_mob" d="M 9 16 L 7.210000038146973 16 C 5.080501556396484 16.05684089660645 3.257140636444092 14.48468017578125 2.999999046325684 12.36999893188477 C 2.895629405975342 11.24662971496582 3.270514488220215 10.13142204284668 4.03226900100708 9.29920482635498 C 4.794022560119629 8.466989517211914 5.871800899505615 7.995162010192871 6.999999046325684 8 L 9 8 C 9.552285194396973 8 10 7.552284717559814 10 7 C 10 6.447715282440186 9.552284240722656 6 9 6 L 7.210000038146973 6 C 4.145279884338379 5.959044933319092 1.518198013305664 8.180975914001465 1.050000667572021 11.20999908447266 C 0.822291374206543 12.92437076568604 1.345400810241699 14.65362358093262 2.485186100006104 15.95432090759277 C 3.624971389770508 17.25501823425293 5.270570278167725 18.00064468383789 6.999999046325684 18 L 9 18 C 9.552285194396973 18 10 17.55228424072266 10 17 C 10 16.44771575927734 9.552284240722656 16 9 16 Z">
                                                                </path>
                                                            </svg>
                                                            <svg className="Path_9121_prdl_mob" viewBox="14 5.998 9.048 12.002">
                                                                <path id="Path_9121_prdl_mob" d="M 23 11.23999977111816 C 22.53731155395508 8.170629501342773 19.8631420135498 5.925011157989502 16.76000022888184 6 L 15.25 6 C 14.4399995803833 6 14 6.449999809265137 14 7 C 14 7.552284717559814 14.44771480560303 8 15 8 L 16.79000091552734 8 C 18.91949844360352 7.943160057067871 20.74285697937012 9.515320777893066 21 11.63000011444092 C 21.1043701171875 12.75337028503418 20.72948455810547 13.86857795715332 19.96773147583008 14.7007942199707 C 19.20597648620605 15.5330114364624 18.12819862365723 16.00483894348145 17 16 L 15 16 C 14.44771480560303 16 14 16.44771575927734 14 17 C 14 17.55228424072266 14.44771575927734 18 15 18 L 17 18 C 18.73217582702637 18.01395797729492 20.3858585357666 17.27861022949219 21.53573799133301 15.98307991027832 C 22.68561744689941 14.68754959106445 23.21947288513184 12.95827102661133 23 11.23999977111816 Z">
                                                                </path>
                                                            </svg>
                                                        </div>


                                                        <div className="copy-and-share-this-link-with-wrapper_ctdl_mob">
                                                            <div className="copy-and-share_ctdl_mob">
                                                                Copy and share this link to initiate checkout and
                                                                accept payments payments.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <NavLink to={`/${categoryDetail['id']}/editproductcategory`} style={{ textDecoration: "none" }} className="customerdetailbuttons_ctdl_mob">
                                                <div className="buttons_ctdl_mob">
                                                    <div className="edit-customer-wrapper_ctdl_mob">
                                                        <div className="edit-customer_ctdl_mob">Edit Category</div>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    </div>


                                    <div className="paymentdetails_pad_mob">
                                        <div className="paymentdetails-inner_pad_mob">
                                            <div className="frame-parent1_pad_mob">
                                                <div className="all-wrapper_pad_mob">
                                                    <div className="all_pad_mob">Category Details</div>
                                                </div>

                                                <div className="paymentdetails-inner_pad_mob">
                                                    <svg className="line-icon_pad_mob " viewBox="0 1 414 0.5">
                                                        <path id="line-icon_pad_mob " d="M 0 1 L 414 1">
                                                        </path>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="paymentdetails-child_pad_mob">
                                            <div className="frame-parent2_pad_mob">
                                                <div className="reference-parent_pad_mob">
                                                    <div className="reference_pad_mob">Name</div>
                                                    <div className="adidas-ultraboost-wrapper_pad_mob">
                                                        <div className="adidas-ultraboost_pad_mob">
                                                            {categoryDetail['category_name']}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="reference-parent_pad_mob">
                                                    <div className="barry-oloo_pad_mob">Created</div>
                                                    <div className="adidas-ultraboost-wrapper_pad_mob">
                                                        <div className="adidas-ultraboost_pad_mob">{datetimeFormatter_human(categoryDetail['created_at'])}</div>
                                                    </div>
                                                </div>
                                                <div className="reference-parent_pad_mob">
                                                    <div className="reference2_pad_mob">Total Revenue</div>
                                                    <div className="adidas-ultraboost-wrapper_pad_mob">
                                                        <div className="adidas-ultraboost_pad_mob">KES. {totalRevenue ? totalRevenue : 0.00}</div>
                                                    </div>
                                                </div>
                                                <div className="reference-parent_pad_mob">
                                                    <div className="reference2_pad_mob">Payment method</div>
                                                    <div className="adidas-ultraboost-wrapper_pad_mob">
                                                        <div className="adidas-ultraboost_pad_mob">MPESA/CARD</div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    {products && products.length > 0 ? (



                                        <div className="products_ctdl_mob">
                                            <div className="categorydetails-inner_ctdl_mob">
                                                <div className="frame-parent5_ctdl_mob">
                                                    <div className="all-wrapper_ctdl_mob">
                                                        <div className="product_ctdl1_mob">Products</div>
                                                    </div>
                                                    <div className="categorydetails-inner_ctdl_mob">

                                                        <svg className="line-icon1_ctdl_mob" viewBox="0 1 414 0.5">
                                                            <path id="Line_i" d="M 0 1 L 414 1">
                                                            </path>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>

                                            {products.map((product: AvailableProducts) => {
                                                return (




                                                    <NavLink to={`/productdetail/${product.slug}`} style={{ textDecoration: "none" }} className="frame-parent6_ctdl_mob">
                                                        <div className="frame-parent7_ctdl_mob">
                                                            <div className="frame-wrapper4_ctdl_mob">
                                                                <div className="kes-1490000-parent_ctdl_mob">
                                                                    <div className="kes-1490000_ctdl_mob">{product.product_name}</div>
                                                                    <div className="barry-oloo_ctdl_mob">Available : {product.product_units}</div>
                                                                </div>
                                                            </div>
                                                            <div className="july-2023-parent_ctdl_mob">
                                                                <div className="july-2023_ctdl_mob">KES. {product.product_price ? product.product_price : 0.00}</div>
                                                                <div className="group-wrapper1_ctdl_mob">
                                                                    <div className="group-div_ctdl_mob">
                                                                        <div className="rectangle-container_ctdl_mob">
                                                                            <div className="rectangle-div_ctdl_mob" />
                                                                        </div>
                                                                        <div className="available1_ctdl_mob">{product.status}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>





                                                    </NavLink>

                                                )
                                            })}
                                        </div>



                                    ) : null}




                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </MediaQuery>



        </>
    )
}

export default CategoryDetails;


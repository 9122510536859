import '../../styles/Welcome/login.css';
import axios from 'axios';
import React, { useState, SyntheticEvent } from 'react'
import { NavLink, Navigate, useNavigate } from "react-router-dom"
import { FullPageSpinner } from '../../components/spinner';
import '../../styles/Components/Spinner.css'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import MediaQuery from "react-responsive";
import { BsEyeFill } from 'react-icons/bs'
import { BsEyeSlashFill } from 'react-icons/bs'
import { BouncingDotsLoader, BouncingDotsLoaderMobile } from '../../components/loading-dots';
import 'react-toastify/dist/ReactToastify.css';
import '../../styles/Components/Toastify.css'
import '../../styles/Components/loading-dots.css'
import '../../styles/Components/loading-dots.css'


const Login = () => {

    const initialValues = { email: '', password: '' }
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState<Record<string, string>>({});
    const [showPassword, setShowPassword] = useState(false);

    const [redirect, setredirect] = useState(false)
    const [loading, setLoading] = useState(false)




    const handleChange = (e: { target: { name: any; value: any; }; }) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value })

    }

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const isEmailValidator = require('validator').isEmail

    const Validate = (Values: { email: any; password: any; }) => {
        const errors: any = {};
        setFormErrors(errors)
        if (!Values.email) {
            toast("Input Email Address", { type: "error" });
            errors.email = "Input Email Address";
        }
        else if (!isEmailValidator(Values.email)) {
            toast("Invalid Email formart", { type: "error" });
            errors.email = "Invalid Email formart";
        }
        else if (!Values.password) {
            toast("Input Password", { type: "error" });
            errors.password = "Input Password";
        }



        setFormErrors(errors)
        if (Object.keys(errors).length > 0) {
            let errorMessage = "";
            for (const key in formErrors) {
                if (formErrors[key]) {
                    errorMessage += formErrors[key] + "\n";
                }
            }

        }
        return Object.keys(errors).length === 0;
    };

    const navigate = useNavigate();

    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();
        const isValid = Validate(formValues);
        if (!isValid) {
            return;
        }

        let api_url = process.env.REACT_APP_CHPTER_API_URL + '/merchants/login'
        let headers = {
            "Content-Type": "application/json"
        };

        const data = JSON.stringify(formValues);

        setLoading(true)

        await axios.post(api_url, data, { headers: headers })
            .then((response) => {

                const token = response.data.auth_token;
                localStorage.setItem('JWT', token);


                if (response.status === 200) {
                    if (response.data.is_email_verified) {
                        if (response.data.is_shopify_active == false) {
                            navigate('/homepage');
                        } else {
                            navigate('/shopify');
                        }

                    } else {
                        toast("Email has not been verified", { type: "error" });
                        toast("Kindly check you inbox for a verification email", { type: "error" });
                        setLoading(false)
                    }
                }

            })
            .catch((error) => {
                // Error
                if (error.response) {
                    if (error.response.status === 404) {
                        toast("Email Address not found", { type: "error" });
                    } else {
                        toast(error.response.data.message, { type: "error" });
                    }

                } else if (error.request) {
                    toast(error.request, { type: "error" });
                } else {
                    toast(error.message, { type: "error" });
                }
                toast(error.config, { type: "error" });
            }).finally(() => {
                setLoading(false)
            });


    }

    if (localStorage.getItem('JWT') === '') {
        localStorage.removeItem('JWT');
        return <Navigate to={'/login'} />
    }




    const handleKeyDown = (event: React.SyntheticEvent<Element, Event>) => {
        const keyEvent = event as React.KeyboardEvent;
        if (keyEvent.key === "Enter") {
            submit(event);
        }
    };


    return (

        <div>


            <MediaQuery minWidth={1001}>
                <ToastContainer
                    position="top-center"
                    autoClose={9000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick={false}
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    toastClassName="custom-toast"
                    className="toast-container"
                    style={{
                        top: "10%",
                        color: "white",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                    }}
                />


                {loading ? (<BouncingDotsLoader></BouncingDotsLoader>
                ) : (
                    <div id="SignIn" >
                        <div id="Group_3972_si">
                            <svg className="Rectangle_1922_si">
                                <rect id="Rectangle_1922_si" rx="15" ry="15" x="0" y="0" width="600" height="575">
                                </rect>
                            </svg>
                            <div id="All_si">
                                <span>Sign in to your account</span>
                            </div>
                            <div id="Group_3969_si" onClick={submit} >
                                <svg className="Rectangle_2057_er_si">
                                    <linearGradient id="Rectangle_2057_er" spreadMethod="pad" x1="0.066" x2="0.79" y1="0" y2="1.3">
                                        <stop offset="0" stop-color="#000" stop-opacity="1"></stop>
                                        <stop offset="1" stop-color="#000" stop-opacity="1"></stop>
                                    </linearGradient>
                                    <rect id="Rectangle_2057_er_si" rx="7" ry="7" x="0" y="0" width="469" height="48">
                                    </rect>
                                </svg>
                            </div>
                            <form onSubmit={submit} >
                                <div id="Group_3971_si">
                                    <div id="Group_3858_si">

                                        <input id="Input_Email_Address_si" type="email" name="email" placeholder="Email Address" value={formValues.email} required
                                            onChange={handleChange} onKeyDown={handleKeyDown}

                                        />
                                        <div id="Email__si">
                                            <span>Email Address</span>
                                        </div>

                                    </div>
                                </div>
                                <div id="Group_4529_si">
                                    <div id="Group_3858_ey_si">
                                        <input id="Input_Password__si" type={showPassword ? 'text' : 'password'} name="password" placeholder="Password" required
                                            onChange={handleChange} onKeyDown={handleKeyDown} />
                                        <div id="Eye_icon_c_si" onClick={handleTogglePasswordVisibility}>
                                            {showPassword ? <BsEyeSlashFill style={{ color: 'rgb(118, 118, 118, 1)' }} /> : <BsEyeFill style={{ color: 'rgb(118, 118, 118, 1)' }} />}
                                        </div>
                                        <div id="Password__si">
                                            <span>Password </span>
                                        </div>

                                    </div>
                                    <NavLink to="/password-reset">
                                        <div id="forgot_your_password__si">
                                            <span>forgot your password? </span>
                                        </div>
                                    </NavLink>
                                </div>
                                <div id="Sign_in_si" onClick={submit} >
                                    <span>Sign in</span>
                                </div>
                            </form>

                            <div id="Group_4530_si">
                                <div id="Dont_have_an_account_si">
                                    <span>Don't have an account?</span>
                                </div>
                                <a href="https://app.chpter.co/auth/register">
                                    <div id="Sign_up_si">
                                        <span>Sign up</span>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div id="Group_4528_si">
                            <div id="Group_3168_si">
                                <NavLink to={'/homepage'}>
                                    <div id="chpter_si">
                                        <span>chpter.</span>
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                        <div id="Group_4541_si">
                            <div id="Group_3168_fb_si">
                                <div id="Welcome_back_Your_customers_ar_si">
                                    <span>Welcome back.<br />Your customers are waiting . . .</span>
                                </div>
                            </div>
                        </div>
                        <div id="Make_your_business_stand_out_si">
                            <span>Make your business stand out.</span>
                        </div>
                        <div id="Group_4531_si">
                            <div id="chpter_ff_si">
                                <span>chpter.</span>
                            </div>
                            <div id="Contact_si">
                                <span>Contact</span>
                            </div>
                            <div id="Privacy_Policy_si">
                                <span>Privacy Policy</span>
                            </div>
                            <div id="Group_4532_si">
                                <div id="Layer_2">
                                    <div id="radio-button-off_si">
                                        <svg className="Rectangle_2094_si">
                                            <rect id="Rectangle_2094_si" rx="0" ry="0" x="0" y="0" width="15" height="15">
                                            </rect>
                                        </svg>
                                    </div>
                                </div>
                                <div id="c_si">
                                    <span>©</span>
                                </div>
                            </div>
                        </div>
                    </div>
                )
                }
            </MediaQuery>



            <MediaQuery maxWidth={1000}>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick={false}
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    toastClassName="custom-toast"
                    className="toast-container"
                    style={{
                        position: "fixed",
                        color: "white",
                        top: "10%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        height: "10vh",
                        width: "70vw",
                    }}
                />


                {loading ? (<BouncingDotsLoaderMobile></BouncingDotsLoaderMobile>
                ) : (

                    <div className="mobilesignin_si_mob">
                        <div className="mobilesignin-inner_si_mob">
                            <div className="group-wrapper_si_mob">
                                <div className="group-wrapper_si_mob">
                                    <div className="signintemplate_si_mob">
                                        <div className="header_si_mob">
                                            <div className="payment-detail-wrapper_si_mob">
                                                <div className="payment-detail_si_mob">chpter.</div>
                                            </div>
                                        </div>
                                        <div className="welcomeback_si_mob">
                                            <div className="welcome-back-wrapper_si_mob">
                                                <div className="welcome-back_si_mob">Welcome back.</div>
                                            </div>
                                            <div className="your-customers-are-waiting-wrapper_si_mob">
                                                <div className="mobilesignin-inner_si_mob">
                                                    Your customers are waiting.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="title_si_mob">
                                            <div className="payment-detail_si_mob">Sign in</div>
                                        </div>
                                        <div className="inputfields_si_mob">
                                            <div className="inputname-parent_si_mob">
                                                <div className="inputname_si_mob">Email</div>
                                                <input
                                                    className="frame-child_si_mob"
                                                    type="email"
                                                    placeholder="Input Email Address"
                                                    name="email"
                                                    value={formValues.email} required
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="inputname-parent_si_mob">
                                                <div className="frame-group_si_mob">
                                                    <div className="inputname-wrapper_si_mob">
                                                        <div className="inputname_si_mob">Password</div>
                                                    </div>
                                                    <div className="forgot-your-password-wrapper_si_mob">
                                                        <NavLink to="/password-reset" style={{ textDecoration: "none" }} className="forgot-your-password_si_mob">{`forgot your password? `}</NavLink>
                                                        <div id="Eye_icon_si_mob" onClick={handleTogglePasswordVisibility}>
                                                            {showPassword ? <BsEyeSlashFill style={{ color: 'rgb(118, 118, 118, 1)' }} /> : <BsEyeFill style={{ color: 'rgb(118, 118, 118, 1)' }} />}
                                                        </div>
                                                    </div>
                                                </div>
                                                <input
                                                    className="frame-child_si_mob"
                                                    type={showPassword ? 'text' : 'password'}
                                                    placeholder="Input Password"
                                                    name="password"
                                                    value={formValues.password} required
                                                    onChange={handleChange}
                                                />

                                            </div>
                                            <div className="submitbutton_si_mob">
                                                <div className="submitbutton-inner_si_mob">
                                                    <div className="sign-in-wrapper_si_mob" onClick={submit}>
                                                        <div className="payment-detail1_si_mob">Sign in</div>
                                                    </div>
                                                </div>
                                                <div className="dont-have-an-account-parent_si_mob">
                                                    <div className="dont-have-an_si_mob">Don't have an account?</div>
                                                    <a href="https://app.chpter.co/auth/register" style={{ textDecoration: "none" }} className="sign-up_si_mob">
                                                        Sign up
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                )
                }

            </MediaQuery>

        </div>
    )
}

export default Login


// Get Payment Links 
export async function get_paymentlinks(){

  const response = await fetch(process.env.REACT_APP_CHPTER_API_URL+"/paymentlinks", {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer "+localStorage.getItem("JWT"),
        'Accept': 'application/json',
      },
    });
  const data = await response.json();
  console.log(data.status);

  if(data.status === 401){
    //Redirect to login Token expired
    window.location.href = '/login';

  }    
  if(data.status === 200){
      return data.paymentlinks;
  }

  return [];
}


// Get Payment Link Detail
export async function get_paymentlink_detail(paymentlink_slug: string | undefined){

const response = await fetch(process.env.REACT_APP_CHPTER_API_URL+"/paymentlink/" + paymentlink_slug , {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': "Bearer "+localStorage.getItem("JWT"),
      'Accept': 'application/json',
    },
  });
const data = await response.json();
console.log(data.status);

if(data.status === 401){
  //Redirect to login Token expired
  window.location.href = '/login';
}    
if(data.status === 200){
    return data.paymentlink;
}

return [];
}



// Get Payment Link Checkout Detail
export async function get_paymentlink_checkout_detail(paymentlink_slug: string | undefined){

  const response = await fetch(process.env.REACT_APP_CHPTER_API_URL+"/paymentlink/details/" + paymentlink_slug , {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    });
  const data = await response.json();
  console.log(data);

  if(data.status === 200){
      return data.paymentlink;
  }
  
  return [];
  }
  
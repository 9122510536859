import axios from 'axios';

// Get Branding Info
export async function get_branding_info() {

  const response = await fetch(process.env.REACT_APP_CHPTER_API_URL + "/merchants/branding", {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("JWT"),
      'Accept': 'application/json',
    },
  });
  const data = await response.json();
  console.log(data.status);

  if (data.status === 401) {
    //Redirect to login Token expired
    window.location.replace('/login');

  }

  if (data.status === 200) {
    return data.branding;
  }

  return [];
}



// Get Link Navigation Info
export async function get_linkbasket_navigation(brand_alias: string | undefined) {

  const response = await fetch(process.env.REACT_APP_CHPTER_API_URL + "/merchants/nocodenavigation/" + brand_alias, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
  });
  const data = await response.json();
  console.log('get_linkbasket_navigation', data.status);

  if (data.status === 401) {
    //Redirect to login Token expired

  }

  if (data.status === 200) {
    return data.link_basket_navigation;
  }

  return [];
}



// Get Link Navigation Info
export async function get_branding_navigation() {

  const response = await fetch(process.env.REACT_APP_CHPTER_API_URL + "/merchants/branding", {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("JWT"),
        'Accept': 'application/json',
      },
  });
  const data = await response.json();

  if (data.status === 401) {
    //Redirect to login Token expired
    window.location.replace('/login');

  }

  if (data.status === 200) {

      const branding_response = await fetch(process.env.REACT_APP_CHPTER_API_URL + "/merchants/nocodenavigation/" + data.branding.brand_alias, {
        headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
        },
      });

      const branding_data = await branding_response.json();
      console.log('branding_data branding_data ::::', branding_data);
      console.log(branding_data.status);

      if(data.status === 401){
          //Redirect to login Token expired
          window.location.replace('/login');
      }
      
      if(data.status === 200){
          return branding_data.link_basket_navigation;
      }
      
    }

  return [];

}




import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import '../../styles/Wallet/payoutresponse.css'
import MediaQuery from 'react-responsive';



const Wallet = () => {
    const location = useLocation();
    const PayoutDetails = location.state.PayoutDetails
    const PayoutType = location.state.PayoutType
    const WithdrawalAmount = location.state.WithdrawalAmount
    const HashedPhoneNumber = location.state.HashedPhoneNumber
    const HashedBankAccountNumber = location.state.HashedBankAccountNumber
    const selectedBankAccount = location.state.selectedBankAccount
    const selectedDivMob = location.state.selectedDivMob
    const mobiledetails=location.state.mobiledetails


    return (
        <>

            <MediaQuery maxWidth={1000}>

                <div className="withdrawalcomplete_wwss_mob">
                    <div className="withdrawalcomplete-inner_wwss_mob">
                        <div className="group-wrapper_wwss_mob">
                            <div className="group-wrapper_wwss_mob">
                                <div className="complete_wwss_mob">
                                    <div className="header_wwss_mob">
                                        <div className="frame-parent_wwss_mob">
                                            {/* <div className="group-container_wwss_mob">
                                                <div className="rectangle-parent_wwss_mob">
                                                    <div className="group-child_wwss_mob" />
                                                    <img
                                                        className="path-9390-icon_wwss_mob"
                                                        alt=""
                                                        src="/path-9390.svg"
                                                    />
                                                </div>
                                            </div> */}
                                            <div className="payment-detail_wwss_mob">
                                                Withdrawal Request Complete!
                                            </div>
                                        </div>
                                    </div>
                                    <div className="checkmark-circle-2-wrapper_wwss_mob">
                                        {/* <img
                                            className="checkmark-circle-2-icon_wwss_mob"
                                            alt=""
                                            src="/checkmarkcircle2.svg"
                                        /> */}
                                          <div className="checkmark-circle-2-icon_wwss_mob">
                                <div id="checkmark-circle-2_wwss">
                                    <svg className="Rectangle_2089_wwss">
                                        <rect id="Rectangle_2089_wwss" rx="0" ry="0" x="0" y="0" width="194" height="194">
                                        </rect>
                                    </svg>
                                    <svg className="Path_9060_wwss" viewBox="2 2 163.357 163.357">
                                        <path id="Path_9060_wwss" d="M 83.67869567871094 2.000000238418579 C 38.56880187988281 2.000000238418579 2.000000238418579 38.56880569458008 2.000000238418579 83.67869567871094 C 2.000000238418579 128.7886199951172 38.56880569458008 165.3573913574219 83.67869567871094 165.3573913574219 C 128.7886199951172 165.3573913574219 165.3573913574219 128.78857421875 165.3573913574219 83.67869567871094 C 165.3573913574219 38.56880187988281 128.78857421875 2.000000238418579 83.67869567871094 2.000000238418579 Z M 83.67869567871094 149.0216522216797 C 47.59078216552734 149.0216522216797 18.33574104309082 119.7666244506836 18.33574104309082 83.67869567871094 C 18.33574104309082 47.59078216552734 47.59078598022461 18.33574104309082 83.67869567871094 18.33574104309082 C 119.7666244506836 18.33574104309082 149.0216522216797 47.59078598022461 149.0216522216797 83.67869567871094 C 149.0216522216797 119.7666244506836 119.7666244506836 149.0216522216797 83.67869567871094 149.0216522216797 Z">
                                        </path>
                                    </svg>
                                    <svg className="Path_9061_wwss" viewBox="7.499 7.994 73.489 65.393">
                                        <path id="Path_9061_wwss" d="M 66.31731414794922 11.22909736633301 L 35.44277191162109 52.06844329833984 L 22.12914276123047 34.83424377441406 C 19.35488319396973 31.27055549621582 14.21696662902832 30.63060188293457 10.65328407287598 33.40486145019531 C 7.089601993560791 36.17913055419922 6.449650287628174 41.31703948974609 9.223909378051758 44.88071441650391 L 29.07183837890625 70.28277587890625 C 30.63043212890625 72.25524139404297 33.01054763793945 73.40010833740234 35.52445220947266 73.38658142089844 C 38.05238342285156 73.3804931640625 40.43503952026367 72.20426940917969 41.97706604003906 70.20109558105469 L 79.30424499511719 21.19388580322266 C 82.05593872070312 17.5851001739502 81.36112976074219 12.42889404296875 77.75234222412109 9.677190780639648 C 74.1435546875 6.925485610961914 68.98735809326172 7.620294570922852 66.23563385009766 11.22908210754395 Z">
                                        </path>
                                    </svg>
                                </div>
                            </div>
                                    </div>

                                    {PayoutType === "Bank" ? (
                                        <>


                                            <div className="payoutmethod_wwss_mob">
                                                <div className="add-a-new-product-wrapper_wwss_mob">
                                                    <div className="add-a-new_wwss_mob">Destination</div>
                                                </div>
                                            </div>
                                            <div className="complete-inner_wwss_mob">
                                                <div className="frame-wrapper_wwss_mob">
                                                    <div className="frame-container_wwss_mob">
                                                        <div className="frame-div_wwss_mob">
                                                            <div className="frame-group_wwss_mob">
                                                                <div className="icons8-bank-96-wrapper_wwss_mob">
                                                                    <img
                                                                        className="icons8-bank-96_wwss_mob"
                                                                        alt=""
                                                                        src="media/icons8-bank-96.png"
                                                                    />
                                                                </div>
                                                                <div className="stardard-chartered-bank-parent_wwss_mob">
                                                                    <div className="stardard-chartered-bank-container_wwss_mob">
                                                                        <span className="star_wwss_mob">{selectedBankAccount.bank_account_name}</span>
                                                                       
                                                                    </div>
                                                                    <div className="account-parent_wwss_mob">
                                                                        <div className="stardard-chartered-bank-container_wwss_mob">
                                                                            Account
                                                                        </div>
                                                                        <div className="div_wwss_mob">{`${selectedBankAccount.bank_account_number?.slice(0, 4)}****${selectedBankAccount.bank_account_number.slice(-4)}`}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="payoutamount_wwss_mob">
                                                <div className="add-a-new-product-wrapper_wwss_mob">
                                                    <div className="add-a-new_wwss_mob">Payout Amount</div>
                                                </div>
                                            </div>
                                            <div className="complete-child_wwss_mob">
                                                <div className="frame-wrapper1_wwss_mob">
                                                    <div className="add-a-new-product-parent_wwss_mob">
                                                        <div className="stardard-chartered-bank-container_wwss_mob">KES</div>
                                                        <div className="add-a-new3_wwss_mob">{WithdrawalAmount}</div>
                                                    </div>
                                                </div>
                                            </div>

                                        </>
                                    ) : (
                                        <>

                                            <div className="payoutmethod_wwss_mob">
                                                <div className="add-a-new-product-wrapper_wwss_mob">
                                                    <div className="add-a-new_wwss_mob">Destination</div>
                                                </div>
                                            </div>
                                            <div className="complete-inner_wwss_mob">
                                                <div className="frame-wrapper_wwss_mob">
                                                    <div className="frame-container_wwss_mob">
                                                        <div className="frame-div_wwss_mob">
                                                            <div className="frame-group_wwss_mob">
                                                                <div className="icons8-bank-96-wrapper_wwss_mob">
                                                                    <img
                                                                        className="icons8-bank-96_wwss_mob"
                                                                        alt=""
                                                                        src="media/n_024px-M-PESA_LOGO-01svg.png" 
                                                                    />
                                                                </div>
                                                                <div className="stardard-chartered-bank-parent_wwss_mob">
                                                                    <div className="stardard-chartered-bank-container_wwss_mob">
                                                                        <span className="star_wwss_mob">MOBILE MONEY WALLET</span>
                                                                        
                                                                    </div>
                                                                    <div className="account-parent_wwss_mob">
                                                                        <div className="stardard-chartered-bank-container_wwss_mob">
                                                                            Account
                                                                        </div>
                                                                        <div className="div_wwss_mob">{`${PayoutDetails?.mobile_number.slice(0, 4)}****${PayoutDetails?.mobile_number.slice(-4)}`}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="payoutamount_wwss_mob">
                                                <div className="add-a-new-product-wrapper_wwss_mob">
                                                    <div className="add-a-new_wwss_mob">Payout Amount</div>
                                                </div>
                                            </div>
                                            <div className="complete-child_wwss_mob">
                                                <div className="frame-wrapper1_wwss_mob">
                                                    <div className="add-a-new-product-parent_wwss_mob">
                                                        <div className="stardard-chartered-bank-container_wwss_mob">KES</div>
                                                        <div className="add-a-new3_wwss_mob">{WithdrawalAmount}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>

                                    )}



                                    <div className="footer_wwss_mob">
                                    <NavLink to={'/wallet'}  style={{ textDecoration: 'none',color:"white" }} className="save-changes-wrapper_wwss_mob">
                                            <div className="withdrawalcomplete-inner_wwss_mob">PROCEED</div>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>

            <MediaQuery minWidth={1001}>
                <main className="form-signin">
                    <div id="WalletWithdrawSuccessful_wwss">
                        <div id='Group_6621_wwss'>
                            <div id="All_wwss">
                                <span>Withdrawal Request Complete!</span>
                            </div>
                            <div id="Layer_2_wwss">
                                <div id="checkmark-circle-2_wwss">
                                    <svg className="Rectangle_2089_wwss">
                                        <rect id="Rectangle_2089_wwss" rx="0" ry="0" x="0" y="0" width="194" height="194">
                                        </rect>
                                    </svg>
                                    <svg className="Path_9060_wwss" viewBox="2 2 163.357 163.357">
                                        <path id="Path_9060_wwss" d="M 83.67869567871094 2.000000238418579 C 38.56880187988281 2.000000238418579 2.000000238418579 38.56880569458008 2.000000238418579 83.67869567871094 C 2.000000238418579 128.7886199951172 38.56880569458008 165.3573913574219 83.67869567871094 165.3573913574219 C 128.7886199951172 165.3573913574219 165.3573913574219 128.78857421875 165.3573913574219 83.67869567871094 C 165.3573913574219 38.56880187988281 128.78857421875 2.000000238418579 83.67869567871094 2.000000238418579 Z M 83.67869567871094 149.0216522216797 C 47.59078216552734 149.0216522216797 18.33574104309082 119.7666244506836 18.33574104309082 83.67869567871094 C 18.33574104309082 47.59078216552734 47.59078598022461 18.33574104309082 83.67869567871094 18.33574104309082 C 119.7666244506836 18.33574104309082 149.0216522216797 47.59078598022461 149.0216522216797 83.67869567871094 C 149.0216522216797 119.7666244506836 119.7666244506836 149.0216522216797 83.67869567871094 149.0216522216797 Z">
                                        </path>
                                    </svg>
                                    <svg className="Path_9061_wwss" viewBox="7.499 7.994 73.489 65.393">
                                        <path id="Path_9061_wwss" d="M 66.31731414794922 11.22909736633301 L 35.44277191162109 52.06844329833984 L 22.12914276123047 34.83424377441406 C 19.35488319396973 31.27055549621582 14.21696662902832 30.63060188293457 10.65328407287598 33.40486145019531 C 7.089601993560791 36.17913055419922 6.449650287628174 41.31703948974609 9.223909378051758 44.88071441650391 L 29.07183837890625 70.28277587890625 C 30.63043212890625 72.25524139404297 33.01054763793945 73.40010833740234 35.52445220947266 73.38658142089844 C 38.05238342285156 73.3804931640625 40.43503952026367 72.20426940917969 41.97706604003906 70.20109558105469 L 79.30424499511719 21.19388580322266 C 82.05593872070312 17.5851001739502 81.36112976074219 12.42889404296875 77.75234222412109 9.677190780639648 C 74.1435546875 6.925485610961914 68.98735809326172 7.620294570922852 66.23563385009766 11.22908210754395 Z">
                                        </path>
                                    </svg>
                                </div>
                            </div>
                            {PayoutType === "Bank" ? (
                                <div id="Group_6791_wwss">
                                    <div id="Group_6661_wwss">
                                        <div id="Group_6654_wwss">
                                            <svg className="Rectangle_2155_wwss">
                                                <rect id="Rectangle_2155_wwss" rx="5" ry="5" x="0" y="0" width="326" height="97">
                                                </rect>
                                            </svg>
                                            <div id="Group_5402_wwss">
                                                <div id="Stardard_Chartered_Bank___wwss">
                                                    <span>{PayoutDetails.bank_account_name} </span>
                                                </div>
                                                <div id="Account_wwss">
                                                    <span>Account</span>
                                                </div>
                                                <div id="n_01277839173900_wwss">
                                                    <span>{HashedBankAccountNumber}</span>
                                                </div>
                                            </div>
                                            <img id="icons8-bank-96_wwss" src="media/icons8-bank-96.png" />
                                        </div>
                                        <div id="All_e_wwss">
                                            <span>Payout Method</span>
                                        </div>
                                    </div>
                                    <div id="Group_6790_wwss">
                                        <svg className="Rectangle_2429_wwss">
                                            <rect id="Rectangle_2429_wwss" rx="5" ry="5" x="0" y="0" width="326" height="97">
                                            </rect>
                                        </svg>
                                        <div id="Group_6656_wwss">
                                            <div id="All_fa_wwss">
                                                <span>{WithdrawalAmount}</span>
                                            </div>
                                            <div id="All_fb_wwss">
                                                <span>KES.</span>
                                            </div>
                                        </div>
                                        <div id="All_fc_wwss">
                                            <span>Payout Amount</span>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div id="Group_6791_wwss">
                                    <div id="Group_6661_wwss">
                                        <div id="Group_6654_wwss">
                                            <svg className="Rectangle_2155_wwss">
                                                <rect id="Rectangle_2155_wwss" rx="5" ry="5" x="0" y="0" width="326" height="97">
                                                </rect>
                                            </svg>
                                            <div id="Group_5402_wwss">
                                                <div id="Stardard_Chartered_Bank___wwss">
                                                    <span>MOBILE MONEY WALLET</span>
                                                </div>
                                                <div id="Account_wwss">
                                                    <span>Account</span>
                                                </div>
                                                <div id="n_01277839173900_wwss">
                                                    <span>{`${PayoutDetails?.mobile_number.slice(0, 4)}****${PayoutDetails?.mobile_number.slice(-4)}`}</span>
                                                </div>
                                            </div>
                                            <img id="icons8-bank-96_wwss" src="media/n_024px-M-PESA_LOGO-01svg.png" />
                                        </div>
                                        <div id="All_e_wwss">
                                            <span>Payout Method</span>
                                        </div>
                                    </div>
                                    <div id="Group_6790_wwss">
                                        <svg className="Rectangle_2429_wwss">
                                            <rect id="Rectangle_2429_wwss" rx="5" ry="5" x="0" y="0" width="326" height="97">
                                            </rect>
                                        </svg>
                                        <div id="Group_6656_wwss">
                                            <div id="All_fa_wwss">
                                                <span>{WithdrawalAmount}</span>
                                            </div>
                                            <div id="All_fb_wwss">
                                                <span>KES.</span>
                                            </div>
                                        </div>
                                        <div id="All_fc_wwss">
                                            <span>Payout Amount</span>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div id="Group_6658_wwss">
                                <NavLink to={'/wallet'} className="nav-link" style={{ textDecoration: 'none' }}>
                                    <div id="Group_4741_wwss">
                                        <div id="Group_3969_wwss">
                                            <svg className="Rectangle_2057_wwss">
                                                <rect id="Rectangle_205_wwss" rx="5" ry="5" x="0" y="0" width="125" height="30">
                                                </rect>
                                            </svg>
                                        </div>
                                        <div id="Group_4628_wwss">
                                            <div id="PROCEED_wwss">
                                                <span>PROCEED</span>
                                            </div>
                                        </div>
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </main>

            </MediaQuery>

        </>
    )
};

export default Wallet;

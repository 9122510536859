import { SyntheticEvent, useState } from "react";
import axios from "axios";
import { NavLink } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import '../../styles/Welcome/passwordchange.css';
import { ToastContainer, toast } from 'react-toastify';
import { BsEyeFill } from 'react-icons/bs'
import { BsEyeSlashFill } from 'react-icons/bs'
import NavBar_Mobile from "../../components/NavBar_Mobile";
import NavBarMobile from "../../components/NavBarMobile";
import 'react-toastify/dist/ReactToastify.css';
import '../../styles/Components/Toastify.css'

const PasswordChange = () => {
    const initialValues = { old_password: '', new_password: '', confirm_new_password: '' }
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState<Record<string, string>>({});
    const [redirect, setredirect] = useState(false)
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);


    const handleChange = (e: { target: { name: any; value: any; }; }) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value })

    }

    // Password Visibility
    const handleToggleOldPasswordVisibility = () => {
        setShowOldPassword(!showOldPassword);
    };
    const handleToggleNewPasswordVisibility = () => {
        setShowNewPassword(!showNewPassword);
    };
    const handleToggleConfirmNewPasswordVisibility = () => {
        setShowConfirmNewPassword(!showConfirmNewPassword);
    };


    const Validate = (Values: { old_password: any; new_password: any; confirm_new_password: any; }) => {
        const errors: any = {};
        setFormErrors(errors)

        if (!Values.old_password) {
            toast("Input Old Password", { type: "error" });
            errors.old_password = "Input Old Password";
        }
        else if (!Values.new_password) {
            toast("Input New Password", { type: "error" });
            errors.new_password = "Input New Password";
        }
        else if (Values.new_password.length < 8) {
            toast("Password must be at least 8 characters long", { type: "error" });
            errors.new_password = "Password must be at least 8 characters long";
        }
        else if (String(Values.confirm_new_password) !== String(Values.new_password)) {
            toast("Passwords don't match", { type: "error" });
            errors.confirm_new_password = "Passwords don't match";
        }

        setFormErrors(errors)
        if (Object.keys(errors).length > 0) {
            let errorMessage = "";
            for (const key in formErrors) {
                if (formErrors[key]) {
                    errorMessage += formErrors[key] + "\n";
                }
            }

        }
        return Object.keys(errors).length === 0;
    };



    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();
        const isValid = Validate(formValues);
        if (!isValid) {
            return;
        }

        // submit the form
        let api_url = process.env.REACT_APP_CHPTER_API_URL + '/merchants/password/change'
        let headers = {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("JWT"),
        };

        const data = JSON.stringify(formValues);

        await axios.put(api_url, data, { headers: headers })
            .then((response) => {

                if (response.status === 200) {
                    toast.success('Password changed successfully', {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                    });
                    setTimeout(() => {
                        setredirect(true);
                    }, 2000);
                }
            })
            .catch((error) => {
                // Error
                if (error.response) {

                    toast(error.response.data.message, { type: "error" });
                    if (error.response.status === 401) {
                        //Redirect to login Token expired
                        window.location.href = '/login';

                    }

                } else if (error.request) {
  
                } else {

                }
            });
    }

  

    const handleKeyDown = (event: React.SyntheticEvent<Element, Event>) => {
        const keyEvent = event as React.KeyboardEvent;
        if (keyEvent.key === "Enter") {
            submit(event);
        }
    };



    return (
        <>
            <MediaQuery minWidth={1001}>
                <ToastContainer position="top-right" autoClose={9000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false}
                    pauseOnFocusLoss draggable pauseOnHover toastClassName="custom-toast" className="toast-container" />
                <div id="PasswordChange_pc" style={{ left: "0px", transformOrigin: "0px 0px", transform: "scale(0.750)" }}>
                    <div id="Group_3972_pc">
                        <svg className="Rectangle_1922_pc">
                            <rect id="Rectangle_1922_pc" rx="15" ry="15" x="0" y="0" width="600" height="620">
                            </rect>
                        </svg>
                        <div id="All_pc">
                            <span>Change your Password</span>
                        </div>
                        <div id="Group_6789_pc">
                            {/* Old Password */}
                            <div id="Group_4529_op_pc">
                                <div id="Group_3858_ch_op_pc">
                                    <div >
                                        <input id="Password_op_pc" type={showOldPassword ? 'text' : 'password'} name="old_password" placeholder="Old Password" value={formValues.old_password} required
                                            onChange={handleChange} onKeyDown={handleKeyDown} />
                                        <div id="Eye_icon_op_pc" onClick={handleToggleOldPasswordVisibility}>
                                            {showOldPassword ? <BsEyeSlashFill style={{ color: 'rgb(118, 118, 118, 1)' }} /> : <BsEyeFill style={{ color: 'rgb(118, 118, 118, 1)' }} />}
                                        </div>
                                    </div>
                                    <div id="Password__op_pc">
                                        <span>Old Password </span>
                                    </div>
                                </div>
                            </div>
                            {/* New Password */}
                            <div id="Group_4529_pc">
                                <div id="Group_3858_ch_pc">
                                    <div >
                                        <input id="Password_pc" type={showNewPassword ? 'text' : 'password'} name="new_password" placeholder="New Password" value={formValues.new_password} required
                                            onChange={handleChange} onKeyDown={handleKeyDown} />
                                        <div id="Eye_icon_pc" onClick={handleToggleNewPasswordVisibility}>
                                            {showNewPassword ? <BsEyeSlashFill style={{ color: 'rgb(118, 118, 118, 1)' }} /> : <BsEyeFill style={{ color: 'rgb(118, 118, 118, 1)' }} />}
                                        </div>
                                    </div>
                                    <div id="Password__pc">
                                        <span>New Password </span>
                                    </div>
                                </div>
                            </div>
                            {/* Confirm New Password */}
                            <div id="Group_4529_cp_pc">
                                <div id="Group_3858_ch_cp_pc">
                                    <div >
                                        <input id="ConfirmPassword_pc" type={showConfirmNewPassword ? 'text' : 'password'} name="confirm_new_password" placeholder="Confirm New Password" value={formValues.confirm_new_password} required
                                            onChange={handleChange} onKeyDown={handleKeyDown} />
                                    </div>
                                    <div id="Eye_icon_c_pc" onClick={handleToggleConfirmNewPasswordVisibility}>
                                        {showConfirmNewPassword ? <BsEyeSlashFill style={{ color: 'rgb(118, 118, 118, 1)' }} /> : <BsEyeFill style={{ color: 'rgb(118, 118, 118, 1)' }} />}
                                    </div>
                                    <div id="Password_cp_pc">
                                        <span>Confirm New Password </span>
                                    </div>
                                </div>
                            </div>
                            {/* Button */}
                            <div id="Group_3969_pc" onClick={submit}>
                                <svg className="Rectangle_2057_ca_pc">
                                    <linearGradient id="Rectangle_2057_ca" spreadMethod="pad" x1="0.066" x2="0.79" y1="0" y2="1.3">
                                        <stop offset="0" stop-color="#000" stop-opacity="1"></stop>
                                        <stop offset="1" stop-color="#000" stop-opacity="1"></stop>
                                    </linearGradient>
                                    <rect id="Rectangle_2057_ca_pc" rx="7" ry="7" x="0" y="0" width="469" height="48">
                                    </rect>
                                </svg>
                                <div id="Change_Password_button_pc">Change Password</div>

                            </div>

                        </div>
                        <NavLink to="/settings-profile">
                            <div id="Group_4742__pc">
                                <div id="Group_4628_fk__pc">
                                    <div id="CANCEL__pc">
                                        <span>Back To Settings</span>
                                    </div>
                                </div>
                            </div>
                        </NavLink>

                    </div>
                    <div id="Group_4528_pc">
                        <div id="Group_3168_pc">
                            <div id="chpter_pc">
                                <span>chpter.</span>
                            </div>
                        </div>
                    </div>
                  
                </div>
            </MediaQuery>


            <MediaQuery maxWidth={1000}>
                <ToastContainer position="top-center" autoClose={5000} hideProgressBar={true} newestOnTop={false} closeOnClick={true}
                    pauseOnFocusLoss={true} draggable={true} pauseOnHover={true} toastClassName="custom-toast-mobile" className="toast-container-mobile" />




                <div className="mobilesignin_si_mob">
                    <div className="mobilesignin-inner_si_mob">
                        <div className="group-wrapper_si_mob">
                            <div className="group-wrapper_si_mob">
                                <div className="signintemplate_si_mob">

                                    <NavBarMobile></NavBarMobile>


                                    <div className="title_si_mob">
                                        <div className="payment-detail_si_mob">Change your password</div>
                                    </div>

                                    <div className="inputfields_si_mob">
                                        {/* Old Password */}

                                        <div className="inputname-parent_si_mob">
                                            <div className="frame-group_si_mob">
                                                <div className="inputname-wrapper_si_mob">
                                                    <div className="inputname_si_mob">Old Password</div>
                                                </div>
                                                <div className="forgot-your-password-wrapper_si_mob">
                                                    <div id="Eye_icon_si_mob" onClick={handleToggleOldPasswordVisibility}>
                                                        {showOldPassword ? <BsEyeSlashFill style={{ color: 'rgb(118, 118, 118, 1)' }} /> : <BsEyeFill style={{ color: 'rgb(118, 118, 118, 1)' }} />}
                                                    </div>
                                                </div>
                                            </div>
                                            <input
                                                className="frame-child_si_mob"
                                                type={showOldPassword ? 'text' : 'password'}
                                                name="old_password"
                                                placeholder="Old Password"
                                                value={formValues.old_password}
                                                required onChange={handleChange}
                                                onKeyDown={handleKeyDown}
                                            />

                                        </div>


                                        {/* New Password */}
                                        <div className="inputname-parent_si_mob">
                                            <div className="frame-group_si_mob">
                                                <div className="inputname-wrapper_si_mob">
                                                    <div className="inputname_si_mob">New Password</div>
                                                </div>
                                                <div className="forgot-your-password-wrapper_si_mob">
                                                    <div id="Eye_icon_si_mob" onClick={handleToggleNewPasswordVisibility}>
                                                        {showNewPassword ? <BsEyeSlashFill style={{ color: 'rgb(118, 118, 118, 1)' }} /> : <BsEyeFill style={{ color: 'rgb(118, 118, 118, 1)' }} />}
                                                    </div>
                                                </div>
                                            </div>
                                            <input
                                                className="frame-child_si_mob"
                                                type={showNewPassword ? 'text' : 'password'}
                                                name="new_password"
                                                placeholder="New Password"
                                                value={formValues.new_password}
                                                required onChange={handleChange}
                                                onKeyDown={handleKeyDown}
                                            />

                                        </div>
                                        {/* Confirm New Password */}


                                        <div className="inputname-parent_si_mob">
                                            <div className="frame-group_si_mob">
                                                <div className="inputname-wrapper_si_mob">
                                                    <div className="inputname_si_mob">Confirm New Password</div>
                                                </div>
                                                <div className="forgot-your-password-wrapper_si_mob">
                                                    <div id="Eye_icon_si_mob" onClick={handleToggleConfirmNewPasswordVisibility}>
                                                        {showConfirmNewPassword ? <BsEyeSlashFill style={{ color: 'rgb(118, 118, 118, 1)' }} /> : <BsEyeFill style={{ color: 'rgb(118, 118, 118, 1)' }} />}
                                                    </div>
                                                </div>
                                            </div>
                                            <input
                                                className="frame-child_si_mob"
                                                type={showConfirmNewPassword ? 'text' : 'password'}
                                                name="confirm_new_password"
                                                placeholder="Confirm New Password "
                                                value={formValues.confirm_new_password}
                                                required onChange={handleChange}
                                                onKeyDown={handleKeyDown}
                                            />

                                        </div>

                                        {/* Submit Button */}

                                        <div className="submitbutton_si_mob">
                                            <div className="submitbutton-inner_si_mob">
                                                <div className="sign-in-wrapper_si_mob" onClick={submit}>
                                                    <div className="payment-detail1_si_mob">CHANGE PASSWORD</div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
















            </MediaQuery>
        </>
    )
}

export default PasswordChange;
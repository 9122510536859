import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import '../../styles/Developers/servicestatus.css'
import '../../styles/Components/NavBar.css'
import '../../styles/Components/UserRibbon.css'
import '../../styles/Components/HotLinks_Developers.css'
import Wrapper from "../../components/Wrapper";
import HotlinksDevelopers from '../../components/HotLinks_Developers';


const ServiceStatus = () => {
    return (
        <>
            <main className="form-signin">
                <div id="DevelopersServiceStatus_devstat">
                    <div id="Group_6821_devstat">
                        <svg className="Rectangle_2449_devstat">
                            <rect id="Rectangle_2449_devstat" rx="10" ry="10" x="0" y="0" width="357" height="96">
                            </rect>
                        </svg>
                        <div id="Group_6820_devstat">
                            <div id="Accounts_API_devstat">
                                <span>Accounts API</span>
                            </div>
                            <div id="Group_6819_devstat">
                                <div id="Group_6647_devstat">
                                    <div id="Operational_devstat">
                                        <span>Operational</span>
                                    </div>
                                </div>
                                <svg className="Ellipse_782_e_devstat">
                                    <radialGradient id="Ellipse_782_e_devstat" spreadMethod="pad" x1="0" x2="0" y1="0" y2="0">
                                        <stop offset="0" stop-color="#8ffa8f" stop-opacity="1"></stop>
                                        <stop offset="0.4975" stop-color="#3bff3b" stop-opacity="1"></stop>
                                        <stop offset="1" stop-color="#06f206" stop-opacity="1"></stop>
                                    </radialGradient>
                                    <ellipse id="Ellipse_782_e_devstat" rx="5" ry="5" cx="5" cy="5">
                                    </ellipse>
                                </svg>
                            </div>
                            <div id="Last_updated_20_seconds_ago_devstat">
                                <span>Last updated 20 seconds ago….</span>
                            </div>
                        </div>
                    </div>
                    <div id="Group_6838_devstat">
                        <svg className="Rectangle_2449_e_devstat">
                            <rect id="Rectangle_2449_e_devstat" rx="10" ry="10" x="0" y="0" width="357" height="96">
                            </rect>
                        </svg>
                        <div id="Group_6820_e_devstat">
                            <div id="Accounts_API_e_devstat">
                                <span>Accounts API</span>
                            </div>
                            <div id="Group_6819_e_devstat">
                                <div id="Group_6647_e_devstat">
                                    <div id="Operational_e_devstat">
                                        <span>Operational</span>
                                    </div>
                                </div>
                                <svg className="Ellipse_782_fb_devstat">
                                    <radialGradient id="Ellipse_782_fb_devstat" spreadMethod="pad" x1="0" x2="0" y1="0" y2="0">
                                        <stop offset="0" stop-color="#8ffa8f" stop-opacity="1"></stop>
                                        <stop offset="0.4975" stop-color="#3bff3b" stop-opacity="1"></stop>
                                        <stop offset="1" stop-color="#06f206" stop-opacity="1"></stop>
                                    </radialGradient>
                                    <ellipse id="Ellipse_782_fb_devstat" rx="5" ry="5" cx="5" cy="5">
                                    </ellipse>
                                </svg>
                            </div>
                            <div id="Last_updated_20_seconds_ago_fc_devstat">
                                <span>Last updated 20 seconds ago….</span>
                            </div>
                        </div>
                    </div>
                    <div id="Group_6836_devstat">
                        <svg className="Rectangle_2449_fe_devstat">
                            <rect id="Rectangle_2449_fe_devstat" rx="10" ry="10" x="0" y="0" width="357" height="96">
                            </rect>
                        </svg>
                        <div id="Group_6820_ff_devstat">
                            <div id="Accounts_API_fg_devstat">
                                <span>Accounts API</span>
                            </div>
                            <div id="Group_6819_fh_devstat">
                                <div id="Group_6647_fi_devstat">
                                    <div id="Operational_fj_devstat">
                                        <span>Operational</span>
                                    </div>
                                </div>
                                <svg className="Ellipse_782_fl_devstat">
                                    <radialGradient id="Ellipse_782_fl_devstat" spreadMethod="pad" x1="0" x2="0" y1="0" y2="0">
                                        <stop offset="0" stop-color="#8ffa8f" stop-opacity="1"></stop>
                                        <stop offset="0.4975" stop-color="#3bff3b" stop-opacity="1"></stop>
                                        <stop offset="1" stop-color="#06f206" stop-opacity="1"></stop>
                                    </radialGradient>
                                    <ellipse id="Ellipse_782_fl_devstat" rx="5" ry="5" cx="5" cy="5">
                                    </ellipse>
                                </svg>
                            </div>
                            <div id="Last_updated_20_seconds_ago_fm_devstat">
                                <span>Last updated 20 seconds ago….</span>
                            </div>
                        </div>
                    </div>
                    <div id="Group_6839_devstat">
                        <svg className="Rectangle_2449_fo_devstat">
                            <rect id="Rectangle_2449_fo_devstat" rx="10" ry="10" x="0" y="0" width="357" height="96">
                            </rect>
                        </svg>
                        <div id="Group_6820_fp_devstat">
                            <div id="Accounts_API_fq_devstat">
                                <span>Accounts API</span>
                            </div>
                            <div id="Group_6819_fr_devstat">
                                <div id="Group_6647_fs_devstat">
                                    <div id="Operational_ft_devstat">
                                        <span>Operational</span>
                                    </div>
                                </div>
                                <svg className="Ellipse_782_fv_devstat">
                                    <radialGradient id="Ellipse_782_fv_devstat" spreadMethod="pad" x1="0" x2="0" y1="0" y2="0">
                                        <stop offset="0" stop-color="#8ffa8f" stop-opacity="1"></stop>
                                        <stop offset="0.4975" stop-color="#3bff3b" stop-opacity="1"></stop>
                                        <stop offset="1" stop-color="#06f206" stop-opacity="1"></stop>
                                    </radialGradient>
                                    <ellipse id="Ellipse_782_fv_devstat" rx="5" ry="5" cx="5" cy="5">
                                    </ellipse>
                                </svg>
                            </div>
                            <div id="Last_updated_20_seconds_ago_fw_devstat">
                                <span>Last updated 20 seconds ago….</span>
                            </div>
                        </div>
                    </div>
                    <div id="Group_6835_devstat">
                        <svg className="Rectangle_2449_fy_devstat">
                            <rect id="Rectangle_2449_fy_devstat" rx="10" ry="10" x="0" y="0" width="357" height="96">
                            </rect>
                        </svg>
                        <div id="Group_6820_fz_devstat">
                            <div id="Accounts_API_f_devstat">
                                <span>Accounts API</span>
                            </div>
                            <div id="Group_6819_f_devstat">
                                <div id="Group_6647_f_devstat">
                                    <div id="Operational_f_devstat">
                                        <span>Operational</span>
                                    </div>
                                </div>
                                <svg className="Ellipse_782_ga_devstat">
                                    <radialGradient id="Ellipse_782_ga_devstat" spreadMethod="pad" x1="0" x2="0" y1="0" y2="0">
                                        <stop offset="0" stop-color="#8ffa8f" stop-opacity="1"></stop>
                                        <stop offset="0.4975" stop-color="#3bff3b" stop-opacity="1"></stop>
                                        <stop offset="1" stop-color="#06f206" stop-opacity="1"></stop>
                                    </radialGradient>
                                    <ellipse id="Ellipse_782_ga_devstat" rx="5" ry="5" cx="5" cy="5">
                                    </ellipse>
                                </svg>
                            </div>
                            <div id="Last_updated_20_seconds_ago_f_devstat">
                                <span>Last updated 20 seconds ago….</span>
                            </div>
                        </div>
                    </div>
                    <div id="Group_6837_devstat">
                        <svg className="Rectangle_2449_f_devstat">
                            <rect id="Rectangle_2449_f_devstat" rx="10" ry="10" x="0" y="0" width="357" height="96">
                            </rect>
                        </svg>
                        <div id="Group_6820_f_devstat">
                            <div id="Accounts_API_ga_devstat">
                                <span>Accounts API</span>
                            </div>
                            <div id="Group_6819_gb_devstat">
                                <div id="Group_6647_gc_devstat">
                                    <div id="Operational_gd_devstat">
                                        <span>Operational</span>
                                    </div>
                                </div>
                                <svg className="Ellipse_782_gf_devstat">
                                    <radialGradient id="Ellipse_782_gf_devstat" spreadMethod="pad" x1="0" x2="0" y1="0" y2="0">
                                        <stop offset="0" stop-color="#8ffa8f" stop-opacity="1"></stop>
                                        <stop offset="0.4975" stop-color="#3bff3b" stop-opacity="1"></stop>
                                        <stop offset="1" stop-color="#06f206" stop-opacity="1"></stop>
                                    </radialGradient>
                                    <ellipse id="Ellipse_782_gf_devstat" rx="5" ry="5" cx="5" cy="5">
                                    </ellipse>
                                </svg>
                            </div>
                            <div id="Last_updated_20_seconds_ago_gg_devstat">
                                <span>Last updated 20 seconds ago….</span>
                            </div>
                        </div>
                    </div>
                    <div id="Group_6862_devstat">
                        <div id="Past_Incidents_devstat">
                            <span>Past Incidents</span>
                        </div>
                        <div id="Group_6832_devstat">
                            <div id="Monday_24th_Oct_2022_120000AM_devstat">
                                <span>Monday, 24th Oct 2022 12:00:00AM</span>
                            </div>
                            <div id="Group_6829_devstat">
                                <svg className="Line_449_devstat" viewBox="0 0 1 194">
                                    <path id="Line_449_devstat" d="M 0 0 L 0 194">
                                    </path>
                                </svg>
                                <div id="Group_6827_devstat">
                                    <svg className="Ellipse_783_devstat">
                                        <ellipse id="Ellipse_783_devstat" rx="18" ry="18" cx="18" cy="18">
                                        </ellipse>
                                    </svg>
                                    <div id="Layer_2_devstat">
                                        <div id="checkmark_devstat">
                                            <svg className="Rectangle_2122_devstat">
                                                <rect id="Rectangle_2122_devstat" rx="0" ry="0" x="0" y="0" width="18" height="18">
                                                </rect>
                                            </svg>
                                            <svg className="Path_9092_devstat" viewBox="3.999 5.956 12.298 9.233">
                                                <path id="Path_9092_devstat" d="M 8.492274284362793 15.18946647644043 C 8.279788017272949 15.18878364562988 8.077116012573242 15.09994220733643 7.932625770568848 14.94414138793945 L 4.206743717193604 10.98060131072998 C 3.916711807250977 10.67151546478271 3.932157278060913 10.18583393096924 4.2412428855896 9.895801544189453 C 4.550328254699707 9.605769157409668 5.036009311676025 9.621214866638184 5.326041698455811 9.930300712585449 L 8.484606742858887 13.29586124420166 L 14.93206882476807 6.242752075195312 C 15.11035251617432 6.020743370056152 15.39699077606201 5.915829658508301 15.6764669418335 5.970292091369629 C 15.95594310760498 6.024755477905273 16.18221473693848 6.229621887207031 16.26408958435059 6.502328872680664 C 16.34596633911133 6.775036811828613 16.26995849609375 7.070656776428223 16.06669998168945 7.270052909851074 L 9.059587478637695 14.9364767074585 C 8.916444778442383 15.09511184692383 8.713593482971191 15.18681240081787 8.49993896484375 15.18946933746338 Z">
                                                </path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="Group_6828_devstat">
                                <svg className="Rectangle_2453_devstat">
                                    <rect id="Rectangle_2453_devstat" rx="10" ry="10" x="0" y="0" width="435" height="188">
                                    </rect>
                                </svg>
                                <div id="We_are_currently_experiencing__devstat">
                                    <span>We are currently experiencing issues with processing Mobile Money payouts.<br /><br />We are working to resolve the issue as soon as possible. We regret any inconvenience caused.</span>
                                </div>
                                <div id="n__hours_ago_devstat">
                                    <span>4 hours ago</span>
                                </div>
                                <div id="Accounts_API_gx_devstat">
                                    <span>Accounts API</span>
                                </div>
                            </div>
                        </div>
                        <div id="Group_6833_devstat">
                            <div id="Sunday_23th_Oct_2022_120000AM_devstat">
                                <span>Sunday, 23th Oct 2022 12:00:00AM</span>
                            </div>
                            <div id="Group_6831_devstat">
                                <svg className="Rectangle_2453_g_devstat">
                                    <rect id="Rectangle_2453_g_devstat" rx="10" ry="10" x="0" y="0" width="435" height="57">
                                    </rect>
                                </svg>
                                <div id="No_incidents_reported_devstat">
                                    <span>No incidents reported</span>
                                </div>
                            </div>
                        </div>
                        <div id="Group_6834_devstat">
                            <div id="Saturday_22th_Oct_2022_120000A_devstat">
                                <span>Saturday, 22th Oct 2022 12:00:00AM</span>
                            </div>
                            <div id="Group_6831_g_devstat">
                                <svg className="Rectangle_2453_ha_devstat">
                                    <rect id="Rectangle_2453_ha_devstat" rx="10" ry="10" x="0" y="0" width="435" height="57">
                                    </rect>
                                </svg>
                                <div id="No_incidents_reported_g_devstat">
                                    <span>No incidents reported</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <HotlinksDevelopers></HotlinksDevelopers>
            <Wrapper></Wrapper>
        </>
    )
};

export default ServiceStatus;


// Date Only Formatter i.e 26-08-2023
export const dateFormatter = (dateString:string) => {

  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');


  return `${day}-${month}-${year}`;
};

// Date Time Formatter i.e 26-08-2023 12:23
export const datetimeFormatter = (dateString:string) => {

    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    // const seconds = date.getSeconds().toString().padStart(2, '0');
  
    return `${day}-${month}-${year}  ${hours}:${minutes}`;
  };


// Date Formatter i.e 26 Aug, 2023
  export const dateFormatter_ = (dateString:string) => {
    const date = new Date(dateString);
    
  const day = date.getDate().toString().padStart(2, '0');
  const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);
  const year = date.getFullYear();

  return `${day} ${month}, ${year}`;
  };


// Date Formatter i.e 24 July, 16:45 PM
  export const datetimeFormatter_ = (dateString: string) => {
    const date = new Date(dateString);
  
    const day = date.getDate();
    const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);
    const hour = date.getHours() % 12 || 12;
    const minute = date.getMinutes().toString().padStart(2, '0');
    const period = date.getHours() >= 12 ? 'PM' : 'AM';
  
    return `${day} ${month}, ${hour}:${minute} ${period}`;
  }

  // Date Formatter i.e 24 July, 2023
  export const datetimeFormatter_year = (dateString: string) => {
    const date = new Date(dateString);

    const day = date.getDate().toString().padStart(2, '0');
    const month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date);
    const year = date.getFullYear().toString();
    
    return `${day} ${month}, ${year}`;
  }

// Time Formatter i.e 16:45 PM
  export const timeFormatter_ = (dateString: string) => {
    const date = new Date(dateString);
    
    const hour = date.getHours() % 12 || 12;
    const minute = date.getMinutes().toString().padStart(2, '0');
    const period = date.getHours() >= 12 ? 'PM' : 'AM';
  
    return `${hour}:${minute} ${period}`;
  }

// Time Formatter (24 Hour Clock) i.e 16:45
  export const timeFormatter_24 = (dateString: string) => {
    const date = new Date(dateString);
    
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');
    
    return `${hour}:${minute}`;
    }

  // Date Formatter i.e 24 July 2023, 2:45 PM
  export const datetimeFormatter_receipt = (dateString: string) => {
    const date = new Date(dateString);
  
    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }
    
    const day = date.getDate();
    const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);
    const year = date.getFullYear().toString();

    const hour = date.getHours() % 12 || 12;
    const minute = date.getMinutes().toString().padStart(2, '0');
    const period = date.getHours() >= 12 ? 'PM' : 'AM';
  
    return `${day} ${month} ${year} , ${hour}:${minute} ${period}`;
  }


  export const datetimeFormatter_human = (dateString: string) => {
    const date = new Date(dateString);
  
    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }
    
    const day = date.getDate();
    const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);
    const year = date.getFullYear().toString();

    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
  
    return `${day} ${month} ${year}, ${hours}:${minutes}`;
  }

  export const dateFormatter_subscription_human = (dateString: string) => {
    const parts = dateString.split('/');
    const day = parts[0];
    const month = parts[1];
    const year = parts[2];
  
    const date = new Date(`${month}/${day}/${year}`);
  
    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }
  
    const formattedMonth = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date);
  
    return `${day} ${formattedMonth} ${year}`;
  }
  
  export const dateTIMEFormatter_subscription_human = (dateString: string) => {
    const date = new Date(dateString);
    
    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }
    
    const day = date.getDate();
    const month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date);
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, '0');
    
    return `${day} ${month} ${year} ${formattedHours}:${formattedMinutes}${ampm}`;
  }


  export function calculateSubscriptionEndDate(start_date: Date, duration: number, interval: string) {
    let end_date = new Date(start_date.getTime()); // make a copy of the start date
    switch (interval) {
      case 'hourly':
        end_date.setTime(start_date.getTime() + (duration * 60 * 60 * 1000));
        break;
      case 'daily':
        end_date.setDate(start_date.getDate() + duration);
        break;
      case 'weekly':
        end_date.setDate(start_date.getDate() + (duration * 7));
        break;
      case 'monthly':
        end_date.setMonth(start_date.getMonth() + duration);
        break;
      case 'quarterly':
        end_date.setMonth(start_date.getMonth() + (duration * 3));
        break;
      case 'annually':
        end_date.setFullYear(start_date.getFullYear() + duration);
        break;
      default:
        throw new Error(`Invalid interval: ${interval}`);
    }
  
    // Format the end date to match the desired format: "YYYY-MM-DDTHH:mm"
    const year = end_date.getFullYear();
    const month = String(end_date.getMonth() + 1).padStart(2, '0');
    const day = String(end_date.getDate()).padStart(2, '0');
    const hours = String(end_date.getHours()).padStart(2, '0');
    const minutes = String(end_date.getMinutes()).padStart(2, '0');
    const formattedEndDate = `${year}-${month}-${day}T${hours}:${minutes}`;
  
    return formattedEndDate;
  }
import '../../styles/Welcome/passwordreset.css';
import React, { SyntheticEvent, useState } from 'react'
import axios from "axios";
import { NavLink, Navigate } from "react-router-dom"
import MediaQuery from "react-responsive";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../styles/Components/Toastify.css'

const PasswordReset = () => {
    const initialValues = { email: '' }
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState<Record<string, string>>({});

    const handleChange = (e: { target: { name: any; value: any; }; }) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const Validate = (Values: { email: any }) => {
        const errors: any = {};
        let isValid = true;
        setFormErrors(errors)
        if (!Values.email) {
            toast("Input Email Address", { type: "error" });
            errors.email = "Input Email Address";
        }

        if (Object.keys(errors).length > 0) {
            let errorMessage = "";
            for (const key in formErrors) {
                if (formErrors[key]) {
                    errorMessage += formErrors[key] + "\n";
                }
            }
        }
        return Object.keys(errors).length === 0;
    };

    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();
        const isValid = Validate(formValues);
        if (!isValid) {
            return;
        }


        // submit the form
        let api_url = process.env.REACT_APP_CHPTER_API_URL + '/merchants/forgot-password'
        let headers = {
            "Content-Type": "application/json"
        };

        const data = JSON.stringify(formValues);


        await axios.put(api_url, data, { headers: headers })
            .then((response) => {

                if (response.status === 200) {
                    toast.success('Password reset link sent to your email address', {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                    });
                }
            })
            .catch((error) => {
                // Error
                if (error.response) {

                    if (error.response.status === 404) {
                        toast("Email Address not found", { type: "error" });
                    }

                } else if (error.request) {

                } else {

                }
            });

    }

    const handleKeyDown = (event: React.SyntheticEvent<Element, Event>) => {
        const keyEvent = event as React.KeyboardEvent;
        if (keyEvent.key === "Enter") {
            submit(event);
        }
    };



    return (
        <>
            <MediaQuery minWidth={1001}>
                <ToastContainer position="top-right" autoClose={9000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false}
                    pauseOnFocusLoss draggable pauseOnHover toastClassName="custom-toast" className="toast-container" />
                <div id="PasswordReset_pr" style={{ left: "0px", transformOrigin: "0px 0px", transform: "scale(0.750)" }}>
                    <div id="Group_3972_pr">
                        <svg className="Rectangle_1922_pr">
                            <rect id="Rectangle_1922_pr" rx="15" ry="15" x="0" y="0" width="600" height="450">
                            </rect>
                        </svg>
                        <div id="All_pr">
                            <span>Enter your email address below and we will send you a <br /> password reset link.</span>
                        </div>

                        <div id="Group_4537_pr" onClick={submit}>
                            <div id="Group_3969_pr">
                                <svg className="Rectangle_2057_ca_pr">
                                    <linearGradient id="Rectangle_2057_ca" spreadMethod="pad" x1="0.066" x2="0.79" y1="0" y2="1.3">
                                        <stop offset="0" stop-color="#000" stop-opacity="1"></stop>
                                        <stop offset="1" stop-color="#000" stop-opacity="1"></stop>
                                    </linearGradient>
                                    <rect id="Rectangle_2057_ca_pr" rx="7" ry="7" x="0" y="0" width="469" height="48">
                                    </rect>
                                </svg>
                            </div>
                            <div id="Reset_Password_button_pr">Reset Password</div>
                        </div>

                        <div id="Group_3971_pr">
                            <div id="All__pr">
                                <span>Reset your Password</span>
                            </div>


                            <div id="Group_3858_pr">
                                <div>
                                    <input id="Input_Email_Address__pr" type="email" name="email" value={formValues.email} placeholder="Email Address"
                                        onChange={handleChange} onKeyDown={handleKeyDown}

                                    />
                                </div>
                            </div>

                        </div>
                        <div id="Group_4530_pr">
                            <div id="Dont_have_an_account_pr">
                                <span>Don't have an account?</span>
                            </div>
                            <a href="https://app.chpter.co/auth/register">
                                <div id="Sign_up_pr">
                                    <span>Sign up</span>
                                </div>
                            </a>
                        </div>


                    </div>
                    <div id="Group_4528_pr">
                        <div id="Group_3168_pr">
                            <div id="chpter_pr">
                                <span>chpter.</span>
                            </div>
                        </div>
                    </div>
                    <div id="Group_4531_pr">
                        <div id="chpter_cy_pr">
                            <span>chpter.</span>
                        </div>
                        <div id="Contact_pr">
                            <span>Contact</span>
                        </div>
                        <div id="Privacy_Policy_pr">
                            <span>Privacy Policy</span>
                        </div>
                        <div id="Group_4532_pr">
                            <div id="Layer_2_pr">
                                <div id="radio-button-off_pr">
                                    <svg className="Rectangle_2094_pr">
                                        <rect id="Rectangle_2094_pr" rx="0" ry="0" x="0" y="0" width="15" height="15">
                                        </rect>
                                    </svg>
                                </div>
                            </div>
                            <div id="c_pr">
                                <span>©</span>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>


            <MediaQuery maxWidth={1000}>
                <ToastContainer position="top-center" autoClose={5000} hideProgressBar={true} newestOnTop={false} closeOnClick={true}
                    pauseOnFocusLoss={true} draggable={true} pauseOnHover={true} toastClassName="custom-toast-mobile" className="toast-container-mobile" />
                {/* <div id="MobilePasswordReset_pr_mob">
                    <div id="Group_4528_pr_mob">
                        <div id="Group_3168_pr_mob">
                            <div id="chpter_pr_mob">
                                <span>chpter.</span>
                            </div>
                        </div>
                    </div>
                    <div id="Group_5586_pr_mob">
                        <svg className="Rectangle_2103_pr_mob">
                            <rect id="Rectangle_2103_pr_mob" rx="15" ry="15" x="0" y="0" width="390" height="367">
                            </rect>
                        </svg>
                        <div id="All__pr_mob">
                            <span>Enter your email address below and we will send you a<br />  password reset link.</span>
                        </div>
                        <div id="Group_3969_pr_mob" onClick={submit}>
                            <svg className="Rectangle_2057_d_pr_mob">
                                <linearGradient id="Rectangle_2057_d_pr_mob" spreadMethod="pad" x1="0.066" x2="0.79" y1="0" y2="1.3">
                                    <stop offset="0" stop-color="#000" stop-opacity="1"></stop>
                                    <stop offset="1" stop-color="#000" stop-opacity="1"></stop>
                                </linearGradient>
                                <rect id="Rectangle_2057_d_pr_mob" type="submit" rx="7" ry="7" x="0" y="0" width="300" height="40">
                                </rect>
                            </svg>
                            <div id="Reset_Password_button_pr_mob">Reset Password</div>
                        </div>
                        <div id="Group_3971_pr_mob">
                            <div id="All_pr_mob">
                                <span>Reset your Password</span>
                            </div>
                            <div>
                                <input id="Input_Email_Address__pr_mob" type="email" name="email" value={formValues.email} placeholder="Email Address" 
                                        onChange={handleChange} onKeyDown={handleKeyDown}
                                />
                            </div>
                        </div>
                        <div id="Group_4530_pr_mob">
                            <div id="Dont_have_an_account_pr_mob">
                                <span>Don't have an account?</span>
                            </div>
                        <a href="https://app.chpter.co/auth/register">
                                <div id="Sign_up_pr_mob">
                                    <span>Sign up</span>
                                </div>
                        </a>
                        </div>

                    </div>
                </div>
                <div className="ai-modal-container" style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Noto Sans', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'", lineHeight: "18px" }}></div> */}


                <div className="mobilesignin_pr_mob">
                    <div className="mobilesignin-inner_pr_mob">
                        <div className="group-wrapper_pr_mob">
                            <div className="group-wrapper_pr_mob">
                                <div className="signintemplate_pr_mob">
                                    <div className="header_pr_mob">
                                        <div className="payment-detail-wrapper_pr_mob">
                                            <div className="payment-detail_pr_mob">chpter.</div>
                                        </div>
                                    </div>
                                    <div className="welcomeback_pr_mob">

                                        <div className="your-customers-are-waiting-wrapper_pr_mob">
                                            <div className="mobilesignin-inner_pr_mob">
                                                Reset your Password
                                            </div>
                                        </div>
                                    </div>
                                    <div className="title_pr_mob">
                                        <div className="payment-detail2_pr_mob"> Enter your email address below and we will send you a password reset link.</div>
                                    </div>
                                    <div className="inputfields_pr_mob">
                                        <div className="inputname-parent_pr_mob">
                                            {/* <div className="inputname_pr_mob">Reset your Password</div> */}
                                            <input
                                                className="frame-child_pr_mob"
                                                type="email"
                                                name="email"
                                                value={formValues.email}
                                                placeholder="Email Address"
                                                onChange={handleChange}
                                                onKeyDown={handleKeyDown}
                                            />
                                        </div>

                                        <div className="submitbutton_pr_mob">
                                            <div className="submitbutton-inner_pr_mob">
                                                <div className="sign-in-wrapper_pr_mob" onClick={submit}>
                                                    <div className="payment-detail1_pr_mob">Reset Password</div>
                                                </div>
                                            </div>
                                            <div className="dont-have-an-account-parent_pr_mob">
                                                <div className="dont-have-an_pr_mob">Don't have an account?</div>

                                                <NavLink to={'/register'} style={{ textDecoration: "none" }} className="sign-up_pr_mob">Sign up</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>






            </MediaQuery>

        </>
    )
}

export default PasswordReset;
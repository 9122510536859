import '../../styles/Welcome/register.css';
import React, { SyntheticEvent, useEffect, useState } from 'react'
import axios from "axios";
import { NavLink, Navigate } from "react-router-dom"
import { FullPageSpinner } from '../../components/spinner';
import validator from 'validator';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import MediaQuery from "react-responsive";
import { BsEyeFill } from 'react-icons/bs'
import { BsEyeSlashFill } from 'react-icons/bs'
import { useNavigate } from 'react-router';
import { BouncingDotsLoader } from '../../components/loading-dots';
import 'react-toastify/dist/ReactToastify.css';
import '../../styles/Components/Toastify.css'
import '../../styles/Components/loading-dots.css'


const Register = () => {

    const initialValues = { first_name: '', last_name: '', email: '', phone_number: '', company: '', password: '', confirm_password: '', shopify_domain: '', shopify_code: '', shopify_access_token: '', is_shopify_active:false }
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState<Record<string, string>>({});
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [redirect, setredirect] = useState(false);
    const [loading, setLoading] = useState(false);


    const handleChange = (e: { target: { name: any; value: any; }; }) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };
    const isEmailValidator = require('validator').isEmail

    // Password Visibility
    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const handleToggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const shopDomain = searchParams.get('shop_domain') || '';
        const code = searchParams.get('code') || '';
        const accessToken = searchParams.get('accesstoken') || '';
        const shopify_active = searchParams.get('is_shopify_active') === 'true';
      

        setFormValues({ ...formValues, shopify_domain: shopDomain, shopify_code: code, shopify_access_token: accessToken, is_shopify_active: shopify_active });

    }, []);



    const Validate = (Values: { first_name: any; last_name: any; email: any; phone_number: any; company: any; password: any; confirm_password: any; }) => {
        const errors: any = {};
        let isValid = true;
        setFormErrors(errors)
        if (!Values.first_name) {
            toast("Input First Name", { type: "error" });
            errors.first_name = "Input First Name";
        }
        else if (!Values.last_name) {
            toast("Input Last Name", { type: "error" });
            errors.last_name = "Input Last Name";
        }
        else if (!Values.email) {
            toast("Input Email Address", { type: "error" });
            errors.email = "Input Email Address";
        }
        else if (!validator.isEmail(Values.email)) {
            toast("Email Address is not valid", { type: "error" });
            errors.email = "Email Address is not valid";
        }
        else if (!Values.company) {
            toast("Input Company Name", { type: "error" });
            errors.company = "Input Company Name";
        }
        else if (!Values.phone_number) {
            toast("Input Phone number", { type: "error" });
            errors.company = "Input Phone number";
        }
        else if (!Values.password) {
            toast("Input Password", { type: "error" });
            errors.password = "Input Password";

        } else if (Values.password.length < 8) {
            toast("Password must be at least 8 characters long", { type: "error" });
            errors.password = "Password must be at least 8 characters long";
        }
        else if (String(Values.confirm_password) !== String(Values.password)) {
            toast("Passwords don't match", { type: "error" });
            errors.confirm_password = "Passwords don't match";
        }
        else
            if (!Values.phone_number) {
                toast("Phone Number is required", { type: "error" });
                errors.phone_number = "Phone Number is required";
                isValid = false;
            } else if (!/^(\+254|0)[1-9]\d{8}$/.test(Values.phone_number)) {
                toast("Invalid Phone Number", { type: "error" });
                errors.phone_number = "Invalid Phone Number";
                isValid = false;
            }


        setFormErrors(errors);
        if (Object.keys(errors).length > 0) {
            let errorMessage = "";
            for (const key in formErrors) {
                if (formErrors[key]) {
                    errorMessage += formErrors[key] + "\n";
                }
            }

        }
        return Object.keys(errors).length === 0;
    };

    const navigate = useNavigate();


    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();
        const isValid = Validate(formValues);
        if (!isValid) {
            return;
        }


        let api_url = process.env.REACT_APP_CHPTER_API_URL + '/merchants/register'
        let headers = {
            "Content-Type": "application/json"
        };

        const data = JSON.stringify(formValues);
        setLoading(true)

        await axios.post(api_url, data, { headers: headers })
            .then((response) => {

                if (response.status === 201) {
                    setredirect(true)
                }
                setLoading(false)

            })
            .catch((error) => {
                // Error
                if (error.response) {

                    toast(error.response.data.message, { type: "error" });
                    setLoading(false)

                } else if (error.request) {

                } else {
    
                }
            });

    }


    if (redirect) {
        navigate('/email-verification/action-required', { state: { email: formValues.email } });
    }


    return (
        <div>
            <MediaQuery minWidth={1001}>

                <ToastContainer
                    position="top-center"
                    autoClose={9000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick={false}
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    toastClassName="custom-toast"
                    className="toast-container"
                    style={{
                        top: "10%",
                        color: "white",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                    }}
                />

                {loading ? (<BouncingDotsLoader></BouncingDotsLoader>
                ) : (

                    <div id="SignUp" style={{ left: "0px", transformOrigin: "0px 0px", transform: "scale(0.755)" }}>
                        <div id="Group_4528_su">
                            <div id="Group_3168_su">
                                <div id="chpter_su">
                                    <span>chpter.</span>
                                </div>
                            </div>
                        </div>

                        <div id="Group_4541_su">
                            <div id="Group_3168_b_su">
                                <div id="Ready_to_create_the_fastest__c_su">
                                    <span>Ready to create the fastest <br />checkout experience?</span>
                                </div>
                            </div>
                        </div>

                        <div id="Get_started_quickly_and_for_fr_su">
                            <span>Get started quickly and for free. </span>
                        </div>

                        <div id="Group_3972_su">
                            <svg className="Rectangle_1922_su">
                                <rect id="Rectangle_1922_su" rx="15" ry="15" x="0" y="0" width="600" height="825">
                                </rect>
                            </svg>
                            <div id="All_su">
                                <span>Create your account</span>
                            </div>

                            <form onSubmit={submit}>

                                <div id="Group_3971_su">
                                    <div id="Group_3858_su">
                                        <div >
                                            <input id="Input_first_name_su" type="text" name="first_name" placeholder="First Name" value={formValues.first_name} required
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div id="First_Name__su">
                                            <span>First Name </span>
                                        </div>

                                    </div>
                                    <div id="Group_4536_su">
                                        <div >
                                            <input id="Input_last_name_su" type="text" name="last_name" placeholder="Last Name" value={formValues.last_name} required
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div id="Last_Name__su">
                                            <span>Last Name </span>
                                        </div>


                                    </div>
                                </div>
                                <div id="Group_4529_su">
                                    <div id="Group_3858_cp_su">
                                        <div>
                                            <input id="Input_Email_Address__su" type="email" name="email" placeholder="Email Address" value={formValues.email} required
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div id="Email__su">
                                            <span>Email Address</span>
                                        </div>

                                    </div>

                                    <div id="Group_4536_cp_su">
                                        <input id="phonenumber_input_su" type="number" name="phone_number" placeholder="0723 887 536" value={formValues.phone_number} required
                                            onChange={handleChange}
                                        />
                                        <div id="Last_Name__su">
                                            <span>Phone Number </span>
                                        </div>
                                    </div>
                                    <div id="Phone_Number_Invalid_su">
                                        <i className="exclamation triangle icon_su"></i>
                                        <span>Invalid</span>
                                    </div>
                                </div>

                                <div id="Group_4538_su">
                                    <div id="Group_3858_cu_su">
                                        <div >
                                            <input id="Input_companybusiness_name_su" type="text" name="company" placeholder="Business Name" value={formValues.company} required
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div id="Company_su">
                                            <span>Company</span>
                                        </div>
                                    </div>

                                </div>
                                <div id="Group_4539_su">
                                    <div id="Group_3858_cz_su">
                                        <div>
                                            <input id="password_su" type={showPassword ? 'text' : 'password'} name="password" placeholder="Password" value={formValues.password} required
                                                onChange={handleChange} />
                                            <div id="Eye_icon_su" onClick={handleTogglePasswordVisibility}>
                                                {showPassword ? <BsEyeSlashFill style={{ color: 'rgb(118, 118, 118, 1)' }} /> : <BsEyeFill style={{ color: 'rgb(118, 118, 118, 1)' }} />}
                                            </div>
                                        </div>
                                        <div id="Password_c_su">
                                            <span>Password</span>
                                        </div>
                                    </div>
                                    <NavLink to="/password-reset">
                                        <div id="forgot_your_password__su">
                                            <span>forgot your password? </span>
                                        </div>
                                    </NavLink>
                                </div>

                                <div id="Group_4540_su">
                                    <div id="Group_3858_c_su">
                                        <div >
                                            <input id="confirm_password_su" type={showConfirmPassword ? 'text' : 'password'} name="confirm_password" placeholder="Confirm Password" value={formValues.confirm_password} required
                                                onChange={handleChange} />
                                        </div>
                                        <div id="Eye_icon_c_su" onClick={handleToggleConfirmPasswordVisibility}>
                                            {showConfirmPassword ? <BsEyeSlashFill style={{ color: 'rgb(118, 118, 118, 1)' }} /> : <BsEyeFill style={{ color: 'rgb(118, 118, 118, 1)' }} />}
                                        </div>
                                        <div id="Confirm_Password_c_su">
                                            <span>Confirm Password</span>
                                        </div>
                                    </div>


                                </div>
                                <div id="Group_4537_su" onClick={submit}>
                                    <div id="Group_3969_su">
                                        <svg className="Rectangle_2057_db_su">
                                            <linearGradient id="Rectangle_2057_db" spreadMethod="pad" x1="0.066" x2="0.79" y1="0" y2="1.3">
                                                <stop offset="0" stop-color="#000" stop-opacity="1"></stop>
                                                <stop offset="1" stop-color="#000" stop-opacity="1"></stop>
                                            </linearGradient>
                                            <rect id="Rectangle_2057_db_su" rx="7" ry="7" x="0" y="0" width="469" height="48">
                                            </rect>
                                        </svg>
                                    </div>
                                    <div id="Get_Started_su">Get Started</div>
                                </div>
                            </form>

                            <div id="Group_4542_su">
                                <div id="Have_an_account_su">
                                    <span>Have an account?</span>
                                </div>
                                <NavLink to={'/login'}>
                                    <div id="Sign_in_su">
                                        <span>Sign in</span>
                                    </div>
                                </NavLink>
                            </div>
                        </div>

                        <div id="Group_4531_su">
                            <div id="chpter_dh_su">
                                <span>chpter.</span>
                            </div>

                            <div id="Contact_su">
                                <span>Contact</span>
                            </div>
                            <div id="Privacy_Policy_su">
                                <span>Privacy Policy</span>
                            </div>
                            <div id="Group_4532_su">
                                <div id="c_su">
                                    <span>©</span>
                                </div>
                            </div>
                        </div>
                    </div>
                )
                }
            </MediaQuery>

            <MediaQuery maxWidth={1000}>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick={false}
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    toastClassName="custom-toast"
                    className="toast-container"
                    style={{
                        position: "fixed",
                        color: "white",
                        top: "10%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        height: "10vh",
                        width: "70vw",
                    }}
                />



                <div className="mobilesignup_su_mob">
                    <div className="mobilesignup-inner_su_mob">
                        <div className="group-wrapper_su_mob">
                            <div className="group-wrapper_su_mob">
                                <div className="signup_su_mob">
                                    <div className="header_su_mob">
                                        <div className="payment-detail-wrapper_su_mob">
                                            <div className="payment-detail_su_mob">chpter.</div>
                                        </div>
                                    </div>
                                    <div className="getstarted_su_mob">
                                        <div className="welcome-back-wrapper_su_mob">
                                            <div className="welcome-back_su_mob">
                                                <p className="supercharge-every-aspect_su_mob">
                                                    Supercharge every aspect of
                                                </p>
                                                <p className="supercharge-every-aspect_su_mob">your business</p>
                                            </div>
                                        </div>
                                        <div className="your-customers-are-waiting-wrapper_su_mob">
                                            <div className="your-customers-are_su_mob">{`Get started quickly and for free. `}</div>
                                        </div>
                                    </div>
                                    <div className="title_su_mob">
                                        <div className="payment-detail_su_mob">Create your account</div>
                                    </div>
                                    <div className="inputfields_su_mob">
                                        <div className="frame-parent_su_mob">
                                            <div className="inputname-parent_su_mob">
                                                <div className="inputname_su_mob">First Name</div>
                                                <input
                                                    className="frame-child_su_mob"
                                                    type="text"
                                                    placeholder="First Name"
                                                    name="first_name"
                                                    value={formValues.first_name} required
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="inputname-parent_su_mob">
                                                <div className="inputname_su_mob">Last Name</div>
                                                <input
                                                    className="frame-child_su_mob"
                                                    type="text"
                                                    placeholder="Last Name"
                                                    name="last_name"
                                                    value={formValues.last_name} required
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="inputname-container_su_mob">
                                            <div className="inputname2_su_mob">Email</div>
                                            <input
                                                className="frame-child_su_mob"
                                                type="text"
                                                placeholder="Input Email Address"
                                                name="email"
                                                value={formValues.email} required
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="inputname-container_su_mob">
                                            <div className="inputname2_su_mob">Company</div>
                                            <input
                                                className="frame-child_su_mob"
                                                type="text"
                                                placeholder="Input Company/ Business Name"
                                                name="company"
                                                value={formValues.company} required
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="inputname-container_su_mob">
                                            <div className="inputname2_su_mob">Phone Number</div>
                                            <input
                                                className="frame-child_su_mob"
                                                type="number"
                                                placeholder="Input Phone Number"
                                                name="phone_number"
                                                value={formValues.phone_number} required
                                                onChange={handleChange}
                                            />
                                        </div>

                                        <div className="inputname-parent_si_mob">
                                            <div className="frame-group_si_mob">
                                                <div className="inputname-wrapper_si_mob">
                                                    <div className="inputname_si_mob">Password</div>
                                                </div>
                                                <div className="forgot-your-password-wrapper_si_mob">

                                                    <div id="Eye_icon_si_mob" onClick={handleTogglePasswordVisibility}>
                                                        {showPassword ? <BsEyeSlashFill style={{ color: 'rgb(118, 118, 118, 1)' }} /> : <BsEyeFill style={{ color: 'rgb(118, 118, 118, 1)' }} />}
                                                    </div>
                                                </div>
                                            </div>
                                            <input
                                                className="frame-child_si_mob"
                                                placeholder="Password"
                                                type={showPassword ? 'text' : 'password'}
                                                name="password"
                                                value={formValues.password} required
                                                onChange={handleChange}
                                            />

                                        </div>
                                        <div className="inputname-parent_si_mob">
                                            <div className="frame-group_si_mob">
                                                <div className="inputname-wrapper_si_mob">
                                                    <div className="inputname_si_mob">Confirm Password</div>
                                                </div>
                                                <div className="forgot-your-password-wrapper_si_mob">

                                                    <div id="Eye_icon1_si_mob" onClick={handleToggleConfirmPasswordVisibility}>
                                                        {showConfirmPassword ? <BsEyeSlashFill style={{ color: 'rgb(118, 118, 118, 1)' }} /> : <BsEyeFill style={{ color: 'rgb(118, 118, 118, 1)' }} />}
                                                    </div>
                                                </div>
                                            </div>
                                            <input
                                                className="frame-child_si_mob"
                                                value={formValues.confirm_password} required
                                                onChange={handleChange}
                                                placeholder="Confirm Password"
                                                type={showConfirmPassword ? 'text' : 'password'}
                                                name="confirm_password"
                                            />

                                        </div>

                                    </div>
                                    <div className="submitbutton_su_mob" onClick={submit}>
                                        <div className="submitbutton-inner_su_mob">
                                            <div className="sign-in-wrapper_su_mob">
                                                <div className="payment-detail_su_mob">Get Started</div>
                                            </div>
                                        </div>
                                        <div className="dont-have-an-account-parent_su_mob">
                                            <div className="dont-have-an_su_mob">Have an account?</div>
                                            <NavLink to={'/login'} style={{ textDecoration: "none" }} className="sign-up_su_mob">Sign in</NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </MediaQuery>

        </div>
    )
}
export default Register


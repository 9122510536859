import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { get_wallet_balance } from '../services/Wallet/walletbalanceinfo';
import { display } from 'html2canvas/dist/types/css/property-descriptors/display';


const HotlinksWallet = ({ handleExport }: { handleExport: () => void }) => {

    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;

    const [WalletBalance, setWalletBalance] = useState([] as any);
    const [wallet_count, setWalletCount] = useState(0);
    const [ExchangeRate, setExchangeRate] = useState([] as any)
    const [showButton, setShowButton] = useState(true)

    useEffect(() => {
        async function fetchBankBalances() {
            await get_wallet_balance().then(wallet => {

                setWalletBalance(wallet);
                setWalletCount((Object.keys(wallet).length) / 2)

            }).catch(error => {

            }).finally(() => {
            })
        }

        fetchBankBalances();


        // Get Exchange Rate
        async function fetchExchangeRate() {

            const options = {
                method: 'GET',
                url: 'https://currency-converter5.p.rapidapi.com/currency/convert',
                params: { format: 'json', from: 'USD', to: 'KES', amount: '1' },
                headers: {
                    'X-RapidAPI-Key': '135ca29c7emsh49edd4af30452d7p130999jsn4dc7febc3d2c',
                    'X-RapidAPI-Host': 'currency-converter5.p.rapidapi.com'
                }
            };

            await axios.request(options).then(data => {


                setExchangeRate(data.data.rates.KES.rate);

            }).catch(function (error) {
                if (error.response) {
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                } else if (error.request) {
                    // console.log(error.request);
                } else {
                    // console.log('Error', error.message);
                }

            })
        }
        fetchExchangeRate()
    }, []);

    const grand_total = parseFloat(WalletBalance.available_balance_kes)
    const rounded_grand_total = grand_total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

    const available_balance_kes = parseFloat(WalletBalance.total_balance_kes).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    const pending_balance_kes = parseFloat(WalletBalance.available_balance_kes).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    const available_balance_usd = parseFloat(WalletBalance.total_balance_usd).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    const pending_balance_usd = parseFloat(WalletBalance.available_balance_usd).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

    const [titleSelected, setTitleSelected] = useState(1)
    const handleNavLinkClick = (index: React.SetStateAction<number>) => {
        setTitleSelected(index);
    }

    const location = useLocation();
    const currentRoutePath = location.pathname;
    useEffect(() => {
        if (currentRoutePath === '/wallet') {
            setTitleSelected(1);
            setShowButton(false)

        } else if (currentRoutePath === '/successfulpayouts') {
            setTitleSelected(2);
            setShowButton(true)


        } else if (currentRoutePath === '/pendingpayouts') {
            setTitleSelected(3);
            setShowButton(true)


        }
    }, [currentRoutePath])



    return (
        <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse" >
            <div id="Group_4589_wa">
                <div id="Checkout-ss_wa">
                    <span>Wallet</span>
                </div>
            </div>
            <div id="Group_6782_wa">
                <div id="Group_5021_wa">
                    <svg className="Rectangle_2198_wa">
                        <rect id="Rectangle_2198_wa" rx="10" ry="10" x="0" y="0" width="1402" height="345">
                        </rect>
                    </svg>
                </div>
                <div id="Group_6755_wa">
                    <div id="All_wa">
                        <span>{available_balance_kes}</span>
                    </div>
                    <div id="All_et_wa">
                        <span>Kes.</span>
                    </div>
                </div>
                <div id="Your_total_balance_is_an_estim_wa">
                    <span>Your available balance is ready for withdrawal as of {date}.
                        <br />
                        Your pending balance will be added to availbale balance after 3 days of processing.</span>
                </div>
                <div id="Currency_Balances_wa">
                    <span>Currency Balances</span>
                </div>
                <div id="Group_6768_wa">
                    <div id="Group_5337_wa">
                        <svg className="Rectangle_2144_wa">
                            <rect id="Rectangle_2144_wa" rx="7" ry="7" x="0" y="0" width="1318.623" height="165">
                            </rect>
                        </svg>
                    </div>
                    <div id="Group_6767_wa">
                        <div id="YOUR_WALLET_wa">
                            <span>YOUR WALLET</span>
                        </div>
                        <div id="n__accounts_wa">
                            <span>{wallet_count} accounts</span>
                        </div>
                    </div>
                    {/* <div id="Group_6766_wa">
                        <div id="NGN_667000_wa">
                            <span>NGN. 6,670.00</span>
                        </div>
                        <div id="Group_6762_wa">
                            <div id="NGN_wa">
                                <span>NGN</span>
                            </div>
                            <img id="icons8-nigeria-flag-96_wa" src="media/icons8-nigeria-flag-96.png" />
                                
                        </div>
                    </div> */}
                    <div id="Group_6764_wa">
                        <div id="USD_179000_total_wa">
                            <div id="USD_179000_totalbalance_wa">
                                <span>Available Balance</span>
                            </div>
                            <div id="USD_179000_wa">
                                <span>USD. </span>
                            </div>
                            <div id="USD_179000_amount_wa">
                                <span>{available_balance_usd}</span>
                            </div>
                        </div>
                        <div id="USD_179000_available_wa">
                            <div id="USD_179000_totalbalance_wa">
                                <span>Pending Balance</span>
                            </div>
                            <div id="USD_179000_wa">
                                <span>USD. </span>
                            </div>
                            <div id="USD_179000_amount_wa">
                                <span>{pending_balance_usd}</span>
                            </div>
                        </div>

                        <div id="Group_6760_wa">
                            <div id="USD_wa">
                                <span>USD</span>
                            </div>
                            <img id="icons8-usa-96_wa" src="media/icons8-usa-96.png" />

                        </div>
                    </div>
                    {/* <div id="Group_6765_wa">
                        <div id="UGX_7600000_wa">
                            <span>UGX. 76,000.00</span>
                        </div>
                        <div id="Group_6761_wa">
                            <div id="UGX_wa">
                                <span>UGX</span>
                            </div>
                            <img id="icons8-uganda-96_wa" src="media/icons8-uganda-96.png"/>
                                
                        </div>
                    </div> */}

                    {/* KES WALLET */}
                    <div id="Group_6763_wa">
                        <div id="KES_12699000_total_wa">
                            <div id="KES_12699000_totalbalance_wa">
                                <span>Available Balance</span>
                            </div>
                            <div id="KES_12699000_wa">
                                <span>KES. </span>
                            </div>
                            <div id="KES_12699000_amount_wa">
                                <span>{available_balance_kes}</span>
                            </div>
                        </div>
                        <div id="KES_12699000_available_wa">
                            <div id="KES_12699000_totalbalance_wa">
                                <span>Pending Balance</span>
                            </div>
                            <div id="KES_12699000_wa">
                                <span>KES. </span>
                            </div>
                            <div id="KES_12699000_amount_wa">
                                <span>{pending_balance_kes}</span>
                            </div>
                        </div>
                        <div id="Group_6759_wa">
                            <div id="KES_wa">
                                <span>KES</span>
                            </div>
                            <img id="icons8-kenya-96_wa" src="media/icons8-kenya-96.png" />
                        </div>
                    </div>
                </div>
                <div id="Group_6642_wa">
                    <div id="Group_6641_wa">
                        <div id="Group_4117_wa">
                            <svg className="Rectangle_1977_wa">
                                <rect id="Rectangle_1977_wa" rx="5" ry="5" x="0" y="0" width="127" height="34">
                                </rect>
                            </svg>
                            <div id="Load_Wallet_wa">
                                <span>Load Wallet</span>
                            </div>
                            <img id="icons8-wallet-100_wa" src="media/icons8-wallet-100.png" />

                        </div>
                    </div>
                    <div id="Group_6609_wa">
                        <div id="Group_3969_wa">
                            <svg className="Rectangle_2057_wa">
                                <rect id="Rectangle_2057_wa" rx="5" ry="5" x="0" y="0" width="93" height="30">
                                </rect>
                            </svg>
                        </div>
                        <div id="Coming_soon_wa">
                            <span>Coming soon</span>
                        </div>
                    </div>
                </div>
            </div>
            <ul className="nav flex-column">
                <div id="Group_6783_wa">
                    <svg className="Line_355_wa" viewBox="0 0 1383 1">
                        <path id="Line_355_wa" d="M 0 0 L 1383 0">
                        </path>
                    </svg>
                    <NavLink to={'/wallet'} className="nav-link" style={{ textDecoration: 'none' }}>
                        <div id="All_hv_wa" key={1} onClick={() => handleNavLinkClick(1)} className={`div ${1 === titleSelected ? 'bold-title' : ''}`} >
                            <span>balance overview</span>
                        </div>
                    </NavLink>
                    <NavLink to={'/successfulpayouts'} className="nav-link" style={{ textDecoration: 'none' }}>
                        <div id="All_hu_wa" key={2} onClick={() => handleNavLinkClick(2)} className={`div ${2 === titleSelected ? 'bold-title' : ''}`} >
                            <span>successful payouts</span>
                        </div>
                    </NavLink>
                    <NavLink to={'/pendingpayouts'} className="nav-link" style={{ textDecoration: 'none' }}>
                        <div id="All_hs_wa" key={3} onClick={() => handleNavLinkClick(3)} className={`div ${3 === titleSelected ? 'bold-title' : ''}`} >
                            <span>failed payouts</span>
                        </div>
                    </NavLink>
                </div>

                <div id="Group_4972_wa" style={{ visibility: showButton ? "visible" : "hidden" }} onClick={() => handleExport()}>
                    <div id="Group_4117_wa">
                        <svg className="Rectangle_1977_wa">
                            <rect id="Rectangle_1977_wa" rx="5" ry="5" x="0" y="0" width="75" height="34">
                            </rect>
                        </svg>
                        <div id="Export_wa">
                            <span>Export</span>
                        </div>
                    </div>
                </div>

            </ul>
        </nav>
    )
}

export default HotlinksWallet;
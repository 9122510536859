import React from 'react';
import {NavLink} from 'react-router-dom';

const HotlinksDevelopers = () => {
    return (
        
        <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse" >
            <div id="DevelopersHotlink_devhotl">
                <div id="Group_6814_devhotl">
                    <NavLink to={'/developers'} id="All_ey_devhotl" className="nav-link" style={{ textDecoration: 'none' }}>
                            Overview
                    </NavLink>
                    <NavLink to={'/developers-apikeys'} id="All_ew_devhotl" className="nav-link" style={{ textDecoration: 'none' }}>
                            Api keys
                    </NavLink>
                    <NavLink to={'/developers-logs'} id="All_devhotl" className="nav-link" style={{ textDecoration: 'none' }}>
                            logs
                    </NavLink>
                    <svg className="Line_355_devhotl" viewBox="0 0 1383 1">
                        <path id="Line_355_devhotl" d="M 0 0 L 1383 0">
                        </path>
                    </svg>
                </div>
                <div id="Group_4589_devhotl">
                    <div id="Developers_devhotl">
                        <span>Developers</span>
                    </div>
                </div>
                <div id="Component_77__8_devhotl" className="Component_77___8">
                    <div id="Group_5021_devhotl">
                        <svg className="Rectangle_2198_devhotl">
                            <rect id="Rectangle_2198_devhotl" rx="10" ry="10" x="0" y="0" width="1402" height="345">
                            </rect>
                        </svg>
                    </div>
                    <div id="Group_6641_devhotl">
                        <div id="Group_4117_devhotl">
                            <svg className="Rectangle_1977_devhotl">
                                <rect id="Rectangle_1977_devhotl" rx="5" ry="5" x="0" y="0" width="167" height="34">
                                </rect>
                            </svg>
                            <div id="Create_API_Keys_devhotl">
                                <span>Create API Keys</span>
                            </div>                                
                        </div>
                    </div>
                    <div id="Group_6875_devhotl">
                        <div id="Easy_seamless_integration____devhotl">
                            <span>Easy seamless integration. . . </span>
                        </div>
                        <div id="Easy_seamless_integration____fb_devhotl">
                            <span>Easy seamless integration. . . </span>
                        </div>
                    </div>
                    <a href="https://docs.chpter.co/">
                        <div id="Group_6853_devhotl">
                            <div id="API_Reference_devhotl">
                                <span>API Reference</span>
                            </div>
                            <div id="Group_5329_devhotl">
                                <div id="Group_5321_devhotl">
                                    <div id="Layer_2_devhotl">
                                        <div id="arrow-forward_devhotl">
                                            <svg className="Rectangle_2234_devhotl">
                                                <rect id="Rectangle_2234_devhotl" rx="0" ry="0" x="0" y="0" width="20" height="20">
                                                </rect>
                                            </svg>
                                            <svg className="Path_9157_devhotl" viewBox="4 5 13.187 11.539">
                                                <path id="Path_9157_devhotl" d="M 4.82415771484375 11.59326171875 L 14.59866905212402 11.59326171875 L 11.60697650909424 15.18659019470215 C 11.3156681060791 15.53706836700439 11.3636360168457 16.05734062194824 11.71411609649658 16.34865188598633 C 12.06459712982178 16.63995933532715 12.58486938476562 16.59199142456055 12.87617874145508 16.24151229858398 L 16.99696731567383 11.29656600952148 C 17.02469253540039 11.25723457336426 17.04948425292969 11.21591472625732 17.07114219665527 11.17294311523438 C 17.07114219665527 11.13173484802246 17.11234855651855 11.10700988769531 17.12883186340332 11.0658016204834 C 17.16618919372559 10.97130489349365 17.18574714660645 10.87071323394775 17.1865234375 10.76910400390625 C 17.18574714660645 10.66749477386475 17.16618919372559 10.56690406799316 17.12883186340332 10.47240734100342 C 17.12883186340332 10.4311990737915 17.08762550354004 10.40647506713867 17.07114219665527 10.36526679992676 C 17.04948425292969 10.32229518890381 17.02469253540039 10.28097534179688 16.99696731567383 10.24164390563965 L 12.87617874145508 5.296696662902832 C 12.71929550170898 5.108343601226807 12.48670864105225 4.999601364135742 12.2415771484375 4.999999523162842 C 12.04901123046875 4.999622821807861 11.86239147186279 5.066689014434814 11.71411609649658 5.189555644989014 C 11.54557037353516 5.32928991317749 11.43954944610596 5.530345439910889 11.41944885253906 5.748357772827148 C 11.39934921264648 5.966370582580566 11.46682071685791 6.183421611785889 11.60697555541992 6.35161828994751 L 14.59866905212402 9.9449462890625 L 4.82415771484375 9.9449462890625 C 4.368988037109375 9.9449462890625 4 10.31393432617188 4 10.76910400390625 C 4 11.22427368164062 4.368988037109375 11.59326171875 4.82415771484375 11.59326171875 Z">
                                                </path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a>
                    <div id="Group_6891_devhotl">
                        <div id="Group_5337_devhotl">
                            <svg className="Rectangle_2144_devhotl">
                                <rect id="Rectangle_2144_devhotl" rx="7" ry="7" x="0" y="0" width="1318.623" height="140">
                                </rect>
                            </svg>
                        </div>
                        <div id="Group_6882_devhotl">
                            <div id="Your_overview_devhotl">
                                <span>Your overview</span>
                            </div>
                            <div id="Group_6893_devhotl">
                                <div id="Active_devhotl">
                                    <span>Active</span>
                                </div>
                                <svg className="Ellipse_782_fs_devhotl">
                                    <radialGradient id="Ellipse_782_fs_devhotl" spreadMethod="pad" x1="0" x2="0" y1="0" y2="0">
                                        <stop offset="0" stop-color="#8ffa8f" stop-opacity="1"></stop>
                                        <stop offset="0.4975" stop-color="#3bff3b" stop-opacity="1"></stop>
                                        <stop offset="1" stop-color="#06f206" stop-opacity="1"></stop>
                                    </radialGradient>
                                    <ellipse id="Ellipse_782_fs_devhotl" rx="5" ry="5" cx="5" cy="5">
                                    </ellipse>
                                </svg>
                            </div>
                        </div>
                        <div id="Group_6890_devhotl">
                            <div id="n_69_devhotl">
                                <span>269</span>
                            </div>
                            <div id="Request_count_devhotl">
                                <span>Request count</span>
                            </div>
                                
                            <div id="Group_6883_devhotl">
                                <div id="Group_3969_devhotl">
                                    <svg className="Rectangle_2057_devhotl">
                                        <rect id="Rectangle_2057_devhotl" rx="5" ry="5" x="0" y="0" width="90.955" height="25">
                                        </rect>
                                    </svg>
                                </div>
                                <div id="Past_30_days_devhotl">
                                    <span>Past 30 days</span>
                                </div>
                            </div>
                        </div>
                        <div id="Group_6889_devhotl">
                            <div id="n_26_devhotl">
                                <span>126</span>
                            </div>
                            <div id="Successful_requests_devhotl">
                                <span>Successful requests</span>
                            </div>
                                
                            <div id="Group_6884_devhotl">
                                <div id="Group_3969_f_devhotl">
                                    <svg className="Rectangle_2057_f_devhotl">
                                        <rect id="Rectangle_2057_f_devhotl" rx="5" ry="5" x="0" y="0" width="90.955" height="25">
                                        </rect>
                                    </svg>
                                </div>
                                <div id="Past_30_days_f_devhotl">
                                    <span>Past 30 days</span>
                                </div>
                            </div>
                        </div>
                        <div id="Group_6888_devhotl">
                            <div id="n_9_devhotl">
                                <span>99</span>
                            </div>
                            <div id="Failed_requests_devhotl">
                                <span>Failed requests</span>
                            </div>
                                
                            <div id="Group_6885_devhotl">
                                <div id="Group_3969_ge_devhotl">
                                    <svg className="Rectangle_2057_gf_devhotl">
                                        <rect id="Rectangle_2057_gf_devhotl" rx="5" ry="5" x="0" y="0" width="90.955" height="25">
                                        </rect>
                                    </svg>
                                </div>
                                <div id="Past_30_days_gg_devhotl">
                                    <span>Past 30 days</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default HotlinksDevelopers;
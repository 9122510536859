import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import '../../styles/Wallet/payoutdetail.css'
import '../../styles/Components/NavBar.css'
import '../../styles/Components/UserRibbon.css'
import Wrapper from "../../components/Wrapper";

import { useParams } from "react-router-dom";
import { dateFormatter, datetimeFormatter_human } from '../../utils/date_formatter';
import MediaQuery from 'react-responsive';
import { get_merchant_profile } from "../../services/Settings/merchantprofileinfo";
import { getPayoutDetails } from '../../services/Wallet/payoutsinfo';




const Wallet = () => {

    let { slug } = useParams();
    let payout_slug = { slug }.slug

    const [showDiv, setShowDiv] = useState(false);
    const [text, setText] = useState('');


    const handleCopy = () => {
        var text = `${process.env.REACT_APP_BASE_URL}/successfulpayoutsreceipt/${PayoutDetail['slug']}`
        const el = document.createElement('textarea');
        el.value = text;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        setShowDiv(true);
        setTimeout(() => setShowDiv(false), 2000);


    };



    const [PayoutDetail, setPayoutDetail] = useState([] as any);
    const [MerchantInfo, setMerchant] = useState([] as any);



    async function GetPayoutDetail() {
        await getPayoutDetails(payout_slug).then(response => {


            setPayoutDetail(response.payout);
            setText(`${process.env.REACT_APP_BASE_URL}/successfulpayoutsreceipt/${PayoutDetail['slug']}`)

        }).catch(error => {

        }).finally(() => {
        })

    }



    useEffect(() => {
        async function fetchMerchantInfo() {
            var merchant = await get_merchant_profile();
            setMerchant(merchant);

        }
        fetchMerchantInfo();
        GetPayoutDetail();

    }, []);

    return (
        <>
            <MediaQuery minWidth={1001}>
                <main className="form-signin">

                    <div id="PayoutDetail__2_pd">
                        <div id="Group_6787_pd">
                            <div id="Group_4590_pd">
                                <svg className="Line_pd" viewBox="0 1 1337.868 1">
                                    <path id="Line_pd" d="M 0 1 L 1337.867553710938 1">
                                    </path>
                                </svg>
                                <div id="All_pd">
                                    <span>Payout Details</span>
                                </div>
                                <div id="Group_5149_pd">
                                    <div id="Account_pd">
                                        <span>Account</span>
                                    </div>
                                    <div id="To_Bank_pd">
                                        <span>To Bank</span>
                                    </div>
                                    <img id="icons8-merchant-account-100_pd" src="/media/icons8-merchant-account-100.png" alt='' />

                                </div>
                                <div id="Group_5150_pd">
                                    <div id="Amount_pd">
                                        <span>Amount</span>
                                    </div>
                                    <div id="ksh_1279900_pd">
                                        <span>{PayoutDetail['currency']} {PayoutDetail['amount']}.00</span>
                                    </div>
                                </div>
                                <div id="Group_5151_pd">
                                    <div id="Fee_pd">
                                        <span>Fee</span>
                                    </div>
                                    <div id="ksh_3500_pd">
                                        <span>ksh. 35.00</span>
                                    </div>
                                </div>
                                <div id="Group_5153_pd">
                                    <div id="Status_pd">
                                        <span>Status</span>
                                    </div>
                                    <div id="Success_pd">
                                        <span>Success</span>
                                    </div>
                                </div>
                                <div id="Group_5414_pd">
                                    <div id="Payout_Receipt_pd">
                                        <span>Payout Receipt</span>
                                    </div>
                                    <div id="Group_5381_pd" style={{ display: showDiv ? "block" : "none" }}>
                                        <svg className="Rectangle_2057_pd">
                                            <rect id="Rectangle_2057_pd" rx="7" ry="7" x="0" y="0" width="133" height="24">
                                            </rect>
                                        </svg>
                                        <div id="Link_Copied_to_Clipboard_pd">
                                            <span>Link Copied to Clipboard</span>
                                        </div>
                                    </div>
                                    <div id="Component_76_pd">
                                        <svg className="Rectangle_2127_pd">
                                            <rect id="Rectangle_2127_pd" rx="5" ry="5" x="0" y="0" width="650" height="25">
                                            </rect>
                                        </svg>
                                        <div id="Search_Input_pd">
                                            <div id="httpscheckoutchptercodrip_city_pd" >

                                                <span>{`${process.env.REACT_APP_BASE_URL}/successfulpayoutsreceipt${PayoutDetail['slug']}`}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="Group_5382_pd" onClick={handleCopy}>
                                        <svg className="Rectangle_2126_pd">
                                            <rect id="Rectangle_2126_pd" rx="5" ry="5" x="0" y="0" width="25" height="25">
                                            </rect>
                                        </svg>
                                        <div id="Layer_2_pd">
                                            <div id="copy_pd">
                                                <svg className="Rectangle_2125_pd">
                                                    <rect id="Rectangle_2125_pd" rx="0" ry="0" x="0" y="0" width="21" height="21">
                                                    </rect>
                                                </svg>
                                                <svg className="Path_9096_pd" viewBox="9 9 10.333 10.333">
                                                    <path id="Path_9096_pd" d="M 16.75 19.33333396911621 L 11.58333301544189 19.33333396911621 C 10.15659713745117 19.33333396911621 9 18.17673492431641 9 16.75 L 9 11.58333301544189 C 9 10.15659713745117 10.15659809112549 9 11.58333396911621 9 L 16.75 9 C 18.17673492431641 9 19.33333396911621 10.15659713745117 19.33333396911621 11.58333301544189 L 19.33333396911621 16.75 C 19.33333396911621 18.17673492431641 18.17673492431641 19.33333396911621 16.75 19.33333396911621 Z M 11.58333301544189 10.72222232818604 C 11.10775470733643 10.72222232818604 10.72222232818604 11.10775470733643 10.72222232818604 11.58333301544189 L 10.72222232818604 16.75 C 10.72222232818604 17.22557830810547 11.10775470733643 17.61111068725586 11.58333301544189 17.61111068725586 L 16.75 17.61111068725586 C 17.22557830810547 17.61111068725586 17.61111068725586 17.22557830810547 17.61111068725586 16.75 L 17.61111068725586 11.58333301544189 C 17.61111068725586 11.10775470733643 17.22557830810547 10.72222232818604 16.75 10.72222232818604 L 11.58333301544189 10.72222232818604 Z">
                                                    </path>
                                                </svg>
                                                <svg className="Path_9097_pd" viewBox="3 3 10.333 10.333">
                                                    <path id="Path_9097_pd" d="M 8.79527759552002 13.33333396911621 L 5.299166679382324 13.33333396911621 C 4.031332492828369 13.32861328125 3.004721164703369 12.302001953125 2.999999523162842 11.03416728973389 L 3 5.299166679382324 C 3.004721641540527 4.031332492828369 4.031333446502686 3.004721164703369 5.299167156219482 2.999999523162842 L 11.03416728973389 3 C 12.302001953125 3.004721164703369 13.32861328125 4.031332492828369 13.33333396911621 5.299167156219482 L 13.33333396911621 8.511111259460449 L 11.61111164093018 8.511111259460449 L 11.61111164093018 5.299166679382324 C 11.61111164093018 4.980528831481934 11.35280418395996 4.722221851348877 11.03416728973389 4.722221851348877 L 5.299166679382324 4.722221851348877 C 4.980528831481934 4.722221851348877 4.722221851348877 4.980528831481934 4.722221851348877 5.299166679382324 L 4.722221851348877 11.03416728973389 C 4.722221851348877 11.35280418395996 4.980528831481934 11.61111164093018 5.299166679382324 11.61111164093018 L 8.79527759552002 11.61111164093018 L 8.79527759552002 13.33333396911621 Z">
                                                    </path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="Once_funds_have_been_transferr_pd">
                                        <span>Once funds have been transferred. Payout Receipt Link will be live.</span>
                                    </div>
                                </div>
                            </div>
                            <div id="Group_4616_pd">
                                <svg className="Line_fn_pd" viewBox="0 1 1332.717 1">
                                    <path id="Line_fn_pd" d="M 0 1 L 1332.716918945312 1">
                                    </path>
                                </svg>
                                <div id="All_fo_pd">
                                    <span>Recipient Details</span>
                                </div>

                                {PayoutDetail.payout_type === 'DOMESTIC' && (
                                    <>
                                        <div id="Group_5157_pd">
                                            <div id="Bank_Account_No_pd">
                                                <span>Bank Account No.</span>
                                            </div>
                                            <div id="n_1105678900091_pd">
                                                <span>{PayoutDetail['destination'] && PayoutDetail['destination'].split(':')[1]?.trim().replace(/.(?=.{4})/g, "*")}</span>
                                            </div>
                                        </div><div id="Group_5158_pd">
                                            <div id="Bank_pd">
                                                <span>Bank</span>
                                            </div>
                                            <div id="Stanchart_Bank_Kenya_pd">
                                                <span>{PayoutDetail['payout_type'] === 'Mpesa' ? 'Mpesa' : (PayoutDetail['destination'] && PayoutDetail['destination'].split('|')[0])}</span>
                                            </div>
                                        </div>
                                        <div id="Group_5160_pd">
                                            <div id="Bank_Code_pd">
                                                <span> Bank Code </span>

                                            </div>
                                            <div id="STNRX23_pd">
                                                <span>{PayoutDetail['destination'] && PayoutDetail['destination'].split("|")[1]?.split(":")[1]?.trim()}</span>
                                            </div>
                                        </div>
                                        <div id="Group_5159_pd">
                                            <div id="Country_pd">
                                                <span>Country</span>
                                            </div>
                                            <div id="Kenya_pd">
                                                <span>Kenya</span>
                                            </div>
                                        </div>
                                        <div id="Group_5161_pd">
                                            <div id="Recepient_Phone_No_pd">
                                                <span>Recepient Phone No.</span>
                                            </div>
                                            <div id="n_723_657_893_pd">
                                                <span>{MerchantInfo['mobile_number']}</span>
                                            </div>
                                        </div>
                                        <div id="Group_51612_pd">
                                            <div id="Recepient_Phone_No_pd">
                                                <span>Bank Account Name</span>
                                            </div>
                                            <div id="n_723_657_893_pd">
                                                <span>{PayoutDetail['destination'] && PayoutDetail['destination'].split("|")[1]?.split(":")[2]?.trim()}</span>
                                            </div>
                                        </div>


                                        <div id="Group_5412_pd">
                                            <svg className="Line_gx_pd" viewBox="0 1 1332.717 1">
                                                <path id="Line_gx_pd" d="M 0 1 L 1332.716918945312 1">
                                                </path>
                                            </svg>
                                            <div id="All_gy_pd">
                                                <span>Timeline</span>
                                            </div>
                                            <div id="Group_5411_pd">
                                                <div id="Group_5165_pd">
                                                    <div id="n_5_Nov__10450500_AM_pd">
                                                        <span>15 Nov,  10:45:05:00 AM</span>
                                                    </div>
                                                    <div id="Payout_Completed_pd">
                                                        <span>Payout Completed</span>
                                                    </div>
                                                </div>
                                                <div id="Group_5166_pd">
                                                    <div id="n_5_Nov__10450509_AM_pd">
                                                        <span>15 Nov,  10:45:05:09 AM</span>
                                                    </div>
                                                    <div id="Payout_Initiated_pd">
                                                        <span>Payout Initiated</span>
                                                    </div>
                                                </div>
                                                <div id="Group_5167_pd">
                                                    <div id="Group_4613_pd">
                                                        <div id="Group_3969_g_pd">
                                                            <svg className="Rectangle_2057_g_pd">
                                                                <rect id="Rectangle_2057_g_pd" rx="7" ry="7" x="0" y="0" width="84" height="18">
                                                                </rect>
                                                            </svg>
                                                        </div>
                                                        <div id="success__ha_pd">
                                                            <span>success </span>
                                                        </div>
                                                        <div id="Layer_2_hb_pd">
                                                            <div id="checkmark_hc_pd">
                                                                <svg className="Rectangle_2122_hd_pd">
                                                                    <rect id="Rectangle_2122_hd_pd" rx="0" ry="0" x="0" y="0" width="18" height="18">
                                                                    </rect>
                                                                </svg>
                                                                <svg className="Path_9092_he_pd" viewBox="3.999 5.956 12.298 9.233">
                                                                    <path id="Path_9092_he_pd" d="M 8.492274284362793 15.18946647644043 C 8.279788017272949 15.18878364562988 8.077116012573242 15.09994220733643 7.932625770568848 14.94414138793945 L 4.206743717193604 10.98060131072998 C 3.916711807250977 10.67151546478271 3.932157278060913 10.18583393096924 4.2412428855896 9.895801544189453 C 4.550328254699707 9.605769157409668 5.036009311676025 9.621214866638184 5.326041698455811 9.930300712585449 L 8.484606742858887 13.29586124420166 L 14.93206882476807 6.242752075195312 C 15.11035251617432 6.020743370056152 15.39699077606201 5.915829658508301 15.6764669418335 5.970292091369629 C 15.95594310760498 6.024755477905273 16.18221473693848 6.229621887207031 16.26408958435059 6.502328872680664 C 16.34596633911133 6.775036811828613 16.26995849609375 7.070656776428223 16.06669998168945 7.270052909851074 L 9.059587478637695 14.9364767074585 C 8.916444778442383 15.09511184692383 8.713593482971191 15.18681240081787 8.49993896484375 15.18946933746338 Z">
                                                                    </path>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="Group_4614_pd">
                                                        <div id="Group_3969_hg_pd">
                                                            <svg className="Rectangle_2057_hh_pd">
                                                                <rect id="Rectangle_2057_hh_pd" rx="7" ry="7" x="0" y="0" width="93" height="18">
                                                                </rect>
                                                            </svg>
                                                        </div>
                                                        <div id="funds_transferred__pd">
                                                            <span>funds transferred </span>
                                                        </div>
                                                    </div>
                                                    <svg className="Line_343_pd" viewBox="0 0 0.20000000298023224 144">
                                                        <path id="Line_343_pd" d="M 0 0 L 0 144">
                                                        </path>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                                {PayoutDetail.payout_type === 'MPESA' && (
                                    <><div id="Group_51160_pd">
                                        <div id="Bank_Code_pd">
                                            <span> Recepient Phone No.</span>

                                        </div>
                                        <div id="STNRX23_pd">
                                            <span>{PayoutDetail.destination}</span>
                                        </div>
                                    </div><div id="Group_51519_pd">
                                            <div id="Country_pd">
                                                <span>Country</span>
                                            </div>
                                            <div id="Kenya_pd">
                                                <span>Kenya</span>
                                            </div>
                                        </div>

                                        <div id="Group_52412_pd">
                                            <svg className="Line_gx_pd" viewBox="0 1 1332.717 1">
                                                <path id="Line_gx_pd" d="M 0 1 L 1332.716918945312 1">
                                                </path>
                                            </svg>
                                            <div id="All_gy_pd">
                                                <span>Timeline</span>
                                            </div>
                                            <div id="Group_5411_pd">
                                                <div id="Group_5165_pd">
                                                    <div id="n_5_Nov__10450500_AM_pd">
                                                        <span>15 Nov,  10:45:05:00 AM</span>
                                                    </div>
                                                    <div id="Payout_Completed_pd">
                                                        <span>Payout Completed</span>
                                                    </div>
                                                </div>
                                                <div id="Group_5166_pd">
                                                    <div id="n_5_Nov__10450509_AM_pd">
                                                        <span>15 Nov,  10:45:05:09 AM</span>
                                                    </div>
                                                    <div id="Payout_Initiated_pd">
                                                        <span>Payout Initiated</span>
                                                    </div>
                                                </div>
                                                <div id="Group_5167_pd">
                                                    <div id="Group_4613_pd">
                                                        <div id="Group_3969_g_pd">
                                                            <svg className="Rectangle_2057_g_pd">
                                                                <rect id="Rectangle_2057_g_pd" rx="7" ry="7" x="0" y="0" width="84" height="18">
                                                                </rect>
                                                            </svg>
                                                        </div>
                                                        <div id="success__ha_pd">
                                                            <span>success </span>
                                                        </div>
                                                        <div id="Layer_2_hb_pd">
                                                            <div id="checkmark_hc_pd">
                                                                <svg className="Rectangle_2122_hd_pd">
                                                                    <rect id="Rectangle_2122_hd_pd" rx="0" ry="0" x="0" y="0" width="18" height="18">
                                                                    </rect>
                                                                </svg>
                                                                <svg className="Path_9092_he_pd" viewBox="3.999 5.956 12.298 9.233">
                                                                    <path id="Path_9092_he_pd" d="M 8.492274284362793 15.18946647644043 C 8.279788017272949 15.18878364562988 8.077116012573242 15.09994220733643 7.932625770568848 14.94414138793945 L 4.206743717193604 10.98060131072998 C 3.916711807250977 10.67151546478271 3.932157278060913 10.18583393096924 4.2412428855896 9.895801544189453 C 4.550328254699707 9.605769157409668 5.036009311676025 9.621214866638184 5.326041698455811 9.930300712585449 L 8.484606742858887 13.29586124420166 L 14.93206882476807 6.242752075195312 C 15.11035251617432 6.020743370056152 15.39699077606201 5.915829658508301 15.6764669418335 5.970292091369629 C 15.95594310760498 6.024755477905273 16.18221473693848 6.229621887207031 16.26408958435059 6.502328872680664 C 16.34596633911133 6.775036811828613 16.26995849609375 7.070656776428223 16.06669998168945 7.270052909851074 L 9.059587478637695 14.9364767074585 C 8.916444778442383 15.09511184692383 8.713593482971191 15.18681240081787 8.49993896484375 15.18946933746338 Z">
                                                                    </path>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="Group_4614_pd">
                                                        <div id="Group_3969_hg_pd">
                                                            <svg className="Rectangle_2057_hh_pd">
                                                                <rect id="Rectangle_2057_hh_pd" rx="7" ry="7" x="0" y="0" width="93" height="18">
                                                                </rect>
                                                            </svg>
                                                        </div>
                                                        <div id="funds_transferred__pd">
                                                            <span>funds transferred </span>
                                                        </div>
                                                    </div>
                                                    <svg className="Line_343_pd" viewBox="0 0 0.20000000298023224 144">
                                                        <path id="Line_343_pd" d="M 0 0 L 0 144">
                                                        </path>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>



                                    </>


                                )}




                            </div>
                            <div id="Group_5164_pd">
                                <div id="Group_4590_f_pd">
                                    <div id="All_f_pd">
                                        <span>{PayoutDetail['amount']}.00</span>
                                    </div>
                                    <div id="All_ga_pd">
                                        <span>{PayoutDetail['currency']} </span>
                                    </div>
                                </div>
                                <svg className="Line_339_pd" viewBox="0 0 1344 0.5">
                                    <path id="Line_339_pd" d="M 0 0 L 1344 0">
                                    </path>
                                </svg>
                                <svg className="Line_340_pd" viewBox="0 0 0.20000000298023224 104">
                                    <path id="Line_340_pd" d="M 0 0 L 0 104">
                                    </path>
                                </svg>
                                <svg className="Line_341_pd" viewBox="0 0 0.20000000298023224 104">
                                    <path id="Line_341_pd" d="M 0 0 L 0 104">
                                    </path>
                                </svg>
                                <div id="Group_3393_pd">
                                    <div id="n_5_Nov__104505_AM_pd">
                                        <span>{dateFormatter(PayoutDetail['created_at'])} </span>
                                    </div>
                                    <div id="Date_pd">
                                        <span>Date</span>
                                    </div>
                                </div>
                                <div id="Group_4602_pd">
                                    <div id="Whole_Sale_Africa_pd">
                                        <span>Whole Sale Africa</span>
                                    </div>
                                    <div id="Recipient__Name_pd">
                                        <span>{MerchantInfo['mobile_number']}</span>
                                    </div>
                                </div>
                                <div id="Group_4603_pd">
                                    <div id="One_Time_Payment_pd">
                                        <span>{PayoutDetail['reference']} </span>
                                    </div>
                                    <div id="Expense_Type_pd">
                                        <span>Payout Reference</span>
                                    </div>
                                </div>
                                <div id="Group_4599_pd">
                                    <div id="Group_3969_pd">
                                        <svg className="Rectangle_2057_gq_pd">
                                            <rect id="Rectangle_2057_gq_pd" rx="5" ry="5" x="0" y="0" width="93" height="30">
                                            </rect>
                                        </svg>
                                    </div>
                                    <div id="success__pd">
                                        <span>success </span>
                                    </div>
                                    <div id="Layer_2_gs_pd">
                                        <div id="checkmark_pd">
                                            <svg className="Rectangle_2122_pd">
                                                <rect id="Rectangle_2122_pd" rx="0" ry="0" x="0" y="0" width="18" height="18">
                                                </rect>
                                            </svg>
                                            <svg className="Path_9092_pd" viewBox="3.999 5.956 12.298 9.233">
                                                <path id="Path_9092_pd" d="M 8.492274284362793 15.18946647644043 C 8.279788017272949 15.18878364562988 8.077116012573242 15.09994220733643 7.932625770568848 14.94414138793945 L 4.206743717193604 10.98060131072998 C 3.916711807250977 10.67151546478271 3.932157278060913 10.18583393096924 4.2412428855896 9.895801544189453 C 4.550328254699707 9.605769157409668 5.036009311676025 9.621214866638184 5.326041698455811 9.930300712585449 L 8.484606742858887 13.29586124420166 L 14.93206882476807 6.242752075195312 C 15.11035251617432 6.020743370056152 15.39699077606201 5.915829658508301 15.6764669418335 5.970292091369629 C 15.95594310760498 6.024755477905273 16.18221473693848 6.229621887207031 16.26408958435059 6.502328872680664 C 16.34596633911133 6.775036811828613 16.26995849609375 7.070656776428223 16.06669998168945 7.270052909851074 L 9.059587478637695 14.9364767074585 C 8.916444778442383 15.09511184692383 8.713593482971191 15.18681240081787 8.49993896484375 15.18946933746338 Z">
                                                </path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div id="Group_6591_pd">
                                <div id="Group_4738_pd">
                                    <div id="Group_3969_i_pd">
                                        <svg className="Rectangle_2057_i_pd">
                                            <rect id="Rectangle_2057_i_pd" rx="5" ry="5" x="0" y="0" width="135" height="35">
                                            </rect>
                                        </svg>
                                    </div>
                                </div>
                                <div id="PAYOUT_Detail_pd">
                                    <span>PAYOUT Detail</span>
                                </div>
                            </div>
                        </div>
                    </div>


                </main>
                <Wrapper navigation="Wallet"></Wrapper>
            </MediaQuery>


            <MediaQuery maxWidth={1000}>



                <div className="mobilepayoutdetail_pd_mob">
                    <div className="mobilepayoutdetail-inner_pd_mob">
                        <div className="group-wrapper_pd_mob">
                            <div className="group-wrapper_pd_mob">
                                <div className="payoutdetail_pd_mob">
                                    <div className="header_pd_mob">
                                        <div className="frame-parent_pd_mob">
                                            <div className="group-container_pd_mob">
                                                <NavLink to={"/successfulpayouts"}>

                                                    <div className="rectangle-parent_pd_mob">
                                                        <div className="group-child_pd_mob" />

                                                        <svg className="path-9390-icon_pd_mob" viewBox="0.636 0.05 10.513 18">
                                                            <path id="Path_9390_pd_mob" d="M 5.357423305511475 10.11950778961182 C 4.766643047332764 9.528789520263672 4.766643047332764 8.571026802062988 5.357423305511475 7.980309963226318 L 10.7065315246582 2.631746768951416 C 11.29722785949707 2.041119337081909 11.2972297668457 1.083506107330322 10.7065315246582 0.4928695559501648 L 10.7065315246582 0.4928695559501648 C 10.11583614349365 -0.09776583313941956 9.158125877380371 -0.09776552766561508 8.567437171936035 0.4928695559501648 L 1.079220414161682 7.980309963226318 C 0.4884408712387085 8.571026802062988 0.4884408712387085 9.528774261474609 1.079220414161682 10.11950778961182 L 8.567437171936035 17.60687637329102 C 9.158125877380371 18.19756317138672 10.11583614349365 18.19756317138672 10.7065315246582 17.60687637329102 L 10.7065315246582 17.60687637329102 C 11.2972297668457 17.0163402557373 11.2972297668457 16.05868339538574 10.7065315246582 15.46799468994141 L 5.357423305511475 10.11950778961182 Z">
                                                            </path>
                                                        </svg>
                                                    </div>
                                                </NavLink>

                                            </div>
                                            <div className="payment-detail_pd_mob">payout detail</div>
                                        </div>
                                    </div>
                                    <div className="payoutbio_pd_mob">
                                        <div className="kes-15045689-parent_pd_mob">
                                            <div className="kes-15045689_pd_mob">{PayoutDetail['currency']}.{PayoutDetail['amount']}.00</div>
                                            <div className="wallet-balance_pd_mob">
                                                {PayoutDetail['slug']}
                                            </div>



                                            {PayoutDetail.payout_type === 'DOMESTIC' && (
                                                <div className="next-billing-22_pd_mob">
                                                    {PayoutDetail['payout_type'] === 'Mpesa' ? 'Mpesa' : (PayoutDetail['destination'] && PayoutDetail['destination'].split('|')[0])}
                                                </div>

                                            )}


                                            {PayoutDetail.payout_type === 'MPESA' && (
                                                <div className="next-billing-22_pd_mob">
                                                    {PayoutDetail['destination']}
                                                </div>

                                            )}
                                            <div className="group-parent_pd_mob">
                                                <div className="rectangle-wrapper_pd_mob">
                                                    <div className="group-item_pd_mob" />
                                                </div>
                                                <div className="active_pd_mob">{PayoutDetail['status']}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="copylink_pd_mob">
                                        <div className="view-withdrawal-receipt-wrapper_pd_mob">
                                            <div className="view-withdrawal-receipt_pd_mob">
                                                View Withdrawal Receipt
                                            </div>
                                        </div>
                                        <div className="copylink-inner_pd_mob">
                                            <div className="frame-wrapper_pd_mob">
                                                <div className="copied-wrapper_pd_mob" style={{ display: showDiv ? "block" : "none" }}>
                                                    <b className="mobilepayoutdetail-inner_pd_mob">{`Copied! `}</b>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="frame-group_pd_mob">
                                            <div className="frame-container_pd_mob">
                                                <div className="httpscheckoutchptercodri-wrapper_pd_mob">
                                                    <div className="httpscheckoutchptercodri_pd_mob">
                                                        {`${process.env.REACT_APP_BASE_URL}/successfulpayoutsreceipt/${PayoutDetail['slug']}`}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="copy-link-wrapper_pd_mob" onClick={handleCopy}>
                                                <b className="copy-link_pd_mob">Copy link</b>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="payoutdetails_pd_mob">
                                        <div className="payoutdetails-inner_pd_mob">
                                            <div className="frame-div_pd_mob">
                                                <div className="all-wrapper_pd_mob">
                                                    <div className="all_pd_mob">Payout Details</div>
                                                </div>
                                                <div className="payoutdetails-inner_pd_mob">

                                                    <svg className="line-icon_pd_mob" viewBox="0 1 414 0.5">
                                                        <path id="Line_pd_mob" d="M 0 1 L 414 1">
                                                        </path>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="payoutdetails-child_pd_mob">
                                            <div className="frame-parent1_pd_mob">
                                                <div className="reference-parent_pd_mob">
                                                    <div className="copy-link_pd_mob">Payout Type</div>
                                                    <div className="adidas-ultraboost-wrapper_pd_mob">
                                                        <div className="adidas-ultraboost_pd_mob">
                                                            {PayoutDetail['payout_type']}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="reference-parent_pd_mob">
                                                    <div className="copy-link_pd_mob">Destination</div>
                                                    <div className="adidas-ultraboost-wrapper_pd_mob">
                                                        <div className="adidas-ultraboost_pd_mob">
                                                            {PayoutDetail['payout_type'] === 'Mpesa' ? 'Mpesa' : (PayoutDetail['destination'] && PayoutDetail['destination'].split('|')[0])}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="reference-parent_pd_mob">
                                                    <div className="copy-link_pd_mob">Amount</div>
                                                    <div className="adidas-ultraboost-frame_pd_mob">
                                                        <div className="adidas-ultraboost2_pd_mob">{PayoutDetail['currency']} {PayoutDetail['amount']}.00</div>
                                                    </div>
                                                </div>
                                                <div className="reference-parent_pd_mob">
                                                    <div className="copy-link_pd_mob">Fee</div>
                                                    <div className="adidas-ultraboost-frame_pd_mob">
                                                        <div className="adidas-ultraboost2_pd_mob"> KES 0.00</div>
                                                    </div>
                                                </div>
                                                <div className="reference-parent_pd_mob">
                                                    <div className="copy-link_pd_mob">Date</div>
                                                    <div className="adidas-ultraboost-frame_pd_mob">
                                                        <div className="adidas-ultraboost2_pd_mob">
                                                            {datetimeFormatter_human(PayoutDetail['created_at'])}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="reference-parent_pd_mob">
                                                    <div className="copy-link_pd_mob">Reference</div>
                                                    <div className="adidas-ultraboost-wrapper_pd_mob">
                                                        <div className="adidas-ultraboost5_pd_mob">
                                                            {PayoutDetail['reference']}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="reference-parent_pd_mob">
                                                    <div className="copy-link_pd_mob">Status</div>
                                                    <div className="frame-wrapper1_pd_mob">
                                                        <div className="frame-parent2_pd_mob">
                                                            <div className="frame-child_pd_mob" />
                                                            <div className="success_pd_mob">{PayoutDetail['status']}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accountdetails_pd_mob">
                                        <div className="payoutdetails-inner_pd_mob">
                                            <div className="frame-div_pd_mob">
                                                <div className="all-wrapper_pd_mob">
                                                    <div className="all_pd_mob">Account Details</div>
                                                </div>
                                                <div className="payoutdetails-inner_pd_mob">
                                                    <svg className="line-icon_pd_mob" viewBox="0 1 414 0.5">
                                                        <path id="Line_pd_mob" d="M 0 1 L 414 1">
                                                        </path>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>

                                        {PayoutDetail.payout_type === 'DOMESTIC' && (
                                            <div className="payoutdetails-child_pd_mob">


                                                <div className="frame-parent1_pd_mob">
                                                    <div className="reference-parent_pd_mob">
                                                        <div className="copy-link_pd_mob">Bank</div>
                                                        <div className="adidas-ultraboost-wrapper_pd_mob">
                                                            <div className="adidas-ultraboost_pd_mob">{PayoutDetail['payout_type'] === 'Mpesa' ? 'Mpesa' : (PayoutDetail['destination'] && PayoutDetail['destination'].split('|')[0])}</div>
                                                        </div>
                                                    </div>
                                                    <div className="reference-parent_pd_mob">
                                                        <div className="copy-link_pd_mob">Bank Code</div>
                                                        <div className="adidas-ultraboost-wrapper_pd_mob">
                                                            <div className="adidas-ultraboost_pd_mob">{PayoutDetail['destination'] && PayoutDetail['destination'].split('|')[1].split(':')[0].trim()}</div>
                                                        </div>
                                                    </div>
                                                    <div className="reference-parent_pd_mob">
                                                        <div className="copy-link_pd_mob">Bank Account No.</div>
                                                        <div className="adidas-ultraboost-frame_pd_mob">
                                                            <div className="adidas-ultraboost2_pd_mob">
                                                                {/* {PayoutDetail['destination'] && PayoutDetail['destination'].split(':')[1].replace(/.(?=.{4})/g, "*")} */}
                                                                {PayoutDetail['destination'] && PayoutDetail['destination'].split(':')[1].replace(/.(?=.{4})/g, "*")}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="reference-parent_pd_mob">
                                                        <div className="copy-link_pd_mob">Bank Account Name</div>
                                                        <div className="adidas-ultraboost-frame_pd_mob">
                                                            <div className="adidas-ultraboost2_pd_mob">
                                                            {PayoutDetail['destination'] && PayoutDetail['destination'].split("|")[1]?.split(":")[2]?.trim()}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="reference-parent_pd_mob">
                                                        <div className="copy-link_pd_mob">Country</div>
                                                        <div className="adidas-ultraboost-frame_pd_mob">
                                                            <div className="adidas-ultraboost2_pd_mob">Kenya</div>
                                                        </div>
                                                    </div>
                                                </div>



                                            </div>
                                        )}
                                        {PayoutDetail.payout_type === 'MPESA' && (

                                            <div className="payoutdetails-child_pd_mob">


                                                <div className="frame-parent1_pd_mob">
                                                    <div className="reference-parent_pd_mob">
                                                        <div className="copy-link_pd_mob">Recipient Phone</div>
                                                        <div className="adidas-ultraboost-wrapper_pd_mob">
                                                            <div className="adidas-ultraboost_pd_mob">{PayoutDetail.destination}</div>
                                                        </div>
                                                    </div>
                                                    <div className="reference-parent_pd_mob">
                                                        <div className="copy-link_pd_mob">Country</div>
                                                        <div className="adidas-ultraboost-frame_pd_mob">
                                                            <div className="adidas-ultraboost2_pd_mob">Kenya</div>
                                                        </div>
                                                    </div>
                                                </div>



                                            </div>
                                        )}


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>



        </>
    )
};

export default Wallet;

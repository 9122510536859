export const bankslistKE= 

    [
        {bank_name: "ABC Bank", bank_code: 35, company_name: "African Banking Corp. Bank Ltd"},
        {bank_name: "ABSA Bank", bank_code: 3, company_name: "Absa Bank Kenya PLC"},
        {bank_name: "Access Bank", bank_code: 26, company_name: "Access Bank Kenya PLC"},
        {bank_name: "Bank of Africa", bank_code: 19, company_name: "Bank of  Africa Kenya Limited"},
        {bank_name: "Bank of India", bank_code: 5, company_name: "Bank of India"},
        {bank_name: "Central Bank", bank_code: 9, company_name: "Central Bank of  Kenya"},
        {bank_name: "Chase Bank", bank_code: 30, company_name: "Chase Bank Limited"},
        {bank_name: "Citi Bank", bank_code: 16, company_name: "Citibank N A"},
        {bank_name: "Consolidated Bank", bank_code: 23, company_name: "Consolidated Bank of Kenya Limited"},
        {bank_name: "Coop Bank", bank_code: 11, company_name: "Co-operative bank of Kenya"},
        {bank_name: "Credit Bank", bank_code: 25, company_name: "Credit Bank Limited"},
        {bank_name: "Development Bank", bank_code: 59, company_name: "Development Bank of Kenya Limited"},
        {bank_name: "DIB Bank", bank_code: 75, company_name: "DIB Bank Kenya Limited"},
        {bank_name: "DTB Bank", bank_code: 63, company_name: "Diamond Trust Bank Limited"},
        {bank_name: "ECOBANK", bank_code: 43, company_name: "ECO Bank Limited"},
        {bank_name: "Equity Bank", bank_code: 68, company_name: "Equity Bank Limited"},
        {bank_name: "Family Bank", bank_code: 70, company_name: "Family Bank Ltd"},
        {bank_name: "Faulu Bank", bank_code: 79, company_name: "Faulu Microfinance Bank Ltd"},
        {bank_name: "First Community Bank", bank_code: 74, company_name: "First Community Bank"},
        {bank_name: "Guaranty Trust Bank", bank_code: 53, company_name: "Guaranty Trust Bank ( Kenya) Ltd."},
        {bank_name: "Guardian Bank", bank_code: 55, company_name: "Guardian Bank Limited"},
        {bank_name: "Gulf African Bank", bank_code: 72, company_name: "Gulf African Bank Ltd"},
        {bank_name: "Habib Bank", bank_code: 17, company_name: "Habib Bank A G  Zurich"},
        {bank_name: "Housing Finance Bank", bank_code: 61, company_name: "Housing Finance Bank"},
        {bank_name: "I&M Bank", bank_code: 57, company_name: "I&M Bank Limited"},
        {bank_name: "KCB Bank", bank_code: 1, company_name: "Kenya Commercial Bank Limited"},
        {bank_name: "Kenya Post Office Savings", bank_code: 62, company_name: "Kenya Post Office Savings Bank"},
        {bank_name: "KWFT", bank_code: 78, company_name: "Kenya Women Microfinance Bank PLC"},
        {bank_name: "Kingdom Bank", bank_code: 51, company_name: "Kingdom Bank Limited"},
        {bank_name: "M-Oriental", bank_code: 14, company_name: "M-Oriental Bank Limited"},
        {bank_name: "Mayfair CIB Bank", bank_code: 65, company_name: "Mayfair CIB Bank Limited"},
        {bank_name: "Middle East Bank", bank_code: 18, company_name: "Middle East Bank Kenya Limited"},
        {bank_name: "National Bank", bank_code: 12, company_name: "National Bank of Kenya Limited"},
        {bank_name: "NCBA Bank", bank_code: 7, company_name: "NCBA Bank Kenya PLC"},
        {bank_name: "Paramount Bank", bank_code: 50, company_name: "Paramount Universal Bank Limited"},
        {bank_name: "Prime Bank", bank_code: 10, company_name: "Prime Bank Limited"},
        {bank_name: "SBM Bank", bank_code: 60, company_name: "SBM Bank Kenya Limited"},
        {bank_name: "Sidian Bank", bank_code: 66, company_name: "Sidian Bank Limited"},
        {bank_name: "Spire Bank", bank_code: 49, company_name: "Spire Bank Ltd"},
        {bank_name: "Stanbic Bank", bank_code: 31, company_name: "Stanbic Bank Kenya Limited"},
        {bank_name: "Stanchart Bank", bank_code: 2, company_name: "Standard Chartered Bank Kenya Limited"},
        {bank_name: "UBA Bank", bank_code: 76, company_name: "UBA Kenya Bank Ltd"},
        {bank_name: "Victoria Bank", bank_code: 54, company_name: "Victoria Commercial Bank Limited"}
    ]




import '../../styles/Welcome/emailverification.css';
import React, { SyntheticEvent } from 'react'
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { NavLink, useLocation } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import 'react-toastify/dist/ReactToastify.css';
import '../../styles/Components/Toastify.css'

const EmailVerificationActionRequired = () => {
    const location = useLocation();
    const { email } = location.state;

    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();

        let api_url = process.env.REACT_APP_CHPTER_API_URL + '/merchants/account/email-verify-resend/' + { email }.email
        let headers = {
            "Content-Type": "application/json"
        };


        await axios.put(api_url, null, { headers: headers })
            .then((response) => {


                if (response.status === 200) {
                    toast.success('Verification Email sent', {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                    });
                }

            })
            .catch((error) => {
                // Error
                if (error.response) {

                } else if (error.request) {

                } else {

                }
            });

    }


    return (
        <>
            <MediaQuery minWidth={1001}>
                <ToastContainer position="top-right" autoClose={9000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false}
                    pauseOnFocusLoss draggable pauseOnHover toastClassName="custom-toast" className="toast-container" />
                <div id="EmailVerified_ev">
                    <div id="EmailVerified_Body_ev">
                        <div id="continue_ev">
                            <span>continue</span>
                        </div>
                        <div id="Group_4528_ev">
                            <div id="Group_3168_ev">
                                <div id="chpter_ev">
                                    <span>chpter.</span>
                                </div>
                            </div>
                        </div>
                        <div id="Group_4965_ev">
                            <div id="Group_4541_ev">
                                <div id="Group_3168_es_ev">
                                    <div id="Account_successfully__activate_ev">
                                        <span>Verify your email</span>
                                    </div>
                                </div>
                            </div>
                            <div id="Thank_you_for_registering_and__ev" style={{ top: "114px" }}>
                                <span>We've sent a verification step to<br />your email address.
                                    <br /> Click the link sent to your email to continue. <br />
                                </span>
                            </div>
                            <div id="Thank_you_for_registering_and__ev" style={{ top: "224px", fontSize: "14px" }}>
                                <span>To resend the email, click the button below
                                </span>
                            </div>
                        </div>
                        <div id="Group_4531_ev">
                            <div id="chpter_ew_ev">
                                <span>chpter.</span>
                            </div>
                            <div id="Contact_ev">
                                <span>Contact</span>
                            </div>
                            <div id="Privacy_Policy_ev">
                                <span>Privacy Policy</span>
                            </div>
                            <div id="Group_4532_ev">
                                <div id="Layer_2_ev">
                                    <div id="radio-button-off_ev">
                                        <svg className="Rectangle_2094_ev">
                                            <rect id="Rectangle_2094_ev" rx="0" ry="0" x="0" y="0" width="15" height="15">
                                            </rect>
                                        </svg>
                                    </div>
                                </div>
                                <div id="c_ev">
                                    <span>©</span>
                                </div>
                            </div>
                        </div>
                        <div id="Group_3969_ev" onClick={submit}>
                            <svg className="Rectangle_2057_e_ev">
                                <linearGradient id="Rectangle_2057_e" spreadMethod="pad" x1="0.066" x2="0.79" y1="0" y2="1.3">
                                    <stop offset="0" stopColor="#000" stopOpacity="1"></stop>
                                    <stop offset="1" stopColor="#000" stopOpacity="1"></stop>
                                </linearGradient>
                                <rect id="Rectangle_2057_e_ev" rx="7" ry="7" x="0" y="0" width="356" height="62">
                                </rect>
                            </svg>
                            <div id="Take_me_home_ev">
                                <span>Resend Email</span>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>

            <MediaQuery maxWidth={1000}>
                <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false}
                    pauseOnFocusLoss draggable pauseOnHover toastClassName="custom-toast" className="toast-container" />
                <div className="mobileverifyemail_ev">
                    <div className="mobileverifyemail-inner_ev">
                        <div className="group-frame_ev">
                            <div className="group-frame_ev">
                                <div className="verifyemail_ev">
                                    <div className="header_ev">
                                        <div className="chpter1_ev">chpter.</div>
                                    </div>
                                    <div className="body_ev">
                                        <div className="account-successfully-activate_ev">
                                            Verify your email
                                        </div>
                                        <div className="weve-sent-a-container_ev">
                                            <p className="weve-sent-a_ev">
                                                We’ve sent a verification step to
                                            </p>
                                            <p className="weve-sent-a_ev">your email address.</p>
                                            <p className="weve-sent-a_ev">
                                                Click the link sent to your email to continue.
                                            </p>
                                        </div>
                                        <div className="thank-you-for_ev">
                                            To resend the email, click the button below
                                        </div>
                                    </div>
                                    <div className="submitbutton_ev">
                                        <div className="take-me-home-wrapper_ev" onClick={submit}>
                                            <div className="mobileverifyemail-inner_ev">Resend Email</div>
                                        </div>
                                    </div>
                                    <div className="footer_ev">
                                        <div className="contact_ev">© chpter.</div>
                                        <div className="contact_ev">Contact</div>
                                        <div className="contact_ev">Privacy Policy</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>

        </>
    )
}
export default EmailVerificationActionRequired;
import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import ShopifyMainComponent from './ShopifyMainComponent';

const ShopifyHotLinksProducts = ({ handleExport }: { handleExport: () => void }) => {

    const [titleSelected, setTitleSelected] = useState(1)
    const handleNavLinkClick = (index: React.SetStateAction<number>) => {
        setTitleSelected(index);
    }


    const location = useLocation();
    const currentRoutePath = location.pathname;
    useEffect(() => {
        if (currentRoutePath === '/shopifyproducts') {
            setTitleSelected(1);

        } else if (currentRoutePath === '/archivedproducts') {
            setTitleSelected(2);

        } else if (currentRoutePath === '/categories') {
            setTitleSelected(3);

        } else if (currentRoutePath === '/subcategories') {
            setTitleSelected(4);
        }
    })

    return (
        <>
            <ShopifyMainComponent></ShopifyMainComponent>

            <div id="ShopifyNavigationButtons_shopify">
                <div id="Group_4972_shopify" onClick={() => handleExport()}>
                    <div id="Group_4117_shopify">
                        <svg className="Rectangle_1977_shopify">
                            <rect id="Rectangle_1977_shopify" rx="5" ry="5" x="0" y="0" width="75" height="32">
                            </rect>
                        </svg>
                        <div id="Export_shopify">
                            <span>Export</span>
                        </div>
                    </div>
                </div>
                <ul className="nav flex-column">
                    <svg className="Line_355_shopify" viewBox="0 0 1383 1">
                        <path id="Line_355_shopify" d="M 0 0 L 1383 0">
                        </path>
                    </svg>
                    <NavLink to={'/shopifyproducts'} className="nav-link" style={{ textDecoration: 'none' }}>
                        <div id="All_gc_shopify" key={1} onClick={() => handleNavLinkClick(1)} className={`div ${1 === titleSelected ? 'bold-title' : ''}`} >
                            <span>products</span>
                        </div>
                    </NavLink>
                    
                </ul>
            </div>
        </>
    )
}

export default ShopifyHotLinksProducts;

import axios from 'axios';
import React, { useEffect, useState, SyntheticEvent } from 'react';
import { Navigate, NavLink } from 'react-router-dom';
import '../../styles/Settings/settingsbankaccounts_add.css'
import '../../styles/Components/NavBar.css'
import '../../styles/Components/UserRibbon.css'
import '../../styles/Components/HotLinks_Settings.css'
import Wrapper from "../../components/Wrapper";
import HotLinks_Settings from '../../components/HotLinks_Settings';
import MediaQuery from "react-responsive";

import { bankslistKE } from "../../utils/banks-list";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GoogleMapsAPIBankAccountDesktop } from '../../utils/google-maps-api';
import { GoogleMapsAPIBankAccountMobile } from '../../utils/google-maps-api';
import 'react-toastify/dist/ReactToastify.css';
import '../../styles/Components/Toastify.css'


const SettingsAddBankAccount = () => {
    const initialValues = { wallet_type: "", bank_name: "", bank_code: "", bank_account_name: "", bank_account_number: "", bank_branch: "" };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState<Record<string, string>>({});
    const [bank_name, setBankName] = useState('');
    const [bank_code, setBankCode] = useState('');

    const [redirect, setredirect] = useState(false)

    const handleChange = (e: { target: { name: any; value: any; }; }) => {

        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });

    };

    const handleBankChange = (event: { target: { name: any; value: any; }; }) => {
        const { name, value } = event.target;

        // Split the value into the country code and country name
        const [bank_name, bank_code] = value.split('|');
        setFormValues({ ...formValues, [name]: value, bank_name: bank_name, bank_code: bank_code })

        // Set the state variables to the corresponding values
        setBankName(bank_name);
        setBankCode(bank_code);
    }

    const [location, setLocation] = useState([] as any);
    function handleLocationChange(newLocation: any) {
        setLocation(newLocation);
        setFormValues({ ...formValues, bank_branch: String(newLocation.address) })
    }

    const Validate = (Values: { wallet_type: any; bank_name: any; bank_account_name: any; bank_account_number: any; bank_branch: any; }) => {
        const errors: any = {};
        if (!Values.wallet_type) {
            toast("Select wallet type", { type: "error" });
            errors.wallet_type = "Select wallet type ";
        }
        else if (!Values.bank_name) {
            toast("Select Bank Account", { type: "error" });
            errors.bank_name = "Select Bank Account";
        }
        else if (!Values.bank_account_name) {
            toast("Bank account name required", { type: "error" });
            errors.bank_account_name = "Bank account name required";
        }
        else if (!Values.bank_account_number) {
            toast("Bank  account number required", { type: "error" });
            errors.bank_account_number = "Bank  account number required";
        }
        else if (!Values.bank_branch) {
            toast("Bank branch location required", { type: "error" });
            errors.bank_branch = "Bank branch location required";
        }




        setFormErrors(errors)
        if (Object.keys(errors).length > 0) {
            let errorMessage = "";
            for (const key in formErrors) {
                if (formErrors[key]) {
                    errorMessage += formErrors[key] + "\n";
                }
            }

        }
        return Object.keys(errors).length === 0;
    };



    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();
        const isValid = Validate(formValues);
        if (!isValid) {
            return;
        }

        let api_url = process.env.REACT_APP_CHPTER_API_URL + '/merchants/bankaccount'
        let headers = {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("JWT"),
        };

        const data = JSON.stringify(formValues)


        try {
            const response = await axios.post(api_url, data, { headers: headers });

            toast.success('Bank Added successfully!', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
            });

            setTimeout(() => {
                setredirect(true);
            }, 2000);

        }
        catch (error: any) {
            // Error
            if (error.response) {

                if (error.response.status === 401) {
                    //Redirect to login Token expired
                    window.location.href = '/login';
                }
            } else if (error.request) {

            } else {
 
            }
        };

    }

    if (redirect) {
        return <Navigate to={'/settings-bankaccounts'} />

    }


    return (
        <>
            <MediaQuery minWidth={1001}>
            
                <main className="form-signin">
                    <div id="Group_6924_addbnk">
                        <div id="Group_4741_addbnk" onClick={submit}>
                            <div id="Group_3969_addbnk">
                                <svg className="Rectangle_2057_addbnk">
                                    <rect id="Rectangle_2057_addbnk" rx="5" ry="5" x="0" y="0" width="169.925" height="30">
                                    </rect>
                                </svg>
                            </div>
                            <div id="Group_4628_addbnk">
                                <div id="SEND_INVITE_addbnk">
                                    <span>SAVE CHANGES</span>
                                </div>
                            </div>
                        </div>
                        <NavLink to={'/settings-bankaccounts'}>
                            <div id="Group_4742_addbnk">
                                <div id="Group_3969_fj_addbnk">
                                    <svg className="Rectangle_2057_fk_addbnk">
                                        <rect id="Rectangle_2057_fk_addbnk" rx="5" ry="5" x="0" y="0" width="75" height="30">
                                        </rect>
                                    </svg>
                                </div>
                                <div id="Group_4628_fl_addbnk">
                                    <div id="CANCEL_addbnk">
                                        <span>CANCEL</span>
                                    </div>
                                </div>
                            </div>
                        </NavLink>
                    </div>
                    <div id="All_addbnk">
                        <span>Bank Account</span>
                    </div>
                    <div id="Group_7147_addbnk">
                        <div id="Group_7146_addbnk">
                            <input id="Rectangle_2002_addbnk" type="NUMBER" name="bank_account_number" placeholder="098765432109876" required
                                onChange={handleChange} />

                        </div>
                        <div id="Bank_Account_Number_addbnk">
                            <span>Bank Account Number</span>
                        </div>
                    </div>
                    <div id="Group_7151_addbnk">
                        <div id="Select_Your_Wallet_addbnk">
                            <span>Select Your Wallet</span>
                        </div>
                        <div id="Group_7150_addbnk">
                            <select id="Rectangle_2002_fw_addbnk" placeholder="Select Wallet Type" name="wallet_type" onChange={handleChange}>
                                <option disabled selected value="">Select Wallet Type</option>
                                <option value="KES">KES Wallet</option>
                                <option value="US">USD Wallet</option>
                                {/* <option value="GBP">GBP Wallet</option> */}
                            </select>
                        </div>
                    </div>
                    <div id="Group_7152_addbnk">
                        <div id="Select_Your_Bank_Account_addbnk">
                            <span>Select Your Bank Account</span>
                        </div>
                        <div id="Group_7149_addbnk">
                            <select id="Rectangle_2002_f_addbnk" name="bank_name" placeholder="Bank Name" onChange={handleBankChange} required>
                                <option disabled selected value="">Select Bank Account</option>
                                {bankslistKE.map(banks => (
                                    <option key={banks.bank_code} value={`${banks.bank_name}|${banks.bank_code}`}>{banks.bank_name}</option>
                                ))}
                            </select>
                            <div id="ABSA_BANK_KENYA_addbnk">
                                <span>ABSA BANK KENYA</span>
                            </div>
                        </div>
                    </div>
                    <div id="Group_7089_addbnk">
                        <input id="Rectangle_2002_ga_addbnk" type="text" name="bank_branch" placeholder="Bank Branch Location" required
                            onChange={handleChange} />
                        <div id="Location_addbnk">
                            <span>Location</span>
                        </div>
                        {/* <GoogleMapsAPIBankAccountDesktop onChange={handleLocationChange}/> */}
                    </div>
                    <div id="Group_7148_addbnk">
                        <div id="Group_7145_addbnk">
                            <input id="Rectangle_2002_gb_addbnk" type="text" name="bank_account_name" placeholder="Bank Account Name" required
                                onChange={handleChange} />
                        </div>
                        <div id="Bank_Account_Name_addbnk">
                            <span>Bank Account Name</span>
                        </div>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={9000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick={false}
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        toastClassName="custom-toast"
                        className="toast-container"
                        style={{
                            top: "10%",
                            color: "white",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                        }}
                    />
                </main>
                <HotLinks_Settings innerPage="AddBank"></HotLinks_Settings>
                <Wrapper navigation='Settings' ></Wrapper>
            </MediaQuery>


            <MediaQuery maxWidth={1000}>
                {/* <div id="MobileEditBankAccount_bnk_mob">
                    <div id="All_bnk_mob">
                        <span>Bank Account</span>
                    </div>
                    <NavLink to={'/settings-bankaccounts'} className="nav-link" style={{ textDecoration: 'none' }}>

                        <div id="Group_7465_bnk_mob">
                            <svg className="Rectangle_2103_bnk_mob">
                                <rect id="Rectangle_2103_bnk_mob" rx="5" ry="5" x="0" y="0" width="32" height="32">
                                </rect>
                            </svg>
                            <svg className="Path_9390_bnk_mob" viewBox="0.636 0.05 10.513 18">
                                <path id="Path_9390_bnk_mob" d="M 5.357423305511475 10.11950778961182 C 4.766643047332764 9.528789520263672 4.766643047332764 8.571026802062988 5.357423305511475 7.980309963226318 L 10.7065315246582 2.631746768951416 C 11.29722785949707 2.041119337081909 11.2972297668457 1.083506107330322 10.7065315246582 0.4928695559501648 L 10.7065315246582 0.4928695559501648 C 10.11583614349365 -0.09776583313941956 9.158125877380371 -0.09776552766561508 8.567437171936035 0.4928695559501648 L 1.079220414161682 7.980309963226318 C 0.4884408712387085 8.571026802062988 0.4884408712387085 9.528774261474609 1.079220414161682 10.11950778961182 L 8.567437171936035 17.60687637329102 C 9.158125877380371 18.19756317138672 10.11583614349365 18.19756317138672 10.7065315246582 17.60687637329102 L 10.7065315246582 17.60687637329102 C 11.2972297668457 17.0163402557373 11.2972297668457 16.05868339538574 10.7065315246582 15.46799468994141 L 5.357423305511475 10.11950778961182 Z">
                                </path>
                            </svg>
                        </div>
                    </NavLink>
                    <div id="Group_7772_bnk_mob">
                        <div id="Group_7769_bnk_mob">
                            <div id="Group_7768_bnk_mob">

                                <input id="Rectangle_2002_bnk_mob" type="NUMBER" name="bank_account_number" placeholder="098765432109876" onChange={handleChange} required />

                            </div>
                            <div id="Bank_Account_Number_bnk_mob">
                                <span>Bank Account Number</span>
                            </div>
                        </div>
                        <div id="Group_7771_bnk_mob">
                            <div id="Group_7770_bnk_mob">
                                <input id="Rectangle_2002_g_bnk_mob" type="text" name="bank_branch" placeholder="Bank Branch Location" onChange={handleChange} required />
                            </div>
                            <div id="Location_bnk_mob">
                                <span> Location </span>
                            </div>
                        </div>
                        <div id="Group_7767_bnk_mob">
                            <div id="Group_7766_bnk_mob">

                                <input id="Rectangle_2002_ha_bnk_mob" type="text" name="bank_account_name" placeholder="Account Name" onChange={handleChange} required />


                            </div>
                            <div id="Bank_Account_Name_bnk_mob">
                                <span>Account Name</span>
                            </div>
                        </div>
                        <div id="Group_7763_bnk_mob">
                            <div id="Select_Your_Wallet_bnk_mob">
                                <span>Select Your Wallet</span>
                            </div>
                            <div id="Group_7762_bnk_mob">
                                <select id="Rectangle_2002_hg_bnk_mob" placeholder="Select Wallet Type" name="wallet_type" onChange={handleChange} required>
                                    <option disabled selected value="">Select Wallet Type</option>
                                    <option value="KES">KES Wallet</option>
                                    <option value="US">USD Wallet</option>
                                
                                </select>


                            </div>

                        </div>
                        <div id="Group_7765_bnk_mob">
                            <div id="Select_Your_Bank_Account_bnk_mob">
                                <span>Select Your Bank Account</span>
                            </div>
                            <div id="Group_7764_bnk_mob"  >
                                <select id="Rectangle_2002_hm_bnk_mob" name="bank_name" onChange={handleBankChange} required>
                                    <option disabled selected value="">Select Bank Account</option>
                                    {bankslistKE.map(banks => (
                                        <option key={banks.bank_code} value={`${banks.bank_name}|${banks.bank_code}`}>{banks.bank_name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>

                    <div id="All_hp_bnk_mob">
                        <span>Add Bank Account</span>
                    </div>

                    <div id="Group_7642_bnk_mob">
                        <div id="Group_3969_bnk_mob">
                            <svg className="Rectangle_2057_bnk_mob">
                                <rect id="Rectangle_2057_bnk_mob" rx="5" ry="5" x="0" y="0" width="199" height="30">
                                </rect>
                            </svg>
                        </div>
                        <div id="Group_4628_bnk_mob" onClick={submit}>

                            <div id="SAVE_CHANGES_bnk_mob">
                                <span>SAVE CHANGES</span>
                            </div>

                        </div>
                    </div>
                    <div id="Group_7643_bnk_mob">
                        <div id="Group_3969_hw_bnk_mob">
                            <svg className="Rectangle_2057_hx_bnk_mob">
                                <rect id="Rectangle_2057_hx_bnk_mob" rx="5" ry="5" x="0" y="0" width="75" height="30">
                                </rect>
                            </svg>
                        </div>
                        <div id="Group_4628_hy_bnk_mob">

                            <div id="CANCEL_bnk_mob">
                                <span>CANCEL</span>
                            </div>

                        </div>
                    </div>
                </div> */}
                <div className="mobileaddbankaccount_bnk_mob">
                    <div className="mobileaddbankaccount-inner_bnk_mob">
                        <div className="group-wrapper_bnk_mob">
                            <div className="group-wrapper_bnk_mob">
                                <div className="frame-container_bnk_mob">
                                    <div className="header-parent_bnk_mob">
                                        <div className="header_bnk_mob">
                                            <div className="all_bnk_mob">Bank Account</div>
                                            <NavLink to={'/settings-bankaccounts'} >

                                            <div className="rectangle-parent_bnk_mob">
                                                <div className="group-child_bnk_mob" />

                                                <svg className="path-9390-icon_bnk_mob" viewBox="0.636 0.05 10.513 18">
                                                    <path id="Path_9390_bnk_mob" d="M 5.357423305511475 10.11950778961182 C 4.766643047332764 9.528789520263672 4.766643047332764 8.571026802062988 5.357423305511475 7.980309963226318 L 10.7065315246582 2.631746768951416 C 11.29722785949707 2.041119337081909 11.2972297668457 1.083506107330322 10.7065315246582 0.4928695559501648 L 10.7065315246582 0.4928695559501648 C 10.11583614349365 -0.09776583313941956 9.158125877380371 -0.09776552766561508 8.567437171936035 0.4928695559501648 L 1.079220414161682 7.980309963226318 C 0.4884408712387085 8.571026802062988 0.4884408712387085 9.528774261474609 1.079220414161682 10.11950778961182 L 8.567437171936035 17.60687637329102 C 9.158125877380371 18.19756317138672 10.11583614349365 18.19756317138672 10.7065315246582 17.60687637329102 L 10.7065315246582 17.60687637329102 C 11.2972297668457 17.0163402557373 11.2972297668457 16.05868339538574 10.7065315246582 15.46799468994141 L 5.357423305511475 10.11950778961182 Z">
                                                    </path>
                                                </svg>
                                            </div>
                                            </NavLink>

                                            <div className="all1_bnk_mob">Add Bank Account</div>
                                        </div>
                                        <div className="body_bnk_mob">
                                            <div className="inputfields_bnk_mob">
                                                <div className="select-your-wallet-parent_bnk_mob">
                                                    <div className="select-your-wallet_bnk_mob">
                                                        Select Your Wallet
                                                    </div>
                                                    <select className="kes-parent_bnk_mob"
                                                    placeholder="Select Wallet Type"
                                                    name="wallet_type"
                                                    onChange={handleChange} required
                                                    
                                                    >
                                                        <option disabled selected value="">Select Wallet Type</option>
                                                        <option value="KES_bnk_mob">KES</option>
                                                        <option value="USD_bnk_mob">USD</option>
                                                    </select>
                                                </div>
                                                <div className="select-your-wallet-parent_bnk_mob">
                                                    <div className="select-your-wallet_bnk_mob">
                                                        Select Your Bank Account
                                                    </div>
                                                    <select className="absa-bank-parent_bnk_mob"
                                                     name="bank_name"
                                                     onChange={handleBankChange} required
                                                    
                                                    >
                                                         <option disabled selected value="">Select Bank Account</option>
                                    {bankslistKE.map(banks => (
                                        <option key={banks.bank_code} value={`${banks.bank_name}|${banks.bank_code}`}>{banks.bank_name}</option>
                                    ))}
                                                    </select>
                                                </div>
                                                <div className="select-your-wallet-parent_bnk_mob">
                                                    <div className="select-your-wallet_bnk_mob">
                                                        Bank Account Name
                                                    </div>
                                                    <input
                                                        className="frame-child_bnk_mob"
                                                        type="text"
                                                        placeholder="Account Name"
                                                        name="bank_account_name"
                                                        onChange={handleChange} required 
                                                    />
                                                </div>
                                                <div className="select-your-wallet-parent_bnk_mob">
                                                    <div className="select-your-wallet_bnk_mob">
                                                        Bank Account Number
                                                    </div>
                                                    <input
                                                        className="frame-child_bnk_mob"
                                                        type="NUMBER"
                                                        placeholder="098765432109876"
                                                        name="bank_account_number"
                                                        onChange={handleChange} required 
                                                    />
                                                </div>
                                                <div className="select-your-wallet-parent_bnk_mob">
                                                    <div className="select-your-wallet_bnk_mob">Location</div>
                                                    <input
                                                        className="frame-child_bnk_mob"
                                                        type="text"
                                                        placeholder="Input Bank Location"
                                                        name="bank_branch"
                                                        onChange={handleChange} required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="footer_bnk_mob">
                                        <NavLink to={'/settings-bankaccounts'} style={{textDecoration:"none"}}className="rectangle-group_bnk_mob">
                                                <div className="rectangle-div_bnk_mob" />
                                                <div className="cancel_bnk_mob">CANCEL</div>
                                            </NavLink>
                                            <div className="rectangle-container_bnk_mob"onClick={submit}>
                                                <div className="frame-child1_bnk_mob" />
                                                <div className="save-changes_bnk_mob">{`SAVE `}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick={false}
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    toastClassName="custom-toast"
                    className="toast-container"
                    style={{
                        position: "fixed",
                        color: "white",
                        top: "10%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        height: "10vh",
                        width: "70vw",
                    }}
                />

            </MediaQuery>

        </>
    )
};

export default SettingsAddBankAccount;

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { NavLink, Navigate } from 'react-router-dom';
import '../../styles/Products/products.css'
import '../../styles/Products/archivedproducts.css'
import '../../styles/Components/NavBar.css'
import '../../styles/Components/UserRibbon.css'
import '../../styles/Components/HotLinks_Products.css'
import Wrapper from "../../components/Wrapper";
import HotlinksProducts from '../../components/HotLinks_Products';

import { get_archived_products } from "../../services/Products/productsinfo";
import { dateFormatter, datetimeFormatter_human } from '../../utils/date_formatter';
import MediaQuery from "react-responsive";
import { get_branding_info } from '../../services/Settings/brandinginfo';

import FilterableDataTable from '../../utils/data-table-search';
import { TableColumn } from 'react-data-table-component';
import { BouncingDotsLoaderDataTable } from '../../components/loading-dots';
import HotLinksProductsMobile from '../../components/HotLinksProductsMobile';

const Products = () => {
    const [ArchivedProducts, setArchivedProducts] = useState([] as any);
    const [loading, setLoading] = useState(false)
    const [filterText, setFilterText] = React.useState('');
    const [Branding, setBrandingInfo] = useState([] as any);


    useEffect(() => {
        fetchArchivedProducts();
        fetchBrandingInfo();
    }, []);

    // Archived Products
    async function fetchArchivedProducts() {
        setLoading(true)
        await get_archived_products().then(products => {

            setArchivedProducts(products);

        }).catch(error => {

        }).finally(() => {
            setLoading(false)
        })
    }

    // Branding Info
    async function fetchBrandingInfo() {
        var branding = await get_branding_info();
        setBrandingInfo(branding);
    }

    const columns: TableColumn<{ product_name: string, product_image: any, product_price: string, id: string, product_units: string; updated_at: string; slug: string; }>[] = [
        {
            name: 'NAME',
            cell: (row) => (
                <>
                    <a href={`${process.env.REACT_APP_BASE_URL}/archivedproductdetail/${row.slug}`}>

                        <div id="n_2628_e_prds">
                            {row.product_name}
                        </div>
                        <div id="n_2628_fa_prds">
                            KES. {row.product_price}
                        </div>
                        <div id="Group_4712_prds">
                            <svg className="Rectangle_2151_prds">
                                <rect id="Rectangle_2151_prds" rx="7" ry="7" x="0" y="0" width="35" height="35">
                                </rect>
                            </svg>
                            {row.product_image === "" ?
                                (
                                    <img id="adidas-ultraboost_prds" src="media/no-image-placeholder.png" />
                                ) : (
                                    <img id="adidas-ultraboost_prds" src={"https://chpter-3-jarvis-bucket.s3.amazonaws.com/products/" + row.product_image} />
                                )
                            }
                        </div>
                    </a>
                </>
            )
        },
        {
            name: '',
            cell: (row) => (
                <>
                    <a href={`${process.env.REACT_APP_BASE_URL}/archivedproductdetail/${row.id}`}>

                        <div id="Component_58__86_prds">
                            <div id="Search_Input_prds">
                                <div id="httpscheckoutchptercodrip_city_prds">
                                    <span>{process.env.REACT_APP_BASE_URL}/productscheckoutlink/{Branding.brand_alias}/{row.slug}</span>
                                </div>
                            </div>
                            <svg className="Rectangle_2127_prds">
                                <rect id="Rectangle_2127_prds" rx="5" ry="5" x="0" y="0" width="418" height="25">
                                </rect>
                            </svg>
                        </div>
                        <div id="Group_4641_prds">
                            <svg className="Rectangle_2126_prds">
                                <rect id="Rectangle_2126_prds" rx="5" ry="5" x="0" y="0" width="25" height="25">
                                </rect>
                            </svg>
                            <div id="Layer_2_prds">
                                <div id="copy_prds">
                                    <svg className="Rectangle_2125_prds">
                                        <rect id="Rectangle_2125_prds" rx="0" ry="0" x="0" y="0" width="21" height="21">
                                        </rect>
                                    </svg>
                                    <svg className="Path_9096_prds" viewBox="9 9 10.333 10.333">
                                        <path id="Path_9096_prds" d="M 16.75 19.33333396911621 L 11.58333301544189 19.33333396911621 C 10.15659713745117 19.33333396911621 9 18.17673492431641 9 16.75 L 9 11.58333301544189 C 9 10.15659713745117 10.15659809112549 9 11.58333396911621 9 L 16.75 9 C 18.17673492431641 9 19.33333396911621 10.15659713745117 19.33333396911621 11.58333301544189 L 19.33333396911621 16.75 C 19.33333396911621 18.17673492431641 18.17673492431641 19.33333396911621 16.75 19.33333396911621 Z M 11.58333301544189 10.72222232818604 C 11.10775470733643 10.72222232818604 10.72222232818604 11.10775470733643 10.72222232818604 11.58333301544189 L 10.72222232818604 16.75 C 10.72222232818604 17.22557830810547 11.10775470733643 17.61111068725586 11.58333301544189 17.61111068725586 L 16.75 17.61111068725586 C 17.22557830810547 17.61111068725586 17.61111068725586 17.22557830810547 17.61111068725586 16.75 L 17.61111068725586 11.58333301544189 C 17.61111068725586 11.10775470733643 17.22557830810547 10.72222232818604 16.75 10.72222232818604 L 11.58333301544189 10.72222232818604 Z">
                                        </path>
                                    </svg>
                                    <svg className="Path_9097_prds" viewBox="3 3 10.333 10.333">
                                        <path id="Path_9097_prds" d="M 8.79527759552002 13.33333396911621 L 5.299166679382324 13.33333396911621 C 4.031332492828369 13.32861328125 3.004721164703369 12.302001953125 2.999999523162842 11.03416728973389 L 3 5.299166679382324 C 3.004721641540527 4.031332492828369 4.031333446502686 3.004721164703369 5.299167156219482 2.999999523162842 L 11.03416728973389 3 C 12.302001953125 3.004721164703369 13.32861328125 4.031332492828369 13.33333396911621 5.299167156219482 L 13.33333396911621 8.511111259460449 L 11.61111164093018 8.511111259460449 L 11.61111164093018 5.299166679382324 C 11.61111164093018 4.980528831481934 11.35280418395996 4.722221851348877 11.03416728973389 4.722221851348877 L 5.299166679382324 4.722221851348877 C 4.980528831481934 4.722221851348877 4.722221851348877 4.980528831481934 4.722221851348877 5.299166679382324 L 4.722221851348877 11.03416728973389 C 4.722221851348877 11.35280418395996 4.980528831481934 11.61111164093018 5.299166679382324 11.61111164093018 L 8.79527759552002 11.61111164093018 L 8.79527759552002 13.33333396911621 Z">
                                        </path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </a>

                </>
            ),
            sortable: false,
        },
        {
            name: '',
        },
        {
            name: 'IN STOCK',
            selector: (row) => `Available: ${row.product_units}`,
            sortable: true,
        },
        {
            name: 'UPDATED',
            selector: (row) => datetimeFormatter_human(row.updated_at),
            sortable: true,
        },
    ];

    // Filter
    const filteredItems = ArchivedProducts.filter(
        (item: { product_name: string; product_price: { toString: () => string; }; product_units: { toString: () => string; }; updated_at: string; }) =>
            item.product_name?.toLowerCase().includes(filterText.toLowerCase()) ||
            item.product_price.toString().toLowerCase().includes(filterText.toLowerCase()) ||
            item.product_units.toString().toLowerCase().includes(filterText.toLowerCase()) ||
            dateFormatter(item.updated_at)
                .toLowerCase()
                .includes(filterText.toLowerCase())
    );


    // Export to CSV
    const handleExport = () => {
        const columnNames = ['Product Name', 'Product Price', 'Product Units', 'Date Updated'];
        const dataRows = ArchivedProducts.map((product: { product_name: string, product_image: any, product_price: any, product_units: any; updated_at: string }) => (
            [product.product_name, product.product_price, product.product_units, dateFormatter(product.updated_at)].join(',')
        ));
        const csv = [columnNames.join(','), ...dataRows].join('\n');
        const csvData = new Blob([csv], { type: 'text/csv' });
        const csvUrl = URL.createObjectURL(csvData);
        const hiddenElement = document.createElement('a');
        hiddenElement.href = csvUrl;
        hiddenElement.target = '_blank';
        hiddenElement.download = 'Archived_Products.csv';
        hiddenElement.click();
    };


    return (
        <>
            <MediaQuery minWidth={1001}>
                <main className="form-signin">
                    <div id="ProductsLanding_ap">

                        {loading ? (<BouncingDotsLoaderDataTable></BouncingDotsLoaderDataTable>
                        ) : (
                            <div id="Group_4666_ap">
                                <FilterableDataTable data={filteredItems} columns={columns} redirect_url={'archivedproductdetail'} type={'slug'} rowHeight={'wide'} scrollHeight={'660px'} />
                            </div>
                        )
                        }
                    </div>

                </main>
                <HotlinksProducts handleExport={handleExport}></HotlinksProducts>
                <Wrapper navigation="Products"></Wrapper>
            </MediaQuery>



            <MediaQuery maxWidth={1000}>
                <div className="mobilepages">
                    <div className="mobilepages-inner">
                        <div className="group-frame">
                            <div className="group-frame">
                                <div className="mobilepages1">
                                    <HotLinksProductsMobile></HotLinksProductsMobile>

                                    {/* ListView */}
                                    <div className="listview_prds_mob">
                                        <div className="frame-parent41_prds_mob">
                                            {ArchivedProducts.map((archived_product: { slug: any; product_name: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; currency: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; product_price: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; product_image: any; product_units: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; }) => {
                                                return (
                                                    <NavLink to={`/productdetail/${archived_product.slug}`} className="frame-parent42_prds_mob" style={{ textDecoration: 'none' }}>
                                                        <div className="frame-parent43_prds_mob">
                                                            <div className="group-wrapper16_prds_mob">
                                                                <div className="rectangle-parent9_prds_mob">
                                                                    <div className="group-child1_prds_mob" />
                                                                    {archived_product.product_image === "" ?
                                                                        (
                                                                            <img
                                                                                className="adidas-ultraboost-icon_prds_mob"
                                                                                alt=""
                                                                                src="media/no-image-placeholder.png"
                                                                            />
                                                                        ) : (
                                                                            <img
                                                                                className="adidas-ultraboost-icon_prds_mob"
                                                                                alt=""
                                                                                src={"https://chpter-3-jarvis-bucket.s3.amazonaws.com/products/" + archived_product.product_image}
                                                                            />
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="kes-1490000-parent_prds_mob">
                                                                <div className="kes-1490000_prds_mob">{archived_product.product_name}</div>
                                                                <div className="barry-oloo1_prds_mob">KES. {archived_product.product_price}.00</div>
                                                            </div>
                                                        </div>
                                                        <div className="july-2023-wrapper_prds_mob">
                                                            <div className="july-2023_prds_mob">{archived_product.product_units} Available</div>
                                                        </div>
                                                    </NavLink>

                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>

        </>

    )
}

export default Products;


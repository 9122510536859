//Product insights:  Monthly Payments, Last and Total payments
export async function get_product_payments_insights(productId: string | undefined){

    const response = await fetch(process.env.REACT_APP_CHPTER_API_URL+"/insights/monthly-product-payments/" + productId, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer "+localStorage.getItem("JWT"),
          'Accept': 'application/json',
        },
      });
    const data = await response.json();
    console.log(data.status);
  
    if(data.status === 401){
      //Redirect to login Token expired
      window.location.href = '/login';
  
    }
  
    if(data.status === 200){
        return data.insights;
    }
  
    return [];
  }
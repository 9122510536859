import axios from 'axios';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import '../../styles/Wallet/swapconfirm.css'
import MediaQuery from 'react-responsive';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../styles/Components/Toastify.css'
import '../../styles/Components/loading-dots.css'
import '../../styles/Components/loading-dots.css'
import { BouncingDotsLoader, BouncingDotsLoaderMobile } from '../../components/loading-dots';

const SwapConfirm = () => {
  const location = useLocation();
  const converted_swap_value = location.state.converted_swap_value
  const ExchangeRate = location.state.ExchangeRate
  const swap_value = location.state.swap_value
  const KES_wallet_balance = location.state.KES_wallet_balance
  const [loading, setLoading] = useState(false)


  const navigate = useNavigate();
  const Submit = async (e: SyntheticEvent) => {
    e.preventDefault();


    let api_url = process.env.REACT_APP_CHPTER_API_URL + '/wallet/swap'
    let headers = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + localStorage.getItem("JWT"),
    };

    const data = {
      "amount": parseFloat(swap_value),
      "currency": "USD"
    }

    setLoading(true)

    await axios.post(api_url, data, { headers: headers })
      .then((response) => {

        navigate("/swap-response", { state: { KES_wallet_balance, converted_swap_value, ExchangeRate, swap_value } });

      })
      .catch((error) => {
        // Error
        if (error.response) {


          let error_message = error.response.data.message

          if (error_message) {
            toast.error(error.response.data.message, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
            })
          }

        } else if (error.request) {

        } else {

        }
      }).finally(() => {
        setLoading(false)
      })

  }

  return (
    <>

      <MediaQuery minWidth={1001}>
        {loading ? (<BouncingDotsLoader></BouncingDotsLoader>
        ) : (
          <main className="form-signin">
            <div id="WalletSwapToKESConfirm_swapco">
              <div id="Group_6962_swapco">
                <div id="All_swapco">
                  <span>Confirm Swap to KES.</span>
                </div>
                <div id="Group_6660_swapco">
                  <div id="All_e_swapco">
                    <span>New Wallet Balance</span>
                  </div>
                  <div id="Group_6657_swapco">
                    <div id="All_fa_swapco">
                      <span>{KES_wallet_balance}</span>
                    </div>
                    <svg className="Line_445_swapco" viewBox="0 0 326.5 1">
                      <path id="Line_445_swapco" d="M 0 0 L 326.5 0">
                      </path>
                    </svg>
                    <div id="All_fb_swapco">
                      <span>Kes.</span>
                    </div>
                  </div>
                </div>
                <div id="Group_6658_swapco">
                  <div id="Group_4741_swapco" onClick={Submit}>
                    <div id="Group_3969_swapco">
                      <svg className="Rectangle_2057_swapco">
                        <rect id="Rectangle_2057_swapco" rx="5" ry="5" x="0" y="0" width="125" height="30">
                        </rect>
                      </svg>
                    </div>
                    <div id="Group_4628_swapco">
                      <div id="SWAP_swapco">
                        <span>SWAP</span>
                      </div>
                    </div>
                  </div>
                  <NavLink to={'/swap'} className="nav-link" style={{ textDecoration: 'none' }}>
                    <div id="Group_4742_swapco">
                      <div id="Group_3969_ff_swapco">
                        <svg className="Rectangle_2057_fg_swapco">
                          <rect id="Rectangle_2057_fg_swapco" rx="5" ry="5" x="0" y="0" width="75" height="30">
                          </rect>
                        </svg>
                      </div>
                      <div id="Group_4628_fh_swapco">
                        <div id="CANCEL_swapco">
                          <span>BACK</span>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                </div>
                <div id="Group_6960_swapco">
                  <div id="Group_6957_swapco">
                    <svg className="Rectangle_2429_swapco">
                      <rect id="Rectangle_2429_swapco" rx="5" ry="5" x="0" y="0" width="326" height="97">
                      </rect>
                    </svg>
                    <div id="Group_6656_swapco">
                      <div id="All_fn_swapco">
                        <span>{converted_swap_value}</span>
                      </div>
                      <div id="All_fo_swapco">
                        <span>KES.</span>
                      </div>
                    </div>
                  </div>
                  <div id="All_fp_swapco">
                    <span>KES Amount</span>
                  </div>
                </div>
                <div id="Group_6961_swapco">
                  <div id="Group_6958_swapco">
                    <svg className="Rectangle_2429_fs_swapco">
                      <rect id="Rectangle_2429_fs_swapco" rx="5" ry="5" x="0" y="0" width="326" height="97">
                      </rect>
                    </svg>
                    <div id="Group_6656_ft_swapco">
                      <div id="All_fu_swapco">
                        <span>{swap_value}</span>
                      </div>
                      <div id="All_fv_swapco">
                        <span>USD</span>
                      </div>
                    </div>
                  </div>
                  <div id="All_fw_swapco">
                    <span>Swap Amount</span>
                  </div>
                  <div id="exchange_rate_of_12250_swapco">
                    <span>exchange rate of {ExchangeRate}</span>
                  </div>
                  <svg className="icons8-info-48_swapco" viewBox="2 2 11.66 11.66">
                    <path id="icons8-info-48_swapco" d="M 7.830078125 2 C 4.617120265960693 2 2 4.617120265960693 2 7.830078125 C 2 11.04303646087646 4.617120265960693 13.66015625 7.830078125 13.66015625 C 11.04303646087646 13.66015625 13.66015625 11.04303646087646 13.66015625 7.830078125 C 13.66015625 4.617120265960693 11.04303646087646 2 7.830078125 2 Z M 7.830078125 3.166015625 C 10.41287422180176 3.166015625 12.494140625 5.247282028198242 12.494140625 7.830078125 C 12.494140625 10.41287422180176 10.41287422180176 12.494140625 7.830078125 12.494140625 C 5.247282028198242 12.494140625 3.166015625 10.41287422180176 3.166015625 7.830078125 C 3.166015625 5.247282028198242 5.247282028198242 3.166015625 7.830078125 3.166015625 Z M 7.2470703125 4.9150390625 L 7.2470703125 6.0810546875 L 8.4130859375 6.0810546875 L 8.4130859375 4.9150390625 L 7.2470703125 4.9150390625 Z M 7.2470703125 7.2470703125 L 7.2470703125 10.7451171875 L 8.4130859375 10.7451171875 L 8.4130859375 7.2470703125 L 7.2470703125 7.2470703125 Z">
                    </path>
                  </svg>
                </div>
              </div>
            </div>
          </main>

        )}
        <ToastContainer
          position="top-center"
          autoClose={9000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          toastClassName="custom-toast"
          className="toast-container"
          style={{
            top: "10%",
            color: "white",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      </MediaQuery>



      <MediaQuery maxWidth={1000}>

        {loading ? (<BouncingDotsLoaderMobile></BouncingDotsLoaderMobile>
        ) : (
          <div className="swapconfirm_swapco_mob">
            <div className="swapconfirm-inner_swapco_mob">
              <div className="group-wrapper_swapco_mob">
                <div className="group-wrapper_swapco_mob">
                  <div className="confirm_swapco_mob">
                    <div className="header_swapco_mob">
                      <div className="frame-parent_swapco_mob">
                        <NavLink to={'/swap'} style={{ textDecoration: 'none' }}>

                          <div className="group-container_swapco_mob">
                            <div className="rectangle-parent_swapco_mob">
                              <div className="group-child_swapco_mob" />


                              <svg className="path-9390-icon_swapco_mob" viewBox="0.636 0.05 10.513 18">
                                <path id="Path_9390_pad_mob" d="M 5.357423305511475 10.11950778961182 C 4.766643047332764 9.528789520263672 4.766643047332764 8.571026802062988 5.357423305511475 7.980309963226318 L 10.7065315246582 2.631746768951416 C 11.29722785949707 2.041119337081909 11.2972297668457 1.083506107330322 10.7065315246582 0.4928695559501648 L 10.7065315246582 0.4928695559501648 C 10.11583614349365 -0.09776583313941956 9.158125877380371 -0.09776552766561508 8.567437171936035 0.4928695559501648 L 1.079220414161682 7.980309963226318 C 0.4884408712387085 8.571026802062988 0.4884408712387085 9.528774261474609 1.079220414161682 10.11950778961182 L 8.567437171936035 17.60687637329102 C 9.158125877380371 18.19756317138672 10.11583614349365 18.19756317138672 10.7065315246582 17.60687637329102 L 10.7065315246582 17.60687637329102 C 11.2972297668457 17.0163402557373 11.2972297668457 16.05868339538574 10.7065315246582 15.46799468994141 L 5.357423305511475 10.11950778961182 Z">
                                </path>
                              </svg>
                            </div>
                          </div>
                        </NavLink>


                        <div className="payment-detail_swapco_mob">Confirm Swap to KES.</div>
                      </div>
                    </div>
                    <div className="swapamount_swapco_mob">
                      <div className="add-a-new-product-wrapper_swapco_mob">
                        <div className="add-a-new_swapco_mob">Swap Amount</div>
                      </div>
                      <div className="swapamount-inner_swapco_mob">
                        <div className="exchange-rate-of-12250-parent_swapco_mob">
                          <div className="exchange-rate-of_swapco_mob">
                            exchange rate of {ExchangeRate}
                          </div>


                          <svg className="icons8-info-48_swapco_mob" viewBox="2 2 11.66 11.66">
                            <path id="icons8-info-48_swapco" d="M 7.830078125 2 C 4.617120265960693 2 2 4.617120265960693 2 7.830078125 C 2 11.04303646087646 4.617120265960693 13.66015625 7.830078125 13.66015625 C 11.04303646087646 13.66015625 13.66015625 11.04303646087646 13.66015625 7.830078125 C 13.66015625 4.617120265960693 11.04303646087646 2 7.830078125 2 Z M 7.830078125 3.166015625 C 10.41287422180176 3.166015625 12.494140625 5.247282028198242 12.494140625 7.830078125 C 12.494140625 10.41287422180176 10.41287422180176 12.494140625 7.830078125 12.494140625 C 5.247282028198242 12.494140625 3.166015625 10.41287422180176 3.166015625 7.830078125 C 3.166015625 5.247282028198242 5.247282028198242 3.166015625 7.830078125 3.166015625 Z M 7.2470703125 4.9150390625 L 7.2470703125 6.0810546875 L 8.4130859375 6.0810546875 L 8.4130859375 4.9150390625 L 7.2470703125 4.9150390625 Z M 7.2470703125 7.2470703125 L 7.2470703125 10.7451171875 L 8.4130859375 10.7451171875 L 8.4130859375 7.2470703125 L 7.2470703125 7.2470703125 Z">
                            </path>
                          </svg>


                        </div>
                      </div>
                    </div>
                    <div className="confirm-inner_swapco_mob">
                      <div className="frame-wrapper_swapco_mob">
                        <div className="add-a-new-product-parent_swapco_mob">
                          <div className="add-a-new1_swapco_mob">USD</div>
                          <div className="add-a-new2_swapco_mob">{swap_value}</div>
                        </div>
                      </div>
                    </div>
                    <div className="kesamount_swapco_mob">
                      <div className="add-a-new-product-container_swapco_mob">
                        <div className="add-a-new_swapco_mob">KES Amount</div>
                      </div>
                    </div>
                    <div className="confirm-child_swapco_mob">
                      <div className="frame-wrapper_swapco_mob">
                        <div className="add-a-new-product-parent_swapco_mob">
                          <div className="add-a-new1_swapco_mob">KES</div>
                          <div className="add-a-new2_swapco_mob">{converted_swap_value}</div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-div_swapco_mob">
                      <div className="frame-group_swapco_mob">
                        <div className="frame-parent1_swapco_mob">
                          <div className="add-a-new-product-container_swapco_mob">
                            <div className="add-a-new6_swapco_mob">Your Wallet Balance</div>
                          </div>
                          <div className="add-a-new-product-parent1_swapco_mob">
                            <div className="add-a-new_swapco_mob">KES</div>
                            <div className="add-a-new8_swapco_mob">{KES_wallet_balance}</div>
                          </div>
                        </div>
                        <div className="line-wrapper_swapco_mob">
                          <div className="frame-child_swapco_mob" />
                        </div>
                        <div className="add-a-new9_swapco_mob">Service Fee KES. 36.00</div>
                      </div>
                    </div>
                    <div className="footer_swapco_mob">
                      <NavLink to={'/swap'} style={{ textDecoration: 'none', color: "black" }} className="cancel-wrapper_swapco_mob">
                        <div className="swapconfirm-inner_swapco_mob">BACK</div>
                      </NavLink>
                      <div className="save-changes-wrapper_swapco_mob" onClick={Submit}>
                        <div className="swapconfirm-inner_swapco_mob">SWAP</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          toastClassName="custom-toast"
          className="toast-container"
          style={{
            position: "fixed",
            color: "white",
            top: "10%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "10vh",
            width: "70vw",
          }}
        />


      </MediaQuery>
    </>
  )
};

export default SwapConfirm;


// Service Fee Mpesa
export const servicefee_mpesa = (pricing_model: string, amount: any) => {
    console.log("Service fee calculator")
    let service_fee = 0
    if (pricing_model === 'Customer'){
        service_fee = amount * (amount >= 10000 ? 0.005 : 0.01)
    }
    if(pricing_model === 'Business'){
        service_fee = 0
    }
    if(pricing_model === 'Shared'){
        service_fee = amount * (amount >= 10000 ? 0.005 : 0.005)
    }
    return Number(service_fee.toFixed(2));
}

// Service Fee Mpesa
export const servicefee_card = (amount: any) => {
    console.log("Service fee calculator")
    let service_fee = amount * 0.015;
   
    return Number(service_fee.toFixed(2));
}


import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {NavLink} from 'react-router-dom';
import '../../styles/Payments/refundconfirm.css'


const RefundConfirm = () => {
    return (
        <>
        <main className="form-signin">
            <div id="ConfirmRefund_corf">
                <div id="All_corf">
                    <span>Confirm Refund</span>
                </div>
                <div id="Layer_2_corf">
                    <div id="checkmark-circle-2_corf">
                        <svg className="Rectangle_2089_corf">
                            <rect id="Rectangle_2089_corf" rx="0" ry="0" x="0" y="0" width="194" height="194">
                            </rect>
                        </svg>
                        <svg className="Path_9060_corf" viewBox="2 2 163.357 163.357">
                            <path id="Path_9060_corf" d="M 83.67869567871094 2.000000238418579 C 38.56880187988281 2.000000238418579 2.000000238418579 38.56880569458008 2.000000238418579 83.67869567871094 C 2.000000238418579 128.7886199951172 38.56880569458008 165.3573913574219 83.67869567871094 165.3573913574219 C 128.7886199951172 165.3573913574219 165.3573913574219 128.78857421875 165.3573913574219 83.67869567871094 C 165.3573913574219 38.56880187988281 128.78857421875 2.000000238418579 83.67869567871094 2.000000238418579 Z M 83.67869567871094 149.0216522216797 C 47.59078216552734 149.0216522216797 18.33574104309082 119.7666244506836 18.33574104309082 83.67869567871094 C 18.33574104309082 47.59078216552734 47.59078598022461 18.33574104309082 83.67869567871094 18.33574104309082 C 119.7666244506836 18.33574104309082 149.0216522216797 47.59078598022461 149.0216522216797 83.67869567871094 C 149.0216522216797 119.7666244506836 119.7666244506836 149.0216522216797 83.67869567871094 149.0216522216797 Z">
                            </path>
                        </svg>
                        <svg className="Path_9061_corf" viewBox="7.499 7.994 73.489 65.393">
                            <path id="Path_9061_corf" d="M 66.31731414794922 11.22909736633301 L 35.44277191162109 52.06844329833984 L 22.12914276123047 34.83424377441406 C 19.35488319396973 31.27055549621582 14.21696662902832 30.63060188293457 10.65328407287598 33.40486145019531 C 7.089601993560791 36.17913055419922 6.449650287628174 41.31703948974609 9.223909378051758 44.88071441650391 L 29.07183837890625 70.28277587890625 C 30.63043212890625 72.25524139404297 33.01054763793945 73.40010833740234 35.52445220947266 73.38658142089844 C 38.05238342285156 73.3804931640625 40.43503952026367 72.20426940917969 41.97706604003906 70.20109558105469 L 79.30424499511719 21.19388580322266 C 82.05593872070312 17.5851001739502 81.36112976074219 12.42889404296875 77.75234222412109 9.677190780639648 C 74.1435546875 6.925485610961914 68.98735809326172 7.620294570922852 66.23563385009766 11.22908210754395 Z">
                            </path>
                        </svg>
                    </div>
                </div>
                <div id="Group_6790_corf">
                    <svg className="Rectangle_2429_corf">
                        <rect id="Rectangle_2429_corf" rx="5" ry="5" x="0" y="0" width="326" height="97">
                        </rect>
                    </svg>
                    <div id="Group_6656_corf">
                        <div id="All_e_corf">
                            <span>200,000.00</span>
                        </div>
                        <div id="All_fa_corf">
                            <span>Kes.</span>
                        </div>
                    </div>
                    <div id="All_fb_corf">
                        <span>Payout Amount</span>
                    </div>
                </div>
                <NavLink to={'/successfulpayments'} className="nav-link" style={{ textDecoration: 'none' }}>
                <div id="Group_6658_corf">
                    <div id="Group_4741_corf">
                        <div id="Group_3969_corf">
                            <svg className="Rectangle_2057_corf">
                                <rect id="Rectangle_2057_corf" rx="5" ry="5" x="0" y="0" width="125" height="30">
                                </rect>
                            </svg>
                        </div>
                        <div id="Group_4628_corf">
                            <div id="CONFIRM_corf">
                                <span>CONFIRM</span>
                            </div>
                        </div>
                    </div>
                </div>
                </NavLink>
                <div id="Group_6794_corf">
                    <div id="Refunds_take_510_days_to_appea_corf">
                        <span>Refunds take 5–10 days to appear on a <br/>customer’s statement. Fees for the original <br/>payment won’t be returned, but there are no <br/>additional fees for the refund.</span><br/><span>Learn More.</span>
                    </div>
                    <svg className="icons8-info-48_corf" viewBox="2 2 20 20">
                        <path id="icons8-info-48_corf" d="M 12 2 C 6.488996982574463 2 2 6.488996982574463 2 12 C 2 17.5110034942627 6.488996982574463 22 12 22 C 17.5110034942627 22 22 17.5110034942627 22 12 C 22 6.488996982574463 17.5110034942627 2 12 2 Z M 12 4 C 16.43012237548828 4 20 7.569877624511719 20 12 C 20 16.43012237548828 16.43012237548828 20 12 20 C 7.569877624511719 20 4 16.43012237548828 4 12 C 4 7.569877624511719 7.569877624511719 4 12 4 Z M 11 7 L 11 9 L 13 9 L 13 7 L 11 7 Z M 11 11 L 11 17 L 13 17 L 13 11 L 11 11 Z">
                        </path>
                    </svg>
                </div>
            </div>
        </main>
        </>
    )
};

export default RefundConfirm;

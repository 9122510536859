import React from "react";


export const BouncingDotsLoader = () => {
    return <div>

        <div className="bouncing-loader-container">
            <div className="bouncing-loader" style={{top: "0px"}}>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
}

export const BouncingDotsLoaderDataTable = () => {
    return <div>

        <div className="bouncing-loader-container">
            <div className="bouncing-loader" style={{top: "210px"}}>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
}

export const BouncingDotsLoaderPreview = () => {
    return <div>

        <div className="bouncing-loader-container">
            <div className="bouncing-loader" style={{top: "125px"}}>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
}

export const BouncingDotsLoaderMobile = () => {
    return <div>

        <div className="bouncing-loader-container">
            <div className="bouncing-loader" style={{position:"unset", height:"80vh"}}>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
}

export const BouncingDotsLoaderChatMobile = () => {
    return <div>

        <div className="bouncing-loader-container">
            <div className="bouncing-loader" style={{position:"unset", height:"40vh"}}>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
}
export const ProcessingLoader = () => {
    return <div>
        <div className="text-loader">
            Processing Payment
        </div>
    </div>
}
// Get All Customers
export async function get_all_customers(){
  

  const response = await fetch(process.env.REACT_APP_CHPTER_API_URL+"/customers/profile", {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer "+localStorage.getItem("JWT"),
        'Accept': 'application/json',
      },
    });
  const data = await response.json();
  console.log(data.status);

  if(data.status === 401){
    //Redirect to login Token expired
    window.location.replace('/login');

  }

  if(data.status === 200){
      return data.customers;
  }

  return [];
}


// Get Customer Detail
export async function get_customer_detail(customer_id: string | undefined){

    const response = await fetch(process.env.REACT_APP_CHPTER_API_URL+"/customer/profile/" + customer_id, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer "+localStorage.getItem("JWT"),
          'Accept': 'application/json',
        },
      });
    const data = await response.json();
    console.log(data.status);

  if(data.status === 401){
    //Redirect to login Token expired
    window.location.replace('/login');

  }

    if(data.status === 200){
        return data.customer;
    }

    return [];
}


// Get Customer's Payment History
export async function get_customer_payment_history(customer_id: string | undefined){

  const response = await fetch(process.env.REACT_APP_CHPTER_API_URL+"/payments/customer/" + customer_id +
   "/status/Successful", {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer "+localStorage.getItem("JWT"),
        'Accept': 'application/json',
      },
    });
  const data = await response.json();
  console.log(data.status);

  if(data.status === 401){
    //Redirect to login Token expired
    window.location.replace('/login');

  }

  if(data.status === 200){
      return data.payments;
  }

  return [];
}



// Get Customer's Payment History
export async function get_customer_subscriptions(customer_id: string | undefined){

  const response = await fetch(process.env.REACT_APP_CHPTER_API_URL+"/customer/subscriptions/" + customer_id, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer "+localStorage.getItem("JWT"),
        'Accept': 'application/json',
      },
    });
  const data = await response.json();
  console.log(data.status);

  if(data.status === 401){
    //Redirect to login Token expired
    window.location.replace('/login');

  }

  if(data.status === 200){
      return data.subscriptions;
  }

  return [];
}


// Get Customer's Invoices
export async function get_customer_invoices(customer_id: string | undefined){

  const response = await fetch(process.env.REACT_APP_CHPTER_API_URL+"/customers/" + customer_id +
   "/invoice", {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer "+localStorage.getItem("JWT"),
        'Accept': 'application/json',
      },
    });
  const data = await response.json();
  console.log(data.status);

  if(data.status === 401){
    //Redirect to login Token expired
    window.location.replace('/login');

  }

  if(data.status === 200){
      return data.invoices;
  }

  return [];
}
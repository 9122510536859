import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import '../../styles/Settings/settingsteam.css'
import '../../styles/Components/NavBar.css'
import '../../styles/Components/UserRibbon.css'
import '../../styles/Components/HotLinks_Settings.css'
import Wrapper from "../../components/Wrapper";
import HotLinks_Settings from '../../components/HotLinks_Settings';

import { SuccessfulPayments } from "../../models/payments";
import { Withdrawal } from "../../models/wallet";



const SettingsTeam = () => {

    return (
        <>
            <main className="form-signin">
                <NavLink to={'/settings-adduser'} className="nav-link" style={{ textDecoration: 'none' }}>
                    <div id="Group_7077_setteam">
                        <svg className="Rectangle_1977_setteam">
                            <rect id="Rectangle_1977_setteam" rx="5" ry="5" x="0" y="0" width="137" height="34">
                            </rect>
                        </svg>
                        <div id="New_User_setteam">
                            <span>New User</span>
                        </div>
                        <div id="Layer_2_setteam">
                            <div id="plus_setteam">
                                <svg className="Rectangle_2464_setteam">
                                    <rect id="Rectangle_2464_setteam" rx="0" ry="0" x="0" y="0" width="20" height="20">
                                    </rect>
                                </svg>
                                <svg className="Path_9305_setteam" viewBox="4 4 13.692 13.692">
                                    <path id="Path_9305_setteam" d="M 16.83599662780762 9.990132331848145 L 11.70159912109375 9.990132331848145 L 11.70159912109375 4.855732917785645 C 11.70159912109375 4.383124828338623 11.31847381591797 4 10.84586620330811 4 C 10.37325763702393 4 9.990132331848145 4.383124828338623 9.990132331848145 4.855732917785645 L 9.990132331848145 9.990132331848145 L 4.855732917785645 9.990132331848145 C 4.383124828338623 9.990132331848145 4 10.37325763702393 4 10.84586620330811 C 4 11.31847476959229 4.383124828338623 11.70159912109375 4.855732917785645 11.70159912109375 L 9.990132331848145 11.70159912109375 L 9.990132331848145 16.83599662780762 C 9.990132331848145 17.3086051940918 10.37325763702393 17.69173049926758 10.84586620330811 17.69173049926758 C 11.31847381591797 17.69173049926758 11.70159912109375 17.3086051940918 11.70159912109375 16.83599662780762 L 11.70159912109375 11.70159912109375 L 16.83599662780762 11.70159912109375 C 17.3086051940918 11.70159912109375 17.69173049926758 11.31847381591797 17.69173049926758 10.84586620330811 C 17.69173049926758 10.37325763702393 17.3086051940918 9.990132331848145 16.83599662780762 9.990132331848145 Z">
                                    </path>
                                </svg>
                            </div>
                        </div>
                    </div>
                </NavLink>
                <div id="All_setteam">
                    <span>Team Permissions</span>
                </div>
                <div id="Roles_allow_you_to_apply_defin_setteam">
                    <span>Roles allow you to apply defined permission sets to a user or to a group of users.</span>
                </div>
                <div id="Group_7085_setteam">
                    <svg className="Rectangle_2175_setteam">
                        <rect id="Rectangle_2175_setteam" rx="10" ry="10" x="0" y="0" width="262" height="134">
                        </rect>
                    </svg>
                    <div id="Admin_setteam">
                        <span>Admin</span>
                    </div>
                    <div id="Manage_all_areas_make_payouts__setteam">
                        <span>Manage all areas, make payouts and edit<br />settings on the dashboard.</span>
                    </div>
                    <div id="Layer_2_fn_setteam">
                        <div id="person_setteam">
                            <svg className="Rectangle_2463_setteam">
                                <rect id="Rectangle_2463_setteam" rx="0" ry="0" x="0" y="0" width="24" height="24">
                                </rect>
                            </svg>
                            <svg className="Path_9302_setteam" viewBox="8 3 8 8">
                                <path id="Path_9302_setteam" d="M 12 11 C 14.20913887023926 11 16 9.209138870239258 16 6.999999523162842 C 16 4.790861129760742 14.20913887023926 2.999999523162842 11.99999904632568 3 C 9.790861129760742 3 7.999999523162842 4.7908616065979 8 7.000000476837158 C 8 9.209138870239258 9.790861129760742 11 11.99999904632568 11 Z M 12 5 C 13.10456943511963 5 14 5.895430564880371 14 7 C 14 8.104569435119629 13.10456943511963 9 12 9 C 10.89543056488037 9 10 8.104569435119629 10 7 C 10 5.895430564880371 10.89543056488037 5 12 5 Z">
                                </path>
                            </svg>
                            <svg className="Path_9303_setteam" viewBox="5 13 14 8">
                                <path id="Path_9303_setteam" d="M 12 13 C 8.134006500244141 13 4.999999046325684 16.13400840759277 5 20.00000190734863 C 5 20.55228424072266 5.447714805603027 21 5.999999046325684 21 C 6.552284240722656 21 6.999999523162842 20.55228614807129 7 20 C 7 17.23857688903809 9.238576889038086 15 12.00000095367432 15 C 14.76142406463623 15 17 17.23857688903809 17 20 C 17 20.55228424072266 17.44771575927734 21 18 21 C 18.55228424072266 21 19 20.55228424072266 19 20 C 19 16.13400650024414 15.86599349975586 13 12.00000095367432 13 Z">
                                </path>
                            </svg>
                        </div>
                    </div>
                </div>
                <div id="Group_7086_setteam">
                    <svg className="Rectangle_2175_ft_setteam">
                        <rect id="Rectangle_2175_ft_setteam" rx="10" ry="10" x="0" y="0" width="262" height="134">
                        </rect>
                    </svg>
                    <div id="Support_setteam">
                        <span>Support</span>
                    </div>
                    <div id="Views_and_manages_payments_dat_setteam">
                        <span>Views and manages payments data,<br />products and customer data.</span>
                    </div>
                    <div id="Layer_2_fw_setteam">
                        <div id="person_fx_setteam">
                            <svg className="Rectangle_2463_fy_setteam">
                                <rect id="Rectangle_2463_fy_setteam" rx="0" ry="0" x="0" y="0" width="24" height="24">
                                </rect>
                            </svg>
                            <svg className="Path_9302_fz_setteam" viewBox="8 3 8 8">
                                <path id="Path_9302_fz_setteam" d="M 12 11 C 14.20913887023926 11 16 9.209138870239258 16 6.999999523162842 C 16 4.790861129760742 14.20913887023926 2.999999523162842 11.99999904632568 3 C 9.790861129760742 3 7.999999523162842 4.7908616065979 8 7.000000476837158 C 8 9.209138870239258 9.790861129760742 11 11.99999904632568 11 Z M 12 5 C 13.10456943511963 5 14 5.895430564880371 14 7 C 14 8.104569435119629 13.10456943511963 9 12 9 C 10.89543056488037 9 10 8.104569435119629 10 7 C 10 5.895430564880371 10.89543056488037 5 12 5 Z">
                                </path>
                            </svg>
                            <svg className="Path_9303_f_setteam" viewBox="5 13 14 8">
                                <path id="Path_9303_f_setteam" d="M 12 13 C 8.134006500244141 13 4.999999046325684 16.13400840759277 5 20.00000190734863 C 5 20.55228424072266 5.447714805603027 21 5.999999046325684 21 C 6.552284240722656 21 6.999999523162842 20.55228614807129 7 20 C 7 17.23857688903809 9.238576889038086 15 12.00000095367432 15 C 14.76142406463623 15 17 17.23857688903809 17 20 C 17 20.55228424072266 17.44771575927734 21 18 21 C 18.55228424072266 21 19 20.55228424072266 19 20 C 19 16.13400650024414 15.86599349975586 13 12.00000095367432 13 Z">
                                </path>
                            </svg>
                        </div>
                    </div>
                </div>
                <div id="Group_7087_setteam">
                    <svg className="Rectangle_2175_f_setteam">
                        <rect id="Rectangle_2175_f_setteam" rx="10" ry="10" x="0" y="0" width="262" height="134">
                        </rect>
                    </svg>
                    <div id="Developer_setteam">
                        <span>Developer</span>
                    </div>
                    <div id="For_developers_who_need_to_set_setteam">
                        <span>For developers who need to set up chpter.<br />integration with access to the API keys</span>
                    </div>
                    <div id="Group_7071_setteam">
                        <div id="Layer_2_f_setteam">
                            <div id="person_f_setteam">
                                <svg className="Rectangle_2463_f_setteam">
                                    <rect id="Rectangle_2463_f_setteam" rx="0" ry="0" x="0" y="0" width="24" height="24">
                                    </rect>
                                </svg>
                                <svg className="Path_9302_f_setteam" viewBox="8 3 8 8">
                                    <path id="Path_9302_f_setteam" d="M 12 11 C 14.20913887023926 11 16 9.209138870239258 16 6.999999523162842 C 16 4.790861129760742 14.20913887023926 2.999999523162842 11.99999904632568 3 C 9.790861129760742 3 7.999999523162842 4.7908616065979 8 7.000000476837158 C 8 9.209138870239258 9.790861129760742 11 11.99999904632568 11 Z M 12 5 C 13.10456943511963 5 14 5.895430564880371 14 7 C 14 8.104569435119629 13.10456943511963 9 12 9 C 10.89543056488037 9 10 8.104569435119629 10 7 C 10 5.895430564880371 10.89543056488037 5 12 5 Z">
                                    </path>
                                </svg>
                                <svg className="Path_9303_ga_setteam" viewBox="5 13 14 8">
                                    <path id="Path_9303_ga_setteam" d="M 12 13 C 8.134006500244141 13 4.999999046325684 16.13400840759277 5 20.00000190734863 C 5 20.55228424072266 5.447714805603027 21 5.999999046325684 21 C 6.552284240722656 21 6.999999523162842 20.55228614807129 7 20 C 7 17.23857688903809 9.238576889038086 15 12.00000095367432 15 C 14.76142406463623 15 17 17.23857688903809 17 20 C 17 20.55228424072266 17.44771575927734 21 18 21 C 18.55228424072266 21 19 20.55228424072266 19 20 C 19 16.13400650024414 15.86599349975586 13 12.00000095367432 13 Z">
                                    </path>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="Group_7088_setteam">
                    <svg className="Rectangle_2175_gc_setteam">
                        <rect id="Rectangle_2175_gc_setteam" rx="10" ry="10" x="0" y="0" width="262" height="134">
                        </rect>
                    </svg>
                    <div id="View-only_setteam">
                        <span>View-only</span>
                    </div>
                    <div id="Only_view_payments_balance_and_setteam">
                        <span>Only view payments, balance, and<br />connected accounts, but can’t edit.</span>
                    </div>
                    <div id="Layer_2_gf_setteam">
                        <div id="person_gg_setteam">
                            <svg className="Rectangle_2463_gh_setteam">
                                <rect id="Rectangle_2463_gh_setteam" rx="0" ry="0" x="0" y="0" width="24" height="24">
                                </rect>
                            </svg>
                            <svg className="Path_9302_gi_setteam" viewBox="8 3 8 8">
                                <path id="Path_9302_gi_setteam" d="M 12 11 C 14.20913887023926 11 16 9.209138870239258 16 6.999999523162842 C 16 4.790861129760742 14.20913887023926 2.999999523162842 11.99999904632568 3 C 9.790861129760742 3 7.999999523162842 4.7908616065979 8 7.000000476837158 C 8 9.209138870239258 9.790861129760742 11 11.99999904632568 11 Z M 12 5 C 13.10456943511963 5 14 5.895430564880371 14 7 C 14 8.104569435119629 13.10456943511963 9 12 9 C 10.89543056488037 9 10 8.104569435119629 10 7 C 10 5.895430564880371 10.89543056488037 5 12 5 Z">
                                </path>
                            </svg>
                            <svg className="Path_9303_gj_setteam" viewBox="5 13 14 8">
                                <path id="Path_9303_gj_setteam" d="M 12 13 C 8.134006500244141 13 4.999999046325684 16.13400840759277 5 20.00000190734863 C 5 20.55228424072266 5.447714805603027 21 5.999999046325684 21 C 6.552284240722656 21 6.999999523162842 20.55228614807129 7 20 C 7 17.23857688903809 9.238576889038086 15 12.00000095367432 15 C 14.76142406463623 15 17 17.23857688903809 17 20 C 17 20.55228424072266 17.44771575927734 21 18 21 C 18.55228424072266 21 19 20.55228424072266 19 20 C 19 16.13400650024414 15.86599349975586 13 12.00000095367432 13 Z">
                                </path>
                            </svg>
                        </div>
                    </div>
                </div>
                <div id="All_gk_setteam">
                    <span>Users</span>
                </div>
                <div id="Group_7083_setteam">
                    <div id="n_2628_setteam">
                        <span>Kuria Kevin</span>
                    </div>
                    <div id="n_4_July_164500_PM_setteam">
                        <span>24 July, 16:45:00 PM</span>
                    </div>
                    <div id="kuriachpterco_setteam">
                        <span>kuria@chpter.co</span>
                    </div>
                    <div id="Group_7076_setteam">
                        <svg className="Rectangle_2057_setteam">
                            <rect id="Rectangle_2057_setteam" rx="7" ry="7" x="0" y="0" width="100" height="30">
                            </rect>
                        </svg>
                        <div id="Admin_gr_setteam">
                            <span>Admin</span>
                        </div>
                    </div>
                    <svg className="Path_9304_setteam" viewBox="0 -1 1383.34 1">
                        <path id="Path_9304_setteam" d="M 0 -1 L 1383.339599609375 0">
                        </path>
                    </svg>
                </div>
                <div id="Group_7078_setteam">
                    <svg className="Path_9130_setteam" viewBox="0 -1 1383.34 1">
                        <path id="Path_9130_setteam" d="M 0 -1 L 1383.339599609375 0">
                        </path>
                    </svg>
                    <div id="Last_login_setteam">
                        <span>Last login</span>
                    </div>
                    <div id="Email_setteam">
                        <span>Email</span>
                    </div>
                    <div id="Role_setteam">
                        <span>Role</span>
                    </div>
                    <div id="n_2628_g_setteam">
                        <span>Name</span>
                    </div>
                </div>

            </main>
            <HotLinks_Settings></HotLinks_Settings>
            <Wrapper navigation='Settings'></Wrapper>
        </>
    )
};

export default SettingsTeam;

import React, { useRef, useEffect } from "react";


export function GoogleMapsAPIMobile({ onChange }: any) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const autocomplete = useRef<google.maps.places.Autocomplete | null>(null);

  useEffect(() => {
    if (inputRef.current) {

      autocomplete.current = new window.google.maps.places.Autocomplete(
        inputRef.current,
        { types: ["geocode"], componentRestrictions: { country: "KE" } }
      );
      autocomplete.current.addListener("place_changed", () => {
        const place = autocomplete.current?.getPlace();
        if (place?.geometry && place.geometry.location) {
          onChange({
            address: place.formatted_address,
            latLng: {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            },
          });
        }
      });
      inputRef.current.addEventListener("blur", () => {
        const enteredText = inputRef.current?.value;
        if (enteredText) {
          const geocoder = new window.google.maps.Geocoder();
          geocoder.geocode(
            { address: enteredText, componentRestrictions: { country: "KE" } },
            (results, status) => {
              if (status === "OK" && results?.[0]?.geometry?.location) {
                onChange({
                  address: results[0].formatted_address,
                  latLng: {
                    lat: results[0].geometry.location.lat(),
                    lng: results[0].geometry.location.lng(),
                  },
                });
              } else {
                onChange({ address: enteredText, latLng: {} });
              }
            }
          );
        }
      });
    }

  }, [onChange,]);



  return (
    <>
      {/* <input id="Rectangle_2002_chktlnk_mob" ref={inputRef} placeholder="Input location" type="text" name="location"/> */}
      <input
        className="frame-input_chktlnk_mob"
        type="text"
        ref={inputRef} 
        placeholder="Input location" 
        name="location"
      />
    </>
  );
}


export function GoogleMapsAPIMobileCheckout({ onChange }: any) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const autocomplete = useRef<google.maps.places.Autocomplete | null>(null);

  useEffect(() => {
    if (inputRef.current) {

      autocomplete.current = new window.google.maps.places.Autocomplete(
        inputRef.current,
        { types: ["geocode"], componentRestrictions: { country: "KE" } }
      );
      autocomplete.current.addListener("place_changed", () => {
        const place = autocomplete.current?.getPlace();
        if (place?.geometry && place.geometry.location) {
          onChange({
            address: place.formatted_address,
            latLng: {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            },
          });
        }
      });
      inputRef.current.addEventListener("blur", () => {
        const enteredText = inputRef.current?.value;
        if (enteredText) {
          const geocoder = new window.google.maps.Geocoder();
          geocoder.geocode(
            { address: enteredText, componentRestrictions: { country: "KE" } },
            (results, status) => {
              if (status === "OK" && results?.[0]?.geometry?.location) {
                onChange({
                  address: results[0].formatted_address,
                  latLng: {
                    lat: results[0].geometry.location.lat(),
                    lng: results[0].geometry.location.lng(),
                  },
                });
              } else {
                onChange({ address: enteredText, latLng: {} });
              }
            }
          );
        }
      });
    }

  }, [onChange,]);



  return (
    <>
      <input id="Rectangle_2002_chktlnk_mob" ref={inputRef} placeholder="Input location" type="text" name="location"/>
    </>
  );
}


export function GoogleMapsAPIDesktop({ onChange }: any) {

  const inputRef = useRef<HTMLInputElement | null>(null);

  const autocomplete = useRef<google.maps.places.Autocomplete | null>(null);

  useEffect(() => {
    if (inputRef.current) {
      autocomplete.current = new window.google.maps.places.Autocomplete(
        inputRef.current,
        { types: ["geocode"], componentRestrictions: { country: "KE" } }
      );
      autocomplete.current.addListener("place_changed", () => {
        const place = autocomplete.current?.getPlace();
        if (place?.geometry && place.geometry.location) {
          onChange({
            address: place.formatted_address,
            latLng: {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            },
          });
        }
      });
      inputRef.current.addEventListener("blur", () => {
        const enteredText = inputRef.current?.value;
        if (enteredText) {
          const geocoder = new window.google.maps.Geocoder();
          geocoder.geocode(
            { address: enteredText, componentRestrictions: { country: "KE" } },
            (results, status) => {
              if (status === "OK" && results?.[0]?.geometry?.location) {
                onChange({
                  address: results[0].formatted_address,
                  latLng: {
                    lat: results[0].geometry.location.lat(),
                    lng: results[0].geometry.location.lng(),
                  },
                });
              } else {
                onChange({ address: enteredText, latLng: {} });
              }
            }
          );
        }
      });
    }
  }, [onChange]);

  return (
    <>
      <input
        id="Rectangle_2002_chktlnk"
        ref={inputRef}
        placeholder="Input location"
        type="text"
        name="location"
      />
    </>
  );
}

export function GoogleMapsAPIInvoice({ onChange }: any) {

  const inputRef = useRef<HTMLInputElement | null>(null);

  const autocomplete = useRef<google.maps.places.Autocomplete | null>(null);

  useEffect(() => {
    if (inputRef.current) {
      autocomplete.current = new window.google.maps.places.Autocomplete(
        inputRef.current,
        { types: ["geocode"], componentRestrictions: { country: "KE" } }
      );
      autocomplete.current.addListener("place_changed", () => {
        const place = autocomplete.current?.getPlace();
        if (place?.geometry && place.geometry.location) {
          onChange({
            address: place.formatted_address,
            latLng: {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            },
          });
        }
      });
      inputRef.current.addEventListener("blur", () => {
        const enteredText = inputRef.current?.value;
        if (enteredText) {
          const geocoder = new window.google.maps.Geocoder();
          geocoder.geocode(
            { address: enteredText, componentRestrictions: { country: "KE" } },
            (results, status) => {
              if (status === "OK" && results?.[0]?.geometry?.location) {
                onChange({
                  address: results[0].formatted_address,
                  latLng: {
                    lat: results[0].geometry.location.lat(),
                    lng: results[0].geometry.location.lng(),
                  },
                });
              } else {
                onChange({ address: enteredText, latLng: {} });
              }
            }
          );
        }
      });
    }
  }, [onChange]);

  return (
    <>
      <input
        id="Rectangle_2002_invchkt"
        ref={inputRef}
        placeholder="Input location"
        type="text"
        name="location"
      />
    </>
  );
}

export function GoogleMapsAPIBankAccountDesktop({ onChange }: any) {

  const inputRef = useRef<HTMLInputElement | null>(null);

  const autocomplete = useRef<google.maps.places.Autocomplete | null>(null);

  useEffect(() => {
    if (inputRef.current) {
      autocomplete.current = new window.google.maps.places.Autocomplete(
        inputRef.current,
        { types: ["geocode"], componentRestrictions: { country: "KE" } }
      );
      autocomplete.current.addListener("place_changed", () => {
        const place = autocomplete.current?.getPlace();
        if (place?.geometry && place.geometry.location) {
          onChange({
            address: place.formatted_address,
            latLng: {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            },
          });
        }
      });
      inputRef.current.addEventListener("blur", () => {
        const enteredText = inputRef.current?.value;
        if (enteredText) {
          const geocoder = new window.google.maps.Geocoder();
          geocoder.geocode(
            { address: enteredText, componentRestrictions: { country: "KE" } },
            (results, status) => {
              if (status === "OK" && results?.[0]?.geometry?.location) {
                onChange({
                  address: results[0].formatted_address,
                  latLng: {
                    lat: results[0].geometry.location.lat(),
                    lng: results[0].geometry.location.lng(),
                  },
                });
              } else {
                onChange({ address: enteredText, latLng: {} });
              }
            }
          );
        }
      });
    }
  }, [onChange]);

  return (
    <>
      <input
        id="Rectangle_2002_ga_addbnk"
        ref={inputRef}
        placeholder="Bank Branch Location"
        type="text"
        name="bank_branch"
      />
    </>
  );
}


export function GoogleMapsAPIBankAccountMobile({ onChange }: any) {

  const inputRef = useRef<HTMLInputElement | null>(null);

  const autocomplete = useRef<google.maps.places.Autocomplete | null>(null);

  useEffect(() => {
    if (inputRef.current) {
      autocomplete.current = new window.google.maps.places.Autocomplete(
        inputRef.current,
        { types: ["geocode"], componentRestrictions: { country: "KE" } }
      );
      autocomplete.current.addListener("place_changed", () => {
        const place = autocomplete.current?.getPlace();
        if (place?.geometry && place.geometry.location) {
          onChange({
            address: place.formatted_address,
            latLng: {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            },
          });
        }
      });
      inputRef.current.addEventListener("blur", () => {
        const enteredText = inputRef.current?.value;
        if (enteredText) {
          const geocoder = new window.google.maps.Geocoder();
          geocoder.geocode(
            { address: enteredText, componentRestrictions: { country: "KE" } },
            (results, status) => {
              if (status === "OK" && results?.[0]?.geometry?.location) {
                onChange({
                  address: results[0].formatted_address,
                  latLng: {
                    lat: results[0].geometry.location.lat(),
                    lng: results[0].geometry.location.lng(),
                  },
                });
              } else {
                onChange({ address: enteredText, latLng: {} });
              }
            }
          );
        }
      });
    }
  }, [onChange]);

  return (
    <>
      <input
        id="Rectangle_2002_g_bnk_mob"
        ref={inputRef}
        placeholder="Bank Branch Location"
        type="text"
        name="bank_branch"
      />
    </>
  );
}

// Get API Keys
export async function get_api_keys(){

    const response = await fetch( process.env.REACT_APP_CHPTER_API_URL + "/merchants/auth/credentials", {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer "+ localStorage.getItem("JWT"),
          'Accept': 'application/json',
        },
      });
    const data = await response.json();
    console.log(data.status);

  if(data.status === 401){
    //Redirect to login Token expired
    window.location.replace('/login');

  }

    if(data.status === 200){
        return data.auth_credentials;
    }

    return [];
}


import MediaQuery from "react-responsive";
import '../../styles/Welcome/passwordresettoken.css';
import { SyntheticEvent, useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import { BsEyeFill } from 'react-icons/bs'
import { BsEyeSlashFill } from 'react-icons/bs'
import { Navigate } from 'react-router-dom';
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import '../../styles/Components/Toastify.css'


const PasswordResetToken = () => {
    const initialValues = { password: '', confirm_password: ''}
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState<Record<string, string>>({});
    const [token, setToken] = useState('')
    const [redirect, setredirect] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);


    const handleChange = (e: { target: { name: any; value: any; }; }) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value })

    }

    // Password Visibility
    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const handleToggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };


    const Validate = (Values: {password: any; confirm_password: any;}) => {
        const errors: any = {};
        setFormErrors(errors)

        if (!Values.password) {
            toast("Input New Password", { type: "error" });
            errors.password = "Input New Password";
        } 
        else if (Values.password.length < 8) {
            toast("Password must be at least 8 characters long", { type: "error" });
            errors.password = "Password must be at least 8 characters long";
        }
        else if (String(Values.confirm_password) !== String(Values.password)) {
            toast("Passwords don't match", { type: "error" });
            errors.confirm_password = "Passwords don't match";
        }

        setFormErrors(errors)
        if (Object.keys(errors).length > 0) {
            let errorMessage = "";
            for (const key in formErrors) {
                if (formErrors[key]) {
                    errorMessage += formErrors[key] + "\n";
                }
            }

        }
        return Object.keys(errors).length === 0;
    };


    useEffect(() => {
        const url = window.location.href.split('/');
        const url_token  = url[url.length - 1];
        setToken(url_token)
    }, [token])


    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();
        const isValid = Validate(formValues);
        if (!isValid) {
            return;
        }

        // submit the form
        let api_url = process.env.REACT_APP_CHPTER_API_URL + '/merchants/forgot-password/verification'
        let headers = {
            "Content-Type": "application/json"
        };

        const data = {
            "token": token,
            "new_password": formValues.password
        }

        await axios.put(api_url, data, { headers: headers })
            .then((response) => {

                if (response.status === 200) {
                    toast.success('Password changed successfully', {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                    });
                setTimeout(() => {
                    setredirect(true);
                    }, 2000);
                }
            })
            .catch((error) => {
                // Error
                if (error.response) {

                    if (error.response.status === 404) {
                        toast("Email Address not found", { type: "error" });
                    }
                    
                } else if (error.request) {

                } else {

                }
            });
    }

    if (redirect) {
        return <Navigate to={'/login'} />
    }

    const handleKeyDown = (event: React.SyntheticEvent<Element, Event>) => {
        const keyEvent = event as React.KeyboardEvent;
        if (keyEvent.key === "Enter") {
            submit(event);
        }
    };

    return (
        <>
            <MediaQuery minWidth={1001}>
                <ToastContainer position="top-right" autoClose={9000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false}
                    pauseOnFocusLoss draggable pauseOnHover toastClassName="custom-toast" className="toast-container" />
                <div id="PasswordChange_prt" style={{ left: "0px", transformOrigin: "0px 0px", transform: "scale(0.750)" }}>
                    <div id="Group_3972_prt">
                        <svg className="Rectangle_1922_prt">
                            <rect id="Rectangle_1922_prt" rx="15" ry="15" x="0" y="0" width="600" height="485">
                            </rect>
                        </svg>

                        <div id="Group_3969_prt" onClick={submit}>
                            <svg className="Rectangle_2057_ca_prt">
                                <linearGradient id="Rectangle_2057_ca" spreadMethod="pad" x1="0.066" x2="0.79" y1="0" y2="1.3">
                                    <stop offset="0" stop-color="#000" stop-opacity="1"></stop>
                                    <stop offset="1" stop-color="#000" stop-opacity="1"></stop>
                                </linearGradient>
                                <rect id="Rectangle_2057_ca_prt" rx="7" ry="7" x="0" y="0" width="469" height="48">
                                </rect>
                            </svg>
                            <div id="Change_Password_button_prt">Change Password</div>
                        </div>
                        <div id="All_prt">
                            <span>Change your Password</span>
                        </div>
                        <div>
                            <div id="Group_4529_prt">
                                <div id="Group_3858_ch_prt">
                                    <div >
                                        <input id="Password_prt" type={showPassword ? 'text' : 'password'} name="password" placeholder="New Password" value={formValues.password} required
                                            onChange={handleChange} onKeyDown={handleKeyDown}/>
                                        <div id="Eye_icon_prt" onClick={handleTogglePasswordVisibility}>
                                            {showPassword ? <BsEyeSlashFill style={{ color: 'rgb(118, 118, 118, 1)' }} /> : <BsEyeFill style={{ color: 'rgb(118, 118, 118, 1)' }} />}
                                        </div>
                                    </div>
                                    <div id="Password__prt">
                                        <span>New Password </span>
                                    </div>
                                </div>
                            </div>
                            <div id="Group_4529_cp_prt">
                                <div id="Group_3858_ch_cp_prt">
                                    <div >
                                        <input id="ConfirmPassword_prt" type={showConfirmPassword ? 'text' : 'password'} name="confirm_password" placeholder="Confirm New Password" value={formValues.confirm_password} required
                                            onChange={handleChange} onKeyDown={handleKeyDown}/>
                                    </div>
                                    <div id="Eye_icon_c_prt" onClick={handleToggleConfirmPasswordVisibility}>
                                        {showConfirmPassword ? <BsEyeSlashFill style={{ color: 'rgb(118, 118, 118, 1)' }} /> : <BsEyeFill style={{ color: 'rgb(118, 118, 118, 1)' }} />}
                                    </div>
                                    <div id="Password_cp_prt">
                                        <span>Confirm New Password </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="Group_4528_prt">
                        <div id="Group_3168_prt">
                            <div id="chpter_prt">
                                <span>chpter.</span>
                            </div>
                        </div>
                    </div>
                    <div id="Group_4531_prt">
                        <div id="chpter_cy_prt">
                            <span>chpter.</span>
                        </div>
                        <div id="Contact_prt">
                            <span>Contact</span>
                        </div>
                        <div id="Privacy_Policy_prt">
                            <span>Privacy Policy</span>
                        </div>
                        <div id="Group_4532_prt">
                            <div id="Layer_2_prt">
                                <div id="radio-button-off_prt">
                                    <svg className="Rectangle_2094_prt">
                                        <rect id="Rectangle_2094_prt" rx="0" ry="0" x="0" y="0" width="15" height="15">
                                        </rect>
                                    </svg>
                                    <svg className="Path_9068_prt" viewBox="2 2 12.158 12.308">
                                        <path id="Path_9068_prt" d="M 8.079020500183105 14.30843162536621 C 4.721669673919678 14.30843162536621 2 11.55309391021729 2 8.154215812683105 C 2 4.755335807800293 4.721670150756836 2 8.079020500183105 2 C 11.43637180328369 2 14.15804100036621 4.755335807800293 14.15804100036621 8.154215812683105 C 14.15804100036621 11.55309581756592 11.43636989593506 14.30843162536621 8.079020500183105 14.30843162536621 Z M 8.079020500183105 3.230843305587769 C 5.393139839172363 3.230843305587769 3.215804100036621 5.435111999511719 3.215804100036621 8.154215812683105 C 3.215804100036621 10.87331867218018 5.393140316009521 13.07758903503418 8.079020500183105 13.07758903503418 C 10.76490020751953 13.07758903503418 12.94223690032959 10.87331867218018 12.94223690032959 8.154215812683105 C 12.94223690032959 5.435111522674561 10.76490020751953 3.230843305587769 8.079020500183105 3.230843305587769 Z">
                                        </path>
                                    </svg>
                                </div>
                            </div>
                            <div id="c_prt">
                                <span>c</span>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>


            <MediaQuery maxWidth={1000}>
                <ToastContainer position="top-center" autoClose={5000} hideProgressBar={true} newestOnTop={false} closeOnClick={true}
                        pauseOnFocusLoss={true} draggable={true} pauseOnHover={true} toastClassName="custom-toast-mobile" className="toast-container-mobile" />
                <div id="MobilePasswordChange_prt_mob">
                    <div id="Group_4528_prt_mob">
                        <div id="Group_3168_prt_mob">
                            <div id="chpter_prt_mob">
                                <span>chpter.</span>
                            </div>
                        </div>
                    </div>
                    <div id="Group_5586_prt_mob">
                        <svg className="Rectangle_2103_prt_mob">
                            <rect id="Rectangle_2103_prt_mob" rx="15" ry="15" x="0" y="0" width="375" height="362">
                            </rect>
                        </svg>
                        <div id="All_prt_mob">
                            <span>Change your password</span>
                        </div>
                        <div id="Group_3969_prt_mob" onClick={submit}>
                            <svg className="Rectangle_2057_d_prt_mob"  >
                                <linearGradient id="Rectangle_2057_d_prt_mob" spreadMethod="pad" x1="0.066" x2="0.79" y1="0" y2="1.3">
                                    <stop offset="0" stop-color="#000" stop-opacity="1"></stop>
                                    <stop offset="1" stop-color="#000" stop-opacity="1"></stop>
                                </linearGradient>
                                <rect id="Rectangle_2057_d_prt_mob" type="submit" rx="7" ry="7" x="0" y="0" width="300" height="40">
                                </rect>
                            </svg>
                            <div id="Change_Password_button_prt_mob">Change Password</div>
                        </div>
                        <div id="Group_4529_prt_mob">
                            <div id="Group_3858_eb_prt_mob">
                                <div>
                                    <input id="Password__ed_prt_mob" type={showPassword ? 'text' : 'password'} name="password" placeholder="New Password" value={formValues.password} required onChange={handleChange} />
                                    <div id="Eye_icon_prt_mob" onClick={handleTogglePasswordVisibility}>
                                        {showPassword ? <BsEyeSlashFill style={{ color: 'rgb(118, 118, 118, 1)' }} /> : <BsEyeFill style={{ color: 'rgb(118, 118, 118, 1)' }} />}
                                    </div>
                                </div>
                                <div id="Password__prt_mob">
                                    <span>New Password</span>
                                </div>
                            </div>
                            <div id="Group_4529_cp_prt_mob">
                                <div id="Group_3858_eb_prt_mob">
                                        <input id="ConfirmPassword__prt_mob" type={showConfirmPassword ? 'text' : 'password'} name="confirm_password" placeholder="Confirm New Password " value={formValues.confirm_password} required onChange={handleChange} />
                                </div>
                                <div id="Eye_icon_c_prt_mob" onClick={handleToggleConfirmPasswordVisibility}>
                                    {showConfirmPassword ? <BsEyeSlashFill style={{ color: 'rgb(118, 118, 118, 1)' }} /> : <BsEyeFill style={{ color: 'rgb(118, 118, 118, 1)' }} />}
                                </div>
                                <div id="Password__prt_mob">
                                    <span>Confirm New Password </span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </MediaQuery>
        </>
    )
}

export default PasswordResetToken;
import axios from 'axios';
import React, { useEffect, useState, SyntheticEvent } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import '../../styles/Payments/paymentlinkcheckout.css'
import '../../styles/Components/loading-dots.css'
import { useParams } from "react-router-dom";

import { get_checkout_link } from "../../services/LinkBasket-Checkoutlink/checkoutlink";
import { get_linkbasket } from "../../services/LinkBasket-Checkoutlink/linkbasket";
import { get_linkbasket_navigation } from "../../services/LinkBasket-Checkoutlink/checkoutlink";
import { Console } from 'console';
import { get_merchant_profile } from '../../services/Settings/merchantprofileinfo';
import { get_paymentlink_checkout_detail } from '../../services/Payments/paymentlinksinfo';
import { servicefee_mpesa } from '../../utils/servicefee_calc';
import MediaQuery from "react-responsive";
import { BouncingDotsLoader } from '../../components/loading-dots';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router';
// import { parsePhoneNumberFromString } from "libphonenumber-js";
import { GoogleMapsAPIMobile, GoogleMapsAPIMobileCheckout } from '../../utils/google-maps-api';
import 'react-toastify/dist/ReactToastify.css';
import '../../styles/Components/Toastify.css'
import '../../styles/Components/loading-dots.css'


const PaymentLinkCheckout = () => {

    const [hasExpired, setHasExpired] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [PaymentLinkCheckoutDetail, setPaymentLinkCheckoutDetail] = useState([] as any);
    const [redirect, setredirect] = useState(false)

    const [amount, setAmount] = useState([] as any)
    const [payment_details, setPaymentDetails] = useState([] as any)

    const [ProductUnits, setProductUnits] = useState(1);
    const [ProductAmount, setProductAmount] = useState(0)
    const [ServiceFee, setServiceFees] = useState(0);
    const [delivery_fee, setDeliveryFees] = useState(0);
    const [total, setGrandTotal] = useState(0);
    const [pricing_model, setPricingModel] = useState('')
    const initialValues = { cust_name: '', cust_location: '', phone_number: '', email: '' }
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState<Record<string, string>>({});
    const [payMethod, setPayMethod] = useState('mpesa');
    const [navigationInputs, setNavigationInputs] = useState([] as any)
    const [Navigation, setNavigation] = useState([] as any);




    let { brand_alias, slug } = useParams();
    let alias = { brand_alias }.brand_alias

    let paymentlink_slug = { slug }.slug

    useEffect(() => {
        async function fetchPaymentLinkCheckoutDetail() {
            var paymentlink_detail = await get_paymentlink_checkout_detail(paymentlink_slug);
            setPaymentLinkCheckoutDetail(paymentlink_detail);
            setPricingModel(paymentlink_detail.pricing_model)

            const expiryDate = new Date(paymentlink_detail.expiry_date); 

            const now = new Date();
            const nowNairobi = new Date(now.toLocaleString('en-US', { timeZone: 'Africa/Nairobi' })); 

            if (nowNairobi > expiryDate) {
                // The expiry date has passed
                setHasExpired(true)
            }


        }
        fetchPaymentLinkCheckoutDetail();


        // Get navigation details
        async function fetchLinkBasketNavigation() {
            var navigation = await get_linkbasket_navigation(alias);
            setNavigation(navigation);
            setNavigationInputs(navigation.input_one)

        }
        fetchLinkBasketNavigation();


    }, []);

    useEffect(() => {
        if (payMethod === "mpesa") {
            handleServiceFeeMpesa(PaymentLinkCheckoutDetail.amount, PaymentLinkCheckoutDetail.pricing_model, PaymentLinkCheckoutDetail.currency)
        } else {
            handleServiceFeeCard(PaymentLinkCheckoutDetail.amount, PaymentLinkCheckoutDetail.currency)

        }
        setIsLoading(false);
    }, [payMethod, PaymentLinkCheckoutDetail]);


    const handleChange = (e: { target: { name: any; value: any; }; }) => {
        const { name, value } = e.target;
        if (name === 'phone_number') {
            const sanitizedValue = value.replace(/\s/g, '');
            setFormValues({ ...formValues, [name]: sanitizedValue })
        } else {
            setFormValues({ ...formValues, [name]: value })
        }

    }
    const isEmailValidator = require('validator').isEmail
    type FormType = "mpesa" | "card" | "bank";
    const Validate = (Values: any, formType: string) => {

        let isValid = true;
        const errors: any = {};
        setFormErrors(errors)
        if (formType === "mpesa") {
            if (!Values.cust_name) {
                toast("Name is required", { type: "error" });
                errors.cust_name = "Name is required";
            } else if (Values.cust_name.trim().split(' ').length < 2) {
                toast("Please enter both first and last name", { type: "error" });
                errors.cust_name = "Please enter both first and last name";
            }

            else if (!Values.cust_location) {
                toast("location is required", { type: "error" });
                errors.cust_location = "location is required";
            }
            else if (!Values.phone_number) {
                toast("Phone Number is required", { type: "error" });
                errors.phone_number = "Phone Number is required";
                isValid = false;
            } else if (Values.phone_number.length < 10) {
                toast("Invalid Phone Number", { type: "error" });
                errors.phone_number = "Invalid Phone Number";
                isValid = false;
            }
        }
        if (formType === "card") {
            if (!Values.cust_name) {
                toast("Name is required", { type: "error" });
                errors.cust_name = "Name is required";
            } else if (Values.cust_name.trim().split(' ').length < 2) {
                toast("Please enter both first and last name", { type: "error" });
                errors.cust_name = "Please enter both first and last name";
            }

            else if (!Values.cust_location) {
                toast("location is required", { type: "error" });
                errors.cust_location = "location is required";
            }
            else if (!Values.email) {
                toast("Email is required", { type: "error" });
                errors.email = "Email is required";
            } else if (!isEmailValidator(Values.email)) {
                toast("Invalid Email format", { type: "error" });
                errors.email = "Invalid Email format";
            }
        }
        if (formType === "bank") {
            if (!Values.cust_name) {
                toast("Name is required", { type: "error" });
                errors.cust_name = "Name is required";
            } else if (Values.cust_name.trim().split(' ').length < 2) {
                toast("Please enter both first and last name", { type: "error" });
                errors.cust_name = "Please enter both first and last name";
            }

            else if (!Values.cust_location) {
                toast("location is required", { type: "error" });
                errors.cust_location = "location is required";
            }
            else if (!Values.phone_number) {
                toast("Phone Number is required", { type: "error" });
                errors.phone_number = "Phone Number is required";
                isValid = false;
            } else if (!/^(\+254|0)[1-9]\d{8}$/.test(Values.phone_number)) {
                toast("Invalid Phone Number", { type: "error" });
                errors.phone_number = "Invalid Phone Number";
                isValid = false;
            }
        }


        setFormErrors(errors)
        if (Object.keys(errors).length > 0) {
            let errorMessage = "";
            for (const key in formErrors) {
                if (formErrors[key]) {
                    errorMessage += formErrors[key] + "\n";
                }
            }

        }
        return Object.keys(errors).length === 0;
    };


    const [location, setLocation] = useState([] as any);
    function handleLocationChange(newLocation: any) {
        setLocation(newLocation);
        setFormValues({ ...formValues, cust_location: String(newLocation.address) })
    }



    useEffect(() => {


        setPaymentDetails({ "company_name": PaymentLinkCheckoutDetail.company_name, "source": "Payment Link", "payment_link_id": { "String": String(PaymentLinkCheckoutDetail.id), "Valid": true } })

    }, [brand_alias, PaymentLinkCheckoutDetail])



    // Handles Service Fee when the page loads: MPESA
    const handleServiceFeeMpesa = (checkout_product_price: number, pricing_model: string, currency: string) => {



        var serviceFee = servicefee_mpesa(pricing_model, checkout_product_price)
        var deliveryFee = 0
        var discountFee = 0


        setServiceFees(serviceFee);
        setDeliveryFees(deliveryFee);
        setProductAmount(checkout_product_price)
        setGrandTotal(checkout_product_price + serviceFee)
        setAmount({ "delivery_fee": (deliveryFee), "total": (checkout_product_price), "discount_fee": (discountFee), "service_fee": serviceFee, "currency": currency })

    };


    // Handles Service Fee when the page loads: Card or Bank
    const handleServiceFeeCard = (checkout_product_price: number, currency: string) => {
        const serviceFee = parseFloat((checkout_product_price * 0.015).toFixed(2))
        var deliveryFee = 0
        var discountFee = 0

        setServiceFees(serviceFee);
        setDeliveryFees(deliveryFee);
        setProductAmount(checkout_product_price)
        setGrandTotal(checkout_product_price + serviceFee)
        setAmount({ "delivery_fee": (deliveryFee), "total": (checkout_product_price), "discount_fee": (discountFee), "service_fee": serviceFee, "currency": currency })
    }


    const price = 1234.56;
    const formattedPrice = price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });




    const buttonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        const button: HTMLButtonElement = event.currentTarget;
        setPayMethod(button.name);
    };
    const navigate = useNavigate();


    // Submit for payment
    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();
        const isValid = Validate(formValues, payMethod);

        if (!isValid) {
            return;
        }

        else if (payMethod) {
            const element = document.getElementById('Payment-Method-Required') as HTMLElement;
            // element.style.display = 'none';


            let api_url = process.env.REACT_APP_CHPTER_API_URL + '/inhouse/' + payMethod + '-payment/initiate/' + alias
            let headers = {
                "Content-Type": "application/json",
            };

            const data = {
                "amount": amount,
                "customer_details": {
                    "full_name": formValues.cust_name,
                    "delivery_address": formValues.cust_location,
                    "phone_number": formValues.phone_number,
                    "email": formValues.email,
                },

                "payment_details": payment_details,

            }


            setIsLoading(true)

            await axios.post(api_url, data, { headers: headers })
                .then((response) => {


                    if (payMethod == "mpesa") {
                        if (response.data.success) {
                            window.location.replace(process.env.REACT_APP_BASE_URL + '/checkout-customer/CheckoutLinkReceipt/' + response.data.slug);
                        }
                    }

                    if (payMethod == "card") {
                        if (response.data.success) {
                            window.location.replace(response.data.checkout_url);
                        }
                    }

                })
                .catch((error) => {
                    setIsLoading(false);

                    // Error
                    if (error.response) {


                        let error_message = error.response.data.message

                        if (error_message === 'The balance is insufficient for the transaction.') {
                            // Display a toast for insufficient balance error
                            toast.error("Oops! Something went wrong. Please make sure you're connected to the internet and have sufficient balance in your Mpesa Wallet.");
                        } else if (error_message === 'Request cancelled by user') {
                            // Display a toast for cancelled request error
                            toast.error("The request was cancelled by the user. Please try again.");
                        } else {
                            // Display a generic toast for other errors
                            toast.error("Oops! Something went wrong. Please try again later.");
                        }

                    } else {
                    }

                });

        }

        else {
            const element = document.getElementById('Payment-Method-Required') as HTMLElement;
            element.style.display = 'block';
        }
    };


    return (
        <>

            <MediaQuery minWidth={1001}>

                <ToastContainer
                    position="top-center"
                    autoClose={9000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick={false}
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    toastClassName="custom-toast"
                    className="toast-container"
                    style={{
                        top: "10%",
                        color: "white",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                    }}
                />
                {isLoading ? (
                    <BouncingDotsLoader></BouncingDotsLoader>
                ) : (
                    <main className="form-signin">
                        <div id="CheckoutLink_pmtctlnk">
                            <div id="Group_7291_pmtctlnk">
                                <svg className="Rectangle_2211_pmtctlnk">
                                    <rect id="Rectangle_2211_pmtctlnk" rx="0" ry="0" x="0" y="0" width="368.49" height="51.481">
                                    </rect>
                                </svg>
                                <div id="Group_7290_pmtctlnk">
                                    <div id="All_pmtctlnk">
                                        {/* TOD) -- change to company name */}
                                        <span>{PaymentLinkCheckoutDetail['company_name'] || ''}</span>
                                    </div>
                                    <div id="Group_5247_pmtctlnk">
                                        <svg className="Rectangle_2221_pmtctlnk">
                                            <rect id="Rectangle_2221_pmtctlnk" rx="7" ry="7" x="0" y="0" width="25" height="25">
                                            </rect>
                                        </svg>
                                        <div id="Layer_2_pmtctlnk">
                                            <div id="radio-button-on_pmtctlnk">
                                                <svg className="Rectangle_2222_pmtctlnk">
                                                    <rect id="Rectangle_2222_pmtctlnk" rx="0" ry="0" x="0" y="0" width="15" height="15">
                                                    </rect>
                                                </svg>
                                                <svg className="Path_9137_pmtctlnk" viewBox="2 2 12.813 12.813">
                                                    <path id="Path_9137_pmtctlnk" d="M 8.406643867492676 2 C 4.868351936340332 2 2 4.868351936340332 2 8.406643867492676 C 2 11.94493389129639 4.868351936340332 14.81328678131104 8.406643867492676 14.81328678131104 C 11.94493389129639 14.81328678131104 14.81328678131104 11.94493389129639 14.81328678131104 8.406643867492676 C 14.81328678131104 4.868351936340332 11.94493389129639 2 8.406643867492676 2 Z M 8.406643867492676 13.53195762634277 C 5.576010227203369 13.53195762634277 3.281328678131104 11.23727512359619 3.281328678131104 8.406643867492676 C 3.281328678131104 5.576010227203369 5.576010227203369 3.281328678131104 8.406643867492676 3.281328678131104 C 11.23727512359619 3.281328678131104 13.53195762634277 5.576010227203369 13.53195762634277 8.406643867492676 C 13.53195762634277 11.23727512359619 11.23727512359619 13.53195762634277 8.406643867492676 13.53195762634277 Z">
                                                    </path>
                                                </svg>
                                                <svg className="Path_9138_pmtctlnk" viewBox="7 7 6.407 6.407">
                                                    <path id="Path_9138_pmtctlnk" d="M 10.20332145690918 7 C 8.434176445007324 7 7 8.434176445007324 7 10.20332145690918 C 7 11.97246742248535 8.434176445007324 13.40664386749268 10.20332145690918 13.40664386749268 C 11.97246742248535 13.40664386749268 13.40664386749268 11.97246742248535 13.40664386749268 10.20332145690918 C 13.40664386749268 8.434176445007324 11.97246742248535 7 10.20332145690918 7 Z M 10.20332145690918 12.12531471252441 C 9.141834259033203 12.12531471252441 8.281329154968262 11.26480960845947 8.281329154968262 10.20332145690918 C 8.281329154968262 9.141834259033203 9.141834259033203 8.281329154968262 10.20332145690918 8.281329154968262 C 11.26480960845947 8.281329154968262 12.12531471252441 9.141834259033203 12.12531471252441 10.20332145690918 C 12.12531471252441 11.26480960845947 11.26480960845947 12.12531471252441 10.20332145690918 12.12531471252441 Z">
                                                    </path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <img id="Mask_Group_15_pmtctlnk" src={"https://chpter-3-jarvis-bucket.s3.amazonaws.com/branding/" + PaymentLinkCheckoutDetail['logo']} />
                            <div id="Group_7288_pmtctlnk">
                                <div id="KES_1490000_pmtctlnk">
                                    <span>PAYMENT LINK </span>
                                </div>
                                <div id="Adidas_Ultraboost_pmtctlnk">
                                    <span>{PaymentLinkCheckoutDetail['currency']}. {PaymentLinkCheckoutDetail['amount'] || ''}.00</span>
                                </div>
                            </div>

                            <div id="PaymentLink_container">
                                <div id="Group_7296_pmtctlnk">

                                    <div id="Group_7295_pmtctlnk">
                                        <div id="Group_7295_chktlnk_mob">
                                            <div id="Payment_Method_chktlnk_mob">
                                                <span>Payment Method</span>
                                            </div>
                                            <div id="Payment-Method-Required" className="Payment-Method-Required">
                                                <span>❗️ Payment Method Required</span>
                                            </div>
                                            <button id="Group_7292_chktlnk_mob" onClick={buttonHandler} name="mpesa" className={payMethod === "mpesa" ? "active" : ""} >
                                                <img id="Image_14_chktlnk" src="/media/Image14@2x.png" />
                                                <div id="Mpesa_chktlnk">
                                                    <span>Mpesa</span>
                                                </div>
                                            </button>
                                            <button id="Group_7293_chktlnk_mob" onClick={buttonHandler} name="card" className={payMethod === "card" ? "active" : ""}>
                                                <div id="Card_chktlnk">
                                                    <span>Card</span>
                                                </div>
                                                <div id="credit-card_chktlnk">
                                                    <svg className="Rectangle_2350_chktlnk">
                                                        <rect id="Rectangle_2350_chktlnk" rx="0" ry="0" x="0" y="0" width="20" height="20">
                                                        </rect>
                                                    </svg>
                                                    <svg className="Path_9249_chktlnk" viewBox="2 5 16.561 11.593">
                                                        <path id="Path_9249_chktlnk" d="M 16.07674217224121 5 L 4.484130859375 5 C 3.112183332443237 5 1.999999642372131 6.112183570861816 2 7.484131813049316 L 2 14.10848045349121 C 2 15.48042869567871 3.112183094024658 16.59261131286621 4.484130382537842 16.59261131286621 L 16.07674217224121 16.59261131286621 C 17.44868850708008 16.59261131286621 18.56087303161621 15.48042869567871 18.56087303161621 14.10848045349121 L 18.56087303161621 7.484130859375 C 18.56087303161621 6.112183094024658 17.44868850708008 5 16.07674217224121 5 Z M 3.656087398529053 7.484130859375 C 3.656087398529053 7.026814937591553 4.026814937591553 6.656087398529053 4.484130859375 6.656087398529053 L 16.07674217224121 6.656087398529053 C 16.5340576171875 6.656087398529053 16.90478515625 7.026814937591553 16.90478515625 7.484130859375 L 16.90478515625 8.312174797058105 L 3.656087398529053 8.312174797058105 L 3.656087398529053 7.484130859375 Z M 16.90478515625 14.10848045349121 C 16.90478515625 14.5657958984375 16.5340576171875 14.93652439117432 16.07674217224121 14.93652439117432 L 4.484130859375 14.93652439117432 C 4.026814937591553 14.93652439117432 3.656087398529053 14.5657958984375 3.656087398529053 14.10848045349121 L 3.656087398529053 9.968262672424316 L 16.90478515625 9.968262672424316 L 16.90478515625 14.10848045349121 Z">
                                                        </path>
                                                    </svg>
                                                    <svg className="Path_9250_chktlnk" viewBox="6 13 4.968 1.656">
                                                        <path id="Path_9250_chktlnk" d="M 6.828043937683105 14.65608692169189 L 10.14021873474121 14.65608692169189 C 10.5975341796875 14.65608692169189 10.96826171875 14.28535938262939 10.96826171875 13.82804393768311 C 10.96826171875 13.3707275390625 10.5975341796875 13 10.14021873474121 13 L 6.828043937683105 13 C 6.370728015899658 13 6 13.37072849273682 6 13.82804393768311 C 6 14.28535938262939 6.370728015899658 14.65608692169189 6.828043937683105 14.65608692169189 Z">
                                                        </path>
                                                    </svg>
                                                    <svg className="Path_9251_chktlnk" viewBox="14 13 3.312 1.656">
                                                        <path id="Path_9251_chktlnk" d="M 14.82804393768311 14.65608692169189 L 16.484130859375 14.65608692169189 C 16.94144630432129 14.65608692169189 17.31217384338379 14.28535938262939 17.31217384338379 13.82804393768311 C 17.31217384338379 13.3707275390625 16.94144630432129 13 16.484130859375 13 L 14.82804393768311 13 C 14.3707275390625 13 14.00000095367432 13.37072849273682 14.00000095367432 13.82804393768311 C 14.00000095367432 14.28535938262939 14.37072849273682 14.65608692169189 14.82804393768311 14.65608692169189 Z">
                                                        </path>
                                                    </svg>
                                                </div>
                                            </button>
                                            <button id="Group_7294_chktlnk_mob" disabled={true}>
                                                <div id="Bank_chktlnk">
                                                    <span>Bank</span>
                                                </div>
                                                <img id="icons8-bank-96_chktlnk" src="/media/icons8-bank-96.png" />
                                            </button>
                                        </div>
                                    </div>
                                </div>



                                {payMethod === 'mpesa' && (
                                    <div id="Group_7300711_pmtctlnk">
                                        <div id="Group_7298_pmtctlnk">
                                            <input id="Rectangle_2002_gi_pmtctlnk" type="text" name="cust_name" placeholder="Input full name" onChange={handleChange} />
                                            <div id="Full_Name_pmtctlnk">
                                                <span>Full Name</span>
                                            </div>
                                        </div>
                                        <div id="Group_7299_pmtctlnk">
                                            {/* <input id="Rectangle_2002_pmtctlnk" type="text" name="cust_location" placeholder="Input location" onChange={handleChange} /> */}
                                            <div id="Location_pmtctlnk">
                                                <span>Location</span>
                                            </div>
                                            <GoogleMapsAPIMobileCheckout onChange={handleLocationChange} />
                                        </div>
                                        <div id="Group_7300_pmtctlnk">
                                            <input id="Rectangle_2002_ge_pmtctlnk" type="text" name="phone_number" placeholder="0700 000 000" onChange={handleChange} autoComplete='off' />
                                            <div id="Phone_Number_pmtctlnk">
                                                <span>Phone Number</span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {payMethod === 'card' && (
                                    <div id="Group_7300711_pmtctlnk">
                                        <div id="Group_7298_pmtctlnk">
                                            <input id="Rectangle_2002_gi_pmtctlnk" type="text" name="cust_name" placeholder="Input full name" onChange={handleChange} />
                                            <div id="Full_Name_pmtctlnk">
                                                <span>Full Name</span>
                                            </div>
                                        </div>
                                        <div id="Group_7299_pmtctlnk">
                                            {/* <input id="Rectangle_2002_pmtctlnk" type="text" name="cust_location" placeholder="Input location" onChange={handleChange} /> */}
                                            <div id="Location_pmtctlnk">
                                                <span>Location</span>
                                            </div>
                                            <GoogleMapsAPIMobileCheckout onChange={handleLocationChange} />
                                        </div>
                                        <div id="Group_7300_pmtctlnk">
                                            <input id="Rectangle_2002_ge_pmtctlnk" type="text" name="email" placeholder="Input Address" onChange={handleChange} />
                                            <div id="Phone_Number_pmtctlnk">
                                                <span>Email</span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {payMethod === 'bank' && (


                                    <div id="Group_7300711_pmtctlnk">
                                        <div id="Group_7298_pmtctlnk">
                                            <input id="Rectangle_2002_gi_pmtctlnk" type="text" name="cust_name" placeholder="Input full name" onChange={handleChange} />
                                            <div id="Full_Name_pmtctlnk">
                                                <span>Full Name</span>
                                            </div>
                                        </div>
                                        <div id="Group_7299_pmtctlnk">
                                            <div id="Location_pmtctlnk">
                                                <span>Location</span>
                                            </div>
                                            <GoogleMapsAPIMobileCheckout onChange={handleLocationChange} />
                                        </div>
                                        <div id="Group_7300_pmtctlnk">
                                            <input id="Rectangle_2002_ge_pmtctlnk" type="text" name="phone_number" placeholder="0700 000 000" onChange={handleChange} autoComplete='off' />
                                            <div id="Phone_Number_pmtctlnk">
                                                <span>Phone Number</span>
                                            </div>
                                        </div>
                                    </div>
                                )}


                                <div id="Group_73007_pmtctlnk">
                                    <div id="Group_5246_pmtctlnk">
                                        <svg className="Path_9139_pmtctlnk" viewBox="0 0 100 0.5">
                                            <path id="Path_9139_pmtctlnk" d="M 0 0 L 99.99999237060547 0">
                                            </path>
                                        </svg>
                                        <svg className="Path_9140_pmtctlnk" viewBox="0 0 100 0.5">
                                            <path id="Path_9140_pmtctlnk" d="M 0 0 L 100 0">
                                            </path>
                                        </svg>
                                        <div id="Your_summary_pmtctlnk">
                                            <span>Your summary</span>
                                        </div>
                                    </div>
                                    <div id="Group_7301_pmtctlnk">
                                        <div id="n_2628_gv_pmtctlnk">
                                            <span>Payment Link</span>
                                        </div>
                                        <div id="n_2628_gw_pmtctlnk">
                                            <span>Quantity {ProductUnits}</span>
                                        </div>
                                        <div id="Ksh_1490000_pmtctlnk">
                                            <span>{PaymentLinkCheckoutDetail['currency']}. {PaymentLinkCheckoutDetail['amount']}.00</span>
                                        </div>
                                        <svg className="Path_9110_pmtctlnk" viewBox="0 0 347 0.5">
                                            <path id="Path_9110_pmtctlnk" d="M 0 0 L 347 0">
                                            </path>
                                        </svg>
                                    </div>
                                    <div id="Group_7304_pmtctlnk">
                                        <div id="n_2628_pmtctlnk">
                                            <span>Delivery</span>
                                        </div>
                                        <div id="Ksh_14900_pmtctlnk">
                                            <span>{PaymentLinkCheckoutDetail['currency']}. {delivery_fee.toLocaleString()}.00</span>
                                        </div>
                                        <svg className="Path_9108_pmtctlnk" viewBox="0 0 347 0.5">
                                            <path id="Path_9108_pmtctlnk" d="M 0 0 L 347 0">
                                            </path>
                                        </svg>
                                    </div>
                                    <div id="Group_7303_pmtctlnk">
                                        <div id="n_2628_g_pmtctlnk">
                                            <span>Service</span>
                                        </div>
                                        <div id="Ksh_14900_g_pmtctlnk">
                                            <span>{PaymentLinkCheckoutDetail['currency']}.{ServiceFee.toLocaleString()}</span>
                                        </div>
                                        <svg className="Path_9109_pmtctlnk" viewBox="0 0 347 0.5">
                                            <path id="Path_9109_pmtctlnk" d="M 0 0 L 347 0">
                                            </path>
                                        </svg>
                                    </div>
                                    <div id="Group_7302_pmtctlnk">
                                        <div id="n_2628_ha_pmtctlnk">
                                            <span>Total</span>
                                        </div>
                                        <div id="Ksh_1504900_pmtctlnk">
                                            <span>{PaymentLinkCheckoutDetail['currency']}. {total.toLocaleString()}</span>
                                        </div>
                                        <svg className="Path_9109_g_pmtctlnk" viewBox="0 0 347 0.5">
                                            <path id="Path_9109_g_pmtctlnk" d="M 0 0 L 347 0">
                                            </path>
                                        </svg>
                                    </div>
                                    {
                                        hasExpired ? (
                                            <span id="has_expired_pl">Link has Expired</span>
                                        ) : (
                                            <NavLink to={`/receipt`} className="nav-link">
                                                <div id="Group_5214_pmtctlnk" onClick={submit}>
                                                    <div id="Group_3969_pmtctlnk">
                                                        <svg className="Rectangle_2057_go_pmtctlnk">
                                                            <linearGradient id="Rectangle_2057_go_pmtctlnk" spreadMethod="pad" x1="0.066" x2="0.79" y1="0" y2="1.3">
                                                                <stop offset="0" stop-color="#5b97f4" stop-opacity="1"></stop>
                                                                <stop offset="1" stop-color="#5899ff" stop-opacity="1"></stop>
                                                            </linearGradient>
                                                            <rect id="Rectangle_2057_go_pmtctlnk" rx="7" ry="7" x="0" y="0" width="347" height="46">
                                                            </rect>
                                                        </svg>
                                                    </div>

                                                    <div id="PAY_KES_1549000_pmtctlnk">
                                                        <span>PAY {PaymentLinkCheckoutDetail['currency']}. {total.toLocaleString()} </span>
                                                    </div>


                                                </div>
                                            </NavLink>
                                        )
                                    }
                                    <div id="Group_7312_pmtctlnk">
                                        <div id="An_Mpesa_prompt_will_be_sent_t_pmtctlnk">
                                            <span>(An Mpesa prompt will be sent to the entered phone number. <br />Fill in your pin to complete the purchase)</span>
                                        </div>
                                        <div id="Group_7311_pmtctlnk">
                                            <div id="chpter_pmtctlnk">
                                                <span>chpter.</span>
                                            </div>
                                            <div id="Contact_pmtctlnk">
                                                <span>Contact</span>
                                            </div>
                                            <div id="Privacy_Policy_pmtctlnk">
                                                <span>Privacy Policy</span>
                                            </div>
                                            <div id="Group_4532_pmtctlnk">
                                                <div id="Layer_2_it_pmtctlnk">
                                                    <div id="radio-button-off_pmtctlnk">
                                                        <svg className="Rectangle_2094_pmtctlnk">
                                                            <rect id="Rectangle_2094_pmtctlnk" rx="0" ry="0" x="0" y="0" width="12" height="12">
                                                            </rect>
                                                        </svg>
                                                        <svg className="Path_9068_pmtctlnk" viewBox="2 2 9.819 9.941">
                                                            <path id="Path_9068_pmtctlnk" d="M 6.909521579742432 11.94050121307373 C 4.198067188262939 11.94050121307373 2 9.715242385864258 2 6.970250606536865 C 2 4.225256443023682 4.198067665100098 2 6.909521579742432 2 C 9.620976448059082 2 11.81904315948486 4.225256443023682 11.81904315948486 6.970250606536865 C 11.81904315948486 9.715244293212891 9.620974540710449 11.94050121307373 6.909521579742432 11.94050121307373 Z M 6.909521579742432 2.994050264358521 C 4.740357875823975 2.994050264358521 2.981904268264771 4.774255275726318 2.981904268264771 6.970250606536865 C 2.981904268264771 9.166244506835938 4.740358352661133 10.94645118713379 6.909521579742432 10.94645118713379 C 9.078683853149414 10.94645118713379 10.83713817596436 9.166244506835938 10.83713817596436 6.970250606536865 C 10.83713817596436 4.774255275726318 9.078683853149414 2.994050264358521 6.909521579742432 2.994050264358521 Z">
                                                            </path>
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div id="c_pmtctlnk">
                                                    <span>c</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="Group_5215_pmtctlnk">
                                            <div id="powered_by_pmtctlnk">
                                                <span>powered by</span>
                                            </div>
                                            <div id="chpter_i_pmtctlnk">
                                                <span>chpter.</span>
                                            </div>
                                            <div id="Text_pmtctlnk">
                                                <span>|</span>
                                            </div>
                                            <div id="Learn_more_about_chpter_checko_pmtctlnk">
                                                <span>Learn more about chpter checkout</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                )}
            </MediaQuery>

            <MediaQuery maxWidth={1000}>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick={false}
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    toastClassName="custom-toast"
                    className="toast-container"
                    style={{
                        position: "fixed",
                        color: "white",
                        top: "10%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        height: "10vh",
                        width: "70vw",
                    }}
                />
                {isLoading ? (
                    <BouncingDotsLoader></BouncingDotsLoader>
                ) : (
                

                    <div className="checkoutlink_pmtctlnk_mob">
                        <div className="checkoutlink-inner_pmtctlnk_mob">
                            <div className="group-frame_pmtctlnk_mob">
                                <div className="group-frame_pmtctlnk_mob">
                                    <div className="checkout_pmtctlnk_mob">
                                        {/* Header */}
                                        <div className="header_pmtctlnk_mob">
                                            <div className="frame-parent1_pmtctlnk_mob">
                                                <div className="layer-3-parent_pmtctlnk_mob">
                                                    <div className="layer-3-icon_pmtctlnk_mob">
                                                        <svg className="Rectangle_2221_chktlnk_mob">
                                                            <rect id="Rectangle_2221_chktlnk_mob" rx="7" ry="7" x="0" y="0" width="25" height="25">
                                                            </rect>
                                                        </svg>
                                                        <div id="Layer_2_chktlnk_mob">
                                                            <div id="radio-button-on_chktlnk_mob">
                                                                <svg className="Rectangle_2222_chktlnk_mob">
                                                                    <rect id="Rectangle_2222_chktlnk_mob" rx="0" ry="0" x="0" y="0" width="15" height="15">
                                                                    </rect>
                                                                </svg>
                                                                <svg className="Path_9137_chktlnk_mob" viewBox="2 2 12.813 12.813">
                                                                    <path id="Path_9137_chktlnk_mob" d="M 8.406643867492676 2 C 4.868351936340332 2 2 4.868351936340332 2 8.406643867492676 C 2 11.94493389129639 4.868351936340332 14.81328678131104 8.406643867492676 14.81328678131104 C 11.94493389129639 14.81328678131104 14.81328678131104 11.94493389129639 14.81328678131104 8.406643867492676 C 14.81328678131104 4.868351936340332 11.94493389129639 2 8.406643867492676 2 Z M 8.406643867492676 13.53195762634277 C 5.576010227203369 13.53195762634277 3.281328678131104 11.23727512359619 3.281328678131104 8.406643867492676 C 3.281328678131104 5.576010227203369 5.576010227203369 3.281328678131104 8.406643867492676 3.281328678131104 C 11.23727512359619 3.281328678131104 13.53195762634277 5.576010227203369 13.53195762634277 8.406643867492676 C 13.53195762634277 11.23727512359619 11.23727512359619 13.53195762634277 8.406643867492676 13.53195762634277 Z">
                                                                    </path>
                                                                </svg>
                                                                <svg className="Path_9138_chktlnk_mob" viewBox="7 7 6.407 6.407">
                                                                    <path id="Path_9138_chktlnk_mob" d="M 10.20332145690918 7 C 8.434176445007324 7 7 8.434176445007324 7 10.20332145690918 C 7 11.97246742248535 8.434176445007324 13.40664386749268 10.20332145690918 13.40664386749268 C 11.97246742248535 13.40664386749268 13.40664386749268 11.97246742248535 13.40664386749268 10.20332145690918 C 13.40664386749268 8.434176445007324 11.97246742248535 7 10.20332145690918 7 Z M 10.20332145690918 12.12531471252441 C 9.141834259033203 12.12531471252441 8.281329154968262 11.26480960845947 8.281329154968262 10.20332145690918 C 8.281329154968262 9.141834259033203 9.141834259033203 8.281329154968262 10.20332145690918 8.281329154968262 C 11.26480960845947 8.281329154968262 12.12531471252441 9.141834259033203 12.12531471252441 10.20332145690918 C 12.12531471252441 11.26480960845947 11.26480960845947 12.12531471252441 10.20332145690918 12.12531471252441 Z">
                                                                    </path>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="invoice-detail_pmtctlnk_mob">{PaymentLinkCheckoutDetail['company_name'] || ''}</div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Logo */}
                                        <div className="checkoutimage_pmtctlnk_mob">
                                            <img className="checkoutimage-child_pmtctlnk_mob" src={"https://chpter-3-jarvis-bucket.s3.amazonaws.com/branding/" + PaymentLinkCheckoutDetail['logo']} alt='' />
                                        </div>

                                        {/* PaymentLink Bio */}
                                        <div className="checkoutbio_pmtctlnk_mob">
                                            <div className="frame-parent2_pmtctlnk_mob">
                                                <div className="alpineallstars-wrapper_pmtctlnk_mob">
                                                    <div className="alpineallstars_pmtctlnk_mob">Payment Link</div>
                                                </div>
                                                <div className="kes-1490000-wrapper_pmtctlnk_mob">
                                                    <b className="checkoutlink-inner1_pmtctlnk_mob">{PaymentLinkCheckoutDetail['currency']}. {PaymentLinkCheckoutDetail['amount'] || ''}.00</b>
                                                </div>
                                                {/* <div className="highest-level-of-style-safety-wrapper_pmtctlnk_mob">
                                                    <div className="highest-level-of_pmtctlnk_mob">{PaymentLinkCheckoutDetail['description'] || ''}</div>
                                                </div> */}
                                            </div>
                                        </div>

                                        {/* Payment Method */}
                                        <div className="paymentmethod_pmtctlnk_mob">
                                            <div className="payment-method_pmtctlnk_mob">Payment Method</div>
                                            <div className="group-parent4_pmtctlnk_mob">
                                                <button id="rectangle-parent_pmtctlnk_mob" onClick={buttonHandler} name="mpesa" className={payMethod === "mpesa" ? "active" : ""}>
                                                    <div className="mpesa_pmtctlnk_mob">Mpesa</div>
                                                    <img className="mpesa-icon_pmtctlnk_mob" src="/media/Image14@2x.png" alt="" />
                                                </button>
                                                <button id="rectangle-parent_pmtctlnk_mob" onClick={buttonHandler} name="card" className={payMethod === "card" ? "active" : ""}>
                                                    <div className="card_pmtctlnk_mob">Card</div>
                                                    <div className="group-icon_pmtctlnk_mob">
                                                        <svg className="Rectangle_2350_chktlnk">
                                                            <rect id="Rectangle_2350_chktlnk" rx="0" ry="0" x="0" y="0" width="20" height="20">
                                                            </rect>
                                                        </svg>
                                                        <svg className="Path_9249_chktlnk" viewBox="2 5 16.561 11.593">
                                                            <path id="Path_9249_chktlnk" d="M 16.07674217224121 5 L 4.484130859375 5 C 3.112183332443237 5 1.999999642372131 6.112183570861816 2 7.484131813049316 L 2 14.10848045349121 C 2 15.48042869567871 3.112183094024658 16.59261131286621 4.484130382537842 16.59261131286621 L 16.07674217224121 16.59261131286621 C 17.44868850708008 16.59261131286621 18.56087303161621 15.48042869567871 18.56087303161621 14.10848045349121 L 18.56087303161621 7.484130859375 C 18.56087303161621 6.112183094024658 17.44868850708008 5 16.07674217224121 5 Z M 3.656087398529053 7.484130859375 C 3.656087398529053 7.026814937591553 4.026814937591553 6.656087398529053 4.484130859375 6.656087398529053 L 16.07674217224121 6.656087398529053 C 16.5340576171875 6.656087398529053 16.90478515625 7.026814937591553 16.90478515625 7.484130859375 L 16.90478515625 8.312174797058105 L 3.656087398529053 8.312174797058105 L 3.656087398529053 7.484130859375 Z M 16.90478515625 14.10848045349121 C 16.90478515625 14.5657958984375 16.5340576171875 14.93652439117432 16.07674217224121 14.93652439117432 L 4.484130859375 14.93652439117432 C 4.026814937591553 14.93652439117432 3.656087398529053 14.5657958984375 3.656087398529053 14.10848045349121 L 3.656087398529053 9.968262672424316 L 16.90478515625 9.968262672424316 L 16.90478515625 14.10848045349121 Z">
                                                            </path>
                                                        </svg>
                                                        <svg className="Path_9250_chktlnk" viewBox="6 13 4.968 1.656">
                                                            <path id="Path_9250_chktlnk" d="M 6.828043937683105 14.65608692169189 L 10.14021873474121 14.65608692169189 C 10.5975341796875 14.65608692169189 10.96826171875 14.28535938262939 10.96826171875 13.82804393768311 C 10.96826171875 13.3707275390625 10.5975341796875 13 10.14021873474121 13 L 6.828043937683105 13 C 6.370728015899658 13 6 13.37072849273682 6 13.82804393768311 C 6 14.28535938262939 6.370728015899658 14.65608692169189 6.828043937683105 14.65608692169189 Z">
                                                            </path>
                                                        </svg>
                                                        <svg className="Path_9251_chktlnk" viewBox="14 13 3.312 1.656">
                                                            <path id="Path_9251_chktlnk" d="M 14.82804393768311 14.65608692169189 L 16.484130859375 14.65608692169189 C 16.94144630432129 14.65608692169189 17.31217384338379 14.28535938262939 17.31217384338379 13.82804393768311 C 17.31217384338379 13.3707275390625 16.94144630432129 13 16.484130859375 13 L 14.82804393768311 13 C 14.3707275390625 13 14.00000095367432 13.37072849273682 14.00000095367432 13.82804393768311 C 14.00000095367432 14.28535938262939 14.37072849273682 14.65608692169189 14.82804393768311 14.65608692169189 Z">
                                                            </path>
                                                        </svg>
                                                    </div>
                                                </button>
                                                <button id="rectangle-parent_pmtctlnk_mob" disabled={true} style={{ backgroundColor: "#f0f0f0" }}>
                                                    <div className="mpesa_pmtctlnk_mob">Bank</div>
                                                    <img className="icons8-bank-96_pmtctlnk_mob" src="/media/icons8-bank-96.png" alt="" />
                                                </button>
                                            </div>
                                        </div>

                                        {/* Inputs */}
                                        {payMethod === 'mpesa' && (
                                            <>
                                                <div className="inputfields_pmtctlnk_mob">
                                                    <div className="inputname-parent_pmtctlnk_mob">
                                                        <div className="inputname_pmtctlnk_mob">Full Name</div>
                                                        <input
                                                            className="frame-input_pmtctlnk_mob"
                                                            type="text"
                                                            name="cust_name"
                                                            placeholder="Input full name"
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    <div className="inputname-parent_pmtctlnk_mob">
                                                        <div className="inputname_pmtctlnk_mob">Location</div>
                                                        <GoogleMapsAPIMobile onChange={handleLocationChange} />
                                                    </div>
                                                    <div className="inputname-parent_pmtctlnk_mob">
                                                        <div className="inputname_pmtctlnk_mob">Phone Number</div>
                                                        <input
                                                            className="frame-input_pmtctlnk_mob"
                                                            type="text"
                                                            placeholder="0700 000 000"
                                                            name="phone_number"
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                                {navigationInputs !== null ? (
                                                    <>
                                                        {navigationInputs.map((navigationInput: any, index: number) => {
                                                            const inputName = `Input${index + 1}`;
                                                            return (
                                                                <div className="additionalfields_pmtctlnk_mob">
                                                                    <div className="inputname-parent_pmtctlnk_mob">
                                                                        <div className="inputname_pmtctlnk_mob">{navigationInput.label}1</div>
                                                                        <input
                                                                            className="frame-input_pmtctlnk_mob"
                                                                            type={navigationInput.type}
                                                                            name={inputName}
                                                                            placeholder={navigationInput.placeholder}
                                                                            onChange={handleChange}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        )}
                                        {payMethod === 'card' && (
                                            <>
                                                <div className="inputfields_pmtctlnk_mob">
                                                    <div className="inputname-parent_pmtctlnk_mob">
                                                        <div className="inputname_pmtctlnk_mob">Full Name</div>
                                                        <input
                                                            className="frame-input_pmtctlnk_mob"
                                                            type="text"
                                                            name="cust_name"
                                                            placeholder="Input full name"
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    <div className="inputname-parent_pmtctlnk_mob">
                                                        <div className="inputname_pmtctlnk_mob">Location</div>
                                                        <GoogleMapsAPIMobile onChange={handleLocationChange} />
                                                    </div>
                                                    <div className="inputname-parent_pmtctlnk_mob">
                                                        <div className="inputname_pmtctlnk_mob">Email</div>
                                                        <input
                                                            className="frame-input_pmtctlnk_mob"
                                                            type="text"
                                                            name="email"
                                                            placeholder="Input Email Address"
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                                {navigationInputs !== null ? (
                                                    <>
                                                        {navigationInputs.map((navigationInput: any, index: number) => {
                                                            const inputName = `Input${index + 1}`;
                                                            return (
                                                                <div className="additionalfields_pmtctlnk_mob">
                                                                    <div className="inputname-parent_pmtctlnk_mob">
                                                                        <div className="inputname_pmtctlnk_mob">{navigationInput.label}1</div>
                                                                        <input
                                                                            className="frame-input_pmtctlnk_mob"
                                                                            type={navigationInput.type}
                                                                            name={inputName}
                                                                            placeholder={navigationInput.placeholder}
                                                                            onChange={handleChange}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </>


                                        )}
                                        {payMethod === 'bank' && (
                                            <>
                                                <div className="inputfields_pmtctlnk_mob">
                                                    <div className="inputname-parent_pmtctlnk_mob">
                                                        <div className="inputname_pmtctlnk_mob">Full Name</div>
                                                        <input
                                                            className="frame-input_pmtctlnk_mob"
                                                            type="text"
                                                            name="cust_name"
                                                            placeholder="Input full name"
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    <div className="inputname-parent_pmtctlnk_mob">
                                                        <div className="inputname_pmtctlnk_mob">Location</div>
                                                        <GoogleMapsAPIMobile onChange={handleLocationChange} />
                                                    </div>
                                                    <div className="inputname-parent_pmtctlnk_mob">
                                                        <div className="inputname_pmtctlnk_mob">Phone Number</div>
                                                        <input
                                                            className="frame-input_pmtctlnk_mob"
                                                            type="text"
                                                            placeholder="0700 000 000"
                                                            name="phone_number"
                                                            onChange={handleChange}
                                                            autoComplete='off'
                                                        />
                                                    </div>
                                                </div>
                                                {navigationInputs !== null ? (
                                                    <>
                                                        {navigationInputs.map((navigationInput: any, index: number) => {
                                                            const inputName = `Input${index + 1}`;
                                                            return (
                                                                <div className="additionalfields_pmtctlnk_mob">
                                                                    <div className="inputname-parent_pmtctlnk_mob">
                                                                        <div className="inputname_pmtctlnk_mob">{navigationInput.label}1</div>
                                                                        <input
                                                                            className="frame-input_pmtctlnk_mob"
                                                                            type={navigationInput.type}
                                                                            name={inputName}
                                                                            placeholder={navigationInput.placeholder}
                                                                            onChange={handleChange}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        )}

                                        {/* Summary */}
                                        <div className="summary_pmtctlnk_mob">
                                            <div className="summary-inner_pmtctlnk_mob">
                                                <div className="alpineallstars-wrapper_pmtctlnk_mob">
                                                    <div className="line-container_pmtctlnk_mob">
                                                        <div className="line-div_pmtctlnk_mob" />
                                                    </div>
                                                    <div className="alpineallstars-wrapper_pmtctlnk_mob">
                                                        <div className="your-summary_pmtctlnk_mob">Your summary</div>
                                                    </div>
                                                    <div className="line-container_pmtctlnk_mob">
                                                        <div className="line-div_pmtctlnk_mob" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="productssummary-parent_pmtctlnk_mob">
                                                <div className="productssummary_pmtctlnk_mob">
                                                    <div className="frame-parent4_pmtctlnk_mob">
                                                        <div className="frame-parent5_pmtctlnk_mob">
                                                            <div className="parent_pmtctlnk_mob">
                                                                <div className="div2_pmtctlnk_mob">
                                                                    <span className="adidas-ultrab_pmtctlnk_mob">Payment Link</span>
                                                                    <span className="adidas-ultrab_pmtctlnk_mob">{` `}</span>
                                                                    {` `}
                                                                </div>
                                                                <div className="div3_pmtctlnk_mob">Quantity {ProductUnits}</div>
                                                            </div>
                                                            <div className="ksh-1490000-wrapper_pmtctlnk_mob">
                                                                <div className="ksh-1490000_pmtctlnk_mob">{PaymentLinkCheckoutDetail['currency']}. {PaymentLinkCheckoutDetail['amount']}</div>
                                                            </div>
                                                        </div>
                                                        <div className="frame-child8_pmtctlnk_mob" />
                                                    </div>
                                                </div>
                                                <div className="pricesummary_pmtctlnk_mob">
                                                    <div className="frame-parent10_pmtctlnk_mob">
                                                        <div className="parent1_pmtctlnk_mob">
                                                            <div className="div8_pmtctlnk_mob">Delivery</div>
                                                            <div className="ksh-14900_pmtctlnk_mob">{PaymentLinkCheckoutDetail['currency']}. {delivery_fee.toLocaleString()}</div>
                                                        </div>
                                                        <div className="frame-child8_pmtctlnk_mob" />
                                                    </div>
                                                    <div className="frame-parent10_pmtctlnk_mob">
                                                        <div className="parent1_pmtctlnk_mob">
                                                            <div className="div8_pmtctlnk_mob">Service</div>
                                                            <div className="ksh-14900_pmtctlnk_mob">{PaymentLinkCheckoutDetail['currency']}.{ServiceFee.toLocaleString()}</div>
                                                        </div>
                                                        <div className="frame-child8_pmtctlnk_mob" />
                                                    </div>
                                                    <div className="frame-parent10_pmtctlnk_mob">
                                                        <div className="parent1_pmtctlnk_mob">
                                                            <div className="div8_pmtctlnk_mob">Total</div>
                                                            <div className="ksh-14900_pmtctlnk_mob">{PaymentLinkCheckoutDetail['currency']}. {total.toLocaleString()}</div>
                                                        </div>
                                                        <div className="frame-child8_pmtctlnk_mob" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* CheckoutButton */}
                                        {
                                            hasExpired ? (
                                                <span id="has_expired_pl_mob">Link has Expired</span>
                                            ) : (




                                                <NavLink to={`/receipt`} style={{ textDecoration: 'none' }} className="paymentbutton_pmtctlnk_mob">
                                                    <div className="pay-kes-1549000-wrapper_pmtctlnk_mob" onClick={submit}>
                                                        <div className="ksh-1490000_pmtctlnk_mob">PAY {PaymentLinkCheckoutDetail['currency']}. {total.toLocaleString()}</div>
                                                    </div>
                                                </NavLink>
                                            )
                                        }

                                        {/* Footer */}
                                        <div className="footer_pmtctlnk_mob">
                                            <div className="chpter-container_pmtctlnk_mob">
                                                <div className="contact_pmtctlnk_mob">
                                                    <p className="an-mpesa-prompt_pmtctlnk_mob">{`(An Mpesa prompt will be sent to the entered phone number. `}</p>
                                                    <p className="an-mpesa-prompt_pmtctlnk_mob">
                                                        Fill in your pin to complete the purchase
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="powered-by-parent_pmtctlnk_mob">
                                                <div className="powered-by_pmtctlnk_mob">powered by</div>
                                                <b className="chpter2_pmtctlnk_mob">chpter.</b>
                                                <div className="div11_pmtctlnk_mob">|</div>
                                                <div className="learn-more-about_pmtctlnk_mob">
                                                    Learn more about chpter checkout
                                                </div>
                                            </div>
                                            <div className="footer1_pmtctlnk_mob">
                                                <div className="contact_pmtctlnk_mob">chpter.</div>
                                                <div className="contact_pmtctlnk_mob">Contact</div>
                                                <div className="contact_pmtctlnk_mob">Privacy Policy</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </MediaQuery>
        </>
    )
};

export default PaymentLinkCheckout;
// Get Sub Categories
export async function get_subcategories(){

    const response = await fetch(process.env.REACT_APP_CHPTER_API_URL+"/sub_categories", {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer "+localStorage.getItem("JWT"),
          'Accept': 'application/json',
        },
      });
    const data = await response.json();
    console.log(data.status);
  
    if(data.status === 401){
      //Redirect to login Token expired
      window.location.href = '/login';

    }
  
    if(data.status === 200){
        return data.sub_categories;
    }
  
    return [];
  }

  
  // Get SubCategory Detail
  export async function get_subcategory_detail(subcategory_id: string | undefined){
  
      const response = await fetch(process.env.REACT_APP_CHPTER_API_URL+"/sub_category/" +  subcategory_id, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': "Bearer "+localStorage.getItem("JWT"),
            'Accept': 'application/json',
          },
        });
      const data = await response.json();
      console.log(data.status);
  
      if(data.status === 401){
        //Redirect to login Token expired
        
      }
  
      if(data.status === 200){
          return data.sub_category;
      }
  
      return [];
  }
// Get Successful Payments 
export async function get_successful_payments(){

  const response = await fetch(process.env.REACT_APP_CHPTER_API_URL+"/payments/status/Successful", {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer "+localStorage.getItem("JWT"),
        'Accept': 'application/json',
      },
    });
  const data = await response.json();
  console.log(data.status);

  if(data.status === 401){
    //Redirect to login Token expired
    window.location.href = '/login';

  }    
  if(data.status === 200){
      return data.payments;
  }

  return [];
}

// Get Pending Payments 
export async function get_pending_payments(){

  const response = await fetch(process.env.REACT_APP_CHPTER_API_URL+"/payments/status/Pending", {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer "+localStorage.getItem("JWT"),
        'Accept': 'application/json',
      },
    });
  const data = await response.json();
  console.log(data.status);

  if(data.status === 401){
    //Redirect to login Token expired
    window.location.href = '/login';

  }    
  if(data.status === 200){
      return data.payments;
  }

  return [];
}

// Get Pending Payments 
export async function get_refunded_payments(){

  const response = await fetch(process.env.REACT_APP_CHPTER_API_URL+"/payments/status/Refunded", {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer "+localStorage.getItem("JWT"),
        'Accept': 'application/json',
      },

    });
  const data = await response.json();
  console.log(data.status);

  if(data.status === 401){
    //Redirect to login Token expired
    window.location.href = '/login';

  }    
  if(data.status === 200){
      return data.payments;
  }

  return [];
}

// Get Payment Detail for all statuses
export async function get_payment_detail(payment_slug: string | undefined){

  const response = await fetch(process.env.REACT_APP_CHPTER_API_URL+"/payments/" + payment_slug , {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer "+localStorage.getItem("JWT"),
        'Accept': 'application/json',
      },
    });
  const data = await response.json();
  console.log(data.status);

  if(data.status === 401){
    //Redirect to login Token expired
    window.location.href = '/login';

  }    
  if(data.status === 200){
      return data;
  }

  return [];
}

// Get Payment Detail for all statuses
export async function validateCardPaymentTransaction(transactionRef: string | undefined){

  const response = await fetch(process.env.REACT_APP_CHPTER_API_URL+"/inhouse/card-payment/verify"  , {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      method: "POST",
      // Adding body or contents to send
      body: JSON.stringify({
        "reference": transactionRef,
        "transaction_reference": transactionRef
      }),
    });
  const data = await response.json();
  return data;
}
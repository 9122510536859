// Get Bank Account Info
export async function get_all_bank_account_info(){

  const response = await fetch( process.env.REACT_APP_CHPTER_API_URL + "/merchants/bankaccount", {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer "+ localStorage.getItem("JWT"),
        'Accept': 'application/json',
      },
    });
  const data = await response.json();
  console.log(data.status);

if(data.status === 401){
  //Redirect to login Token expired
  window.location.replace('/login');

}

  if(data.status === 200){
      return data.bank_account;
  }

  return [];
}


// Get Bank Account Info
export async function get_one_bank_account_info(merchant_id: string | undefined){

const response = await fetch(process.env.REACT_APP_CHPTER_API_URL+"/merchants/bankaccount/" + merchant_id, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': "Bearer "+ localStorage.getItem("JWT"),
      'Accept': 'application/json',
    },
  });
const data = await response.json();
console.log(data.status);
console.log(data);

if(data.status === 401){
  //Redirect to login Token expired
  
}

if(data.status === 200){
    return data.bank_account;
}

return [];
}
import axios from 'axios';
import React, { useEffect, useState, SyntheticEvent } from 'react';
import '../../styles/Settings/settingsbusinessprofile_mobile.css'
import '../../styles/Components/NavBar.css'
import '../../styles/Components/UserRibbon.css'
import '../../styles/Components/HotLinks_Settings.css'
import { nationalityList } from "../../utils/nationality-list";
import { get_merchant_profile } from "../../services/Settings/merchantprofileinfo";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import validator from 'validator';

import MediaQuery from 'react-responsive';
import { NavLink } from 'react-router-dom';




const SettingsBusinessProfile_Mobile = () => {
    const [formErrors, setFormErrors] = useState<Record<string, string>>({});
    const [redirect, setredirect] = useState(false)
    const [profileData, setProfileData] = useState([] as any);
    const [nationality, setNationality] = useState("Kenyan")
    const [notify_personal_email, setNotifyPersonalEmail] = useState(true)
    const [notify_mobile_number, setNotifyMobileNumber] = useState(true)
    const [country_code, setCountryCode] = useState("KE")
    const isEmailValidator = require('validator').isEmail
    const [formValues, setFormValues] = useState({} as any);


    useEffect(() => {
        async function fetchMerchantProfile() {
            var merchant = await get_merchant_profile();
            setProfileData(merchant);

            const initialValues = {
                company_name: merchant.company_name,
                industry: merchant.industry,
                business_email: merchant.business_email,
                office_number: merchant.office_number,
                address: merchant.address,
                pricing_model: merchant.pricing_model,

            };

            setFormValues(initialValues);
        }

        fetchMerchantProfile();

    }, []);


    function handleChange(event: { target: { name: any; value: any; }; }) {
        const { name, value } = event.target;
        setFormValues({ ...formValues, [name]: value });
        setProfileData((prevData: any) => ({
            ...prevData,
            [name]: value
        }));
    };

    const checkFormValidity = (formValues: {
        company_name: any; industry: any; pricing_model: any;
        business_email: any; office_number: any; address: any;
    }) => {
        const errors: any = {};

        if (!formValues.company_name) {
            toast("Company Name is required", { type: "error" });
            errors.company_name = 'Company Name is required';

        } else if (!formValues.industry) {
            toast("Industry input is required", { type: "error" });
            errors.industry = 'Industry input is required';
        }
        else if (!formValues.business_email) {
            toast("Business Email is required", { type: "error" });
            errors.business_email = 'Business Email is required';
        }
        else if (!validator.isEmail(formValues.business_email)) {
            toast("Business Email  is not valid", { type: "error" });
            errors.business_email = 'Business Email  is not valid';
        }
        else if (!formValues.office_number) {
            toast("Office Number is required", { type: "error" });
            errors.office_number = 'Office Number is required';
        }
        else if (!formValues.address) {
            toast("Address is required", { type: "error" });
            errors.address = 'Address is required';
        }
        else if (!formValues.pricing_model) {
            toast("Pricing model is required", { type: "error" });
            errors.pricing_model = 'Pricing model is required';
        }

        setFormErrors(errors);
        if (Object.keys(errors).length > 0) {
            let errorMessage = "";
            for (const key in formErrors) {
                if (formErrors[key]) {
                    errorMessage += formErrors[key] + "\n";
                }
            }

        }
        return Object.keys(errors).length === 0;
    };

    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();

        const isValid = checkFormValidity(formValues);
        if (!isValid) {
            return;
        }

        let api_url = process.env.REACT_APP_CHPTER_API_URL + '/merchants/profile'
        let headers = {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("JWT"),
        };

        const data = formValues;


        await axios.post(api_url, data, { headers: headers })
            .then((response) => {


                setredirect(true)

            })
            .catch((error) => {
                // Error
                if (error.response) {

                } else if (error.request) {
                   
                } else {
                   
                }
            });

    }

    return (




        <>
            {/* <MediaQuery maxWidth={1000}>
                <div id="MobileSettingsBusiness_bp_mob">
                    <div id="All_bp_mob">
                        <span>Business Details</span>
                    </div>
                    <a href="ProjectOverview.html">
                        <div id="All_gq_bp_mob">
                            <span>Account Information </span>
                        </div>
                    </a>
                    <NavLink to={'/settings-mobile'} className="nav-link">
                        <div id="Group_7465_bp_mob">
                            <svg className="Rectangle_2103_bp_mob">
                                <rect id="Rectangle_2103_bp_mob" rx="5" ry="5" x="0" y="0" width="32" height="32">
                                </rect>
                            </svg>
                            <svg className="Path_9390_bp_mob" viewBox="0.636 0.05 10.513 18">
                                <path id="Path_9390_bp_mob" d="M 5.357423305511475 10.11950778961182 C 4.766643047332764 9.528789520263672 4.766643047332764 8.571026802062988 5.357423305511475 7.980309963226318 L 10.7065315246582 2.631746768951416 C 11.29722785949707 2.041119337081909 11.2972297668457 1.083506107330322 10.7065315246582 0.4928695559501648 L 10.7065315246582 0.4928695559501648 C 10.11583614349365 -0.09776583313941956 9.158125877380371 -0.09776552766561508 8.567437171936035 0.4928695559501648 L 1.079220414161682 7.980309963226318 C 0.4884408712387085 8.571026802062988 0.4884408712387085 9.528774261474609 1.079220414161682 10.11950778961182 L 8.567437171936035 17.60687637329102 C 9.158125877380371 18.19756317138672 10.11583614349365 18.19756317138672 10.7065315246582 17.60687637329102 L 10.7065315246582 17.60687637329102 C 11.2972297668457 17.0163402557373 11.2972297668457 16.05868339538574 10.7065315246582 15.46799468994141 L 5.357423305511475 10.11950778961182 Z">
                                </path>
                            </svg>
                        </div>
                    </NavLink>
                    <div id="Group_7616_bp_mob">

                        <div id="Group_7615_bp_mob">

                            <input id="Rectangle_2128_bp_mob" type="text" name="industry" placeholder="Input Industry" value={profileData.industry || ''} onChange={handleChange} required />
                            <div id="All_gv_bp_mob">
                                <span>Industry</span>
                            </div>
                        </div>
                    </div>


                    <div id="Group_7620_bp_mob">

                        <div id="Group_7619_bp_mob">

                            <input id="Rectangle_2128_g_bp_mob" type="text" name="address" placeholder="Input Business Address" value={profileData.address || ''} onChange={handleChange} required />
                            <div id="All_g_bp_mob">
                                <span>Business Address</span>
                            </div>


                        </div>
                    </div>


                    <div id="Group_7618_bp_mob">

                        <div id="Group_7617_bp_mob">

                            <input id="Rectangle_2155_bp_mob" value={profileData.company_name || ''} onChange={handleChange} required />

                            <div id="All_ha_bp_mob">
                                <span>Company Name</span>
                            </div>

                        </div>
                    </div>
                    <div id="Group_7614_bp_mob">

                        <div id="Group_7613_bp_mob">

                            <input id="Rectangle_2128_hc_bp_mob" type="text" name="business_email" placeholder="Input Business Email" value={profileData.business_email || ''} onChange={handleChange} required />
                            <div id="All_hb_bp_mob">
                                <span>Business Email</span>
                            </div>


                        </div>
                        <div id="Group_7054_bp_mob">
                            <div id="Enable_notifications_bp_mob">
                                <span>Enable </span><span >notifications</span>
                            </div>
                            <svg className="Rectangle_2461_bp_mob">
                                <rect id="Rectangle_2461_bp_mob" rx="3" ry="3" x="0" y="0" width="15" height="15">
                                </rect>
                            </svg>
                        </div>
                    </div>
                    <div id="Group_7621_bp_mob">
                        <div id="Group_7611_bp_mob">

                            <input id="Rectangle_2128_hk_bp_mob" type="number" name="office_number" placeholder="700001234" value={profileData.office_number || ''} onChange={handleChange} required />
                            <div id="All_hi_bp_mob">
                                <span>Office Number</span>
                            </div>


                            <div id="Group_7612_bp_mob">
                                <div id="Enable_notifications_hn_bp_mob">
                                    <span>Enable </span><span >notifications</span>
                                </div>
                                <svg className="Rectangle_2461_ho_bp_mob">
                                    <rect id="Rectangle_2461_ho_bp_mob" rx="3" ry="3" x="0" y="0" width="15" height="15">
                                    </rect>
                                </svg>
                            </div>
                        </div>



                        <div id="Group_7610_bp_mob">

                            <div id="Group_761_bp_mob">
                                <div id="All_hi_bp_mob">
                                    <span>Pricing Model</span>
                                </div>


                                <select id="Rectangle_2129_bp_mob" name="pricing_model" placeholder="Input Pricing Model" value={profileData.pricing_model || ''} onChange={handleChange} required>
                                    <option value="">Select Pricing Model</option>
                                    <option value="Business">Business</option>
                                    <option value="Customer">Customer </option>
                                    <option value="Shared"> Shared</option>
                                </select>
                            </div>
                        </div>
                        <div id='Group_4_bp_mob'>
                            <div id="Group_4741_bp_mob">
                                <div id="Group_3969_bp_mob">
                                    <svg className="Rectangle_2057_bp_mob">
                                        <rect id="Rectangle_2057_bp_mob" rx="5" ry="5" x="0" y="0" width="199" height="30">
                                        </rect>
                                    </svg>
                                </div>
                                <div id="Group_4628_bp_mob" onClick={submit}>

                                    <div id="SAVE_CHANGES_bp_mob">
                                        <span>SAVE CHANGES</span>
                                    </div>

                                </div>
                            </div>
                            <div id="Group_4742_bp_mob">
                                <div id="Group_3969_hv_bp_mob">
                                    <svg className="Rectangle_2057_hw_bp_mob">
                                        <rect id="Rectangle_2057_hw_bp_mob" rx="5" ry="5" x="0" y="0" width="75" height="30">
                                        </rect>
                                    </svg>
                                </div>
                                <div id="Group_4628_hx_bp_mob">
                                    <a href="SignIn.html">
                                        <div id="CANCEL_bp_mob">
                                            <span>CANCEL</span>
                                        </div>
                                    </a>
                                </div>
                            </div>

                        </div>

                    </div>
                    <ToastContainer position="top-center" autoClose={5000} hideProgressBar={true} newestOnTop={false} closeOnClick={true}
                        pauseOnFocusLoss={true} draggable={true} pauseOnHover={true} toastClassName="custom-toast-mobile" className="toast-container-mobile" />
















                </div>
            </MediaQuery> */}
        </>
    )
}
export default SettingsBusinessProfile_Mobile;
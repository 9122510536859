import { useEffect, useState } from "react";
import { getPayoutDetails } from "../../services/Wallet/payoutsinfo";
import { get_merchant_profile } from "../../services/Settings/merchantprofileinfo";
import { useParams } from "react-router-dom";
import '../../styles/Wallet/successfulpayoutsreceipt.css'
import { datetimeFormatter_human } from "../../utils/date_formatter";










const SuccessfulPayoutReceipt = () => {

    let { slug } = useParams();
    let payout_slug = { slug }.slug



    const [PayoutDetail, setPayoutDetail] = useState([] as any);
    const [text, setText] = useState('');
    const [MerchantInfo, setMerchant] = useState([] as any);

    
    useEffect(() => {
        // Get Merchant Information
        async function fetchMerchantInfo() {
            var merchant = await get_merchant_profile();
            setMerchant(merchant);
            console.log('merchant', merchant)
        }


        fetchMerchantInfo();
        GetPayoutDetail();


    }, []);
    console.log(PayoutDetail, "PayoutDetail");


    async function GetPayoutDetail() {
        var payoutreceipt = await getPayoutDetails(payout_slug).then(response => {
            console.log(getPayoutDetails, "PayoutDetailll");
            setPayoutDetail(response.payout)
            console.log(payoutreceipt, "PayoutDetll");
            setPayoutDetail(response.payout)


        }).catch(error => {

        }).finally(() => {
            //Hide spinner

        })





    }



    return (
        <>



            <div className="payoutreceipt_spr_mob">
                <div className="payoutreceipt-inner_spr_mob">
                    <div className="group-wrapper_spr_mob">
                        <div className="group-wrapper_spr_mob">
                            <div className="payoutreceipt1_spr_mob">
                                <div className="header_spr_mob">
                                    <div className="payment-detail-wrapper_spr_mob">
                                        <div className="payment-detail_spr_mob">{MerchantInfo['company_name']}</div>
                                    </div>
                                </div>
                                <div className="body_spr_mob">
                                    <div className="barry-oloo_spr_mob">Your receipt from chpter.</div>
                                    <div className="body-inner_spr_mob">
                                        <div className="frame-parent_spr_mob">
                                            <div className="frame-wrapper_spr_mob">
                                                <div className="kes-1490000-wrapper_spr_mob">
                                                    <div className="kes-1490000_spr_mob">{datetimeFormatter_human(PayoutDetail['created_at'])}</div>
                                                </div>
                                            </div>
                                            <div className="july-2023-wrapper_spr_mob">
                                                <div className="july-2023_spr_mob">Status:{PayoutDetail['status']}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {PayoutDetail.payout_type === 'DOMESTIC' && (
                                     <>
                                     <div className="withdraw_spr_mob">
                                        <div className="withdraw-inner_spr_mob">
                                            <div className="frame-container_spr_mob">
                                                <div className="frame-div_spr_mob">
                                                    <div className="frame-wrapper1_spr_mob">
                                                        <div className="stardard-chartered-bank-parent_spr_mob">
                                                            <div className="stardard-chartered-bank_spr_mob">
                                                                Withdraw
                                                            </div>

                                                            <div className="wrapper_spr_mob">
                                                                <div className="div_spr_mob">
                                                                    To {PayoutDetail['payout_type'] === 'Mpesa' ? 'Mpesa' : (PayoutDetail['destination'] && PayoutDetail['destination'].split('|')[0])}
                                                                </div>
                                                            </div>





                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="receiptdetails_spr_mob">
                                            <div className="receiptdetails-inner_spr_mob">
                                                <div className="frame-group_spr_mob">
                                                    <div className="reference-parent_spr_mob">
                                                        <div className="reference_spr_mob">Receipent’s Name</div>
                                                        <div className="adidas-ultraboost-wrapper_spr_mob">
                                                            <div className="adidas-ultraboost_spr_mob">{MerchantInfo['company_name']}</div>
                                                        </div>
                                                    </div>
                                                    <div className="reference-parent_spr_mob">
                                                        <div className="reference_spr_mob">Destination</div>
                                                        <div className="adidas-ultraboost-wrapper_spr_mob">
                                                            <div className="adidas-ultraboost_spr_mob">
                                                                {PayoutDetail['payout_type'] === 'Mpesa' ? 'Mpesa' : (PayoutDetail['destination'] && PayoutDetail['destination'].split('|')[0])}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="reference-parent_spr_mob">
                                                        <div className="reference_spr_mob">{` Account `}</div>
                                                        <div className="adidas-ultraboost-frame_spr_mob">
                                                            <div className="adidas-ultraboost2_spr_mob">
                                                                {PayoutDetail['destination'] && PayoutDetail['destination'].split(':')[1].replace(/.(?=.{4})/g, "*")}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="reference-parent_spr_mob">
                                                        <div className="reference_spr_mob">Total Amount</div>
                                                        <div className="adidas-ultraboost-frame_spr_mob">
                                                            <div className="adidas-ultraboost2_spr_mob">{PayoutDetail['currency']} {PayoutDetail['amount']}.00</div>
                                                        </div>
                                                    </div>
                                                    <div className="reference-parent_spr_mob">
                                                        <div className="reference_spr_mob">Service Fee</div>
                                                        <div className="adidas-ultraboost-frame_spr_mob">
                                                            <div className="adidas-ultraboost2_spr_mob">KES 0.00</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </>
                                     )}
                                     {PayoutDetail.payout_type === 'MPESA' && PayoutDetail.destination && ( 

                                    <><div className="withdraw_spr_mob">
                                        <div className="withdraw-inner_spr_mob">
                                            <div className="frame-container_spr_mob">
                                                <div className="frame-div_spr_mob">
                                                    <div className="frame-wrapper1_spr_mob">
                                                        <div className="stardard-chartered-bank-parent_spr_mob">
                                                            <div className="stardard-chartered-bank_spr_mob">
                                                                Withdraw
                                                            </div>

                                                            <div className="wrapper_spr_mob">
                                                                <div className="div_spr_mob">
                                                                    To {PayoutDetail.destination}
                                                                </div>
                                                            </div>





                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div><div className="receiptdetails_spr_mob">
                                            <div className="receiptdetails-inner_spr_mob">
                                                <div className="frame-group_spr_mob">
                                                    <div className="reference-parent_spr_mob">
                                                        <div className="reference_spr_mob">Receipent’s Name</div>
                                                        <div className="adidas-ultraboost-wrapper_spr_mob">
                                                            <div className="adidas-ultraboost_spr_mob">{MerchantInfo['company_name']}</div>
                                                        </div>
                                                    </div>
                                                    <div className="reference-parent_spr_mob">
                                                        <div className="reference_spr_mob">Destination</div>
                                                        <div className="adidas-ultraboost-wrapper_spr_mob">
                                                            <div className="adidas-ultraboost_spr_mob">
                                                            {PayoutDetail.destination}                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="reference-parent_spr_mob">
                                                        <div className="reference_spr_mob">{` Account `}</div>
                                                        <div className="adidas-ultraboost-frame_spr_mob">
                                                            <div className="adidas-ultraboost2_spr_mob">
                                                                {PayoutDetail['destination'] && PayoutDetail['destination'].split(':')[1].replace(/.(?=.{4})/g, "*")}
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    <div className="reference-parent_spr_mob">
                                                        <div className="reference_spr_mob">Total Amount</div>
                                                        <div className="adidas-ultraboost-frame_spr_mob">
                                                            <div className="adidas-ultraboost2_spr_mob">{PayoutDetail['currency']} {PayoutDetail['amount']}.00</div>
                                                        </div>
                                                    </div>
                                                    <div className="reference-parent_spr_mob">
                                                        <div className="reference_spr_mob">Service Fee</div>
                                                        <div className="adidas-ultraboost-frame_spr_mob">
                                                            <div className="adidas-ultraboost2_spr_mob">KES 0.00</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div></>
                                         )}

                                
                                <div className="footer1_spr_mob">
                                    <div className="reference_spr_mob">chpter.</div>
                                    <div className="reference_spr_mob">Contact</div>
                                    <div className="reference_spr_mob">Privacy Policy</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>



    )
}
export default SuccessfulPayoutReceipt;

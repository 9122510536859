import React, { lazy, Suspense } from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";


//Eager (Non-Lazy) Loading Pages
import LandingPage from './pages/Welcome/landingpage';
import ChpterPricing from './pages/Welcome/chpterpricing';
import ChpterExplore from './pages/Welcome/chpterexplore';
import ChpterWooCommerce from './pages/Welcome/chpterwoocommerce';
import Register from './pages/Welcome/register';
import Login from './pages/Welcome/login';
import WelcomePage from './pages/Welcome/welcomepage';
import SuccessfulActivation from './pages/Welcome/successfulactivation';
import AccountVerification from './pages/Welcome/accountverification';
import EmailVerificationActionRequired from './pages/Welcome/emailverification-actionrequired';
import EmailVerification from './pages/Welcome/emailverification';
import PasswordReset from './pages/Welcome/passwordreset';
import PasswordResetToken from './pages/Welcome/passwordresettoken';
import PasswordChange from './pages/Welcome/passwordchange';


import Homepage from "./pages/HomePage/homepage";
import SuccessfulPayments from "./pages/Payments/successfulpayments";
import SuccessfulPaymentDetail from "./pages/Payments/successfulpaymentdetail";
import PendingPayments from "./pages/Payments/pendingpayments";
import PendingPaymentDetail from "./pages/Payments/pendingpaymentdetail";
import RefundedPayments from "./pages/Payments/refundedpayments";
import RefundedPaymentDetail from "./pages/Payments/refundedpaymentdetail";
import RefundInitiate from "./pages/Payments/refundinitiate";
import RefundConfirm from "./pages/Payments/refundconfirm";
import PaymentSubscriptions from "./pages/Payments/subscriptions";
import SubscriptionDetail from "./pages/Payments/subscriptiondetail";
import PaymentLinks from "./pages/Payments/paymentlinks";
import PaymentLinkDetail from "./pages/Payments/paymentlinksdetail";
import Invoices from "./pages/Payments/invoices";
import InvoiceDetail from "./pages/Payments/invoicedetail";
import PaymentLinkCheckout from "./pages/Payments/paymentlinkcheckout";
import SubscriptionCheckout from "./pages/Payments/subscriptioncheckout";
import InvoiceCheckout from "./pages/Payments/invoicecheckout";


import Products from "./pages/Products/products";
import ArchivedProducts from "./pages/Products/archivedproducts";
import Categories from "./pages/Products/categories";
import SubCategories from "./pages/Products/subcategories";
import ProductDetail from "./pages/Products/productdetail";
import ArchivedProductDetail from "./pages/Products/archivedproductdetail";
import CategoryDetail from "./pages/Products/categorydetail";
import SubCategoryDetail from "./pages/Products/subcategorydetail";
import ProductCheckoutLink from "./pages/Products/productscheckoutlink";

import Insights from "./pages/Insights/insights";

import Wallet from "./pages/Wallet/wallet";
import SuccessfulPayouts from "./pages/Wallet/successfulpayouts";
import PendingPayouts from "./pages/Wallet/pendingpayouts";
import PayoutDetail from "./pages/Wallet/payoutdetail";
import SuccessfulPayoutReceipt from './pages/Wallet/successfulpayoutreceipt';

import PayoutInitiate from "./pages/Wallet/payoutinitiate";
import PayoutConfirm from "./pages/Wallet/payoutconfirm";
import PayoutResponse from "./pages/Wallet/payoutresponse";
import SwapInitiate from "./pages/Wallet/swapinitiate";
import SwapConfirm from "./pages/Wallet/swapconfirm";
import SwapResponse from "./pages/Wallet/swapresponse";

import Developers from "./pages/Developers/developeroverview";
import DevelopersAPIkeys from "./pages/Developers/apikeys";
import DevelopersLogs from "./pages/Developers/apilogs";
import DevelopersService from "./pages/Developers/servicestatus";


import SetProfile from "./pages/Settings/settingsprofile";
import SetBankAccounts from "./pages/Settings/settingsbankaccounts";
import SetAddBank from "./pages/Settings/settingsbankaccounts_add";
import SetEditBank from "./pages/Settings/settingsbankaccounts_edit";
import SetBranding from "./pages/Settings/settingsbranding";
import SetTeam from "./pages/Settings/settingsteam";
import SetAddUser from "./pages/Settings/settingsteam_adduser";
import SetIntegrations from "./pages/Settings/settingsintegrations";
import SettingsProfile_Mobile from "./pages/Settings/settings_mobile";
import SettingsPersonalProfile_Mobile from "./pages/Settings/settingspersonalprofile_mobile";
import SettingsBusinessProfile_Mobile from "./pages/Settings/settingsbusinessprofile_mobile";
import SettingsBrandingMobile from "./pages/Settings/settingsbranding_mobile";
import APIKeys from "./pages/Settings/settingsapikeys";


// Marketing & Customers
import MarketingCustomers from "./pages/Marketing/customers";
import MarketingCustomerDetail from "./pages/Marketing/customerdetail";
import MarketingCampaigns from "./pages/Marketing/campaigns";

// Chat
import Chat from "./pages/Chat/chat";

// Shopify
import Shopify from "./pages/Shopify/shopify";
import NewCampaign from "./pages/Shopify/newcampaign";
import ShopifyPayments from "./pages/Shopify/shopifypayments";
import ShopifyAbandonedCarts from "./pages/Shopify/shopifyabandonedcarts";
import ShopifyCustomers from "./pages/Shopify/shopifycustomers";
import ShopifyProducts from "./pages/Shopify/shopifyproducts";
import ShopifyProfileSettings from "./pages/Shopify/shopifyprofilesettings";
import ShopifyIntegrationsSettings from "./pages/Shopify/shopifyintegrationssettings";
import ShopifyAPIKeysSettings from "./pages/Shopify/shopifyapikeyssettings";

// Redirect
import RedirectCheckout from "./pages/Redirect/redirectcheckout";


// 404 Page Not Found
import PageNotFound from './components/PageNotFound';


import CardPaymentVerifier from './pages/NoCodeCheckout/card-payment-verifier';


// LAZY LOADING
const NoCodeBranding = lazy(() => import('./pages/NoCodeCheckout/branding'));
const NoCodeAppearance = lazy(() => import('./pages/NoCodeCheckout/appearance'));
const NoCodeCheckoutPage = lazy(() => import('./pages/NoCodeCheckout/checkoutpage'));
const NoCodeConfirmationPage = lazy(() => import('./pages/NoCodeCheckout/confirmationpage'));

const LinkBasket = lazy(() => import('./pages/NoCodeCheckout/linkbasket'));
const LinkBasketDetail = lazy(() => import('./pages/NoCodeCheckout/linkbasketdetail'));
const LinkBasketCheckoutLink = lazy(() => import('./pages/NoCodeCheckout/linkbasket_checkoutlink'));
const CustomerReceipt = lazy(() => import('./pages/NoCodeCheckout/CustomerReceipt'));
const OopsPage = lazy(() => import('./pages/NoCodeCheckout/oopspage'));
const MerchantReceipt = lazy(() => import('./pages/NoCodeCheckout/MerchantReceipt'));

// Create and Edit Pages
const SubscriptionCreate = lazy(() => import('./pages/Payments/subscriptioncreate'));
const SubscriptionEdit = lazy(() => import('./pages/Payments/subscriptionedit'));
const EditInvoice = lazy(() => import('./pages/Payments/editinvoice'));
const PaymentLinkCreate = lazy(() => import('./pages/Payments/paymentlinkscreate'));
const CreateInvoice = lazy(() => import('./pages/Payments/createinvoice'));

const MarketingCreateCustomer = lazy(() => import('./pages/Marketing/createcustomer'));
const MarketingCreateCustomerSubscription = lazy(() => import('./pages/Marketing/createcustomer-subscription'));
const MarketingEditCustomer = lazy(() => import('./pages/Marketing/editcustomer'));
const MarketingCreateCampaign = lazy(() => import('./pages/Marketing/createcampaign'));
const MarketingEditCampaign = lazy(() => import('./pages/Marketing/editcampaign'));
const MarketingCreateMarketingCopy = lazy(() => import('./pages/Marketing/createmarketingcopy'));


const CreateProduct = lazy(() => import('./pages/Products/createproduct'));
const CreateProductSubscription = lazy(() => import('./pages/Products/createproduct-subscription'));
const EditProduct = lazy(() => import('./pages/Products/editproduct'));
const EditArchivedProduct = lazy(() => import('./pages/Products/editarchivedproduct'));
const CreateProductCategory = lazy(() => import('./pages/Products/createproductcategory'));
const EditProductCategory = lazy(() => import('./pages/Products/editproductcategory'));
const CreateProductSubCategory = lazy(() => import('./pages/Products/createproductsubcategory'));
const EditProductSubCategory = lazy(() => import('./pages/Products/editproductsubcategory'));


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {/* Welcome */}
          <Route path='/' element={<LandingPage />} />
          <Route path='/chpterpricing' element={<ChpterPricing />} />
          <Route path='/chpterexplore' element={<ChpterExplore />} />
          <Route path='/chpterwoocommerce' element={<ChpterWooCommerce />} />
          <Route path='/register' element={<Register />} />
          <Route path='/login' element={<Login />} />
          <Route path='/welcomepage' element={<WelcomePage />} />
          <Route path='/successful-activation' element={<SuccessfulActivation />} />
          <Route path='/account-verification' element={<AccountVerification />} />
          <Route path='/email-verification/:verificationToken' element={<EmailVerification />} />
          <Route path='/email-verification/action-required' element={<EmailVerificationActionRequired />} />
          <Route path='/password-reset' element={<PasswordReset />} />
          <Route path='/password-reset/:token' element={<PasswordResetToken />} />
          <Route path='/password-change' element={<PasswordChange />} />


          ActivateEmail
          {/* Homepage */}
          <Route path="/homepage" element={<Homepage />} />


          {/* Checkout */}
          <Route path="/successfulpayments" element={<SuccessfulPayments />} />
          <Route path="/successfulpaymentdetail/:slug" element={<SuccessfulPaymentDetail />} />
          <Route path="/pendingpayments" element={<PendingPayments />} />
          <Route path="/pendingpaymentdetail/:slug" element={<PendingPaymentDetail />} />
          <Route path="/refundedpayments" element={<RefundedPayments />} />
          <Route path="/refundedpaymentdetail/:slug" element={<RefundedPaymentDetail />} />
          <Route path="/payment-subscription" element={<PaymentSubscriptions />} />
          <Route path="/subscription-detail/:slug" element={<SubscriptionDetail />} />
          <Route path="/payment-links" element={<PaymentLinks />} />
          <Route path="/payment-link-detail/:slug" element={<PaymentLinkDetail />} />
          <Route path="/refund" element={<RefundInitiate />} />
          <Route path="/refund-confirm" element={<RefundConfirm />} />
          <Route path="/invoices" element={<Invoices />} />
          <Route path="/invoicedetail/:inv" element={<InvoiceDetail />} />
          <Route path="/paymentlinks/:slug/:brand_alias/checkout" element={<PaymentLinkCheckout />} />
          <Route path="/invoices/:inv/:brand_alias/checkout" element={<InvoiceCheckout />} />
          <Route path="/subscriptions/:slug/:brand_alias/checkout" element={<SubscriptionCheckout />} />
          <Route path=":slug/subscription-edit/" element={<Suspense fallback={<div></div>}>
            <SubscriptionEdit />
          </Suspense>} />
          <Route path="/subscription-create" element={<Suspense fallback={<div></div>}>
            <SubscriptionCreate />
          </Suspense>} />
          <Route path="/editinvoice/:inv" element={<Suspense fallback={<div></div>}>
            <EditInvoice />
          </Suspense>} />
          <Route path="/payment-link-create" element={<Suspense fallback={<div></div>}>
            <PaymentLinkCreate />
          </Suspense>} />
          <Route path="/createinvoice" element={<Suspense fallback={<div></div>}>
            <CreateInvoice />
          </Suspense>} />



          {/* Products */}
          <Route path="/products" element={<Products />} />
          <Route path="/archivedproducts" element={<ArchivedProducts />} />
          <Route path="/categories" element={<Categories />} />
          <Route path="/subcategories" element={<SubCategories />} />
          <Route path="/productdetail/:slug" element={<ProductDetail />} />
          <Route path="/archivedproductdetail/:slug" element={<ArchivedProductDetail />} />
          <Route path="/categorydetail/:id" element={<CategoryDetail />} />
          <Route path="/subcategorydetail/:id" element={<SubCategoryDetail />} />
          <Route path="/createproduct" element={<Suspense fallback={<div></div>}>
            <CreateProduct />
          </Suspense>} />
          <Route path="/createproduct-subscription" element={<Suspense fallback={<div></div>}>
            <CreateProductSubscription />
          </Suspense>} />
          <Route path=":id/editproduct/:slug" element={<Suspense fallback={<div></div>}>
            <EditProduct />
          </Suspense>} />
          <Route path=":id/editarchivedproduct" element={<Suspense fallback={<div></div>}>
            <EditArchivedProduct />
          </Suspense>} />
          <Route path="/createproductcategory" element={<Suspense fallback={<div></div>}>
            <CreateProductCategory />
          </Suspense>} />
          <Route path=":id/editproductcategory" element={<Suspense fallback={<div></div>}>
            <EditProductCategory />
          </Suspense>} />
          <Route path="/createproductsubcategory" element={<Suspense fallback={<div></div>}>
            <CreateProductSubCategory />
          </Suspense>} />
          <Route path=":id/editproductsubcategory" element={<Suspense fallback={<div></div>}>
            <EditProductSubCategory />
          </Suspense>} />
          <Route path="/productcheckoutlink/:brand_alias/:slug" element={<ProductCheckoutLink />} />


          {/* Homepage */}
          <Route path="/insights" element={<Insights />} />


          {/* Wallet */}
          <Route path="/wallet" element={<Wallet />} />
          <Route path="/successfulpayouts" element={<SuccessfulPayouts />} />
          <Route path="/pendingpayouts" element={<PendingPayouts />} />
          <Route path="/pendingpayouts" element={<PendingPayouts />} />
          <Route path="/payoutdetail/:slug" element={<PayoutDetail />} />
          <Route path="/withdraw" element={<PayoutInitiate />} />
          <Route path="/withdraw-confirm" element={<PayoutConfirm />} />
          <Route path="/withdraw-response" element={<PayoutResponse />} />
          <Route path="/swap" element={<SwapInitiate />} />
          <Route path="/swap-confirm" element={<SwapConfirm />} />
          <Route path="/swap-response" element={<SwapResponse />} />
          <Route path="/successfulpayoutsreceipt/:slug" element={<SuccessfulPayoutReceipt />} />


          {/* Developers */}
          <Route path="/developers" element={<Developers />} />
          <Route path="/developers-apikeys" element={<DevelopersAPIkeys />} />
          <Route path="/developers-logs" element={<DevelopersLogs />} />
          <Route path="/developers-service" element={<DevelopersService />} />


          {/* Settings */}
          <Route path="/settings-profile" element={<SetProfile />} />
          <Route path="/settings-bankaccounts" element={<SetBankAccounts />} />
          <Route path="/settings-addbank" element={<SetAddBank />} />
          <Route path=":id/settings-editbank" element={<SetEditBank />} />
          <Route path="/settings-branding" element={<SetBranding />} />
          <Route path="/settings-team" element={<SetTeam />} />
          <Route path="/settings-adduser" element={<SetAddUser />} />
          <Route path="/settings-integrations" element={<SetIntegrations />} />
          <Route path="/settings-mobile" element={<SettingsProfile_Mobile />} />
          <Route path="/settings_personalprofilemobile" element={<SettingsPersonalProfile_Mobile />} />
          <Route path="/settings_businessprofilemobile" element={<SettingsBusinessProfile_Mobile />} />
          <Route path="/settings_brandingmobile" element={<SettingsBrandingMobile />} />
          <Route path="/settings_apikeys" element={<APIKeys />} />



          {/* No-Code Checkout */}
          <Route path="/no-code-branding" element={<Suspense fallback={<div></div>}>
            <NoCodeBranding />
          </Suspense>} />
          <Route path="/no-code-appearance" element={<Suspense fallback={<div></div>}>
            <NoCodeAppearance />
          </Suspense>} />
          <Route path="/no-code-checkoutpage" element={<Suspense fallback={<div></div>}>
            <NoCodeCheckoutPage />
          </Suspense>} />
          <Route path="/no-code-confirmationpage" element={<Suspense fallback={<div></div>}>
            <NoCodeConfirmationPage />
          </Suspense>} />
          <Route path="/checkout-customer/CheckoutLinkReceipt/:slug" element={<Suspense fallback={<div></div>}>
            <CustomerReceipt />
          </Suspense>} />
          <Route path="/checkout-merchant/CheckoutLinkReceipt/:slug" element={<Suspense fallback={<div></div>}>
            <MerchantReceipt />
          </Suspense>} />


          {/* LinkBasket -- CheckoutLink */}
          <Route path="/linkbasket/:brand_alias" element={<Suspense fallback={<div></div>}>
            <LinkBasket />
          </Suspense>} />
          <Route path="/linkbasketdetail/:brand_alias/:categoryID" element={<Suspense fallback={<div></div>}>
            <LinkBasketDetail />
          </Suspense>} />
          <Route path="/linkbasketcheckout/:brand_alias/:slug" element={<Suspense fallback={<div></div>}>
            <LinkBasketCheckoutLink />
          </Suspense>} />
          <Route path="/oopspage" element={<Suspense fallback={<div></div>}>
            <OopsPage />
          </Suspense>} />
          <Route path="/card-payment-verifier" element={<Suspense fallback={<div></div>}>
            <CardPaymentVerifier />
          </Suspense>} />


          {/* Open-AI-test */}
          {/* <Route path="/openai-test" element={<OpenAItesting />} /> */}


          {/* Marketing & Customers */}.
          <Route path="/customers" element={<MarketingCustomers />} />
          <Route path="/customerdetail/:id" element={<MarketingCustomerDetail />} />
          <Route path="/marketingcampaigns" element={<MarketingCampaigns />} />
          <Route path="/createcustomer" element={<Suspense fallback={<div></div>}>
            <MarketingCreateCustomer />
          </Suspense>} />
          <Route path="/createcustomer-subscription" element={<Suspense fallback={<div></div>}>
            <MarketingCreateCustomerSubscription />
          </Suspense>} />
          <Route path=":id/editcustomer/" element={<Suspense fallback={<div></div>}>
            <MarketingEditCustomer />
          </Suspense>} />
          <Route path="/marketingcreatecampaign" element={<Suspense fallback={<div></div>}>
            <MarketingCreateCampaign />
          </Suspense>} />
          <Route path="/marketingeditcampaign" element={<Suspense fallback={<div></div>}>
            <MarketingEditCampaign />
          </Suspense>} />
          <Route path="/marketingcreatemarketingcopy" element={<Suspense fallback={<div></div>}>
            <MarketingCreateMarketingCopy />
          </Suspense>} />


          {/* Chat */}
          <Route path="/chat" element={<Chat />} />


          {/* Shopify */}
          <Route path="/shopify" element={<Shopify />} />
          <Route path="/newcampaign" element={<NewCampaign />} />
          <Route path="/shopifypayments" element={<ShopifyPayments />} />
          <Route path="/shopifyabandonedcarts" element={<ShopifyAbandonedCarts />} />
          <Route path="/shopifycustomers" element={<ShopifyCustomers />} />
          <Route path="/shopifyproducts" element={<ShopifyProducts />} />
          <Route path="/shopifyprofilesettings" element={<ShopifyProfileSettings />} />
          <Route path="/shopifyintegrationssettings" element={<ShopifyIntegrationsSettings />} />
          <Route path="/shopifyapikeyssettings" element={<ShopifyAPIKeysSettings />} />


          {/* Redirect */}
          <Route path="/redirectcheckout" element={<RedirectCheckout />} />


          {/* 404 Page Not Found */}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
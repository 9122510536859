// Get Merchant Profile Info
export async function get_merchant_profile(){

    const response = await fetch( process.env.REACT_APP_CHPTER_API_URL + "/merchants/profile", {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer "+ localStorage.getItem("JWT"),
          'Accept': 'application/json',
        },
      });
    const data = await response.json();
    console.log(data.status);

  if(data.status === 401){
    //Redirect to login Token expired
    window.location.replace('/login');

  }

    if(data.status === 200){
        return data.merchant;
    }

    return [];
}
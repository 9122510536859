import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {NavLink} from 'react-router-dom';


const RefundInitiate = () => {
    return (
        <>
        <main className="form-signin">
            <div id="InitiateRefund_inrf">
                <div id="All_inrf">
                    <span>Initiate Refund</span>
                </div>
                <div id="Group_6658_inrf">
                    <NavLink to={'/refund-confirm'} className="nav-link" style={{ textDecoration: 'none' }}>
                    <div id="Group_4741_inrf">
                        <div id="Group_3969_inrf">
                            <svg className="Rectangle_2057_inrf">
                                <rect id="Rectangle_2057_inrf" rx="5" ry="5" x="0" y="0" width="125" height="30">
                                </rect>
                            </svg>
                        </div>
                        <div id="Group_4628_inrf">
                            <div id="REFUND_inrf">
                                <span>REFUND</span>
                            </div>
                        </div>
                    </div>
                    </NavLink>
                    <div id="Group_4742_inrf">
                        <div id="Group_3969_ez_inrf">
                            <svg className="Rectangle_2057_e_inrf">
                                <rect id="Rectangle_2057_e_inrf" rx="5" ry="5" x="0" y="0" width="75" height="30">
                                </rect>
                            </svg>
                        </div>
                        <div id="Group_4628_e_inrf">
                            <div id="CANCEL_inrf">
                                <span>CANCEL</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="Group_6793_inrf">
                    <div id="Group_6661_inrf">
                        <div id="Group_6654_inrf">
                            <select id="Rectangle_2155_inrf" placeholder="Select a reason">
                                <option value="Requested by customer"> Requested by customer</option>
                                <option value="Product/Service not rendered"> Product/Service not rendered</option>
                                <option value="Duplicate payment"> Duplicate payment</option>
                                <option value="Other reason"> Other reason</option>
                            </select>
                        </div>
                        <div id="All_fc_inrf">
                            <span>Refund Reason</span>
                        </div>
                    </div>
                    <div id="Group_6790_inrf">
                        <svg className="Rectangle_2429_inrf">
                            <rect id="Rectangle_2429_inrf" rx="5" ry="5" x="0" y="0" width="326" height="97">
                            </rect>
                        </svg>
                        <div id="Group_6656_inrf">
                            {/* TODO -- Pass amount and currency of payment */}
                            <input id="All_fg_inrf" type="number" name="amount" placeholder="00.00"/>
                            <div id="All_fh_inrf">
                                <span>KES.</span>
                            </div>
                        </div>
                        <div id="All_fi_inrf">
                            <span>Refund Amount</span>
                        </div>
                    </div>
                </div>
                <div id="Group_6792_inrf">
                    <div id="Refunds_take_510_days_to_appea_inrf">
                        <span>Refunds take 5–10 days to appear on a <br/>customer’s statement. Fees for the original <br/>payment won’t be returned, but there are no <br/>additional fees for the refund.</span><br/><span>Learn More.</span>
                    </div>
                    <svg className="icons8-info-48_inrf" viewBox="2 2 20 20">
                        <path id="icons8-info-48_inrf" d="M 12 2 C 6.488996982574463 2 2 6.488996982574463 2 12 C 2 17.5110034942627 6.488996982574463 22 12 22 C 17.5110034942627 22 22 17.5110034942627 22 12 C 22 6.488996982574463 17.5110034942627 2 12 2 Z M 12 4 C 16.43012237548828 4 20 7.569877624511719 20 12 C 20 16.43012237548828 16.43012237548828 20 12 20 C 7.569877624511719 20 4 16.43012237548828 4 12 C 4 7.569877624511719 7.569877624511719 4 12 4 Z M 11 7 L 11 9 L 13 9 L 13 7 L 11 7 Z M 11 11 L 11 17 L 13 17 L 13 11 L 11 11 Z">
                        </path>
                    </svg>
                </div>
            </div>
        </main>
        </>
    )
};

export default RefundInitiate;

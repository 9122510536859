// Get Payouts
export async function get_payouts(status:string){
  

  const response = await fetch(process.env.REACT_APP_CHPTER_API_URL+"/wallet/payouts/"+status, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer "+localStorage.getItem("JWT"),
        'Accept': 'application/json',
      },
    });
  const data = await response.json();
  console.log(data.status);

  if(data.status === 401){
    //Redirect to login Token expired
    window.location.href = '/login';

  }

  if(data.status === 200){
      return data.payouts;
  }

  return [];
}

// Get Payouts
export async function getPayoutDetails(slug:any){
  

  const response = await fetch(process.env.REACT_APP_CHPTER_API_URL+"/wallet/payout-detail/"+slug, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer "+localStorage.getItem("JWT"),
        'Accept': 'application/json',
      },
    });
  const data = await response.json();

  if(data.status === 401){
    //Redirect to login Token expired
    window.location.href = '/login';

  }

  if(data.status === 200){
      return data;
  }

  return [];
}






import '../../styles/Welcome/successfulactivation.css';
import React from 'react'
import { NavLink } from "react-router-dom"


const SuccessfulActivation =() => {

    return (
        <div>
            <div id="SuccessfulActivation" style={{left: "0px", transformOrigin: "0px 0px", transform: "scale(0.755)"}}>
                <div id="continue_sa">
                    <span>continue</span>
                </div>
                <div id="Group_4528_sa">
                    <div id="Group_3168_sa">
                        <div id="chpter_sa">
                            <span>chpter.</span>
                        </div>
                    </div>
                </div>
                <div id="Group_4965_sa">
                    <div id="Group_4541_sa">
                        <div id="Group_3168_es_sa">
                            <div id="Account_successfully__activate_sa">
                                <span>Account successfully <br/>activated!</span>
                            </div>
                        </div>
                    </div>
                    <div id="Thank_you_for_registering_and__sa">
                        <span>Thank you for registering and <br/>activating your account.</span>
                    </div>
                </div>
                <div id="Group_4531_sa">
                    <div id="chpter_ew_sa">
                        <span>chpter.</span>
                    </div>
                    <div id="Contact_sa">
                        <span>Contact</span>
                    </div>
                    <div id="Privacy_Policy_sa">
                        <span>Privacy Policy</span>
                    </div>
                    <div id="Group_4532_sa">
                        <div id="Layer_2_sa">
                            <div id="radio-button-off_sa">
                                <svg className="Rectangle_2094_sa">
                                    <rect id="Rectangle_2094_sa" rx="0" ry="0" x="0" y="0" width="15" height="15">
                                    </rect>
                                </svg>
                            </div>
                        </div>
                        <div id="c_sa">
                            <span>©</span>
                        </div>
                    </div>
                </div>
                <div id="Group_3969_sa">
                    <svg className="Rectangle_2057_e_sa">
                        <linearGradient id="Rectangle_2057_e" spreadMethod="pad" x1="0.066" x2="0.79" y1="0" y2="1.3">
                            <stop offset="0" stop-color="#000" stop-opacity="1"></stop>
                            <stop offset="1" stop-color="#000" stop-opacity="1"></stop>
                        </linearGradient>
                        <rect id="Rectangle_2057_e_sa" rx="7" ry="7" x="0" y="0" width="356" height="62">
                        </rect>
                    </svg>
                    <NavLink to={'/welcomepage'}>
                        <div id="Take_me_home">
                            <span>Take me home</span>
                        </div>
                    </NavLink>
                </div>
            </div>
        </div>

    )
}

export default SuccessfulActivation
import axios from 'axios';
import React, { SyntheticEvent, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import '../../styles/Wallet/payoutconfirm.css'
import { useLocation } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../styles/Components/Toastify.css'
import '../../styles/Components/loading-dots.css'
import '../../styles/Components/loading-dots.css'
import { BouncingDotsLoader, BouncingDotsLoaderMobile } from '../../components/loading-dots';
import { parse } from 'path';

const PayoutConfirm = () => {
    const location = useLocation();
    const PayoutDetails = location.state.PayoutDetails

    const selectedBankAccount = location.state.selectedBankAccount

    const PayoutType = location.state.PayoutType
    const WithdrawalAmount = location.state.WithdrawalAmount
    const Service_Fee = location.state.Service_Fee
    const KES_wallet_balance = parseFloat(location.state.KES_wallet_balance).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    const HashedPhoneNumber = location.state.HashedPhoneNumber
    const HashedBankAccountNumber = location.state.HashedBankAccountNumber
    const [selectedDivMob, setSelectedDivMob] = useState<number>(0);
    const [loading, setLoading] = useState(false)



    const navigate = useNavigate();
    const Submit_Bank = async (e: SyntheticEvent) => {
        e.preventDefault();


        let api_url = process.env.REACT_APP_CHPTER_API_URL + '/wallet/bank-withdrawal'
        let headers = {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("JWT"),
        };

        const data = {
            "amount": parseInt(WithdrawalAmount),
            "currency": "KES",
            "withdrawal_type": "bank",
            "bank_details": {
                "bank_name": PayoutDetails.bank_name ? PayoutDetails.bank_name : selectedBankAccount.bank_name,
                "bank_account": PayoutDetails.bank_account_number ? PayoutDetails.bank_account_number : selectedBankAccount.bank_account_number,
                "bank_code": PayoutDetails.bank_code ? PayoutDetails.bank_code : selectedBankAccount.bank_code,
                "bank_type": "Domestic", 
                "bank_account_name":  PayoutDetails.bank_account_name ? PayoutDetails.bank_account_name : selectedBankAccount.bank_account_name
            },

        }
        setLoading(true)


        await axios.post(api_url, data, { headers: headers })
            .then((response) => {

                navigate("/withdraw-response", {
                    state: {
                        PayoutDetails: { ...PayoutDetails },
                        selectedBankAccount: { ...selectedBankAccount },
                        selectedDivMob: selectedDivMob, PayoutType, WithdrawalAmount, HashedBankAccountNumber
                    }
                });

            })
            .catch((error) => {
                // Error
                if (error.response) {

                    let error_message = error.response.data.message

                    if (error_message === 'Insufficient Funds') {
                        toast.error("Insufficient Funds", {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                        })
                    } else {
                        toast.error(error.response.data.message, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                        })
                    }
                } else if (error.request) {
   
                } else {
        
                }
            }).finally(() => {
                setLoading(false)
            })

    }

    const Submit_Mpesa = async (e: SyntheticEvent) => {
        e.preventDefault();


        let api_url = process.env.REACT_APP_CHPTER_API_URL + '/wallet/mobile-withdrawal'
        let headers = {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("JWT"),
        };

        const data = {
            "amount": parseFloat(WithdrawalAmount),
            "currency": "KES",
            "withdrawal_type": "mobile",
            "mobile_details": {
                "mobile_number": PayoutDetails.mobile_number,
                "mobile_type": PayoutType
            }
        }
        setLoading(true)

        await axios.post(api_url, data, { headers: headers })

            .then((response) => {


                setTimeout(() => {
                    navigate("/withdraw-response", {
                        state: {
                            PayoutDetails: { ...PayoutDetails },
                            selectedBankAccount: { ...selectedBankAccount },
                            selectedDivMob: selectedDivMob, PayoutType, WithdrawalAmount, HashedBankAccountNumber
                        }
                    });
                }, 3000);


            })
            .catch((error) => {
                // Error
                if (error.response) {


                    let error_message = error.response.data.message

                    if (error_message === 'Insufficient Funds') {
                        toast.error("Insufficient Funds", {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                        })
                    } else {
                        toast.error(error.response.data.message, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                        })
                    }

                } else if (error.request) {

                } else {
      
                }
            }).finally(() => {
                setLoading(false)
            });



    }





    return (
        <>
            <MediaQuery maxWidth={1000}>

                {loading ? (<BouncingDotsLoaderMobile></BouncingDotsLoaderMobile>
                ) : (
                    <div className="withdrawalconfirm_wwc_mob">
                        <div className="withdrawalconfirm-inner_wwc_mob">
                            <div className="group-wrapper_wwc_mob">
                                <div className="group-wrapper_wwc_mob">
                                    <div className="confirm_wwc_mob">
                                        <div className="header_wwc_mob">
                                            <div className="frame-parent_wwc_mob">
                                                <NavLink to={'/wallet'} style={{ textDecoration: 'none' }}>

                                                    <div className="group-container_wwc_mob">
                                                        <div className="rectangle-parent_wwc_mob">
                                                            <div className="group-child_wwc_mob" />

                                                            <svg className="path-9390-icon_wwc_mob" viewBox="0.636 0.05 10.513 18">
                                                                <path id="Path_9390_pad_mob" d="M 5.357423305511475 10.11950778961182 C 4.766643047332764 9.528789520263672 4.766643047332764 8.571026802062988 5.357423305511475 7.980309963226318 L 10.7065315246582 2.631746768951416 C 11.29722785949707 2.041119337081909 11.2972297668457 1.083506107330322 10.7065315246582 0.4928695559501648 L 10.7065315246582 0.4928695559501648 C 10.11583614349365 -0.09776583313941956 9.158125877380371 -0.09776552766561508 8.567437171936035 0.4928695559501648 L 1.079220414161682 7.980309963226318 C 0.4884408712387085 8.571026802062988 0.4884408712387085 9.528774261474609 1.079220414161682 10.11950778961182 L 8.567437171936035 17.60687637329102 C 9.158125877380371 18.19756317138672 10.11583614349365 18.19756317138672 10.7065315246582 17.60687637329102 L 10.7065315246582 17.60687637329102 C 11.2972297668457 17.0163402557373 11.2972297668457 16.05868339538574 10.7065315246582 15.46799468994141 L 5.357423305511475 10.11950778961182 Z">
                                                                </path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </NavLink>


                                                <div className="payment-detail_wwc_mob">Confirm Withdrawal</div>
                                            </div>
                                        </div>
                                        <div className="payoutmethod_wwc_mob">
                                            <div className="add-a-new-product-wrapper_wwc_mob">
                                                <div className="add-a-new_wwc_mob">Payout Method</div>
                                            </div>
                                        </div>
                                        {PayoutType === "Bank" ? (
                                            <>

                                                <div className="confirm-inner_wwc_mob">
                                                    <div className="frame-wrapper_wwc_mob">
                                                        <div className="frame-container_wwc_mob">
                                                            <div className="frame-div_wwc_mob">
                                                                <div className="frame-group_wwc_mob">
                                                                    <div className="icons8-bank-96-wrapper_wwc_mob">
                                                                        <img
                                                                            className="icons8-bank-96_wwc_mob"
                                                                            alt=""
                                                                            src="media/icons8-bank-96.png"
                                                                        />
                                                                    </div>
                                                                    <div className="stardard-chartered-bank-parent_wwc_mob">
                                                                        <div className="stardard-chartered-bank-container_wwc_mob">
                                                                            <span className="star_wwc_mob">{selectedBankAccount.bank_account_name}</span>

                                                                        </div>
                                                                        <div className="account-parent_wwc_mob">
                                                                            <div className="stardard-chartered-bank-container_wwc_mob">
                                                                                Account
                                                                            </div>
                                                                            <div className="div_wwc_mob">{`${selectedBankAccount.bank_account_number?.slice(0, 4)}****${selectedBankAccount.bank_account_number.slice(-4)}`}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="payoutmethod_wwc_mob">
                                                    <div className="add-a-new-product-wrapper_wwc_mob">
                                                        <div className="add-a-new_wwc_mob">Payout Amount</div>
                                                    </div>
                                                </div>
                                                <div className="confirm-child_wwc_mob">
                                                    <div className="frame-wrapper1_wwc_mob">
                                                        <div className="add-a-new-product-parent_wwc_mob">
                                                            <div className="stardard-chartered-bank-container_wwc_mob">KES</div>
                                                            <div className="add-a-new3_wwc_mob">{WithdrawalAmount}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="confirm-inner1_wwc_mob">
                                                    <div className="frame-parent1_wwc_mob">
                                                        <div className="frame-parent2_wwc_mob">
                                                            <div className="add-a-new-product-wrapper_wwc_mob">
                                                                <div className="add-a-new4_wwc_mob">Your Wallet Balance</div>
                                                            </div>
                                                            <div className="add-a-new-product-group_wwc_mob">
                                                                <div className="add-a-new_wwc_mob">KES</div>
                                                                <div className="add-a-new6_wwc_mob">{KES_wallet_balance}</div>
                                                            </div>
                                                        </div>
                                                        <div className="line-wrapper_wwc_mob">
                                                            <div className="frame-child_wwc_mob" />
                                                        </div>
                                                        <div className="add-a-new7_wwc_mob">Service Fee KES. {Service_Fee}</div>
                                                    </div>
                                                </div>
                                                <div className="footer_wwc_mob">
                                                    <NavLink to={'/wallet'} style={{ textDecoration: 'none', color: "black" }} className="cancel-wrapper_wwc_mob" >
                                                        <div className="withdrawalconfirm-inner_wwc_mob">CANCEL</div>
                                                    </NavLink>
                                                    <div className="save-changes-wrapper_wwc_mob" onClick={Submit_Bank}>
                                                        <div className="withdrawalconfirm-inner_wwc_mob">WITHDRAW</div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>

                                                <div className="confirm-inner_wwc_mob">
                                                    <div className="frame-wrapper_wwc_mob">
                                                        <div className="frame-container_wwc_mob">
                                                            <div className="frame-div_wwc_mob">
                                                                <div className="frame-group_wwc_mob">
                                                                    <div className="icons8-bank-96-wrapper_wwc_mob">
                                                                        <img
                                                                            className="icons8-bank-96_wwc_mob"
                                                                            alt=""
                                                                            src="media/n_024px-M-PESA_LOGO-01svg.png"
                                                                        />
                                                                    </div>
                                                                    <div className="stardard-chartered-bank-parent_wwc_mob">
                                                                        <div className="stardard-chartered-bank-container_wwc_mob">
                                                                            <span className="star_wwc_mob">MOBILE MONEY WALLET</span>

                                                                        </div>
                                                                        <div className="account-parent_wwc_mob">
                                                                            <div className="stardard-chartered-bank-container_wwc_mob">
                                                                                Account
                                                                            </div>
                                                                            <div className="div_wwc_mob">{HashedPhoneNumber}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="payoutmethod_wwc_mob">
                                                    <div className="add-a-new-product-wrapper_wwc_mob">
                                                        <div className="add-a-new_wwc_mob">Payout Amount</div>
                                                    </div>
                                                </div>
                                                <div className="confirm-child_wwc_mob">
                                                    <div className="frame-wrapper1_wwc_mob">
                                                        <div className="add-a-new-product-parent_wwc_mob">
                                                            <div className="stardard-chartered-bank-container_wwc_mob">KES</div>
                                                            <div className="add-a-new3_wwc_mob">{WithdrawalAmount}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="confirm-inner1_wwc_mob">
                                                    <div className="frame-parent1_wwc_mob">
                                                        <div className="frame-parent2_wwc_mob">
                                                            <div className="add-a-new-product-wrapper_wwc_mob">
                                                                <div className="add-a-new4_wwc_mob">Your Wallet Balance</div>
                                                            </div>
                                                            <div className="add-a-new-product-group_wwc_mob">
                                                                <div className="add-a-new_wwc_mob">KES</div>
                                                                <div className="add-a-new6_wwc_mob">{KES_wallet_balance}</div>
                                                            </div>
                                                        </div>
                                                        <div className="line-wrapper_wwc_mob">
                                                            <div className="frame-child_wwc_mob" />
                                                        </div>
                                                        <div className="add-a-new7_wwc_mob">Service Fee KES. {Service_Fee}</div>
                                                    </div>
                                                </div>
                                                <div className="footer_wwc_mob">
                                                    <NavLink to={'/wallet'} style={{ textDecoration: 'none', color: "black" }} className="cancel-wrapper_ww_mob" >
                                                        <div className="withdrawalconfirm-inner_wwc_mob">CANCEL</div>
                                                    </NavLink>
                                                    <div className="save-changes-wrapper_wwc_mob" onClick={Submit_Mpesa}>
                                                        <div className="withdrawalconfirm-inner_wwc_mob">WITHDRAW</div>
                                                    </div>
                                                </div>
                                            </>

                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick={false}
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    toastClassName="custom-toast"
                    className="toast-container"
                    style={{
                        position: "fixed",
                        color: "white",
                        top: "10%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        height: "10vh",
                        width: "70vw",
                    }}
                />
            </MediaQuery>


            <MediaQuery minWidth={1001}>
                <ToastContainer
                    position="top-center"
                    autoClose={9000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick={false}
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    toastClassName="custom-toast"
                    className="toast-container"
                    style={{
                        top: "10%",
                        color: "white",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                    }}
                />

                {loading ? (<BouncingDotsLoader></BouncingDotsLoader>
                ) : (
                    <main className="form-signin">
                        <div id="WalletWithdrawConfirm_wwc">
                            <div id='Group_9621_wwc'>
                                <div id="All_wwc">
                                    <span>Confirm Withdrawal</span>
                                </div>
                                {PayoutType === "Bank" ? (
                                    <>
                                        <div id="Group_6661_wwc">
                                            <div id="Group_6654_wwc">
                                                <svg className="Rectangle_2155_wwc">
                                                    <rect id="Rectangle_2155_wwc" rx="5" ry="5" x="0" y="0" width="326" height="97">
                                                    </rect>
                                                </svg>
                                                <div id="Group_5402_wwc">
                                                    <div id="Stardard_Chartered_Bank___wwc">
                                                        <span>{PayoutDetails.bank_account_name} </span>
                                                    </div>
                                                    <div id="Account_wwc">
                                                        <span>Account</span>
                                                    </div>
                                                    <div id="n_01277839173900_wwc">
                                                        <span>{HashedBankAccountNumber}</span>
                                                    </div>
                                                </div>
                                                <img id="icons8-bank-96_wwc" src="media/icons8-bank-96.png" alt='' />

                                            </div>
                                            <div id="All_e_wwc">
                                                <span>Payout Method</span>
                                            </div>
                                        </div>
                                        <div id="Group_6658_wwc">
                                            <div id="Group_4741_wwc" onClick={Submit_Bank}>
                                                <div id="Group_3969_wwc">
                                                    <svg className="Rectangle_2057_wwc">
                                                        <rect id="Rectangle_2057_wwc" rx="5" ry="5" x="0" y="0" width="125" height="30">
                                                        </rect>
                                                    </svg>
                                                </div>
                                                <div id="Group_4628_wwc">
                                                    <div id="WITHDRAW_wwc">
                                                        <span>WITHDRAW</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <NavLink to={'/wallet'} className="nav-link" style={{ textDecoration: 'none' }}>
                                                <div id="Group_4742_wwc">
                                                    <div id="Group_3969_fe_wwc">
                                                        <svg className="Rectangle_2057_ff_wwc">
                                                            <rect id="Rectangle_2057_ff_wwc" rx="5" ry="5" x="0" y="0" width="75" height="30">
                                                            </rect>
                                                        </svg>
                                                    </div>
                                                    <div id="Group_4628_fg_wwc">
                                                        <div id="CANCEL_wwc">
                                                            <span>CANCEL</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div id="Group_6661_wwc">
                                            <div id="Group_6654_wwc">
                                                <svg className="Rectangle_2155_wwc">
                                                    <rect id="Rectangle_2155_wwc" rx="5" ry="5" x="0" y="0" width="326" height="97">
                                                    </rect>
                                                </svg>
                                                <div id="Group_5402_wwc">
                                                    <div id="Stardard_Chartered_Bank___wwc">
                                                        <span>MOBILE MONEY WALLET</span>
                                                    </div>
                                                    <div id="Account_wwc">
                                                        <span>Account</span>
                                                    </div>
                                                    <div id="n_01277839173900_wwc">
                                                        <span>+{HashedPhoneNumber}</span>
                                                    </div>
                                                </div>
                                                <img id="icons8-bank-96_wwc" src="media/n_024px-M-PESA_LOGO-01svg.png" alt='' />


                                            </div>
                                            <div id="All_e_wwc">
                                                <span>Payout Method</span>
                                            </div>
                                        </div>
                                        <div id="Group_6658_wwc">
                                            <div id="Group_4741_wwc" onClick={Submit_Mpesa}>
                                                <div id="Group_3969_wwc">
                                                    <svg className="Rectangle_2057_wwc">
                                                        <rect id="Rectangle_2057_wwc" rx="5" ry="5" x="0" y="0" width="125" height="30">
                                                        </rect>
                                                    </svg>
                                                </div>
                                                <div id="Group_4628_wwc">
                                                    <div id="WITHDRAW_wwc">
                                                        <span>WITHDRAW</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <NavLink to={'/wallet'} className="nav-link" style={{ textDecoration: 'none' }}>
                                                <div id="Group_4742_wwc">
                                                    <div id="Group_3969_fe_wwc">
                                                        <svg className="Rectangle_2057_ff_wwc">
                                                            <rect id="Rectangle_2057_ff_wwc" rx="5" ry="5" x="0" y="0" width="75" height="30">
                                                            </rect>
                                                        </svg>
                                                    </div>
                                                    <div id="Group_4628_fg_wwc">
                                                        <div id="CANCEL_wwc">
                                                            <span>CANCEL</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    </>
                                )}


                                <div id="Group_6660_wwc">
                                    <div id="All_fa_wwc">
                                        <span>Your Wallet Balance</span>
                                    </div>
                                    <div id="Group_6657_wwc">
                                        <div id="All_fb_wwc">
                                            <span>{KES_wallet_balance}</span>
                                        </div>
                                        <svg className="Line_445_wwc" viewBox="0 0 326.5 1">
                                            <path id="Line_445_wwc" d="M 0 0 L 326.5 0">
                                            </path>
                                        </svg>
                                        <div id="All_fc_wwc">
                                            <span>KES.</span>
                                        </div>
                                    </div>
                                </div>

                                <div id="All_fi_wwc">
                                    <span>Service Fee KES. {Service_Fee}</span>
                                </div>
                                <div id="Group_6790_wwc">
                                    <svg className="Rectangle_2429_wwc">
                                        <rect id="Rectangle_2429_wwc" rx="5" ry="5" x="0" y="0" width="326" height="97">
                                        </rect>
                                    </svg>
                                    <div id="Group_6656_wwc">
                                        <div id="All_fm_wwc">
                                            <span>{WithdrawalAmount}</span>
                                        </div>
                                        <div id="All_fn_wwc">
                                            <span>KES.</span>
                                        </div>
                                    </div>
                                    <div id="All_fo_wwc">
                                        <span>Payout Amount</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>

                )}
            </MediaQuery>




        </>
    )
};

export default PayoutConfirm;

import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import MediaQuery from "react-responsive";
import Wrapper from '../../components/Wrapper';

import '../../styles/Components/NavBar.css'
import '../../styles/Components/UserRibbon.css'
import '../../styles/Shopify/newcampaign.css'
import { get_merchant_profile } from '../../services/Settings/merchantprofileinfo';


const NewCampaign = () => {

    const [characterCount, setCharacterCount] = useState(0)
    const [message, setMessage] = useState("")

    const handleTextareaChange = (event: { target: { value: any; }; }) => {
        const textareaContent = event.target.value;
        const Count = textareaContent.length;
        setCharacterCount(Count)
        setMessage(textareaContent)

    };
    // Get Merchant Information
    const [MerchantInfo, setMerchant] = useState([] as any);
    useEffect(() => {
        async function fetchMerchantInfo() {
            var merchant = await get_merchant_profile();
            setMerchant(merchant);
        }
        fetchMerchantInfo();
    }, []);

    return (
        <>
            <MediaQuery minWidth={1001}>
                <div id="Send_First_Campaign_cmpgn">
                    <div id="Group_7975_cmpgn">
                        <div id="All_cmpgn">
                            <span>Send Your First Campaign </span>
                        </div>
                        <div id="Send_your_first_message_and_da_cmpgn">
                            <span>Send your first message and dazzle your customers.</span>
                        </div>
                    </div>
                    <div id="Group_7977_cmpgn">
                        <svg className="Rectangle_2057_cmpgn">
                            <rect id="Rectangle_2057_cmpgn" rx="7" ry="7" x="0" y="0" width="598" height="50">
                            </rect>
                        </svg>
                        <div id="Group_7973_cmpgn">
                            <div id="Send_message_cmpgn">
                                <span>Send message</span>
                            </div>
                            <div id="Layer_2_cmpgn">
                                <div id="arrow-forward_cmpgn">
                                    <svg className="Rectangle_2559_cmpgn">
                                        <rect id="Rectangle_2559_cmpgn" rx="0" ry="0" x="0" y="0" width="24" height="24">
                                        </rect>
                                    </svg>
                                    <svg className="Path_9376_cmpgn" viewBox="4 5 16 14.001">
                                        <path id="Path_9376_cmpgn" d="M 5 13 L 16.86000061035156 13 L 13.23000049591064 17.36000061035156 C 12.87653923034668 17.78525733947754 12.93474102020264 18.41653633117676 13.35999965667725 18.77000045776367 C 13.78525924682617 19.12346267700195 14.41653728485107 19.06525993347168 14.77000045776367 18.64000129699707 L 19.77000045776367 12.64000129699707 C 19.80364036560059 12.59227752685547 19.83372116088867 12.54214191436768 19.86000061035156 12.4900016784668 C 19.86000061035156 12.44000148773193 19.90999984741211 12.41000175476074 19.93000030517578 12.36000156402588 C 19.97532653808594 12.24534225463867 19.99905776977539 12.12328910827637 20 12 C 19.99905776977539 11.87671089172363 19.97532653808594 11.75465869903564 19.93000030517578 11.64000034332275 C 19.93000030517578 11.59000015258789 19.88000106811523 11.5600004196167 19.86000061035156 11.51000022888184 C 19.83372116088867 11.45785999298096 19.80364036560059 11.40772438049316 19.77000045776367 11.36000061035156 L 14.77000045776367 5.360000610351562 C 14.57964420318604 5.131460189819336 14.2974328994751 4.99951696395874 14 5 C 13.76634788513184 4.999543190002441 13.5399112701416 5.080918312072754 13.35999965667725 5.229999542236328 C 13.15549278259277 5.399547576904297 13.02685165405273 5.643500328063965 13.00246238708496 5.908028125762939 C 12.9780740737915 6.172555923461914 13.05994129180908 6.435916900634766 13.22999954223633 6.639999866485596 L 16.86000061035156 11 L 5 11 C 4.447715282440186 11 4 11.44771575927734 4 12 C 4 12.55228519439697 4.447715282440186 13 5 13 Z">
                                        </path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="Group_7978_cmpgn">
                        <svg className="Rectangle_2562_cmpgn">
                            <rect id="Rectangle_2562_cmpgn" rx="10" ry="10" x="0" y="0" width="391" height="521">
                            </rect>
                        </svg>
                        <img id="Mask_Group_1_cmpgn" src="media/Mask_Group_1.png" />
                        <div id="ATELIER_BIENA_cmpgn">
                            <span>{MerchantInfo['company_name']} 👝:</span>
                        </div>
                        <div id="ATELIER_BIENA___Hello_Customer_cmpgn" style={{ whiteSpace: "pre-wrap" }}>
                            {message}
                        </div>
                    </div>
                    <div id="Message_cmpgn">
                        <span>Message</span>
                    </div>

                    <div id="PREVIEW_cmpgn">
                        <span>PREVIEW</span>
                    </div>
                    <div id="Send_Test_SMS_cmpgn">
                        <span>Send Test SMS</span>
                    </div>
                    {/* <div id="Group_7976_cmpgn">
                        <div id="n__of_2_cmpgn">
                            <span>1 of 2</span>
                        </div>
                        <svg className="Path_9468_cmpgn" viewBox="71.902 4.08 7.098 12.901">
                            <path id="Path_9468_cmpgn" d="M 79 16.98132705688477 L 72.48000335693359 11.67092895507812 C 71.70999908447266 11.04378128051758 71.70999908447266 10.01753807067871 72.48000335693359 9.390390396118164 L 79 4.079959869384766">
                            </path>
                        </svg>
                        <svg className="Path_9467_cmpgn" viewBox="8.91 4.08 7.097 12.901">
                            <path id="Path_9467_cmpgn" d="M 8.910030364990234 16.98132705688477 L 15.43000030517578 11.67092895507812 C 16.20000076293945 11.04378128051758 16.20000076293945 10.01753807067871 15.43000030517578 9.390390396118164 L 8.910030364990234 4.079959869384766">
                            </path>
                        </svg>
                    </div> */}
                    <div id="Group_7982_cmpgn">
                        <svg className="Rectangle_2553_cmpgn">
                            <rect id="Rectangle_2553_cmpgn" rx="10" ry="10" x="0" y="0" width="598" height="377">
                            </rect>
                        </svg>
                        <textarea id="Hello_Customer__We_are_big_on__cmpgn" onChange={handleTextareaChange} name='message'>
                        </textarea>
                        <div id="Group_7981_cmpgn">
                            <svg className="Line_465_cmpgn" viewBox="0 0 540 0.699999988079071">
                                <path id="Line_465_cmpgn" d="M 0 0 L 540 0">
                                </path>
                            </svg>
                            <div id="n_46__1_SMS_cmpgn">
                                <span>{characterCount} </span><span style={{ fontSize: "16px" }}>|</span><span> 1 SMS</span>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </>
    )

}
export default NewCampaign;
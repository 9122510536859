import axios from 'axios';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import Picker from 'emoji-picker-react';
import MediaQuery from "react-responsive";
import '../../styles/Marketing/campaigns.css'
import '../../styles/Components/NavBar.css'
import '../../styles/Components/UserRibbon.css'
import '../../styles/Components/HotLinks_Customers.css'
import '../../styles/Chat/chat_list.css'
import { ToastContainer, toast } from 'react-toastify';
import { GetConversationMessagesInstagram, SendTextMessageInstagram, SendMediaMessageInstagram } from '../../services/Chat/InstagramService';
import { GetFileExtension, GetFileType } from '../../utils/file-functions';
import { timeFormatter_ } from '../../utils/date_formatter';
import AWS from 'aws-sdk';
import { v4 as uuid } from 'uuid';
import '../../styles/Components/Toastify.css'

import useWebSocket from 'react-use-websocket';

const WS_URL = 'wss://minastirith.chpter.co/v1/social-media/messages/websocket';


const ChatThreadBody = (props: any) => {
    const { sendJsonMessage } = useWebSocket(WS_URL, {
        onMessage: (message: any) => {
            
            setIsReloadConversation(!isReloadConversation)

        }
    });

    const [isReloadConversation, setIsReloadConversation] = useState(false);

    const { conversation } = props.bodyPropObj;

    const textareaRef = useRef<HTMLTextAreaElement>(null);

    function handleTextareaResizeMobile() {
        if (textareaRef.current) {
            textareaRef.current.style.height = '28px';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }
    function handleTextareaResizeDesktop() {
        if (textareaRef.current) {
            textareaRef.current.style.height = '34px';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }

    const [chosenEmoji, setChosenEmoji] = useState<string | null>(null);
    const [showPicker, setShowPicker] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState(false);
    const [textMessage, setTextMessage] = useState('');


    const onEmojiClick = (e: any, emojiObject: any) => {
        let sym = e.unified.split("-");
        let codesArray: any = [];
        sym.forEach((el: any) => codesArray.push("0x" + el));
        let emoji = String.fromCodePoint(...codesArray);
        setTextMessage(textMessage + emoji);
    };

    const togglePicker = () => {
        setShowPicker(!showPicker);
    };



   


    const handleButtonClick = () => {
        setIsOpen(!isOpen);
    }
    const messagesBodyRef = useRef<null | HTMLDivElement>(null)

    const scrollToBottom = () => {
        messagesBodyRef.current?.scrollIntoView({ behavior: "smooth", block: 'end', inline: 'nearest' })
    }

    const [instagramMessages, setInstagramMessages] = useState([] as any);
    const [isLoading, setIsLoading] = useState(true)
    const [isMessageSent, setIsMessageSent] = useState(false)

    useEffect(() => {

        fetchInstagramConversationMessages();
    }, [isMessageSent, props, isReloadConversation])

    const sendMessage = () => {
        setShowPicker(false);
        var message = {
            "conversation_id": String(conversation.id),
            "message": textMessage
        }
        sendTextMessages(message)
    };
    async function fetchInstagramConversationMessages() {
        setIsLoading(false)
        sendJsonMessage({ "type": "instagram_conversation_messages_read", "payload": {}, "conversation_payload": { "conversation_id": String(conversation.id), } })
        await GetConversationMessagesInstagram(conversation.id).then(data => {
            if (data.success) {
                setInstagramMessages(data.instagram_messages);
                scrollToBottom();


            }
            if (!data.success) { toast(data.message, { type: "error" }); }
        }).catch(error => {

        }).finally(() => {
            setIsLoading(false)
        })
    }

    async function sendTextMessages(message: any) {
        await SendTextMessageInstagram(message).then(data => {
            if (data.success) {
                setTextMessage('');
                setIsMessageSent(true);
            }
            if (!data.success) { toast(data.message, { type: "error" }); }
        }).catch(error => {

        }).finally(() => {
            setIsLoading(false)
        })
    }

    async function sendMediaMessage(message: any) {
        await SendMediaMessageInstagram(message).then(data => {

            if (data.success) {
                setTextMessage('');
                setIsMessageSent(true);
            }
            if (!data.success) { toast(data.message, { type: "error" }); }
        }).catch(error => {

        }).finally(() => {
            setIsLoading(false)
        })
    }

    //Setup AWS S3
    AWS.config.update({
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
    })

    const myBucket = new AWS.S3({
        params: { Bucket: process.env.REACT_APP_AWS_BUCKET_NAME },
        region: process.env.REACT_APP_AWS_REGION,
    })


    //Uploading image to S3 function
    const uploadFile = async (file: { name: any, type: any }) => {
        const fileExtension = GetFileExtension(file)
        const fileName = `${uuid()}.${fileExtension}`;

        const params = {
            Body: file,
            Bucket: process.env.REACT_APP_AWS_BUCKET_NAME + '/instagram',
            Key: fileName,
            ContentType: file.type
        };
        const putObjectPromise = myBucket.putObject(params).promise();
        await putObjectPromise;
        return { fileName: fileName, fileExtension: fileExtension, success: true };
    }

    const imageFileInputRef = useRef<null | HTMLInputElement>(null);
    const videoFileInputRef = useRef<null | HTMLInputElement>(null);
    const audioFileInputRef = useRef<null | HTMLInputElement>(null);

    const selectImageFile = () => {
        if (imageFileInputRef.current) {
            imageFileInputRef.current.click();
        }

    }
    const selectVideoFile = () => {
        if (videoFileInputRef.current) {
            videoFileInputRef.current.click();
        }
    }
    const selectAudioFile = () => {
        if (audioFileInputRef.current) {
            audioFileInputRef.current.click();
        }
    }

    const handleMediaFileChange = (e: any) => {
        const file = e.target.files[0];

        uploadFile(file).then(response => {

            if (response.success) {
                var message = {
                    "is_reusable": "true",
                    "conversation_id": String(conversation.id),
                    "media_type": GetFileType(response.fileExtension),
                    "media_url": process.env.REACT_APP_AWS_BUCKET_URL + "/instagram/" + response.fileName,
                }

                sendMediaMessage(message)
            }
        }).catch(error => {

        }).finally(() => {
            setIsLoading(false)
        })


    };

    const documentFileInputRef = useRef<null | HTMLInputElement>(null);
    const selectDocumentFile = () => {
        if (documentFileInputRef.current) {
            documentFileInputRef.current.click();
        }
    }
    const handleDocumentFileChange = (e: any) => {
        const file = e.target.files[0];

        uploadFile(file).then(response => {

            if (response.success) {
                var message = {
                    "is_reusable": "true",
                    "conversation_id": String(conversation.id),
                    "media_type": GetFileType(response.fileExtension),
                    "media_url": process.env.REACT_APP_AWS_BUCKET_URL + "/instagram/" + response.fileName,
                }

                sendMediaMessage(message)
            }
        }).catch(error => {

        }).finally(() => {
            setIsLoading(false)
        })
    };



    const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            sendMessage();
        } else if (event.key === 'Enter' && event.shiftKey) {
            setTextMessage((prevMessage) => prevMessage + '\n');
        }
    };

    const footerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        adjustFooterPosition();
    }, [textMessage]);

    function adjustFooterPosition() {
        const rectangleElement = textareaRef.current;

        const footerElement = footerRef.current;

        if (rectangleElement && footerElement) {
            const rectangleHeight = rectangleElement.offsetHeight;
            footerElement.style.top = `${rectangleHeight + 601}px`;
            footerElement.style.marginBottom = `${3}px`;
            footerElement.style.marginTop = `${3}px`;
        }
    }

    function handleTextareaChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
        setTextMessage(e.target.value);
    }


    return (
        <>

            <MediaQuery minWidth={1001}>
                <ToastContainer
                    position="top-center"
                    autoClose={9000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick={false}
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    toastClassName="custom-toast"
                    className="toast-container"
                    style={{
                        top: "10%",
                        color: "white",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                    }}
                />
                <main className="form-signin">
                    <div id="Chat_Thread_Body_thrdbdy">
                        <div id="Group_7407_thrdbdy">
                            <svg className="Rectangle_2565_thrdbdy">
                                <rect id="Rectangle_2565_thrdbdy" rx="0" ry="0" x="0" y="0" width="946" height="758">
                                </rect>
                            </svg>

                            {instagramMessages.map((message: any, index: any) => {
                                return (
                                    <div key={index} ref={messagesBodyRef}>
                                        <>
                                            <div className="chat-container">
                                                {message.is_business ? (
                                                    <>
                                                        {message.has_attachment ?
                                                            <div className="chat-bubble-media">
                                                                <iframe className="chat-media-bubble" src={message.media_url} frameBorder="0"
                                                                    scrolling="no"></iframe>
                                                                <div className="chat-timestamp-media">{message.read ? "Seen" : ""} {timeFormatter_(message.created_at)}</div>
                                                            </div>
                                                            :
                                                            <div className="chat-bubble-reply">
                                                                <div className="chat-text-reply" style={{ backgroundColor: "rgb(64,93,230)" }}>{message.message}</div>
                                                                <div className="chat-timestamp-reply">{message.read ? "Seen" : ""} {timeFormatter_(message.created_at)}</div>
                                                            </div>
                                                        }
                                                    </>
                                                ) : (
                                                    <>
                                                        {message.type == "text"
                                                            ?
                                                            <div className="chat-bubble-msg">
                                                                <div className="chat-text-msg">{message.message}</div>
                                                                <div className="chat-timestamp-msg">{timeFormatter_(message.created_at)}</div>
                                                            </div>
                                                            :
                                                            <></>
                                                        }

                                                        {message.type == "image"
                                                            ?
                                                            <div className="chat-bubble-media-send-image">
                                                                <iframe src={message.media_url} frameBorder="0"
                                                                    scrolling="no"></iframe>
                                                                <div className="chat-timestamp-media-send">{timeFormatter_(message.created_at)}</div>
                                                            </div>
                                                            :
                                                            <></>
                                                        }

                                                        {message.type == "video"
                                                            ?
                                                            <div className="chat-bubble-media-send-video">
                                                                <iframe src={message.media_url} frameBorder="0"
                                                                    scrolling="no"></iframe>
                                                                <div className="chat-timestamp-media-send">{timeFormatter_(message.created_at)}</div>
                                                            </div>
                                                            :
                                                            <></>
                                                        }
                                                        {message.type == "audio"
                                                            ?
                                                            <div className="chat-bubble-media-send-audio">
                                                                <iframe src={message.media_url} frameBorder="0"
                                                                    scrolling="no"></iframe>
                                                                <div className="chat-timestamp-media-send">{timeFormatter_(message.created_at)}</div>
                                                            </div>
                                                            :
                                                            <></>
                                                        }

                                                    </>
                                                )}
                                            </div>
                                        </>
                                    </div>

                                )
                            })}

                        </div>
                    </div>
                    <div id="Chat_Thread_Footer_thrdftr">
                        <div id="Group_7406_thrdftr">
                            <div id="Group_7404_thrdftr">
                                <textarea id="Rectangle_2537_thrdftr" name="instagram_message" ref={textareaRef} onKeyUp={handleTextareaResizeDesktop} value={textMessage}
                                    onChange={(e) => setTextMessage(e.target.value)} onKeyDown={handleKeyDown} />
                            </div>
                            {/* togglePicker */}
                            <div id="Layer_2_thrdftr" >
                                <div id="smiling-face_thrdftr">
                                    <div id="smiling-face_fp_thrdftr">
                                        <div onClick={togglePicker}>
                                            <svg className="n_Icon-olor_thrdftr" viewBox="2 2 17.867 18.867">
                                                <path id="n_Icon-olor_thrdftr" d="M 10.93356037139893 2 C 15.8675651550293 2 19.86712074279785 6.223405838012695 19.86712074279785 11.43356037139893 C 19.86712074279785 16.64371299743652 15.8675651550293 20.86712074279785 10.93356037139893 20.86712074279785 C 5.999556064605713 20.86712074279785 2 16.64371681213379 2 11.43356037139893 C 2 6.223404407501221 5.999555587768555 2 10.93356037139893 2 Z M 10.93356037139893 3.886712074279785 C 6.986464500427246 3.886712074279785 3.786712408065796 7.265551567077637 3.786712408065796 11.43356037139893 C 3.786712408065796 15.60156917572021 6.986464977264404 18.98040771484375 10.93356037139893 18.98040771484375 C 14.88065528869629 18.98040771484375 18.08040809631348 15.60156917572021 18.08040809631348 11.43356037139893 C 18.08040809631348 7.26555061340332 14.88065528869629 3.886712074279785 10.93356037139893 3.886712074279785 Z M 15.40034103393555 12.37691688537598 C 15.40034103393555 14.98192310333252 13.4004955291748 17.09369659423828 10.93356037139893 17.09369659423828 C 8.466626167297363 17.09369659423828 6.466780662536621 14.98192310333252 6.466780185699463 12.37691879272461 L 15.40034103393555 12.37691688537598 Z">
                                                </path>
                                            </svg>
                                        </div>
                                        {showPicker && <Picker height={400} width={330} onEmojiClick={onEmojiClick} />}
                                    </div>
                                </div>
                            </div>
                            <div id="Group_7403_thrdftr" onClick={handleButtonClick}>
                                <svg className="Ellipse_800_thrdftr">
                                    <ellipse id="Ellipse_800_thrdftr" rx="20.5" ry="20.5" cx="20.5" cy="20.5">
                                    </ellipse>
                                </svg>
                                <div id="Layer_2_fu_thrdftr">
                                    <div id="plus_thrdftr">
                                        <svg className="Rectangle_2538_thrdftr">
                                            <rect id="Rectangle_2538_thrdftr" rx="0" ry="0" x="0" y="0" width="25" height="25">
                                            </rect>
                                        </svg>
                                        <svg className="Path_9335_thrdftr" viewBox="4 4 12.694 12.694">
                                            <path id="Path_9335_thrdftr" d="M 15.90034008026123 9.553492546081543 L 11.14020442962646 9.553492546081543 L 11.14020442962646 4.793355941772461 C 11.14020442962646 4.355197906494141 10.78500652313232 4 10.346848487854 4 C 9.908688545227051 4 9.553492546081543 4.355197906494141 9.553492546081543 4.793355941772461 L 9.553492546081543 9.553492546081543 L 4.793355941772461 9.553492546081543 C 4.355197906494141 9.553492546081543 4 9.908690452575684 4 10.346848487854 C 4 10.78500747680664 4.355197906494141 11.14020442962646 4.793355941772461 11.14020442962646 L 9.553492546081543 11.14020442962646 L 9.553492546081543 15.90034008026123 C 9.553492546081543 16.33849906921387 9.908688545227051 16.69369697570801 10.346848487854 16.69369697570801 C 10.78500652313232 16.69369697570801 11.14020442962646 16.33849906921387 11.14020442962646 15.90034008026123 L 11.14020442962646 11.14020442962646 L 15.90034008026123 11.14020442962646 C 16.33849906921387 11.14020442962646 16.69369697570801 10.78500652313232 16.69369697570801 10.346848487854 C 16.69369697570801 9.908688545227051 16.33849906921387 9.553492546081543 15.90034008026123 9.553492546081543 Z">
                                            </path>
                                        </svg>
                                    </div>
                                </div>
                            </div>


                            {isOpen && (
                                <div className="dropdown-menu-instagram">
                                    <p className="dropdown-item" onClick={() => selectImageFile()}>
                                        <input type="file" accept="image/*" ref={imageFileInputRef} onChange={handleMediaFileChange} style={{ display: "none" }} />
                                        Image
                                    </p>

                                    <p className="dropdown-item" onClick={() => selectVideoFile()}>
                                        <input type="file" accept="video/*" ref={videoFileInputRef} onChange={handleDocumentFileChange} style={{ display: "none" }} />
                                        Video
                                    </p>

                                    <p className="dropdown-item" onClick={() => selectAudioFile()}>
                                        <input type="file" accept="audio/*" ref={audioFileInputRef} onChange={handleDocumentFileChange} style={{ display: "none" }} />
                                        Audio
                                    </p>

                                </div>
                            )}
                            <div id="Group_7402_thrdftr">
                                <svg className="Ellipse_800_fz_thrdftr">
                                    <ellipse id="Ellipse_800_fz_thrdftr" rx="20.5" ry="20.5" cx="20.5" cy="20.5">
                                    </ellipse>
                                </svg>
                                <div id="Layer_2_f_thrdftr">
                                    <div id="message-square_thrdftr">
                                        <svg className="Rectangle_2540_thrdftr">
                                            <rect id="Rectangle_2540_thrdftr" rx="0" ry="0" x="0" y="0" width="25" height="25">
                                            </rect>
                                        </svg>
                                        <svg className="Ellipse_801_thrdftr">
                                            <ellipse id="Ellipse_801_thrdftr" rx="1" ry="1" cx="1" cy="1">
                                            </ellipse>
                                        </svg>
                                        <svg className="Ellipse_802_thrdftr">
                                            <ellipse id="Ellipse_802_thrdftr" rx="1" ry="1" cx="1" cy="1">
                                            </ellipse>
                                        </svg>
                                        <svg className="Ellipse_803_thrdftr">
                                            <ellipse id="Ellipse_803_thrdftr" rx="1" ry="1" cx="1" cy="1">
                                            </ellipse>
                                        </svg>
                                        <svg className="Path_9338_thrdftr" viewBox="2 3 21 20">
                                            <path id="Path_9338_thrdftr" d="M 19.84999847412109 3.000000476837158 L 5.150000095367432 3.000000476837158 C 3.410302877426147 3.000000476837158 1.999999284744263 4.413837909698486 1.999999761581421 6.157894611358643 L 1.999999761581421 21.94736862182617 C 2.000652313232422 22.32711982727051 2.205288648605347 22.67707824707031 2.535500049591064 22.86315727233887 C 2.692428350448608 22.95231819152832 2.869621992111206 22.99944496154785 3.049999952316284 23 C 3.238457441329956 22.99994468688965 3.423426866531372 22.94904136657715 3.58549976348877 22.85263061523438 L 8.299999237060547 19.98947334289551 C 8.474263191223145 19.88546752929688 8.67484188079834 19.83428382873535 8.877497673034668 19.84210395812988 L 19.84999847412109 19.84210395812988 C 21.58969497680664 19.84210395812988 22.99999809265137 18.42826843261719 22.99999809265137 16.68421173095703 L 22.99999809265137 6.157894611358643 C 22.99999809265137 4.413837432861328 21.58969497680664 3.000000476837158 19.84999847412109 3.000000476837158 Z M 20.89999771118164 16.68421173095703 C 20.89999771118164 17.26556396484375 20.42989730834961 17.73684120178223 19.84999847412109 17.73684120178223 L 8.877497673034668 17.73684120178223 C 8.304013252258301 17.73638343811035 7.741284370422363 17.89289093017578 7.249999046325684 18.18947410583496 L 4.099999904632568 20.08420944213867 L 4.099999904632568 6.157894611358643 C 4.099999904632568 5.576541900634766 4.570100784301758 5.105262756347656 5.150000095367432 5.105262756347656 L 19.84999847412109 5.105262756347656 C 20.42989730834961 5.105262756347656 20.89999771118164 5.576541900634766 20.89999771118164 6.157894611358643 L 20.89999771118164 16.68421173095703 Z">
                                            </path>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div id="Group_7405_thrdftr" onClick={() => sendMessage()}>
                                <svg className="Ellipse_799_thrdftr">
                                    <ellipse id="Ellipse_799_thrdftr" rx="20.5" ry="20.5" cx="20.5" cy="20.5">
                                    </ellipse>
                                </svg>
                                <div id="Group_7068_thrdftr">
                                    <svg className="Path_9336_thrdftr" viewBox="-7.49 9.102 29.11 8.331">
                                        <path id="Path_9336_thrdftr" d="M -7.490234375 9.1015625 L 21.6201171875 17.43298721313477 L -4.630783557891846 16.43239974975586 L -7.490234375 9.1015625 Z">
                                        </path>
                                    </svg>
                                    <svg className="Path_9337_thrdftr" viewBox="-7.49 9.102 29.11 8.331">
                                        <path id="Path_9337_thrdftr" d="M -7.490234375 17.43298721313477 L 21.6201171875 9.1015625 L -4.630783557891846 10.10214996337891 L -7.490234375 17.43298721313477 Z">
                                        </path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </MediaQuery>

            <MediaQuery maxWidth={1000}>

                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick={false}
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    toastClassName="custom-toast"
                    className="toast-container"
                    style={{
                        position: "fixed",
                        color: "white",
                        top: "10%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        height: "10vh",
                        width: "70vw",
                    }}
                />

                {/* <div id="Messages_thrdbdy_mob">
                    <div id="Group_7858_thrdbdy_mob">

                        {instagramMessages.map((message: any, index: any) => {
                            return (
                                <div key={index}>


                                    <div className="chat-container-mb">
                                        {message.is_business ? (
                                            <>

                                                {message.type == "text"
                                                    ?
                                                    <div className="chat-bubble-reply-mb">
                                                        <div className="chat-text-reply-mb">{message.message}</div>
                                                        <div className="chat-timestamp-reply-mb">{message.read ? "Seen" : ""} {timeFormatter_(message.created_at)}</div>
                                                    </div>
                                                    :
                                                    <></>}

                                                {message.type == "media"
                                                    ?
                                                    <div className="chat-bubble-media-mb">
                                                        <iframe src={message.media_url} frameBorder="0"
                                                            scrolling="no"></iframe>
                                                        <div className="chat-timestamp-media-mb">{message.read ? "Seen" : ""} {timeFormatter_(message.created_at)}</div>
                                                    </div>
                                                    :
                                                    <></>}

                                            </>
                                        ) : (
                                            <>
                                                {message.type == "text"
                                                    ?
                                                    <div className="chat-bubble-mb">
                                                        <div className="chat-text-msg-mb">{message.message}</div>
                                                        <div className="chat-timestamp-msg-mb">{timeFormatter_(message.created_at)}</div>
                                                    </div>
                                                    :
                                                    <></>
                                                }

                                                {message.type == "image"
                                                    ?
                                                    <div className="chat-bubble-media-send-image-mb">
                                                        <iframe src={message.media_url} frameBorder="0"
                                                            scrolling="no"></iframe>
                                                        <div className="chat-timestamp-media-send-mb">{timeFormatter_(message.created_at)}</div>
                                                    </div>
                                                    :
                                                    <></>
                                                }

                                                {message.type == "video"
                                                    ?
                                                    <div className="chat-bubble-media-send-video-mb">
                                                        <iframe src={message.media_url} frameBorder="0"
                                                            scrolling="no"></iframe>
                                                        <div className="chat-timestamp-media-send-mb">{timeFormatter_(message.created_at)}</div>
                                                    </div>
                                                    :
                                                    <></>
                                                }
                                                {message.type == "audio"
                                                    ?
                                                    <div className="chat-bubble-media-send-audio-mb">
                                                        <iframe src={message.media_url} frameBorder="0"
                                                            scrolling="no"></iframe>
                                                        <div className="chat-timestamp-media-send-mb">{timeFormatter_(message.created_at)}</div>
                                                    </div>
                                                    :
                                                    <></>
                                                }

                                            </>
                                        )}
                                    </div>

                                </div>
                            )
                        })}

                    </div>
                </div>

                <div id='bottom_thrdbdy_mob'>
                </div>

                <div id="Footer_thrdbdy_mob" ref={footerRef}
                    style={{
                        transformOrigin: "0px 0px",
                        transform: "scale(1)",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>

                    <div id="Group_7405_thrdftr_mob" onClick={() => sendMessage()}>
                        <svg className="Ellipse_799_thrdftr_mob">
                            <ellipse id="Ellipse_799_thrdftr_mob" rx="14.5" ry="14.5" cx="14.5" cy="14.5">
                            </ellipse>
                        </svg>
                        <div id="Group_7068_thrdftr_mob">
                            <svg
                                viewBox="0 0 24 24"
                                style={{
                                    width: "20px",
                                    height: "20px",
                                    marginRight: "10px",
                                    fill: "#FFFFFF",
                                }}
                            >
                                <path d="M2.01,21L23,12L2.01,3L2,10l15,2L2,14L2.01,21z" />
                            </svg>

                        </div>


                    </div>


                    <svg className="Line_i_thrdbdy_mob" viewBox="0 1 414 0.4000000059604645">
                        <path id="Line_i_thrdbdy_mob" d="M 0 1 L 414 1">
                        </path>
                    </svg>
                    <div id="Group_7859_thrdbdy_mob">
                        <div id="Group_7067_thrdbdy_mob">
                            <textarea id="Rectangle_2537_thrdbdy_mob" name="instagram_message" ref={textareaRef} value={textMessage}
                                onChange={(e) => setTextMessage(e.target.value)}
                                onKeyUp={handleTextareaResizeMobile} />

                        </div>
                        <div id="Group_7066_thrdbdy_mob" onClick={handleButtonClick}>
                            <svg className="Ellipse_800_thrdbdy_mob">
                                <ellipse id="Ellipse_800_thrdbdy_mob" rx="14.195333480834961" ry="14.195384979248047" cx="14.195333480834961" cy="14.195384979248047">
                                </ellipse>
                            </svg>
                            <div id="Layer_2_hj_thrdbdy_mob">
                                <div id="plus_thrdbdy_mob">
                                    <svg className="Rectangle_2538_thrdbdy_mob">
                                        <rect id="Rectangle_2538_thrdbdy_mob" rx="0" ry="0" x="0" y="0" width="17.693" height="17.281">
                                        </rect>
                                    </svg>
                                    <svg className="Path_9335_thrdbdy_mob" viewBox="4 4 11.24 11.24">
                                        <path id="Path_9335_thrdbdy_mob" d="M 14.5373706817627 8.917458534240723 L 10.32242393493652 8.917458534240723 L 10.32242393493652 4.702493667602539 C 10.32242393493652 4.314516544342041 10.00790596008301 3.999999523162842 9.619930267333984 3.999999523162842 C 9.231955528259277 3.999999523162842 8.917439460754395 4.314516544342041 8.917439460754395 4.702493667602539 L 8.917439460754395 8.917458534240723 L 4.702491283416748 8.917458534240723 C 4.314515113830566 8.917458534240723 3.999999523162842 9.231975555419922 3.999999523162842 9.619951248168945 C 3.999999523162842 10.00792980194092 4.314515113830566 10.32244682312012 4.702491283416748 10.32244682312012 L 8.917439460754395 10.32244682312012 L 8.917439460754395 14.53740787506104 C 8.917439460754395 14.92538642883301 9.231955528259277 15.23990440368652 9.619930267333984 15.23990440368652 C 10.00790596008301 15.23990440368652 10.32242393493652 14.92538642883301 10.32242393493652 14.53740787506104 L 10.32242393493652 10.32244682312012 L 14.5373706817627 10.32244682312012 C 14.92534637451172 10.32244682312012 15.23986434936523 10.00792789459229 15.23986434936523 9.619951248168945 C 15.23986434936523 9.231973648071289 14.92534637451172 8.917458534240723 14.5373706817627 8.917458534240723 Z">
                                        </path>
                                    </svg>
                                </div>
                            </div>
                        </div>

                        {isOpen && (
                            <div className="dropdown-menu-mobile">
                                <p className="dropdown-item" onClick={() => selectImageFile()}>
                                    <input type="file" accept="image/*" ref={imageFileInputRef} onChange={handleMediaFileChange} style={{ display: "none" }} />
                                    Image
                                </p>

                                <p className="dropdown-item" onClick={() => selectVideoFile()}>
                                    <input type="file" accept="video/*" ref={videoFileInputRef} onChange={handleDocumentFileChange} style={{ display: "none" }} />
                                    Video
                                </p>

                                <p className="dropdown-item" onClick={() => selectAudioFile()}>
                                    <input type="file" accept="audio/*" ref={audioFileInputRef} onChange={handleDocumentFileChange} style={{ display: "none" }} />
                                    Audio
                                </p>
                            </div>
                        )}
                        <div id="Group_7070_thrdbdy_mob">
                            <svg className="Ellipse_800_ho_thrdbdy_mob">
                                <ellipse id="Ellipse_800_ho_thrdbdy_mob" rx="14.401063919067383" ry="14.195384979248047" cx="14.401063919067383" cy="14.195384979248047">
                                </ellipse>
                            </svg>
                            <div id="Layer_2_hp_thrdbdy_mob">
                                <div id="message-square_thrdbdy_mob">
                                    <svg className="Rectangle_2540_thrdbdy_mob">
                                        <rect id="Rectangle_2540_thrdbdy_mob" rx="0" ry="0" x="0" y="0" width="17.693" height="17.281">
                                        </rect>
                                    </svg>
                                    <svg className="Ellipse_801_thrdbdy_mob">
                                        <ellipse id="Ellipse_801_thrdbdy_mob" rx="0.6960599422454834" ry="0.696062445640564" cx="0.6960599422454834" cy="0.696062445640564">
                                        </ellipse>
                                    </svg>
                                    <svg className="Ellipse_802_thrdbdy_mob">
                                        <ellipse id="Ellipse_802_thrdbdy_mob" rx="0.6960599422454834" ry="0.696062445640564" cx="0.6960599422454834" cy="0.696062445640564">
                                        </ellipse>
                                    </svg>
                                    <svg className="Ellipse_803_thrdbdy_mob">
                                        <ellipse id="Ellipse_803_thrdbdy_mob" rx="0.6960599422454834" ry="0.696062445640564" cx="0.6960599422454834" cy="0.696062445640564">
                                        </ellipse>
                                    </svg>
                                    <svg className="Path_9338_thrdbdy_mob" viewBox="2 3 14.617 13.921">
                                        <path id="Path_9338_thrdbdy_mob" d="M 14.42466926574707 3.000000476837158 L 4.192588329315186 3.000000476837158 C 2.981655120849609 3.000000476837158 1.999999165534973 3.984119653701782 1.999999523162842 5.198092460632324 L 1.999999523162842 16.18855476379395 C 2.00045371055603 16.45288467407227 2.142892837524414 16.69647789001465 2.372739791870117 16.82600021362305 C 2.481971263885498 16.88806343078613 2.605308771133423 16.92086410522461 2.730862617492676 16.92124938964844 C 2.862040519714355 16.92121315002441 2.990790128707886 16.88577842712402 3.103602647781372 16.81867408752441 L 6.385178089141846 14.82573509216309 C 6.506475925445557 14.75333976745605 6.646090507507324 14.71771430969238 6.787151336669922 14.7231559753418 L 14.42466926574707 14.7231559753418 C 15.63560485839844 14.7231559753418 16.61725997924805 13.73904037475586 16.61725997924805 12.52506828308105 L 16.61725997924805 5.198092460632324 C 16.61725997924805 3.984119653701782 15.63560485839844 3.000000476837158 14.42466926574707 3.000000476837158 Z M 15.15553188323975 12.52506828308105 C 15.15553188323975 12.92972564697266 14.82831478118896 13.25776481628418 14.42466926574707 13.25776481628418 L 6.787151336669922 13.25776481628418 C 6.3879714012146 13.25744438171387 5.996278285980225 13.36638641357422 5.654314517974854 13.57282447814941 L 3.46172571182251 14.89167785644531 L 3.46172571182251 5.198092460632324 C 3.46172571182251 4.793434143066406 3.788943767547607 4.465394973754883 4.192588329315186 4.465394973754883 L 14.42466926574707 4.465394973754883 C 14.82831478118896 4.465394973754883 15.15553188323975 4.793434143066406 15.15553188323975 5.198092460632324 L 15.15553188323975 12.52506828308105 Z">
                                        </path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}


                <div className="chatbody_thrdbdy_mob">

                    {instagramMessages.map((message: any, index: any) => {
                        return (
                            <>
                                {message.is_business ? (
                                    <>
                                        {message.type == "text"
                                            ?
                                            <div className="sendermessage_thrdbdy_mob">
                                                <div className="frame-parent12_thrdbdy_mob">
                                                    <div className="frame-parent13_thrdbdy_mob">
                                                        <div className="hello-there-can-i-make-an-ord-container_thrdbdy_mob" style={{ backgroundColor: "#4499ed" }}>
                                                            <div className="hello-there-can1_thrdbdy_mob" style={{ color: "white" }}>
                                                                {message.message}
                                                            </div>
                                                        </div>
                                                        <div className="group-wrapper11_thrdbdy_mob">
                                                            <img className="frame-child3_thrdbdy_mob" alt="" src="/group-7848.svg" />
                                                        </div>
                                                    </div>
                                                    <div className="div1_thrdbdy_mob">{timeFormatter_(message.created_at)}</div>
                                                </div>
                                            </div>
                                            :
                                            <></>}

                                        {message.type == "media"
                                            ?
                                            <div className="sendermessage_thrdbdy_mob">
                                                <div className="frame-parent12_thrdbdy_mob">
                                                    <div className="frame-parent13_thrdbdy_mob">
                                                        <div className="hello-there-can-i-make-an-ord-container_thrdbdy_mob">
                                                            <div className="hello-there-can1_thrdbdy_mob">
                                                                {message.media_url}
                                                            </div>
                                                        </div>
                                                        {/* <div className="group-wrapper11_thrdbdy_mob">
                                        <img className="frame-child3_thrdbdy_mob" alt="" src="/group-7848.svg" />
                                    </div> */}
                                                        <svg className="group-wrapper11_thrdbdy_mob" width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6217 0.990921L2.91403 8.74364C2.66521 8.99391 2.25988 8.99273 2.01254 8.74099L0.0898438 6.78417L0.829563 6.05734L2.46628 7.72312L9.88624 0.259766L10.6217 0.990921Z" fill="#4879FF" />
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.9998 0.876594L6.1367 8.74517C5.88815 8.99391 5.4846 8.99272 5.23751 8.74253L4.62207 8.11938L5.35992 7.39067L5.69009 7.72498L13.2662 0.143555L13.9998 0.876594Z" fill="#4879FF" />
                                                        </svg>

                                                    </div>
                                                    <div className="div1_thrdbdy_mob">{timeFormatter_(message.created_at)}</div>
                                                </div>
                                            </div>
                                            :
                                            <></>}
                                    </>
                                ) : (
                                    <>
                                        {message.type == "text"
                                            ?
                                            <div className="receiptmessage_thrdbdy_mob">
                                                <div className="frame-parent11_thrdbdy_mob">
                                                    <div className="hello-there-can-i-make-an-ord-wrapper_thrdbdy_mob">
                                                        <div className="hello-there-can_thrdbdy_mob">
                                                            {message.message}
                                                        </div>
                                                    </div>
                                                    <div className="div_thrdbdy_mob">{timeFormatter_(message.created_at)}</div>
                                                </div>
                                            </div>
                                            :
                                            <></>
                                        }
                                        {message.type == "image"
                                            ?
                                            <div className="receiptmessage_thrdbdy_mob">
                                                <div className="frame-parent11_thrdbdy_mob">
                                                    <iframe src={message.media_url} frameBorder="0"
                                                        scrolling="no"></iframe>
                                                    {/* <div className="hello-there-can-i-make-an-ord-wrapper_thrdbdy_mob">
                                    <div className="hello-there-can_thrdbdy_mob">
                                        <iframe src={message.media_url} frameBorder="0"
                                            scrolling="no"></iframe>
                                    </div>
                                </div> */}
                                                    <div className="div_thrdbdy_mob">{timeFormatter_(message.created_at)}</div>
                                                </div>
                                            </div>
                                            :
                                            <></>
                                        }
                                        {message.type == "video"
                                            ?
                                            <div className="receiptmessage_thrdbdy_mob">
                                                <div className="frame-parent11_thrdbdy_mob">
                                                    <div className="hello-there-can-i-make-an-ord-wrapper_thrdbdy_mob">
                                                        <div className="hello-there-can_thrdbdy_mob">
                                                            <iframe src={message.media_url} frameBorder="0"
                                                                scrolling="no"></iframe>
                                                        </div>
                                                    </div>
                                                    <div className="div_thrdbdy_mob">{timeFormatter_(message.created_at)}</div>
                                                </div>
                                            </div>
                                            :
                                            <></>
                                        }
                                        {message.type == "audio"
                                            ?
                                            <div className="receiptmessage_thrdbdy_mob">
                                                <div className="frame-parent11_thrdbdy_mob">
                                                    <div className="hello-there-can-i-make-an-ord-wrapper_thrdbdy_mob">
                                                        <div className="hello-there-can_thrdbdy_mob">
                                                            <iframe src={message.media_url} frameBorder="0"
                                                                scrolling="no"></iframe>
                                                        </div>
                                                    </div>
                                                    <div className="div_thrdbdy_mob">{timeFormatter_(message.created_at)}</div>
                                                </div>
                                            </div>
                                            :
                                            <></>
                                        }
                                    </>
                                )}
                            </>
                        )
                    })}
                </div>

                <div className="chatfooter_thrdbdy_mob">
                    <div className="chatfooter-inner_thrdbdy_mob">
                        <div className="line-div_thrdbdy_mob" />
                    </div>
                    <div className="chatfooter-child_thrdbdy_mob">
                        <div className="frame-parent17_thrdbdy_mob">
                            <div className="group-wrapper13_thrdbdy_mob">
                                {/* <img className="frame-child5_thrdbdy_mob" alt="" src="/group-7066.svg" /> */}
                                <div className="frame-child5_thrdbdy_mob" onClick={handleButtonClick}>
                                    <svg className="Ellipse_800_thrdbdy_mob">
                                        <ellipse id="Ellipse_800_thrdbdy_mob" rx="14.195333480834961" ry="14.195384979248047" cx="14.195333480834961" cy="14.195384979248047">
                                        </ellipse>
                                    </svg>
                                    <div id="Layer_2_hj_thrdbdy_mob">
                                        <div id="plus_thrdbdy_mob">
                                            <svg className="Rectangle_2538_thrdbdy_mob">
                                                <rect id="Rectangle_2538_thrdbdy_mob" rx="0" ry="0" x="0" y="0" width="17.693" height="17.281">
                                                </rect>
                                            </svg>
                                            <svg className="Path_9335_thrdbdy_mob" viewBox="4 4 11.24 11.24">
                                                <path id="Path_9335_thrdbdy_mob" d="M 14.5373706817627 8.917458534240723 L 10.32242393493652 8.917458534240723 L 10.32242393493652 4.702493667602539 C 10.32242393493652 4.314516544342041 10.00790596008301 3.999999523162842 9.619930267333984 3.999999523162842 C 9.231955528259277 3.999999523162842 8.917439460754395 4.314516544342041 8.917439460754395 4.702493667602539 L 8.917439460754395 8.917458534240723 L 4.702491283416748 8.917458534240723 C 4.314515113830566 8.917458534240723 3.999999523162842 9.231975555419922 3.999999523162842 9.619951248168945 C 3.999999523162842 10.00792980194092 4.314515113830566 10.32244682312012 4.702491283416748 10.32244682312012 L 8.917439460754395 10.32244682312012 L 8.917439460754395 14.53740787506104 C 8.917439460754395 14.92538642883301 9.231955528259277 15.23990440368652 9.619930267333984 15.23990440368652 C 10.00790596008301 15.23990440368652 10.32242393493652 14.92538642883301 10.32242393493652 14.53740787506104 L 10.32242393493652 10.32244682312012 L 14.5373706817627 10.32244682312012 C 14.92534637451172 10.32244682312012 15.23986434936523 10.00792789459229 15.23986434936523 9.619951248168945 C 15.23986434936523 9.231973648071289 14.92534637451172 8.917458534240723 14.5373706817627 8.917458534240723 Z">
                                                </path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="frame-child6_thrdbdy_mob" /> */}
                            {/* <textarea id="Rectangle_2537_thrdbdy_mob" name="instagram_message" ref={textareaRef} value={textMessage}
                                onChange={handleTextareaChange}
                                // onChange={(e) => setTextMessage(e.target.value)}
                                onKeyUp={handleTextareaResizeMobile} /> */}
                            <textarea
                                className="frame-child10_thrdbdy_mob"
                                name="instagram_message"
                                ref={textareaRef}
                                onChange={handleTextareaChange}
                            />

                            <div className="group-wrapper13_thrdbdy_mob">
                                <div className="frame-child7_thrdbdy_mob" onClick={() => sendMessage()}>
                                    <svg className="Ellipse_799_thrdftr_mob">
                                        <ellipse id="Ellipse_799_thrdftr_mob" rx="14.5" ry="14.5" cx="14.5" cy="14.5">
                                        </ellipse>
                                    </svg>
                                    <div id="Group_7068_thrdftr_mob">
                                        <svg
                                            viewBox="0 0 24 24"
                                            style={{
                                                width: "20px",
                                                height: "20px",
                                                marginRight: "10px",
                                                fill: "#FFFFFF",
                                            }}
                                        >
                                            <path d="M2.01,21L23,12L2.01,3L2,10l15,2L2,14L2.01,21z" />
                                        </svg>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>

                {isOpen && (
                    <div className="dropdown-menu_thrdbdy_mob">
                        <p className="dropdown-item_thrdbdy_mob" style={{marginTop:"10px"}} onClick={() => selectImageFile()}>
                            <input type="file" accept="image/*" ref={imageFileInputRef} onChange={handleMediaFileChange} style={{ display: "none" }} />
                            Image
                        </p>

                        <p className="dropdown-item_thrdbdy_mob" onClick={() => selectVideoFile()}>
                            <input type="file" accept="video/*" ref={videoFileInputRef} onChange={handleDocumentFileChange} style={{ display: "none" }} />
                            Video
                        </p>

                        <p className="dropdown-item_thrdbdy_mob" onClick={() => selectAudioFile()}>
                            <input type="file" accept="audio/*" ref={audioFileInputRef} onChange={handleDocumentFileChange} style={{ display: "none" }} />
                            Audio
                        </p>
                    </div>
                )}

            </MediaQuery>
        </>
    )
};

export default ChatThreadBody;
ChatThreadBody.displayName = 'ChatThreadBody';

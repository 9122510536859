import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

const ShopifyHotLinksSettings = (innerPage: any) => {

    const [titleSelected, setTitleSelected] = useState(1)
    const handleNavLinkClick = (index: React.SetStateAction<number>) => {
        setTitleSelected(index);
    }

    const location = useLocation();
    const currentRoutePath = location.pathname;


    useEffect(() => {
        if (currentRoutePath === '/shopifyprofilesettings') {
            setTitleSelected(1);

        }  else if (currentRoutePath === '/shopifyintegrationssettings') {
            setTitleSelected(2);

        } else if (currentRoutePath === '/shopifyapikeyssettings') {
            setTitleSelected(3);

        } 

    })


    return (

        <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse" >
            <div id="Component_80__10_shopify" className="Component_80___10">
                <svg className="Line_shopify" viewBox="0 1 1247.983 0.5">
                    <path id="Line_shopify" d="M 0 1 L 1247.98291015625 1">
                    </path>
                </svg>
                <NavLink to={'/shopifyprofilesettings'} className="nav-link" style={{ textDecoration: 'none' }}>
                    <div id="All_shopify" key={1} onClick={() => handleNavLinkClick(1)} className={`div ${1 === titleSelected ? 'bold-title' : ''}`} >
                        <span>Business Profile</span>
                    </div>
                </NavLink>
                <NavLink to={'/shopifyintegrationssettings'} className="nav-link" style={{ textDecoration: 'none' }}>
                    <div id="All_fe_shopify" key={2} onClick={() => handleNavLinkClick(2)} className={`div ${2 === titleSelected ? 'bold-title' : ''}`} >
                        <span>Integrations</span>
                    </div>
                </NavLink>
                <NavLink to={'/shopifyapikeyssettings'} className="nav-link" style={{ textDecoration: 'none' }}>
                    <div id="All_ff_shopify" key={3} onClick={() => handleNavLinkClick(3)} className={`div ${3 === titleSelected ? 'bold-title' : ''}`} >
                        <span>API Keys</span>
                    </div>
                </NavLink>
               
            </div>
        </nav>
    )
}

export default ShopifyHotLinksSettings;
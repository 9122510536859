import axios from 'axios';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import '../../styles/Payments/paymentlinks.css'
import '../../styles/Components/NavBar.css'
import '../../styles/Components/UserRibbon.css'
import '../../styles/Components/HotLinks_Checkout.css'
import Wrapper from "../../components/Wrapper";
import HotlinksCheckout from '../../components/HotLinks_Checkout';

import { get_paymentlinks } from '../../services/Payments/paymentlinksinfo';
import { Spinner } from '../../components/spinner';
import { datetimeFormatter_human } from '../../utils/date_formatter';

import MediaQuery from "react-responsive";
import NavBar_Mobile from '../../components/NavBar_Mobile';

import FilterableDataTable from '../../utils/data-table-search';
import { TableColumn } from 'react-data-table-component';
import { get_branding_info } from '../../services/Settings/brandinginfo';
import { BouncingDotsLoader, BouncingDotsLoaderDataTable } from '../../components/loading-dots';
import HotLinksCheckoutMobile from '../../components/HotLinksCheckoutMobile';
import '../../styles/Components/loading-dots.css'


const PaymentLinks = () => {

    const [PaymentLinksInfo, setPaymentLinks] = useState([] as any);
    const [loading, setLoading] = useState(false)
    const [filterText, setFilterText] = React.useState('');
    const [brandingData, setBrandingData] = useState([] as any);
    const [brandAlias, setBrandAlias] = useState('');


    async function fetchPaymentLinks() {
        setLoading(true)
        //show spinner
        await get_paymentlinks().then(paymentlinks => {

            setPaymentLinks(paymentlinks);
           
        }).catch(error => {

        }).finally(() => {
            //Hide spinner
            setLoading(false)

        })

    }
    useEffect(() => {
        fetchPaymentLinks();
        

    }, []);

    useEffect(() => {
        async function fetchBrandingInfo() {
            var branding = await get_branding_info();
            setBrandingData(branding);
            setBrandAlias(branding.brand_alias)
        }

        fetchBrandingInfo();

    }, []);

    //Date today
    const current = new Date();
    const year = current.getFullYear();
    const month = String(current.getMonth() + 1).padStart(2, '0');
    const day = String(current.getDate()).padStart(2, '0');
    const currentDate = `${year}-${month}-${day}`;


    const columns: TableColumn<{ expiry_date: string; created_at: string; slug: string; currency: string; amount: any; status: string }>[] = [
        {
            name: 'LINK URL',
            cell: (row) => (
                <>
                    <div id="Component_58__86_paylink">
                        <div id="Search_Input_paylink">
                            <div id="httpscheckoutchptercodrip_city_paylink">
                                <span>{process.env.REACT_APP_BASE_URL}/paymentlinks/{brandingData.brand_alias}/{row.slug}/checkout</span>
                            </div>
                        </div>
                        <svg className="Rectangle_2127_paylink">
                            <rect id="Rectangle_2127_paylink" rx="5" ry="5" x="0" y="0" width="418" height="25">
                            </rect>
                        </svg>
                    </div>
                    <div id="Group_4641_paylink">
                        <svg className="Rectangle_2126_paylink">
                            <rect id="Rectangle_2126_paylink" rx="5" ry="5" x="0" y="0" width="25" height="25">
                            </rect>
                        </svg>
                        <div id="Layer_2_paylink">
                            <div id="copy_paylink">
                                <svg className="Rectangle_2125_paylink">
                                    <rect id="Rectangle_2125_paylink" rx="0" ry="0" x="0" y="0" width="21" height="21">
                                    </rect>
                                </svg>
                                <svg className="Path_9096_paylink" viewBox="9 9 10.333 10.333">
                                    <path id="Path_9096_paylink" d="M 16.75 19.33333396911621 L 11.58333301544189 19.33333396911621 C 10.15659713745117 19.33333396911621 9 18.17673492431641 9 16.75 L 9 11.58333301544189 C 9 10.15659713745117 10.15659809112549 9 11.58333396911621 9 L 16.75 9 C 18.17673492431641 9 19.33333396911621 10.15659713745117 19.33333396911621 11.58333301544189 L 19.33333396911621 16.75 C 19.33333396911621 18.17673492431641 18.17673492431641 19.33333396911621 16.75 19.33333396911621 Z M 11.58333301544189 10.72222232818604 C 11.10775470733643 10.72222232818604 10.72222232818604 11.10775470733643 10.72222232818604 11.58333301544189 L 10.72222232818604 16.75 C 10.72222232818604 17.22557830810547 11.10775470733643 17.61111068725586 11.58333301544189 17.61111068725586 L 16.75 17.61111068725586 C 17.22557830810547 17.61111068725586 17.61111068725586 17.22557830810547 17.61111068725586 16.75 L 17.61111068725586 11.58333301544189 C 17.61111068725586 11.10775470733643 17.22557830810547 10.72222232818604 16.75 10.72222232818604 L 11.58333301544189 10.72222232818604 Z">
                                    </path>
                                </svg>
                                <svg className="Path_9097_paylink" viewBox="3 3 10.333 10.333">
                                    <path id="Path_9097_paylink" d="M 8.79527759552002 13.33333396911621 L 5.299166679382324 13.33333396911621 C 4.031332492828369 13.32861328125 3.004721164703369 12.302001953125 2.999999523162842 11.03416728973389 L 3 5.299166679382324 C 3.004721641540527 4.031332492828369 4.031333446502686 3.004721164703369 5.299167156219482 2.999999523162842 L 11.03416728973389 3 C 12.302001953125 3.004721164703369 13.32861328125 4.031332492828369 13.33333396911621 5.299167156219482 L 13.33333396911621 8.511111259460449 L 11.61111164093018 8.511111259460449 L 11.61111164093018 5.299166679382324 C 11.61111164093018 4.980528831481934 11.35280418395996 4.722221851348877 11.03416728973389 4.722221851348877 L 5.299166679382324 4.722221851348877 C 4.980528831481934 4.722221851348877 4.722221851348877 4.980528831481934 4.722221851348877 5.299166679382324 L 4.722221851348877 11.03416728973389 C 4.722221851348877 11.35280418395996 4.980528831481934 11.61111164093018 5.299166679382324 11.61111164093018 L 8.79527759552002 11.61111164093018 L 8.79527759552002 13.33333396911621 Z">
                                    </path>
                                </svg>
                            </div>
                        </div>
                    </div>
                    {(row.expiry_date >= currentDate) ? (
                        <div id="Group_6691_paylink">
                            <div id="Group_3969_paylink">
                                <svg className="Rectangle_2057_f_paylink">
                                    <rect id="Rectangle_2057_f_paylink" rx="3" ry="3" x="0" y="0" width="84" height="20">
                                    </rect>
                                </svg>
                            </div>
                            <div id="active_paylink">
                                <span>active</span>
                            </div>
                            <div id="Layer_2_f_paylink">
                                <div id="checkmark_paylink">
                                    <svg className="Rectangle_2122_paylink">
                                        <rect id="Rectangle_2122_paylink" rx="0" ry="0" x="0" y="0" width="18" height="18">
                                        </rect>
                                    </svg>
                                    <svg className="Path_9092_paylink" viewBox="3.999 5.956 12.298 9.233">
                                        <path id="Path_9092_paylink" d="M 8.492274284362793 15.18946647644043 C 8.279788017272949 15.18878364562988 8.077116012573242 15.09994220733643 7.932625770568848 14.94414138793945 L 4.206743717193604 10.98060131072998 C 3.916711807250977 10.67151546478271 3.932157278060913 10.18583393096924 4.2412428855896 9.895801544189453 C 4.550328254699707 9.605769157409668 5.036009311676025 9.621214866638184 5.326041698455811 9.930300712585449 L 8.484606742858887 13.29586124420166 L 14.93206882476807 6.242752075195312 C 15.11035251617432 6.020743370056152 15.39699077606201 5.915829658508301 15.6764669418335 5.970292091369629 C 15.95594310760498 6.024755477905273 16.18221473693848 6.229621887207031 16.26408958435059 6.502328872680664 C 16.34596633911133 6.775036811828613 16.26995849609375 7.070656776428223 16.06669998168945 7.270052909851074 L 9.059587478637695 14.9364767074585 C 8.916444778442383 15.09511184692383 8.713593482971191 15.18681240081787 8.49993896484375 15.18946933746338 Z">
                                        </path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div id="Group_6691_paylink">
                            <div id="Group_3969_paylink">
                                <svg className="Rectangle_2057_f_paylink">
                                    <rect id="Rectangle_2057_f_paylink" style={{ fill: "rgb(118, 162, 255)" }} rx="3" ry="3" x="0" y="0" width="84" height="20">
                                    </rect>
                                </svg>
                            </div>
                            <div id="active_paylink" style={{ left: "23px" }}>
                                <span>expired</span>
                            </div>
                            <div id="Layer_2_f_paylink" style={{ display: "none" }}>
                                <div id="checkmark_paylink">
                                    <svg className="Rectangle_2122_paylink">
                                        <rect id="Rectangle_2122_paylink" rx="0" ry="0" x="0" y="0" width="18" height="18">
                                        </rect>
                                    </svg>
                                    <svg className="Path_9092_paylink" viewBox="3.999 5.956 12.298 9.233">
                                        <path id="Path_9092_paylink" d="M 8.492274284362793 15.18946647644043 C 8.279788017272949 15.18878364562988 8.077116012573242 15.09994220733643 7.932625770568848 14.94414138793945 L 4.206743717193604 10.98060131072998 C 3.916711807250977 10.67151546478271 3.932157278060913 10.18583393096924 4.2412428855896 9.895801544189453 C 4.550328254699707 9.605769157409668 5.036009311676025 9.621214866638184 5.326041698455811 9.930300712585449 L 8.484606742858887 13.29586124420166 L 14.93206882476807 6.242752075195312 C 15.11035251617432 6.020743370056152 15.39699077606201 5.915829658508301 15.6764669418335 5.970292091369629 C 15.95594310760498 6.024755477905273 16.18221473693848 6.229621887207031 16.26408958435059 6.502328872680664 C 16.34596633911133 6.775036811828613 16.26995849609375 7.070656776428223 16.06669998168945 7.270052909851074 L 9.059587478637695 14.9364767074585 C 8.916444778442383 15.09511184692383 8.713593482971191 15.18681240081787 8.49993896484375 15.18946933746338 Z">
                                        </path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    )
                    }

                </>
            ),
        },

        {
            name: '',
        },
        {
            name: '',
        },
        {
            name: 'AMOUNT',
            selector: (row) => `${row.currency}. ${row.amount}`,
            sortable: true,
        },
        {
            name: 'DATE CREATED',
            selector: (row) => datetimeFormatter_human(row.created_at),
            sortable: true,
        },
        {
            name: 'DATE EXPIRED',
            selector: (row) => datetimeFormatter_human(row.expiry_date),
            sortable: true,
        },
    ];

    // Filter
    const filteredItems = PaymentLinksInfo.filter(
        (item: { amount: { toString: () => string; }; currency: string; transaction_ref: string; created_at: string; expiry_date: string; }) =>
            item.amount.toString().toLowerCase().includes(filterText.toLowerCase()) ||
            item.currency.toLowerCase().includes(filterText.toLowerCase()) ||
            datetimeFormatter_human(item.created_at)
                .toLowerCase()
                .includes(filterText.toLowerCase()) ||
            datetimeFormatter_human(item.expiry_date)
                .toLowerCase()
                .includes(filterText.toLowerCase())
    );


    // Export to CSV
    const handleExport = () => {
        const columnNames = ['Payment Link Url', 'Status', 'Currency', 'Amount', 'Date Due', 'Date Created'];
        const dataRows = PaymentLinksInfo.map((paymentlink: { expiry_date: string; created_at: string; slug: string; currency: string; amount: any; status: string }) => (
            [`${process.env.REACT_APP_BASE_URL}/paymentlinks/${paymentlink.slug}/checkout`, paymentlink.status, paymentlink.currency, paymentlink.amount, paymentlink.created_at, paymentlink.expiry_date].join(',')
        ));
        const csv = [columnNames.join(','), ...dataRows].join('\n');
        const csvData = new Blob([csv], { type: 'text/csv' });
        const csvUrl = URL.createObjectURL(csvData);
        const hiddenElement = document.createElement('a');
        hiddenElement.href = csvUrl;
        hiddenElement.target = '_blank';
        hiddenElement.download = 'Payment_Links_data.csv';
        hiddenElement.click();
    };



    return (
        <>
            <MediaQuery minWidth={1001}>
                <main className="form-signin">

                    <div id="Group_6690_paylink">

                        {loading ? (<BouncingDotsLoaderDataTable></BouncingDotsLoaderDataTable>
                        ) : (
                            <FilterableDataTable data={filteredItems} columns={columns} redirect_url={'payment-link-detail'} type={'slug'} rowHeight={'normal'} scrollHeight={'660px'} />

                        )
                        }
                    </div>

                </main>
                <HotlinksCheckout handleExport={handleExport}></HotlinksCheckout>
                <Wrapper navigation="Payments"></Wrapper>
            </MediaQuery>


            <MediaQuery maxWidth={1000}>
                <div className="mobilepages">
                    <div className="mobilepages-inner">
                        <div className="group-frame">
                            <div className="group-frame">
                                <div className="mobilepages1">
                                    <HotLinksCheckoutMobile></HotLinksCheckoutMobile>

                                    {/* ListView */}
                                    <div className="listview1_paylink_mob">
                                        {PaymentLinksInfo.map((payment_links: { created_at: any, expiry_date: any, amount: any, currency: any, status: any, slug: any }) => {
                                            return (
                                                <NavLink to={`/payment-link-detail/${payment_links.slug}`} className="frame-parent38_paylink_mob" style={{ textDecoration: 'none' }}>
                                                    <div className="frame-parent39_paylink_mob">
                                                        <div className="frame-wrapper7_paylink_mob">
                                                            <div className="kes-1490000-container_paylink_mob">
                                                                <div className="kes-14900002_paylink_mob">{payment_links['currency']}. {payment_links['amount']}</div>
                                                                <div className="barry-oloo3_paylink_mob">{datetimeFormatter_human(payment_links['created_at'])}</div>
                                                            </div>
                                                        </div>
                                                        <div className="frame-wrapper8_paylink_mob">
                                                            <div className="group-wrapper16_paylink_mob">
                                                                <div className="group-parent2_paylink_mob">
                                                                    <div className="rectangle-wrapper_paylink_mob">
                                                                        <div className={(payment_links.expiry_date >= currentDate) ? "group-child1_paylink_mob": "group-child12_paylink_mob"}/>
                                                                    </div>
                                                                    <div className="paid_paylink_mob"> {(payment_links.expiry_date >= currentDate) ? "Active": "Expired"}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </NavLink>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>

        </>
    )
};

export default PaymentLinks;

import axios from 'axios';
import { data } from 'jquery';
import { type } from 'os';
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { get_wallet_balance } from '../../services/Wallet/walletbalanceinfo';
import '../../styles/Wallet/swapinitiate.css'
import MediaQuery from 'react-responsive';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
// import SwapConfirm from './swapconfirm';
import 'react-toastify/dist/ReactToastify.css';
import '../../styles/Components/Toastify.css'

const SwapInitiate = () => {
  const [SwapAmount, setSwapAmount] = useState('');
  const [WalletTotals_KES, setWalletTotalsKES] = useState([] as any);
  const [WalletTotals_USD, setWalletTotalsUSD] = useState([] as any);
  const [swap_value, setSwapValue] = useState('')
  const [converted_swap_value, setConvertedSwapValue] = useState('')

  const [ExchangeRate, setExchangeRate] = useState([] as any)

  useEffect(() => {
    // Fetch Wallet Balances
    async function fetchWalletBalances() {
      await get_wallet_balance().then(wallet => {
        setKESWalletBalance(wallet.available_balance_kes)
        setUSDAccountBalance(wallet.total_balance_usd)
        setWalletTotalsKES(wallet.available_balance_kes)
        setWalletTotalsUSD(wallet.total_balance_usd)

      }).catch(error => {

      }).finally(() => {
      })
    }
    fetchWalletBalances();


    // Get Exchange Rate
    async function fetchExchangeRate() {

      const options = {
        method: 'GET',
        url: 'https://currency-converter5.p.rapidapi.com/currency/convert',
        params: { format: 'json', from: 'USD', to: 'KES', amount: '1' },
        headers: {
          'X-RapidAPI-Key': '135ca29c7emsh49edd4af30452d7p130999jsn4dc7febc3d2c',
          'X-RapidAPI-Host': 'currency-converter5.p.rapidapi.com'
        }
      };

      await axios.request(options).then(data => {


        setExchangeRate(data.data.rates.KES.rate);

      }).catch(function (error) {
        if (error.response) {

        } else if (error.request) {

        } else {

        }

      })
    }
    fetchExchangeRate()

  }, []);

  const USD_total = WalletTotals_USD; //USD
  const KES_total = WalletTotals_KES //KES
  const [USD_account_balance, setUSDAccountBalance] = useState(USD_total);
  const [KES_wallet_balance, setKESWalletBalance] = useState(KES_total);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let SwapValue = parseInt(event.target.value);
    setSwapValue(SwapValue.toString())
    


    // Handles Max and Min 
    if (SwapValue <= 0) {
      SwapValue = 0
    } else if (SwapValue > parseInt(event.target.max)) {
      SwapValue = parseInt(event.target.max)
    } else if (isNaN(USD_total) || USD_account_balance === undefined) {
      // SwapValue = 0
      // Nan
    }

    setSwapAmount(SwapValue.toString());
    setUSDAccountBalance(Number.isNaN((USD_total - SwapValue)) ? 0 : (USD_total - SwapValue))



    // Add to KES Wallet
    let ConvertedSwapValue = parseFloat(ExchangeRate) * SwapValue

    setConvertedSwapValue(ConvertedSwapValue.toString())
    setKESWalletBalance(Number.isNaN((KES_total + ConvertedSwapValue)) ? 0 : (KES_total + ConvertedSwapValue))
  };


  const navigate = useNavigate();
  const Submit = () => {
    if (!SwapAmount || parseFloat(SwapAmount) === 0 || isNaN(parseFloat(SwapAmount))) {
      toast.error("Please enter a valid input amount", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000
      });
      return;
    }
    navigate("/swap-confirm", { state: { KES_wallet_balance, converted_swap_value, ExchangeRate, swap_value } });
  }



  return (
    <>

      <MediaQuery minWidth={1001}>
        <ToastContainer
          position="top-center"
          autoClose={9000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          toastClassName="custom-toast"
          className="toast-container"
          style={{
            top: "10%",
            color: "white",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }} />

        <main className="form-signin">
          <div id="WalletSwapToKES_swapre">
            <div id="Group_6956_swapre">
              <div id="All_swapre">
                <span>Swap to KES.</span>
              </div>
              <div id="Group_6954_swapre">
                <div id="All_e_swapre">
                  <span>Enter Amount to swap</span>
                </div>
                <input id="All_fa_swapre" type="number" name="SwapAmount" placeholder="00.00" min="0" max={USD_total} onChange={handleChange} value={SwapAmount} />

                {/* <div id="All_fa_swapre">
                            <span>00.00</span>
                        </div> */}
                <div id="All_fb_swapre">
                  <span>USD</span>
                </div>
                <svg className="Line_445_swapre" viewBox="0 0 326.5 1">
                  <path id="Line_445_swapre" d="M 0 0 L 326.5 0">
                  </path>
                </svg>
              </div>
              <div id="Group_6955_swapre">
                <div id="All_fc_swapre">
                  <span>Your Wallet Balance</span>
                </div>
                <div id="All_fd_swapre">
                  <span>{USD_account_balance}</span>
                </div>
                <svg className="Line_445_e_swapre" viewBox="0 0 326.5 1">
                  <path id="Line_445_e_swapre" d="M 0 0 L 326.5 0">
                  </path>
                </svg>
                <div id="All_fe_swapre">
                  <span>USD</span>
                </div>
              </div>
              <div id="Group_6953_swapre" onClick={Submit}>
                <div id="Group_3969_swapre">
                  <svg className="Rectangle_2057_swapre">
                    <rect id="Rectangle_2057_swapre" rx="5" ry="5" x="0" y="0" width="125" height="30">
                    </rect>
                  </svg>
                </div>
                <div id="Group_4628_swapre">
                  <div id="CONTINUE_swapre">
                    <span>CONTINUE</span>
                  </div>
                </div>
              </div>
              <NavLink to={'/wallet'} className="nav-link" style={{ textDecoration: 'none' }}>
                <div id="Group_6952_swapre">
                  <div id="Group_3969_fh_swapre">
                    <svg className="Rectangle_2057_fi_swapre">
                      <rect id="Rectangle_2057_fi_swapre" rx="5" ry="5" x="0" y="0" width="75" height="30">
                      </rect>
                    </svg>
                  </div>
                  <div id="Group_4628_fj_swapre">
                    <div id="CANCEL_swapre">
                      <span>CANCEL</span>
                    </div>
                  </div>
                </div>
              </NavLink>
              <div id="Group_6790_swapre">
                <svg className="Rectangle_2429_swapre">
                  <rect id="Rectangle_2429_swapre" rx="5" ry="5" x="0" y="0" width="326" height="97">
                  </rect>
                </svg>
                <div id="Group_6656_swapre">
                  <div id="All_fo_swapre">
                    <span>{KES_wallet_balance}</span>
                  </div>
                  <div id="All_fp_swapre">
                    <span>KES.</span>
                  </div>
                </div>
                <div id="All_fq_swapre">
                  <span>KES Wallet Balance</span>
                </div>
              </div>
              <div id="Group_6931_swapre">
                <div id="USD_to_KES_at_exchange_rate_of_swapre">
                  <span>USD to KES at exchange rate of {ExchangeRate}</span>
                </div>
                <svg className="icons8-info-48_swapre" viewBox="2 2 20 20">
                  <path id="icons8-info-48_swapre" d="M 12 2 C 6.488996982574463 2 2 6.488996982574463 2 12 C 2 17.5110034942627 6.488996982574463 22 12 22 C 17.5110034942627 22 22 17.5110034942627 22 12 C 22 6.488996982574463 17.5110034942627 2 12 2 Z M 12 4 C 16.43012237548828 4 20 7.569877624511719 20 12 C 20 16.43012237548828 16.43012237548828 20 12 20 C 7.569877624511719 20 4 16.43012237548828 4 12 C 4 7.569877624511719 7.569877624511719 4 12 4 Z M 11 7 L 11 9 L 13 9 L 13 7 L 11 7 Z M 11 11 L 11 17 L 13 17 L 13 11 L 11 11 Z">
                  </path>
                </svg>
              </div>
            </div>
          </div>
        </main>



      </MediaQuery>




      <MediaQuery maxWidth={1000}>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          toastClassName="custom-toast"
          className="toast-container"
          style={{
            position: "fixed",
            color: "white",
            top: "10%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "10vh",
            width: "70vw",
          }} />

        <div className="swapinitiate_swapre_mob">
          <div className="swapinitiate-inner_swapre_mob">
            <div className="group-wrapper_swapre_mob">
              <div className="group-wrapper_swapre_mob">
                <div className="initiate_swapre_mob">
                  <div className="header_swapre_mob">
                    <div className="frame-parent_swapre_mob">
                      <NavLink to={'/wallet'} style={{ textDecoration: 'none' }}>


                        <div className="group-container_swapre_mob">
                          <div className="rectangle-parent_swapre_mob">
                            <div className="group-child_swapre_mob" />
                            {/* <img
                  className="path-9390-icon_swapre_mob"
                  alt=""
                  src="/path-9390.svg"
                /> */}
                            <svg className="path-9390-icon_swapre_mob" viewBox="0.636 0.05 10.513 18">
                              <path id="Path_9390_pad_mob" d="M 5.357423305511475 10.11950778961182 C 4.766643047332764 9.528789520263672 4.766643047332764 8.571026802062988 5.357423305511475 7.980309963226318 L 10.7065315246582 2.631746768951416 C 11.29722785949707 2.041119337081909 11.2972297668457 1.083506107330322 10.7065315246582 0.4928695559501648 L 10.7065315246582 0.4928695559501648 C 10.11583614349365 -0.09776583313941956 9.158125877380371 -0.09776552766561508 8.567437171936035 0.4928695559501648 L 1.079220414161682 7.980309963226318 C 0.4884408712387085 8.571026802062988 0.4884408712387085 9.528774261474609 1.079220414161682 10.11950778961182 L 8.567437171936035 17.60687637329102 C 9.158125877380371 18.19756317138672 10.11583614349365 18.19756317138672 10.7065315246582 17.60687637329102 L 10.7065315246582 17.60687637329102 C 11.2972297668457 17.0163402557373 11.2972297668457 16.05868339538574 10.7065315246582 15.46799468994141 L 5.357423305511475 10.11950778961182 Z">
                              </path>
                            </svg>
                          </div>
                        </div>
                      </NavLink>


                      <div className="payment-detail_swapre_mob">Swap to KES.</div>
                    </div>
                  </div>
                  <div className="frame-group_swapre_mob">
                    <div className="frame-container_swapre_mob">
                      <div className="frame-div_swapre_mob">
                        <div className="frame-parent1_swapre_mob">
                          <div className="add-a-new-product-wrapper_swapre_mob">
                            <div className="add-a-new_swapre_mob">Enter Amount</div>
                          </div>
                          <div className="add-a-new-product-parent_swapre_mob">
                            <div className="add-a-new1_swapre_mob">USD</div>
                            <input className="add-a-new2_swapre_mob"
                              type="number"
                              name="SwapAmount"
                              placeholder="00.00"
                              min="0"
                              max={USD_total}
                              onChange={handleChange} value={SwapAmount}

                            />
                          </div>
                        </div>
                        <div className="line-wrapper_swapre_mob">
                          <div className="frame-child_swapre_mob" />
                        </div>
                      </div>
                      <div className="frame-parent2_swapre_mob">
                        <div className="frame-parent1_swapre_mob">
                          <div className="add-a-new-product-wrapper_swapre_mob">
                            <div className="add-a-new_swapre_mob">Your Wallet Balance</div>
                          </div>
                          <div className="add-a-new-product-parent_swapre_mob">
                            <div className="add-a-new1_swapre_mob">USD</div>
                            <div className="add-a-new2_swapre_mob">{USD_account_balance}</div>
                          </div>
                        </div>
                        <div className="line-wrapper_swapre_mob">
                          <div className="frame-child_swapre_mob" />
                        </div>
                      </div>
                    </div>
                    <div className="usd-to-kes-at-exchange-rate-of-parent_swapre_mob">
                      <div className="usd-to-kes_swapre_mob">
                        USD to KES at exchange rate of {ExchangeRate}
                      </div>
                      {/* <img
              className="icons8-info-48_swapre_mob"
              alt=""
              src="/icons8info48.svg"
            /> */}

                      <svg className="icons8-info-48_swapre_mob" viewBox="2 2 20 20">
                        <path id="icons8-info-48_swapre" d="M 12 2 C 6.488996982574463 2 2 6.488996982574463 2 12 C 2 17.5110034942627 6.488996982574463 22 12 22 C 17.5110034942627 22 22 17.5110034942627 22 12 C 22 6.488996982574463 17.5110034942627 2 12 2 Z M 12 4 C 16.43012237548828 4 20 7.569877624511719 20 12 C 20 16.43012237548828 16.43012237548828 20 12 20 C 7.569877624511719 20 4 16.43012237548828 4 12 C 4 7.569877624511719 7.569877624511719 4 12 4 Z M 11 7 L 11 9 L 13 9 L 13 7 L 11 7 Z M 11 11 L 11 17 L 13 17 L 13 11 L 11 11 Z">
                        </path>
                      </svg>
                    </div>
                  </div>
                  <div className="payoutamount_swapre_mob">
                    <div className="add-a-new-product-wrapper_swapre_mob">
                      <div className="add-a-new1_swapre_mob">Your Wallet Balance</div>
                    </div>
                  </div>
                  <div className="initiate-inner_swapre_mob">
                    <div className="frame-wrapper_swapre_mob">
                      <div className="add-a-new-product-parent1_swapre_mob">
                        <div className="add-a-new7_swapre_mob">KES</div>
                        <div className="add-a-new8_swapre_mob">{KES_wallet_balance}</div>
                      </div>
                    </div>
                  </div>
                  <div className="footer_swapre_mob">
                    <NavLink to={'/wallet'} style={{ textDecoration: 'none', color: "black" }} className="cancel-wrapper_swapre_mob">
                      <div className="swapinitiate-inner_swapre_mob">CANCEL</div>
                    </NavLink>
                    <div className="save-changes-wrapper_swapre_mob" onClick={Submit}>
                      <div className="swapinitiate-inner_swapre_mob">CONTINUE</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <NavLink to={'/wallet'} className="nav-link" style={{ textDecoration: 'none' }}>
                    <div id="Group_7465_swapre_mob">
                        <svg className="Rectangle_2103_pad_mob">
                            <rect id="Rectangle_2103_pad_mob" rx="5" ry="5" x="0" y="0" width="32" height="32">
                            </rect>
                        </svg>
                        <svg className="Path_9390_pad_mob" viewBox="0.636 0.05 10.513 18">
                            <path id="Path_9390_pad_mob" d="M 5.357423305511475 10.11950778961182 C 4.766643047332764 9.528789520263672 4.766643047332764 8.571026802062988 5.357423305511475 7.980309963226318 L 10.7065315246582 2.631746768951416 C 11.29722785949707 2.041119337081909 11.2972297668457 1.083506107330322 10.7065315246582 0.4928695559501648 L 10.7065315246582 0.4928695559501648 C 10.11583614349365 -0.09776583313941956 9.158125877380371 -0.09776552766561508 8.567437171936035 0.4928695559501648 L 1.079220414161682 7.980309963226318 C 0.4884408712387085 8.571026802062988 0.4884408712387085 9.528774261474609 1.079220414161682 10.11950778961182 L 8.567437171936035 17.60687637329102 C 9.158125877380371 18.19756317138672 10.11583614349365 18.19756317138672 10.7065315246582 17.60687637329102 L 10.7065315246582 17.60687637329102 C 11.2972297668457 17.0163402557373 11.2972297668457 16.05868339538574 10.7065315246582 15.46799468994141 L 5.357423305511475 10.11950778961182 Z">
                            </path>
                        </svg>
                    </div>
                </NavLink> */}

      </MediaQuery>
    </>
  )
};

export default SwapInitiate;

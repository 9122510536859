import React, { SyntheticEvent, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import NavBarMobile from './NavBarMobile';


const HotLinksMarketingMobile = () => {

    const [titleSelected, setTitleSelected] = useState(1)
    const handleNavLinkClick = (index: React.SetStateAction<number>) => {
        setTitleSelected(index);
    }


    const location = useLocation();
    const currentRoutePath = location.pathname;
    useEffect(() => {
        if (currentRoutePath === '/customers') {
            setTitleSelected(1);

        } else if (currentRoutePath === '/marketingcampaigns') {
            setTitleSelected(2);

        }
    })

    return (
        <>
            <NavBarMobile></NavBarMobile>

            <div className="hotlinksmenu1_mob">
                <div className="pagetitle_mob">
                    <div className="marketing1_mob">Marketing</div>
                </div>
                <div className="hotlinkscard-parent_mob">
                    <NavLink to="/createcustomer" className="hotlinkscard3_mob" style={{ textDecoration: "none" }}>
                        <div className="frame-parent2_mob">
                            <div className="new-customer-x-parent1_mob">
                                <div className="new-customer-x3_mob">New Customer</div>
                                <div className="frame-child3_mob" />
                                <div className="frame-child4_mob" />
                            </div>
                            <div className="path-9386-parent1_mob">
                                <svg className="path-9386-icon3_mob" viewBox="0 0 11 11">
                                    <path d="M 4.714285850524902 4.714285850524902 L 4.714285850524902 0 L 6.285714149475098 0 L 6.285714149475098 4.714285850524902 L 11 4.714285850524902 L 11 6.285714149475098 L 6.285714149475098 6.285714149475098 L 6.285714149475098 11 L 4.714285850524902 11 L 4.714285850524902 6.285714149475098 L 0 6.285714149475098 L 0 4.714285850524902 L 4.714285850524902 4.714285850524902 Z">
                                    </path>
                                </svg>
                                <div className="add-new-customer3_mob">
                                    Add New Customer
                                </div>
                            </div>
                        </div>
                    </NavLink>
                    {/* <NavLink to="/chat" className="hotlinkscard3_mob" style={{ textDecoration: "none" }}>
                        <div className="frame-parent2_mob">
                            <div className="new-customer-x-parent1_mob">
                                <div className="new-customer-x3_mob">
                                    Go to Chat
                                </div>
                                <div className="frame-child3_mob" />
                                <div className="frame-child4" />
                            </div>
                            <div className="path-9386-parent2_mob">
                                <svg className="path-9386-icon3_mob" viewBox="0 0 11 11">
                                    <path d="M 4.714285850524902 4.714285850524902 L 4.714285850524902 0 L 6.285714149475098 0 L 6.285714149475098 4.714285850524902 L 11 4.714285850524902 L 11 6.285714149475098 L 6.285714149475098 6.285714149475098 L 6.285714149475098 11 L 4.714285850524902 11 L 4.714285850524902 6.285714149475098 L 0 6.285714149475098 L 0 4.714285850524902 L 4.714285850524902 4.714285850524902 Z">
                                    </path>
                                </svg>
                                <div className="add-new-customer3_mob">
                                    Go to Customer chat
                                </div>
                            </div>
                        </div>
                    </NavLink> */}
                    {/* <NavLink to="/marketingcampaigns" className="hotlinkscard3_mob" style={{ textDecoration: "none" }}>
                        <div className="frame-parent2_mob">
                            <div className="new-customer-x-parent1_mob">
                                <div className="new-customer-x3_mob">
                                    Marketing copy
                                </div>
                                <div className="frame-child3_mob" />
                                <div className="frame-child4_mob" />
                            </div>
                            <div className="path-9386-parent2_mob">
                                <svg className="path-9386-icon3_mob" viewBox="0 0 11 11">
                                    <path d="M 4.714285850524902 4.714285850524902 L 4.714285850524902 0 L 6.285714149475098 0 L 6.285714149475098 4.714285850524902 L 11 4.714285850524902 L 11 6.285714149475098 L 6.285714149475098 6.285714149475098 L 6.285714149475098 11 L 4.714285850524902 11 L 4.714285850524902 6.285714149475098 L 0 6.285714149475098 L 0 4.714285850524902 L 4.714285850524902 4.714285850524902 Z">
                                    </path>
                                </svg>
                                <div className="add-new-customer3_mob">
                                    New Marketing Copy
                                </div>
                            </div>
                        </div>
                    </NavLink> */}
                </div>
            </div>

            <div className="listviewmenu1_mob">
                <div className="lmenu1_mob">
                    <div className="lmenu-inner_mob">
                        <div className="all-group_mob">
                            <NavLink to={'/customers'} key={1} onClick={() => handleNavLinkClick(1)} className={`new-customer-x1_mob ${1 === titleSelected ? 'bold-title' : ''}`} style={{ textDecoration: 'none' }} >
                                <span>Customers</span>
                            </NavLink>
                        </div>
                    </div>
                    <div className="line-container_mob">
                        <svg className="line-icon1_mob" viewBox="0 1 413.583 0.5">
                            <path id="line-icon1_mob" d="M 0 1 L 413.5830078125 1">
                            </path>
                        </svg>
                    </div>
                </div >
            </div >
        </>
    );
};

export default HotLinksMarketingMobile;

// Get Weekly Gross Turnover 
export async function get_weekly_total_payouts(){
  
    const response = await fetch(process.env.REACT_APP_CHPTER_API_URL+"/insights/weekly-total-payouts", {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer "+localStorage.getItem("JWT"),
          'Accept': 'application/json',
        },
      });
    const data = await response.json();
    console.log(data.status);
  
    if(data.status === 401){
      //Redirect to login Token expired
      window.location.replace('/login');
  
    }
  
    if(data.status === 200){
        return data.insights;
    }
  
    return [];
  }
  
  
  
  // Get Weekly Gross Turnover 
  export async function get_weekly_gross_turnover(){
    
  
    const response = await fetch(process.env.REACT_APP_CHPTER_API_URL+"/insights/weekly-gross-turnover", {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer "+localStorage.getItem("JWT"),
          'Accept': 'application/json',
        },
      });
    const data = await response.json();
    console.log(data.status);
  
    if(data.status === 401){
      //Redirect to login Token expired
      window.location.replace('/login');
  
    }
  
    if(data.status === 200){
        return data.insights;
    }
  
    return [];
  }
  
  
  
  // Get Weekly Gross Turnover 
  export async function get_weekly_customer_spend(){
    
  
    const response = await fetch(process.env.REACT_APP_CHPTER_API_URL+"/insights/weekly-customer-spend", {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer "+localStorage.getItem("JWT"),
          'Accept': 'application/json',
        },
      });
    const data = await response.json();
    console.log(data.status);
  
    if(data.status === 401){
      //Redirect to login Token expired
      window.location.replace('/login');
  
    }
  
    if(data.status === 200){
        return data.insights;
    }
  
    return [];
  }
  
  
  // Get Monthly Gross Payouts 
  export async function get_monthly_total_payouts(){
    
  
    const response = await fetch(process.env.REACT_APP_CHPTER_API_URL+"/insights/monthly-total-payouts", {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer "+localStorage.getItem("JWT"),
          'Accept': 'application/json',
        },
      });
    const data = await response.json();
    console.log(data.status);
  
    if(data.status === 401){
      //Redirect to login Token expired
      window.location.replace('/login');
  
    }
  
    if(data.status === 200){
        return data.insights;
    }
  
    return [];
  }
import React from 'react';
import { NavLink, Navigate } from 'react-router-dom';


const Website_NavLink = () => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(!open);
    };

    return (
        <div id="Group_6970_lp_mob">
            <div id="Group_5869_lp_mob">
                <div id="chpter__lp_mob">
                    <span>chpter.</span>
                </div>
            </div>
            <div id="Group_6427_lp_mob" onClick={handleOpen}>
                <svg className="Rectangle_2374_lp_mob">
                    <rect id="Rectangle_2374_lp_mob" rx="2.0363101959228516" ry="2.0363101959228516" x="0" y="0" width="31.608" height="4.073">
                    </rect>
                </svg>
                <svg className="Rectangle_2375_lp_mob">
                    <rect id="Rectangle_2375_lp_mob" rx="2.0363101959228516" ry="2.0363101959228516" x="0" y="0" width="31.608" height="4.073">
                    </rect>
                </svg>
                {open ? (
                    <div id="Chpter_Nav_Bar_Mobile_web_nav">
                        <div id="Group_6427_web_nav">
                            <svg className="Rectangle_2374_web_nav">
                                <rect id="Rectangle_2374_web_nav" rx="1.7431848049163818" ry="1.7431848049163818" x="0" y="0" width="27.058" height="3.486">
                                </rect>
                            </svg>
                            <svg className="Rectangle_2375_web_nav">
                                <rect id="Rectangle_2375_web_nav" rx="1.7431848049163818" ry="1.7431848049163818" x="0" y="0" width="27.058" height="3.486">
                                </rect>
                            </svg>
                        </div>
                        <NavLink to={'/chpterexplore'} className="nav-link">
                            <div id="Group_7278_web_nav">
                                <div id="Explore_chpter_web_nav">
                                    <span>Explore chpter.</span>
                                </div>
                                <svg className="Line_434_web_nav" viewBox="0 0 359 0.5">
                                    <path id="Line_434_web_nav" d="M 0 0.5 L 359 0">
                                    </path>
                                </svg>
                            </div>
                        </NavLink>
                        <NavLink to={'/chpterpricing'} className="nav-link">
                            <div id="Group_7279_web_nav">
                                <div id="Pricing_web_nav">
                                    <span>Pricing</span>
                                </div>
                                <svg className="Line_434_e_web_nav" viewBox="0 0 359 0.5">
                                    <path id="Line_434_e_web_nav" d="M 0 0.5 L 359 0">
                                    </path>
                                </svg>
                            </div>
                        </NavLink>
                        <div id="Group_7280_web_nav">
                            <div id="Features_web_nav">
                                <span>Features</span>
                            </div>
                            <svg className="Line_434_fa_web_nav" viewBox="0 0 359 0.5">
                                <path id="Line_434_fa_web_nav" d="M 0 0.5 L 359 0">
                                </path>
                            </svg>
                        </div>
                        <a href="https://docs.chpter.co/">
                            <div id="Group_7281_web_nav">
                                <div id="Developers_web_nav">
                                    <span>Developers</span>
                                </div>
                                <svg className="Line_434_fb_web_nav" viewBox="0 0 359 0.5">
                                    <path id="Line_434_fb_web_nav" d="M 0 0.5 L 359 0">
                                    </path>
                                </svg>
                            </div>
                        </a>
                        <a href="https://docs.chpter.co/">
                            <div id="Group_7282_web_nav">
                                <div id="API_Docs_web_nav">
                                    <span>API Docs</span>
                                </div>
                                <svg className="Line_434_fc_web_nav" viewBox="0 0 359 0.5">
                                    <path id="Line_434_fc_web_nav" d="M 0 0.5 L 359 0">
                                    </path>
                                </svg>
                            </div>
                        </a>
                        <a href="mailto:support@chpter.co">
                            <div id="Group_7283_web_nav">
                                <div id="Contact_Support_web_nav">
                                    <span>Contact Support</span>
                                </div>
                                <svg className="Line_434_fe_web_nav" viewBox="0 0 359 0.5">
                                    <path id="Line_434_fe_web_nav" d="M 0 0.5 L 359 0">
                                    </path>
                                </svg>
                            </div>
                        </a>
                        <NavLink to={'/login'} className="nav-link">
                            <div id="Group_7276_web_nav">
                                <div id="Sign_In_web_nav">
                                    <span>Sign In</span>
                                </div>
                                <svg className="Line_434_fh_web_nav" viewBox="0 0 359 0.5">
                                    <path id="Line_434_fh_web_nav" d="M 0 0.5 L 359 0">
                                    </path>
                                </svg>
                            </div>
                        </NavLink>
                        <a href="https://app.chpter.co/auth/register">
                            <div id="Group_7277_web_nav">
                                <div id="Register_web_nav">
                                    <span>Register</span>
                                </div>
                                <svg className="Line_434_fk_web_nav" viewBox="0 0 359 0.5">
                                    <path id="Line_434_fk_web_nav" d="M 0 0.5 L 359 0">
                                    </path>
                                </svg>
                            </div>
                        </a>
                        <NavLink to={'/'} className="nav-link">
                            <div id="chpter_web_nav">
                                <span>chpter.</span>
                            </div>
                        </NavLink>
                    </div>
                ) : null}
            </div>
        </div>

    )
}

export default Website_NavLink;
import React, { Component, useState, SyntheticEvent, useEffect } from "react";
import {NavLink, Navigate} from 'react-router-dom';
import {get_merchant_profile} from "../services/Settings/merchantprofileinfo";
import MediaQuery from "react-responsive";

const Ribbon_Connect = () => {
    const [open, setOpen] = React.useState(false);
    const[redirect, setredirect] = useState(false)

    const [MerchantInfo, setMerchant] = useState([] as any);
    useEffect(() => {
        async function fetchMerchantInfo() {
            var merchant = await get_merchant_profile();
            setMerchant(merchant);
        }
        fetchMerchantInfo();
    }, []);


    const handleOpen = () => {
      setOpen(!open);
    };
    const logout = async (e: SyntheticEvent) =>{
        e.preventDefault();

        localStorage.setItem('JWT', '');
        setredirect(true)
    }
    if(redirect){
        localStorage.removeItem('JWT');
        return <Navigate to={'/'}/>
    }

    return (
        
        <MediaQuery minWidth={1001}>  
            <main className="form-signin">
                <div id="Component_89__1_chthtl" className="Component_89___1_chthtl">
                    <NavLink to={'/homepage'} style={{ textDecoration: 'none' }} className="nav-link">
                    <div id="Group_5271_chthtl">
                        <div id="Group_3168_chthtl">
                            <div id="chpter_chthtl">
                                <span>chpter.</span>
                            </div>
                        </div>
                    </div>
                    </NavLink>
                    <div id="Group_6565_chthtl">
                        <div id="Group_3526_chthtl">
                            <svg className="Rectangle_226_chthtl">
                                <rect id="Rectangle_226_chthtl" rx="10" ry="10" x="0" y="0" width="62.781" height="40.71">
                                </rect>
                            </svg>
                        </div>
                        <div id="Drip_City_chthtl">
                            <span>{MerchantInfo['company_name']}</span>
                        </div>                  
                        <div id="Layer_2_chthtl">
                            <button id="chevron-down_chthtl" onClick={handleOpen}>
                                {open ? (
                                    <ul className="menu" >
                                        <li className="menu-item text-decoration-none">
                                            <div>{MerchantInfo['company_name']}</div>
                                        </li>
                                        <NavLink to={'/settings-profile'} style={{ textDecoration: 'none' }} className="nav-link">
                                        <li className="menu-item">
                                            Profile
                                        </li>
                                        </NavLink>
                                        <NavLink to={'/homepage'} style={{ textDecoration: 'none' }} className="nav-link">
                                        <li className="menu-item">
                                            Help & Support
                                        </li>
                                        </NavLink>
                                        <li className="menu-item" onClick={logout}>
                                            Logout
                                        </li>
                                    </ul>
                                ) : null}
                                {open ? <div hidden>Is Open</div> : <div hidden>Is Closed</div>}
                                <svg className="Path_9070_chthtl" viewBox="6.996 9.496 8.372 5.003">
                                    <path id="Path_9070_chthtl" d="M 11.16557788848877 14.49882984161377 C 10.9440975189209 14.50010967254639 10.73122024536133 14.41316032409668 10.57396984100342 14.25718688964844 L 7.240960597991943 10.92417907714844 C 6.914224147796631 10.59744262695312 6.914224147796631 10.06769752502441 7.240960597991943 9.740961074829102 C 7.567697525024414 9.414224624633789 8.097441673278809 9.414224624633789 8.424179077148438 9.740961074829102 L 11.16557788848877 12.49902534484863 L 13.91530990600586 9.849284172058105 C 14.12425708770752 9.616947174072266 14.44563007354736 9.521040916442871 14.74773406982422 9.600868225097656 C 15.04983997344971 9.680694580078125 15.28188896179199 9.922834396362305 15.34879398345947 10.22806072235107 C 15.41569900512695 10.53328704833984 15.3062105178833 10.85029029846191 15.0651969909668 11.04916763305664 L 11.7321891784668 14.26551914215088 C 11.57980918884277 14.41243457794189 11.37722682952881 14.49585056304932 11.16557788848877 14.49882984161377 Z">
                                    </path>
                                </svg>
                            </button>
                        </div>
                        <div id="Layer_2_e_chthtl">
                            <div id="npm_chthtl">
                                <svg className="Rectangle_2095_chthtl">
                                    <rect id="Rectangle_2095_chthtl" rx="0" ry="0" x="0" y="0" width="22" height="22">
                                    </rect>
                                </svg>
                                <svg className="Path_9069_chthtl" viewBox="3 3 16.5 16.5">
                                    <path id="Path_9069_chthtl" d="M 16.75 19.5 L 5.75 19.5 C 4.231216430664062 19.5 3 18.26878356933594 3 16.75 L 3 5.75 C 3 4.231216430664062 4.231217384338379 2.999999523162842 5.750000476837158 3 L 16.75 3 C 18.26878356933594 3 19.5 4.231217384338379 19.5 5.75 L 19.5 16.75 C 19.5 18.26878356933594 18.26878356933594 19.5 16.75 19.5 Z M 5.75 4.833333492279053 C 5.243739128112793 4.833333492279053 4.833333492279053 5.243739128112793 4.833333492279053 5.75 L 4.833333492279053 16.75 C 4.833333492279053 17.25626182556152 5.243739128112793 17.66666793823242 5.75 17.66666793823242 L 16.75 17.66666793823242 C 17.25626182556152 17.66666793823242 17.66666793823242 17.25626182556152 17.66666793823242 16.75 L 17.66666793823242 5.75 C 17.66666793823242 5.243739128112793 17.25626182556152 4.833333492279053 16.75 4.833333492279053 L 5.75 4.833333492279053 Z">
                                    </path>
                                </svg>
                                <svg className="Rectangle_2096_chthtl">
                                    <rect id="Rectangle_2096_chthtl" rx="0" ry="0" x="0" y="0" width="4" height="7">
                                    </rect>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </MediaQuery>
    )
}

export default Ribbon_Connect;